import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const updateCertificateOfConformity = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/shipment/updateCertificateOfConformity`, payload)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(props.fetchDeliveryNotesDetail){
          props.fetchDeliveryNotesDetail(props.shipmentData);
        }
        if (props.fetchAllShipment) {
          props.fetchAllShipment({
            companyInfo: (props.companyInfo || {}),
            relationshipId: (props.companyInfo || {}).relationshipId,
            pageNumber: props.pageNumber || 1,
            pageSize: props.pageSize || 25
          })
        } else {
          const payloadObj = {
            customerId: props.soData.customerId,
            salesOrderMasterId: props.soData.salesOrderMasterId,
            relationshipId: payload.relationshipId
          }
          props.fetchSalesOrderShipment(payloadObj);
        }
        showToasterMessage({ messageType: 'success', description: lang[((res || {}).data || {}).message] || 'Saved Succssfully' })
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }
      })
  }
}
