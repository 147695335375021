import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMMON_ACTIONS, SUPPLIER_PAYMENT_ACTION_LIST, CONSTANTS } from '../../../../static/constants'
import { showToasterMessage, getCurrentDateForBackend, formatDateForBackend, getFinancialYear } from '../../../../utils';
import { fetchBusinessExpenses, resetPaginatedData} from '../Listing/action'
import {fetchSupplierPayments } from '../../../supplier/payment/Listing/action';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const createSupplierPayment = (payloadObject) => {
  const history = payloadObject.history;
  const payload = _getPaymentData(payloadObject);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/supplierPayment/createSupplierPayment`, payload)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        payloadObject.hideModal();
        let data = {
          relationshipId: payloadObject.companyInfo.relationshipId || 0,
          pageSize: 100,
          pageNumber: 1,
        }
        fetchSupplierPayments(data)(dispatch);
        history.push('/admin/supplier-payment-list');
        showToasterMessage({ messageType: 'success', description: 'Payment saved successfully' });
        resetPaginatedData()(dispatch);
        fetchBusinessExpenses(data)(dispatch);
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        payloadObject.hideModal();
        payloadObject.hideModal();
        let data = {
          relationshipId: payloadObject.companyInfo.relationshipId || 0,
          pageSize: 100,
          pageNumber: 1,
        }
        showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        resetPaginatedData()(dispatch);
        fetchBusinessExpenses(data)(dispatch);
      })
  }
}

const _getPaymentData = (formData) => {
  let payload = {};
  let createdByUserId = formData.userInfo.userId || 0;
  let relationshipId = formData.companyInfo.relationshipId || 0;
  let finYear = getFinancialYear(formData.companyInfo);

  payload.paymentDate = formatDateForBackend(formData.paymentDate);
  payload.reference = formData.reference;
  payload.createdByEmpId = formData.userInfo && formData.userInfo.relationshipEmployeeId; 
  let txList = {
    fyStartDate: finYear && finYear.fyStartDate && formatDateForBackend(finYear.fyStartDate),
    fyEndDate: finYear && finYear.fyEndDate && formatDateForBackend(finYear.fyEndDate),
    relationshipId: relationshipId,
    //dateCreated: getCurrentDateForBackend(new Date()),
    createdByUserId: createdByUserId,
    txType: 'Payment',
    instrumentNumber: formData.paymentModeDetail,
    instrumentDate: formatDateForBackend(formData.paymentDate),
    txDate: formatDateForBackend(formData.paymentDate),
    accountingEntry: 'CREDIT',
    paymentModeId: formData.paymentModeId,
    expenseMasterId: formData.expenseMasterId,
    remarks: formData.remarks,
    availableCredit: formData.availableCredit,
    amount: formData.amount,
    paymentModeDetail: formData.paymentModeDetail,
    bankDate: formatDateForBackend(new Date() ),
    paymentMode: formData.paymentModeName,
    currencyCode: formData.companyInfo.currencyCode,
    ledgerAccountId: formData.chartOfAccountDetailsId
  };

  var txList1 = {
    relationshipId: relationshipId,
    //dateCreated: getCurrentDateForBackend(new Date()),
    createdByUserId: createdByUserId,
    fyStartDate: finYear && finYear.fyStartDate && formatDateForBackend(finYear.fyStartDate),
    fyEndDate: finYear && finYear.fyEndDate && formatDateForBackend(finYear.fyEndDate),
    txType: 'Payment',
    instrumentNumber: formData.paymentModeDetail,
    instrumentDate: formatDateForBackend(formData.paymentDate),
    txDate: formatDateForBackend(formData.paymentDate),
    accountingEntry: 'DEBIT',
    paymentModeId: formData.paymentModeId,
    remarks: formData.remarks,
    expenseMasterId: formData.expenseMasterId,
    availableCredit: formData.availableCredit,
    amount: formData.amount,
    paymentModeDetail: formData.paymentModeDetail,
    paymentMode: formData.paymentModeName,
    bankDate: formatDateForBackend(new Date()),
    ledgerAccountId: (formData.creditorsLedger || {}).ledgerAccountId,
  };

  // var customerTxPayment = {
  //   relationshipId: relationshipId,
  //   txAmount: formData.amount,
  //   paymentModeDetail: formData.paymentModeDetail,
  //   fyStartDate: finYear && finYear.fyStartDate && formatDateForBackend(finYear.fyStartDate),
  //   fyEndDate: finYear && finYear.fyEndDate && formatDateForBackend(finYear.fyEndDate),
  //   status: 'Processed',
  //   statusColor: 'success',
  //   txDate: formatDateForBackend(formData.paymentDate),
  //   txType: 'Payment',
  //   invoiceNumber: formData.businessExpenseData.expenseNumber,
  //   expenseMasterId: formData.expenseMasterId,
  //   isMultiCurrency: formData.businessExpenseData.isMultiCurrency,
  //   currencyCode: formData.businessExpenseData.isMultiCurrency ? formData.businessExpenseData.foreignCurrency : formData.companyInfo.currencyCode,
  //   exchangeRate: formData.exchangeRate,
  //   remarks: formData.remarks,
  //   paymentModeId: formData.paymentModeId,
  //   dateCreated: getCurrentDateForBackend(new Date()),
  //   createdByUserId: createdByUserId,
  // }
  payload.fyStartDate = finYear && finYear.fyStartDate && formatDateForBackend(finYear.fyStartDate);
  payload.fyEndDate = finYear && finYear.fyEndDate && formatDateForBackend(finYear.fyEndDate);
  payload.remarks = formData.remarks;
  payload.coaTxPaymentList = [txList, txList1];
  payload.txDate = formatDateForBackend(formData.paymentDate);
  //payload.customerTxPaymentList = [customerTxPayment];

  payload.invoiceAmount = formData.businessExpenseData.expenseTotalAmount;
  payload.relationshipId = relationshipId;
  payload.chartOfAccountDetailsId = formData.chartOfAccountDetailsId;
  payload.currentBalanceDue = formData.dueAmount - (formData.amount || 0);
  payload.txType = 'Payment';
  payload.paymentModeId = formData.paymentModeId;
  payload.paymentModeName = formData.paymentModeName;
  payload.remarks = formData.remarks;
  payload.expenseMasterId = formData.expenseMasterId;
  payload.status = "Processed";
  payload.statusColor = " success";
  payload.invoiceNumber = formData.businessExpenseData.expenseNumber;
  payload.txNumber = formData.businessExpenseData.expenseNumber;
  payload.paymentAmount = formData.amount;
  payload.paymentModeDetail = formData.paymentModeDetail;
  payload.isMultiCurrency = formData.businessExpenseData.isMultiCurrency;
  payload.currencyCode = formData.businessExpenseData.isMultiCurrency ? formData.businessExpenseData.foreignCurrency : formData.companyInfo.currencyCode;
  payload.exchangeRate = formData.exchangeRate;
  payload.templateCode = formData.businessExpenseData.templateCode;
  payload.relationshipName = formData.businessExpenseData.relationshipName;
  payload.projectNumber = formData.businessExpenseData.projectNumber;
  payload.projectName = formData.businessExpenseData.projectName;
  payload.projectMasterId = formData.businessExpenseData.projectMasterId;
  payload.paymentSource = CONSTANTS.PAYMENT_SOURCE.MANUAL

  return payload;
}

export const getNextTxNumber = (payload) => {
  let date = payload.date ? getCurrentDateForBackend(payload.date) : getCurrentDateForBackend(new Date());
  return dispatch => {
    dispatch({ type: SUPPLIER_PAYMENT_ACTION_LIST.NEXT_SUPPLIER_PAYMENT_NUMBER_RESET});
    dispatch({ type: COMMON_ACTIONS.SHOW_DETAIL_LOADER });
    return axios
      .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/supplierPayment/getNextPaymentNumber?date=${date}&relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: SUPPLIER_PAYMENT_ACTION_LIST.NEXT_SUPPLIER_PAYMENT_NUMBER, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = SUPPLIER_PAYMENT_ACTION_LIST.NEXT_SUPPLIER_PAYMENT_NUMBER;
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_DETAIL_LOADER,
        });
      })
  }
}