import React, { Fragment } from "react";
import { Row, Col, Button, Checkbox, Input, Popover, Tooltip, Divider, } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import * as find from 'lodash.find';
import * as filter from 'lodash.filter';
import ReactHtmlParser from 'react-html-parser';
import { MultipleSelectDropdown } from "../../../../general/MultipleSelectDropdown";
import { Dropdown } from "../../../../general/Dropdown";
import { CustomAgGrid } from '../../../../general/CustomAgGrid';
import { AddAndRemoveColumn } from "../../../../general/AddAndRemoveColumn";
import { AG_GRID_CONSTANTS, GRID_PREFERENCE_CONSTANTS } from '../../../../../static/agGridConstants';
import { AG_GRID_CLASS_CONSTANTS } from '../../../../../static/cssClassConstants';
import { showToasterMessage, fixedDecimalAndFormateNumber, getMomentDateForUIReadOnly } from '../../../../../utils';
import {
    MODAL_TYPE, MODULE_CODED_VALUES, PERMISSION_VALUES, CONSTANTS
} from '../../../../../static/constants'
import { getLabelName } from '../../../../modal/modalBody/customerInfo';
import { CustomTableUpload } from "../../../../general/UploadTable";
import CKEditor from "../../../../general/CustomCKEditor/ckeditor";
import { CUSTOM_CONFIGURATION } from "../../../../../static/ckEditorConfigConstants";

class CustomerProductDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sectionVisible: {
                discount: false,
                tax: false,
                expense: false
            },
            total: 0,
            subtotal: 0,
            subtotalWithoutDiscount: 0,
            totalDiscount: 0,
            totalTax: 0,
            itemLevelTaxes: [],
            rootDiscountPercent: 0,
            rootDiscountAmount: 0,
        }
    }

    getGrandTotal = () => {
        let grandTotal = 0;
        if (this.props.selectedSalesOrder.length) {
            this.props.selectedSalesOrder.forEach((soData) => {
                grandTotal = grandTotal + Number(this.state["total" + soData.salesOrderMasterId] || 0)
            });
            return Number(grandTotal || 0).toFixed(2)
        } else return 0.00;
    }

    getTotalDiscount = () => {
        let totalDiscount = 0;
        if (this.props.selectedSalesOrder.length) {
            this.props.selectedSalesOrder.forEach((soData) => {
                totalDiscount = totalDiscount + Number(this.state["totalDiscount" + soData.salesOrderMasterId] || 0)
            });
            return Number(totalDiscount || 0).toFixed(2)
        } else return 0.00;
    }

    getGrandSubTotal = () => {
        let grandSubTotal = 0;
        if (this.props.selectedSalesOrder.length) {
            this.props.selectedSalesOrder.forEach((soData) => {
                grandSubTotal = grandSubTotal + Number(this.state["subtotalWithoutDiscount" + soData.salesOrderMasterId] || 0)
            });
            return Number(grandSubTotal || 0).toFixed(2)
        } else return 0.00;
    }

    getItemLevelTaxes = () => {
        let itemLevelTaxes = [];
        this.props.selectedSalesOrder.forEach((soData) => {
            if (this.state["itemLevelTaxes" + soData.salesOrderMasterId]) {
                itemLevelTaxes = itemLevelTaxes.concat(this.state["itemLevelTaxes" + soData.salesOrderMasterId]);
            }
        });
        return itemLevelTaxes;
    }

    calculateSubtotal = () => {
        if (this.props.selectedSalesOrder.length === 0) {
            let total = 0;
            let subtotal = 0;
            let totalDiscount = 0;
            let totalTax = 0;
            let itemLevelTaxes = [];
            let subtotalWithoutDiscount = 0;
            let rootDiscountAmount = 0;
            let self = this;

            if (!this.state.rootDiscountPercent && this.props.rootDiscountPercent) {
                this.setState({
                    rootDiscountPercent: this.props.rootDiscountPercent,
                    rootDiscountAmount: this.props.rootDiscountAmount,
                    expenseAmount: this.props.expenseAmount
                });
            }

            this.gridApi && this.gridApi.forEachNode(function (rowNode, index) {
                let stringifiedRowData = JSON.stringify(rowNode.data);
                let totalAmountOfColumn = 0;
                const rowdata = JSON.parse(stringifiedRowData);
                if (rowdata.qty && rowdata.rate) {
                    let taxObj = {
                        subtotal: 0,
                        taxPercent: 0,
                        taxAmount: 0
                    }
                    totalAmountOfColumn = Number(rowdata.qty) * Number(rowdata.rate);
                    subtotalWithoutDiscount = Number(totalAmountOfColumn) + Number(subtotalWithoutDiscount);
                    if (rowdata.discount) {
                        let discountAmount = ((Number(rowdata.discount || 0) * 0.01) * totalAmountOfColumn);
                        totalAmountOfColumn = totalAmountOfColumn - discountAmount;
                        totalDiscount = totalDiscount + discountAmount;
                    }


                    if (self.state.rootDiscountPercent || self.props.rootDiscountPercent) {
                        const rootDiscountPercent = self.state.rootDiscountPercent || self.props.rootDiscountPercent;
                        let itemLevelDiscountAmount = (Number(rootDiscountPercent) * 0.01) * totalAmountOfColumn;
                        totalAmountOfColumn = totalAmountOfColumn - itemLevelDiscountAmount;
                        totalDiscount = totalDiscount + itemLevelDiscountAmount
                        rootDiscountAmount = rootDiscountAmount + itemLevelDiscountAmount;
                    }

                    if (rowdata.tax) {
                        taxObj.taxPercent = Number(rowdata.tax || 0).toFixed(2);
                        taxObj.taxName = rowdata.taxName;
                        let taxAmount = ((Number(rowdata.tax || 0) * 0.01) * totalAmountOfColumn);
                        totalTax = totalTax + taxAmount;
                    }
                    if (taxObj.taxPercent) {
                        taxObj.subtotal = Number(totalAmountOfColumn || 0).toFixed(2);
                        const availableTaxObj = find(itemLevelTaxes, { taxPercent: taxObj.taxPercent });
                        if (availableTaxObj) {
                            itemLevelTaxes = filter(itemLevelTaxes, (ele) => {
                                if (ele.taxPercent === taxObj.taxPercent) {
                                    ele.subtotal = (Number(ele.subtotal || 0) + Number(taxObj.subtotal || 0)).toFixed(2);
                                    ele.taxAmount = (Number(ele.taxPercent || 0) * 0.01) * Number(ele.subtotal || 0);
                                }
                                return true;
                            });
                        } else {
                            taxObj.taxAmount = ((Number(taxObj.taxPercent || 0) * 0.01) * Number(taxObj.subtotal || 0)).toFixed(2);
                            itemLevelTaxes.push(taxObj);
                        }
                    }
                    subtotal = Number(subtotal) + totalAmountOfColumn;
                }
            });


            total = Number(subtotal || 0) + totalTax + Number(this.state.expenseAmount || this.props.expenseAmount || 0);

            this.setState({
                total: Number(total || 0).toFixed(2),
                subtotal: Number(subtotal || 0).toFixed(2),
                totalDiscount: Number(totalDiscount || 0).toFixed(2),
                totalTax: Number(totalTax || 0).toFixed(2),
                subtotalWithoutDiscount: Number(subtotalWithoutDiscount || 0).toFixed(2),
                itemLevelTaxes,
                rootDiscountAmount: Number(rootDiscountAmount || 0).toFixed(2)
            });

            this.props.updateState({
                rootDiscountPercent: 0,
                rootDiscountAmount: 0,
                expenseAmount: 0
            })
        } else {
            this.props.selectedSalesOrder.forEach((soData) => {
                let total = 0;
                let subtotal = 0;
                let totalDiscount = 0;
                let totalTax = 0;
                let itemLevelTaxes = [];
                let subtotalWithoutDiscount = 0;
                let rootDiscountAmount = 0;
                let self = this;

                if (!this.state["rootDiscountPercent" + soData.salesOrderMasterId] && this.props["rootDiscountPercent" + soData.salesOrderMasterId]) {
                    const payload = {};
                    payload["rootDiscountPercent" + soData.salesOrderMasterId] = this.props["rootDiscountPercent" + soData.salesOrderMasterId];
                    payload["rootDiscountAmount" + soData.salesOrderMasterId] = this.props["rootDiscountAmount" + soData.salesOrderMasterId];
                    this.setState(payload);
                }

                if (!this.state["expenseAmount" + soData.salesOrderMasterId] && this.props["expenseAmount" + soData.salesOrderMasterId]) {
                    const payload = {};
                    payload["expenseAmount" + soData.salesOrderMasterId] = this.props["expenseAmount" + soData.salesOrderMasterId];
                    this.setState(payload);
                }

                this["gridApi-" + soData.salesOrderMasterId] && this["gridApi-" + soData.salesOrderMasterId].forEachNode(function (rowNode, index) {
                    let stringifiedRowData = JSON.stringify(rowNode.data);
                    let totalAmountOfColumn = 0;
                    const rowdata = JSON.parse(stringifiedRowData);
                    if (rowdata.qty && rowdata.rate) {
                        let taxObj = {
                            subtotal: 0,
                            taxPercent: 0,
                            taxAmount: 0
                        }
                        totalAmountOfColumn = Number(rowdata.qty) * Number(rowdata.rate);
                        subtotalWithoutDiscount = Number(totalAmountOfColumn) + Number(subtotalWithoutDiscount);
                        if (rowdata.discount) {
                            let discountAmount = ((Number(rowdata.discount || 0) * 0.01) * totalAmountOfColumn);
                            totalAmountOfColumn = totalAmountOfColumn - discountAmount;
                            totalDiscount = totalDiscount + discountAmount;
                        }


                        if (self.state["rootDiscountPercent" + soData.salesOrderMasterId] || self.props["rootDiscountPercent" + soData.salesOrderMasterId]) {
                            const rootDiscountPercent = self.state["rootDiscountPercent" + soData.salesOrderMasterId] || self.props["rootDiscountPercent" + soData.salesOrderMasterId];
                            let itemLevelDiscountAmount = (Number(rootDiscountPercent) * 0.01) * totalAmountOfColumn;
                            totalAmountOfColumn = totalAmountOfColumn - itemLevelDiscountAmount;
                            totalDiscount = totalDiscount + itemLevelDiscountAmount
                            rootDiscountAmount = rootDiscountAmount + itemLevelDiscountAmount;
                        }

                        if (rowdata.tax) {
                            taxObj.taxPercent = Number(rowdata.tax || 0).toFixed(2);
                            taxObj.taxName = rowdata.taxName;
                            let taxAmount = ((Number(rowdata.tax || 0) * 0.01) * totalAmountOfColumn);
                            totalTax = totalTax + taxAmount;
                        }
                        if (taxObj.taxPercent) {
                            taxObj.subtotal = Number(totalAmountOfColumn || 0).toFixed(2);
                            const availableTaxObj = find(itemLevelTaxes, { taxPercent: taxObj.taxPercent });
                            if (availableTaxObj) {
                                itemLevelTaxes = filter(itemLevelTaxes, (ele) => {
                                    if (ele.taxPercent === taxObj.taxPercent) {
                                        ele.subtotal = (Number(ele.subtotal || 0) + Number(taxObj.subtotal || 0)).toFixed(2);
                                        ele.taxAmount = (Number(ele.taxPercent || 0) * 0.01) * Number(ele.subtotal || 0);
                                    }
                                    return true;
                                });
                            } else {
                                taxObj.taxAmount = ((Number(taxObj.taxPercent || 0) * 0.01) * Number(taxObj.subtotal || 0)).toFixed(2);
                                itemLevelTaxes.push(taxObj);
                            }
                        }
                        subtotal = Number(subtotal) + totalAmountOfColumn;
                    }
                });


                total = Number(subtotal || 0) + totalTax + Number(this.state["expenseAmount" + soData.salesOrderMasterId] || this.props["expenseAmount" + soData.salesOrderMasterId] || 0);

                const stateToUpdate = {};
                stateToUpdate["total" + soData.salesOrderMasterId] = Number(total || 0).toFixed(2);
                stateToUpdate["subtotal" + soData.salesOrderMasterId] = Number(subtotal || 0).toFixed(2)
                stateToUpdate["totalDiscount" + soData.salesOrderMasterId] = Number(totalDiscount || 0).toFixed(2);
                stateToUpdate["subtotalWithoutDiscount" + soData.salesOrderMasterId] = Number(subtotalWithoutDiscount || 0).toFixed(2);
                stateToUpdate["itemLevelTaxes" + soData.salesOrderMasterId] = itemLevelTaxes;
                stateToUpdate["totalTax" + soData.salesOrderMasterId] = Number(totalTax || 0).toFixed(2);
                stateToUpdate["rootDiscountAmount" + soData.salesOrderMasterId] = Number(rootDiscountAmount || 0).toFixed(2);

                this.setState(stateToUpdate);

                const payloadReset = {};
                payloadReset["rootDiscountPercent" + soData.salesOrderMasterId] = 0;
                payloadReset["rootDiscountAmount" + soData.salesOrderMasterId] = 0;
                payloadReset["expenseAmount" + soData.salesOrderMasterId] = 0;
                this.props.updateState(payloadReset);
            })
        }
    }

    handleNewTaxAddition = (props, payload) => {
        const formData = payload.formData;
        const { showModal, companyInfo } = props;
        formData.relationshipId = companyInfo.relationshipId;
        this.gridApi && this.gridApi.stopEditing();
        const data = {
            title: <FormattedMessage id='addItem.text.newTax' defaultMessage='' />,
            formData,
            hideFooter: true,
            modalData: {
                modalType: MODAL_TYPE.NEW_TAX,
                data: payload,

            },
            wrapClassName: 'added-item-dropdown-modal',

            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                showModal(data);
            }
        };
        showModal(data);
    }

    addItemConfirmationModal = (props, text, modalType) => {
        const { showModal, companyInfo } = props;
        const labelName = getLabelName(modalType);
        const modalBody = <span>
            <FormattedMessage
                id='addItem.confirmation'
                values={{ value: `${text} to ` }}
                defaultMessage={``}
            />
            {labelName} ?
        </span>;

        let addFunc;

        switch (modalType) {
            case MODAL_TYPE.UOM: {
                addFunc = props.addUOM;
                break;
            }
            case MODAL_TYPE.EXPENSE_TYPE: {
                addFunc = props.addExpenseTypes;
                break;
            }
            default: {
                addFunc = () => {
                }
            }
        }

        const modalData = {
            modalBody,
            handleSubmit: () => {
                addFunc({ text: text, relationshipId: companyInfo.relationshipId });
                this.gridApi && this.gridApi.stopEditing();
                props.hideModal();
            },
        };
        showModal(modalData);
    }

    getModalTitle = (newTextObj, modalType) => {
        let title;

        switch (modalType) {
            case MODAL_TYPE.UOM: {
                title = <FormattedMessage id='addItem.text.uom' defaultMessage='' />;
                break;
            }
            case MODAL_TYPE.EXPENSE_TYPE: {
                title = <FormattedMessage id='addItem.text.expenseType' defaultMessage='' />;
                break;
            }
            default:
                title = <FormattedMessage id='signup.confirmation.title' defaultMessage='' />;
        }
        return title;
    }

    handleNewItemAddition = (props, payload, modalType) => {
        const formData = payload.formData;
        const { showModal } = props;

        if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
            this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
        } else {
            const data = {
                title: this.getModalTitle(payload, modalType),
                formData,
                modalData: { modalType, data: payload },
                handleSubmit: (formData = {}) => {
                    if (formData.textBoxValue && formData.textBoxValue.length) {
                        this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
                    } else {
                        data.formData.submittedOnce = true;
                        showModal(data);
                    }
                }
            };
            showModal(data);
        }
    }

    deleteClickHandler = (props, modalType, payload) => {
        const { showModal, companyInfo } = props;
        this.gridApi && this.gridApi.stopEditing();
        const modalBody =
            <FormattedMessage
                id='deleteItem.confirmation'
                defaultMessage={``}
                values={{ value: payload.text }}
            />;

        let deleteFunc;

        switch (modalType) {
            case MODAL_TYPE.UOM: {
                deleteFunc = props.deleteUOM;
                break;
            }
            case MODAL_TYPE.NEW_TAX: {
                deleteFunc = props.deleteTax;
                break;
            }
            case MODAL_TYPE.EXPENSE_TYPE: {
                deleteFunc = props.deleteExpenseTypes;
                break;
            }
            default: {
                deleteFunc = () => {
                }
            }
        }

        const modalData = {
            modalBody,
            handleSubmit: () => {
                deleteFunc({ id: payload.id, relationshipId: companyInfo.relationshipId })
            },
        };
        showModal(modalData);
    }

    render() {
        //const { txColumnSetting } = this.props;
        const self = this;
        if (this.gridApi && this.props.priceTypeToApply && this.props.isToPriceTypeToApply) {
            let self = this;
            this.gridApi.forEachNode(function (rowNode, index) {
                if (rowNode.data.product) {
                    let rate = self.props.priceTypeToApply === AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PRICE_TYPE_LIST[0] ? Number(rowNode.data.variant.retailPrice || 0).toFixed(2) : Number(rowNode.data.variant.wholesalePrice || 0).toFixed(2);
                    rowNode.setData({ ...rowNode.data, rate, baseRate: rate });
                }
            });
            this.props.updateState({
                isToPriceTypeToApply: false
            })
        }

        const { sectionVisible, itemLevelTaxes } = this.state;
        const isPlaceOfSupplySameAsRelationship = (this.props.relationshipBillingAddress || {}).stateName === this.props.placeOfSupply;
        const onGridReady = (params) => {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;
            let txColumnSetting = this.props.txColumnSetting;
            let actionIndex = 0;
            let amountIndex = 0;
            if (Object.keys(txColumnSetting).length) {
                txColumnSetting.forEach((e, index) => {
                    let columnName = e.colId.split("_")[0];
                    if (columnName !== 'action' && columnName !== 'amount') {
                        if (columnName === 'isChecked' || columnName === 'sno') {
                            e.pinned = 'left';
                            e.hide = false;
                        } else {
                            e.hide = _isColumnToHide(columnName);
                        }
                    }
                    if (columnName === 'action') {
                        e.width = 52;
                        actionIndex = index;
                        e.hide = false;
                        e.pinned = 'right';
                    }
                    if (columnName === 'amount') {
                        e.hide = false;
                        e.lockPosition = true;
                        amountIndex = index;
                        e.pinned = 'right';
                    }

                });
                const actionObj = txColumnSetting[actionIndex];
                const amountObj = txColumnSetting[amountIndex];
                txColumnSetting = txColumnSetting.filter((e) => {
                    let columnName = e.colId.split("_")[0];
                    return columnName !== 'action' && columnName !== 'amount';
                });
                amountObj.lockPosition = true;
                actionObj.lockPosition = true;
                txColumnSetting.push(amountObj);
                txColumnSetting.push(actionObj);
                this.gridColumnApi.setColumnState(txColumnSetting);
                setTimeout(() => {
                    if (this.gridColumnApi && this.gridColumnApi.columnController.bodyWidth < this.gridColumnApi.columnController.scrollWidth) {
                        params.api.sizeColumnsToFit();
                    }
                }, 500);
            }
        }

        const onModelUpdated = (params) => {
            if (this.props.tableDataReRendered) {
                setTimeout(() => {
                    this.calculateSubtotal();
                }, 1500)
                params.api.resetRowHeights();
                this.props.updateState({
                    tableDataReRendered: false
                })
                let actionIndex = 0;
                let amountIndex = 0;
                let txColumnSetting = this.props.txColumnSetting && this.props.txColumnSetting.length ? this.props.txColumnSetting : params.columnApi.getColumnState();
                txColumnSetting.forEach((e, index) => {
                    let columnName = e.colId.split("_")[0];
                    if (columnName !== 'action' && columnName !== 'amount') {
                        if (columnName === 'isChecked' || columnName === 'sno') {
                            e.pinned = 'left';
                            e.lockPosition = true;
                            e.hide = false;
                        } else {
                            e.hide = _isColumnToHide(columnName);
                        }
                    }
                    if (columnName === 'amount') {
                        e.hide = false;
                        amountIndex = index;
                        e.lockPosition = true;
                        e.pinned = 'right';
                    }
                    if (columnName === 'action') {
                        actionIndex = index;
                        e.width = 52;
                        e.hide = false;
                        e.pinned = 'right';
                    }
                });
                const actionObj = txColumnSetting[actionIndex];
                const amountObj = txColumnSetting[amountIndex];
                txColumnSetting = txColumnSetting.filter((e) => {
                    let columnName = e.colId.split("_")[0];
                    return columnName !== 'action' && columnName !== 'amount';
                });
                amountObj.lockPosition = true;
                actionObj.lockPosition = true;
                txColumnSetting.push(amountObj);
                txColumnSetting.push(actionObj);
                params.columnApi.setColumnState(txColumnSetting);
                setTimeout(() => {
                    if (this.gridColumnApi && this.gridColumnApi.columnController.bodyWidth < this.gridColumnApi.columnController.scrollWidth) {
                        params.api.sizeColumnsToFit();
                    }
                }, 500);
            }
        }


        this.gridApis = [];
        const content = <AddAndRemoveColumn {...this.props} />

        const _isColumnToHide = (columnName) => {
            // if (this.props.forceHideTxColumn && this.props.forceHideTxColumn.indexOf(columnName) > -1) {
            //     return true;
            // }
            return (this.props.txColumns.indexOf(columnName) < 0 && this.props.txMandatoryColumns.indexOf(columnName) < 0) ? true : false;
        }


        const columnDefs = [
            {
                headerComponentFramework: () => {
                    return "#";
                },
                headerClass: "pointer-none",
                cellRendererFramework: (params) => {
                    return params.node.rowIndex + 1
                },
                field: "sno",
                rowDrag: true,
                pinned: true,
                suppressMovable: true,
                lockPosition: true,
                colId: "sno_1",
                hide: false,
                minWidth: 75,
                maxWidth: 75,
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='customer.salesInvoice.form.gridHeader.product'
                        defaultMessage='' />;
                },
                field: AG_GRID_CONSTANTS.COMMON.PRODUCT_FIELD,
                colId: AG_GRID_CONSTANTS.COMMON.PRODUCT_FIELD + "_1",
                resizable: true,
                minWidth: 120,
                suppressMovable: true,
                editable: !this.props.isSoConversionToInvoice,
                sortable: true,
                permissions: this.props.permissions,
                moduleName: MODULE_CODED_VALUES.PRODUCTS,
                operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
                hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.PRODUCT),
                cellEditor: 'customTreeDropDown',
                cellRendererFramework: (params) => {
                    setTimeout(() => {
                        if (params.reactContainer && params.reactContainer.scrollHeight >= (params.node.data.maxHeight || 30)) {
                            params.node.setRowHeight(params.reactContainer.scrollHeight);
                            params.node.data.minHeight = params.reactContainer.scrollHeight > 30 ? params.reactContainer.scrollHeight : 30;
                            params.api && params.api.onRowHeightChanged();
                        } else {
                            params.node.data.minHeight = params.reactContainer.scrollHeight > 30 ? params.reactContainer.scrollHeight : 30;
                        }
                    }, 500)
                    return ReactHtmlParser(params.data.product)
                },
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                    return gridShouldDoNothing;
                },
                cellEditorParams: (obj) => {
                    return {
                        lastColumnCell: false,
                        options: this.props.products,
                        onSearch: this.props.onSearch,
                        onAddNewClick: () => {
                            self.props.updateHeaderState({
                                inventory: {
                                    inventoryDrawerVisible: true
                                }
                            });
                        },
                        canAddNew: true,
                        permissions: this.props.permissions,
                        moduleName: MODULE_CODED_VALUES.PRODUCTS,
                        operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
                        value: obj.node.data.selectedProductValue || [],
                        selectedLabel: obj.node.data.product || "",
                        allowClear: true,
                        onSelect: (selectedValue) => {
                            //obj.node.setDataValue(obj.colDef.field, selectedValue);
                            obj.node.data.selectedProductValue = obj.node.selectedProductValue;
                            obj.node.data.productObj = null;
                            obj.node.data.variant = null;
                            const itemId = obj.node.data.selectedProductValue && obj.node.data.selectedProductValue[0];
                            if (itemId) {
                                const variantId = obj.data.selectedProductValue && obj.data.selectedProductValue.length === 2 ? obj.data.selectedProductValue[1] : 0;
                                obj.node.data.productObj = find(this.props.products, { itemId: Number(itemId) }) || {};
                                obj.node.data.variant = variantId ? find(obj.node.data.productObj.inventoryItemVariantList, { variantId: Number(variantId) }) : obj.node.data.productObj.inventoryItemVariantList[0];
                            }
                            if (this.props.priceTypeToApply && obj.node.data.variant && !this.props.isDataToUpdate) {
                                const rate = this.props.priceTypeToApply === AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PRICE_TYPE_LIST[0] ? Number(obj.node.data.variant.retailPrice || 0).toFixed(2) : Number(obj.node.data.variant.wholesalePrice || 0).toFixed(2);
                                obj.node.data.baseRate = Number(rate);
                                obj.node.data.rate = Number(rate);
                                //obj.node.setDataValue('specialDiscount', 0);
                                //obj.node.setDataValue('baseRate', Number(rate));
                                //obj.node.setDataValue('rate', Number(rate));
                                //obj.node.setDataValue('qty', Number(obj.node.data.variant.currentStock));
                                //obj.node.setDataValue('uom', obj.node.data.variant.uomName);
                                obj.node.data.uom = obj.node.data.variant.uomName;
                                if (obj.node.data.productObj.isPopulateTax && !_isColumnToHide(GRID_PREFERENCE_CONSTANTS.TAX)) {
                                    const defaultItemHsnAndTax = obj.node.data.productObj.inventoryItemDefaultTaxList[0] || {};
                                    obj.node.data.hsnCode = ((defaultItemHsnAndTax || {}).hsnCode);
                                    let selectedTax = {};
                                    if (defaultItemHsnAndTax.taxSingleRateId) {
                                        selectedTax = find(this.props.taxes, { taxSingleRateId: defaultItemHsnAndTax.taxSingleRateId }) || {};
                                    } else {
                                        selectedTax = find(this.props.taxes, { taxPercent: ((defaultItemHsnAndTax || {}).igstRate || (defaultItemHsnAndTax || {}).cgstRate || (defaultItemHsnAndTax || {}).globleTaxRate) }) || {};
                                    }
                                    obj.node.data.taxName = selectedTax.taxName;
                                    obj.node.data.taxApplied = selectedTax.taxNameDisplay;
                                    obj.node.data.tax = selectedTax.taxPercent;
                                    obj.node.data.taxId = selectedTax.taxSingleRateId;
                                }
                            }
                            let isDuplicateItem = false;
                            let isOutOfStock = false
                            if (obj.node.data.variant && obj.node.data.variant.currentStock <= 0) {
                                isOutOfStock = true;
                                showToasterMessage({
                                    messageType: 'error', description: 'Selected product is out of stock'
                                });
                            }
                            obj.api && obj.api.forEachNode((rowNode, index) => {
                                const { data } = rowNode;
                                if (data.product === selectedValue && index !== obj.node.rowIndex) {
                                    isDuplicateItem = true;
                                }
                            });
                            if (isDuplicateItem) {
                                showToasterMessage({
                                    messageType: 'warning', description: 'Duplicate product is selected'
                                });
                            }

                            obj.node.data.isDuplicateItem = isDuplicateItem;
                            obj.node.data.isOutOfStock = isOutOfStock;

                        }
                    }
                },
                cellClassRules: {
                    // apply green to 2008
                    'ag-grid-cell-error-boundary': function (obj) {
                        return obj.node.data.variant && obj.node.data.variant.currentStock <= 0;
                    },
                    'ag-grid-cell-warning-boundary': function (obj) {
                        let isDuplicateItem = false;
                        self.gridApi && self.gridApi.forEachNode((rowNode, index) => {
                            const { data } = rowNode;
                            if (data.product && data.product === obj.node.data.product && obj.node.rowIndex !== index) {
                                isDuplicateItem = true;
                            }
                        });
                        return isDuplicateItem;
                    }
                }
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='customer.salesInvoice.form.gridHeader.description'
                        defaultMessage='' />;
                },
                field: AG_GRID_CONSTANTS.COMMON.DESCRIPTION_FIELD,
                editable: true,
                minWidth: 120,
                suppressMovable: true,
                sortable: true,
                resizable: true,
                cellClass: " ag-cell-description",
                hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.DESCRIPTION),
                colId: GRID_PREFERENCE_CONSTANTS.DESCRIPTION + "_1",
                cellEditor: 'CKEditorGrid',
                cellEditorParams: (params) => {
                    return {
                        value: params.node.data.description || "",
                        isEditorLoaded: this.props.isEditorLoaded,
                        onChange: (value) => {
                            params.node.data[params.colDef.field] = value;
                            params.node.data.isEditorLoaded = true;
                            this.props.updateState({
                                isEditorLoaded: true
                            })
                        }
                    }
                },
                cellRendererFramework: (params) => {
                    setTimeout(() => {
                        if (params.reactContainer && params.reactContainer.scrollHeight > 30 && params.reactContainer.scrollHeight >= (params.node.data.minHeight || 0)) {
                            params.eParentOfValue.style.height = 'inherit';
                            params.eParentOfValue.style.height = params.reactContainer.scrollHeight;
                            params.node.data.maxHeight = params.reactContainer.scrollHeight;
                            params.node.setRowHeight(params.reactContainer.scrollHeight);
                            params.api && params.api.onRowHeightChanged();
                        }
                    }, 200)
                    return ReactHtmlParser(params.data.description)
                },
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    const gridShouldDoNothing = params.event.target.value && params.editing && keyCode === KEY_ENTER;
                    params.node.data.scrollHeight = `${params.event.target.scrollHeight}px`;
                    if (params.event.target.scrollHeight > (params.data.minHeight || 30)) {
                        if (params.event.path && params.event.path[2]) {
                            params.event.path[2].style.height = 'inherit';
                            params.event.path[2].style.height = `${params.event.target.scrollHeight}px`;
                        }
                        params.node.setRowHeight(params.event.target.scrollHeight);
                        params.api && params.api.onRowHeightChanged();
                    } else {
                        if (params.event.path && params.event.path[2]) {
                            params.event.path[2].style.height = 'inherit';
                            params.event.path[2].style.height = `${(params.node.data.minHeight || 30)}px`;
                        }
                        params.node.setRowHeight((params.node.data.minHeight || 30));
                        params.api && params.api.onRowHeightChanged();
                    }
                    return gridShouldDoNothing;
                }
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='customer.salesInvoice.form.gridHeader.qty' defaultMessage='' />;
                },
                field: AG_GRID_CONSTANTS.COMMON.QTY_FIELD,
                colId: GRID_PREFERENCE_CONSTANTS.QUANTITY + "_1",
                editable: true,
                sortable: true,
                suppressMovable: true,
                minWidth: 80,
                maxWidth: 100,
                resizable: true,
                cellEditor: 'customNumberEditor',
                hide: this.props.isSoConversionToInvoice,
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='customer.salesInvoice.form.gridHeader.qtyOrdered'
                        defaultMessage='' />;
                },
                field: 'quantityOrdered',
                editable: !this.props.isSoConversionToInvoice,
                sortable: true,
                suppressMovable: true,
                minWidth: 80,
                maxWidth: 100,
                resizable: true,
                cellEditor: 'customNumberEditor',
                hide: this.props.isShipment || !(this.props.isSoConversionToInvoice || this.props.isSoConversion),
                colId: "quantityOrdered_1",
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='customer.salesInvoice.form.gridHeader.qtyInvoiced'
                        defaultMessage='' />;
                },
                field: 'quantityInvoiced',
                editable: !this.props.isSoConversionToInvoice,
                sortable: true,
                suppressMovable: true,
                minWidth: 80,
                maxWidth: 100,
                resizable: true,
                cellEditor: 'customNumberEditor',
                hide: this.props.isShipment || !(this.props.isSoConversionToInvoice || this.props.isSoConversion),
                colId: "quantityInvoiced_1",
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='customer.salesInvoice.form.gridHeader.qtyToInvoice'
                        defaultMessage='' />;
                },
                field: AG_GRID_CONSTANTS.COMMON.QTY_FIELD,
                colId: "qtyToInvoiced_1",
                editable: true,
                sortable: true,
                suppressMovable: true,
                minWidth: 80,
                maxWidth: 100,
                resizable: true,
                cellEditor: 'customNumberEditor',
                hide: this.props.isShipment || !this.props.isSoConversionToInvoice,
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='customer.salesInvoice.form.gridHeader.qty'
                        defaultMessage='' />;
                },
                field: AG_GRID_CONSTANTS.COMMON.QTY_FIELD,
                colId: AG_GRID_CONSTANTS.COMMON.QTY_FIELD + "_1",
                resizable: true,
                sortable: true,
                suppressMovable: true,
                minWidth: 80,
                maxWidth: 100,
                cellEditor: 'customNumberEditor',
                hide: !this.props.isShipment,
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='customer.salesInvoice.form.gridHeader.uom' defaultMessage='' />;
                },
                field: AG_GRID_CONSTANTS.COMMON.UOM_FIELD,
                editable: true,
                sortable: true,
                minWidth: 100,
                maxWidth: 120,
                suppressMovable: true,
                cellEditor: 'customDropDownEditor',
                colId: GRID_PREFERENCE_CONSTANTS.UOM + "_1",
                resizable: true,
                hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.UOM),
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                    return gridShouldDoNothing;
                },
                cellEditorParams: (obj) => {
                    return {
                        lastColumnCell: false,
                        items: this.props.uomList,
                        optionKeyName: 'uomId',
                        valueKeyName: 'uomName',
                        canAddNew: true,
                        canDelete: true,
                        permissions: this.props.permissions,
                        moduleName: MODULE_CODED_VALUES.UOM,
                        operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
                        deleteClickHandler: (payload) => {
                            this.deleteClickHandler(this.props, MODAL_TYPE.UOM, payload);
                        },
                        onAddNewClick: (payload) => {
                            payload.formData = {
                                textBoxValue: payload.textEntered,
                                submittedOnce: false
                            }
                            this.handleNewItemAddition(this.props, payload, MODAL_TYPE.UOM)
                        },
                        onSelect: (selectedValue) => {
                            //obj.node.setDataValue(obj.colDef.field, selectedValue);
                        },
                        allowClear: true,
                        onClear: () => {
                            //obj.node.setDataValue(obj.colDef.field, undefined);
                        },
                    }
                },
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='customer.salesInvoice.form.gridHeader.hsn' defaultMessage='' />;
                },
                field: 'hsnCode',
                colId: GRID_PREFERENCE_CONSTANTS.HSN + "_1",
                resizable: true,
                minWidth: 100,
                maxWidth: 120,
                suppressMovable: true,
                editable: true,
                sortable: true,
                hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.HSN) || (this.props.companyInfo || {}).countryName !== 'India',
                cellEditor: 'customTextEditor',
                cellEditorParams: (obj) => {
                    return {
                        onChange: (value) => {
                            obj.node.data.hsnCode = value;
                            obj.api.refreshCells();

                        }
                    }
                },
            },
            {
                headerComponentFramework: () => {
                    return <div> <span>MRP</span><Tooltip placement="right" title={this.props.intl.formatMessage(
                        {
                            id: 'tooltip.originalRate',
                            defaultMessage: ''
                        })
                    } trigger="click">
                        <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                    </Tooltip></div>;
                },
                colId: GRID_PREFERENCE_CONSTANTS.BASE_PRICE + "_1",
                resizable: true,
                maxWidth: 140,
                minWidth: 100,
                field: "baseRate",
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
                hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.BASE_PRICE),
                valueGetter: (params) => {
                    return params.data.baseRate && Number(params.data.baseRate || 0).toFixed(2);
                }
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='customer.salesInvoice.form.gridHeader.rate' defaultMessage='' />;
                },
                field: AG_GRID_CONSTANTS.COMMON.RATE_FIELD,
                colId: GRID_PREFERENCE_CONSTANTS.RATE + "_1",
                resizable: true,
                maxWidth: 140,
                minWidth: 100,
                suppressMovable: true,
                editable: true,
                sortable: true,
                cellEditor: 'customNumberEditor',
                cellEditorParams: (obj) => {
                    return {
                        decimalDigitsLength: (this.props.companyInfo || {}).decimalDigitsLengthPrice,
                        onChange: (value) => {
                            obj.node.data.baseRate = value;
                            obj.node.data.rate = value;
                            obj.api.refreshCells();

                        }
                    }
                },
                valueGetter: (params) => {
                    if (params.data.specialDiscount && params.data.rate && Number(params.data.rate) !== 0) {
                        params.data.rate = params.data.baseRate - ((params.data.specialDiscount * .01) * params.data.baseRate);
                    }
                    if (!params.data.specialDiscount && params.data.baseRate) {
                        params.data.rate = params.data.baseRate;
                    }
                    if (this.props.priceListId && params.data.rate) {
                        params.data.rate = params.data.baseRate - (this.props.priceListType.toLowerCase() === 'markdown' ? (((params.data.specialDiscount || 0) * .01) * params.data.baseRate) + (this.props.priceListPercentage * .01) * params.data.baseRate : (((params.data.specialDiscount || 0) * .01) * params.data.baseRate) - (this.props.priceListPercentage * .01) * params.data.baseRate);
                    }
                    return params.data.rate && Number(params.data.rate || 0).toFixed((this.props.companyInfo || {}).decimalDigitsLengthPrice || 0);
                },
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='customer.salesInvoice.form.gridHeader.specialDiscount'
                        defaultMessage='' />;
                },
                field: AG_GRID_CONSTANTS.COMMON.SPL_DISCOUNT_FIELD,
                editable: true,
                sortable: true,
                minWidth: 115,
                maxWidth: 130,
                suppressMovable: true,
                hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT),
                cellEditor: 'customNumberEditor',
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
                cellEditorParams: (obj) => {
        
                    return {
                      decimalDigitsLength: (this.props.companyInfo || {})
                        .decimalDigitsLengthPrice,
                      maxValue: 100,
                      onChange: (value) => {
                        obj.node.data.specialDiscount = value;
                        obj.api.refreshCells();
                      },
                    };
                    },
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='customer.salesInvoice.form.gridHeader.discount'
                        defaultMessage='' />;
                },
                field: AG_GRID_CONSTANTS.COMMON.DISCOUNT_FIELD,
                editable: true,
                suppressMovable: true,
                sortable: true,
                minWidth: 115,
                maxWidth: 130,
                hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.DISCOUNT),
                colId: GRID_PREFERENCE_CONSTANTS.DISCOUNT + "_1",
                resizable: true,
                cellEditor: 'customNumberEditor',
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
                cellEditorParams: (obj) => {
                    return { 
                        decimalDigitsLength: (this.props.companyInfo || {}).decimalDigitsLengthPrice,
                        maxValue: 100,
                        onChange: (value) => {
                            obj.node.data.discount = value;
                            obj.api.refreshCells();
                        }
                    }
                  }
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='customer.salesQuote.form.gridHeader.tax' defaultMessage='' />;
                },
                field: 'taxApplied',
                editable: true,
                sortable: true,
                minWidth: 120,
                suppressMovable: true,
                hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.TAX),
                colId: GRID_PREFERENCE_CONSTANTS.TAX + "_1",
                resizable: true,
                cellEditor: 'customDropDownEditor',
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                    return gridShouldDoNothing;
                },
                cellEditorParams: (obj) => {
                    return {
                        lastColumnCell: true,
                        items: this.props.taxes,
                        optionKeyName: 'taxSingleRateId',
                        canAddNew: true,
                        canDelete: true,
                        permissions: this.props.permissions,
                        moduleName: MODULE_CODED_VALUES.TAXES,
                        operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
                        deleteClickHandler: (payload) => {
                            this.deleteClickHandler(this.props, MODAL_TYPE.NEW_TAX, payload);
                        },
                        onAddNewClick: (payload) => {
                            payload.formData = {
                                taxName: payload.textEntered,
                                submittedOnce: false
                            }
                            this.handleNewTaxAddition(this.props, payload, MODAL_TYPE.NEW_TAX)
                        },
                        valueKeyName: 'taxNameDisplay',
                        onSelect: (selectedValue, option) => {
                            const selectedTax = find(this.props.taxes, { taxSingleRateId: Number(option.key) });
                            obj.node.data.tax = selectedTax.taxPercent;
                            obj.node.data.taxName = selectedTax.taxName;
                            //obj.node.setDataValue(obj.colDef.field, selectedValue);
                            obj.node.data.taxId = selectedTax.taxSingleRateId;
                        },
                        allowClear: true,
                        onClear: () => {
                            obj.node.data.tax = null;
                            obj.node.data.taxId = null;
                            obj.node.data.taxApplied = null;
                            obj.node.data.taxName = null;
                            this.calculateSubtotal();
                        },
                    }
                }
            },
            {
                headerComponentFramework: () => {
                    return <div><FormattedMessage id='customer.salesInvoice.form.gridHeader.comment' defaultMessage='' />
                        <Tooltip placement="top" title={this.props.intl.formatMessage(
                            {
                                id: 'tooltip.warehouseDetail',
                                defaultMessage: ''
                            })
                        } trigger="click">
                            <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                        </Tooltip>
                    </div>
                },
                field: AG_GRID_CONSTANTS.COMMON.COMMENT_FIELD,
                editable: true,
                sortable: true,
                suppressMovable: true,
                hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.COMMENT),
                colId: GRID_PREFERENCE_CONSTANTS.COMMENT + "_1",
                resizable: true,
                cellEditor: 'CKEditorGrid',
                cellEditorParams: (params) => {
                    return {
                        value: params.node.data.comment || "",
                        isEditorLoaded: this.props.isEditorLoaded,
                        onChange: (value) => {
                            params.node.data[params.colDef.field] = value;
                            params.node.data.isEditorLoaded = true;
                            this.props.updateState({
                                isEditorLoaded: true
                            })
                        }
                    }
                },
                cellRendererFramework: (params) => {
                    return ReactHtmlParser(params.data.comment)
                },
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    const gridShouldDoNothing = params.event.target.value && params.editing && keyCode === KEY_ENTER;
                    params.node.data.scrollHeight = `${params.event.target.scrollHeight}px`;
                    if (params.event.target.scrollHeight > 30) {
                        if (params.event.path && params.event.path[2]) {
                            params.event.path[2].style.height = 'inherit';
                            params.event.path[2].style.height = `${params.event.target.scrollHeight}px`;
                        }
                        params.node.setRowHeight(params.event.target.scrollHeight);
                        params.api && params.api.onRowHeightChanged();
                    } else {
                        if (params.event.path && params.event.path[2]) {
                            params.event.path[2].style.height = 'inherit';
                            params.event.path[2].style.height = `${30}px`;
                        }
                        params.node.setRowHeight(30);
                        params.api && params.api.onRowHeightChanged();
                    }
                    return gridShouldDoNothing;
                }
            },
            {
                headerComponentFramework: () => {
                    return <div><FormattedMessage id='customer.salesInvoice.form.gridHeader.partNumber' defaultMessage='' />
                    </div>
                },
                field: AG_GRID_CONSTANTS.COMMON.PART_NUMBER_FIELD,
                suppressMovable: true,
                editable: true,
                sortable: true,
                hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.PART_NUMBER),
                resizable: true,
                colId: GRID_PREFERENCE_CONSTANTS.PART_NUMBER + "_1",
                cellEditor: 'customTextEditor'
            },

            {
                headerComponentFramework: () => {
                    return <div><FormattedMessage id='customer.salesInvoice.form.gridHeader.stockNumber' defaultMessage='' />
                    </div>
                },
                field: AG_GRID_CONSTANTS.COMMON.STOCK_NUMBER_FIELD,
                suppressMovable: true,
                editable: true,
                sortable: true,
                hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER),
                colId: GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER + "_1",
                resizable: true,
                cellEditor: 'customTextEditor'
            },
            {
                headerComponentFramework: () => {
                    return <div><FormattedMessage id='customer.salesInvoice.form.gridHeader.origin' defaultMessage='' />
                    </div>
                },
                field: AG_GRID_CONSTANTS.COMMON.ORIGIN_FIELD,
                editable: true,
                sortable: true,
                hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.ORIGIN),
                colId: GRID_PREFERENCE_CONSTANTS.ORIGIN + "_1",
                resizable: true,
                suppressMovable: true,
                cellEditor: 'customDropDownEditor',
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                    return gridShouldDoNothing;
                },
                cellEditorParams: (obj) => {
                    return {
                        lastColumnCell: false,
                        items: this.props.countries,
                        optionKeyName: 'countryId',
                        permissions: this.props.permissions,
                        moduleName: MODULE_CODED_VALUES.UOM,
                        operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
                        valueKeyName: 'countryName',
                        onSelect: (selectedValue) => {
                            //obj.node.setDataValue(obj.colDef.field, selectedValue);
                            obj.node.data.origin = selectedValue;
                        },
                        allowClear: true,
                        onClear: () => {
                            //obj.node.setDataValue(obj.colDef.field, undefined);
                            obj.node.data.origin = undefined;
                        },
                    }
                },
            },
            {
                headerComponentFramework: () => {
                    return <div><FormattedMessage id='customer.salesInvoice.form.gridHeader.hsCode' defaultMessage='' />
                    </div>
                },
                field: AG_GRID_CONSTANTS.COMMON.HS_CODE_FIELD,
                editable: true,
                sortable: true,
                suppressMovable: true,
                hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.HS_CODE),
                colId: GRID_PREFERENCE_CONSTANTS.HS_CODE + "_1",
                resizable: true,
                cellEditor: 'customTextEditor'
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='customer.salesInvoice.form.gridHeader.amount'
                        defaultMessage='' />;
                },
                field: AG_GRID_CONSTANTS.COMMON.AMOUNT_FIELD,
                pinned: 'right',
                minWidth: 120,
                width: 120,
                headerClass: "pointer-none",
                colId: AG_GRID_CONSTANTS.COMMON.AMOUNT_FIELD + "_1",
                resizable: true,
                lockPosition: true,
                suppressMovable: true,
                suppressNavigable: true,
                valueGetter: (params) => {
                    let totalAmountOfColumn = 0
                    if (params.data.qty && params.data.rate) {
                        totalAmountOfColumn = params.data.qty * params.data.rate;
                        if (params.data.discount) {
                            let discountAmount = ((params.data.discount * 0.01) * totalAmountOfColumn);
                            totalAmountOfColumn = totalAmountOfColumn - discountAmount;
                        }
                        if (params.data.tax) {
                            totalAmountOfColumn = ((params.data.tax * 0.01) * totalAmountOfColumn) + totalAmountOfColumn;
                        }
                        setTimeout(() => {
                            this.calculateSubtotal();
                        }, 100)
                    }
                    return totalAmountOfColumn ? fixedDecimalAndFormateNumber(totalAmountOfColumn) : "";
                },
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN

            },
            {
                headerComponentFramework: () => {
                    return <div className="cursor-pointer">
                        <Popover
                            content={content}
                            title={<div className="text-center"><FormattedMessage id='common.addRemove' defaultMessage='' /> </div>}
                            placement="left"
                            trigger="click"
                        >
                            <i className='fa fa-bars' />
                        </Popover>
                    </div>;
                },
                field: 'action',
                colId: "action_1",
                pinned: 'right',
                cellRenderer: this.props.isShipment ? '' : 'customActionEditor',
                minWidth: 50,
                maxWidth: 50,
                suppressMovable: true,
                lockPosition: true,
                suppressNavigable: true,
                cellRendererParams: (params) => {
                    return {
                        onClick: () => {
                            if (params.api.rowRenderer.lastRenderedRow > 0) {
                                // const sodata = params.api.getDisplayedRowAtIndex(params.node.rowIndex).data
                                // const selectedSalesOrder = this.props.selectedSalesOrder;
                                params.api.updateRowData({ remove: [params.api.getDisplayedRowAtIndex(params.node.rowIndex).data] });
                                // selectedSalesOrder.forEach((soDetailData) => {
                                //     if (sodata.salesOrderMasterId === soDetailData.salesOrderMasterId) {
                                //         const currentDetailData = [];
                                //         this["gridApi-" + sodata.salesOrderMasterId].forEachNode((rowNode) => {
                                //             currentDetailData.push(rowNode.data);
                                //         });
                                //         soDetailData.customerSalesOrderDetailsList = currentDetailData;
                                //     }
                                // });
                                // this.props.updateState({ selectedSalesOrder })
                            }
                            this.calculateSubtotal();
                        },
                    }
                }
            }
        ];


        const createInvoice = (isDraft) => {
            let isOutOfStock = false;
            let isRequiredFiledMissing = false;
              if (
                    (!self.props.customer || !self.props.customer.customerId) &&
                    !self.props.isCashSales
                  ) {
                    return showToasterMessage({
                      messageType: "error",
                      description: this.props.intl.formatMessage({
                        id: "missingCustomer.errorMsg",
                        defaultMessage: "",
                      }),
                    });
                  }
            if (self.props.selectedSalesOrder && self.props.selectedSalesOrder.length > 0) {
                self.props.selectedSalesOrder.forEach((soData) => {
                    soData.customerSalesOrderDetailsList.forEach((data, index) => {
                        if (data.isOutOfStock) {
                            isOutOfStock = data.isOutOfStock;
                        }
                        if ((Object.keys(data || {}).length || index === 0) && ((!data.productObj && !data.description) || !(Number(data.rate || 0)) || !(Number(data.qty || 0)))) {
                            isRequiredFiledMissing = true;
                        }

                    })
                })
            } else {
                self.gridApi.forEachNode((rowNode, index) => {
                    const { data } = rowNode;
                    if (data.isOutOfStock) {
                        isOutOfStock = data.isOutOfStock;
                    }
                    if ((Object.keys(data || {}).length || index === 0) && ((!data.product && !data.description) || !(Number(data.rate || 0)) || !(Number(data.qty || 0)))) {
                        isRequiredFiledMissing = true;
                    }

                })
                // this.props.customerSalesOrderDetailsList.forEach((data, index) => {
                //     if (data.isOutOfStock) {
                //         isOutOfStock = data.isOutOfStock;
                //     }
                //     if ((Object.keys(data || {}).length || index === 0) && ((!data.product && !data.description) || !data.rate || !data.qty)) {
                //         isRequiredFiledMissing = true;
                //     }

                // })
            }
            // this.gridApi.forEachNode((rowNode, index) => {
            //     const { data } = rowNode;
            //     if (data.isOutOfStock) {
            //         isOutOfStock = data.isOutOfStock;
            //     }
            //     if ((Object.keys(data || {}).length || index === 0) && (!data.product || !data.rate || !data.qty)) {
            //         isRequiredFiledMissing = true;
            //     }
            // });

            if (isOutOfStock && !isDraft) {
                this.gridApi.refreshCells({ force: true })
                return showToasterMessage({
                    messageType: 'error', description: 'Selected product is out of stock'
                });
            } else if (isRequiredFiledMissing && !isDraft) {
                return showToasterMessage({
                    messageType: 'error', description: 'Please select Product/Description, Quantity and Rate'
                });
            }
            const modalData = {
                modalBody: <FormattedMessage
                    id='customer.proformaInvoice.form.confirmation'
                    defaultMessage={``}
                />,
                handleSubmit: () => {
                    const payload = {
                        props: this.props,
                        gridApis: this.gridApis,
                        isPlaceOfSupplySameAsRelationship: isPlaceOfSupplySameAsRelationship,
                        state: this.state,
                        gridApi: this.gridApi,
                        grandTotal: this.getGrandTotal(),
                        isDraft: isDraft
                    }
                    if (isDraft) {
                        //this.props.createSalesInvoiceDraft(payload);
                    } else {
                        self.props.createPerformaInvoice(payload);
                    }
                    self.props.hideModal();
                    self.props.popModalFromStack();
                }
            };
            self.props.showModal(modalData);


        }



        if (this.props.toUpdatePriceList) {
            this.gridApi.refreshCells({ force: true });
            this.props.updateState({ toUpdatePriceList: false })
        }



        const getRowHeight = (params) => {
            const rowHeight = params.node.data.scrollHeight ? (Number(params.node.data.scrollHeight.split("px")[0]) - 30) : 30;
            return rowHeight < 30 ? 30 : rowHeight;
        }

        const saveColumnIndex = (columnState) => {
            let data = this.props.txPreferences || {};
            let gridPreference = {};
            data.relationshipId = (this.props.companyInfo || {}).relationshipId;
            data.clientUserAccountId = (this.props.userInfo || {}).userId;
            if (data.tablePreference) {
                gridPreference = JSON.parse(data.tablePreference);
            }
            gridPreference.columnSetting = columnState;
            gridPreference.columnList = this.props.txColumns;
            data.gridPreference = JSON.stringify(gridPreference);
            data.preferenceId = this.props.preferenceId;
            data.tablePreference = this.props.tablePreference;
            this.props.saveUserPrefrences(data);
        }

        const saveColumnWidth = (columnState, width) => {
            let data = this.props.txPreferences || {};
            let gridPreference = {};
            data.relationshipId = (this.props.companyInfo || {}).relationshipId;
            data.clientUserAccountId = (this.props.userInfo || {}).userId;
            if (data.gridPreference) {
                gridPreference = JSON.parse(data.gridPreference);
            }
            gridPreference.columnSetting = columnState;
            gridPreference.columnList = this.props.txColumns;
            data.gridPreference = JSON.stringify(gridPreference);
            data.preferenceId = this.props.preferenceId;
            data.tablePreference = this.props.tablePreference;
            this.props.saveUserPrefrences(data, this.props);
        }

        let moveEvent = {};
        const onColumnMoved = (event, isDragStopped) => {
            if (event.column) {
                moveEvent = event;
            }
            if (isDragStopped && moveEvent.column && moveEvent.column.colDef && moveEvent.source !== AG_GRID_CONSTANTS.EVENT_SOURCE.API) {
                saveColumnIndex(moveEvent.columnApi.getColumnState());
            }
        }

        const onColumnResized = (event, tablegridApi, tableGridColumnApi) => {
            const gridColumnApi = tableGridColumnApi || this.gridColumnApi;
            event.api.resetRowHeights();
            //const gridApi = tablegridApi || this.gridApi;
            if (event.type === 'columnResized' && event.source !== 'sizeColumnsToFit' && event.source !== 'api' && event.finished === true) {
                event.api.resetRowHeights();
                if (gridColumnApi && gridColumnApi.columnController.bodyWidth < gridColumnApi.columnController.scrollWidth) {
                    this.gridApi.sizeColumnsToFit();
                    // const columnArray = event.columnApi.getColumnState();
                    // for (let i = (columnArray.length - 1); i >= 0; i--) {
                    //     if (columnArray[i] && !columnArray[i].hide && columnArray[i].colId !== 'action_1') {
                    //         columnArray[i].width = columnArray[i].width + (gridColumnApi.columnController.scrollWidth - gridColumnApi.columnController.bodyWidth);
                    //         break;
                    //     }
                    // }
                    // saveColumnWidth(columnArray);
                    setTimeout(() => {
                        const columnArray = event.columnApi.getColumnState();
                        saveColumnWidth(columnArray);
                    }, 1000);
                } else {
                    saveColumnWidth(event.columnApi.getColumnState());
                }
            }
        }

        return (
            <Fragment>
                <div className="transaction-table">
                    {
                        this.props.selectedSalesOrder && this.props.selectedSalesOrder.map((soData, rootIndex) => {
                            return <div className="invoice-details-container transaction-table" key={rootIndex}>
                                {this.props.isShipmentConversion ?
                                    <Row>
                                        <Col span={3}>
                                            <strong>Shipment #</strong>
                                        </Col>
                                        <Col span={4} >
                                            {soData.shipmentNumber || ''}
                                        </Col>
                                        <Col span={3}>
                                            <strong>Shipment Date</strong>
                                        </Col>
                                        {
                                            soData.shipmentDate ?
                                                <Col span={6}>
                                                    {getMomentDateForUIReadOnly({date: soData.shipmentDate, format: CONSTANTS.DISPLAY_DATE_FORMAT})}
                                                </Col> : ''
                                        }
                                    </Row>
                                    :
                                    <Row>
                                        <Col span={3}>
                                            <strong>SO #</strong>
                                        </Col>
                                        <Col span={4} >
                                            {soData.soNumber || ''}
                                        </Col>
                                        <Col span={3}>
                                            <strong>Date Created</strong>
                                        </Col>
                                        {
                                            soData.salesOrderDate ?
                                                <Col span={6}>
                                                    {getMomentDateForUIReadOnly({date: soData.salesOrderDate, format: CONSTANTS.DISPLAY_DATE_FORMAT})}
                                                </Col> : ''
                                        }
                                    </Row>
                                }
                                {this.props.txColumns.length ? <CustomAgGrid
                                    getRowHeight={getRowHeight}
                                    onColumnResized={(event) => {
                                        onColumnResized(event, this["gridApi-" + soData.salesOrderMasterId], this["gridColumnApi-" + soData.salesOrderMasterId])
                                    }}
                                    onDragStopped={() => {
                                        onColumnMoved({}, true);
                                    }}
                                    //onColumnMoved={onColumnMoved}
                                    columnDefs={columnDefs}
                                    onModelUpdated={onModelUpdated}
                                    onGridReady={(params) => {
                                        this["gridApi-" + soData.salesOrderMasterId] = params.api;
                                        this["gridColumnApi-" + soData.salesOrderMasterId] = params.columnApi;
                                        const txColumnSetting = this.props.txColumnSetting;
                                        if (this.props.isColumnFitTable) {
                                            this["gridApi-" + soData.salesOrderMasterId].sizeColumnsToFit();
                                        }
                                        if (Object.keys(txColumnSetting).length) {
                                            txColumnSetting.forEach((e) => {
                                                let columnName = e.colId.split("_")[0];
                                                if (columnName === 'quantityOrdered') {
                                                    e.hide = this.props.isShipment || !(this.props.isSoConversionToInvoice || this.props.isSoConversion);
                                                } else if (columnName === 'quantityInvoiced') {
                                                    e.hide = this.props.isShipment || !(this.props.isSoConversionToInvoice || this.props.isSoConversion);
                                                }
                                                else if (columnName !== 'action') {
                                                    if (columnName === 'isChecked' || columnName === 'sno') {
                                                        e.hide = false;
                                                    } else {
                                                        e.hide = _isColumnToHide(columnName);
                                                        e.width = (this.props.txColumnSetting[columnName] || {}).width || 200;
                                                    }
                                                } if (columnName === 'action') {
                                                    e.width = 52;
                                                    e.hide = false;
                                                    e.pinned = 'right';
                                                }
                                            });
                                            params.columnApi.setColumnState(txColumnSetting);
                                        }

                                        params.api.sizeColumnsToFit();

                                    }}
                                    rowData={soData.customerSalesOrderDetailsList || [{}]}
                                    gridStyle={{
                                        width: '100%',
                                        height: '100%',
                                        marginBottom: '10px'
                                    }} /> : null}
                                <Col span={6}>
                                    {!this.props.isShipment ? 
                                <div style={{ display: "flex", gap: "10px" }}>
                                        <Button className="grey-button" onClick={
                                            () => {
                                                this["gridApi-" + soData.salesOrderMasterId].updateRowData({ add: [{}] });
                                            }
                                        }><FormattedMessage id='customer.salesInvoice.form.button.addLine'
                                            defaultMessage='' /></Button>
                                        <Button className="grey-button" onClick={
                                            () => {
                                                this.props.showModal({
                                                    modalBody: "Are you sure you want to clear?",
                                                    handleSubmit: () => {
                                                        let gridApi = this["gridApi-" + soData.salesOrderMasterId];
                                                        let self = this;
                                                        gridApi.forEachNode(function (rowNode, index) {
                                                            gridApi.updateRowData({ remove: [rowNode.data] });
                                                            if (gridApi.rowRenderer.lastRenderedRow < 0) {
                                                                gridApi.updateRowData({ add: [{}] });
                                                            }
                                                            self.calculateSubtotal();
                                                        })
                                                    }
                                                })
                                            }
                                        }><FormattedMessage id='customer.salesInvoice.form.button.clearAllLines'
                                            defaultMessage='' /></Button>
                                    </div> 
                                    : null}
                                </Col>

                                <Row>

                                    <Col span={6} >

                                        {this.props.selectedSalesOrder && this.props.selectedSalesOrder.length === 1 ? <div>
                                            <CustomTableUpload fileList={this.props.fileList} onChange={
                                                (fileList) => {
                                                    this.props.updateState({
                                                        fileList
                                                    })
                                                }
                                            } />

                                            <div className="remarks-title">
                                                <FormattedMessage id='customer.salesInvoice.form.bankDetails.label'
                                                    defaultMessage='' />
                                            </div>

                                            <MultipleSelectDropdown
                                                style={{ width: '94%' }}
                                                items={(this.props.companyInfo || {}).bankDetails || []}
                                                placeholder="Bank Details"
                                                mode='multiple'
                                                onDeSelect={(value) => {
                                                    let { selectedBankDetails } = this.props
                                                    selectedBankDetails = filter(selectedBankDetails, (taxId) => {
                                                        return taxId !== value;
                                                    });
                                                    this.props.updateState({ selectedBankDetails });
                                                }}
                                                valueKeyName='name'
                                                addressLineKey1='accountNumber'
                                                addressLineKey2='ifscCode'
                                                selectedValue={this.props.selectedBankDetails || []}
                                                optionKeyName='bankDetailId'
                                                onSelect={(bankDetailId) => {
                                                    let selectedBankDetails = this.props.selectedBankDetails || [];
                                                    if (selectedBankDetails.indexOf(bankDetailId) === -1) {
                                                        selectedBankDetails.push(bankDetailId);
                                                    } else {
                                                        selectedBankDetails = selectedBankDetails.filter((el) => {
                                                            return el !== selectedBankDetails
                                                        });
                                                    }
                                                    this.props.updateState({
                                                        selectedBankDetails
                                                    });
                                                }}
                                            />
                                            <div>
                                                <div className="remarks-title">
                                                    <FormattedMessage id='customer.salesInvoice.form.internalRemarks.label'
                                                        defaultMessage='' />
                                                </div>
                                                <CKEditor
                                                    type="inline"
                                                    className="description-textarea"
                                                    key={`${"internalRemarks"}`}
                                                    data={this.props.internalRemarks}
                                                    onInit={editor => {
                                                        this.editor = editor;
                                                    }}
                                                    onChange={(event) => {
                                                        const data = event.editor.getData();
                                                        this.props.updateState({ internalRemarks: data });
                                                    }}
                                                    config={CUSTOM_CONFIGURATION}
                                                />
                                            </div>
                                            <div className="remarks-title">
                                                <FormattedMessage id='customer.salesInvoice.form.customerRemarks.label'
                                                    defaultMessage='' />
                                            </div>

                                            {/* <TextArea maxLength={500} value={this.props.customerRemarks || ''} className="description-textarea" rows={4}
                                        onChange={(e) => {
                                            this.props.updateState({
                                                customerRemarks: e.target.value
                                            });
                                        }} placeholder={intl.formatMessage(
                                            {
                                                id: 'customer.salesInvoice.form.customerRemarks.placeholder',
                                                defaultMessage: 'tre gra                                            }
                                        )}></TextArea> */}

                                            {<CKEditor
                                                type="inline"
                                                key={`${"customerRemarks"}`}
                                                className="description-textarea"
                                                data={this.props.customerRemarks}
                                                onInit={editor => {
                                                    this.editor = editor;
                                                }}
                                                onChange={(event) => {
                                                    const data = event.editor.getData();
                                                    this.props.updateState({ customerRemarks: data });
                                                }}
                                                config={CUSTOM_CONFIGURATION}
                                            />}
                                        </div> : null
                                        }
                                    </Col>
                                    <Col span={12} offset={6}>
                                        <div className="checkbox-control-group">
                                            {<Checkbox checked={this.props["isRootDiscount" + soData.salesOrderMasterId]} onChange={() => {
                                                const state = {};
                                                state["isRootDiscount" + soData.salesOrderMasterId] = !this.props["isRootDiscount" + soData.salesOrderMasterId]
                                                this.props.updateState(state)
                                            }}> <FormattedMessage id='customer.salesInvoice.form.checkbox.discount'
                                                defaultMessage='' /></Checkbox>}
                                            {false && <Checkbox onChange={() => {
                                                this.setState({ sectionVisible: { ...sectionVisible, tax: !sectionVisible.tax } })
                                            }}><FormattedMessage id='customer.salesInvoice.form.checkbox.tax'
                                                defaultMessage='' /></Checkbox>}
                                            <Checkbox checked={this.props["isRootExpense" + soData.salesOrderMasterId]} onChange={() => {
                                                const state = {};
                                                state["isRootExpense" + soData.salesOrderMasterId] = !this.props["isRootExpense" + soData.salesOrderMasterId]
                                                this.props.updateState(state)
                                            }}><FormattedMessage id='customer.salesInvoice.form.checkbox.expense'
                                                defaultMessage='' /></Checkbox>
                                        </div>

                                        <div className="product-amount-details">
                                            <Row type="flex" justify="end" className="mb20">
                                                {
                                                    this.props["isRootDiscount" + soData.salesOrderMasterId]
                                                        ?
                                                        <Fragment>
                                                            <Col span={8}>
                                                                <span className="label"><FormattedMessage
                                                                    id='customer.salesInvoice.form.totalDiscount.label'
                                                                    defaultMessage='' /></span>
                                                            </Col>

                                                            <Col span={16}>
                                                                <div className="input-group">
                                                                    <div className="input-label-control">
                                                                        <span className="mark">%</span>
                                                                        <Input
                                                                            type='number'
                                                                            placeholder="0.00"
                                                                            value={this.state["rootDiscountPercent" + soData.salesOrderMasterId] || ""}
                                                                            onChange={(e) => {
                                                                                const payload = {};
                                                                                payload["rootDiscountPercent" + soData.salesOrderMasterId] = e.target.value;
                                                                                this.setState(payload);
                                                                            }}
                                                                            onBlur={(e) => {
                                                                                if (this.state["subtotal" + soData.salesOrderMasterId]) {
                                                                                    this.calculateSubtotal();
                                                                                }
                                                                            }
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="input-label-control">
                                                                        <span className="mark">{(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}</span>
                                                                        <Input
                                                                            disabled
                                                                            placeholder="0.00"
                                                                            type='number'
                                                                            value={this.state["rootDiscountAmount" + soData.salesOrderMasterId] || ""}
                                                                            onChange={(e) => {
                                                                                const payload = {};
                                                                                payload["rootDiscountAmount" + soData.salesOrderMasterId] = Number(e.target.value);
                                                                                this.setState(payload);
                                                                            }}
                                                                            onBlur={(e) => {
                                                                                if (this.state["subtotal" + soData.salesOrderMasterId]) {
                                                                                    this.calculateSubtotal();
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Fragment>
                                                        : ''
                                                }
                                            </Row>

                                            {
                                                this.props["isRootExpense" + soData.salesOrderMasterId]
                                                    ? <Fragment>
                                                        <Row type="flex" className='amount-summary mb20' justify="end">
                                                            <Col span={8}>
                                                                <FormattedMessage id='customer.salesInvoice.form.totalExpense.label'
                                                                    defaultMessage='' />
                                                            </Col>

                                                            <Col span={16}>
                                                                <Dropdown
                                                                    style={{ width: '100%' }}
                                                                    items={this.props.expenseTypes}
                                                                    valueKeyName='expenseType'
                                                                    value={this.props["expenseId" + soData.salesOrderMasterId]}
                                                                    canAddNew={true}
                                                                    canDelete={true}
                                                                    deleteClickHandler={(payload) => {
                                                                        this.deleteClickHandler(this.props, MODAL_TYPE.EXPENSE_TYPE, payload);
                                                                    }}
                                                                    onAddNewClick={(payload) => {
                                                                        payload.formData = {
                                                                            textBoxValue: payload.textEntered,
                                                                            submittedOnce: false
                                                                        }
                                                                        this.handleNewItemAddition(this.props, payload, MODAL_TYPE.EXPENSE_TYPE)
                                                                    }}
                                                                    onSelect={(expenseId) => {
                                                                        const selectedExpense = find(this.props.expenseTypes, { lkExpenseTypeId: Number(expenseId) })
                                                                        const payload = {};
                                                                        payload["expenseId" + soData.salesOrderMasterId] = expenseId;
                                                                        payload["expensName" + soData.salesOrderMasterId] = selectedExpense.expenseType;
                                                                        this.setState(payload);
                                                                        this.props.updateState(payload)
                                                                    }}
                                                                    optionKeyName='lkExpenseTypeId'
                                                                    allowClear={true}
                                                                    onClear={() => {
                                                                        this.props.updateState({
                                                                            expenseId: undefined,
                                                                            expensName: undefined
                                                                        })
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>

                                                        <Row type="flex" justify="end" className="mb20">
                                                            <Col span={8}>
                                                                <span className="label"><FormattedMessage
                                                                    id='customer.salesInvoice.form.totalExpenseAmount.label'
                                                                    defaultMessage='' /></span>
                                                            </Col>

                                                            <Col span={16}>
                                                                <div className="input-group">
                                                                    <div className="input-label-control">
                                                                        <span className="mark">{(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}</span>
                                                                        <Input
                                                                            placeholder="0.00"
                                                                            type='number'
                                                                            value={this.state["expenseAmount" + soData.salesOrderMasterId]}
                                                                            onChange={(e) => {
                                                                                const payload = {};
                                                                                payload["expenseAmount" + soData.salesOrderMasterId] = Number(e.target.value);
                                                                                this.setState(payload, () => {
                                                                                    if (this.state["subtotal" + soData.salesOrderMasterId]) {
                                                                                        this.calculateSubtotal();
                                                                                    }
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Fragment>
                                                    : ''
                                            }

                                            <div className="amount-summary">
                                                <Row>
                                                    <Col span={8}>
                                                        <span className="title"><FormattedMessage
                                                            id='customer.salesInvoice.form.subTotal.label' defaultMessage='' /></span>
                                                    </Col>
                                                    <Col className="" span={16}>
                                                        {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                                        {fixedDecimalAndFormateNumber(this.state["subtotalWithoutDiscount" + soData.salesOrderMasterId])}
                                                    </Col>
                                                </Row>
                                            </div>

                                            {!this.state["totalDiscount" + soData.salesOrderMasterId] || this.state["totalDiscount" + soData.salesOrderMasterId] === '0.00' || this.state["totalDiscount" + soData.salesOrderMasterId] === 0 ? null :
                                                <div className="amount-summary">
                                                    <Row>
                                                        <Col span={8}>
                                                            <span className="title"><FormattedMessage
                                                                id='customer.salesInvoice.form.totalDiscount.label'
                                                                defaultMessage='' /></span>
                                                        </Col>
                                                        <Col className="" span={16}>
                                                            {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                                            {this.state["totalDiscount" + soData.salesOrderMasterId]}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            }

                                            {
                                                this.state["itemLevelTaxes" + soData.salesOrderMasterId] && this.state["itemLevelTaxes" + soData.salesOrderMasterId].length === 0 ? null : this.state["itemLevelTaxes" + soData.salesOrderMasterId] && this.state["itemLevelTaxes" + soData.salesOrderMasterId].map((taxObj, i) => {
                                                    if (isPlaceOfSupplySameAsRelationship) {

                                                    }
                                                    return (
                                                        (this.props.companyInfo || {}).countryName === 'India' ? (isPlaceOfSupplySameAsRelationship ? <div key={i}>
                                                            <div className="amount-summary">
                                                                <Row>
                                                                    <Col span={8}>
                                                                        <span
                                                                            className="title">CGST @ {this.props.update || this.props.clone ?taxObj.taxPercent: taxObj.taxPercent / 2}% on {taxObj.subtotal} </span>
                                                                    </Col>
                                                                    <Col span={16}>
                                                                        {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                                                        {/* {(Number(taxObj.taxAmount || 0) / 2).toFixed(2)} */}
                                                                        {(this.props.update || this.props.clone ?Number(taxObj.taxAmount || 0): Number(taxObj.taxAmount || 0) / 2).toFixed(2)}
                                                                    </Col>
                                                                </Row>
                                                            </div>

                                                            <div className="amount-summary">
                                                                <Row>
                                                                    <Col span={8}>
                                                                        <span
                                                                            className="title">SGST @ {this.props.update || this.props.clone ?taxObj.taxPercent: taxObj.taxPercent / 2}% on {taxObj.subtotal} </span>
                                                                    </Col>
                                                                    <Col span={16}>
                                                                        {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                                                        {/* {(Number(taxObj.taxAmount || 0) / 2).toFixed(2)} */}
                                                                        {(this.props.update || this.props.clone ?Number(taxObj.taxAmount || 0): Number(taxObj.taxAmount || 0) / 2).toFixed(2)}
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </div> : <div key={i}>
                                                                <div className="amount-summary">
                                                                    <Row>
                                                                        <Col span={8}>
                                                                            <span
                                                                                className="title">IGST @ {taxObj.taxPercent}% on {taxObj.subtotal} </span>
                                                                        </Col>
                                                                        <Col span={16}>
                                                                            {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                                                            {(Number(taxObj.taxAmount || 0)).toFixed(2)}
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </div>) :
                                                            <div key={i}>
                                                                <div className="amount-summary">
                                                                    <Row>
                                                                        <Col span={8}>
                                                                            <span
                                                                                className="title">{taxObj.taxName} @ {taxObj.taxPercent}% on {taxObj.subtotal} </span>
                                                                        </Col>
                                                                        <Col span={16}>
                                                                            {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                                                            {(Number(taxObj.taxAmount || 0)).toFixed(2)}
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                    )
                                                })
                                            }

                                            <div className="amount-summary">
                                                <Row>
                                                    <Col span={8}>
                                                        <span className="title"><FormattedMessage
                                                            id='customer.salesInvoice.form.totalAmount.label'
                                                            defaultMessage='' /></span>
                                                    </Col>
                                                    <Col span={16}>
                                                        {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                                        {this.state["total" + soData.salesOrderMasterId]}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </Col>

                                </Row>

                            </div>
                        })}
                    {(!this.props.selectedSalesOrder || this.props.selectedSalesOrder.length === 0) &&
                        <div>{this.props.txColumns.length ? <CustomAgGrid
                            getRowHeight={getRowHeight}
                            onColumnResized={onColumnResized}
                            onDragStopped={() => {
                                onColumnMoved({}, true);
                            }}
                            //onColumnMoved={onColumnMoved}
                            columnDefs={columnDefs}
                            onGridReady={onGridReady}
                            onModelUpdated={onModelUpdated}
                            rowData={this.props.customerSalesOrderDetailsList || [{}]}
                            gridStyle={{
                                width: '100%',
                                height: '100%',
                                marginBottom: '10px'
                            }} /> : null}

                            <Row>
                                <Col span={12}>

                                    <div style={{ display: "flex", gap: "10px" }}>
                                        <Button className="grey-button" onClick={
                                            () => {
                                                this.gridApi.updateRowData({ add: [{}] });
                                            }
                                        }><FormattedMessage id='customer.salesInvoice.form.button.addLine'
                                            defaultMessage='' /></Button>
                                        <Button className="grey-button" onClick={
                                            () => {
                                                this.props.showModal({
                                                    modalBody: "Are you sure you want to clear?",
                                                    handleSubmit: () => {
                                                        let gridApi = this.gridApi;
                                                        let self = this;
                                                        this.gridApi.forEachNode(function (rowNode, index) {
                                                            gridApi.updateRowData({ remove: [rowNode.data] });
                                                            if (gridApi.rowRenderer.lastRenderedRow < 0) {
                                                                gridApi.updateRowData({ add: [{}] });
                                                            }
                                                            self.calculateSubtotal();
                                                        })
                                                    }
                                                })
                                            }
                                        }><FormattedMessage id='customer.salesInvoice.form.button.clearAllLines'
                                            defaultMessage='' /></Button>
                                    </div>
                                    <div className="upload-btn-so">
                                        <CustomTableUpload fileList={this.props.fileList} onChange={
                                            (fileList) => {
                                                this.props.updateState({
                                                    fileList
                                                })
                                            }
                                        } />
                                    </div>
                                </Col>
                            </Row>
                            <Divider style={{ margin: "10px 0px" }} />
                            <Row>
                                <Col span={12}>
                                    


                                    <div className="remarks-title">
                                        <FormattedMessage id='customer.salesInvoice.form.bankDetails.label'
                                            defaultMessage='' />
                                    </div>

                                    <MultipleSelectDropdown
                                        style={{ width: "60%" }}
                                        items={(this.props.companyInfo || {}).bankDetails || []}
                                        placeholder="Bank Details"
                                        mode='multiple'
                                        onDeSelect={(value) => {
                                            let { selectedBankDetails } = this.props
                                            selectedBankDetails = filter(selectedBankDetails, (taxId) => {
                                                return taxId !== value;
                                            });
                                            this.props.updateState({ selectedBankDetails });
                                        }}
                                        valueKeyName='name'
                                        addressLineKey1='accountNumber'
                                        addressLineKey2='ifscCode'
                                        selectedValue={this.props.selectedBankDetails || []}
                                        optionKeyName='bankDetailId'
                                        onSelect={(bankDetailId) => {
                                            let selectedBankDetails = this.props.selectedBankDetails || [];
                                            if (selectedBankDetails.indexOf(bankDetailId) === -1) {
                                                selectedBankDetails.push(bankDetailId);
                                            } else {
                                                selectedBankDetails = selectedBankDetails.filter((el) => {
                                                    return el !== selectedBankDetails
                                                });
                                            }
                                            this.props.updateState({
                                                selectedBankDetails
                                            });
                                        }}
                                    />

                                    <div>

                                        <div className="remarks-title">
                                            <FormattedMessage id='customer.salesInvoice.form.internalRemarks.label'
                                                defaultMessage='' />
                                        </div>
                                        <CKEditor
                                            type="inline"
                                            className="description-textarea"
                                            key={`${"internalRemarks"}`}
                                            data={this.props.internalRemarks}
                                            onInit={editor => {
                                                this.editor = editor;
                                            }}
                                            onChange={(event) => {
                                                const data = event.editor.getData();
                                                this.props.updateState({ internalRemarks: data });
                                            }}
                                            config={CUSTOM_CONFIGURATION}
                                        />

                                    </div>

                                    <div className="remarks-title">
                                        <FormattedMessage id='customer.salesInvoice.form.customerRemarks.label'
                                            defaultMessage='' />
                                    </div>

                                    {/* <TextArea maxLength={500} value={this.props.customerRemarks || ''} className="description-textarea" rows={4}
                                        onChange={(e) => {
                                            this.props.updateState({
                                                customerRemarks: e.target.value
                                            });
                                        }} placeholder={intl.formatMessage(
                                            {
                                                id: 'customer.salesInvoice.form.customerRemarks.placeholder',
                                                defaultMessage: or your business We truly appreciate your business, and we are grateful for the trust you have placed in us. If you have any concerns or questions, please do not hesitate to contact us.'
                                            }
                                        )}></TextArea> */}

                                    {<CKEditor
                                        type="inline"
                                        className="description-textarea"
                                        key={`${"customerRemarks"}`}
                                        data={this.props.customerRemarks}
                                        onInit={editor => {
                                            this.editor = editor;
                                        }}
                                        onChange={(event) => {
                                            const data = event.editor.getData();
                                            this.props.updateState({ customerRemarks: data });
                                        }}
                                        config={CUSTOM_CONFIGURATION}
                                    />}
                                </Col>
                                
                                <Col span={7} offset={5}>
                                    <div className="checkbox-control-group">
                                        {<Checkbox checked={this.props.isRootDiscount} onChange={() => {
                                            this.props.updateState({ isRootDiscount: !this.props.isRootDiscount })
                                        }}> <FormattedMessage id='customer.salesInvoice.form.checkbox.discount'
                                            defaultMessage='' /></Checkbox>}
                                        {false && <Checkbox onChange={() => {
                                            this.setState({ sectionVisible: { ...sectionVisible, tax: !sectionVisible.tax } })
                                        }}><FormattedMessage id='customer.salesInvoice.form.checkbox.tax'
                                            defaultMessage='' /></Checkbox>}
                                        <Checkbox checked={this.props.isRootExpense} onChange={() => {
                                            this.props.updateState({ isRootExpense: !this.props.isRootExpense })
                                        }}><FormattedMessage id='customer.salesInvoice.form.checkbox.expense'
                                            defaultMessage='' /></Checkbox>
                                    </div>

                                    <div style={{marginTop:"10px",paddingRight:"2px"}}>
                                        <Row>
                                            {
                                                this.props.isRootDiscount
                                                    ?
                                                    <Fragment>
                                                        <Col span={15}>
                                                            <span className="label"><FormattedMessage
                                                                id='customer.salesInvoice.form.totalDiscount.label'
                                                                defaultMessage='' /></span>
                                                        </Col>

                                                        <Col span={9}>
                                                            <div className="input-group">
                                                                <div className="input-label-control">
                                                                    <span className="mark">%</span>
                                                                    <Input
                                                                        type='number'
                                                                        placeholder="0.00"
                                                                        value={this.state.rootDiscountPercent || ""}
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                rootDiscountPercent: e.target.value
                                                                            });
                                                                        }}
                                                                        onBlur={(e) => {
                                                                            if (this.state.subtotal) {
                                                                                this.calculateSubtotal();
                                                                            }
                                                                        }
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="input-label-control">
                                                                    <span className="mark">{(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}</span>
                                                                    <Input
                                                                        disabled
                                                                        placeholder="0.00"
                                                                        type='number'
                                                                        value={this.state.rootDiscountAmount || ""}
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                rootDiscountAmount: Number(e.target.value)
                                                                            });
                                                                        }}
                                                                        onBlur={(e) => {
                                                                            if (this.state.subtotal) {
                                                                                this.calculateSubtotal();
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Fragment>
                                                    : ''
                                            }
                                        </Row>

                                        {
                                            sectionVisible.tax
                                                ? <Fragment>
                                                    <Row>
                                                        <Col span={15}>
                                                            <FormattedMessage id='customer.salesInvoice.form.taxName.label'
                                                                defaultMessage='' />
                                                        </Col>

                                                        <Col span={9}>
                                                            <Dropdown
                                                                items={this.props.taxes}
                                                                value={this.props.taxName}
                                                                onSelect={(selectedTax) => {
                                                                    this.props.updateState({
                                                                        taxName: selectedTax
                                                                    })
                                                                }}
                                                                valueKeyName='description'
                                                                optionKeyName='taxSingleRateId'
                                                                allowClear={true}
                                                                onClear={() => {
                                                                    this.props.updateState({
                                                                        taxName: undefined,
                                                                    })
                                                                    this.calculateSubtotal();
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col span={15}>
                                                            <span className="label"><FormattedMessage
                                                                id='customer.salesInvoice.form.tax.label' defaultMessage='' /></span>
                                                        </Col>

                                                        <Col span={9}>
                                                            <div className="input-group">
                                                                <div className="input-label-control">
                                                                    <span className="mark">%</span>
                                                                    <Input placeholder="0.00" />
                                                                </div>
                                                                <div className="input-label-control">
                                                                    <span className="mark">{(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}</span>
                                                                    <Input placeholder="0.00" />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Fragment>
                                                : ''
                                        }

                                        {
                                            this.props.isRootExpense
                                                ? <Fragment>
                                                    <Row className='amount-summary mb20'>
                                                        <Col span={15}>
                                                            <FormattedMessage id='customer.salesInvoice.form.totalExpense.label'
                                                                defaultMessage='' />
                                                        </Col>

                                                        <Col span={9} style={{textAlign:"right"}}>
                                                            <Dropdown
                                                                style={{ width: '100%' }}
                                                                items={this.props.expenseTypes}
                                                                valueKeyName='expenseType'
                                                                value={this.props.expenseId}
                                                                canAddNew={true}
                                                                canDelete={true}
                                                                deleteClickHandler={(payload) => {
                                                                    this.deleteClickHandler(this.props, MODAL_TYPE.EXPENSE_TYPE, payload);
                                                                }}
                                                                onAddNewClick={(payload) => {
                                                                    payload.formData = {
                                                                        textBoxValue: payload.textEntered,
                                                                        submittedOnce: false
                                                                    }
                                                                    this.handleNewItemAddition(this.props, payload, MODAL_TYPE.EXPENSE_TYPE)
                                                                }}
                                                                onSelect={(expenseId) => {
                                                                    const selectedExpense = find(this.props.expenseTypes, { lkExpenseTypeId: Number(expenseId) })
                                                                    this.props.updateState({
                                                                        expenseId,
                                                                        expensName: selectedExpense.expenseType

                                                                    })
                                                                }}
                                                                optionKeyName='lkExpenseTypeId'
                                                                allowClear={true}
                                                                onClear={() => {
                                                                    this.props.updateState({
                                                                        expenseId: undefined,
                                                                        expensName: undefined
                                                                    })
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>

                                                    <Row className="mb20">
                                                        <Col span={15}>
                                                            <span className="label"><FormattedMessage
                                                                id='customer.salesInvoice.form.totalExpenseAmount.label'
                                                                defaultMessage='' /></span>
                                                        </Col>

                                                        <Col span={9} style={{textAlign:"right"}}>
                                                            <div className="input-group">
                                                                <div className="input-label-control">
                                                                    <span className="mark">{(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}</span>
                                                                    <Input
                                                                        placeholder="0.00"
                                                                        type='number'
                                                                        value={this.state.expenseAmount}
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                expenseAmount: Number(e.target.value)
                                                                            }, () => {
                                                                                if (this.state.subtotal) {
                                                                                    this.calculateSubtotal();
                                                                                }
                                                                            });
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Fragment>
                                                : ''
                                        }

                                        <div className="amount-summary">
                                            <Row>
                                                <Col span={15}>
                                                    <span className="title"><FormattedMessage
                                                        id='customer.salesInvoice.form.subTotal.label' defaultMessage='' /></span>
                                                </Col>
                                                <Col span={9} style={{textAlign:"right"}}>
                                                    {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                                    {fixedDecimalAndFormateNumber(this.state.subtotalWithoutDiscount)}
                                                </Col>
                                            </Row>
                                        </div>

                                        {this.state.totalDiscount === '0.00' || this.state.totalDiscount === 0 ? null :
                                            <div className="amount-summary">
                                                <Row>
                                                    <Col span={15}>
                                                        <span className="title"><FormattedMessage
                                                            id='customer.salesInvoice.form.totalDiscount.label'
                                                            defaultMessage='' /></span>
                                                    </Col>
                                                    <Col span={9} style={{textAlign:"right"}}>
                                                        {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                                        {fixedDecimalAndFormateNumber(this.state.totalDiscount)}
                                                    </Col>
                                                </Row>
                                            </div>}

                                        {
                                            itemLevelTaxes.length === 0 ? null : itemLevelTaxes.map((taxObj, i) => {
                                                if (isPlaceOfSupplySameAsRelationship) {

                                                }
                                                return (
                                                    (this.props.companyInfo || {}).countryName === 'India' ? (isPlaceOfSupplySameAsRelationship ? <div key={i}>
                                                        <div className="amount-summary">
                                                            <Row>
                                                                <Col span={15}>
                                                                    <span
                                                                        className="title">CGST @ {taxObj.taxPercent / 2}% on {taxObj.subtotal} </span>
                                                                </Col>
                                                                <Col span={9} style={{textAlign:"right"}}>
                                                                    {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                                                    {(Number(taxObj.taxAmount || 0) / 2).toFixed(2)}
                                                                </Col>
                                                            </Row>
                                                        </div>

                                                        <div className="amount-summary">
                                                            <Row>
                                                                <Col span={15}>
                                                                    <span
                                                                        className="title">SGST @ {taxObj.taxPercent / 2}% on {taxObj.subtotal} </span>
                                                                </Col>
                                                                <Col span={9} style={{textAlign:"right"}}>
                                                                    {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                                                    {(Number(taxObj.taxAmount || 0) / 2).toFixed(2)}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div> : <div key={i}>
                                                            <div className="amount-summary">
                                                                <Row>
                                                                    <Col span={15}>
                                                                        <span
                                                                            className="title">IGST @ {taxObj.taxPercent}% on {taxObj.subtotal} </span>
                                                                    </Col>
                                                                    <Col span={9} style={{textAlign:"right"}}>
                                                                        {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                                                        {(Number(taxObj.taxAmount || 0)).toFixed(2)}
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </div>) :
                                                        <div key={i}>
                                                            <div className="amount-summary">
                                                                <Row>
                                                                    <Col span={15}>
                                                                        <span
                                                                            className="title">{taxObj.taxName} @ {taxObj.taxPercent}% on {taxObj.subtotal} </span>
                                                                    </Col>
                                                                    <Col span={9} style={{textAlign:"right"}}>
                                                                        {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                                                        {(Number(taxObj.taxAmount || 0)).toFixed(2)}
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </div>
                                                )
                                            })
                                        }

                                        <div className="amount-summary">
                                            <Row>
                                                <Col span={15}>
                                                    <span className="title"><FormattedMessage
                                                        id='customer.salesInvoice.form.totalAmount.label'
                                                        defaultMessage='' /></span>
                                                </Col>
                                                <Col span={9} style={{textAlign:"right"}}>
                                                    {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                                    {fixedDecimalAndFormateNumber(this.state.total)}
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Col>

                            </Row>

                        </div>
                    }

                </div>


                {this.props.selectedSalesOrder && this.props.selectedSalesOrder.length > 1 ? 
                <div className="invoice-details-container transaction-table">
                    <Row type="flex" justify="end">
                        <Col span={6}>

                            <CustomTableUpload fileList={this.props.fileList} onChange={
                                (fileList) => {
                                    this.props.updateState({
                                        fileList
                                    })
                                }
                            } />


                            <div className="remarks-title">
                                <FormattedMessage id='customer.salesInvoice.form.bankDetails.label'
                                    defaultMessage='' />
                            </div>

                            <MultipleSelectDropdown
                                style={{ width: '94%' }}
                                items={(this.props.companyInfo || {}).bankDetails || []}
                                placeholder="Bank Details"
                                mode='multiple'
                                onDeSelect={(value) => {
                                    let { selectedBankDetails } = this.props
                                    selectedBankDetails = filter(selectedBankDetails, (taxId) => {
                                        return taxId !== value;
                                    });
                                    this.props.updateState({ selectedBankDetails });
                                }}
                                valueKeyName='name'
                                addressLineKey1='accountNumber'
                                addressLineKey2='ifscCode'
                                selectedValue={this.props.selectedBankDetails || []}
                                optionKeyName='bankDetailId'
                                onSelect={(bankDetailId) => {
                                    let selectedBankDetails = this.props.selectedBankDetails || [];
                                    if (selectedBankDetails.indexOf(bankDetailId) === -1) {
                                        selectedBankDetails.push(bankDetailId);
                                    } else {
                                        selectedBankDetails = selectedBankDetails.filter((el) => {
                                            return el !== selectedBankDetails
                                        });
                                    }
                                    this.props.updateState({
                                        selectedBankDetails
                                    });
                                }}
                            />
                            <div>
                                <div className="remarks-title">
                                    <FormattedMessage id='customer.salesInvoice.form.internalRemarks.label'
                                        defaultMessage='' />
                                </div>

                                <CKEditor
                                    type="inline"
                                    className="description-textarea"
                                    key={`${"internalRemarks"}`}
                                    data={this.props.internalRemarks}
                                    onInit={editor => {
                                        this.editor = editor;
                                    }}
                                    onChange={(event) => {
                                        const data = event.editor.getData();
                                        this.props.updateState({ internalRemarks: data });
                                    }}
                                    config={CUSTOM_CONFIGURATION}
                                />

                            </div>

                            <div className="remarks-title">
                                <FormattedMessage id='customer.salesInvoice.form.customerRemarks.label'
                                    defaultMessage='' />
                            </div>

                            {/* <TextArea maxLength={500} value={this.props.customerRemarks || ''} className="description-textarea" rows={4}
                                onChange={(e) => {
                                    this.props.updateState({
                                        customerRemarks: e.target.value
                                    });
                                }} placeholder={intl.formatMessage(
                                    {
                                        id: 'customer.salesInvoice.form.customerRemarks.placeholder',
                                        defaultMessage: placed in us. If you have any concerns or questions, please do not hesitate to contact us.'
                                    }
                                )}></TextArea> */}
                            <CKEditor
                                type="inline"
                                className="description-textarea"
                                key={`${"customerRemarks"}`}
                                data={this.props.customerRemarks}
                                onInit={editor => {
                                    this.editor = editor;
                                }}
                                onChange={(event) => {
                                    const data = event.editor.getData();
                                    this.props.updateState({ customerRemarks: data });
                                }}
                                config={CUSTOM_CONFIGURATION}
                            />
                        </Col>
                        <Col span={12} offset={6}>
                            <div className="product-amount-details">

                                <div className="amount-summary">
                                    <Row type="flex" justify="end" className="mb20">
                                        <Col span={8}>
                                            <span className="title">Grand Sub Total</span>
                                        </Col>
                                        <Col span={16}>
                                            {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                            {fixedDecimalAndFormateNumber(this.getGrandSubTotal() || 0)}
                                        </Col>
                                    </Row>
                                </div>


                                <div className="amount-summary">
                                    <Row type="flex" justify="end" className="mb20">
                                        <Col span={8}>
                                            <span className="title">Total Discount</span>
                                        </Col>
                                        <Col span={9}>
                                            {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                            {this.getTotalDiscount() || 0}
                                        </Col>
                                    </Row>
                                </div>
                                {
                                    this.getItemLevelTaxes().length === 0 ? null : this.getItemLevelTaxes().map((taxObj, i) => {
                                        if (isPlaceOfSupplySameAsRelationship) {

                                        }
                                        return (
                                            (this.props.companyInfo || {}).countryName === 'India' ? (isPlaceOfSupplySameAsRelationship ? <div key={i}>
                                                <div className="amount-summary">
                                                    <Row>
                                                        <Col span={8}>
                                                            <span
                                                                className="title">CGST @ {taxObj.taxPercent / 2}% on {taxObj.subtotal} </span>
                                                        </Col>
                                                        <Col span={16}>
                                                            {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                                            {(Number(taxObj.taxAmount || 0) / 2).toFixed(2)}
                                                        </Col>
                                                    </Row>
                                                </div>

                                                <div className="amount-summary">
                                                    <Row>
                                                        <Col span={8}>
                                                            <span
                                                                className="title">SGST @ {taxObj.taxPercent / 2}% on {taxObj.subtotal} </span>
                                                        </Col>
                                                        <Col span={16}>
                                                            {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                                            {(Number(taxObj.taxAmount || 0) / 2).toFixed(2)}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div> : <div key={i}>
                                                    <div className="amount-summary">
                                                        <Row>
                                                            <Col span={8}>
                                                                <span
                                                                    className="title">IGST @ {taxObj.taxPercent}% on {taxObj.subtotal} </span>
                                                            </Col>
                                                            <Col span={16}>
                                                                {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                                                {(Number(taxObj.taxAmount || 0)).toFixed(2)}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>) :
                                                <div key={i}>
                                                    <div className="amount-summary">
                                                        <Row>
                                                            <Col span={8}>
                                                                <span
                                                                    className="title">{taxObj.taxName} @ {taxObj.taxPercent}% on {taxObj.subtotal} </span>
                                                            </Col>
                                                            <Col span={16}>
                                                                {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                                                {(Number(taxObj.taxAmount || 0)).toFixed(2)}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                        )
                                    })
                                }



                                <div className="amount-summary">
                                    <Row type="flex" justify="end" className="mb20">
                                        <Col span={8}>
                                            <span className="title">Grand Total Invoice</span>
                                        </Col>
                                        <Col span={16}>
                                            {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                            {this.getGrandTotal() || 0}
                                        </Col>
                                    </Row>
                                </div>




                            </div>
                        </Col>
                    </Row>
                    <CKEditor
                        type="inline"
                        className="add-footer-textarea2"
                        key={`${"footer"}`}
                        data={this.props.footer}
                        onInit={editor => {
                            this.editor = editor;
                        }}
                        onChange={(event) => {
                            const data = event.editor.getData();
                            this.props.updateState({ footer: data });
                        }}
                        config={CUSTOM_CONFIGURATION}
                    />

                    <Row type="flex" className="action-button-group">
                        <Col span={3}>
                            <Button className="ant-btn-primary" onClick={() => {
                                createInvoice();
                            }}><FormattedMessage id='button.save.label' defaultMessage='' /></Button>
                        </Col>

                        <Col span={2} offset={19}>
                            <Button className="grey-button" onClick={() => {
                                this.props.history.goBack();
                            }}><FormattedMessage id='button.cancel.label' defaultMessage='' /></Button>
                        </Col>
                    </Row>

                </div> : <div>
                        {/* <TextArea maxLength={500} value={this.props.footer || ''} className="add-footer-textarea" onChange={(e) => {
                    this.props.updateState({
                        footer: e.target.value
                    });
                }}
                    rows={1} placeholder="Add Footer Here"></TextArea> */}
                        <CKEditor
                            type="inline"
                            className="add-footer-textarea"
                            key={`${"footer"}`}
                            data={this.props.footer}
                            onInit={editor => {
                                this.editor = editor;
                            }}
                            onChange={(event) => {
                                const data = event.editor.getData();
                                this.props.updateState({ footer: data });
                            }}
                            config={{ ...CUSTOM_CONFIGURATION, placeholder: "Add Footer Here" }}
                        />

                        
                    </div>
                    }
                    {/* <Row type="flex" className="action-button-group">
                            <Col span={1}>
                                <Button className="ant-btn-primary" onClick={() => {
                                    createInvoice();
                                }}><FormattedMessage id='button.save.label' defaultMessage='' /></Button>
                            </Col>

                            <Col span={2} offset={21}>
                                <Button className="grey-button" onClick={() => {
                                    this.props.history.goBack();
                                }}><FormattedMessage id='button.cancel.label' defaultMessage='' /></Button>
                            </Col>
                        </Row> */}
                <div className="footer">
                    <div className="left-fItems">
                        <Button
                            className="grey-button"
                            onClick={() => {
                                this.props.showModal({
                                    modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage="" />,
                                    footer: { submit: { intlId: "button.close.label" } },
                                    handleSubmit: () => {
                                        this.props.popModalFromStack();
                                    }
                                })
                            }}
                        >
                            <FormattedMessage id="button.cancel.label" defaultMessage="" />
                        </Button>
                    </div>
                    <div className="right-fItems">


                        <Button
                            type="primary"
                            loading={this.props.saveLoading}
                            disabled={this.props.saveLoading}
                            onClick={() => {
                                createInvoice();
                            }}
                        >
                            <FormattedMessage id="button.save.label" defaultMessage="" />
                        </Button>
                    </div>
                </div>
            </Fragment>
        )
    }
};

export default injectIntl(CustomerProductDetails);
