import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { SALES_INVOICE_ACTION_LIST, COMMON_ACTIONS } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const fetchSalesInvoiceDetail = (payload) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_DETAIL_LOADER });
        return axios
          .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customerInvoice/getInvoiceData?invoiceMasterId=${payload.invoiceMasterId}&relationshipId=${payload.relationshipId}&customerId=${payload.customerId}`)
          .then(res => {
            const { data } = res
            dispatch({ type: SALES_INVOICE_ACTION_LIST.SALES_INVOICE_DETAIL, data });
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
          .catch((err) => {
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            if(!err.__isRetryRequest){
              showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
            }else{
              err.actionToCall = SALES_INVOICE_ACTION_LIST.SALES_INVOICE_DETAIL;
            }
          })
          .finally(() => {
            dispatch({
              type: COMMON_ACTIONS.HIDE_DETAIL_LOADER,
            });
          })
      }
  }

  export const approveInvoice = (payload, props) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customerInvoice/approveSalesInvoice`, payload)
        .then(res => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          dispatch({ type: SALES_INVOICE_ACTION_LIST.SALES_INVOICE_LIST_PAGE_RESET });
          props.fetchSalesInvoices({ ...props, relationshipId: payload.relationshipId });
          showToasterMessage({
            description: (res || {}).message || 'Approved successfully.',
            messageType: 'success'
          })
        })
        .catch((err = {}) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (err.__isRetryRequest) {
          } else {
            showToasterMessage({
              description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
            })
          }
        })
    }
  }

  export const uploadStamp = (payload) => {
    return dispatch => {
      let formData = new FormData();
      formData.append("file", payload.file);
      formData.append('objectKey', payload.objectKey);
      formData.append('rid', payload.rid);
      formData.append('bucketName',payload.bucketName)
      formData.append('customerId', payload.customerId);
      formData.append('invoiceMasterId', payload.invoiceMasterId);
      formData.append("contentType", "multipart/form-data;");
      formData.append("contentLength", "1234");
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customerInvoice/saveOrUpdateStamp`,
          formData, {
          headers: {
            'content-type': 'multipart/form-data'
          }
        })
        .then(res => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          dispatch({ type: SALES_INVOICE_ACTION_LIST.SALES_INVOICE_DETAIL, data: res.data });
        })
        .catch(err => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
    }
  }
  
  export const deleteStamp = (payload, props) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customerInvoice/deleteStamp?relationshipId=${payload.relationshipId}&customerId=${payload.customerId}&invoiceMasterId=${payload.invoiceMasterId}&objectKey=${payload.objectKey}&bucketName=${payload.bucketName}&folderName=${payload?.folderName}`)
        .then(res => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          props.fetchSalesInvoiceDetail(payload);
          showToasterMessage({
            description: (res || {}).message || 'Deleted successfully.',
            messageType: 'success'
          })
        })
        .catch((err = {}) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (err.__isRetryRequest) {
          } else {
            showToasterMessage({
              description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
            })
          }
        })
    }
  }

  export const getAvailableBalance = (payload) => {
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        dispatch({ type: SALES_INVOICE_ACTION_LIST.AVAILABLE_CREDITS_RESET });
        return axios
          .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customers/getAvailableBalance?relationshipId=${payload.relationshipId}&customerId=${payload.customerId}`)
          .then(res => {
            const { data } = res
            dispatch({ type: SALES_INVOICE_ACTION_LIST.AVAILABLE_CREDITS, data });
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
          .catch((err) => {
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            if(!err.__isRetryRequest){
             // showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
            }else{
              err.actionToCall = SALES_INVOICE_ACTION_LIST.AVAILABLE_CREDITS;
            }
          })
      }
  }

  export const payOnline = (payload, props) => {
    payload.baseUrl = `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/`;
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.API_BASE_URL}alpide-paypal/paymentGateway/getPaymentUrl?relationshipId=${payload.relationshipId}&customerId=${payload.customerId}&invoiceMasterId=${payload.invoiceMasterId}&baseUrl=${payload.baseUrl}`, payload)
        .then(res => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          props.fetchSalesInvoiceDetail(payload);
          window.open(res.data);
        })
        .catch((err = {}) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (err.__isRetryRequest) {
          } else {
            showToasterMessage({
              description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
            })
          }
        })
    }
  }

 