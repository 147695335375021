import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { PURCHASE_REQUEST_ACTION_LIST, COMMON_ACTIONS } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const fetchPurchaseRequestDetail = (payload) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_DETAIL_LOADER });

        return axios
          .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/purchaseRequestRest/getPurchaseRequestsByPRId?prMasterId=${payload.prMasterId}&relationshipId=${payload.relationshipId}&supplierId=${payload.supplierId}`)
          .then(res => {
            const { data } = res
            dispatch({ type: PURCHASE_REQUEST_ACTION_LIST.PURCHASE_REQUEST_DETAIL, data });
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
          .catch((err) => {
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            if(!err.__isRetryRequest){
             // showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
            }else{
              err.actionToCall = PURCHASE_REQUEST_ACTION_LIST.PURCHASE_REQUEST_DETAIL;
            }
          }).finally(() => {
            dispatch({
              type: COMMON_ACTIONS.HIDE_DETAIL_LOADER,
            });
          })
      }
  }

  export const approvePR = (payload, props) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/purchaseRequestRest/approvePR`, payload)
        .then(res => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          dispatch({ type: PURCHASE_REQUEST_ACTION_LIST.PURCHASE_REQUEST_LIST_PAGE_RESET });
          props.fetchPurchaseRequests({ ...props, relationshipId: payload.relationshipId });
          showToasterMessage({
            description: (res || {}).message || 'Approved successfully.',
            messageType: 'success'
          })
        })
        .catch((err = {}) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (err.__isRetryRequest) {
          } else {
            showToasterMessage({
              description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
            })
          }
        })
    }
  }

  export const resetPurchaseRequestDetail = () => {
    return dispatch => {
      dispatch({ type: PURCHASE_REQUEST_ACTION_LIST.RESET_PURCHASE_REQUEST_DETAIL });
    }
  }

  export const uploadStamp = (payload) => {
    return dispatch => {
      let formData = new FormData();
      formData.append("file", payload.file);
      formData.append('objectKey', payload.objectKey);
      formData.append('rid', payload.rid);
      formData.append('supplierId', payload.supplierId);
      formData.append('purchaseRequestMasterId', payload.purchaseRequestMasterId);
      formData.append("contentType", "multipart/form-data;");
      formData.append("contentLength", "1234");
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/purchaseRequestRest/saveOrUpdateStamp`,
          formData, {
          headers: {
            'content-type': 'multipart/form-data'
          }
        })
        .then(res => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          dispatch({ type: PURCHASE_REQUEST_ACTION_LIST.PURCHASE_REQUEST_DETAIL, data: res.data });
        })
        .catch(err => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
    }
  }
  
  export const deleteStamp = (payload, props) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/purchaseRequestRest/deleteStamp?relationshipId=${payload.relationshipId}&supplierId=${payload.supplierId}&purchaseRequestMasterId=${payload.purchaseRequestMasterId}&objectKey=${payload.objectKey}`, payload)
        .then(res => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          props.fetchPurchaseRequestDetail(payload);
          showToasterMessage({
            description: (res || {}).message || 'Deleted successfully.',
            messageType: 'success'
          })
        })
        .catch((err = {}) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (err.__isRetryRequest) {
          } else {
            showToasterMessage({
              description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
            })
          }
        })
    }
  }

  export const updateInquiryPRStatus = (payload, props) => {
    return dispatch => {
  
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/inquiry/updateStatusPRMessage`, payload)
        .then(res => {
          showToasterMessage({ messageType: 'success', description: 'Status updated' })
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          props.fetchSalesEnquiry && props.fetchSalesEnquiry(props);
          props.fetchInquiryDetail && props.fetchInquiryDetail(payload); 
          props.fetchPurchaseRequestDetail && props.fetchPurchaseRequestDetail(payload);
        })
        .catch((err) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (!err.__isRetryRequest) {
            showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          }
        })
    }
  }

  
export const updateSOPRStatus = (payload, props) => {
  return dispatch => {

    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesOrder/updateStatusPRMessage`, payload)
      .then(res => {
        showToasterMessage({ messageType: 'success', description: 'Status updated' })
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        props.fetchSalesOrders && props.fetchSalesOrders(props);
       // props.fetchSODetail && props.fetchSODetail(payload);
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }
      })
  }
}