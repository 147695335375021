import axios from "axios";
import config from "../../../../config/environmentConfig";
import { FUNDRAISING_ACTION_LIST } from "../../../../static/constants";
import { showToasterMessage } from "../../../../utils";
const { lang } = require("../../../../translations/" +
  (sessionStorage.getItem("language") || "en") +
  ".js");


export const saveFundraisingPledge = (payload, props) => {
  return (dispatch) => {
    return axios
      .post(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/pledge/saveOrUpdate`,
        payload
      )
      .then((response) => {
        if (props) {
          props.updateHeaderState({
            fundraising: {
              addPledgeDrawerVisible: false
            }
          });
          props.openPledgeDrawerFromListing && props.getPledge && props.getPledge(props);
          props.openPledgeDrawerFromListing && props.pledgeCount && props.pledgeCount(props);
        }
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const resetAllFundraisingCampaign = () => {
  return dispatch => {
    dispatch({ type: FUNDRAISING_ACTION_LIST.RESET_ALL_CAMPAIGN_LIST });
  }
}

export const getAllFundraisingCampaign = (payload) => {
  return (dispatch) => {
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext
        }/fundraisingCampaign/getFundraisingCampaign?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 25
        }&pageNumber=${(payload.pageNumber || 1) - 1}`
      )
      .then((res) => {

        dispatch({
          type: FUNDRAISING_ACTION_LIST.ALL_CAMPAIGN_LIST,
          data: res.data,
        });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          // showToasterMessage({
          //   messageType: "error",
          //   description:
          //     lang[((err.response || {}).data || {}).message] ||
          //     "Some error occurred",
          // });
        }
      });
  };
};
