import React, { Fragment, useState } from "react";
import { Row, Col, Drawer, Checkbox, Tabs, Upload, Modal, Button, Card } from "antd";
import { UploadOutlined, CloseOutlined } from "@ant-design/icons";
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from "react-intl";
import { CustomButton } from "../../../general/CustomButton";
import config from "../../../../config/environmentConfig";
import "./index.scss";
// import { MODAL_TYPE, CONSTANTS } from '../../../../../static/constants';
import { TextBox } from "../../../general/TextBox";

import storeDef from "../../../../assets/images/store-def.jpg";

// import { getLabelName } from '../../customerInfo';
import {
  base64Toblob,
  getAWSFileURL,
  showToasterMessage,
} from "../../../../utils";
import { ImageCropper } from "../../../../components/general/ImageCropper";
import { CustomUploader } from "../../../../components/general/CustomUploader";
import CategoryModel from "./CategoryModel";
import TaxjarCategoryModel from "./TaxjarCategoryModel";
import CKEditor from "../../../general/CustomCKEditor/ckeditor";
import { CUSTOM_CONFIGURATION } from "../../../../static/ckEditorConfigConstants";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { CONSTANTS } from "../../../../static/constants";
const { TabPane } = Tabs;

// const { Step } = Steps;
function CategoryDrawer(props) {
  const { intl, companyInfo, isUpdate ,categoryData } = props;


  const [thumbNailImage ,setThumbnailImage] = useState()
  const [categoryImage ,setCategoryImage] = useState()
 

  const customProps1 = {

    imgUrl: categoryImage ? categoryImage : props?.awsKeyName ? getAWSFileURL(props?.awsKeyName ,props.companyInfo.bucketName , props.folderName) : "",
    isImage: true,
    multiple: false,
    height: 60,
    width: 60,
    type: CONSTANTS.TYPE_IMAGE,
    name: "file",
    action: null,
    maxCount: 1,
    accept: ".jpg, .jpeg, .png",
    buttonName: "Upload Category Image",
    onChange: (fileList) => {
      let file = {file: fileList[0]}; 
      if (file.file.originFileObj) {
        var reader = new FileReader();
        reader.onload = function (e) {
          // props.updateState({
          //     fileList: file.fileList
          // })
          props.showModal({
            title: (
              <Fragment>
                <div className="ant-modal-title">Crop Image</div>
              </Fragment>
            ),
            //modalBody: <ImageCropper cropperRef={cropperRef} onClick={(e) => {
            modalBody: (
              <ImageCropper
                onClick={(e) => {}}
                {...props}
                handleSubmit={(newUrl) => {
                  props.updateState({
                    fileList: base64Toblob(newUrl),
                    // categoryThumbnailUploadeImage: base64Toblob(newUrl),
                  });
                  setCategoryImage(newUrl)
                }}
                url={e.target.result}
              />
            ),
            width: 800,
            hideFooter: true,
            wrapClassName: "Upload-profile-modal",
          });
        };

        reader.readAsDataURL(fileList[0].originFileObj); // convert to base64 string
      }
    },
  };
  const customProps = {
    // type: CONSTANTS.TYPE_IMAGE,
    imgUrl: thumbNailImage ? thumbNailImage : props?.categoryThumbnail ? getAWSFileURL(props?.categoryThumbnail ,props.companyInfo.bucketName , props.folderName) : "",
    isImage: true,
    multiple: false,
    height: 60,
    width: 60,
    type: CONSTANTS.TYPE_IMAGE,
    name: "file",
    action: null,
    maxCount: 1,
    accept: ".jpg, .jpeg, .png",
    buttonName: "Upload Category Image",
    onChange: (fileList) => {
      let file = {file: fileList[0]}; 
      if (file.file.originFileObj) {
        var reader = new FileReader();
        reader.onload = function (e) {
          // props.updateState({
          //     fileList: file.fileList
          // })
          props.showModal({
            title: (
              <Fragment>
                <div className="ant-modal-title">Crop Image</div>
              </Fragment>
            ),
            //modalBody: <ImageCropper cropperRef={cropperRef} onClick={(e) => {
            modalBody: (
              <ImageCropper
                onClick={(e) => {}}
                {...props}
                handleSubmit={(newUrl) => {
                  props.updateState({
                   
                    categoryThumbnailUploadeImage: base64Toblob(newUrl),
                  });
                  setThumbnailImage(newUrl)
                }}
                url={e.target.result}
              />
            ),
            width: 800,
            hideFooter: true,
            wrapClassName: "Upload-profile-modal",
          });
        };

        reader.readAsDataURL(fileList[0].originFileObj); // convert to base64 string
      }
    },
  };

  const closeDrawer = () => {
    props.updateHeaderState({
      inventory: {
        ...props,
        categoryDrawerVisible: false,
        classData: {},
      },
    });
  };
 


  const handelSubmit = (props) => {
    if (props.categoryName) {
      const payload = {
        relationshipId: companyInfo.relationshipId,
        fileList: props.fileList,
        text: props.categoryName,
        description: props.categoryDesc,
        inventoryItemCategoryId: props.inventoryItemCategoryId,
        version: props.version,
        awsKeyName: props.awsKeyName,
        isPublished: props.isPublished,
        subcategories: props.subcategories || [],
        inventoryItemCategoryRefs:props.inventoryItemCategoryRefs || [],
        parentId: props.parentId,
        parentName: props.parentName,
        categoryPageTitle: props.categoryPageTitle,
        categoryPageDescription: props.categoryPageDescription,
        productTaxCode: props.productTaxCode,
        categoryThumbnailImage: props.categoryThumbnail,
        categoryThumbnailUploadeImage:props.categoryThumbnailUploadeImage,
        title: props.title || props.categoryName,
        subTitle:props.subTitle,
      
      }
      props.addCategory(
        payload,
        props
      );
      closeDrawer();
    } else {
      showToasterMessage({
        messageType: "error",
        description: "Category Name in required",
      });
    }
  };

  const onChangeCheckBox = (e) => {
    props.updateState({
      addAsSubcategory: e.target.checked,
    });
  };


  
  const handleParentCategory = () => {
    props.pushModalToStack({
      modalBody: <CategoryModel {...props} />,
      title: (
        <FormattedMessage
          id="model.inventory.title.SelectParentCategory"
          defaultMessage=""
        />
      ),
      width: "40%",
      wrapClassName: "cat-modal-custom",
      hideTitle: false,
      hideFooter: true,
    });
  };

 
 
  const handleTaxCategory = () => {
    props.pushModalToStack({
      modalBody: <TaxjarCategoryModel {...props} />,
      title: (
        <FormattedMessage
          id="drawer.inventory.taxCategorySelect"
          defaultMessage=""
        />
      ),
      width: "40%",
      wrapClassName: "cat-modal-custom",
      hideTitle: false,
      hideFooter: true,
    });
  };

  // Upload Image code
  const [fileList, setFileList] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);

  const [fileList1, setFileList1] = useState([]);
  const [previewVisible1, setPreviewVisible1] = useState(false);
  
  const handleRemove = (file) => {
    const updatedFileList = fileList.filter((item) => item.uid !== file.uid);
    setFileList(updatedFileList);
  };

  const handleRemove1 = (file1) => {
    const updatedFileList1 = fileList.filter((item) => item.uid !== file1.uid);
    setFileList1(updatedFileList1);
  };
 
  const customRequest = ({ file, onSuccess, onError }) => {
    setTimeout(() => {
      if (file.type === "image/png" || file.type === "image/jpeg") {
        onSuccess("OK");
      } else {
        onError("Upload failed");
      }
    }, 1000);
  };

  const customRequest1 = ({ file, onSuccess, onError }) => {
    setTimeout(() => {
      if (file?.type === "image/png" || file?.type === "image/jpeg") {
        onSuccess("OK");
      } else {
        onError("Upload failed");
      }
    }, 1000);
  };

  const customRequest2 = ({ file, onSuccess, onError }) => {
    setTimeout(() => {
      if (file?.type === "image/png" || file?.type === "image/jpeg") {
        onSuccess("OK");
      } else {
        onError("Upload failed");
      }
    }, 1000);
  };
  const handleChange = ({ fileList }) => {
    setFileList(fileList);
  };

  const handleChange1 = ({ fileList }) => {
    setFileList1(fileList);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>
        <h5>Add category banner</h5>
        <p>Recommended size (1296px*236px)</p>
      </div>
    </div>
  );

  const uploadButton1 = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>
        <h5>Add category banner</h5>
        <p>Recommended size (328px*200px)</p>
      </div>
    </div>
  );

  return (
    <Fragment>
      <Drawer
        title={!isUpdate?"Create Category":"Edit Category"}
        width={720}
        keyboard={false}
        zIndex={1}
        destroyOnClose={true}
        onClose={closeDrawer}
        maskClosable={false}
        className="custom-drawer"
        visible={props.categoryDrawerVisible}
        afterVisibleChange={(visible) => {
          if (visible) {
            //props.initCalls();
          }
        }}
        footer={
          <>
            <CustomButton
              intlId="confirmation.cancel"
              defaultMessage=""
              type="default"
              key={0}
              style={{ marginRight: 8 }}
              onClick={closeDrawer}
            />
            <CustomButton
              intlId="confirmation.submit"
              defaultMessage=""
              htmlType="submit"
              onClick={() => handelSubmit(props)}
              key={1}
            />
          </>
        }
      >
        <Tabs type="line" className=" inventory-tabs">
          <TabPane
            tab={intl.formatMessage({
              id: "drawer.category.tabNames.infomation",
              defaultMessage: "",
            })}
            key="1"
          >
            <div>
              <Row gutter={[16, 16]}>
              <Col span={12}>
                  <div className="i-lable">
                    <FormattedMessage
                      id='ThumbNail Image'
                      defaultMessage=""
                    />
                  </div>
                  <CustomUploader {...customProps} />
                </Col>
                <Col span={12}>
                  <div className="i-lable">
                    <FormattedMessage
                      id='Category Image'
                      defaultMessage=""
                    />
                  </div>
                  <CustomUploader {...customProps1} />
                </Col>
                <Col span={12}>
                <div className="i-label">
                    <FormattedMessage
                      id={'setting.userManagement.listing.title'
                      }
                      defaultMessage=""
                    />
                    <span>*</span>
                  </div>
                  <TextBox
                  maxLength={50}
                  countStyle={{top: '35px'}}
                    value={props.title || props.categoryName}
                    placeholder={intl.formatMessage({
                      id: "setting.userManagement.listing.title",
                      defaultMessage: "",
                    })}
                    onChange={(e) => {
                      props.updateState({
                        title: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col span={12}>
                <div className="i-label">
                    <FormattedMessage
                      id={'product.category.drawer.subTitle'
                      }
                      defaultMessage=""
                    />
                    <span>*</span>
                  </div>
                  <TextBox
                    value={props.subTitle}
                    maxLength={100}
                    countStyle={{top: '35px'}}
                    placeholder={intl.formatMessage({
                      id: "product.category.drawer.subTitle",
                      defaultMessage: "",
                    })}
                    onChange={(e) => {
                      props.updateState({
                        subTitle: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col span={12}>
                  <div className="i-label">
                    <FormattedMessage
                      id={
                        props.addAsSubcategory
                          ? "drawer.inventory.subCategory"
                          : "drawer.inventory.category"
                      }
                      defaultMessage=""
                    />
                    <span>*</span>
                  </div>
                  <TextBox
                    value={props.categoryName}
                    placeholder={intl.formatMessage({
                      id: "drawer.product.category.placeholder",
                      defaultMessage: "",
                    })}
                    onChange={(e) => {
                      props.updateState({
                        categoryName: e.target.value,
                        title: e.target.value
                      });
                    }}
                  />
                  <div className="subcategory-checkBox">
                    <Checkbox
                      checked={props?.addAsSubcategory}
                      onChange={onChangeCheckBox}
                    >
                      Add as Sub Category
                    </Checkbox>
                  </div>
                </Col>

                <Col span={12}>
                  {props.addAsSubcategory && (
                    <>
                      <div className="i-label">
                        <FormattedMessage
                          id={"drawer.inventory.ParentCategory"}
                          defaultMessage=""
                        />
                        <span>*</span>
                      </div>
                      <div onClick={() => handleParentCategory()}>
                        <TextBox
                          value={
                            props.parentName ? props.parentName : undefined
                          }
                          placeholder={intl.formatMessage({
                            id: "drawer.inventory.category.parentPlaceholder",
                            defaultMessage: "",
                          })}
                        />
                      </div>
                    </>
                  )}
                </Col>
                
                

               

             
                {
                  (props.taxjarCategories && props.taxjarCategories.length) ?<Col span={12}>
                 
                    <>
                      <div className="i-label">
                        <FormattedMessage
                          id={"drawer.inventory.taxCategory"}
                          defaultMessage=""
                        />
                        <span>*</span>
                      </div>
                      <div onClick={() => handleTaxCategory()}>
                        <TextBox
                          value={
                            props.productTaxCodeCatName ? props.productTaxCodeCatName : props.productTaxCode
                          }
                          placeholder={intl.formatMessage({
                            id: "drawer.inventory.taxCategorySelect",
                            defaultMessage: "",
                          })}
                        />
                      </div>
                    </>
                 
                </Col>:''
                }
              </Row>
              <div>
               
              <Row>
                <Col span={24}>
                  <span>
                    <FormattedHTMLMessage
                      id="drawer.category.content.placeholder"
                      defaultMessage=""
                    />
                  </span>
                </Col>
                <Col span={24}>
                  <div>
                    <CKEditor
                      type="replace"
                      className="description-input"
                      key={`${"description"}`}
                      // data={description}
                      editor={props.editor}
                      onInit={(editor) => {
                        props.updateState({
                          editor,
                        });
                      }}
                      data={props.categoryDesc}
                      onChange={(event) => {
                        const data = event.editor.getData();
                        props.updateState({
                          categoryDesc: data,
                        });
                      }}
                      config={CUSTOM_CONFIGURATION}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            </div>
          </TabPane>

          <TabPane
            tab={intl.formatMessage({
              id: "drawer.category.tabNames.banner",
              defaultMessage: "",
            })}
            key="2"
          >
            <div>
              <Row>
                <Col span={24}>
                  <span>
                    <FormattedMessage
                      id="drawer.category.forDesktop.placeholder"
                      defaultMessage=""
                    />
                  </span>
                </Col>
                <Col span={24}>
                  <div className="cat-img-uploader">
                    <Upload
                      action=""
                      listType="picture-card"
                      fileList={fileList}
                      onChange={handleChange}

                      customRequest={customRequest}
                      onRemove={handleRemove}
                    >
                      {fileList.length >= 1 ? null : uploadButton}
                    </Upload>
                    <Modal
                      visible={previewVisible}
                      footer={null}
                      onCancel={() => setPreviewVisible(false)}
                    >
                      <img
                        alt="Preview"
                        style={{ objectFit: "contain" }}
                        src={fileList[0] ? fileList[0].url : null}
                      />
                      <Button
                        icon={<DeleteOutlined />}
                        onClick={() => handleRemove(fileList[0])}
                      >
                        Remove
                      </Button>
                    </Modal>
                  </div>
                </Col>

                <Col span={24}>
                  <span>
                    <FormattedMessage
                      id="drawer.category.formobile.placeholder"
                      defaultMessage=""
                    />
                  </span>
                </Col>
                <Col span={24}>
                  <div className="cat-img-uploader">
                    <Upload
                      action=""
                      listType="picture-card"
                      fileList={fileList1}
                      onChange={handleChange1}
                      customRequest={customRequest1}
                      onRemove={handleRemove1}
                    >
                      {fileList1?.length >= 1 ? null : uploadButton1}
                    </Upload>
                    <Modal
                      visible={previewVisible1}
                      footer={null}
                      onCancel={() => setPreviewVisible1(false)}
                    >
                      <img
                        alt="Preview"
                        style={{ objectFit: "contain" }}
                        src={fileList1[0] ? fileList1[0].url : null}
                      />
                      <Button
                        icon={<DeleteOutlined />}
                        onClick={() => handleRemove1(fileList1[0])}
                      >
                        Remove
                      </Button>
                    </Modal>
                  </div>
                </Col>
              </Row>
            </div>
          </TabPane>

          <TabPane
            tab={intl.formatMessage({
              id: "drawer.category.tabNames.content",
              defaultMessage: "",
            })}
            key="3"
          >
          <div>
            <Row>
            <Col span={18} style={{marginBottom:"20px"}}>
                  <div className="i-label">
                   Category Meta Title
                  </div>
                  <TextBox
                    value={props.categoryPageTitle}
                    placeholder="Enter Title"
                    maxLength={60}
                    onChange={(e) => {
                      props.updateState({
                        categoryPageTitle: e.target.value,
                      });
                    }}
                  />
                  
                </Col>
                <Col span={18}>
                  <div className="i-label">
                   Category Meta Description
                  </div>
                  <textarea
              value={props.categoryPageDescription}
            rows={5}
            maxLength={340}
           
            onChange={(e) => {
              props.updateState({
                categoryPageDescription: e.target.value,
              });
            }}
            style={{ width: "100%", padding: "10px" }}
            placeholder="Enter Meta Description"
          />
                 
                  
                </Col>
            </Row>
          </div>
          </TabPane>
        </Tabs>
        <div className="drawer-footer"></div>
      </Drawer>
    </Fragment>
  );
}

export default injectIntl(CategoryDrawer);