import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input } from 'antd';
import { showToasterMessage } from '../../../../../utils'
import { CustomButton } from '../../../../general/CustomButton';

class NewHsnComponent extends Component {
  constructor(props) {
    super(props);
    this.state={
      ...this.props.data.formData
    }
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 24,
          offset: 0,
        },
      },
    };

    return (
      <div className="add-new-tax-modal" >
        <Form  {...formItemLayout} onSubmit={this.handleSubmit} hideRequiredMark={true}>
          <div className="modal-content create-new-control pl30 pr60 pb20" >
            <Form.Item label={'HSN Code'} colon={false}>
              {getFieldDecorator('hsnCode', {
                  initialValue: (this.props.data.formData || {}).hsnCode || '',
                rules: [
                  {
                    required: true,
                    message: this.props.intl.formatMessage({ id: 'common.name.required'} ),
                  }
                ],
              })(
                  <Input placeholder='HSN Code' />
              )}
            </Form.Item>
            <Form.Item label={'Description'} colon={false}>
              {getFieldDecorator('description', {
                initialValue: (this.props.data.formData || {}).description || '',
                rules: [
                  {
                    required: true,
                    message: 'Description is required',
                  }
                ],
              })(
                <Input.TextArea placeholder='Description' rows={4} style={{maxHeight:"50px"}}/>
              )}
            </Form.Item>

            <Form.Item label='IGST' colon={false}>
              {getFieldDecorator('iGstRate', {
                initialValue: (this.props.data.formData || {}).iGstRate || '',
                rules: [
                  {
                    required: true,
                    message: this.props.intl.formatMessage({ id: 'taxRate.required'} ),
                  },
                  // {
                  //   pattern: '^[1-9][0-9]?$|^100$',
                  //   message: <FormattedMessage id='taxRate.validation' defaultMessage='' />,
                  // },
                ],
              })
                (
                  <span className="tax-rate-input">
                    <Input type='number' value={this.state.iGstRate} placeholder='IGST' onChange={(e) => {
                      this.setState({
                        iGstRate: e.target.value
                      });
                      this.props.form.setFieldsValue({ 'iGstRate': e.target.value });
                    }}
                     />

                    <span>%</span>
                  </span>
                )}
            </Form.Item>
            <Form.Item label='CGST' colon={false}>
              {getFieldDecorator('cGstRate', {
                initialValue: (this.props.data.formData || {}).cGstRate || '',
                rules: [
                  {
                    required: true,
                    message: this.props.intl.formatMessage({ id: 'taxRate.required'} ),
                  },
                  // {
                  //   pattern: '^[1-9][0-9]?$|^100$',
                  //   message: <FormattedMessage id='taxRate.validation' defaultMessage='' />,
                  // },
                ],
              })
                (
                    <span className="tax-rate-input">
                      <Input type='number' placeholder='CGST' value={this.state.cGstRate}  onChange={(e) => {
                      this.setState({
                        cGstRate: e.target.value
                      });
                      this.props.form.setFieldsValue({ 'cGstRate': e.target.value });
                    }}/>
                      <span>%</span>
                    </span>
                )}
            </Form.Item>
            <Form.Item label='SGST/UGST' colon={false}>
              {getFieldDecorator('sGstUtGstRate', {
                initialValue: (this.props.data.formData || {}).sGstUtGstRate || '',
                rules: [
                  {
                    required: true,
                    message: this.props.intl.formatMessage({ id: 'taxRate.required'} ),
                  },
                  // {
                  //   pattern: '^[1-9][0-9]?$|^100$',
                  //   message: <FormattedMessage id='taxRate.validation' defaultMessage='' />,
                  // },
                ],
              })
                (
                    <span className="tax-rate-input">
                      <Input type='number' placeholder='SGST/UGST' value={this.state.sGstUtGstRate} onChange={(e) => {
                      this.setState({
                        sGstUtGstRate: e.target.value
                      });
                      this.props.form.setFieldsValue({ 'sGstUtGstRate': e.target.value });
                    }}/>
                      <span>%</span>
                    </span>
                )}
            </Form.Item>
          </div>

          <Form.Item {...tailFormItemLayout} className="modal-footer">
            <div key={0} className='customModal-button-group'>
              <CustomButton
                intlId={'modal.newTax.button.cancel'}
                defaultMessage={''}
                type={'default'}
                key={1}
                onClick={() => { this.props.hideModal() }}
              />
              <CustomButton
                intlId={'button.submit.label'}
                defaultMessage={''}
                htmlType='submit'
                key={2}
              />
            </div>
          </Form.Item>
        </Form>
      </div >
    )
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (values.taxPercent < 0 || values.taxPercent > 100) {
          return showToasterMessage({ messageType: 'error', description: this.props.intl.formatMessage({ id: 'taxRate.validation' } ) });
        }
        const payload = {
          hsnCode: values.hsnCode,
          description: values.description,
          // relationshipId: (this.props.companyInfo || {}).relationshipId,
          igstRate: values.iGstRate ? Number(values.iGstRate) : 0,
          cgstRate: values.cGstRate ? Number(values.cGstRate) : 0,
          sgstUtGstRate: values.sGstUtGstRate ? Number(values.sGstUtGstRate) : 0,
          financeType: ((this.props.data || {}).formData || {}).financeType || 'Goods',
          financeGstGoodsRates: ((this.props.data || {}).formData || {}).financeGstGoodsRates,
          cess: ((this.props.data || {}).formData || {}).cess,
          version: ((this.props.data || {}).formData || {}).version
        }

        if(this.props.companyInfo && (this.props.companyInfo || {}).countryName !== 'India'){
          payload.taxType = 'VAT';
        }

        const modalData = {
          modalBody: 'Are you sure you want to create tax ?',
          handleSubmit: () => {
            this.props.saveHsnCode({financeGSTGoodsRatesList: [payload]}, this.props);
            this.props.hideModal();
          }
        };
        this.props.pushModalToStack(modalData);
      }
    });
  };
}

export default Form.create({ name: 'New_tax_component' })(injectIntl(NewHsnComponent));
