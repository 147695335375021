import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import GenerateShippingLabelShiprocketComponent from '../../../../../components/modal/modalBody/customer/GenerateShippingLabelShiprocket';
import { fetchFromLocalStorage } from '../../../../../utils';
import { getCarrierPricesShiprocket, createShiprocketShipmentOrder, generateAwbForShipment, getShiprocketIntegratedChannel, getShiprocketAccountAddress,
  generateManifest, scheduleCourierPickup, generateShippingLabel, getTrackingByOrderId
 } from './action'
import { popModalFromStack } from '../../../../../actions/commonActions';
import { LOCAL_STORAGE_KEYS, LOGISTIC_CONSTANTS } from '../../../../../static/constants';
import { fetchAllShipment } from '../../../../customer/shipment/Listing/action';
import { fetchSODetail } from '../SODetail/action';


class GenerateShippingLabelShiprocket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: props.data.formData?.pageSize || 25,
      pageNumber: props.data.formData?.pageNumber || 1,
      logisticName: LOGISTIC_CONSTANTS.SHIPROCKET,
      currentStep: 0
    }
  }

  componentDidMount() {
    console.log(`vvv`, this.state)
    const companyInfo = fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA) && JSON.parse(fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA));
    console.log(`vvv comapny info`, companyInfo)
    let shipmentData = this.props.data?.txData || {};

    this.props.getShiprocketIntegratedChannel({relationshipId: ((companyInfo || {}).defaultCompany || {}).relationshipId});
    this.props.getShiprocketAccountAddress({relationshipId: ((companyInfo || {}).defaultCompany || {}).relationshipId})
    const val = this.props.data.txData.customerSOShipmentDetailsList?.length  ? this.props.data.txData.customerSOShipmentDetailsList[0] :{}
    const payload2 = {
      customerId: val?.customerId,
      salesOrderMasterId: val?.salesOrderMasterId,
      relationshipId:this.props.companyInfo.relationshipId
  }
      this.props.fetchSODetail(payload2);
  }
 

  render() {
    return <GenerateShippingLabelShiprocketComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />;
  }

}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    carrierRates: state.shipment.carrierRates,
    isLoadingCarriarList: state.shipment.isLoadingCarriarList,
    buyShipmentDetails: state.shipment.buyShipmentDetails,
    boughtShipment: state.shipment.boughtShipment,
    soDetail: state.so.soDetail,
    detailLoading: state.common.detailLoading,
    carrierDetails: state.shipment.carrierDetails,
    shiprocketCarrierDetails: state.shipment.shiprocketCarrierDetails,
    deliveyNotesData: state.so.soDeliveyNotesDetail,
    // shiprocketOrderDetails: state.shipment.shiprocketOrderDetails,
    listLoading: state.common.listLoading,
    shiprocketIntgratedChannels: state.shipment.shiprocketIntgratedChannels,
    saveLoading: state.common.saveLoading,
    detailLoading: state.common.detailLoading,
    shiprocketAccountAddress: state.shipment.shiprocketAccountAddress,
    boughtShipment: state.shipment.boughtShipment,
    shipmentLogisticsInfo: state.shipment.shipmentLogisticsInfo,
    awbAssignedDetails: state.shipment.awbAssignedDetails,
    shipmentPickupDetail: state.shipment.shipmentPickupDetail,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  popModalFromStack,
  fetchAllShipment,
  fetchSODetail,
  getCarrierPricesShiprocket,
  createShiprocketShipmentOrder,
  generateAwbForShipment,
  getShiprocketIntegratedChannel,
  getShiprocketAccountAddress,
  generateManifest,
  scheduleCourierPickup,
  generateShippingLabel,
  getTrackingByOrderId,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GenerateShippingLabelShiprocket);
