import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS, SUPPLIER_ACTION_LIST } from '../../../../../static/constants';
import { showToasterMessage, getFinancialYear } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchPaidInvoices = (payload, props) => {
  return dispatch => {
    let FY = getFinancialYear(props?.companyInfo);
    let url = '';
    if (payload.isForSupplier) {
      url = `supplierInvoice/getPaidInvoicesBySupplierId?supplierId=${payload.supplierId}&relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1)-1}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`;
    } else {
      url = `supplierInvoice/getAllPaidInvoices?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1)-1}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`;
    }
    dispatch({ type: COMMON_ACTIONS.SHOW_MODAL_LOADER });
    return axios
      .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/${url}`)
      .then(res => {
        if(!res.data){
          throw new Error();
        }
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: SUPPLIER_ACTION_LIST.SUPPLIER_PAID_INVOICES_BY_SUPPLIER, data });
        dispatch({ type: COMMON_ACTIONS.HIDE_MODAL_LOADER });
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_MODAL_LOADER });
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}



export const fetchPaidInvoicesCount = (payload, props) => {
  let FY = getFinancialYear(props?.companyInfo);
  const searchedTextQuery = payload.searchedText && payload.searchedText.length ? `&searchString=${payload.searchedText}` : '';

  let url = '';
  if (payload.isForSupplier) {
    url = `supplierInvoice/getPaidInvoicesCount?relationshipId=${payload.relationshipId}&supplierId=${payload.supplierId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`;
  } else {
    url = `supplierInvoice/getAllPaidInvoicesCount?relationshipId=${payload.relationshipId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}${searchedTextQuery}`;
  }
  
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/${url}`)
      .then(res => {
        dispatch({ type: SUPPLIER_ACTION_LIST.SUPPLIER_PAID_INVOICES_COUNT_BY_SUPPLIER, data: res.data });
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}

export const resetPaginatedData = () => {
  return dispatch => {
    dispatch({ type: SUPPLIER_ACTION_LIST.SUPPLIER_PAID_INVOICES_BY_SUPPLIER_RESET });
  }
}