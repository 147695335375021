import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS, CONSTANTS_TRANSACTIONS } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

const getStatusURI = (transactionName) => {
  let uri = '';

  // Need to update switch for supplier
  switch (transactionName) {
    case CONSTANTS_TRANSACTIONS.INQUIRY: {
      uri = 'lookupSalesInquiryStatus';
      break;
    }
    case CONSTANTS_TRANSACTIONS.SALES_QUOTE: {
      uri = 'lookupSalesQuoteStatus';
      break;
    }
    case CONSTANTS_TRANSACTIONS.SALES_ORDER: {
      uri = 'lookupSalesOrderStatus';
      break;
    }
    case CONSTANTS_TRANSACTIONS.SALES_INVOICE: {
      uri = 'lookupSalesInvoiceStatus';
      break;
    }
    case CONSTANTS_TRANSACTIONS.PACKAGE: {
      uri = 'lookupPackageStatus';
      break;
    }
    case CONSTANTS_TRANSACTIONS.SHIPMENT: {
      uri = 'lookupShipmentStatus';
      break;
    }
    case CONSTANTS_TRANSACTIONS.PURCHASE_REQUEST: {
      uri = 'lookupPRStatus';
      break;
    }
    case CONSTANTS_TRANSACTIONS.PURCHASE_ORDER: {
      uri = 'lookupPOStatus';
      break;
    }
    case CONSTANTS_TRANSACTIONS.INBOUND_DELIVERY: {
      uri = 'lookupInboundDeliveryStatus';
      break;
    }
    case CONSTANTS_TRANSACTIONS.PURCHASE_INVOICE: {
      uri = 'lookupPurchaseInvoiceStatus';
      break;
    }
    case CONSTANTS_TRANSACTIONS.STUDENT: {
      uri = 'lookupRegistrationStatus';
      break;
    }
    default:
      break;
  }
  return uri;
};

export const createStatus = (payload) => {
  let uri = getStatusURI(payload.transactionName);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/${uri}/createStatus`, payload)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        dispatch({ type: COMMON_ACTIONS.USER_STATUS_LIST_ADDITION, data: res.data });
        showToasterMessage({ messageType: 'success', description: 'Status saved successfully' });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else {
          err.actionToCall = COMMON_ACTIONS.USER_STATUS_LIST_ADDITION;
        }
      })
  }
}

export const fetchAllStatus = (payload) => {
  let uri = getStatusURI(payload.transactionName);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/${uri}/getAllStatus?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: COMMON_ACTIONS.USER_STATUS_LIST, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch(err => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });

        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.USER_STATUS_LIST;
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      })
  }
}

export const resetUserStatusList = () => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.USER_STATUS_LIST_RESET });
  }
}

export const deleteStatus = (payload, props) => {
  let uri = getStatusURI(props.transactionName);
 
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/${uri}/deleteStatus`, payload)
      .then(res => {
        if(props){
          props.fetchAllStatus({
            relationshipId: payload.relationshipId,
            transactionName: props.transactionName
          });
        }
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: (res || {}).message || 'Deleted successfully.',
          messageType: 'success'
        })
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.USER_STATUS_LIST_RESET;
        } else {
          showToasterMessage({
            description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
          })
        }
      })
  }
}

// const putStatusID = (transactionName, payload, id) => {

//   switch (transactionName) {
//     case CONSTANTS_TRANSACTIONS.INQUIRY: {
//       payload.inquiryStatusId = id;
//       break;
//     }
//     case CONSTANTS_TRANSACTIONS.SALES_QUOTE: {
//       payload.sqStatusId = id;
//       break;
//     }
//     case CONSTANTS_TRANSACTIONS.SALES_ORDER: {
//       payload.soStatusId = id;
//       break;
//     }
//     case CONSTANTS_TRANSACTIONS.SALES_INVOICE: {
//       payload.salesInvoiceStatusId = id;
//       break;
//     }
//     case CONSTANTS_TRANSACTIONS.SHIPMENT: {
//       payload.shipmentStatusId = id;
//       break;
//     }
//     case CONSTANTS_TRANSACTIONS.PURCHASE_REQUEST: {
//       payload.prStatusId = id;
//       break;
//     }
//     case CONSTANTS_TRANSACTIONS.PURCHASE_ORDER: {
//       payload.poStatusId = id;
//       break;
//     }
//     case CONSTANTS_TRANSACTIONS.INBOUND_DELIVERY: {
//       payload.poStatusId = id;
//       break;
//     }
//     case CONSTANTS_TRANSACTIONS.PURCHASE_INVOICE: {
//       payload.purchaseInvoiceStatusId = id;
//       break;
//     }
//     default:
//       break;
//   }
//   return payload;
// };