import React, { Fragment } from "react";
import { Skeleton, Pagination, DatePicker, Empty } from "antd";
import moment from "moment-timezone";

import { CONSTANTS } from "../../../../static/constants";
import {
  fetchPaginationDataIfNeeded,
  getMomentDateForUI,
  getCurrentDateForBackend,
  showToasterMessage,
} from "../../../../utils";
import { injectIntl, FormattedMessage } from "react-intl";
import PurchaseInvoiceDetail from "../../../../containers/modal/modalBody/supplier/PurchaseInvoiceDetail";
import PODetail from "../../../../containers/modal/modalBody/supplier/PurchaseOrderDetail";
import SupplierPaymentDetails from "../../../../containers/modal/modalBody/supplier/PaymentDetail";
// import { AG_GRID_CLASS_CONSTANTS } from "../../../../static/cssClassConstants";
import { FilterOutlined, UndoOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;

const openTransactionDetail = (data, props, type) => {
  const txType = type || data.txType;
  switch (txType) {
    case "Standard Purchase Order":
    case "Purchase Order": {
      const poPayload = {
        supplierId: data.supplierId,
        poMasterId: data.poMasterId,
      };
      props.pushModalToStack({
        modalBody: <PODetail {...props} poPayload={poPayload} />,
        width: "100%",
        hideFooter: true,
        wrapClassName: "modal-custom-detail",
      });
      break;
    }
    case "Invoice": {
      const invoicePayload = {
        supplierId: data.supplierId,
        invoiceMasterId: data.invoiceMasterId,
      };
      props.pushModalToStack({
        modalBody: (
          <PurchaseInvoiceDetail {...props} invoicePayload={invoicePayload} />
        ),
        width: "100%",
        hideFooter: true,
        wrapClassName: "modal-custom-detail",
      });
      break;
    }
    case "Payment": {
      const paymentPayload = {
        supplierId: data.supplierId,
        supplierPaymentId:
          data.supplierPaymentId ||
          (data.supplierTxId || {}).supplierTxPaymentId,
      };
      const modalData = {
        title: <div className="ant-modal-title">Purchase Payment</div>,
        modalBody: <SupplierPaymentDetails paymentPayload={paymentPayload} />,
        width: 500,
        hideFooter: true,
      };
      props.showModal(modalData);
      break;
    }
    default: {
      break;
    }
  }
};

// const actionMenu = (data, props) => (
//     <Menu className="cell-action-dropdown">

//            <Menu.Item key="0" onClick={() => {
//                props.history.push({
//                 pathname: '/admin/purchase-invoice-update',
//                 state: {
//                     supplierId: data.supplierId,
//                     invoiceMasterId: data.invoiceMasterId,
//                     update: true
//                 }
//             })
//             }}>
//                <i className="fa fa-pencil-square-o" />
//                <span className="cursor-pointer">Update</span>
//            </Menu.Item>
//                <Menu.Item key="1" onClick={() => {
//                    props.history.push({
//                     pathname: '/admin/purchase-invoice-update',
//                     state: {
//                         supplierId: data.supplierId,
//                         invoiceMasterId: data.invoiceMasterId,
//                         clone: true
//                     }
//                 })
//                }}>
//                    <i className="fa fa-copy" />
//                    <span className="cursor-pointer">Clone</span>
//                </Menu.Item>
//                <Menu.Item key="3" onClick={() => {  }}>
//                    <i className="fa fa-trash-o" />
//                    <span className="cursor-pointer">Delete</span>
//                </Menu.Item>

//    </Menu >
// );

const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const handlePageSizeChange = (pageNumber, pageSize, props) => {
  const payload = {
    ...props.payload,
    pageNumber: pageNumber,
    pageSize: pageSize,
  };
  props.resetInvoiceTransactionListData();
  props.fetchSupplierInvoiceList(payload);
  //props.updateState({ pageSize: pageSize, pageNumber: pageNumber })
};

const loadPageData = (pageNumber, pageSize, props) => {
  const payload = {
    ...props.payload,
    pageNumber: pageNumber,
    pageSize: pageSize,
  };
  fetchPaginationDataIfNeeded(
    "fetchSupplierInvoiceList",
    "invoiceList",
    props,
    payload
  );
};

const Invoice = (props) => {
  const { companyInfo } = props;
  const _getInvoiceList = (dataList) => {
    dataList &&
      dataList.forEach((dt, dtIndex) => {
        if (dt.supplierPaymentsList && dt.supplierPaymentsList.length)
          dt.children = dt.supplierPaymentsList;
        dt.children &&
          dt.children.forEach((dt2) => {
            dt2.invoiceNumber = dt2.paymentNumber;
            dt2.invoiceTotalAmount = dt.invoiceTotalAmount;
            dt2.paymentTermName = dt.paymentTermName;
          });
      });

    return dataList || [];
  };

  const invoiceList = _getInvoiceList(props.invoiceList[props.pageNumber || 1]);
  const invoiceListCount = props.invoiceListCount;
  // const columnDefs = [
  //   {
  //     title: (
  //       <FormattedMessage
  //         id="supplier.detail.transaction.gridHeader.transaction"
  //         defaultMessage=""
  //       />
  //     ),
  //     dataIndex: "txType",
  //     key: "txType",
  //   },
  //   {
  //     title: (
  //       <FormattedMessage
  //         id="supplier.detail.transaction.gridHeader.invoiceNumber"
  //         defaultMessage=""
  //       />
  //     ),
  //     dataIndex: "invoiceNumber",
  //     key: "invoiceNumber",
  //     render: (invoiceNumber, row) => (
  //       <div>
  //         <div
  //           className="cursor-pointer"
  //           onClick={() => openTransactionDetail(row, props, "Invoice")}
  //         >
  //           {invoiceNumber}
  //         </div>
  //       </div>
  //     ),
  //   },
  //   {
  //     title: (
  //       <FormattedMessage
  //         id="supplier.detail.transaction.gridHeader.supplierInvoiceNumber"
  //         defaultMessage=""
  //       />
  //     ),
  //     dataIndex: "supplierInvoiceNumber",
  //     key: "supplierInvoiceNumber",
  //     render: (supplierInvoiceNumber, row) => (
  //       <div>
  //         <div
  //           className="cursor-pointer"
  //           onClick={() => openTransactionDetail(row, props, "Invoice")}
  //         >
  //           {supplierInvoiceNumber}
  //         </div>
  //       </div>
  //     ),
  //   },
  //   {
  //     title: (
  //       <FormattedMessage
  //         id="supplier.detail.transaction.gridHeader.poNumber"
  //         defaultMessage=""
  //       />
  //     ),
  //     dataIndex: "supplierPoNumber",
  //     key: "supplierPoNumber",
  //     render: (supplierPoNumber, row) => (
  //       <div>
  //         <div
  //           className="cursor-pointer"
  //           onClick={() => openTransactionDetail(row, props, "Purchase Order")}
  //         >
  //           {supplierPoNumber}
  //         </div>
  //       </div>
  //     ),
  //   },
  //   {
  //     title: (
  //       <FormattedMessage
  //         id="supplier.detail.transaction.gridHeader.amount"
  //         defaultMessage=""
  //       />
  //     ),
  //     dataIndex: "invoiceTotalAmount",
  //     key: "invoiceTotalAmount",
  //     className: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
  //     render: (amount, data) => {
  //       return amount ? (
  //         <span>
  //           {" "}
  //           {data.foreignCurrencyIcon ? (
  //             <i className={data.foreignCurrencyIcon}></i>
  //           ) : data.foreignCurrency ? (
  //             <span>{data.foreignCurrency}</span>
  //           ) : props.companyInfo.currencyIcon ? (
  //             <i className={props.companyInfo.currencyIcon}></i>
  //           ) : (
  //             props.companyInfo.currencyCode + " "
  //           )}
  //           {amount
  //             .toFixed(2)
  //             .toString()
  //             .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
  //         </span>
  //       ) : (
  //         "-"
  //       );
  //     },
  //   },
  //   {
  //     title: (
  //       <FormattedMessage
  //         id="supplier.detail.transaction.gridHeader.paymentDueDate"
  //         defaultMessage=""
  //       />
  //     ),
  //     dataIndex: "invoiceDueDate",
  //     key: "invoiceDueDate",
  //     render: (invoiceDueDate) => (
  //       <div>{moment(new Date(invoiceDueDate)).format(format)}</div>
  //     ),
  //   },
  //   {
  //     title: (
  //       <FormattedMessage
  //         id="supplier.detail.transaction.gridHeader.paymentTerm"
  //         defaultMessage=""
  //       />
  //     ),
  //     dataIndex: "paymentTermName",
  //     key: "paymentTermName",
  //   },
  //   {
  //     title: (
  //       <FormattedMessage
  //         id="supplier.detail.transaction.gridHeader.date"
  //         defaultMessage=""
  //       />
  //     ),
  //     dataIndex: "invoiceDate",
  //     key: "invoiceDate",
  //     render: (invoiceDate) => (
  //       <div>{moment(new Date(invoiceDate)).format(format)}</div>
  //     ),
  //   },
  //   {
  //     title: <FormattedMessage id="common.status" defaultMessage="" />,
  //     dataIndex: "status",
  //     key: "status",
  //     render: (status) => (
  //       <div>
  //         <span className={["status-code", status.toLowerCase()].join(" ")}>
  //           <FormattedMessage id={status} defaultMessage={status} />
  //         </span>
  //       </div>
  //     ),
  //   },
  //   // {
  //   //     title: "",
  //   //     dataIndex: 'action',
  //   //     key: 'action',
  //   //     render: (param, row) => <div>
  //   //         <Dropdown overlay={actionMenu(row, props)} trigger={['click']} overlayClassName="action-listing-dropdown">
  //   //             <Button className="action-btn small">
  //   //                 <i className="fa fa-ellipsis-v" />
  //   //             </Button>
  //   //         </Dropdown>
  //   //     </div>
  //   // }
  // ];

  return (
    <Fragment>
      {/* <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            <FormattedMessage id="invoiceSummary.text" />
          </div>
          <div className="vertical-sep" />
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        <div className="top-view-right"></div>
      </div> */}
      <div className="view-container">
          <div className="view-container-actions">
            <div className="left-actions">
              <div className="table-heading">Invoice</div>
            </div>

            <div className="right-actions">
            <RangePicker
                                allowEmpty={true}
                                format={format}
                                placeholder={['Start date', 'End date']}
                                value={[props.startDate ? getMomentDateForUI({ date: new Date(props.startDate), format }) : null, props.endDate ? getMomentDateForUI({ date: new Date(props.endDate), format }) : null]}
                                onChange={(dates) => {
                                    if ((dates || []).length === 2) {
                                        props.updateState({ startDate: dates[0], endDate: dates[1] });
                                           
                                        const payload = {
                                            supplierId: props.supplierId, relationshipId: companyInfo.relationshipId, pageNumber: 1, pageSize: props.pageSize || 100,
                                            filters: {
                                                startDate: getCurrentDateForBackend(dates[0]),
                                                endDate: getCurrentDateForBackend(dates[1]),
                                                supplierId: props.supplierId,
                                            }
                                        };
                                        props.resetInvoiceTransactionListData();
                                        props.fetchSupplierInvoiceList(payload);
                                        props.fetchSupplierInvoiceCount(payload);

                                    } else {
                                        props.updateState({ startDate: null, endDate: null });
                                        const payload = {
                                            supplierId: props.supplierId, relationshipId: companyInfo.relationshipId, pageNumber: 1, pageSize: props.pageSize || 25,
                                            filters: { supplierId: props.supplierId}
                                        };
                                        props.resetInvoiceTransactionListData();
                                        props.fetchSupplierInvoiceList(payload);
                                        props.fetchSupplierInvoiceCount(payload);
                                    }
                                }}
                            />
              {/* <RangePicker
                allowEmpty={true}
                format={format}
                placeholder={["Start date", "End date"]}
                value={[
                  props.startDate
                    ? getMomentDateForUI({
                        date: new Date(props.startDate),
                        format,
                      })
                    : null,
                  props.endDate
                    ? getMomentDateForUI({
                        date: new Date(props.endDate),
                        format,
                      })
                    : null,
                ]}
                onChange={(dates) => {
                  if (dates) {
                    props.updateState({
                      startDate: dates[0],
                      endDate: dates[1],
                    });
                  } else {
                    props.updateState({ startDate: null, endDate: null });
                  }
                }}
              /> */}
              {/* <button
                className="ant-btn ant-btn-default vab ml5"
                onClick={() => {
                  if (!props.startDate && !props.endDate) {
                    return showToasterMessage({
                      messageType: "error",
                      description: "Please select date range",
                    });
                  }
                  const payload = {
                    supplierId: props.supplierId,
                    relationshipId: companyInfo.relationshipId,
                    pageNumber: 1,
                    pageSize: props.pageSize || 100,
                    filters: {
                      startDate: props.startDate
                      ? getCurrentDateForBackend(props.startDate)
                      : null,
                      endDate: props.endDate
                      ? getCurrentDateForBackend(props.endDate)
                      : null,
                      supplierId: props.supplierId,
                    },
                  };
                  props.resetInvoiceTransactionListData();
                  props.fetchSupplierInvoiceList(payload);
                  props.fetchSupplierInvoiceCount(payload);
                }}
              >
                {<FilterOutlined />} Apply Filter
              </button>
              <button
                className="ant-btn ant-btn-default vab ml5"
                onClick={() => {
                  props.updateState({
                    startDate: null,
                    endDate: null,
                  });
                  const payload = {
                    supplierId: props.supplierId,
                    relationshipId: companyInfo.relationshipId,
                    pageNumber: 1,
                    pageSize: props.pageSize || 100,
                    filters: {},
                  };
                  props.resetInvoiceTransactionListData();
                  props.fetchSupplierInvoiceList(payload);
                  props.fetchSupplierInvoiceCount(payload);
                }}
              >
                {<UndoOutlined />} Clear Filter
              </button> */}
            </div>
          </div>
        <Skeleton loading={props.isLoading} active paragraph={{ rows: 8 }}>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="serial.no" defaultMessage="" />
                  </th>
                  <th>
                    <FormattedMessage
                      id="supplier.detail.transaction.gridHeader.transaction"
                      defaultMessage=""
                    />
                  </th>
                  <th>
                    {" "}
                    <FormattedMessage
                      id="supplier.detail.transaction.gridHeader.invoiceNumber"
                      defaultMessage=""
                    />
                  </th>
                  <th>
                    {" "}
                    <FormattedMessage
                      id="supplier.detail.transaction.gridHeader.supplierInvoiceNumber"
                      defaultMessage=""
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="supplier.detail.transaction.gridHeader.poNumber"
                      defaultMessage=""
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="supplier.detail.transaction.gridHeader.amount"
                      defaultMessage=""
                    />
                  </th>
                  <th>
                    {" "}
                    <FormattedMessage
                      id="supplier.detail.transaction.gridHeader.paymentDueDate"
                      defaultMessage=""
                    />
                  </th>
                  <th>
                    {" "}
                    <FormattedMessage
                      id="supplier.detail.transaction.gridHeader.paymentTerm"
                      defaultMessage=""
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="supplier.detail.transaction.gridHeader.date"
                      defaultMessage=""
                    />
                  </th>
                  <th>
                    <FormattedMessage id="common.status" defaultMessage="" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {invoiceList.length? invoiceList.map((e, i) => {
                  return (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{e.txType || <div className="empty-data-box"></div>}</td>
                      <td style={{ width: "15%" }}>
                        {
                          <div
                            className="cursor-pointer"
                            onClick={() =>
                              openTransactionDetail(e, props, "Invoice")
                            }
                          >
                            {e.invoiceNumber}
                          </div>
                        }
                      </td>
                      <td style={{ width: "15%" }}>
                        {
                          <div
                            className="cursor-pointer"
                            onClick={() =>
                              openTransactionDetail(e, props, "Invoice")
                            }
                          >
                            {e.supplierInvoiceNumber || <div className="empty-data-box"></div>}
                          </div>
                        }
                      </td>
                      <td style={{ width: "15%" }}>
                        {
                          <div
                            className="cursor-pointer"
                            onClick={() =>
                              openTransactionDetail(e, props, "Purchase Order")
                            }
                          >
                            {e.supplierPoNumber || <div className="empty-data-box"></div>}
                          </div>
                        }
                      </td>
                      <td style={{ width: "15%" }}>
                        {
                          <span>
                            {" "}
                            {e.foreignCurrencyIcon ? (
                              <i className={e.foreignCurrencyIcon}></i>
                            ) : e.foreignCurrency ? (
                              <span>{e.foreignCurrency}</span>
                            ) : props.companyInfo.currencyIcon ? (
                              <i className={props.companyInfo.currencyIcon}></i>
                            ) : (
                              props.companyInfo.currencyCode + " "
                            )}
                            {e.invoiceTotalAmount
                              .toFixed(2)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                          </span>
                        }
                      </td>
                      <td style={{ width: "15%" }}>
                        {
                          <div>
                            {moment(new Date(e.invoiceDueDate)).format(format)}
                          </div>
                        }
                      </td>
                      <td style={{ width: "15%" }}>{e.paymentTermName}</td>
                      <td style={{ width: "15%" }}>
                        {
                          <div>
                            {moment(new Date(e.invoiceDate)).format(format)}
                          </div>
                        }
                      </td>
                      <td className="one-line-view">
                        {
                          <span
                            className={[
                              "status-code",
                              e.status.toLowerCase(),
                            ].join(" ")}
                          >
                            
                            {e.status ?<FormattedMessage id={e.status}/>:''}
                          </span>
                        }
                      </td>
                    </tr>
                  );
                }): <tr key="empty-data">
                <td colSpan={"100%"} style={{borderBottom:"none"}}>
                  <Empty />
                </td>
              </tr>}
              </tbody>
            </table>
            {/* <Table
              pagination={false}
              columns={columnDefs}
              dataSource={invoiceList || []}
              className="customer-transactions-table"
            /> */}
            {/* <div className="ag-theme-balham" style={agGridStyle} >
                        <CustomAgGrid
                            columnDefs={columnDefs}
                            rowData={invoiceList || []}
                            rowDragManaged={true}
                            domLayout={"autoHeight"}
                            animateRows={true}
                            onGridSizeChanged={onFirstDataRendered}
                            getRowHeight={getRowHeight}
                        >
                        </CustomAgGrid>
                    </div> */}
          </div>
        </Skeleton>
      </div>
      <div className="footer">
        <div className="f-left"></div>
        <div className="f-right">
          <Pagination
            size="small"
            total={invoiceListCount}
            showTotal={(total) => {
              return (
                props.intl.formatMessage({
                  id: "pagination.text.total",
                  defaultMessage: "",
                }) +
                ` ${total} ` +
                props.intl.formatMessage({
                  id: "pagination.text.items",
                  defaultMessage: "",
                })
              );
            }}
            defaultPageSize={100}
            showSizeChanger
            pageSizeOptions={[10, 25, 50, 100, 200]}
            showQuickJumper
            onChange={(pageNumber, pageSize) => {
              loadPageData(pageNumber, pageSize, props);
              props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
            }}
            onShowSizeChange={(pageNumber, pageSize) => {
              handlePageSizeChange(pageNumber, pageSize, props);
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default injectIntl(Invoice);
