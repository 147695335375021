import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMMON_ACTIONS, SO_ACTION_LIST, SALES_INVOICE_ACTION_LIST } from '../../../../static/constants';
import { getCurrentDateForBackend, formatDateForBackend, showToasterMessage, getFinancialYear } from '../../../../utils'
import { LEDGER_TYPE, ACCOUNTING_ENTRY_TYPE, SALE_INVOICE_TYPE, TX_TYPE, TX_STATUS } from "../../../../static/constants";
//import { resetPaginatedData, getSalesInvoiceCount, getSalesInvoiceDraftCount } from '../Listing/action';
import * as filter from 'lodash.filter';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const createPerformaInvoice = (payloadObject) => {
    const props = payloadObject.props;

    const invoiceData = _getInvoiceData(payloadObject);
    const payload = {
        relationshipId: (props.companyInfo || {}).relationshipId,
        customerId: props.customer && props.customer.customerId,
        salesOrderMasterId: props.salesOrderMasterId,
        invoiceProformaId: props.invoiceProformaId || 0,
        //dateCreated: formatDateForBackend(new Date() ),
        invoiceData: JSON.stringify(invoiceData, true)
    }
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios
            .post(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customerInvoice/saveOrUpdateProforma`, payload)
            .then(res => {
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
                showToasterMessage({ messageType: 'success', description: 'Proforma invoice saved successfully' });
                props.openSalesInvoiceDetails({...res.data, ...JSON.parse(res.data.invoiceData)});
                props.popModalFromStack();
                props.history.push({
                    pathname: '/admin/sales-invoice-list',
                    state: { activeTab: 'Proforma' }
                })
            })
            .catch((err) => {
                if (lang[((err.response || {}).data || {}).message]) {
                    showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
                }
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            })
    }
}

export const fetchSoDataForInvoice = (payload) => {
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios
            .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesOrder/getSalesOrderData?customerId=${payload.customerId}&relationshipId=${payload.relationshipId}&salesOrderMasterId=${payload.soMasterId}`)
            .then(res => {
                dispatch({ type: SO_ACTION_LIST.SO_INVOICE_DATA, data: res.data });
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            })
            .catch((err) => {
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
                if (!err.__isRetryRequest) {
                    //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
                } else {
                    err.actionToCall = SO_ACTION_LIST.SO_INVOICE_DATA;
                }
            })
    }
}

const _getInvoiceData = (payload) => {
    const props = payload.props;
    const state = payload.state;
    const gridApi = payload.gridApi;
    // const gridObject = payload.gridObject;
    const isPlaceOfSupplySameAsRelationship = payload.isPlaceOfSupplySameAsRelationship;
    const companyInfo = props.companyInfo || {};
    const userInfo = props.userInfo || {};
    const financialYear = getFinancialYear(props.companyInfo);
    let invoiceMaster = {
        version: props.version,
        invoiceDraftId: props.invoiceDraftId,
        customerId: props.customer && props.customer.customerId,
        fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
        fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
        projectName: props.projectName,
        projectMasterId: props.projectMasterId,
        projectNumber: props.projectNumber,
        salesOrderMasterId: props.salesOrderMasterId,
        isInventoryInvoice: props.salesOrderMasterId ? true : false,
        soNumber: props.soNumber,
        expenseId: props.expenseId,
        salesQuotationMasterId: props.salesQuotationMasterId,
        quotationNumber: props.quotationNumber,
        rfqNumber: props.rfqNumber,
        rfqMasterId: props.rfqMasterId,
        isCashInvoice: props.isCashSales ? 1 : 0,
        invoiceTotalAmount: payload.grandTotal ? Number(payload.grandTotal) : Number(state.total),
        invoiceType: SALE_INVOICE_TYPE.STANDARD,
        customerName: props.customer && props.customer.companyName,
        relationshipName: companyInfo && companyInfo.storeName,
        customerGstNumber: props.customer && props.customer.gstNumber,
        customerPanNumber: props.customer && props.customer.panNumber,
        placeOfSupply: props.placeOfSupply,
        status: TX_STATUS.OPEN,
        invoiceDate: props.invoiceDate && formatDateForBackend(props.invoiceDate),
        invoiceDueDate: props.invoiceDueDate && formatDateForBackend(props.invoiceDueDate),
        relationshipId: companyInfo.relationshipId,
        customerPONumber: props.customerPo,
        referenceNumber: props.customerRef,
        remarksCustomer: props.customerRemarks,
        remarksInternal: props.internalRemarks,
        paymentTermId: props.customerPaymentTerm && props.customerPaymentTerm.paymentTermId,
        paymentTermName: props.customerPaymentTerm && props.customerPaymentTerm.termName,
        paymentTermDays: props.paymentTermDays,
        //dateCreated: getCurrentDateForBackend(new Date()),
        createdByUserId: userInfo && userInfo.userId,
        createdByEmpId: userInfo && userInfo.relationshipEmployeeId,
        footer: props.footer,
        totalDiscountGiven: Number(state.totalDiscount || 0),
        txType: TX_TYPE.INVOICE,
        expenseName: props.expenseName,
        customerInvoiceCOATxList: [],
        customerInvoiceDetailsList: [],
        boLocationSalesInvoiceList: [],
        documentName: props.docName,
        isSOConversion: (props.isSoConversionToInvoice || props.isSoConversion) ? 1 : 0,
        isShipmentConversion: props.isShipmentConversion ? 1 : 0,
        currencyCode: companyInfo.currencyCode,
        nameOfTransport: props.nameOfTransport,
        vehicleNumber: props.vehicleNumber,
        roadPermitNumber: props.roadPermitNumber,
        freightType: props.freightType,
        consignee: props.consignee,
        ewayBillNo: props.ewayBillNo,
        station: props.station
    };

    invoiceMaster.customerInvoiceCOATxList.push({
        relationshipId: companyInfo.relationshipId,
        relationshipName: companyInfo.storeName,
        fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
        fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
        customerId: props.customer && props.customer.customerId,
        //dateCreated: getCurrentDateForBackend(new Date()),
        createdBy: userInfo.userId,
        amount: Number(state.subtotalWithoutDiscount || 0),
        txDate: formatDateForBackend(new Date()),
        txType: LEDGER_TYPE.TYPE_SALES,
        accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
        ledgerAccountId: props.salesLedgerId,
        customerName: props.customer && props.customer.companyName
    });

    invoiceMaster.customerInvoiceCOATxList.push({
        relationshipId: companyInfo.relationshipId,
        relationshipName: companyInfo.storeName,
        fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
        fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
        customerId: props.customer && props.customer.customerId,
        //dateCreated: getCurrentDateForBackend(new Date()),
        createdBy: userInfo.userId,
        amount: Number(state.subtotalWithoutDiscount || 0),
        txDate: formatDateForBackend(new Date()),
        txType: LEDGER_TYPE.TYPE_SALES,
        accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
        ledgerAccountId: props.salesDebtorLedgerId,
        customerName: props.customer && props.customer.companyName
    });

    if (state.rootDiscountAmount) {
        invoiceMaster.customerInvoiceCOATxList.push({
            relationshipId: companyInfo.relationshipId,
            relationshipName: companyInfo.storeName,
            fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
            fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
            customerId: props.customer && props.customer.customerId,
            //dateCreated: getCurrentDateForBackend(new Date()),
            createdBy: userInfo.userId,
            amount: Number(state.rootDiscountAmount || 0),
            amountPercent: Number(state.rootDiscountPercent || 0),
            txDate: formatDateForBackend(new Date()),
            txType: LEDGER_TYPE.TYPE_DISCOUNT,
            accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
            ledgerAccountId: props.discountDebtorLedgerId,
            customerName: props.customer && props.customer.companyName
        });

        invoiceMaster.customerInvoiceCOATxList.push({
            relationshipId: companyInfo.relationshipId,
            relationshipName: companyInfo.storeName,
            fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
            fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
            customerId: props.customer && props.customer.customerId,
            //dateCreated: getCurrentDateForBackend(new Date()),
            createdBy: userInfo.userId,
            amount: Number(state.rootDiscountAmount || 0),
            amountPercent: Number(state.rootDiscountPercent || 0),
            txDate: formatDateForBackend(new Date()),
            txType: LEDGER_TYPE.TYPE_DISCOUNT,
            accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
            ledgerAccountId: props.salesDiscountLedgerId,
            customerName: props.customer && props.customer.companyName
        });
    }

    if (state.expenseAmount) {
        invoiceMaster.customerInvoiceCOATxList.push({
            relationshipId: companyInfo.relationshipId,
            relationshipName: companyInfo.storeName,
            fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
            fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
            customerId: props.customer && props.customer.customerId,
            //dateCreated: getCurrentDateForBackend(new Date()),
            createdBy: userInfo.userId,
            amount: Number(state.expenseAmount || 0),
            txDate: formatDateForBackend(new Date()),
            txType: LEDGER_TYPE.TYPE_EXPENSE,
            accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
            ledgerAccountId: props.expenseLedgerId,
            customerName: props.customer && props.customer.companyName
        });

        invoiceMaster.customerInvoiceCOATxList.push({
            relationshipId: companyInfo.relationshipId,
            relationshipName: companyInfo.storeName,
            fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
            fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
            customerId: props.customer && props.customer.customerId,
            //dateCreated: getCurrentDateForBackend(new Date()),
            createdBy: userInfo.userId,
            amount: Number(state.expenseAmount || 0),
            txDate: formatDateForBackend(new Date()),
            txType: LEDGER_TYPE.TYPE_EXPENSE,
            accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
            ledgerAccountId: props.expenseDebtorLedgerId,
            customerName: props.customer && props.customer.companyName
        });
    }

    if (props.relationshipBillingAddress && (props.relationshipBillingAddress.locationId || props.relationshipBillingAddress.locationSOId)) {
       // props.relationshipBillingAddress.dateCreated = getCurrentDateForBackend(new Date());
        invoiceMaster.boLocationSalesInvoiceList.push(props.relationshipBillingAddress);
    }

    if (props.customerShippingAddress && (props.customerShippingAddress.locationId || props.customerShippingAddress.locationSOId)) {
       // props.customerShippingAddress.dateCreated = getCurrentDateForBackend(new Date());
        invoiceMaster.boLocationSalesInvoiceList.push(props.customerShippingAddress);
    }

    if (props.customerBillingAddress && (props.customerBillingAddress.locationId || props.customerBillingAddress.locationSOId)) {
       // props.customerBillingAddress.dateCreated = getCurrentDateForBackend(new Date());
        invoiceMaster.boLocationSalesInvoiceList.push(props.customerBillingAddress);
    }
    if (props.selectedSalesOrder && props.selectedSalesOrder.length > 0) {
        props.selectedSalesOrder.forEach((soData) => {
            soData.customerSalesOrderDetailsList.forEach((data, index) => {
                const itemId = data.selectedProductValue && data.selectedProductValue[0];
                //if (!itemId) return;
                let variantId = data.selectedProductValue && data.selectedProductValue.length === 2 ? data.selectedProductValue[1] : 0;
                if (data.productObj && !data.productObj.hasVariant && !variantId) {
                    variantId = data.productObj.inventoryItemVariantList ? data.productObj.inventoryItemVariantList[0].variantId : data.inventoryItemVariantId;
                }
                let product = {};
                let itemLevelCoaTxList = [];
                if (itemId) {
                    product = data.productObj || {};
                }
                let variant = {};
                if (variantId) {
                    variant = data.variant || {};
                }
                let itemLevelAmount = ((data || {}).rate || 0) * ((data || {}).qty || 0);

                if (data.discount) {
                    const discountOnItem = itemLevelAmount ? ((data.discount * 0.01) * itemLevelAmount) : 0;
                    itemLevelAmount = itemLevelAmount - discountOnItem;
                    itemLevelCoaTxList.push({
                        relationshipId: companyInfo.relationshipId,
                        relationshipName: companyInfo.storeName,
                        fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
                        fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
                        customerId: props.customer && props.customer.customerId,
                        //dateCreated: getCurrentDateForBackend(new Date()),
                        createdBy: userInfo.userId,
                        amount: Number(discountOnItem || 0),
                        amountPercent: Number(data.discount || 0),
                        txDate: formatDateForBackend(new Date()),
                        txType: LEDGER_TYPE.TYPE_DISCOUNT,
                        accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
                        ledgerAccountId: props.discountDebtorLedgerId,
                        customerName: props.customer && props.customer.companyName
                    });

                    itemLevelCoaTxList.push({
                        relationshipId: companyInfo.relationshipId,
                        relationshipName: companyInfo.storeName,
                        fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
                        fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
                        customerId: props.customer && props.customer.customerId,
                        //dateCreated: getCurrentDateForBackend(new Date()),
                        createdBy: userInfo.userId,
                        amount: Number(discountOnItem || 0),
                        amountPercent: Number(data.discount || 0),
                        txDate: formatDateForBackend(new Date()),
                        txType: LEDGER_TYPE.TYPE_DISCOUNT,
                        accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
                        ledgerAccountId: props.salesDiscountLedgerId,
                        customerName: props.customer && props.customer.companyName
                    })
                }

                if (data.tax) {
                    if (companyInfo.countryName !== 'India') {
                        itemLevelCoaTxList.push({
                            relationshipId: companyInfo.relationshipId,
                            relationshipName: companyInfo.storeName,
                            fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
                            fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
                            customerId: props.customer && props.customer.customerId,
                            //dateCreated: getCurrentDateForBackend(new Date()),
                            createdBy: userInfo.userId,
                            amount: (itemLevelAmount ? ((data.tax * 0.01) * itemLevelAmount) : 0).toFixed(2),
                            amountPercent: Number(data.tax || 0),
                            txDate: formatDateForBackend(new Date()),
                            txType: LEDGER_TYPE.TYPE_TAX,
                            taxName: data.taxName,
                            taxId: data.taxId,
                            accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
                            ledgerAccountId: props.salesOutputTaxLedgerId,
                            customerName: props.customer && props.customer.companyName
                        });

                        itemLevelCoaTxList.push({
                            relationshipId: companyInfo.relationshipId,
                            relationshipName: companyInfo.storeName,
                            fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
                            fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
                            customerId: props.customer && props.customer.customerId,
                            //dateCreated: getCurrentDateForBackend(new Date()),
                            createdBy: userInfo.userId,
                            amount: (itemLevelAmount ? ((data.tax * 0.01) * itemLevelAmount) : 0).toFixed(2),
                            amountPercent: Number(data.tax || 0),
                            txDate: formatDateForBackend(new Date()),
                            txType: LEDGER_TYPE.TYPE_TAX,
                            taxName: data.taxName,
                            accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
                            taxId: data.taxId,
                            ledgerAccountId: props.taxDebtorLedgerId,
                            customerName: props.customer && props.customer.companyName
                        });
                    }
                    else if (isPlaceOfSupplySameAsRelationship) {
                        itemLevelCoaTxList.push({
                            relationshipId: companyInfo.relationshipId,
                            relationshipName: companyInfo.storeName,
                            fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
                            fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
                            customerId: props.customer && props.customer.customerId,
                            //dateCreated: getCurrentDateForBackend(new Date()),
                            createdBy: userInfo.userId,
                            amount: (itemLevelAmount ? (((data.tax) * 0.01) * itemLevelAmount)/2 : 0).toFixed(2),
                            amountPercent: (data.tax)/2,
                            txDate: formatDateForBackend(new Date()),
                            txType: LEDGER_TYPE.TYPE_TAX,
                            taxName: LEDGER_TYPE.TYPE_TAX_CGST,
                            taxId: data.taxId,
                            accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
                            ledgerAccountId: props.salesOutputTaxLedgerId,
                            customerName: props.customer && props.customer.companyName
                        });

                        itemLevelCoaTxList.push({
                            relationshipId: companyInfo.relationshipId,
                            relationshipName: companyInfo.storeName,
                            fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
                            fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
                            customerId: props.customer && props.customer.customerId,
                            //dateCreated: getCurrentDateForBackend(new Date()),
                            createdBy: userInfo.userId,
                            amount: (itemLevelAmount ? (((data.tax) * 0.01) * itemLevelAmount)/2 : 0).toFixed(2),
                            amountPercent: (data.tax)/2,
                            txDate: formatDateForBackend(new Date()),
                            txType: LEDGER_TYPE.TYPE_TAX,
                            taxName: LEDGER_TYPE.TYPE_TAX_CGST,
                            taxId: data.taxId,
                            accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
                            ledgerAccountId: props.taxDebtorLedgerId,
                            customerName: props.customer && props.customer.companyName
                        });


                        itemLevelCoaTxList.push({
                            relationshipId: companyInfo.relationshipId,
                            relationshipName: companyInfo.storeName,
                            fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
                            fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
                            customerId: props.customer && props.customer.customerId,
                            //dateCreated: getCurrentDateForBackend(new Date()),
                            createdBy: userInfo.userId,
                            amount: (itemLevelAmount ? (((data.tax) * 0.01) * itemLevelAmount)/2 : 0).toFixed(2),
                            amountPercent: (data.tax)/2,
                            txDate: formatDateForBackend(new Date()),
                            txType: LEDGER_TYPE.TYPE_TAX,
                            taxName: LEDGER_TYPE.TYPE_TAX_SGST,
                            taxId: data.taxId,
                            accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
                            ledgerAccountId: props.salesOutputTaxLedgerId,
                            customerName: props.customer && props.customer.companyName
                        });

                        itemLevelCoaTxList.push({
                            relationshipId: companyInfo.relationshipId,
                            relationshipName: companyInfo.storeName,
                            fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
                            fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
                            customerId: props.customer && props.customer.customerId,
                            //dateCreated: getCurrentDateForBackend(new Date()),
                            createdBy: userInfo.userId,
                            amount: (itemLevelAmount ? (((data.tax) * 0.01) * itemLevelAmount)/2 : 0).toFixed(2),
                            amountPercent: (data.tax)/2,
                            txDate: formatDateForBackend(new Date()),
                            txType: LEDGER_TYPE.TYPE_TAX,
                            taxName: LEDGER_TYPE.TYPE_TAX_SGST,
                            taxId: data.taxId,
                            accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
                            ledgerAccountId: props.taxDebtorLedgerId,
                            customerName: props.customer && props.customer.companyName
                        });

                    } else {
                        itemLevelCoaTxList.push({
                            relationshipId: companyInfo.relationshipId,
                            relationshipName: companyInfo.storeName,
                            fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
                            fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
                            customerId: props.customer && props.customer.customerId,
                            //dateCreated: getCurrentDateForBackend(new Date()),
                            createdBy: userInfo.userId,
                            amount: (itemLevelAmount ? ((data.tax * 0.01) * itemLevelAmount) : 0).toFixed(2),
                            amountPercent: Number(data.tax || 0),
                            txDate: formatDateForBackend(new Date()),
                            txType: LEDGER_TYPE.TYPE_TAX,
                            taxName: LEDGER_TYPE.TYPE_TAX_IGST,
                            accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
                            ledgerAccountId: props.salesOutputTaxLedgerId,
                            customerName: props.customer && props.customer.companyName
                        });

                        itemLevelCoaTxList.push({
                            relationshipId: companyInfo.relationshipId,
                            relationshipName: companyInfo.storeName,
                            fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
                            fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
                            customerId: props.customer && props.customer.customerId,
                            //dateCreated: getCurrentDateForBackend(new Date()),
                            createdBy: userInfo.userId,
                            amount: (itemLevelAmount ? ((data.tax * 0.01) * itemLevelAmount) : 0).toFixed(2),
                            amountPercent: Number(data.tax || 0),
                            txDate: formatDateForBackend(new Date()),
                            txType: LEDGER_TYPE.TYPE_TAX,
                            taxName: LEDGER_TYPE.TYPE_TAX_IGST,
                            accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
                            ledgerAccountId: props.taxDebtorLedgerId,
                            customerName: props.customer && props.customer.companyName
                        });
                    }
                }

                invoiceMaster.customerInvoiceDetailsList.push({
                    version: data.version,
                    salesOrderMasterId: soData.salesOrderMasterId,
                    soNumber: soData.soNumber,
                    relationshipId: companyInfo && companyInfo.relationshipId,
                    customerId: props.customer && props.customer.customerId,
                    quantityToInvoice: Number(data.qty || 0),
                    quantityOrderedFromSO: data.quantityOrdered,
                    quantityInvoiced: data.quantityInvoiced,
                    sNo: index + 1,
                    sku: product.sku,
                    uomName: data.uom,
                    basePrice: Number(data.baseRate),
                    specialDiscount: data.specialDiscount,
                    txDate: formatDateForBackend(props.invoiceDate),
                    invoiceDate: formatDateForBackend(props.invoiceDate),
                    discountPercentage: Number(data.discount || 0),
                    itemName: product.itemName,
                    itemId: product.itemId,
                    anItemSalePrice: Number(data.rate || 0),
                    rowHeight: data.scrollHeight,
                    description: data.description,
                    hsnCode: data.hsnCode,
                    inventoryItemVariantId: variantId,
                    customerInvoiceCOATxList: itemLevelCoaTxList,
                    comment: data.comment,
                    stockNumber: data.stockNumber,
                    partNumber: data.partNumber,
                    origin: data.origin,
                    hsCode: data.hsCode,
                    attributeId1: variant.attributeId1,
                    attributeId2: variant.attributeId2,
                    attributeId3: variant.attributeId3,
                    attributeName1: variant.attributeName1,
                    attributeName2: variant.attributeName2,
                    attributeName3: variant.attributeName3,
                    attributeValue1: variant.attributeValue1,
                    attributeValue2: variant.attributeValue2,
                    attributeValue3: variant.attributeValue3,
                    itemVariantName: data.itemVariantName,
                    parentDetailsId: data.parentDetailsId,
                    shipmentMasterId: soData.shipmentMasterId,
                });
            });
        });
    } else {
        gridApi.forEachNode((rowNode, index) => {
            const { data } = rowNode;
            const itemId = data.selectedProductValue && data.selectedProductValue[0];
            //if (!itemId) return;
            const variantId = data.selectedProductValue && data.selectedProductValue.length === 2 ? data.selectedProductValue[1] : 0;
            let product = {};
            let itemLevelCoaTxList = [];
            if (itemId) {
                product = data.productObj || {};
            }
            let variant = {};
            if (variantId) {
                variant = data.variant || {};
            }
            let itemLevelAmount = ((data || {}).rate || 0) * ((data || {}).qty || 0);

            if (data.discount) {
                const discountOnItem = itemLevelAmount ? ((data.discount * 0.01) * itemLevelAmount) : 0;
                itemLevelAmount = itemLevelAmount - discountOnItem;
                itemLevelCoaTxList.push({
                    relationshipId: companyInfo.relationshipId,
                    relationshipName: companyInfo.storeName,
                    fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
                    fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
                    customerId: props.customer && props.customer.customerId,
                    //dateCreated: getCurrentDateForBackend(new Date()),
                    createdBy: userInfo.userId,
                    amount: Number(discountOnItem || 0),
                    amountPercent: Number(data.discount || 0),
                    txDate: formatDateForBackend(new Date()),
                    txType: LEDGER_TYPE.TYPE_DISCOUNT,
                    accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
                    ledgerAccountId: props.discountDebtorLedgerId,
                    customerName: props.customer && props.customer.companyName
                });

                itemLevelCoaTxList.push({
                    relationshipId: companyInfo.relationshipId,
                    relationshipName: companyInfo.storeName,
                    fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
                    fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
                    customerId: props.customer && props.customer.customerId,
                    //dateCreated: getCurrentDateForBackend(new Date()),
                    createdBy: userInfo.userId,
                    amount: Number(discountOnItem || 0),
                    amountPercent: Number(data.discount || 0),
                    txDate: formatDateForBackend(new Date()),
                    txType: LEDGER_TYPE.TYPE_DISCOUNT,
                    accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
                    ledgerAccountId: props.salesDiscountLedgerId,
                    customerName: props.customer && props.customer.companyName
                })
            }

            if (data.tax) {
                if (companyInfo.countryName !== 'India') {
                    itemLevelCoaTxList.push({
                        relationshipId: companyInfo.relationshipId,
                        relationshipName: companyInfo.storeName,
                        fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
                        fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
                        customerId: props.customer && props.customer.customerId,
                        //dateCreated: getCurrentDateForBackend(new Date()),
                        createdBy: userInfo.userId,
                        amount: (itemLevelAmount ? ((data.tax * 0.01) * itemLevelAmount) : 0).toFixed(2),
                        amountPercent: Number(data.tax || 0),
                        txDate: formatDateForBackend(new Date()),
                        txType: LEDGER_TYPE.TYPE_TAX,
                        taxName: data.taxName,
                        taxId: data.taxId,
                        accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
                        ledgerAccountId: props.salesOutputTaxLedgerId,
                        customerName: props.customer && props.customer.companyName
                    });

                    itemLevelCoaTxList.push({
                        relationshipId: companyInfo.relationshipId,
                        relationshipName: companyInfo.storeName,
                        fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
                        fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
                        customerId: props.customer && props.customer.customerId,
                        //dateCreated: getCurrentDateForBackend(new Date()),
                        createdBy: userInfo.userId,
                        amount: (itemLevelAmount ? ((data.tax * 0.01) * itemLevelAmount) : 0).toFixed(2),
                        amountPercent: Number(data.tax || 0),
                        txDate: formatDateForBackend(new Date()),
                        txType: LEDGER_TYPE.TYPE_TAX,
                        taxName: data.taxName,
                        accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
                        taxId: data.taxId,
                        ledgerAccountId: props.taxDebtorLedgerId,
                        customerName: props.customer && props.customer.companyName
                    });
                }
                else if (isPlaceOfSupplySameAsRelationship) {
                    itemLevelCoaTxList.push({
                        relationshipId: companyInfo.relationshipId,
                        relationshipName: companyInfo.storeName,
                        fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
                        fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
                        customerId: props.customer && props.customer.customerId,
                        //dateCreated: getCurrentDateForBackend(new Date()),
                        createdBy: userInfo.userId,
                        amount: (itemLevelAmount ? (((data.tax) * 0.01) * itemLevelAmount) : 0).toFixed(2),
                        amountPercent: (data.tax),
                        txDate: formatDateForBackend(new Date()),
                        txType: LEDGER_TYPE.TYPE_TAX,
                        taxName: LEDGER_TYPE.TYPE_TAX_CGST,
                        taxId: data.taxId,
                        accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
                        ledgerAccountId: props.salesOutputTaxLedgerId,
                        customerName: props.customer && props.customer.companyName
                    });

                    itemLevelCoaTxList.push({
                        relationshipId: companyInfo.relationshipId,
                        relationshipName: companyInfo.storeName,
                        fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
                        fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
                        customerId: props.customer && props.customer.customerId,
                        //dateCreated: getCurrentDateForBackend(new Date()),
                        createdBy: userInfo.userId,
                        amount: (itemLevelAmount ? (((data.tax) * 0.01) * itemLevelAmount) : 0).toFixed(2),
                        amountPercent: (data.tax),
                        txDate: formatDateForBackend(new Date()),
                        txType: LEDGER_TYPE.TYPE_TAX,
                        taxName: LEDGER_TYPE.TYPE_TAX_CGST,
                        taxId: data.taxId,
                        accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
                        ledgerAccountId: props.taxDebtorLedgerId,
                        customerName: props.customer && props.customer.companyName
                    });


                    itemLevelCoaTxList.push({
                        relationshipId: companyInfo.relationshipId,
                        relationshipName: companyInfo.storeName,
                        fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
                        fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
                        customerId: props.customer && props.customer.customerId,
                        //dateCreated: getCurrentDateForBackend(new Date()),
                        createdBy: userInfo.userId,
                        amount: (itemLevelAmount ? (((data.tax) * 0.01) * itemLevelAmount) : 0).toFixed(2),
                        amountPercent: (data.tax),
                        txDate: formatDateForBackend(new Date()),
                        txType: LEDGER_TYPE.TYPE_TAX,
                        taxName: LEDGER_TYPE.TYPE_TAX_SGST,
                        taxId: data.taxId,
                        accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
                        ledgerAccountId: props.salesOutputTaxLedgerId,
                        customerName: props.customer && props.customer.companyName
                    });

                    itemLevelCoaTxList.push({
                        relationshipId: companyInfo.relationshipId,
                        relationshipName: companyInfo.storeName,
                        fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
                        fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
                        customerId: props.customer && props.customer.customerId,
                        //dateCreated: getCurrentDateForBackend(new Date()),
                        createdBy: userInfo.userId,
                        amount: (itemLevelAmount ? (((data.tax) * 0.01) * itemLevelAmount) : 0).toFixed(2),
                        amountPercent: (data.tax),
                        txDate: formatDateForBackend(new Date()),
                        txType: LEDGER_TYPE.TYPE_TAX,
                        taxName: LEDGER_TYPE.TYPE_TAX_SGST,
                        taxId: data.taxId,
                        accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
                        ledgerAccountId: props.taxDebtorLedgerId,
                        customerName: props.customer && props.customer.companyName
                    });

                } else {
                    itemLevelCoaTxList.push({
                        relationshipId: companyInfo.relationshipId,
                        relationshipName: companyInfo.storeName,
                        fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
                        fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
                        customerId: props.customer && props.customer.customerId,
                        //dateCreated: getCurrentDateForBackend(new Date()),
                        createdBy: userInfo.userId,
                        amount: (itemLevelAmount ? ((data.tax * 0.01) * itemLevelAmount) : 0).toFixed(2),
                        amountPercent: Number(data.tax || 0),
                        txDate: formatDateForBackend(new Date()),
                        txType: LEDGER_TYPE.TYPE_TAX,
                        taxName: LEDGER_TYPE.TYPE_TAX_IGST,
                        accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
                        ledgerAccountId: props.salesOutputTaxLedgerId,
                        customerName: props.customer && props.customer.companyName
                    });

                    itemLevelCoaTxList.push({
                        relationshipId: companyInfo.relationshipId,
                        relationshipName: companyInfo.storeName,
                        fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
                        fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
                        customerId: props.customer && props.customer.customerId,
                        //dateCreated: getCurrentDateForBackend(new Date()),
                        createdBy: userInfo.userId,
                        amount: (itemLevelAmount ? ((data.tax * 0.01) * itemLevelAmount) : 0).toFixed(2),
                        amountPercent: Number(data.tax || 0),
                        txDate: formatDateForBackend(new Date()),
                        txType: LEDGER_TYPE.TYPE_TAX,
                        taxName: LEDGER_TYPE.TYPE_TAX_IGST,
                        accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
                        ledgerAccountId: props.taxDebtorLedgerId,
                        customerName: props.customer && props.customer.companyName
                    });
                }
            }

            invoiceMaster.customerInvoiceDetailsList.push({
                relationshipId: companyInfo && companyInfo.relationshipId,
                customerId: props.customer && props.customer.customerId,
                quantityToInvoice: Number(data.qty || 0),
                quantityOrderedFromSO: data.quantityOrdered,
                quantityInvoiced: data.quantityInvoiced,
                sNo: index + 1,
                sku: product.sku,
                uomName: data.uom,
                basePrice: Number(data.baseRate),
                specialDiscount: data.specialDiscount,
                txDate: formatDateForBackend(props.invoiceDate),
                invoiceDate: formatDateForBackend(props.invoiceDate),
                discountPercentage: Number(data.discount || 0),
                itemName: product.itemName,
                itemId: product.itemId,
                anItemSalePrice: Number(data.rate || 0),
                rowHeight: data.scrollHeight,
                description: data.description,
                hsnCode: data.hsnCode,
                inventoryItemVariantId: variantId,
                customerInvoiceCOATxList: itemLevelCoaTxList,
                comment: data.comment,
                stockNumber: data.stockNumber,
                partNumber: data.partNumber,
                origin: data.origin,
                hsCode: data.hsCode,
                attributeId1: variant.attributeId1,
                attributeId2: variant.attributeId2,
                attributeId3: variant.attributeId3,
                attributeName1: variant.attributeName1,
                attributeName2: variant.attributeName2,
                attributeName3: variant.attributeName3,
                attributeValue1: variant.attributeValue1,
                attributeValue2: variant.attributeValue2,
                attributeValue3: variant.attributeValue3,
                itemVariantName: data.itemVariantName,
                parentDetailsId: data.parentDetailsId
            });
        });
    }

    let additionalInfo = {};

    if (props.selectedContact && props.selectedContact.firstName) {
        additionalInfo = { ...props.selectedContact }
        // additionalInfo.relationshipVatNumber = companyInfo.vatNumber;
        // additionalInfo.relationshipGstNumber = companyInfo.gstNumber;
        // additionalInfo.relationshipPanNumber = companyInfo.panNumber;
        additionalInfo.relationshipFaxNumber = companyInfo.faxNumber;
        additionalInfo.relationshipPhoneNumber = companyInfo.phoneNumber;
        additionalInfo.relationshipWebsite = companyInfo.website;
    }
    if (props.customer) {
        additionalInfo.customerId = props.customer && props.customer.customerId;
        // additionalInfo.gstNumber = props.customer.gstNumber;
        // additionalInfo.panNumber = props.customer.panNumber;
    }
    if (Object.keys(additionalInfo).length) {
        additionalInfo.relationshipId = companyInfo && companyInfo.relationshipId;
        additionalInfo.additionalInfoId = props.salesOrderMasterId ? 0 : additionalInfo.additionalInfoId;
    }
    if (props.selectedCustomerTaxIdentifications && props.selectedCustomerTaxIdentifications.length) {
        let selectedCustomerTaxIdentifications = filter(props.customer.customerTaxIdentificationsList, (taxObj) => {
            return props.selectedCustomerTaxIdentifications.indexOf(taxObj.customerTaxIdentificationId) > -1;
        });
        additionalInfo.taxIdentifications = JSON.stringify(selectedCustomerTaxIdentifications);
    }
    if (props.selectedRelationshipTaxIdentifications && props.selectedRelationshipTaxIdentifications.length) {
        let selectedRelTaxIdentifications = filter(props.allRelationshipTaxIdentificatins, (taxObj) => {
            return props.selectedRelationshipTaxIdentifications.indexOf(taxObj.relationshipTaxIdentificationId) > -1;
        });
        additionalInfo.relationshipTaxIdentifications = JSON.stringify(selectedRelTaxIdentifications);
    }
    if (props.selectedBankDetails && props.selectedBankDetails.length) {
        let selectedBankDetails = filter((companyInfo || {}).bankDetails, (bank) => {
            return props.selectedBankDetails.indexOf(bank.bankDetailId) > -1;
        });
        additionalInfo.bankDetails = JSON.stringify(selectedBankDetails);
    }
    invoiceMaster.additionalInfoList = [additionalInfo];

    return invoiceMaster;
}

export const resetSoInvoiceData = () => {
    return dispatch => {
        dispatch({ type: SO_ACTION_LIST.RESET_SO_INVOICE_DATA });
    }
}

export const getNextTxNumber = (payload) => {
    let date = payload.date ? getCurrentDateForBackend(payload.date) : getCurrentDateForBackend(new Date());
    return dispatch => {
        dispatch({ type: SALES_INVOICE_ACTION_LIST.NEXT_SALES_INVOICE_NUMBER_RESET });
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios
            .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customerInvoice/getNextSalesInvoiceNumber?date=${date}&relationshipId=${payload.relationshipId}`)
            .then(res => {
                dispatch({ type: SALES_INVOICE_ACTION_LIST.NEXT_SALES_INVOICE_NUMBER, data: res.data });
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            })
            .catch((err) => {
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
                if (!err.__isRetryRequest) {
                    //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
                } else {
                    err.actionToCall = SALES_INVOICE_ACTION_LIST.NEXT_SALES_INVOICE_NUMBER;
                }
            })
    }
}

export const fetchAllUnfulfilledSalesOrderByCustomerId = (payload, history) => {
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios
            .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesOrder/getOpenedOrdersByCustomerId?relationshipId=${payload.relationshipId}&customerId=${payload.customerId}&pageSize=100&pageNumber=0`)
            .then(res => {
                dispatch({ type: SO_ACTION_LIST.SO_UNFULFILLED_LIST, data: res.data });
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            })
            .catch((err) => {
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
                if (err.__isRetryRequest) {
                    err.actionToCall = SO_ACTION_LIST.SO_UNFULFILLED_LIST;
                }
            })
    }
}

export const resetUnfulfilledSalesOrder = () => {
    return dispatch => {
        dispatch({ type: SO_ACTION_LIST.SO_UNFULFILLED_LIST_RESET });
    }
}

export const fetchShipmentForInvoice = (payload, history) => {
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios
            .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/shipment/getShipmentByCustomerId?relationshipId=${payload.relationshipId}&customerId=${payload.customerId}&pageSize=100&pageNumber=0`)
            .then(res => {
                dispatch({ type: SO_ACTION_LIST.SHIPMENT_LIST_FOR_INVOICE, data: (res.data || {}).invoicePandingShipmentList || [] });
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            })
            .catch((err) => {
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
                if (err.__isRetryRequest) {
                    err.actionToCall = SO_ACTION_LIST.SHIPMENT_LIST_FOR_INVOICE;
                }
            })
    }
}

export const resetShipmentForInvoice = () => {
    return dispatch => {
        dispatch({ type: SO_ACTION_LIST.SHIPMENT_LIST_FOR_INVOICE_RESET });
    }
}


export const createSalesInvoiceDraft = (payloadObject) => {
    const props = payloadObject.props;
    const invoiceData = _getInvoiceData(payloadObject);
    return dispatch => {
        const data = {
            invoiceDraftId: props.invoiceDraftId || 0,
            invoiceData: JSON.stringify(invoiceData),
            salesOrderMasterId: props.salesOrderMasterId,
            relationshipId: invoiceData.relationshipId,
            customerId: invoiceData.customerId
        }

        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios
            .post(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customerInvoice/saveOrUpdateProforma`, data)
            .then(res => {
                showToasterMessage({ messageType: 'success', description: 'Invoice saved in draft' })
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
                props.history.push({
                    pathname: '/admin/sales-invoice-list',
                    state: { activeTab: 'Draft' }
                })
            })
            .catch((err) => {
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
                if (!err.__isRetryRequest) {
                    showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
                }
            })
    }
}