import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMMON_ACTIONS } from '../../../../static/constants';
import { showToasterMessage } from '../../../../utils';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const createBroadcastMessage = (formData, props, state) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
      .post(
        `${config.COMMUNICATION_BASE_URL}${config.API_VERSION}/${config.rootContextCommunication}/broadcastMessage/saveOrUpdate`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      )
      .then((response) => {
        if (props) {
          dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          props.updateState({
            broadcastMessageDrawerVisible: false,
          });
          props.getActiveBroadcastMessage && props.getActiveBroadcastMessage({...props, relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId});
          props.getActiveBroadcastMessageCount && props.getActiveBroadcastMessageCount({...props, relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId});
        }
        showToasterMessage({ messageType: 'success', description: 'Message has been sent successfully' });
      })
      .catch((err) => {

        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          messageType: "error",
          description:
            lang[((err.response || {}).data || {}).message] ||
            "Some error occurred",
        });
      });
  };
};

