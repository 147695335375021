import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMMON_ACTIONS, SO_ACTION_LIST, SALES_INVOICE_ACTION_LIST } from '../../../../static/constants'
import { getCurrentDateForBackend, formatDateForBackend, showToasterMessage, getFinancialYear } from '../../../../utils';
import { LEDGER_TYPE, ACCOUNTING_ENTRY_TYPE, SALE_INVOICE_TYPE, TX_TYPE, TX_STATUS } from "../../../../static/constants";
import { resetPaginatedData, getSalesInvoiceCount } from '../Listing/action';
import * as filter from 'lodash.filter';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const createSalesInvoice = (payloadObject) => {
    const props = payloadObject.props;
    const invoiceData = _getInvoiceData(payloadObject);
    let formData = new FormData();
    if (props.fileList && props.fileList.length) {
      props.fileList.forEach((file)=>{
        formData.append('files', file, file.name);
      })
    }
    formData.append('invoiceData', JSON.stringify(invoiceData, true));
    formData.append('bucketName' ,props.companyInfo?.bucketName)
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios
            .post(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customerInvoice/generateCustomerInvoice`, formData, {
                headers: {
                  'content-type': 'multipart/form-data'
                }
              })
            .then(res => {
                props.openSalesInvoiceDetails(res.data);
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
                resetPaginatedData()(dispatch);
                getSalesInvoiceCount({ relationshipId: props.companyInfo.relationshipId })(dispatch);
                showToasterMessage({ messageType: 'success', description: 'Sales invoice created successfully' });
                dispatch({ type: SO_ACTION_LIST.SO_LIST_PAGE_RESET });
                props.history.replace({
                    pathname: '/admin/sales-invoice',
                    state: {}
                  });
                props.isSoConversionToInvoice ? props.history.push('/admin/so-list') : props.history.push('/admin/sales-invoice-list');
            })
            .catch((err) => {
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
                showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
            })
    }
}


export const fetchSoDataForInvoice = (payload) => {
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios
            .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesOrder/getSalesOrderDataForInvoice?customerId=${payload.customerId}&relationshipId=${payload.relationshipId}&salesOrderMasterId=${payload.soMasterId}`)
            .then(res => {
                dispatch({ type: SO_ACTION_LIST.SO_INVOICE_DATA, data: res.data });
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            })
            .catch((err) => {
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
                if (!err.__isRetryRequest) {
                    //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
                } else {
                    err.actionToCall = SO_ACTION_LIST.SO_INVOICE_DATA;
                }
            })
    }
}

const _getInvoiceData = (payload) => {
    const props = payload.props;
    const state = payload.state;
    const gridObject = payload.gridObject;
    const isPlaceOfSupplySameAsRelationship = payload.isPlaceOfSupplySameAsRelationship;
    const companyInfo = props.companyInfo || {};
    const userInfo = props.userInfo || {};
    const financialYear = getFinancialYear(companyInfo);
    let invoiceMaster = {
        version: props.version,
        invoiceDraftId: props.invoiceDraftId,
        customerId: props.customer && props.customer.customerId,
        fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
        fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
        projectName: props.projectName,
        foreignCurrency: props.currencyCode + "",
        foreignCurrencyIcon: props.currencyIcon,
        isMultiCurrency: 1,
        exchangeRate: props.exchangeRate,
        salesOrderMasterId: props.salesOrderMasterId,
        isInventoryInvoice : props.salesOrderMasterId ? true : false,
        soNumber: props.soNumber,
        salesQuotationMasterId: props.salesQuotationMasterId,
        quotationNumber: props.quotationNumber,
        rfqNumber: props.rfqNumber,
        rfqMasterId: props.rfqMasterId,
        expenseId: props.expenseId,
        projectMasterId: props.projectMasterId,
        projectNumber: props.projectNumber,
        isCashInvoice: props.isCashSales ? 1 : 0,
        invoiceTotalAmount: Number(state.total),
        invoiceType: SALE_INVOICE_TYPE.STANDARD,
        customerName: props.customer && props.customer.companyName,
        relationshipName: companyInfo && companyInfo.storeName,
        customerGstNumber: props.customer && props.customer.gstNumber,
        customerPanNumber: props.customer && props.customer.panNumber,
        placeOfSupply: props.placeOfSupply,
        status: props.isbankReco ? TX_STATUS.PAID : TX_STATUS.OPEN,
        invoiceDate: props.invoiceDate && formatDateForBackend(props.invoiceDate),
        invoiceDueDate: props.invoiceDueDate && formatDateForBackend(props.invoiceDueDate),
        relationshipId: companyInfo.relationshipId,
        customerPONumber: props.customerPo,
        referenceNumber: props.customerRef,
        remarksCustomer: props.customerRemarks,
        remarksInternal: props.internalRemarks,
        paymentTermId: props.customerPaymentTerm && props.customerPaymentTerm.paymentTermId,
        paymentTermName: props.customerPaymentTerm && props.customerPaymentTerm.termName,
        paymentTermDays: props.customerPaymentTerm && props.customerPaymentTerm.numberOfDays,
        //dateCreated: getCurrentDateForBackend(new Date()),
        createdByUserId: userInfo && userInfo.userId,
        createdByEmpId: userInfo && userInfo.relationshipEmployeeId, 
        footer: props.footer,
        totalDiscountGiven: Number(state.totalDiscount || 0),
        txType: TX_TYPE.INVOICE,
        expenseName: props.expenseName,
        customerInvoiceCOATxList: [],
        customerInvoiceDetailsList: [],
        boLocationSalesInvoiceList: [],
        documentName: props.docName,
        nameOfTransport: props.nameOfTransport,
        vehicleNumber: props.vehicleNumber,
        roadPermitNumber: props.roadPermitNumber,
        freightType: props.freightType,
        consignee: props.consignee,
        ewayBillNo: props.ewayBillNo,
        station: props.station,
        customerPaymentList: props.isbankReco ? _getPaymentData(state, props) : []
    };

    invoiceMaster.customerInvoiceCOATxList.push({
        relationshipId: companyInfo.relationshipId,
        relationshipName: companyInfo.storeName,
        exchangeRate: props.exchangeRate,
        fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
        fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
        customerId: props.customer && props.customer.customerId,
        //dateCreated: getCurrentDateForBackend(new Date()),
        createdBy: userInfo.userId,
        amount: Number(state.subtotalWithoutDiscount || 0),
        txDate: formatDateForBackend(new Date()),
        txType: LEDGER_TYPE.TYPE_SALES,
        accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
        ledgerAccountId: props.salesLedgerId,
        customerName: props.customer && props.customer.companyName
    });

    invoiceMaster.customerInvoiceCOATxList.push({
        relationshipId: companyInfo.relationshipId,
        exchangeRate: props.exchangeRate,
        relationshipName: companyInfo.storeName,
        fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
        fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
        customerId: props.customer && props.customer.customerId,
        //dateCreated: getCurrentDateForBackend(new Date()),
        createdBy: userInfo.userId,
        amount: Number(state.subtotalWithoutDiscount || 0),
        txDate: formatDateForBackend(new Date()),
        txType: LEDGER_TYPE.TYPE_SALES,
        accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
        ledgerAccountId: props.salesDebtorLedgerId,
        customerName: props.customer && props.customer.companyName
    });

    if (state.rootDiscountAmount) {
        invoiceMaster.customerInvoiceCOATxList.push({
            relationshipId: companyInfo.relationshipId,
            exchangeRate: props.exchangeRate,
            relationshipName: companyInfo.storeName,
            fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
            fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
            customerId: props.customer && props.customer.customerId,
            //dateCreated: getCurrentDateForBackend(new Date()),
            createdBy: userInfo.userId,
            amount: Number(state.rootDiscountAmount || 0),
            amountPercent: Number(state.rootDiscountPercent || 0),
            txDate: formatDateForBackend(new Date()),
            txType: LEDGER_TYPE.TYPE_DISCOUNT,
            accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
            ledgerAccountId: props.discountDebtorLedgerId,
            customerName: props.customer && props.customer.companyName
        });

        invoiceMaster.customerInvoiceCOATxList.push({
            relationshipId: companyInfo.relationshipId,
            exchangeRate: props.exchangeRate,
            relationshipName: companyInfo.storeName,
            fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
            fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
            customerId: props.customer && props.customer.customerId,
            //dateCreated: getCurrentDateForBackend(new Date()),
            createdBy: userInfo.userId,
            amount: Number(state.rootDiscountAmount || 0),
            amountPercent: Number(state.rootDiscountPercent || 0),
            txDate: formatDateForBackend(new Date()),
            txType: LEDGER_TYPE.TYPE_DISCOUNT,
            accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
            ledgerAccountId: props.salesDiscountLedgerId,
            customerName: props.customer && props.customer.companyName
        });
    }

    if (state.expenseAmount) {
        invoiceMaster.customerInvoiceCOATxList.push({
            relationshipId: companyInfo.relationshipId,
            relationshipName: companyInfo.storeName,
            exchangeRate: props.exchangeRate,
            fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
            fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
            customerId: props.customer && props.customer.customerId,
            //dateCreated: getCurrentDateForBackend(new Date()),
            createdBy: userInfo.userId,
            amount: Number(state.expenseAmount || 0),
            txDate: formatDateForBackend(new Date()),
            txType: LEDGER_TYPE.TYPE_EXPENSE,
            accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
            ledgerAccountId: props.expenseLedgerId,
            customerName: props.customer && props.customer.companyName
        });

        invoiceMaster.customerInvoiceCOATxList.push({
            relationshipId: companyInfo.relationshipId,
            relationshipName: companyInfo.storeName,
            exchangeRate: props.exchangeRate,
            fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
            fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
            customerId: props.customer && props.customer.customerId,
            //dateCreated: getCurrentDateForBackend(new Date()),
            createdBy: userInfo.userId,
            amount: Number(state.expenseAmount || 0),
            txDate: formatDateForBackend(new Date()),
            txType: LEDGER_TYPE.TYPE_EXPENSE,
            accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
            ledgerAccountId: props.expenseDebtorLedgerId,
            customerName: props.customer && props.customer.companyName
        });
    }

    if (props.relationshipBillingAddress && (props.relationshipBillingAddress.locationId || props.relationshipBillingAddress.locationSOId || props.relationshipBillingAddress.locationSalesInvocieId)) {
        if (props.isClone){ 
          delete props.relationshipBillingAddress.invoiceMasterId;
          delete props.relationshipBillingAddress.locationSalesInvocieId;
          //props.relationshipBillingAddress.dateCreated = getCurrentDateForBackend(new Date());
          //props.relationshipBillingAddress.dateUpdated = getCurrentDateForBackend(new Date());
        } else {
         // props.relationshipBillingAddress.dateUpdated = getCurrentDateForBackend(new Date());
        }
        invoiceMaster.boLocationSalesInvoiceList.push(props.relationshipBillingAddress);
      }
    
      if (props.customerShippingAddress && (props.customerShippingAddress.locationId || props.customerShippingAddress.locationSOId || props.customerShippingAddress.locationSalesInvocieId)) {
        if (props.isClone){ 
          delete props.customerShippingAddress.invoiceMasterId;
          delete props.customerShippingAddress.locationSalesInvocieId;
        //  props.customerShippingAddress.dateCreated = getCurrentDateForBackend(new Date());
        //  props.customerShippingAddress.dateUpdated = getCurrentDateForBackend(new Date());
        } else {
         // props.customerShippingAddress.dateUpdated = getCurrentDateForBackend(new Date());
        }
        invoiceMaster.boLocationSalesInvoiceList.push(props.customerShippingAddress);
      }
    
      if (props.customerBillingAddress && (props.customerBillingAddress.locationId || props.customerBillingAddress.locationSOId || props.customerBillingAddress.locationSalesInvocieId)) {
        if (props.isClone){ 
          delete props.customerBillingAddress.invoiceMasterId;
          delete props.customerBillingAddress.locationSalesInvocieId;
         // props.customerBillingAddress.dateCreated = getCurrentDateForBackend(new Date());
         // props.customerBillingAddress.dateUpdated = getCurrentDateForBackend(new Date());
        } else {
        //  props.customerBillingAddress.dateUpdated = getCurrentDateForBackend(new Date());
        }
        invoiceMaster.boLocationSalesInvoiceList.push(props.customerBillingAddress);
      }

    gridObject.forEachNode((rowNode, index) => {
        const { data } = rowNode;
        if(!data.itemId && !data.description && !data.qty && !data.rate){
            return
          }
        const itemId = data.selectedProductValue && data.selectedProductValue[0];
        //if (!itemId) return;
        const variantId = data.selectedProductValue && data.selectedProductValue.length === 2 ? data.selectedProductValue[1] : 0;
        let product = {};
        let itemLevelCoaTxList = [];
        if (itemId) {
            product = data.productObj || {};
        }
        let variant = {};
        if(variantId){
          variant = data.variant || {};
        }
        let itemLevelAmount = ((data || {}).rate || 0) * ((data || {}).qty || 0);

        if (data.discount) {
            const discountOnItem = itemLevelAmount ? ((data.discount * 0.01) * itemLevelAmount) : 0;
            itemLevelAmount = itemLevelAmount - discountOnItem;
            itemLevelCoaTxList.push({
                relationshipId: companyInfo.relationshipId,
                relationshipName: companyInfo.storeName,
                exchangeRate: props.exchangeRate,
                fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
                fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
                customerId: props.customer && props.customer.customerId,
                //dateCreated: getCurrentDateForBackend(new Date()),
                createdBy: userInfo.userId,
                amount: Number(discountOnItem || 0),
                amountPercent: Number(data.discount || 0),
                txDate: formatDateForBackend(new Date()),
                txType: LEDGER_TYPE.TYPE_DISCOUNT,
                accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
                ledgerAccountId: props.discountDebtorLedgerId,
                customerName: props.customer && props.customer.companyName
            });

            itemLevelCoaTxList.push({
                relationshipId: companyInfo.relationshipId,
                relationshipName: companyInfo.storeName,
                exchangeRate: props.exchangeRate,
                fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
                fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
                customerId: props.customer && props.customer.customerId,
                //dateCreated: getCurrentDateForBackend(new Date()),
                createdBy: userInfo.userId,
                amount: Number(discountOnItem || 0),
                amountPercent: Number(data.discount || 0),
                txDate: formatDateForBackend(new Date()),
                txType: LEDGER_TYPE.TYPE_DISCOUNT,
                accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
                ledgerAccountId: props.salesDiscountLedgerId,
                customerName: props.customer && props.customer.companyName
            })
        }

        if (data.tax) {
            if(companyInfo.countryName !== 'India'){
                itemLevelCoaTxList.push({
                    relationshipId: companyInfo.relationshipId,
                    relationshipName: companyInfo.storeName,
                    exchangeRate: props.exchangeRate,
                    fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
                    fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
                    customerId: props.customer && props.customer.customerId,
                    //dateCreated: getCurrentDateForBackend(new Date()),
                    createdBy: userInfo.userId,
                    amount: (itemLevelAmount ? ((data.tax * 0.01) * itemLevelAmount) : 0).toFixed(2),
                    amountPercent: Number(data.tax || 0),
                    txDate: formatDateForBackend(new Date()),
                    txType: LEDGER_TYPE.TYPE_TAX_VAT,
                    taxId: data.taxId,
                    accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
                    ledgerAccountId: props.salesOutputTaxLedgerId,
                    customerName: props.customer && props.customer.companyName
                });

                itemLevelCoaTxList.push({
                    relationshipId: companyInfo.relationshipId,
                    relationshipName: companyInfo.storeName,
                    exchangeRate: props.exchangeRate,
                    fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
                    fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
                    customerId: props.customer && props.customer.customerId,
                    //dateCreated: getCurrentDateForBackend(new Date()),
                    createdBy: userInfo.userId,
                    amount: (itemLevelAmount ? ((data.tax * 0.01) * itemLevelAmount) : 0).toFixed(2),
                    amountPercent: Number(data.tax || 0),
                    txDate: formatDateForBackend(new Date()),
                    txType: LEDGER_TYPE.TYPE_TAX_VAT,
                    taxId: data.taxId,
                    accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
                    ledgerAccountId: props.taxDebtorLedgerId,
                    customerName: props.customer && props.customer.companyName
                });
            }
            else if (isPlaceOfSupplySameAsRelationship) {
                itemLevelCoaTxList.push({
                    relationshipId: companyInfo.relationshipId,
                    relationshipName: companyInfo.storeName,
                    exchangeRate: props.exchangeRate,
                    fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
                    fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
                    customerId: props.customer && props.customer.customerId,
                    //dateCreated: getCurrentDateForBackend(new Date()),
                    createdBy: userInfo.userId,
                    amount: (itemLevelAmount ? (((data.tax) * 0.01) * itemLevelAmount)/2 : 0).toFixed(2),
                    amountPercent: (data.tax)/2,
                    txDate: formatDateForBackend(new Date()),
                    txType: LEDGER_TYPE.TYPE_TAX_CGST,
                    taxId: data.taxId,
                    accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
                    ledgerAccountId: props.cgstSalesOutputTaxLedgerId,
                    customerName: props.customer && props.customer.companyName
                });

                itemLevelCoaTxList.push({
                    relationshipId: companyInfo.relationshipId,
                    relationshipName: companyInfo.storeName,
                    exchangeRate: props.exchangeRate,
                    fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
                    fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
                    customerId: props.customer && props.customer.customerId,
                    //dateCreated: getCurrentDateForBackend(new Date()),
                    createdBy: userInfo.userId,
                    amount: (itemLevelAmount ? (((data.tax) * 0.01) * itemLevelAmount)/2 : 0).toFixed(2),
                    amountPercent: (data.tax)/2,
                    txDate: formatDateForBackend(new Date()),
                    txType: LEDGER_TYPE.TYPE_TAX_CGST,
                    taxId: data.taxId,
                    accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
                    ledgerAccountId: props.cgstTaxDebtorLedgerId,
                    customerName: props.customer && props.customer.companyName
                });


                itemLevelCoaTxList.push({
                    relationshipId: companyInfo.relationshipId,
                    relationshipName: companyInfo.storeName,
                    exchangeRate: props.exchangeRate,
                    fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
                    fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
                    customerId: props.customer && props.customer.customerId,
                    //dateCreated: getCurrentDateForBackend(new Date()),
                    createdBy: userInfo.userId,
                    amount: (itemLevelAmount ? (((data.tax) * 0.01) * itemLevelAmount)/2 : 0).toFixed(2),
                    amountPercent: (data.tax)/2,
                    txDate: formatDateForBackend(new Date()),
                    txType: LEDGER_TYPE.TYPE_TAX_SGST,
                    taxId: data.taxId,
                    accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
                    ledgerAccountId: props.sgstSalesOutputTaxLedgerId,
                    customerName: props.customer && props.customer.companyName
                });

                itemLevelCoaTxList.push({
                    relationshipId: companyInfo.relationshipId,
                    relationshipName: companyInfo.storeName,
                    exchangeRate: props.exchangeRate,
                    fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
                    fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
                    customerId: props.customer && props.customer.customerId,
                    //dateCreated: getCurrentDateForBackend(new Date()),
                    createdBy: userInfo.userId,
                    amount: (itemLevelAmount ? (((data.tax) * 0.01) * itemLevelAmount)/2 : 0).toFixed(2),
                    amountPercent: (data.tax)/2,
                    txDate: formatDateForBackend(new Date()),
                    txType: LEDGER_TYPE.TYPE_TAX_SGST,
                    taxId: data.taxId,
                    accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
                    ledgerAccountId: props.sgstTaxDebtorLedgerId,
                    customerName: props.customer && props.customer.companyName
                });

            } else {
                itemLevelCoaTxList.push({
                    relationshipId: companyInfo.relationshipId,
                    relationshipName: companyInfo.storeName,
                    exchangeRate: props.exchangeRate,
                    fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
                    fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
                    customerId: props.customer && props.customer.customerId,
                    //dateCreated: getCurrentDateForBackend(new Date()),
                    createdBy: userInfo.userId,
                    amount: (itemLevelAmount ? ((data.tax * 0.01) * itemLevelAmount) : 0).toFixed(2),
                    amountPercent: Number(data.tax || 0),
                    txDate: formatDateForBackend(new Date()),
                    txType: LEDGER_TYPE.TYPE_TAX_IGST,
                    taxId: data.taxId,
                    accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
                    ledgerAccountId: props.igstSalesOutputTaxLedgerId,
                    customerName: props.customer && props.customer.companyName
                });

                itemLevelCoaTxList.push({
                    relationshipId: companyInfo.relationshipId,
                    relationshipName: companyInfo.storeName,
                    exchangeRate: props.exchangeRate,
                    fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
                    fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
                    customerId: props.customer && props.customer.customerId,
                    //dateCreated: getCurrentDateForBackend(new Date()),
                    createdBy: userInfo.userId,
                    amount: (itemLevelAmount ? ((data.tax * 0.01) * itemLevelAmount) : 0).toFixed(2),
                    amountPercent: Number(data.tax || 0),
                    txDate: formatDateForBackend(new Date()),
                    txType: LEDGER_TYPE.TYPE_TAX_IGST,
                    taxId: data.taxId,
                    accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
                    ledgerAccountId: props.igstTaxDebtorLedgerId,
                    customerName: props.customer && props.customer.companyName
                });
            }
        }

        invoiceMaster.customerInvoiceDetailsList.push({
            version: data.version,
            relationshipId: companyInfo && companyInfo.relationshipId,
            customerId: props.customer && props.customer.customerId,
            quantityToInvoice: Number(data.qty || 0),
            quantityOrderedFromSO: data.quantityOrdered,
            quantityInvoiced: data.quantityInvoiced,
            sNo: index + 1,
            sku: product.sku,
            uomName: data.uom,
            basePrice: Number(data.baseRate),
            specialDiscount: data.specialDiscount,
            txDate: getCurrentDateForBackend(props.invoiceDate),
            invoiceDate: getCurrentDateForBackend(props.invoiceDate),
            discountPercentage: Number(data.discount || 0),
            itemName: product.itemName,
            itemId: product.itemId,
            anItemSalePrice: Number(data.rate || 0),
            description: data.description,
            rowHeight: data.scrollHeight,
            hsnCode: data.hsnCode,
            inventoryItemVariantId: variantId,
            customerInvoiceCOATxList: itemLevelCoaTxList,
            warehouse: data.comment,
            stockNumber: data.stockNumber,
            partNumber: data.partNumber,
            origin: data.origin,
            hsCode: data.hsCode,
            materialNumber: data.materialNumber,
            attributeId1: variant.attributeId1,
            attributeId2: variant.attributeId2,
            attributeId3: variant.attributeId3,
            attributeName1: variant.attributeName1,
            attributeName2: variant.attributeName2,
            attributeName3: variant.attributeName3,
            attributeValue1: variant.attributeValue1,
            attributeValue2: variant.attributeValue2,
            attributeValue3: variant.attributeValue3,
            itemVariantName: data.itemVariantName
        });
    });

    let additionalInfo = {};

    if (props.selectedContact && props.selectedContact.firstName) {
        additionalInfo = { ...props.selectedContact }
        // additionalInfo.relationshipVatNumber = companyInfo.vatNumber;
        // additionalInfo.relationshipGstNumber = companyInfo.gstNumber;
        // additionalInfo.relationshipPanNumber = companyInfo.panNumber;
        additionalInfo.relationshipFaxNumber = companyInfo.faxNumber;
        additionalInfo.relationshipPhoneNumber = companyInfo.phoneNumber;
        additionalInfo.relationshipWebsite = companyInfo.website;
    }
    if (props.customer) {
      additionalInfo.customerId = props.customer && props.customer.customerId;
    //   additionalInfo.gstNumber = props.customer.gstNumber;
    //   additionalInfo.panNumber = props.customer.panNumber;
    }
    if (Object.keys(additionalInfo).length) {
      additionalInfo.relationshipId = companyInfo && companyInfo.relationshipId;
      additionalInfo.additionalInfoId =  props.salesOrderMasterId ? 0 : additionalInfo.additionalInfoId;
    }
    if (props.selectedCustomerTaxIdentifications && props.selectedCustomerTaxIdentifications.length && props.customer) {
        let selectedCustomerTaxIdentifications = filter((props.customer.customerTaxIdentificationsList || []), (taxObj) => {
          return props.selectedCustomerTaxIdentifications.indexOf(taxObj.customerTaxIdentificationId) > -1;
        });
        additionalInfo.taxIdentifications = JSON.stringify(selectedCustomerTaxIdentifications);
    }
    if (props.selectedRelationshipTaxIdentifications && props.selectedRelationshipTaxIdentifications.length) {
        let selectedRelTaxIdentifications = filter(props.allRelationshipTaxIdentificatins, (taxObj) => {
            return props.selectedRelationshipTaxIdentifications.indexOf(taxObj.relationshipTaxIdentificationId) > -1;
        });
        additionalInfo.relationshipTaxIdentifications = JSON.stringify(selectedRelTaxIdentifications);
    }  
    if (props.selectedBankDetails && props.selectedBankDetails.length) {
        let selectedBankDetails = filter((companyInfo || {}).bankDetails, (bank) => {
            return props.selectedBankDetails.indexOf(bank.bankDetailId) > -1;
        });
        additionalInfo.bankDetails = JSON.stringify(selectedBankDetails);
    }
    
    invoiceMaster.additionalInfoList = [additionalInfo];

    return invoiceMaster;
}

export const resetSoInvoiceData = () => {
    return dispatch => {
        dispatch({ type: SO_ACTION_LIST.RESET_SO_INVOICE_DATA });
    }
}

export const getNextTxNumber = (payload) => {
    let date = payload.date ? getCurrentDateForBackend(payload.date) : getCurrentDateForBackend(new Date());
    return dispatch => {
      dispatch({ type: SALES_INVOICE_ACTION_LIST.NEXT_SALES_INVOICE_NUMBER_RESET});
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customerInvoice/getNextSalesInvoiceNumber?date=${date}&relationshipId=${payload.relationshipId}`)
        .then(res => {
          dispatch({ type: SALES_INVOICE_ACTION_LIST.NEXT_SALES_INVOICE_NUMBER, data: res.data });
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
        .catch((err) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (!err.__isRetryRequest) {
            //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          } else {
            err.actionToCall = SALES_INVOICE_ACTION_LIST.NEXT_SALES_INVOICE_NUMBER;
          }
        })
    }
  }

  export const createSalesInvoiceDraft = (payloadObject) => {
    const props = payloadObject.props;
    const invoiceData = _getInvoiceData(payloadObject);
    return dispatch => {
      const data = {
        invoiceDraftId: props.invoiceDraftId || 0,
        invoiceData: JSON.stringify(invoiceData),
        relationshipId: invoiceData.relationshipId,
        customerId: invoiceData.customerId
      }
  
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customerInvoice/saveOrUpdateDraft`, data)
        .then(res => {
          showToasterMessage({ messageType: 'success', description: 'Invoice saved in draft' })
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          props.history.push({
            pathname: '/admin/sales-invoice-list',
            state: { activeTab: 'Draft' }
          })
        })
        .catch((err) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (!err.__isRetryRequest) {
            showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          }
        })
    }
  }

  const _getPaymentData = (payloadData, props) => {
    let payload = {};
    let customerName = props.customer && props.customer.companyName;
    let createdByUserId = props.userInfo.userId || 0;
    let relationshipId = props.companyInfo.relationshipId || 0;
    let finYear = getFinancialYear(props.companyInfo);
  
    payload.paymentDate = formatDateForBackend(new Date());
    //payload.reference = formData.reference;
    payload.currencyCode = payloadData.currencyCode || props.companyInfo.currencyCode;
    payload.createdByEmpId = props.userInfo && props.userInfo.relationshipEmployeeId;
    payload.createdByUserId = createdByUserId;
  
    let txList = {
      fyStartDate: finYear && finYear.fyStartDate && getCurrentDateForBackend(finYear.fyStartDate),
      fyEndDate: finYear && finYear.fyEndDate && getCurrentDateForBackend(finYear.fyEndDate),
      relationshipId: relationshipId,
      //dateCreated: getCurrentDateForBackend(new Date()),
      createdByUserId: createdByUserId,
      txType: 'Payment',
      txDate: formatDateForBackend(new Date()),
      accountingEntry: 'CREDIT',
      remarks: payloadData.remarks,
      amount: Number(payloadData.total),
      customerId: props.customer && props.customer.customerId,
      customerName: customerName,
    };
  
    var txList1 = {
      yStartDate: finYear && finYear.fyStartDate && getCurrentDateForBackend(finYear.fyStartDate),
      fyEndDate: finYear && finYear.fyEndDate && getCurrentDateForBackend(finYear.fyEndDate),
      relationshipId: relationshipId,
      //dateCreated: getCurrentDateForBackend(new Date()),
      createdByUserId: createdByUserId,
      txType: 'Payment',
      txDate: formatDateForBackend(new Date()),
      accountingEntry: 'DEBIT',
      remarks: payloadData.remarks,
      amount: Number(payloadData.total),
      customerId: props.customer && props.customer.customerId,
      customerName: customerName,
    };
  
    var customerTxPayment = {
      relationshipId: relationshipId,
      txAmount: Number(payloadData.total),
      paymentModeDetail: payloadData.paymentModeDetail,
      fyStartDate: finYear && finYear.fyStartDate && getCurrentDateForBackend(finYear.fyStartDate),
      fyEndDate: finYear && finYear.fyEndDate && getCurrentDateForBackend(finYear.fyEndDate),
      status: 'Processed',
      statusColor: ' success',
      txDate: formatDateForBackend(new Date()),
      txType: 'Payment',
      isMultiCurrency: payloadData.isMultiCurrency,
      exchangeRate: payloadData.exchangeRate,
      remarks: payloadData.remarks,
      invoiceDueDate: payloadData.invoiceDueDate,
      //dateCreated: getCurrentDateForBackend(new Date()),
      createdByUserId: createdByUserId,
      customerId: props.customer && props.customer.customerId,
      soMasterId: payloadData.salesOrderMasterId,
    }
    payload.customerId = props.customer && props.customer.customerId;
    payload.fyStartDate = finYear && finYear.fyStartDate && getCurrentDateForBackend(finYear.fyStartDate);
    payload.fyEndDate = finYear && finYear.fyEndDate && getCurrentDateForBackend(finYear.fyEndDate);
    payload.remarks = payloadData.remarks;
    payload.soNumber = payloadData.soNumber;
  
    payload.coaTxPaymentsList = [txList, txList1];
    payload.txDate = formatDateForBackend(new Date());
    payload.customerTxPaymentList = [customerTxPayment];
  
    payload.invoiceAmount = Number(payloadData.total);
    payload.relationshipId = relationshipId;
    payload.chartOfAccountDetailsId = payloadData.chartOfAccountDetailsId;
    payload.txType = 'Payment';
    payload.remarks = payloadData.remarks;
    payload.invoiceMasterId = payloadData.invoiceMasterId;
    payload.status = "Processed";
    payload.statusColor = " success";
    payload.invoiceNumber = payloadData.invoiceNumber;
    payload.paymentAmount = Number(payloadData.total);
    payload.isMultiCurrency = payloadData.isMultiCurrency;
    payload.currencyCode = payloadData.isMultiCurrency ? payloadData.foreignCurrency : props.companyInfo.currencyCode;
    payload.exchangeRate = payloadData.exchangeRate;
    payload.templateCode = payloadData.templateCode;
    payload.relationshipName = props.companyInfo.storeName;
    payload.projectNumber = payloadData.projectNumber;
    payload.projectName = payloadData.projectName;
    payload.projectMasterId = payloadData.projectMasterId;
    payload.customerName = customerName;
    payload.rfqNumber = payloadData.rfqNumber;
    payload.rfqMasterId = payloadData.rfqMasterId;
    payload.salesQuotationMasterId = payloadData.salesQuotationMasterId;
    payload.quotationNumber = payloadData.quotationNumber;
    payload.transactionId = props.bankTxData.transactionId;
    payload.institutionName = props.bankTxData.institutionName;
    payload.paymentChannel = props.bankTxData.paymentChannel;
    payload.merchantName = props.bankTxData.merchantName;
  
    return [payload];
  }