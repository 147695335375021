import axios from "axios";
import config from "../../../config/environmentConfig";
import { COMMON_ACTIONS, SMS_ACTION_LIST } from "../../../static/constants";
import { showToasterMessage } from "../../../utils";
const { lang } = require("../../../translations/" +(sessionStorage.getItem("language") || "en") +".js");

export const getTimeTableByClassSection = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/timetable/getTimeTableByClass?relationshipId=${payload.relationshipId}&classId=${payload.classId}&sectionId=${payload.sectionId}&startDate=${payload.startDate || ''}&endDate=${payload.endDate || ''}`)
      .then((res) => {
        dispatch({
          type: SMS_ACTION_LIST.TIME_TABLE_LIST,
          data: res.data
        });
      })
      .catch((err) => {
        showToasterMessage({
          messageType: "error",
          description: lang[((err.response || {}).data || {}).message] || "Some error occurred"
        });
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
      })
  };
};