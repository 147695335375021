import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


  export const saveOrUpdateStudentEnrollment = (payload) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/student/assignEnrolmentForm?relationshipId=${payload.relationshipId}&`, payload.studentsToEnroll)
        .then(res => {
          showToasterMessage({ description: (res || {}).message || 'Student enrolled successfully.', messageType: 'success' });
        })
        .catch(err => {
          showToasterMessage({ description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        })
    }
  }