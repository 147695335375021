import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS, SMS_ACTION_LIST } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
import { popModalFromStack } from '../../../../../actions/commonActions';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const createAssignment = (payload, props) => {
  var formData = new FormData();
    if(props.fileList && props.fileList.length){
      props.fileList.forEach((file) => {
        formData.append('files', file, file.name);
      })
    }

    delete payload.fileList;
    formData.append("payload", JSON.stringify(payload));
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/assignment/createAssignment`,
        formData,
        {
          headers: {
            'content-type': 'multipart/form-data'
          }
        }
      )
      .then(res => {
        showToasterMessage({ messageType: "success", description: lang[(res.data || {}).message] || "Saved succesfully" });
        popModalFromStack();

        if (props.getSchoolAssignment) {
          const payload = {
            relationshipId: props.companyInfo.relationshipId,
            relationshipEmployeeId: props.userInfo.relationshipEmployeeId,
            formId: props.defaultSchoolForm,
            pageNumber: 1,
            pageSize: 200,
          };
          props.getSchoolAssignment(payload);
          props.getSchoolAssignmentCount(payload);
        }
        props.popModalFromStack();

        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch(err => {
        if (lang[((err.response || {}).data || {}).message]) {
          showToasterMessage({ messageType: "error", description: lang[((err.response || {}).data || {}).message] || "Some error occurred." });
        } else {
          showToasterMessage({ messageType: "error", description: "Some error occurred." });
          props.popModalFromStack();
        }
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
  }
}

export const getAssignmentData= (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/assignment/getById?relationshipId=${payload.relationshipId}&assignmentId=${payload.assignmentId}`)
      .then(res => {
        dispatch({ type: SMS_ACTION_LIST.ASSIGNMENT_DATA, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = SMS_ACTION_LIST.ASSIGNMENT_DATA;
        }
      })
  }
}

