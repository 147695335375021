import axios from 'axios';
import config from '../../config/environmentConfig';
import { COMMON_ACTIONS } from '../../static/constants'
import { showToasterMessage } from '../../utils';
const { lang } = require('../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const resendActivationLink = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.USERS_BASE_URL}${config.API_VERSION}/${config.rootContextUsers}/clientRelationship/resendActivationEmail?email=${payload.username}`)
      .then(res => {
        showToasterMessage({ messageType: 'success', description: ((res || {}).data || {}).message || 'Resend Activation Email Sent Successfully' })
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' });
        }
      })
  }
}