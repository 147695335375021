import React, { Fragment, useMemo } from "react";
import { CONSTANTS, SUPPLIER_LISTING_PAGE,MODULE_CODED_VALUES } from "../../../static/constants";
import "./details.scss";
import { Tabs, Button } from "antd";
import { FormattedMessage } from "react-intl";
import Communication from "./communications";
import TotalOrders from "../../../containers/modal/modalBody/supplier/TotalOrders";
import OpenOrder from "../../../containers/modal/modalBody/supplier/OpenOrders";
import UnpaidInvoices from "../../../containers/modal/modalBody/supplier/UnpaidInvoices";
import PaidInvoices from "../../../containers/modal/modalBody/supplier/PaidInvoices";
import TaxIdentification from "./taxIdentification";
import { fetchDataIfNeeded,getPermissionData } from "../../../utils";
import { EditOutlined } from "@ant-design/icons";
import ShowAddress from "../../../containers/supplier/Listing/ShowAddress";
import PurchaseLedger from "./ledgerTransactions/purchaseLedger";
import Invoice from "./ledgerTransactions/invoice";
import { getInitialsFromString, capitalizeFirstLetter } from "../../../utils";
import Information from "./Sidenav/Information";
import ContactInfo from "./Sidenav/Contact";
import Contact from "./contacts";
import Tasks from "./tasks";

const { TabPane } = Tabs;
const format = CONSTANTS.DISPLAY_DATE_FORMAT;

function callback(key, props) {
  const payload = props.payload;

  switch (key) {
    case "1":
      props.resetPurchaseOrderTransactionsListData();
      props.fetchSupplierPurchaseOrderTransactions(payload);
      props.fetchSupplierPurchaseOrderTransactionCount(payload);
      break;

    case "4":
      fetchDataIfNeeded(
        "fetchSupplierCommunications",
        "commuincationList",
        props,
        payload
      );
      fetchDataIfNeeded("fetchAllEmployees", "allEmployee", props, payload);
      break;

    case "5":
      fetchDataIfNeeded(
        "fetchSupplierActivities",
        "activitiesList",
        props,
        payload
      );
      break;

    case "3":
      fetchDataIfNeeded("fetchAllContacts", "contacts", props, payload);
      break;

    case "2":
      fetchDataIfNeeded("fetchSupplierAddresses", "addressList", props, payload);
      break;

    case "6":
      props.fetchSupplierTaxIdentifications(payload);
      break;

    case "7":
      fetchDataIfNeeded("fetchSupplierToDoTasks", "taskList", props, payload);
      break;

    case "8":
      const ledgerPayload = { ...props.payload };
      ledgerPayload.filters = {
        supplierId: props.supplierId,
      };
      props.fetchSupplierLedgerTxSummaryList(ledgerPayload);
      break;

    case "9":
      const invoicePayload = { ...props.payload, pageNumber: 1, pageSize: 100 };
      invoicePayload.filters = {
        supplierId: props.supplierId,
      };
      props.resetInvoiceTransactionListData();
      props.fetchSupplierInvoiceList(invoicePayload);
      props.fetchSupplierInvoiceCount(invoicePayload);
      break;

    default:
      // No default behavior specified
      break;
  }
}

const Details = (props) => {
  const primaryPerm = (props.permissions && props.permissions.primary) || [];

  const permissionsData = useMemo(()=>{
    return getPermissionData(primaryPerm, MODULE_CODED_VALUES.SUPPLIER_ADDRESS )
  },[])

  const openModalOfSelectedTile = (data) => {
    switch (data.type) {
      case SUPPLIER_LISTING_PAGE.TOTAL_ORDERS:
        props.pushModalToStack({
          title: (
            <Fragment>
              <div className="ant-modal-title">
                {" "}
                <FormattedMessage id="totalOrder.text" defaultMessage="" />
              </div>
            </Fragment>
          ),
          modalBody: <TotalOrders {...props} />,
          width: 1000,
          hideFooter: true,
          wrapClassName: "tiles-modal",
        });
        break;
      case SUPPLIER_LISTING_PAGE.OPEN_PO:
        props.pushModalToStack({
          title: (
            <Fragment>
              <div className="ant-modal-title">
                <FormattedMessage
                  id="common.Open.Purchase.Orders"
                  defaultMessage=""
                />{" "}
              </div>
            </Fragment>
          ),
          modalBody: <OpenOrder {...props} />,
          width: 1000,
          hideFooter: true,
          wrapClassName: "tiles-modal",
        });
        break;
      case SUPPLIER_LISTING_PAGE.UNPAID_BALANCE:
        props.pushModalToStack({
          title: (
            <Fragment>
              <div className="ant-modal-title">
                <FormattedMessage
                  id="common.Unpaid.Balance"
                  defaultMessage=""
                />{" "}
              </div>
            </Fragment>
          ),
          modalBody: <UnpaidInvoices {...props} />,
          width: 1000,
          hideFooter: true,
          wrapClassName: "tiles-modal",
        });
        break;
      case SUPPLIER_LISTING_PAGE.TOTAL_PURCHASE:
        props.pushModalToStack({
          title: (
            <Fragment>
              <div className="ant-modal-title">
                <FormattedMessage
                  id="common.Total.Purchases"
                  defaultMessage=""
                />{" "}
              </div>
            </Fragment>
          ),
          modalBody: <PaidInvoices {...props} />,
          width: 1000,
          hideFooter: true,
          wrapClassName: "tiles-modal",
        });
        break;
      default:
        break;
    }
  };

  const getAddressInfoFragment = (obj) => {
    return (
      <>
        {obj.streetAddress1 ? (
          <span className="billing-address">{obj.streetAddress1}</span>
        ) : (
          ""
        )}
        {obj.streetAddress2 ? (
          <span className="billing-address"> {obj.streetAddress2}</span>
        ) : (
          ""
        )}
        {obj.cityName ? (
          <div className="billing-address">
            {`${obj.cityName || ""}${obj.stateName ? ", " + obj.stateName : ""
              }${obj.zipCode ? ", " + obj.zipCode : ""}`}
          </div>
        ) : (
          ""
        )}
        {obj.countryName ? (
          <div
            className="billing-address"
            style={{ textTransform: "uppercase" }}
          >
            {`${capitalizeFirstLetter(obj.countryName) || ""}`}
          </div>
        ) : (
          ""
        )}
      </>
    );
  };

  // const tilesData = [
  //   {
  //     footer: "TOTAL ORDERS",
  //     count: 0,
  //     icon: "fa fa-bullseye",
  //     antIcon: <BookTwoTone />,
  //     color: "darkGray-tile",
  //     type: SUPPLIER_LISTING_PAGE.TOTAL_ORDERS,
  //   },
  //   {
  //     footer: "OPEN PURCHASE ORDERS",
  //     count: 0,
  //     icon: "fa fa-file-text-o",
  //     antIcon: <ContainerTwoTone />,
  //     color: "green-tile",
  //     type: SUPPLIER_LISTING_PAGE.OPEN_PO,
  //   },
  //   {
  //     footer: "UNPAID BALANCE",
  //     count: 0,
  //     // currencyIcon: props.companyInfo.currencyIcon,
  //     antIcon: <ExclamationCircleTwoTone />,
  //     color: "blue-tile",
  //     type: SUPPLIER_LISTING_PAGE.UNPAID_BALANCE,
  //   },
  //   {
  //     footer: "TOTAL PURCHASE",
  //     count: 0,
  //     // currencyIcon: props.companyInfo.currencyIcon,
  //     antIcon: <CalculatorTwoTone />,
  //     color: "magenta-tile",
  //     type: SUPPLIER_LISTING_PAGE.TOTAL_PURCHASE,
  //   },
  // ];

  // const supplierObj = props.supplier || {};

  const supplierObj = props.supplier;

  return (
    <>
      {/* <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            <FormattedMessage
              id="heading.purchase.supplier.supplierDetails"
              defaultMessage=""
            />
          </div>
          <div className="vertical-sep" />
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
      </div> */}

      {/* <div className="pb10" /> */}
      {/* <Tile
        tilesData={tilesData}
        getSelectedTile={(data) => {
          openModalOfSelectedTile(data);
        }}
      /> */}

      {/* <div className="view-container">
        <Collapse accordion>
          <Panel
            header={`Supplier Information (${supplierObj.supplierStoreName})`}
            key="1"
          >
            <Row>
              <Col span={6}>
                <div className="address-info">
                  <div>{supplierAddress.streetAddress1}</div>
                  <div>{supplierAddress.streetAddress2}</div>
                  <div>{supplierAddress.stateName}</div>
                </div>
              </Col>
              <Col span={10}>
                <div className="customer-identity-number">
                  <Row>
                    <Col span={10}>
                      <FormattedMessage id="drawer.supplier.supplierType" />
                    </Col>

                    <Col span={14}>
                      {supplierObj.supplierTypeName ? (
                        <FormattedMessage id={supplierObj.supplierTypeName} />
                      ) : (
                        "-"
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col span={10}>
                      <FormattedMessage id="drawer.supplier.supplierSource" />
                    </Col>

                    <Col span={14}>
                      {supplierObj.supplierSource ? (
                        <FormattedMessage id={supplierObj.supplierSource} />
                      ) : (
                        "-"
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col span={10}>
                      <FormattedMessage id="drawer.supplier.industry" />
                    </Col>

                    <Col span={14}>
                      {supplierObj.industryName ? (
                        <FormattedMessage id={supplierObj.industryName} />
                      ) : (
                        "-"
                      )}
                    </Col>
                  </Row>

                  {supplierObj.gstMechanism ? (
                    <Row>
                      <Col span={10}>
                        <FormattedMessage
                          id="common.gst.mechanism"
                          defaultMessage=""
                        />
                      </Col>

                      <Col span={14}>
                        {supplierObj.gstMechanism
                          ? supplierObj.gstMechanism
                          : "-"}
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}

                  {supplierObj.locatedInSez === 1 ? (
                    <Row>
                      <Col span={10}>
                        <FormattedMessage
                          id="common.Located.in.SEZ"
                          defaultMessage=""
                        />
                      </Col>

                      <Col span={14}>
                        {supplierObj.locatedInSez === 1 ? "true" : "false"}
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                </div>
              </Col>
              <Col span={8}>
                <div className="customer-identity-number">
                  <Row>
                    <Col span={10}>
                      <FormattedMessage id="drawer.customer.paymentTerm" />
                    </Col>

                    <Col span={14}>
                      {supplierObj.paymentTermName ? (
                        <FormattedMessage id={supplierObj.paymentTermName} />
                      ) : (
                        "-"
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col span={10}>
                      <FormattedMessage id="drawer.customer.websiteUrl" />
                    </Col>

                    <Col span={14}>
                      {supplierObj.webSite ? (
                        <FormattedMessage id={supplierObj.webSite} />
                      ) : (
                        "-"
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col span={10}>
                      <FormattedMessage id="drawer.customer.rating" />
                    </Col>

                    <Col span={14}>
                      {supplierObj.starRating ? (
                        <FormattedMessage id={supplierObj.starRating} />
                      ) : (
                        "-"
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={10}>
                      <FormattedMessage
                        id="common.Relationship.since"
                        defaultMessage=""
                      />
                    </Col>

                    <Col span={14}>
                      {supplierObj.relationshipSince
                        ? getMomentDateForUIReadOnly({
                            date: new Date(supplierObj.relationshipSince),
                            format,
                          })
                        : "-"}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={10}>
                      <FormattedMessage
                        id="common.Total.balance.due"
                        defaultMessage=""
                      />
                    </Col>

                    <Col span={14}>
                      <strong>
                        {props.companyInfo.currencyIcon ? (
                          <i className={props.companyInfo.currencyIcon}></i>
                        ) : (
                          props.companyInfo.currencyCode + " "
                        )}
                      </strong>
                      {Number(supplierObj.totalAmountDue || 0).toFixed(2)}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={10}>
                      <FormattedMessage
                        id="common.available.credit"
                        defaultMessage=""
                      />
                    </Col>

                    <Col span={14}>
                      <strong>
                        {props.companyInfo.currencyIcon ? (
                          <i className={props.companyInfo.currencyIcon}></i>
                        ) : (
                          props.companyInfo.currencyCode + " "
                        )}
                      </strong>
                      {Number(supplierObj.availableCredit || 0).toFixed(2)}
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Panel>
        </Collapse>

        <Tabs
          className="primary-tab-container secondary-tab"
          onChange={(key) => {
            callback(key, props);
          }}
          type="line"
        >
         
          <TabPane
            tab={
              <FormattedMessage
                id="supplier.listing.actionMenu.title.address"
                defaultMessage=""
              />
            }
            key="2"
          >
            <ShowAddress supplierId={props.supplierId} />,
          </TabPane>
          <TabPane
            tab={<FormattedMessage id="common.Contacts" defaultMessage="" />}
            key="3"
          >
            <Contact {...props} />
          </TabPane>
          <TabPane
            tab={<FormattedMessage id="communications" defaultMessage="" />}
            key="4"
          >
            <Communication {...props} />
          </TabPane>
          <TabPane
            tab={<FormattedMessage id="activities" defaultMessage="" />}
            key="5"
          >
            <Activity {...props} />
          </TabPane>
          <TabPane
            tab={
              <FormattedMessage
                id="addItem.text.taxIdentification"
                defaultMessage=""
              />
            }
            key="6"
          >
            <TaxIdentification {...props} />
          </TabPane>

         
          <TabPane
            tab={<FormattedMessage id="ledgerSummary.text" defaultMessage="" />}
            key="8"
          >
            <PurchaseLedger {...props} />
          </TabPane>
          <TabPane
            tab={
              <FormattedMessage id="invoiceSummary.text" defaultMessage="" />
            }
            key="9"
          >
            <Invoice {...props} />
          </TabPane>
        </Tabs>
      </div> */}
      <div className="txn-header">
        <div className="left-itms">
          <ul>
            <li className="title">Supplier Details </li>
          </ul>
        </div>
      </div>
      <div className="profile-container">
        <div className="sidenav">
          <div className="sidenav-top">
            <div className="top-view">
              <div className="view1">
                <div className="name-icon">
                  {supplierObj.supplierStoreName
                    ? getInitialsFromString(supplierObj.supplierStoreName)
                    : "USER"}
                </div>
                <div>
                  <div className="profile-name">
                    {supplierObj.supplierStoreName}
                  </div>
                  <div>
                    {props.primaryContact?.emailAddress ||
                     <div className="empty-data-box"></div>
                    }
                  </div>
                </div>
              </div>

              {/* <div className="st-address">
                <i className="fi fi-rs-marker" />
                <div style={{marginLeft:"20px"}}>
                  {(Object.keys(supplierObj.primaryAddress || {}) || []).length
                    ? getAddressInfoFragment(supplierObj.primaryAddress)
                    :  <><div className="grey-box" style={{marginLeft:"0"}}></div>
                    <div className="grey-box" style={{width:"150px",marginLeft:"0"}}></div></>
                    }
                </div>
              </div> */}
              <div className="addressBar">
                <div className="addressIcon"><i className="fi fi-rs-marker" /></div>
                {(Object.keys(props.primaryAddress || {}) || []).length ? (
                  <>
                    <div className="address-data">
                      <div>{getAddressInfoFragment(props.primaryAddress)}</div>
                   {permissionsData.update ?   <Button
                        className="edit-btn-rf"
                        type="primary"
                        ghost
                        style={{ color: "#1890ff" }}
                        onClick={() => {
                          props.updateHeaderState({
                            address: {
                              addressDrawer: true,
                              formData: {
                                isUpdate:true,
                                ...props.primaryAddress,
                                // isDefault: "Yes",
                                supplierId: props.supplierId,
                              }, 
                            },
                          });
                        }}
                      >
                        <EditOutlined style={{ color: "#1890ff" }} />
                        <span style={{ color: "#1890ff" }}>Edit</span>
                      </Button> : "" }
                    </div>
                  </>
                ) : (

                  <div className="address-data">
                    <div>
                      <div className="grey-box"></div>
                      <div className="grey-box"></div>
                    </div>
             
                    {permissionsData.create ?   <Button
                      className="edit-btn-rf"
                      type="primary"
                      ghost
                      style={{ color: "#1890ff" }}
                      onClick={() => {
                        props.updateHeaderState({
                          address: {
                            addressDrawer: true,
                            formData: {

                              isUpdate:false,
                              // isDefault: "Yes",
                              supplierId: props.supplierId,
                            },
                          },
                        });
                      }}
                    >
                      <EditOutlined style={{ color: "#1890ff" }} />
                      <span style={{ color: "#1890ff" }}>Add</span>
                    </Button> : "" }

                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="sidenav-bottom">
            <div className="tabs-info">
              <Tabs type="line" className="custom-tab-st" animated={false}>
                <TabPane tab="Information" key="1">
                  <Information {...supplierObj} {...props} {...props.primaryContact} {...props.primaryAddress} />
                </TabPane>
                <TabPane tab="Contact" key="2">
                  {/* {...customerObj.primaryContact}  */}
                  <ContactInfo {...props.primaryContact} />
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>
        <div className="leftnav">
          <div className="view-container" style={{margin: '10px 0px'}}>
          <Tabs
            className="primary-tab-container secondary-tab"
            onChange={(key) => {
              callback(key, props);
            }}
            type="line"
            animated={false}
          >
            {/* <TabPane tab="Activities" key="1">
              <Activities {...props} />
            </TabPane> */}

            <TabPane
              tab={
                <FormattedMessage
                  id="supplier.listing.actionMenu.title.address"
                  defaultMessage=""
                />
              }
              key="2"
            >
              <ShowAddress {...props} supplierId={props.supplierId} />,
            </TabPane>
            <TabPane
              tab={<FormattedMessage id="common.Contacts" defaultMessage="" />}
              key="3"
            >
              <Contact {...props} contactId={props.contactId}/>
            </TabPane>
            <TabPane tab="Tasks" key="5">
              <Tasks {...props} />
            </TabPane>
            <TabPane
              tab={<FormattedMessage id="communications" defaultMessage="" />}
              key="4"
            >
              <Communication {...props} />
            </TabPane>

            <TabPane
              tab={
                <FormattedMessage
                  id="addItem.text.taxIdentification"
                  defaultMessage=""
                />
              }
              key="6"
            >
              <TaxIdentification
                {...props}
                supplierTaxIdentificationId={props.supplierTaxIdentificationId}
              />
            </TabPane>

            <TabPane
              tab={
                <FormattedMessage id="ledgerSummary.text" defaultMessage="" />
              }
              key="8"
            >
              <PurchaseLedger {...props} />
            </TabPane>
            <TabPane
              tab="Purchase"
              key="9"
            >
              <Invoice {...props} />
            </TabPane>
          </Tabs>
          </div>
        </div>
      </div>
    </>
  );
};

export default Details;
