import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS, CRM_ACTION_LIST } from '../../../static/constants';
import { showToasterMessage } from '../../../utils';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const fetchAllLeadList = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios 
      // .get(`${config.CRM_BASE_URL}${config.API_VERSION}/${config.rootContext}/lead/getAllLeadsByPage?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 100}&pageNumber=${payload.pageNumber - 1 || 0 }&crmLeadFormSettingId=${payload.crmLeadFormSettingId || 0}&startDate=${payload.startDate|| null}&endDate=${payload.endDate || null}`)
      .get(`${config.CRM_BASE_URL}${config.API_VERSION}/${config.rootContextCRM}/lead/getLeadsByFilters?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 25}&pageNumber=${payload.pageNumber - 1 || 0 }&filters=${encodeURIComponent(JSON.stringify(payload.filters || {}))}`)
      .then(res => {
        const data = {
          list: res.data,
          pageNo: res.pageNo,
        }
        dispatch({ type: CRM_ACTION_LIST.ALL_LEAD_LIST, data });
      })
      .catch((err) => {
       
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      })
  }
}

export const getAllLeadListCount = (payload) => {
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_HEADER_LOADER });
        return axios 
        // .get(`${config.CRM_BASE_URL}${config.API_VERSION}/${config.rootContext}/lead/getLeadsCount?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 100}&pageNumber=${payload.pageNumber - 1 || 0 }&crmLeadFormSettingId=${payload.crmLeadFormSettingId || 0}&startDate=${payload.startDate|| null}&endDate=${payload.endDate || null}`)
        .get(`${config.CRM_BASE_URL}${config.API_VERSION}/${config.rootContextCRM}/lead/countLeadsByFilters?relationshipId=${payload.relationshipId}&filters=${encodeURIComponent(JSON.stringify(payload.filters || {}))}`)
        .then(res => {
            const data = res.data
            dispatch({ type: CRM_ACTION_LIST.ALL_LEAD_LIST_COUNT, data });
        })
        .catch((err) => {
            if (!err.__isRetryRequest) {
            showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
            } else {
            err.actionToCall = CRM_ACTION_LIST.ALL_LEAD_LIST_COUNT;
            // err.data = err.data;
            }
        }).finally(() => {
          dispatch({
            type: COMMON_ACTIONS.HIDE_HEADER_LOADER,
          });
        })
    }
}

export const deleteLead = (payload, props) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios 
        .post(`${config.CRM_BASE_URL}${config.API_VERSION}/${config.rootContextCRM}/lead/deleteLead`, payload)
        .then(res => {
            showToasterMessage({
                description: 'Deleted Successfully!',
                messageType: 'success'
            })

            const payload = {
                relationshipId: props.companyInfo.relationshipId,
                pageNumber: 1,
                pageSize: 25,
            }
            props.fetchAllLeadList(payload);
            props.getAllLeadListCount(payload);
        })
        .catch((err) => {
          if (!err.__isRetryRequest) {
            showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          } else {
            err.actionToCall = CRM_ACTION_LIST.CRM_FORM_LIST;
            // err.data = err.data;
          }
        })
    }
}


export const saveOrUpdateNotes = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.CRM_BASE_URL}${config.API_VERSION}/${config.rootContextCRM}/leadNotes/saveOrUpdate`, payload)
      .then(res => {
        showToasterMessage({
          description: (res || {}).message || 'Created Successfully.',
          messageType: 'success'
        })
        const payload = {
          relationshipId: props.companyInfo.relationshipId,
          pageNumber: 1,
          pageSize: 25,
        }
        props.getAllLeadNotes(payload); 
        props.updateState({ notesContent: '' })
      })
      .catch((err = {}) => {
          showToasterMessage({
            description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
          })
      })
  }
}

export const getAllLeadNotes = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios 
      .get(`${config.CRM_BASE_URL}${config.API_VERSION}/${config.rootContextCRM}/leadNotes/getAllLeadNotes?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: CRM_ACTION_LIST.CRM_LEAD_NOTES_LIST, data: res.data });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = CRM_ACTION_LIST.CRM_LEAD_NOTES_LIST;
          // err.data = err.data;
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      })
  }
}

export const resetAllLeadNotes = (payload) => {
  return dispatch => {
        dispatch({ type: CRM_ACTION_LIST.CRM_LEAD_NOTES_LIST, data: [] });
  }
}
export const getAllLeadNotesByUserId = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios 
      .get(`${config.CRM_BASE_URL}${config.API_VERSION}/${config.rootContextCRM}/leadNotes/getAllLeadNotesByUserId?relationshipId=${payload.relationshipId}&createdByEmpId=${payload.createdByEmpId}`)
      .then(res => {
        dispatch({ type: CRM_ACTION_LIST.CRM_LEAD_NOTES_LIST, data: res.data });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = CRM_ACTION_LIST.CRM_LEAD_NOTES_LIST;
          // err.data = err.data;
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      })
  }
}

export const saveOrUpdateReminder = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.CRM_BASE_URL}${config.API_VERSION}/${config.rootContextCRM}/leadReminder/saveOrUpdate`, payload)
      .then(res => {
        showToasterMessage({
          description: (res || {}).message || 'Created Successfully.',
          messageType: 'success'
        })
        const payload = {
          relationshipId: props.companyInfo.relationshipId,
          pageNumber: 1,
          pageSize: 25,
        }
        props.getAllLeadReminder(payload);
      })
      .catch((err = {}) => {
          showToasterMessage({
            description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
          })
      })
  }
}

export const getAllLeadReminder = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios 
      .get(`${config.CRM_BASE_URL}${config.API_VERSION}/${config.rootContextCRM}/leadReminder/getAllLeadReminder?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: CRM_ACTION_LIST.CRM_LEAD_REMINDER_LIST, data: res.data });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = CRM_ACTION_LIST.CRM_LEAD_REMINDER_LIST;
          // err.data = err.data;
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      })
  }
}


export const changeLeadStatus = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.CRM_BASE_URL}${config.API_VERSION}/${config.rootContextCRM}/lead/changeLeadStatus?relationshipId=${payload.relationshipId}&leadId=${payload.crmLeadId}&relEmpId=${payload.relEmpId}&statusName=${payload.statusName}&statusId=${payload.statusId}`)
      .then(res => {
        showToasterMessage({
          description: (res || {}).message || 'Updated Successfully.',
          messageType: 'success'
        })
        const payload = {
          relationshipId: props.companyInfo.relationshipId,
          pageNumber: 1,
          pageSize: 25,
      }
      props.fetchAllLeadList(payload);
      // props.getAllLeadListCount(payload);
      })
      .catch((err = {}) => {
          showToasterMessage({
            description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
          })
      })
  }
}

export const changeCrmLeadEmpAssignedList = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.CRM_BASE_URL}${config.API_VERSION}/${config.rootContextCRM}/assignedEmpToLead/save?empId=${props.userInfo.relationshipEmployeeId}`, payload)
      .then(res => {
        showToasterMessage({
          description: (res || {}).message || 'Updated Successfully.',
          messageType: 'success'
        })
        const payload = {
          relationshipId: props.companyInfo.relationshipId,
          pageNumber: 1,
          pageSize: 25,
      }
      props.fetchAllLeadList(payload);
      // props.getAllLeadListCount(payload);
      })
      .catch((err = {}) => {
          showToasterMessage({
            description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
          })
      })
  }
}


export const saveOrUpdateLeadStatus = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.CRM_BASE_URL}${config.API_VERSION}/${config.rootContextCRM}/leadStatus/saveOrUpdateLeadStatus`, payload)
      .then(res => {
        showToasterMessage({
          description: (res || {}).message || 'Created Successfully.',
          messageType: 'success'
        })
        const payload = {
          relationshipId: props.companyInfo.relationshipId,
          pageNumber: 1,
          pageSize: 25,
        }
        props.getAllLeadStatus(payload);
      })
      .catch((err = {}) => {
          showToasterMessage({
            description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
          })
      })
  }
}

export const deleteCrmNotes = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios 
      .post(`${config.CRM_BASE_URL}${config.API_VERSION}/${config.rootContextCRM}/leadNotes/delete`, payload)
      .then(res => {
          showToasterMessage({
              description: 'Deleted Successfully!',
              messageType: 'success'
          })

          const payload = {
              relationshipId: props.companyInfo.relationshipId,
              pageNumber: 1,
              pageSize: 25,
          }
          props.getAllLeadNotes(payload);
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = CRM_ACTION_LIST.CRM_FORM_LIST;
          // err.data = err.data;
        }
      })
  }
}

export const deleteLeadReminder = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios 
      .post(`${config.CRM_BASE_URL}${config.API_VERSION}/${config.rootContextCRM}/leadReminder/delete`, payload)
      .then(res => {
          showToasterMessage({
              description: 'Deleted Successfully!',
              messageType: 'success'
          })

          const payload = {
              relationshipId: props.companyInfo.relationshipId,
              pageNumber: 1,
              pageSize: 25,
          }
          props.getAllLeadReminder(payload);
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = CRM_ACTION_LIST.CRM_FORM_LIST;
          // err.data = err.data;
        }
      })
  }
}


