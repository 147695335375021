import axios from 'axios';
import config from '../../../../../../config/environmentConfig';
import { COMMON_ACTIONS,SALES_INVOICE_ACTION_LIST } from '../../../../../../static/constants';
import { showToasterMessage } from '../../../../../../utils';
const { lang } = require('../../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const getXeroContacts = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/alpide-xero/xeroInvoice/getAllContacts?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: COMMON_ACTIONS.XERO_CONTACT_LIST, data: (res.data || {}).Contacts  });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch(err => {
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else {
          err.actionToCall = COMMON_ACTIONS.XERO_CONTACT_LIST;
        }
      })
  }
}

export const getXeroAccounts = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/alpide-xero/xeroInvoice/getAllAccounts?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: COMMON_ACTIONS.XERO_LEDGER_LIST, data: (res.data || {}).Accounts  });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch(err => {
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else {
          err.actionToCall = COMMON_ACTIONS.XERO_LEDGER_LIST;
        }
      })
  }
}

export const getAllXeroTaxes = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/alpide-xero/xeroInvoice/getAllTaxes?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: COMMON_ACTIONS.XERO_TAX_LIST, data: (res.data || {}).TaxRates  });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch(err => {
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else {
          err.actionToCall = COMMON_ACTIONS.XERO_TAX_LIST;
        }
      })
  }
}

export const getInvoicesByInInvoiceMasterId = (payload) => {
  let rootUrl = payload.type === 'Supplier' ? config.rootContextPurchase: config.rootContextSales
  let uri = `${config.API_BASE_URL}${config.API_VERSION}/${rootUrl}${payload.type === 'Supplier' ? '/supplierInvoice/getInvoices' : '/customerInvoice/getInvoices'}`
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${uri}?relationshipId=${payload.relationshipId}&invoiceMasterIdList=${payload.invoiceMasterIdList}`)
      .then(res => {
        dispatch({ type: SALES_INVOICE_ACTION_LIST.INVOICE_LIST_BY_MASTER_ID, data: res.data  });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch(err => {
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else {
          err.actionToCall = SALES_INVOICE_ACTION_LIST.INVOICE_LIST_BY_MASTER_ID;
        }
      })
  }
}

export const resetInvoicesListByInInvoiceMasterId = () => {
  return dispatch => {
    dispatch({ type: SALES_INVOICE_ACTION_LIST.INVOICE_LIST_BY_MASTER_ID_RESET });
  }
}