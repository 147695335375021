import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMMON_ACTIONS, SHIPMENT_ACTION_LIST } from '../../../../static/constants';
import { showToasterMessage, getFinancialYear} from '../../../../utils';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchAllShipment = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/shipment/getAllShipments?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1)-1}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: SHIPMENT_ACTION_LIST.SHIPMENT_LIST, data });
      })
      .catch((err) => {
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = SHIPMENT_ACTION_LIST.SHIPMENT_LIST;
          err.data = {
            pageNo: payload.pageNumber,
            list: []
          };
        }
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
      })
  }
}
export const getShipmentCount = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_HEADER_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/shipment/countAllShipments?relationshipId=${payload.relationshipId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
      .then(res => {
        dispatch({ type: SHIPMENT_ACTION_LIST.SHIPMENT_COUNT, data: res.data });
        dispatch({ type: COMMON_ACTIONS.HIDE_HEADER_LOADER });
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_HEADER_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = SHIPMENT_ACTION_LIST.SHIPMENT_COUNT;
        }
      })
  }
}
export const resetPaginatedData = () => {
  return dispatch => {
    dispatch({ type: SHIPMENT_ACTION_LIST.SHIPMENT_LIST_PAGE_RESET });
  }
}


export const deleteShipment = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/shipment/deleteShipment `, payload)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        props.fetchAllShipment(props);
        props.getShipmentCount(props);
        props.fetchSalesOrders && props.fetchSalesOrders(props);
        showToasterMessage({ messageType: 'success', description: (res.data || {}).message || 'Success' });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' });
      })
  }
}

export const updateUserStatus = (payload, props) => {
  return dispatch => {

    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/shipment/updateUserStatus`, payload)
      .then(res => {
        showToasterMessage({ messageType: 'success', description: lang['saved.successfully'] })
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        props.fetchAllShipment(props);
        props.fetchDeliveryNotesDetail && props.fetchDeliveryNotesDetail(payload);  
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }
      })
  }
}

export const fetchAllShipmentByFilter = (payload, history) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/shipment/getSalesShipmentSummary?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1)-1}&filters=${encodeURIComponent(JSON.stringify(payload.filters || {}))}`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: SHIPMENT_ACTION_LIST.SHIPMENT_LIST, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = SHIPMENT_ACTION_LIST.SHIPMENT_LIST;
          // err.data = {
          //   pageNo: payload.pageNumber,
          //   list: []
          // };
        }
      })
  }
}

export const getShipmentCountByFilter = (payload, history) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/shipment/countSalesShipmentSummary?relationshipId=${payload.relationshipId}&filters=${encodeURIComponent(JSON.stringify(payload.filters || {}))}`)
      .then(res => {
        dispatch({ type: SHIPMENT_ACTION_LIST.SHIPMENT_COUNT, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = SHIPMENT_ACTION_LIST.SHIPMENT_COUNT;
        }
      })
  }
}