import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { INBOUND_DELIVERY_ACTION_LIST, COMMON_ACTIONS } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const fetchInboundryDetailDetail = (payload) => {
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_DETAIL_LOADER });
        dispatch({ type: INBOUND_DELIVERY_ACTION_LIST.RESET_INBOUND_DELIVERY_DATA });
        return axios
          .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/inboundDelivery/getInboundDeliveryData?inboundDeliveryMasterId=${payload.inboundDeliveryMasterId}&relationshipId=${payload.relationshipId}&supplierId=${payload.supplierId}`)
          .then(res => {
            const { data } = res
            dispatch({ type: INBOUND_DELIVERY_ACTION_LIST.INBOUND_DELIVERY_DATA, data });
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
          .catch((err) => {
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            if(!err.__isRetryRequest){
              showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
            }else{
              err.actionToCall = INBOUND_DELIVERY_ACTION_LIST.INBOUND_DELIVERY_DATA;
            }
          }).finally(() => {
            dispatch({
              type: COMMON_ACTIONS.HIDE_DETAIL_LOADER,
            });
          })
      }
  }

  export const resetIDDetail = () => {
    return dispatch => {
      dispatch({ type: INBOUND_DELIVERY_ACTION_LIST.RESET_INBOUND_DELIVERY_DATA });
    }
  }

  export const uploadStamp = (payload) => {
    return dispatch => {
      let formData = new FormData();
      formData.append("file", payload.file);
      formData.append('objectKey', payload.objectKey);
      formData.append('rid', payload.rid);
      formData.append('supplierId', payload.supplierId);
      formData.append('inboundDeliveryMasterId', payload.inboundDeliveryMasterId);
      formData.append("contentType", "multipart/form-data;");
      formData.append("contentLength", "1234");
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/inboundDelivery/saveOrUpdateStamp`,
          formData, {
          headers: {
            'content-type': 'multipart/form-data'
          }
        })
        .then(res => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          dispatch({ type: INBOUND_DELIVERY_ACTION_LIST.INBOUND_DELIVERY_DATA, data: res.data });
        })
        .catch(err => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
    }
  }
  
  export const deleteStamp = (payload, props) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/inboundDelivery/deleteStamp?relationshipId=${payload.relationshipId}&supplierId=${payload.supplierId}&inboundDeliveryMasterId=${payload.inboundDeliveryMasterId}&objectKey=${payload.objectKey}`, payload)
        .then(res => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          props.fetchInboundryDetailDetail(payload);
          showToasterMessage({
            description: (res || {}).message || 'Deleted successfully.',
            messageType: 'success'
          })
        })
        .catch((err = {}) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (err.__isRetryRequest) {
          } else {
            showToasterMessage({
              description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
            })
          }
        })
    }
  }