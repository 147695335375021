import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//import CheckoutPlanComp from "../../../components/B2B/CheckoutPlan";
import { applyUserPlans, getAllPricingPlans, getUserCountry } from "./action";
import planData from "./planData.json";
import { CardComponent, CardNumber, CardExpiry, CardCVV, Provider } from "@chargebee/chargebee-js-react-wrapper";

 //import './Example1.css'

class CheckoutPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginData: props?.location?.state,
      isDataLoaded: false,
      errors: {},
      errorMessage: '',
      // CSS class names for field's status
      classes: {
        focus: 'focus',
        invalid: 'invalid',
        empty: 'empty',
        complete: 'complete',
      },
      // Google Fonts and other whitelisted fonts
      fonts: [
        'https://fonts.googleapis.com/css?family=Open+Sans'
      ],
      // Style customizations
      styles: {
        color: '#333',
        fontWeight: '500',
        fontFamily: 'Roboto, Segoe UI, Helvetica Neue, sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',

        ':focus': {
          color: '#424770',
        },

        '::placeholder': {
          color: 'transparent',
        },

        ':focus::placeholder': {
          color: '#7b808c',
        },

        ':-webkit-autofill': {
          webkitTextColor: '#333',
        }
      },

      // Styles for invalid field state
      invalid: {
        color: '#e41029',

        ':focus': {
          color: '#e44d5f',
        },
        '::placeholder': {
          color: '#FFCCA5',
        },
      },



    }

    // if(!props?.location?.state?.planCode){
    //   props.history.push("/");
    // }
  };
  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};

    const payload = {
      relationshipId: companyInfo.relationshipId,
    }
    this.props.getAllPricingPlans(payload);
    this.props.getUserCountry();
    window.Chargebee.init({
      site: "alpide-test",
      publishableKey: "test_9KdLB3laV1fzmNupMYMQyQRwUioTPYVL"
    });

    // get cb Instance
    const cbInstance = window.Chargebee.getInstance();
    this.setState({
      cbInstance: window.Chargebee.getInstance()
    })

  }

  componentWillReceiveProps(props) {

  }



  onSubmit = (e) => {
    if (e) e.preventDefault()
    if (this.cardRef) {
      // Call tokenize method on card element
      this.cardRef.current.tokenize().then((data) => {
        console.log('chargebee token', data)
      });
    }
  }

  onChange = (status) => {
    let errors = {
      ...this.state.errors,
      [status.field]: status.error
    };
    let errMessages = Object.values(errors).filter(message => !!message);
    this.setState({
      errors,
      errorMessage: errMessages
      //: errMessages.pop() || '',
    })
  }

  onReady = (el) => {
    el.focus();
  }
  cardRef = React.createRef();
  render() {

    // return (
    //   <CheckoutPlanComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    // )
    const { fonts, styles, classes, locale } = this.state;
    // Using individual fields mode
    return (
      <div className="App">

        <Provider cbInstance={this.state.cbInstance}>
          <div className="cell example" id="example-1">
            {this.state.cbInstance ? <form>
              <div className="ex1 container">
                <div className="ex1-wrap">
                  <div className="ex1-fieldset">
                    <div className="ex1-field">
                      <CardComponent className="field"
                        fonts={this.state.fonts}
                        classes={this.state.classes}
                        locale={this.state.locale}
                        styles={this.state.styles}
                        ref={this.cardRef}
                        onReady={this.onReady}
                      >
                     

                        <div className="ex1-field">
                          {/* Card number component */}
                          <CardNumber className="ex1-input" />
                          <label className="ex1-label">Card Number</label><i className="ex1-bar"></i>
                        </div>

                        <div className="ex1-fields">
                          <div className="ex1-field">
                            {/* Card expiry component */}
                            <CardExpiry className="ex1-input" />
                            <label className="ex1-label">Expiry</label><i className="ex1-bar"></i>
                          </div>

                          <div className="ex1-field">
                            {/* Card cvv component */}
                            <CardCVV className="ex1-input" />
                            <label className="ex1-label">CVC</label><i className="ex1-bar"></i>
                          </div>
                        </div>

                      </CardComponent>

                    </div>
                    {/* <button type="submit" onClick={this.onSubmit}>Pay now</button> */}
                    <button type="submit" className={this.state.loading ? "submit ex1-button" : "ex1-button"} onClick={this.onSubmit}> Tokenize</button>
                    {this.state.error && <div className="error" role="alert">{this.state.error}</div>}
                    {this.state.token && <div className="token" >{this.state.token}</div>}
                  </div>
                </div>
              </div>
            </form> : ''
            }
            <div id="errors">{this.state.errorMessage[0]?.message}</div>
          </div>
        </Provider>
      </div>
    );
  }

}
const mapStateToProps = (state) => {
  return {

    companyInfo: state.common.companyInfo,
    pricingPlans: state.common.pricingPlans,
    userLocation: (state.common.userLocation || {}).country_name
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getAllPricingPlans,
  applyUserPlans,
  getUserCountry
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPlan);
