import React, { Component, Fragment, useMemo } from "react";
// import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, DatePicker, Tooltip, Skeleton } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Dropdown } from "../../../../general/Dropdown";
// import config from '../../../../../config/environmentConfig';
import { MultipleSelectDropdown } from "../../../../general/MultipleSelectDropdown";
import * as filter from 'lodash.filter';
import * as find from 'lodash.find';

// import { CustomAgGrid } from '../../../../general/CustomAgGrid';
import { CONSTANTS, MODAL_TYPE, CONSTANTS_TRANSACTIONS, CONSTANTS_TRANSACTIONS_KEY, MODULE_CODED_VALUES } from '../../../../../static/constants';
import { getLabelName } from '../../../../modal/modalBody/customerInfo';
// import LoadingIcon from '../../../../../assets/images/loading.gif';
// import { LogoComponent } from "../../../../general/LogoComponent";
import { getMomentDateForUI, getPermissionData } from "../../../../../utils";
import { QuestionCircleTwoTone ,EditOutlined} from '@ant-design/icons';
import { TextBox } from "../../../../general/TextBox";
const format = CONSTANTS.DISPLAY_DATE_FORMAT;
class SalesEnquiryDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            size: 'default',
            billingAddress: this.defaultBillingAddress,
        };
    }

    handleSizeChange = e => {
        this.setState({ size: e.target.value });
    };

    handleNewAddressAddition = (props, payload, modalType) => {
        const formData = payload.formData;
        const { showModal, companyInfo } = props;
        formData.relationshipId = companyInfo.relationshipId;
        if (modalType === MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS) {
            payload.formData = { addressType: 'relationship', locationType: 'RelationshipBillingAddress' };
        }
        payload.formData.callback = function (address) {
            // props.hideModal(data);
            if (address.locationType === 'BillingAddress' || address.locationType === 'ShippingAddress') {
                props.fetchAddresses({
                    customerId: address.customerId,
                    relationshipId: (props.companyInfo || {}).relationshipId,
                    locationType: address.locationType
                });
            } else {
                props.getRelationshipAddress({
                    relationshipId: (props.companyInfo || {}).relationshipId
                });
            }
        }
        const data = {
            title: this.getModalTitle(payload, modalType),
            formData,
            hideFooter: true,
            modalData: {
                modalType: modalType,
                data: payload,
            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                // props.hideModal(data);
            }
        };
        props.pushModalToStack(data);
    }

    handleNewTaxIdentificationAddition = (props, payload, modalType) => {
        const formData = payload.formData;
        const { showModal, companyInfo } = props;
        formData.relationshipId = companyInfo.relationshipId;
        formData.callback = function (taxIdentification) {
            if (formData.customerId && props.customer && props.customer.customerTaxIdentificationsList) {
                let list = props.customer.customerTaxIdentificationsList || [];
                list.push(taxIdentification)
                props.updateState({ customer: { ...props.customer, customerTaxIdentificationsList: list } })
            }
        }
        const data = {
            title: this.getModalTitle(payload, modalType),
            formData,
            hideFooter: true,
            modalData: {
                modalType: modalType,
                data: payload,
            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                props.hideModal(data);
            }
        };
        showModal(data);
    }

    handleNewEmployeeAddition = (props, payload) => {
        const formData = payload.formData;
        const { showModal, companyInfo } = props;
        formData.relationshipId = companyInfo.relationshipId;
        const data = {
            title: <FormattedMessage id='addItem.text.employee' defaultMessage='' />,
            formData,
            hideFooter: true,
            modalData: {
                modalType: MODAL_TYPE.EMPLOYEE,
                data: payload,

            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                props.hideModal(data);
            }
        };
        showModal(data);
    }

    handleNewContactAddition = (props, payload) => {
        const formData = payload.formData;
        const { showModal, companyInfo } = props;
        formData.relationshipId = companyInfo.relationshipId;
        formData.callback = (contact) => {
            props.hideModal(data);
            if (contact.customerId) {
                props.fetchAllContacts({
                    customerId: contact.customerId,
                    relationshipId: (props.companyInfo || {}).relationshipId,
                });
            }
        }
        const data = {
            title: <div>
                <FormattedMessage id='addItem.text.contact' defaultMessage='' />
                <div style={{ 'font-size': '12px' }}> {formData.customerName || ''}</div>
            </div>,
            formData,
            hideFooter: true,
            modalData: {
                modalType: MODAL_TYPE.CONTACT,
                data: payload,

            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                this.gridApi && this.gridApi.stopEditing();
                props.hideModal(data);
            }
        };
        showModal(data);
    }

    handleNewProjectAddition = (props, payload) => {
        const formData = payload.formData;
        const { showModal, companyInfo } = props;
        this.gridApi && this.gridApi.stopEditing();
        formData.relationshipId = companyInfo.relationshipId;
        const data = {
            title: <FormattedMessage id='addItem.text.project' defaultMessage='' />,
            formData,
            hideFooter: true,
            modalData: {
                modalType: MODAL_TYPE.PROJECT,
                data: payload,

            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                showModal(data);
            }
        };
        showModal(data);
    }

    handleNewPriorityAddition = (props, payload) => {
      const formData = payload.formData;
      const { showModal, companyInfo } = props;
      formData.relationshipId = companyInfo.relationshipId;
      const data = {
          title: 'Add Priority',
          formData,
          hideFooter: true,
          modalData: {
              modalType: MODAL_TYPE.ACTIVITY_PRIORITY,
              data: payload,
          },
          handleSubmit: (formData = {}) => {
              data.formData.submittedOnce = true;
              showModal(data);
          }
      };
      showModal(data);
  }

    addItemConfirmationModal = (props, text, modalType) => {
        const { showModal, companyInfo } = props;
        const labelName = getLabelName(modalType);
        const modalBody = <span>
            <FormattedMessage
                id='addItem.confirmation'
                values={{ value: `${text} to ` }}
                defaultMessage=''
            />
            {labelName} ?
        </span>;

        let addFunc;
        let payload = {
            text: text,
            relationshipId: companyInfo.relationshipId
        }

        switch (modalType) {
            case MODAL_TYPE.DEPARTMENT: {
                addFunc = props.addDepartment;
                break;
            }
            case MODAL_TYPE.DOCUMENT_NAME: {
                addFunc = props.addDocumentName;
                payload.txType = CONSTANTS_TRANSACTIONS_KEY[CONSTANTS_TRANSACTIONS.INQUIRY];
                break;
            }
            default: {
                addFunc = () => { }
            }
        }

        const modalData = {
            modalBody,
            handleSubmit: () => {
                addFunc(payload);
                this.gridApi && this.gridApi.stopEditing();
                props.hideModal();
            },
        };
        showModal(modalData);
    }

    getModalTitle = (newTextObj, modalType) => {
        let title;

        switch (modalType) {
            case MODAL_TYPE.UOM: {
                title = <FormattedMessage id='addItem.text.department' defaultMessage='' />;
                break;
            }
            case MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS: {
                title = <div>
                    <FormattedMessage id='addItem.text.billingAddress' defaultMessage='' />
                    <h6>{(this.props.companyInfo || {}).storeName} </h6>
                </div>
                break;
            }
            case MODAL_TYPE.BILLING_ADDRESS: {
                title = <div>
                    <FormattedMessage id='addItem.text.billingAddress' defaultMessage='' />
                    <div style={{ 'font-size': '12px' }}> {this.props.customerName || ''}</div>
                </div>
                break;
            }
            case MODAL_TYPE.DOCUMENT_NAME: {
                title = <FormattedMessage id='addItem.text.documentName' defaultMessage='' />;
                break;
            }
            case MODAL_TYPE.NEW_TAX_IDENTIFICATION: {
                title = <div>
                    <FormattedMessage id='addItem.text.taxIdentification' defaultMessage='' />
                </div>
                break;
            }
            default:
                title = <FormattedMessage id='signup.confirmation.title' defaultMessage='' />;
        }
        return title;
    }

    handleNewItemAddition = (props, payload, modalType) => {
        const formData = payload.formData;
        const { showModal } = props;

        if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
            this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
        } else {
            const data = {
                title: this.getModalTitle(payload, modalType),
                formData,
                modalData: { modalType, data: payload },
                handleSubmit: (formData = {}) => {
                    if (formData.textBoxValue && formData.textBoxValue.length) {
                        this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
                    } else {
                        data.formData.submittedOnce = true;
                        showModal(data);
                    }
                }
            };
            showModal(data);
        }
    }

    deleteClickHandler = (props, modalType, payload) => {
        const { showModal, companyInfo } = props;
        const modalBody =
            <FormattedMessage
                id='deleteItem.confirmation'
                defaultMessage=''
                values={{ value: payload.text }}
            />;

        let deleteFunc;
        let callback = function (data) {
            if (modalType === MODAL_TYPE.CONTACT && props.customer.customerId) {
                props.fetchAllContacts({
                    customerId: props.customer.customerId,
                    relationshipId: (props.companyInfo || {}).relationshipId,
                });
            }
            if(modalType === MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS){
                props.getRelationshipAddress({
                  relationshipId: (props.companyInfo || {}).relationshipId
                });
              }
        }

        switch (modalType) {
            case MODAL_TYPE.DEPARTMENT: {
                deleteFunc = props.deleteDepartment;
                break;
            }
            case MODAL_TYPE.PROJECT: {
                deleteFunc = props.deleteProject;
                break;
            }
            case MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS:
            case MODAL_TYPE.BILLING_ADDRESS: {
                deleteFunc = props.deleteAddress;
                break;
            }
            case MODAL_TYPE.DOCUMENT_NAME: {
                deleteFunc = props.deleteDocumentName;
                break;
            }
            case MODAL_TYPE.CONTACT: {
                deleteFunc = props.deleteContact;
                break;
            }
            case MODAL_TYPE.EMPLOYEE: {
                deleteFunc = props.deleteEmployee;
                break;
            }
            case MODAL_TYPE.NEW_TAX_IDENTIFICATION: {
                deleteFunc = props.deleteRelationshipTaxIdentifications;
                break;
            }
            case MODAL_TYPE.NEW_TAX_IDENTIFICATION_CUSTOMER: {
                deleteFunc = props.deleteCustomerTaxIdentifications;
                break;
            }
            default: {
                deleteFunc = () => { }
            }
        }

        const modalData = {
            modalBody,
            handleSubmit: () => {
                props.getRelationshipAddress({
                    relationshipId: (props.companyInfo || {}).relationshipId
                  });
                deleteFunc({ id: payload.id, relationshipId: companyInfo.relationshipId, customerId: (props.customer || {}).customerId, callback });
                if (modalType === MODAL_TYPE.NEW_TAX_IDENTIFICATION_CUSTOMER && props.customer && props.customer.customerTaxIdentificationsList) {
                    let list = (props.customer.customerTaxIdentificationsList || []).filter(x => x.customerTaxIdentificationId !== payload.id);
                    props.updateState({ customer: { ...props.customer, customerTaxIdentificationsList: list } })
                }
                if (modalType === MODAL_TYPE.DOCUMENT_NAME && payload.id === this.props.documentNameId) {
                    const selectedObj = find((this.props.documentList || []), { txType: CONSTANTS_TRANSACTIONS_KEY[CONSTANTS_TRANSACTIONS.INQUIRY], isDefault: 1 }) || {};
                    this.props.updateState({
                        documentNameId: selectedObj.documentNameId,
                        docName: selectedObj.docName
                    });
                }
                props.hideModal();
            },
        };
        showModal(modalData);
    }

    render() {

        const { size ,permissions} = this.state;
        const rowNode = this.gridApi && this.gridApi.getDisplayedRowAtIndex(0);
       
        if (rowNode) {
            this.props.departmentName && rowNode.setDataValue("department", this.props.departmentName);
            this.props.priority && rowNode.setDataValue("priority", this.props.priority);
            this.props.prNumber && rowNode.setDataValue("prNumber", this.props.prNumber);
            this.props.projectName && rowNode.setDataValue("project", this.props.projectName);
            this.props.customerInquiryNumber && rowNode.setDataValue("customerEnquiryNumber", this.props.customerInquiryNumber);
            this.props.refNumber && rowNode.setDataValue("refNumber", this.props.refNumber);
            this.props.salesPersonName && rowNode.setDataValue("salesPerson", this.props.salesPersonName);
            this.gridApi.sizeColumnsToFit();
        }

        // const columnDefs = [
        //     {
        //         headerComponentFramework: () => {
        //             return <div>
        //                 <FormattedMessage id='customer.salesEnquiry.form.gridHeader.customerSalesEnquiryNumber' defaultMessage='' />
        //                 <Tooltip placement="top" title={this.props.intl.formatMessage(
        //                     {
        //                         id: 'tooltip.InqNumberToLocateInquiry',
        //                         defaultMessage: ''
        //                     })
        //                 } trigger="click">
        //                     <i className="fa fa-question-circle-o pl5 cursor-pointer" />
        //                 </Tooltip>
        //             </div>
        //         },
        //         field: 'customerEnquiryNumber',
        //         editable: true,
        //         cellEditor: 'customTextEditor',
        //         cellEditorParams: (obj) => {
        //             return {
        //                 onChange: (val) => {
        //                     this.props.updateState({
        //                         customerInquiryNumber: val
        //                     });
        //                 }
        //             }
        //         }
        //     },
        //     {
        //         headerComponentFramework: () => {
        //             return <div>
        //                 <FormattedMessage id='customer.salesEnquiry.form.gridHeader.refNumber' defaultMessage='' />
        //                 <Tooltip placement="top" title={this.props.intl.formatMessage(
        //                     {
        //                         id: 'tooltip.referenceNumberAssign',
        //                         defaultMessage: ''
        //                     })
        //                 } trigger="click">
        //                     <i className="fa fa-question-circle-o pl5 cursor-pointer" />
        //                 </Tooltip>
        //             </div>
        //         },
        //         field: 'refNumber',
        //         editable: true,
        //         cellEditor: 'customTextEditor',
        //         cellEditorParams: (obj) => {
        //             return {
        //                 onChange: (val) => {
        //                     this.props.updateState({
        //                         refNumber: val
        //                     });
        //                 }
        //             }
        //         }
        //     },
        //     {
        //         headerComponentFramework: () => {
        //             return <FormattedMessage id='customer.salesEnquiry.form.gridHeader.salesPerson' defaultMessage='' />;
        //         },
        //         field: 'salesPerson',
        //         editable: true,
        //         cellEditor: 'customDropDownEditor',
        //         cellEditorParams: (obj) => {
        //             return {
        //                 lastColumnCell: false,
        //                 items: this.props.employees || [],
        //                 optionKeyName: 'relationshipEmployeeId',
        //                 valueKeyName: 'fullName',
        //                 canAddNew: true,
        //                 canDelete: true,
        //                 allowClear: true,
        //                 onClear: () => {
        //                     //obj.node.setDataValue(obj.colDef.field, undefined);
        //                     this.props.updateState({
        //                         salesPersonId: undefined
        //                     })
        //                 },
        //                 deleteClickHandler: (payload) => {
        //                     this.deleteClickHandler(this.props, MODAL_TYPE.EMPLOYEE, payload);
        //                 },
        //                 onAddNewClick: (payload) => {
        //                     // payload.formData = {
        //                     //     firstName: payload.textEntered,
        //                     //     submittedOnce: false
        //                     // }
        //                     // this.handleNewEmployeeAddition(this.props, payload, MODAL_TYPE.EMPLOYEE)
        //                     this.gridApi && this.gridApi.stopEditing();
        //                     this.props.updateHeaderState({
        //                         company: {
        //                             ...this.props.company,
        //                             employeeDrawerVisible: true,
        //                         }
        //                     })
        //                 },
        //                 onSelect: (selectedValue, option) => {
        //                     //obj.node.setDataValue(obj.colDef.field, selectedValue);
        //                     this.props.updateState({
        //                         salesPersonId: option.key, salesPersonName: selectedValue
        //                     });
        //                 },
        //             }
        //         }
        //     },
        //     {
        //         headerComponentFramework: () => {
        //             return <FormattedMessage id='customer.salesEnquiry.form.gridHeader.priority' defaultMessage='' />;
        //         },
        //         field: 'priority',
        //         editable: true,
        //         cellEditor: 'customDropDownEditor',
        //         cellEditorParams: (obj) => {
        //             return {
        //                 lastColumnCell: false,
        //                 items: CONSTANTS.PRIORITIES,
        //                 allowClear: true,
        //                 onClear: () => {
        //                     obj.node.data.priority = null;
        //                     this.props.updateState({
        //                         orderPriority: null
        //                     });
        //                 },
        //                 onSelect: (selectedValue) => {
        //                     this.props.updateState({
        //                         orderPriority: selectedValue
        //                     })
        //                     //obj.node.setDataValue(obj.colDef.field, selectedValue);
        //                 },
        //             }
        //         }
        //     },
        //     {
        //         headerComponentFramework: () => {
        //             return <FormattedMessage id='customer.salesEnquiry.form.gridHeader.project' defaultMessage='' />;
        //         },
        //         field: 'project',
        //         editable: true,
        //         cellEditor: 'customDropDownEditor',
        //         cellEditorParams: (obj) => {
        //             return {
        //                 lastColumnCell: false,
        //                 items: this.props.projectList || [],
        //                 optionKeyName: 'projectMasterId',
        //                 valueKeyName: 'projectName',
        //                 projectIdKey: 'projectMasterId',
        //                 canAddNew: true,
        //                 canDelete: true,
        //                 allowClear: true,
        //                 deleteClickHandler: (payload) => {
        //                     this.deleteClickHandler(this.props, MODAL_TYPE.PROJECT, payload);
        //                 },
        //                 onAddNewClick: (payload) => {
        //                     payload.formData = {
        //                         projectName: payload.projectName,
        //                     }
        //                     this.handleNewProjectAddition(this.props, payload, MODAL_TYPE.PROJECT)
        //                 },
        //                 onSelect: (selectedValue, option) => {
        //                     const selectedProject = find(this.props.projectList, { projectMasterId: Number(option.key || 0) }) || {};
        //                     //obj.node.setDataValue(obj.colDef.field, selectedValue);
        //                     this.props.updateState({
        //                         projectNumber: selectedProject.projectNumber,
        //                         projectName: selectedProject.projectName,
        //                         projectMasterId: selectedProject.projectMasterId
        //                     })
        //                 },
        //                 onClear: () => {
        //                     obj.node.data.project = null;
        //                     this.props.updateState({
        //                         projectNumber: null,
        //                         projectName: null,
        //                         projectMasterId: null
        //                     })
        //                 },
        //             }
        //         }
        //     },
        //     // {
        //     //     headerComponentFramework: () => {
        //     //         return <FormattedMessage id='customer.salesEnquiry.form.gridHeader.requestingDepartment' defaultMessage='' />;
        //     //     },
        //     //     field: 'department',
        //     //     editable: true,
        //     //     sortable: true,
        //     //     cellEditor: 'customDropDownEditor',
        //     //     cellEditorParams: (obj) => {
        //     //         return {
        //     //             lastColumnCell: false,
        //     //             items: this.props.departments || [],
        //     //             optionKeyName: 'departmentId',
        //     //             valueKeyName: 'departmentName',
        //     //             value: this.props.departmentName || '',
        //     //             canAddNew: true,
        //     //             canDelete: true,
        //     //             deleteClickHandler: (payload) => {
        //     //                 this.deleteClickHandler(this.props, MODAL_TYPE.DEPARTMENT, payload);
        //     //             },
        //     //             onAddNewClick: (payload) => {
        //     //                 payload.formData = {
        //     //                     textBoxValue: payload.textEntered,
        //     //                     submittedOnce: false
        //     //                 }
        //     //                 this.handleNewItemAddition(this.props, payload, MODAL_TYPE.DEPARTMENT)
        //     //             },
        //     //             onSelect: (selectedValue) => {
        //     //                 //obj.node.setDataValue(obj.colDef.field, selectedValue);
        //     //                 this.props.updateState({
        //     //                     departmentName: selectedValue
        //     //                 })
        //     //             },
        //     //         }
        //     //     }
        //     // },
        //     // {
        //     //     headerComponentFramework: () => {
        //     //         return <FormattedMessage id='customer.salesEnquiry.form.gridHeader.purchaseType' defaultMessage='' />;
        //     //     },
        //     //     field: 'ref',
        //     //     valueGetter: () => { return "Purchase Order" },
        //     // },
        //     // {
        //     //     headerComponentFramework: () => {
        //     //         return <FormattedMessage id='customer.salesEnquiry.form.gridHeader.prRequestedBy' defaultMessage='' />;
        //     //     },
        //     //     field: 'prRequestedBy',
        //     //     valueGetter: () => { return this.props.prRequestedBy },
        //     // },
        // ]

        // const onGridReady = (params) => {
        //     this.gridApi = params.api;
        //     this.gridApi.sizeColumnsToFit()
        //     this.gridColumnApi = params.columnApi;
        // }

        return (
          <Fragment>
            <Row>
              <Col span={5}>
            <Skeleton loading={this.props.detailLoading} paragraph={{ rows: 5 }} active>
                <Row gutter={[0,3]}>
                  <Col span={24} className="details-heading">
                    Organization Information
                  </Col>
                  <Col span={24}>
                    <div className="details-heading-subheading">
                      <FormattedMessage
                        id="sales.order.create.organization.name"
                        defaultMessage=""
                      />
                    </div>
                    </Col>
                    <Col span={24}>
                    <div className="deatils-field-value">
                      {(this.props.companyInfo || {}).storeName}
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="details-heading-subheading">
                      <FormattedMessage
                        id="sales.order.create.organization.adress"
                        defaultMessage=""
                      />
                      <Tooltip
                        placement="right"
                        title={this.props.intl.formatMessage({
                          id: "tooltip.companyBillingAddress",
                          defaultMessage: "",
                        })}
                        trigger="click"
                      >
                        <QuestionCircleTwoTone />
                      </Tooltip>
                    </div>
                    </Col>
                    <Col span={24}>
                    <span>
                      {!this.props.addressHide &&
                      this.props.relationshipAddress ? (
                        <>
                          <div className="detail-address-value">
                            <div style={{ height: "96px" , paddingTop:'10px'}}>
                              {getAddressInfoFragment(
                                this.props.relationshipAddress,
                                this.props.companyInfo,
                                {}
                              )}
                            </div>
                            <div
                              onClick={() => {
                                
                                this.props.updateState({
                                  addressHide: true,
                                });
                              }}
                            >
                              <EditOutlined />
                            </div>
                          </div>
                        </>
                      ) : (
                        <Dropdown
                          style={{ width: "100%" }}
                          value={
                            (this.props.relationshipAddress || {})
                              .streetAddress1
                          }
                          items={this.props.allRelationshipBillingAddress || []}
                          valueKeyName="streetAddress1"
                          addressLineKey1="streetAddress2"
                          addressLineKey2="cityName"
                          addressLineKey3="stateName"
                          addressLineKey4="zipCode"
                          optionClassAddress="custom-address-dropdown"
                          isFavouriteKeyName="isDefault"
                          optionKeyName="locationId"
                          moduleName={MODULE_CODED_VALUES.SETTINGS_COMPANY_INFORMATION}
                          canAddNew={true}
                          canDelete={true}
                          deleteClickHandler={(payload) => {
                            this.deleteClickHandler(
                              this.props,
                              MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS,
                              payload
                            );
                          }}
                          onAddNewClick={(payload) => {
                            payload.formData = {
                              locationType:
                                CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
                            };
                            this.handleNewAddressAddition(
                              this.props,
                              payload,
                              MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS
                            );
                          }}
                          onSelect={(locationId) => {
                            this.props.updateState({
                              relationshipAddress:
                                this.props.allRelationshipBillingAddress.find(
                                  (x) => x.locationId === locationId
                                ),
                              addressHide: false,
                            });
                          }}
                        />
                      )}
                    </span>
                  </Col>
                  <Col span={24}>
                    <div className="details-heading-subheading">
                      <FormattedMessage
                        id="customer.form.taxIdentification.label"
                        defaultMessage=""
                      />
                      <Tooltip
                        placement="right"
                        title={this.props.intl.formatMessage({
                          id: "tooltip.taIdentification",
                          defaultMessage: "",
                        })}
                        trigger="click"
                      >
                        <QuestionCircleTwoTone />
                      </Tooltip>
                    </div>

                    <MultipleSelectDropdown
                      items={this.props.allRelationshipTaxIdentificatins || []}
                      placeholder="Tax Identification(s)"
                      mode="multiple"
                      onDeSelect={(value) => {
                        let { selectedRelationshipTaxIdentifications } =
                          this.props;
                        selectedRelationshipTaxIdentifications = filter(
                          selectedRelationshipTaxIdentifications,
                          (taxId) => {
                            return taxId !== value;
                          }
                        );
                        this.props.updateState({
                          selectedRelationshipTaxIdentifications,
                        });
                      }}
                      valueKeyName="taxIdentificationType"
                      valueKeyName2="taxIdentificationNumber"
                      selectedValue={
                        (this.props.allRelationshipTaxIdentificatins || [])
                          .length
                          ? this.props.selectedRelationshipTaxIdentifications ||
                            []
                          : []
                      }
                      optionKeyName="relationshipTaxIdentificationId"
                      onSelect={(relationshipTaxIdentificationId) => {
                        let selectedRelationshipTaxIdentifications =
                          this.props.selectedRelationshipTaxIdentifications ||
                          [];
                        if (
                          selectedRelationshipTaxIdentifications.indexOf(
                            relationshipTaxIdentificationId
                          ) === -1
                        ) {
                          selectedRelationshipTaxIdentifications.push(
                            relationshipTaxIdentificationId
                          );
                        } else {
                          selectedRelationshipTaxIdentifications =
                            selectedRelationshipTaxIdentifications.filter(
                              (el) => {
                                return (
                                  el !== selectedRelationshipTaxIdentifications
                                );
                              }
                            );
                        }
                        this.props.updateState({
                          selectedRelationshipTaxIdentifications,
                        });
                      }}
                      canAddNew={true}
                      canDelete={true}
                      deleteClickHandler={(payload) => {
                        this.deleteClickHandler(
                          this.props,
                          MODAL_TYPE.NEW_TAX_IDENTIFICATION,
                          payload
                        );
                      }}
                      onAddNewClick={(payload) => {
                        payload.formData = {
                          taxIdentificationNumber: payload.textEntered,
                          submittedOnce: false,
                        };
                        this.handleNewTaxIdentificationAddition(
                          this.props,
                          payload,
                          MODAL_TYPE.NEW_TAX_IDENTIFICATION
                        );
                      }}
                    />
                  </Col>
                  {/* <LogoComponent src={CONSTANTS.BASE_URL_S3 + '/' + config.BUCKET_NAME.BO_RELATIONSHIP + '/' + (this.props.companyInfo || {}).logoAwsObjectKey + "?" + Date.now()} /> */}
                  {/* <Avatar shape='square' size={64} icon={<UserOutlined />} src={CONSTANTS.BASE_URL_S3 + '/' + config.BUCKET_NAME.BO_RELATIONSHIP + '/' + (this.props.companyInfo || {}).logoAwsObjectKey + "?" + Date.now()} /> */}
                  {/* <Form.Item>
                                    <div className="year">
                                        {(this.props.companyInfo || {}).storeName}
                                    </div>
                                    <Dropdown
                                        style={{ width: '100%'}}
                                        value={(this.props.relationshipAddress || {}).streetAddress1}
                                        items={this.props.allRelationshipBillingAddress || []}
                                        valueKeyName='streetAddress1'
                                        addressLineKey1='streetAddress2'
                                        addressLineKey2='cityName'
                                        addressLineKey3='stateName'
                                        addressLineKey4='zipCode'
                                        optionClassAddress='custom-address-dropdown'
                                        isFavouriteKeyName='isDefault'
                                        optionKeyName='locationId'
                                        canAddNew={true}
                                        canDelete={true}
                                        deleteClickHandler={(payload) => {
                                            this.deleteClickHandler(this.props, MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS, payload);
                                        }}
                                        onAddNewClick={(payload) => {
                                            payload.formData = {
                                                locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS
                                            }
                                            this.handleNewAddressAddition(this.props, payload, MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS)
                                        }}
                                        onSelect={(locationId) => {
                                            this.props.updateState({
                                                relationshipAddress: this.props.allRelationshipBillingAddress.find(x => x.locationId === locationId)
                                            })
                                        }}
                                    />
                                    <Tooltip placement="right" title={this.props.intl.formatMessage(
                                        {
                                            id: 'tooltip.companyBillingAddress',
                                            defaultMessage: ''
                                        })
                                    } trigger="click">
                                        <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                                    </Tooltip>
                                    {
                                        getAddressInfoFragment(this.props.relationshipAddress, this.props.companyInfo, {})
                                    }
                                    <MultipleSelectDropdown
                                        style={{ width: '80%', paddingLeft: 15 }}
                                        items={this.props.allRelationshipTaxIdentificatins || []}
                                        placeholder="Tax Identification(s)"
                                        mode='multiple'
                                        onDeSelect={(value) => {
                                            let { selectedRelationshipTaxIdentifications } = this.props
                                            selectedRelationshipTaxIdentifications = filter(selectedRelationshipTaxIdentifications, (taxId) => {
                                                return taxId !== value;
                                            });
                                            this.props.updateState({ selectedRelationshipTaxIdentifications });
                                        }}
                                        valueKeyName='taxIdentificationType'
                                        valueKeyName2='taxIdentificationNumber'
                                        selectedValue={(this.props.allRelationshipTaxIdentificatins || []).length ? (this.props.selectedRelationshipTaxIdentifications || []) : []}
                                        optionKeyName='relationshipTaxIdentificationId'
                                        onSelect={(relationshipTaxIdentificationId) => {
                                            let selectedRelationshipTaxIdentifications = this.props.selectedRelationshipTaxIdentifications || [];
                                            if (selectedRelationshipTaxIdentifications.indexOf(relationshipTaxIdentificationId) === -1) {
                                                selectedRelationshipTaxIdentifications.push(relationshipTaxIdentificationId);
                                            } else {
                                                selectedRelationshipTaxIdentifications = selectedRelationshipTaxIdentifications.filter((el) => {
                                                    return el !== selectedRelationshipTaxIdentifications
                                                });
                                            }
                                            this.props.updateState({
                                                selectedRelationshipTaxIdentifications
                                            });
                                        }
                                        }
                                        canAddNew={true}
                                        canDelete={true}
                                        deleteClickHandler={(payload) => {
                                            this.deleteClickHandler(this.props, MODAL_TYPE.NEW_TAX_IDENTIFICATION, payload);
                                        }}
                                        onAddNewClick={(payload) => {
                                            payload.formData = {
                                                taxIdentificationNumber: payload.textEntered,
                                                submittedOnce: false,
                                            }
                                            this.handleNewTaxIdentificationAddition(this.props, payload, MODAL_TYPE.NEW_TAX_IDENTIFICATION)
                                        }}
                                    /><Tooltip placement="right" title={this.props.intl.formatMessage(
                                        {
                                            id: 'tooltip.taIdentification',
                                            defaultMessage: ''
                                        })
                                    } trigger="click">
                                        <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                                    </Tooltip>
                                </Form.Item> */}
                </Row>
                </Skeleton>
              </Col>
              <Col span={1}></Col>
              <Col span={5}>
            <Skeleton loading={ this.props.detailLoading} paragraph={{ rows: 5 }} active>
                <Row gutter={[0,3]}>
                  <Col span={24} className="details-heading">
                    Customer Information
                  </Col>
                  {this.props.customerName ? (
                    <>
                      <Col span={24}>
                        <div className="details-heading-subheading">
                          <FormattedMessage
                            id="customer.salesOrder.form.customer.label"
                            defaultMessage=""
                          />
                        </div>
                      </Col>
                      <Col span={24}>
                        <div className="deatils-field-value">
                          {this.props.customerName}
                        </div>
                      </Col>
                      <Col span={24}>
                        <div className="details-heading-subheading">
                          <FormattedMessage id="contact" defaultMessage="" />
                          <Tooltip
                            placement="right"
                            title={this.props.intl.formatMessage({
                              id: "tooltip.customerContact",
                              defaultMessage: "",
                            })}
                            trigger="click"
                          >
                            <QuestionCircleTwoTone />
                          </Tooltip>
                        </div>
                      </Col>
                      <Col span={24}>
                        <Dropdown
                          style={{ width: "100%" }}
                          value={
                            ((this.props.selectedContact || {}).firstName ||
                              "") +
                            " " +
                            ((this.props.selectedContact || {}).lastName || "")
                          }
                          optionKeyName="contactId"
                          items={this.props.boContactList}
                          valueKeyName="firstName"
                          lastNameKey="lastName"
                          placeholder="Please select contact name"
                          isFavouriteKeyName="isPrimaryContact"
                          moduleName={MODULE_CODED_VALUES.CUSTOMER_CONTACTS}
                          canAddNew={true}
                          canDelete={true}
                          deleteClickHandler={(payload) => {
                            this.deleteClickHandler(
                              this.props,
                              MODAL_TYPE.CONTACT,
                              payload
                            );
                          }}
                          onAddNewClick={(payload) => {
                            payload.formData = {
                              firstName: payload.textEntered,
                              submittedOnce: false,
                              customerId: (this.props.customer || {})
                                .customerId,
                              pageNumber: this.props.pageNumber || 1,
                              customerName: this.props.customerName,
                            };
                            this.handleNewContactAddition(
                              this.props,
                              payload,
                              MODAL_TYPE.CONTACT
                            );
                          }}
                          onSelect={(contactId) => {
                            let contact =
                              find(this.props.boContactList || [], {
                                contactId: +contactId,
                              }) || {};
                            this.props.updateState({
                              selectedContact: contact,
                            });
                          }}
                          allowClear={true}
                          onClear={() => {
                            this.props.updateState({
                              selectedContact: null,
                            });
                          }}
                        />
                      </Col>
                      <Col   span={24}>
                        <div className='details-heading-subheading'>

                        <FormattedMessage id='customer.form.taxIdentification.label' defaultMessage='' />
                        <Tooltip placement="right" title={this.props.intl.formatMessage(
                          {
                            id: 'taxIdentification.tooltip',
                            defaultMessage: ''
                          }
                        )} trigger="click">
                          <QuestionCircleTwoTone />
                        </Tooltip>
                        </div>
                      </Col>
                      <Col  span={24}>
                      <MultipleSelectDropdown
                              style={{ width: "100%" }}
                              items={
                                this.props.customer
                                  .customerTaxIdentificationsList || []
                              }
                              placeholder="Tax Identification(s)"
                              mode="multiple"
                              onDeSelect={(value) => {
                                let { selectedCustomerTaxIdentifications } =
                                  this.props;
                                selectedCustomerTaxIdentifications = filter(
                                  selectedCustomerTaxIdentifications,
                                  (taxId) => {
                                    return taxId !== value;
                                  }
                                );
                                this.props.updateState({
                                  selectedCustomerTaxIdentifications:
                                    selectedCustomerTaxIdentifications,
                                });
                              }}
                              valueKeyName="taxIdentificationType"
                              selectedValue={
                                (this.props.selectedCustomerTaxIdentifications || []).length
                                  ? this.props.selectedCustomerTaxIdentifications
                                  : []
                              }
                              optionKeyName="customerTaxIdentificationId"
                              valueKeyName2="taxIdentificationNumber"
                              onSelect={(customerTaxIdentificationId) => {
                                let selectedCustomerTaxIdentifications =
                                  this.props
                                    .selectedCustomerTaxIdentifications || [];
                                if (
                                  selectedCustomerTaxIdentifications.indexOf(
                                    customerTaxIdentificationId
                                  ) === -1
                                ) {
                                  selectedCustomerTaxIdentifications.push(
                                    customerTaxIdentificationId
                                  );
                                } else {
                                  selectedCustomerTaxIdentifications =
                                    selectedCustomerTaxIdentifications.filter(
                                      (el) => {
                                        return (
                                          el !== customerTaxIdentificationId
                                        );
                                      }
                                    );
                                }
                                this.props.updateState({
                                  selectedCustomerTaxIdentifications,
                                });
                              }}
                              canAddNew={true}
                              canDelete={true}
                              deleteClickHandler={(payload) => {
                                this.deleteClickHandler(
                                  this.props,
                                  MODAL_TYPE.NEW_TAX_IDENTIFICATION_CUSTOMER,
                                  payload
                                );
                              }}
                              onAddNewClick={(payload) => {
                                payload.formData = {
                                  firstName: payload.textEntered,
                                  submittedOnce: false,
                                  customerId: (this.props.customer || {})
                                    .customerId,
                                  pageNumber: this.props.pageNumber || 1,
                                  customerName: this.props.customerName,
                                };
                                this.handleNewTaxIdentificationAddition(
                                  this.props,
                                  payload,
                                  MODAL_TYPE.NEW_TAX_IDENTIFICATION
                                );
                              }}
                            />
                      </Col>

                      {/* <div className="mt10">
                        <Row>
                          <Col span={5} className="text-right">
                            <div className="title">
                              <FormattedMessage
                                id="customer.salesEnquiry.form.contact.label"
                                defaultMessage=""
                              />
                            </div>
                          </Col>
                          <Col span={19}>
                            <Dropdown
                              style={{ width: "100%" }}
                              value={
                                ((this.props.selectedContact || {}).firstName ||
                                  "") +
                                " " +
                                ((this.props.selectedContact || {}).lastName ||
                                  "")
                              }
                              optionKeyName="contactId"
                              items={this.props.boContactList}
                              valueKeyName="firstName"
                              lastNameKey="lastName"
                              placeholder="Please select contact name"
                              isFavouriteKeyName="isPrimaryContact"
                              canAddNew={true}
                              canDelete={true}
                              deleteClickHandler={(payload) => {
                                this.deleteClickHandler(
                                  this.props,
                                  MODAL_TYPE.CONTACT,
                                  payload
                                );
                              }}
                              onAddNewClick={(payload) => {
                                payload.formData = {
                                  firstName: payload.textEntered,
                                  submittedOnce: false,
                                  customerId: (this.props.customer || {})
                                    .customerId,
                                  pageNumber: this.props.pageNumber || 1,
                                  customerName: this.props.customerName,
                                };
                                this.handleNewContactAddition(
                                  this.props,
                                  payload,
                                  MODAL_TYPE.CONTACT
                                );
                              }}
                              onSelect={(contactId) => {
                                let contact =
                                  find(this.props.boContactList || [], {
                                    contactId: +contactId,
                                  }) || {};
                                this.props.updateState({
                                  selectedContact: contact,
                                });
                              }}
                              allowClear={true}
                              onClear={() => {
                                this.props.updateState({
                                  selectedContact: null,
                                });
                              }}
                            />
                            <Tooltip
                              placement="right"
                              title={this.props.intl.formatMessage({
                                id: "tooltip.customerContact",
                                defaultMessage: "",
                              })}
                              trigger="click"
                            >
                              <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                            </Tooltip>
                          </Col>
                        </Row>
                      </div>
                      <div className="inner">
                        <Row>
                          <Col span={5} className="text-right">
                            <div className="title">
                              <FormattedMessage
                                id="customer.salesEnquiry.form.billTo.label"
                                defaultMessage=""
                              />
                            </div>
                          </Col>
                          <Col span={19} style={{ "text-align": "left" }}>
                            <Dropdown
                              style={{ width: "70%" }}
                              value={
                                (this.props.customerBillingAddress || {})
                                  .streetAddress1
                              }
                              items={this.props.allBillingAddress}
                              valueKeyName="streetAddress1"
                              addressLineKey1="streetAddress2"
                              addressLineKey2="cityName"
                              addressLineKey3="stateName"
                              addressLineKey4="zipCode"
                              optionClassAddress="custom-address-dropdown"
                              optionKeyName="locationId"
                              isFavouriteKeyName="isDefault"
                              canAddNew={true}
                              canDelete={true}
                              deleteClickHandler={(payload) => {
                                this.deleteClickHandler(
                                  this.props,
                                  MODAL_TYPE.BILLING_ADDRESS,
                                  payload
                                );
                              }}
                              onAddNewClick={(payload) => {
                                payload.formData = {
                                  locationType: CONSTANTS.BILLING_ADDRESS,
                                  customerId: (this.props.customer || {})
                                    .customerId,
                                  pageNumber: this.props.pageNumber,
                                  customerName: this.props.customerName,
                                };
                                this.handleNewAddressAddition(
                                  this.props,
                                  payload,
                                  MODAL_TYPE.BILLING_ADDRESS
                                );
                              }}
                              onSelect={(locationId) => {
                                this.props.updateState({
                                  customerBillingAddress:
                                    find(this.props.allBillingAddress, {
                                      locationId: +locationId,
                                    }) || {},
                                });
                              }}
                              allowClear={true}
                              onClear={() => {
                                this.props.updateState({
                                  customerBillingAddress: null,
                                });
                              }}
                            />
                          </Col>
                        </Row>
                      </div>
                      <div className="inner">
                        <Row>
                          <Col span={5} className="text-right">
                            <FormattedMessage
                              id="customer.form.taxIdentification.label"
                              defaultMessage=""
                            />
                          </Col>
                          <Col span={19} style={{ "text-align": "left" }}>
                            <MultipleSelectDropdown
                              style={{ width: "70%" }}
                              items={
                                this.props.customer
                                  .customerTaxIdentificationsList || []
                              }
                              placeholder="Tax Identification(s)"
                              mode="multiple"
                              onDeSelect={(value) => {
                                let { selectedCustomerTaxIdentifications } =
                                  this.props;
                                selectedCustomerTaxIdentifications = filter(
                                  selectedCustomerTaxIdentifications,
                                  (taxId) => {
                                    return taxId !== value;
                                  }
                                );
                                this.props.updateState({
                                  selectedCustomerTaxIdentifications:
                                    selectedCustomerTaxIdentifications,
                                });
                              }}
                              valueKeyName="taxIdentificationType"
                              selectedValue={
                                (
                                  this.props.customer
                                    .customerTaxIdentificationsList || []
                                ).length
                                  ? this.props
                                      .selectedCustomerTaxIdentifications || []
                                  : []
                              }
                              optionKeyName="customerTaxIdentificationId"
                              valueKeyName2="taxIdentificationNumber"
                              onSelect={(customerTaxIdentificationId) => {
                                let selectedCustomerTaxIdentifications =
                                  this.props
                                    .selectedCustomerTaxIdentifications || [];
                                if (
                                  selectedCustomerTaxIdentifications.indexOf(
                                    customerTaxIdentificationId
                                  ) === -1
                                ) {
                                  selectedCustomerTaxIdentifications.push(
                                    customerTaxIdentificationId
                                  );
                                } else {
                                  selectedCustomerTaxIdentifications =
                                    selectedCustomerTaxIdentifications.filter(
                                      (el) => {
                                        return (
                                          el !== customerTaxIdentificationId
                                        );
                                      }
                                    );
                                }
                                this.props.updateState({
                                  selectedCustomerTaxIdentifications,
                                });
                              }}
                              canAddNew={true}
                              canDelete={true}
                              deleteClickHandler={(payload) => {
                                this.deleteClickHandler(
                                  this.props,
                                  MODAL_TYPE.NEW_TAX_IDENTIFICATION_CUSTOMER,
                                  payload
                                );
                              }}
                              onAddNewClick={(payload) => {
                                payload.formData = {
                                  firstName: payload.textEntered,
                                  submittedOnce: false,
                                  customerId: (this.props.customer || {})
                                    .customerId,
                                  pageNumber: this.props.pageNumber || 1,
                                  customerName: this.props.customerName,
                                };
                                this.handleNewTaxIdentificationAddition(
                                  this.props,
                                  payload,
                                  MODAL_TYPE.NEW_TAX_IDENTIFICATION
                                );
                              }}
                            />
                            <Tooltip
                              placement="right"
                              title={this.props.intl.formatMessage({
                                id: "taxIdentification.tooltip",
                                defaultMessage: "",
                              })}
                              trigger="click"
                            >
                              <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                            </Tooltip>
                          </Col>
                        </Row>
                      </div> */}
                    </>
                  ) : (
                    "Please select customer for details"
                  )}
                </Row>
                </Skeleton>
              </Col>
              <Col span={7}></Col>
              <Col span={6} className='order-value-info'>
            <Skeleton loading={this.props.detailLoading} paragraph={{ rows: 5 }} active>

        <Row gutter={[0,3]}>
              <Col  span={24} className='details-heading'>
                Inquiry Information
              </Col>
              {
                this.props.prNumber && <>
                  <Col  span={24}>
                    <FormattedMessage id='customer.salesOrder.form.quotationNumber.label' defaultMessage='' />
                  </Col>
                  <Col  span={24}>
                  {this.props.prNumber}
                  </Col>
                </>
              }

              <Col  span={24} >
                <FormattedMessage id='common.dateCreated' defaultMessage='' />
              </Col>
              <Col   span={24}>
              <DatePicker
                            allowClear={false}
                            format={format}
                            key={`${
                              this.props.enquiryDate
                                ? getMomentDateForUI({
                                    date: new Date(this.props.enquiryDate),
                                    format,
                                  })
                                : getMomentDateForUI({
                                    date: new Date(),
                                    format,
                                  })
                            }`}
                            defaultValue={
                              this.props.enquiryDate
                                ? getMomentDateForUI({
                                    date: new Date(this.props.enquiryDate),
                                    format,
                                  })
                                : getMomentDateForUI({
                                    date: new Date(),
                                    format,
                                  })
                            }
                            onChange={(selectedDate) => {
                              this.props.updateState({
                                enquiryDate: selectedDate,
                              });
                              //  this.props.getNextTxNumber({ enquiryDate: selectedDate, relationshipId: (this.props.companyInfo || {}).relationshipId });
                            }}
                            size={size}
                          />
              </Col>
              <Col  span={24} >
                <FormattedMessage id='customer.salesOrder.form.expirationDate.label' defaultMessage='' />
              </Col>
              <Col  span={24}>
              <DatePicker
                            allowClear={false}
                            format={format}
                            key={`${
                              this.props.expiryDate
                                ? getMomentDateForUI({
                                    date: new Date(this.props.expiryDate),
                                    format,
                                  })
                                : getMomentDateForUI({
                                    date: new Date(),
                                    format,
                                  })
                            }`}
                            defaultValue={
                              this.props.expiryDate
                                ? getMomentDateForUI({
                                    date: new Date(this.props.expiryDate),
                                    format,
                                  })
                                : getMomentDateForUI({
                                    date: new Date(),
                                    format,
                                  })
                            }
                            onChange={(selectedDate) => {
                              this.props.updateState({
                                expiryDate: selectedDate,
                              });
                            }}
                            size={size}
                          />
              </Col>
              <Col  span={24} >
                <FormattedMessage id='customer.salesOrder.form.documentDropdown.label' defaultMessage='' />
                <Tooltip placement="left" title={this.props.intl.formatMessage(
                  {
                    id: 'tooltip.documentAppearInPdf',
                    defaultMessage: ''
                  }
                )}>
                  <QuestionCircleTwoTone />
                </Tooltip>
              </Col>
              <Col  span={24}>
              <Dropdown
                            style={{ width: "100%" }}
                            items={filter(
                              this.props.documentList || [],
                              (document) => {
                                return (
                                  document.txType ===
                                  CONSTANTS_TRANSACTIONS_KEY[
                                    CONSTANTS_TRANSACTIONS.INQUIRY
                                  ]
                                );
                              }
                            )}
                            placeholder="Document name"
                            value={
                              this.props.docName || this.props.documentNameId
                            }
                            canAddNew={true}
                            canDelete={true}
                            deleteClickHandler={(payload) => {
                              this.deleteClickHandler(
                                this.props,
                                MODAL_TYPE.DOCUMENT_NAME,
                                payload
                              );
                            }}
                            onAddNewClick={(payload) => {
                              payload.formData = {
                                textBoxValue: payload.textEntered,
                                submittedOnce: false,
                                maxLength: 30,
                              };
                              this.handleNewItemAddition(
                                this.props,
                                payload,
                                MODAL_TYPE.DOCUMENT_NAME
                              );
                            }}
                            onSelect={(selectedValue) => {
                              const selectedObj = find(
                                this.props.documentList || [],
                                { documentNameId: Number(selectedValue) }
                              );
                              this.props.updateState({
                                documentNameId: selectedValue,
                                docName: selectedObj.docName,
                              });
                            }}
                            valueKeyName="docName"
                            optionKeyName="documentNameId"
                            isLockKeyName="isSystemGenerated"
                          />
              </Col>
            </Row>

            </Skeleton>
          </Col>
            {/*  <Col span={9}>
                <div className="right-col detail-column">
                  <Form.Item>
                    <div className="project-dropdown">
                      <Row>
                         <Col span={11} className='detail-column'>
                                                <Tooltip placement="bottom" title={this.props.intl.formatMessage(
                                                    {
                                                        id: 'tooltip.generateSalesInquiryNum',
                                                        defaultMessage: ''
                                                    })
                                                }>
                                                    <span className="tooltip-title">
                                                        <FormattedMessage id='customer.salesEnquiry.form.salesEnquiryNumber.label' defaultMessage='' />
                                                    </span>
                                                </Tooltip>
                                            </Col>
                                            <Col span={13} style={{ 'text-align': 'left' }}>
                                                {this.props.salesEnquiryNumber ?
                                                    // <span>{this.props.salesEnquiryNumber}</span>
                                                    <span>{getFormatedNextTxNumber(this.props)}</span>
                                                    :
                                                    <img src={LoadingIcon} alt="Invoice" style={{ 'height': '74px', 'margin-top': '-25px', 'margin-bottom': '-25px' }} />
                                                }
                                            </Col>
                      </Row>
                    </div>
                    {this.props.prNumber && (
                      <div className="project-dropdown">
                        <Row>
                          <Col span={11} className="detail-column">
                            <div className="title">
                              <FormattedMessage
                                id="customer.salesEnquiry.form.prNumber.label"
                                defaultMessage=""
                              />
                            </div>
                          </Col>
                          <Col span={13} style={{ "text-align": "left" }}>
                            <div className="value">{this.props.prNumber}</div>
                          </Col>
                        </Row>
                      </div>
                    )}
                    <div className="date-picker-container">
                      <Row>
                        <Col span={11} className="detail-column">
                          <FormattedMessage
                            id="modal.txDetails.inquiry.inquiryDate"
                            defaultMessage=""
                          />
                        </Col>
                        <Col span={13}>
                          <DatePicker
                            allowClear={false}
                            format={format}
                            key={`${
                              this.props.enquiryDate
                                ? getMomentDateForUI({
                                    date: new Date(this.props.enquiryDate),
                                    format,
                                  })
                                : getMomentDateForUI({
                                    date: new Date(),
                                    format,
                                  })
                            }`}
                            defaultValue={
                              this.props.enquiryDate
                                ? getMomentDateForUI({
                                    date: new Date(this.props.enquiryDate),
                                    format,
                                  })
                                : getMomentDateForUI({
                                    date: new Date(),
                                    format,
                                  })
                            }
                            onChange={(selectedDate) => {
                              this.props.updateState({
                                enquiryDate: selectedDate,
                              });
                              //  this.props.getNextTxNumber({ enquiryDate: selectedDate, relationshipId: (this.props.companyInfo || {}).relationshipId });
                            }}
                            size={size}
                          />
                        </Col>
                      </Row>
                    </div>

                    <div className="date-picker-container">
                      <Row>
                        <Col span={11} className="detail-column">
                          <FormattedMessage
                            id="modal.txDetails.inquiry.inquiryClosingDate"
                            defaultMessage=""
                          />
                        </Col>
                        <Col span={13}>
                          <DatePicker
                            allowClear={false}
                            format={format}
                            key={`${
                              this.props.expiryDate
                                ? getMomentDateForUI({
                                    date: new Date(this.props.expiryDate),
                                    format,
                                  })
                                : getMomentDateForUI({
                                    date: new Date(),
                                    format,
                                  })
                            }`}
                            defaultValue={
                              this.props.expiryDate
                                ? getMomentDateForUI({
                                    date: new Date(this.props.expiryDate),
                                    format,
                                  })
                                : getMomentDateForUI({
                                    date: new Date(),
                                    format,
                                  })
                            }
                            onChange={(selectedDate) => {
                              this.props.updateState({
                                expiryDate: selectedDate,
                              });
                            }}
                            size={size}
                          />
                        </Col>
                      </Row>
                    </div>

                    <div className="project-dropdown">
                      <Row>
                        <Col span={11} className="text-right">
                          <Tooltip
                            placement="bottom"
                            title={this.props.intl.formatMessage({
                              id: "tooltip.documentAppearInPdf",
                              defaultMessage: "",
                            })}
                          >
                            <span className="tooltip-title">
                              <FormattedMessage
                                id="customer.salesOrder.form.documentDropdown.label"
                                defaultMessage=""
                              />
                            </span>
                          </Tooltip>
                        </Col>
                        <Col span={13} className="documentTitleDropdown">
                          <Dropdown
                            style={{ width: "100%" }}
                            items={filter(
                              this.props.documentList || [],
                              (document) => {
                                return (
                                  document.txType ===
                                  CONSTANTS_TRANSACTIONS_KEY[
                                    CONSTANTS_TRANSACTIONS.INQUIRY
                                  ]
                                );
                              }
                            )}
                            placeholder="Document name"
                            value={
                              this.props.docName || this.props.documentNameId
                            }
                            canAddNew={true}
                            canDelete={true}
                            deleteClickHandler={(payload) => {
                              this.deleteClickHandler(
                                this.props,
                                MODAL_TYPE.DOCUMENT_NAME,
                                payload
                              );
                            }}
                            onAddNewClick={(payload) => {
                              payload.formData = {
                                textBoxValue: payload.textEntered,
                                submittedOnce: false,
                                maxLength: 30,
                              };
                              this.handleNewItemAddition(
                                this.props,
                                payload,
                                MODAL_TYPE.DOCUMENT_NAME
                              );
                            }}
                            onSelect={(selectedValue) => {
                              const selectedObj = find(
                                this.props.documentList || [],
                                { documentNameId: Number(selectedValue) }
                              );
                              this.props.updateState({
                                documentNameId: selectedValue,
                                docName: selectedObj.docName,
                              });
                            }}
                            valueKeyName="docName"
                            optionKeyName="documentNameId"
                            isLockKeyName="isSystemGenerated"
                          />
                        </Col>
                      </Row>
                    </div>
                  </Form.Item>
                </div>
              </Col>  */}
            </Row>

            {/* <Row>
              <CustomAgGrid
                columnDefs={columnDefs}
                onGridReady={onGridReady}
                disableFocusOnClickOutside={true}
                rowData={[{}]}
                gridStyle={{
                  width: "100%",
                  height: "100px",
                }}
              />
            </Row> */}
             <Row>
             <Skeleton loading={this.props.detailLoading} paragraph={{ rows: 2 }} active>

          <Col span={24}>
            <div className='txn-table'>
              <table>
                <thead>
                  <tr>
                    {/* <th><FormattedMessage id='customer.salesOrder.form.gridHeader.placeOfSupply' defaultMessage='' /></th> */}
                    <th ><FormattedMessage id='customer.salesEnquiry.form.gridHeader.customerSalesEnquiryNumber' defaultMessage='' /></th>
                    <th ><FormattedMessage id='customer.salesEnquiry.form.gridHeader.refNumber' defaultMessage='' /></th>
                    <th ><FormattedMessage id='customer.salesEnquiry.form.gridHeader.salesPerson' defaultMessage='' /></th>
                    <th ><FormattedMessage id='customer.salesEnquiry.form.gridHeader.priority' defaultMessage='' /></th>
                    <th ><FormattedMessage id='customer.salesEnquiry.form.gridHeader.project' defaultMessage='' /></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <TextBox
                        value={this.props.customerInquiryNumber}
                          onChange= {(value) => {
                            this.props.updateState({ customerInquiryNumber: value.target.value});
                          }
                        }
                      />
                    </td>
                    <td>
                      <TextBox
                        value={this.props.refNumber}
                        onChange= {(value) => {
                          this.props.updateState({  refNumber: value.target.value });
                        }}
                      />
                    </td>
                    <td>
                    <Dropdown
                  items={(this.props.employees || []).sort((a, b) => a.fullName?.localeCompare(b.fullName))}
                  optionKeyName={'relationshipEmployeeId'}
                  defaultValue={(this.props.employees || []).length ? (this.props.salesPersonName || this.props.salesPersonId || null) : ""}
                  valueKeyName={'fullName'}
                moduleName={MODULE_CODED_VALUES.HRMS_EMPLOYEE}
                  canAddNew={true}
                  canDelete={true}
                  deleteClickHandler={(payload) => {
                    this.deleteClickHandler(this.props, MODAL_TYPE.EMPLOYEE, payload);
                  }}
                  onAddNewClick={(payload) => {
                    this.props.updateHeaderState({
                      company: {
                        ...this.props.company,
                        employeeDrawerVisible: true,
                      }
                    })
                  }}
                  onSelect={(selectedValue, option) => {
                    this.props.updateState({
                      salesPersonId: option.key, salesPersonName: option.name
                    });
                  }}
                  allowClear={true}
                  onClear={() => {
                    this.props.updateState({
                      salesPersonId: undefined,
                      salesPersonName: undefined
                    });
                  }
                  }/>
                    </td>
                    <td>
                    <Dropdown
                  items={CONSTANTS.PRIORITIES}
                  value={this.props.priority}
                  onSelect={(selectedValue, option) => {
                    this.props.updateState({
                      priority: selectedValue
                    });
                  }}
                  allowClear={true}
                  canAddNew={true}
                  onAddNewClick={(payload) => {
                    payload.formData = {
                      projectName: payload.projectName,
                    }
                    this.handleNewPriorityAddition(this.props, payload, MODAL_TYPE.ACTIVITY_PRIORITY)
                  }}
                  onClear={() => {
                    this.props.updateState({
                      priority: undefined
                    });
                  }
                  }>
                  
                </Dropdown>
                    </td>
                    <td>
                    <Dropdown
                  items={this.props.projectList}
                  valueKeyName='projectName'
                  value={this.props.projectName}
                  optionKeyName='projectMasterId'
                  projectIdKey='projectMasterId'
                  moduleName={MODULE_CODED_VALUES.PROJECT}
                  canAddNew={true}
                  canDelete={true}
                  deleteClickHandler={(payload) => {
                    this.deleteClickHandler(this.props, MODAL_TYPE.PROJECT, payload);
                  }}
                  onAddNewClick={(payload) => {
                    payload.formData = {
                      projectName: payload.projectName,
                    }
                    this.handleNewProjectAddition(this.props, payload, MODAL_TYPE.PROJECT)
                  }}
                  onSelect={(projectMasterId, option) => {
                    const selectedProject = find(this.props.projectList, { projectMasterId: Number(option.key) }) || {};
                    this.props.updateState({
                      projectMasterId: selectedProject.projectMasterId,
                      projectName: selectedProject.projectName,
                      projectNumber: selectedProject.projectNumber
                    });
                  }}
                  allowClear={true}
                  onClear={() => {
                    this.props.updateState({
                      projectMasterId: undefined,
                      projectName: undefined,
                      projectNumber: undefined
                    })
                  }}
                />
                    </td>
                    

                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
          </Skeleton>
        </Row>
          </Fragment>
        );
    }
}

const getAddressInfoFragment = (obj, companyObj, customerContactObj) => {
    // if (!obj || !Object.keys(obj).length) {
    //     return '';
    // }

    // let contactName = "";
    // if (customerContactObj.salutationName) contactName += customerContactObj.salutationName + ' ';
    // if (customerContactObj.firstName) contactName += customerContactObj.firstName;
    // if (customerContactObj.middleName) contactName += ' ' + customerContactObj.middleName;
    // if (customerContactObj.lastName) contactName += ' ' + customerContactObj.lastName;

    const fragmentObj = <Fragment>
      <div className="billing-address first-caps">
        {obj.streetAddress1 || obj.streetAddress2 ? (
          <div>
            {obj.streetAddress1} {obj.streetAddress2}{", "}
          </div>
        ) : ""}
        {obj.cityName || obj.stateName || obj.zipCode ? (
          <div>
            {obj.cityName} {obj.stateName}{obj.stateName && obj.zipCode ? ", " : ""}{obj.zipCode}
          </div>
        ) : ""}
        {obj.countryName ? (
          <div>{obj.countryName}</div>
        ) : ""}
      </div>
        {/* {
            customerContactObj.firstName
                ? <div className='billing-address'>
                    {contactName}
                </div> :
                ''
        } */}
        {
            customerContactObj.workPhone
                ? <div className='billing-address'>
                    <FormattedMessage id='customer.salesEnquiry.form.phone.label' defaultMessage='' /> - {customerContactObj.workPhone}
                </div> :
                ''
        }
        {
            customerContactObj.email
                ? <div className='billing-address'>
                    <FormattedMessage id='customer.salesEnquiry.form.email.label' defaultMessage='' /> - {customerContactObj.email}
                </div> :
                ''
        }
        {
            companyObj.gstNumber
                ? <div className='billing-address'>
                    <FormattedMessage id='customer.salesEnquiry.form.gstnIn.label' defaultMessage='' /> - {companyObj.gstNumber}
                </div> :
                ''
        }
        {
            companyObj.panNumber
                ? <div className='billing-address'>
                    <FormattedMessage id='customer.salesEnquiry.form.pan.label' defaultMessage='' /> - {companyObj.panNumber}
                </div> :
                ''
        }
    </Fragment>

    return fragmentObj;
}
// const getFormatedNextTxNumber = (props) => {
//     // const { customer } = props;
//     // if (!customer || !customer.customerId) {
//     //   return <span>{props.salesEnquiryNumber}</span>;
//     // }
//     // return <span>{props.salesEnquiryNumber.replace('INQ-00', 'INQ-' + (customer.customerId < 10 ? ('0' + customer.customerId) : customer.customerId))}</span>
//     return <span>{props.salesEnquiryNumber}</span>  
// }
export default injectIntl(SalesEnquiryDetail);
