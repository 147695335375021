import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { CUSTOMER_PAYMENT_ACTION_LIST, COMMON_ACTIONS } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const fetchPaymentDetail = (payload) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_DETAIL_LOADER });
      return axios
          .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customerPayment/getPaymentByPaymentId?customerPaymentId=${payload.customerPaymentId}&relationshipId=${payload.relationshipId}&customerId=${payload.customerId}`)
          .then(res => {
            const { data } = res
            dispatch({ type: CUSTOMER_PAYMENT_ACTION_LIST.CUSTOMER_PAYMENT_DETAIL, data });
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
          .catch((err) => {
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            if(!err.__isRetryRequest){
              showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
            }else{
              err.actionToCall = CUSTOMER_PAYMENT_ACTION_LIST.CUSTOMER_PAYMENT_DETAIL;
            }
          }).finally(() => {
            dispatch({
              type: COMMON_ACTIONS.HIDE_DETAIL_LOADER,
            });
          })
      }
  }