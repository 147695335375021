import axios from 'axios';
import config from '../../../config/environmentConfig';
import { TAXES_SETTINGS_ACTION_LIST } from '../../../static/constants';
import { showToasterMessage } from '../../../utils';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const getMarketplcaeCountries = (payload) => {
    return dispatch => {
        return axios
            .get(`${config.USERS_BASE_URL}${config.API_VERSION}/${config.rootContextUsers}/marketplaceCountry/getAllMarketplaceCountries?relationshipId=${payload.relationshipId}`)
            .then(res => {

                if(res.data.status){

                    dispatch({ type: TAXES_SETTINGS_ACTION_LIST.MARKETPLACE_COUNTRIES_LIST, data: res.data });
                }
                else{
          
                    showToasterMessage({
                      description: lang[(res || {}).message] || "Some Error Occured",
                      messageType: "error",
                    })
                   }
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            })
            .catch(err => {
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            })
    }
}

export const saveUpdateMarketplaceTaxCountry = (payload, props) => {

    return dispatch => {
        // dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios
            .post(`${config.USERS_BASE_URL}${config.API_VERSION}/${config.rootContextUsers}/marketplaceCountry/saveOrUpdate`, payload)
            .then(res => {

                props.getMarketplcaeCountries(payload);
                showToasterMessage({
                    description: (res || {}).message || 'Created successfully.',
                    messageType: 'success'
                  })
            })
            .catch((err = {}) => {
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
                // if (err.__isRetryRequest) {
                //   err.actionToCall = SETTING_ACTION_LIST.DELETE_COMPANY_USERS;
                // } else {
                //   showToasterMessage({
                //     description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
                //   })
                // }
            })
    }
}

export const updateTaxIncludeInPrice = (payload, props) => {

    return dispatch => {
        // dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios
            .post(`${config.USERS_BASE_URL}${config.API_VERSION}/${config.rootContextUsers}/marketplaceCountry/updateIsTaxIncludedInPrice`, payload)
            .then(res => {
                //dispatch({ type: SETTING_ACTION_LIST.DELETE_COMPANY_USERS, data: { id: payload.id } })
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
                // showToasterMessage({
                //   description: (res || {}).message || 'User access revoked successfully',
                //   messageType: 'success'
                // })
                props.getMarketplcaeCountries(payload);
            })
            .catch((err = {}) => {
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
                // if (err.__isRetryRequest) {
                //   err.actionToCall = SETTING_ACTION_LIST.DELETE_COMPANY_USERS;
                // } else {
                //   showToasterMessage({
                //     description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
                //   })
                // }
            })
    }
}

export const deleteMarketplaceCountry = (payload, props) => {

    return dispatch => {
        // dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios
            .post(`${config.USERS_BASE_URL}${config.API_VERSION}/${config.rootContextUsers}/marketplaceCountry/delete`, payload)
            .then(res => {
                props.getMarketplcaeCountries(props);
            })
            .catch((err = {}) => {

            })
    }
}


export const getAllCountries = (payload) => {
    return dispatch => {
        return axios
            .get(`${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/country/getCountries`)
            .then(res => {
                dispatch({ type: TAXES_SETTINGS_ACTION_LIST.COUNTRIES_LIST, data: res.data });
            })
            .catch(err => {
                showToasterMessage({
                    description: lang[(err || {}).message] || "Some Error Occured",
                    messageType: "error",
                })
            })
    }
}
