import axios from 'axios';
import config from '../../../../../../config/environmentConfig';
import { getFinancialYear, showToasterMessage } from '../../../../../../utils';
import { LEDGER_ACTION_LIST, COMMON_ACTIONS } from '../../../../../../static/constants'
const { lang } = require('../../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const fetchLedgerDetails = (payload) => {
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios
            .get(`${config.ACCOUNTING_BASE_URL}${config.API_VERSION}/${config.rootContextAccounting}/chartOfAccountDetails/getAllTxByLedgerAccountId?relationshipId=${payload.relationshipId}&ledgerAccountId=${payload.ledgerAccountId}&pageSize=${payload.pageSize}&pageNumber=${(payload.pageNumber || 1)-1}&startDate=${payload.startDate || ''}&endDate=${payload.endDate || ''}`)
            .then(res => {
                const data = {
                    pageNumber: payload.pageNumber,
                    list: res.data
                  }
                dispatch({ type: LEDGER_ACTION_LIST.LEDGER_DETAIL_LIST, data });
            })
            .catch((err) => {
                if (!err.__isRetryRequest) {
                    // showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
                } else {
                    err.actionToCall = LEDGER_ACTION_LIST.LEDGER_DETAIL_LIST;
                }
            })
    }
}

export const fetchLedgerDetailsCount = (payload) => {
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios
            .get(`${config.ACCOUNTING_BASE_URL}${config.API_VERSION}/${config.rootContextAccounting}/chartOfAccountDetails/countAllTxByLedgerAccountId?relationshipId=${payload.relationshipId}&ledgerAccountId=${payload.ledgerAccountId}`)
            .then(res => {
                dispatch({ type: LEDGER_ACTION_LIST.LEDGER_DETAIL_LIST_COUNT, data: res.data });
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            })
            .catch((err) => {
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
                if (!err.__isRetryRequest) {
                    //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
                } else {
                    err.actionToCall = LEDGER_ACTION_LIST.LEDGER_DETAIL_LIST_COUNT;
                }
            })
    }
}

export const resetPaginatedData = () => {
    return dispatch => {
      dispatch({ type: LEDGER_ACTION_LIST.LEDGER_DETAIL_LIST_RESET });
    }
  }


  export const fetchLedgerDetailsByAccountId = (payload,props) => {
  let FY = getFinancialYear(props?.companyInfo);
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios
            .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/daybook/getAllTxByLedgerAccountId?relationshipId=${payload.relationshipId}&ledgerAccountId=${payload.ledgerAccountId}&pageSize=${payload.pageSize}&pageNumber=${(payload.pageNumber || 1)-1}&startDate=${payload.startDate || FY.fyStartDate}&endDate=${payload.endDate || FY.fyStartDate}`)          
            .then(res => {
                const data = {
                    pageNumber: payload.pageNumber,
                    list: res.data
                  }
                dispatch({ type: LEDGER_ACTION_LIST.LEDGER_DETAILS, data });
            })
            .catch((err) => {
                if (!err.__isRetryRequest) {
                    showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
                } else {
                    err.actionToCall = LEDGER_ACTION_LIST.LEDGER_DETAILS;
                }
            })
    }
}