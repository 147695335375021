import React from 'react';
import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS, PRODUCT_ACTION_LIST } from "../../../static/constants";
import { showToasterMessage } from '../../../utils';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const fetchReviewList= (payload) => {
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
      return axios
        .get(`${config.ECOM_BASE_URL}${config.API_VERSION}/${config.rootContextECOM}/ecomFeedback/getByRelationshipId?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1) - 1}`)
        .then(response => {
          const data = {
            pageNo: payload.pageNumber,
            list: response.data
          }
          dispatch({ type: PRODUCT_ACTION_LIST.RATING_REVIEW_LIST, data  });
         
        })
        .catch((err) => {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
         
        })
        .finally(()=>{
            dispatch({
                type: COMMON_ACTIONS.HIDE_LIST_LOADER,
              });
        }
  
        )
    }
  }
  export const countReviewList= (payload) => {
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
      return axios
        .get(`${config.ECOM_BASE_URL}${config.API_VERSION}/${config.rootContextECOM}/ecomFeedback/countByRelationshipId?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1) - 1}`)
        .then(res => {
          dispatch({ type: PRODUCT_ACTION_LIST.RATING_REVIEW_COUNT, data: res.data  });
         
        })
        .catch((err) => {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
         
        })
        .finally(()=>{
            dispatch({
                type: COMMON_ACTIONS.HIDE_LIST_LOADER,
              });
        }
  
        )
    }
  }

  export const fetchPublishAndUnpublishList= (payload) => {
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
      return axios
        .get(`${config.ECOM_BASE_URL}${config.API_VERSION}/${config.rootContextECOM}/ecomFeedback/getByRelationshipIdAndIsPublished?relationshipId=${payload.relationshipId}&isPublished=${payload.isPublished}&pageSize=${payload.pageSize || 25}&pageNumber=${(payload.pageNumber || 1) - 1}`)
        .then(response => {
          const data = {
            pageNo: payload.pageNumber,
            list: response.data
        }
          dispatch({ type: PRODUCT_ACTION_LIST.PUBLISHED_LIST, data  });
         
        })
        .catch((err) => {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
         
        })
        .finally(()=>{
            dispatch({
                type: COMMON_ACTIONS.HIDE_LIST_LOADER,
              });
        }
  
        )
    }
  }
  export const countPublishAndUnpublish= (payload) => {
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
      return axios
        .get(`${config.ECOM_BASE_URL}${config.API_VERSION}/${config.rootContextECOM}/ecomFeedback/countByRelationshipIdAndIsPublish?relationshipId=${payload.relationshipId}&isActive=${payload.isPublished}`)
        .then(res => {
          dispatch({ type: PRODUCT_ACTION_LIST.PUBLISHED_LIST_COUNT, data: res.data  });
         
        })
        .catch((err) => {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
         
        })
        .finally(()=>{
            dispatch({
                type: COMMON_ACTIONS.HIDE_LIST_LOADER,
              });
        }
  
        )
    }
  }
  export const updatePublishFeedback = (payload, props) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios.post(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/ecomFeedback/updatePublisedFeedback?itemIdList=${props.selectedItems}`,payload)
        .then(response => {
          showToasterMessage({ messageType: 'success', description: `Status change` });
          const payload = {
            relationshipId: props.companyInfo.relationshipId,
            pageNumber: 1,
            pageSize: 25,
          }
          props.fetchPublishAndUnpublishList({ ...payload, isPublished: 0 });
          props.countPublishAndUnpublish({ ...payload, isPublished: 0 });

        })
        .catch(err => {
          if (!err.__isRetryRequest) {
            showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          }
        })
    }
  }

  export const fetchUnboxingVideoList= (payload) => {
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
      return axios
        .get(`${config.ECOM_BASE_URL}${config.API_VERSION}/${config.rootContextECOM}/unboxing/getAllUnboxing?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1) - 1}`)
        .then(response => {
          const data = {
            pageNo: payload.pageNumber,
            list: response.data
          }
          dispatch({ type: PRODUCT_ACTION_LIST.UNBOXING_VIDEO_LIST, data  });
         
        })
        .catch((err) => {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
         
        })
        .finally(()=>{
            dispatch({
                type: COMMON_ACTIONS.HIDE_LIST_LOADER,
              });
        }
  
        )
    }
  }

  export const fetchUnboxingListCount= (payload) => {
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
      return axios
        .get(`${config.ECOM_BASE_URL}${config.API_VERSION}/${config.rootContextECOM}/unboxing/countAllUnboxing?relationshipId=${payload.relationshipId}`)
        .then(res => {
          dispatch({ type: PRODUCT_ACTION_LIST.UNBOXING_VIDEO_COUNT, data: res.data  });
         
        })
        .catch((err) => {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
         
        })
        .finally(()=>{
            dispatch({
                type: COMMON_ACTIONS.HIDE_LIST_LOADER,
              });
        }
  
        )
    }
  }


  export const deleteUnboxingItem = (payload ,props)=>{

    return dispatch => {
      return axios.post(`${config.ECOM_BASE_URL}${config.API_VERSION}/${config.rootContextECOM}/unboxing/deleteUnboxing?relationshipId=${payload.relationshipId}&unboxingId=${payload.unboxingId}`
      )
          .then(response => {
            const payload1 = {
              relationshipId: (props.companyInfo || {}).relationshipId,
         
  
            }
            props.fetchUnboxingVideoList(payload1)
            props.fetchUnboxingListCount(payload1)

            showToasterMessage({
              description: (response || {}).message || 'Deleted successfully.',
              messageType: 'success'
            })
           
          })
          .catch(err => {
            showToasterMessage({
                messageType: "error",
                description: "Some error occured",
              });
            //   dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
    }
  }
  
 