import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS, PRODUCT_ACTION_LIST } from '../../../../../static/constants';
import {  showToasterMessage } from '../../../../../utils';

export const updateInventoryItem = (payload, props) => {
    //var formData = new FormData(); 
   // formData.append('inventory', JSON.stringify(payload, true));
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.INVENTORY_BASE_URL}${config.API_VERSION}/${config.rootContextInventory}/inventoryItem/updateProductAdjustment`,
        payload
        )
        .then(res => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          showToasterMessage({description: 'Updated successfully.',
          messageType: 'success' })
          dispatch({ type: PRODUCT_ACTION_LIST.RESET_PRODUCT_DETAILS });
          props.fetchProducts({relationshipId: payload.relationshipId, pageNumber: props.pageNumber || 1, pageSize: props.pageSize || 100, isDetails: true});
        })
        .catch(err => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          dispatch({ type: PRODUCT_ACTION_LIST.RESET_PRODUCT_DETAILS });
          showToasterMessage({ description: ((err.response || {}).data || {}).message || 'Some error occurred' })
        })
    }
  }

  export const approveQtyChange = (payload, props) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.INVENTORY_BASE_URL}${config.API_VERSION}/${config.rootContextInventory}/inventoryItem/approveQtyChange`, payload)
        .then(res => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          props.fetchProducts({relationshipId: payload.relationshipId, pageNumber: props.pageNumber || 1, pageSize: props.pageSize || 100, isDetails: true});
          showToasterMessage({
            description: (res || {}).message || 'Approved successfully.',
            messageType: 'success'
          })
        })
        .catch((err = {}) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (err.__isRetryRequest) {
          } else {
            showToasterMessage({
              description: ((err.response || {}).data || {}).message || 'Some error occurred'
            })
          }
        })
    }
  }

  export const rejectQtyChange = (payload, props) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.INVENTORY_BASE_URL}${config.API_VERSION}/${config.rootContextInventory}/inventoryItem/rejectQtyChange`, payload)
        .then(res => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          props.fetchProducts({relationshipId: payload.relationshipId, pageNumber: props.pageNumber || 1, pageSize: props.pageSize || 100, isDetails: true});
          showToasterMessage({
            description: (res || {}).message || 'Approved successfully.',
            messageType: 'success'
          })
        })
        .catch((err = {}) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (err.__isRetryRequest) {
          } else {
            showToasterMessage({
              description: ((err.response || {}).data || {}).message || 'Some error occurred'
            })
          }
        })
    }
  }