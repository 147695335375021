import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS } from '../../../../../static/constants';
import { fetchProducts, getProductCount, resetPaginatedData } from '../../../../product/Listing/action';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const importProducts = (payload, props,callback) => {
  // var formData = new FormData();
  // formData.append("file", payload.fileToUpload);
  // formData.append("countryName", payload.countryName);
  var formData = new FormData();
  formData.append("file", payload.fileToUpload);
  //ledgerInfo
  formData.append("ledgerInfo", payload.ledgerInfo);

  formData.append(
    "mapping",
    new Blob([payload.mappedData], {
      type: "application/json",
    })
  );
  const startTime = new Date();
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.INVENTORY_BASE_URL}${config.API_VERSION}/${config.rootContextInventory}/inventoryExcel/importInventoryFromExcel`,
        formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
      )
      .then(res => {
        showToasterMessage({ messageType: "success", description: lang[(res.data || {}).message] || "Please reselect the upload file." });
        let data = {
          relationshipId: props.companyInfo.relationshipId || 0,
          pageSize: 100,
          pageNumber: 1,
        }
        callback()
        props.updateState({
          isuploded:true,
          resData:res.data
        })
        resetPaginatedData()(dispatch);
        fetchProducts(data)(dispatch);
        getProductCount(data)(dispatch);
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch(err => {
        if (lang[((err.response || {}).data || {}).message]) {
          showToasterMessage({ messageType: "error", description: lang[((err.response || {}).data || {}).message] || "Please reselect the upload file." });
        } else {
          const errorTime = new Date();
          var dif = startTime.getTime() - errorTime.getTime();

          var Seconds_from_T1_to_T2 = dif / 1000;
          var Seconds_Between_Dates = Math.abs(Seconds_from_T1_to_T2);
          
          if (Seconds_Between_Dates > 55){
            showToasterMessage({ messageType: "success", description: lang["productUpload.inprogress.messsage"] || "Product upload in progress you will be notified once the upload is done" });
            
          }else{
            showToasterMessage({ messageType: "error", description: "Please reselect the upload file." });
          }
            
          props.popModalFromStack();
        }

        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
  }
}

export const getProductImportHeader = (relationshipId, props) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(
        `${config.INVENTORY_BASE_URL}${config.API_VERSION}/${config.rootContextInventory}/inventoryExcel/getInventoryHeaders?relationshipId=${relationshipId}`
      )
      .then((res) => {
        const response = res.data;
        dispatch({
          type: COMMON_ACTIONS.PRODUCT_IMPORT_HEADER,
          data: response,
        });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        if (lang[((err.response || {}).data || {}).message]) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some Error Occurs",
          });
        } else {
          showToasterMessage({
            messageType: "error",
            description: "Some Error Occurs",
          });
          props.popModalFromStack();
        }
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      });
  };
};

export const downloadExelFile = (payload, props) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.INVENTORY_BASE_URL}${config.API_VERSION}/${config.rootContextInventory}/inventoryExcel/download-error-csv `,
        payload,
        { responseType: 'arraybuffer' }
      )
      .then((response) => {
        const blob = new Blob([response.data], { type: 'text/csv' });
        const blobUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = blobUrl;
        a.download = 'report.csv';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(blobUrl);
        // props.popModalFromStack()
      })
      .catch((err) => {
        if (lang[((err.response || {}).data || {}).message]) {
          showToasterMessage({
            messageType: 'error',
            description:
              lang[((err.response || {}).data || {}).message] || 'Some Error Occurs',
          });
        } else {
          showToasterMessage({
            messageType: 'error',
            description: 'Some Error Occurs',
          });
          props.popModalFromStack();
        }
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      });
  };
};

export const downloadExelSampleFile = (props) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(
        `${config.INVENTORY_BASE_URL}${config.API_VERSION}/${config.rootContextInventory}/inventoryItem/downloadInventoryItemTemplate?relationshipId=${props.companyInfo.relationshipId}&countryName=${props.companyInfo.countryName}`,
        { responseType: 'arraybuffer' }
      )
      .then((response) => {
        const blob = new Blob([response.data], { type: 'text/csv' });
        const blobUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = blobUrl;
        a.download = 'Product_Import_Template.csv';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(blobUrl);
        // props.popModalFromStack()
      })
      .catch((err) => {
        if (lang[((err.response || {}).data || {}).message]) {
          showToasterMessage({
            messageType: 'error',
            description:
              lang[((err.response || {}).data || {}).message] || 'Some Error Occurs',
          });
        } else {
          showToasterMessage({
            messageType: 'error',
            description: 'Some Error Occurs',
          });
          props.popModalFromStack();
        }
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      });
  };
};