import React, { Fragment,useMemo } from "react";
import { Link } from "react-router-dom";
import { injectIntl, FormattedMessage } from "react-intl";
// import { AgGridReact } from 'ag-grid-react';
import { FileExcelOutlined, FilePdfOutlined } from "@ant-design/icons";
import {
  Pagination,
  Checkbox,
  Input,
  Skeleton,
  Menu,
  Dropdown,
  Button,
  Popover,
  Switch,
  Empty,
} from "antd";
import * as find from "lodash.find";
import * as debounce from "lodash.debounce";
import {
  fetchPaginationDataIfNeeded,
  fixedDecimalAndFormateNumber,
  sortTable,
  capitalizeFirstLetter,
  getMomentLoclDateForUIReadOnly,
  exportExcel,
  showToasterMessage,
  getPermissionData
} from "../../../utils";
import Tile from "../../tiles/tilesIndex";
import TotalOrders from "../../../containers/modal/modalBody/supplier/TotalOrders";
import OpenOrder from "../../../containers/modal/modalBody/supplier/OpenOrders";
import UnpaidInvoices from "../../../containers/modal/modalBody/supplier/UnpaidInvoices";
import PaidInvoices from "../../../containers/modal/modalBody/supplier/PaidInvoices";
import "./index.scss";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import ImportSupplier from "../../../containers/modal/modalBody/supplier/Import";
import {
  SUPPLIER_LISTING_PAGE,
  MODULE_CODED_VALUES,
  PERMISSION_VALUES,
  ICONS,
} from "../../../static/constants";
import ShowContact from "./../../../containers/supplier/Listing/ShowConact";
import ShowAddress from "./../../../containers/supplier/Listing/ShowAddress";
// import LogActivity from './../../../containers/supplier/Listing/LogActivity';
// import LogCommunication from './../../../containers/supplier/Listing/LogCommunication';
// import PayInvoice from './../../../containers/supplier/invoice/PayInvoice';
import { AddAndRemoveSupplierListingColumn } from "../../general/AddAndRemoveSupplierListingColumn";
// import { AG_GRID_CONSTANTS } from '../../../static/agGridConstants';
// import { AG_GRID_CLASS_CONSTANTS } from '../../../static/cssClassConstants';
// import { pushModalToStack } from '../../../actions/commonActions';
import PageBreadcrumb from "../../PageBreadcrumb";
import DropdownAction from "antd/lib/dropdown";
import {
  DownloadOutlined,
  InsertRowRightOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DownOutlined,
  UserOutlined,
  HomeOutlined,
  CopyOutlined,
} from "@ant-design/icons";

import SupplierDetails from "../../../containers/supplier/detail";
import img1 from "../../../assets/images/Usernewstu.png";
import img2 from "../../../assets/images/Userstu.png";
import img3 from "../../../assets/images/Userdue.png";
import img4 from "../../../assets/images/Userpaid.png";
import PageHeader from "../../Common/pageHeader";

const { Search } = Input;

// const getRowHeight = (params) => {
//     let height = 42;
//     let calcHeight = 1;
//     if (params.data.boLocationList) {
//         const address = find(params.data.boLocationList, { isDefault: 1, locationType: "BillingAddress" }) || {};
//         if (address) {
//             calcHeight = (address.streetAddress1 || address.streetAddress2) ? calcHeight + 1 : calcHeight;
//             calcHeight = (address.stateName || address.cityName || address.zipCode) ? calcHeight + .1 : calcHeight;
//         } else {
//             calcHeight = 2;
//         }
//     }
//     return height * calcHeight;
// };

// const agGridStyle = {
//     height: '100%',
//     width: '100%'
// };

const SuppliersListingComp = (props) => {
  const {
    permissions,
    suppliers,
    supplierCount,
    pageNumber,
    updateState,
    companyInfo,
    searchedText,
    supplierOpenOrderCount,
    supplierOpenPOCount,
    supplierUnpaidBalance,
    supplierPaidInvoiceListCount,
    selectedItems = [],
    dir = 0,
  } = props;
  const primaryPerm = (permissions && permissions.primary) || [];

  const supplierPermissionData = useMemo(()=>{
    return getPermissionData(primaryPerm, MODULE_CODED_VALUES.SUPPLIERS )
  },[])
  const addressPermissionData = useMemo(()=>{
    return getPermissionData(primaryPerm, MODULE_CODED_VALUES.SUPPLIER_ADDRESS )
  },[])
  const contactPermissionData = useMemo(()=>{
    return getPermissionData(primaryPerm, MODULE_CODED_VALUES.SUPPLIER_CONTACTS )
  },[])

  // const [isAllChecked, toggleAllChecked] = useState(false);

  // const [isTyping, setIsTyping] = useState(false);
  // let typingTimer;

  // // Function to be executed when typing stops
  // const typingStopped = () => {
  //     setIsTyping(false);
  //     handleSearchedText(props.searchedText, props)
  // };

  // // Event handler for keyup event
  // const handleKeyUp = () => {
  //     clearTimeout(typingTimer);
  //     setIsTyping(true);
  //     typingTimer = setTimeout(typingStopped, 1500); // Set the desired delay in milliseconds
  // };

  // // Event handler for keydown event
  // const handleKeyDown = () => {
  //     clearTimeout(typingTimer);
  //     setIsTyping(true);
  // };

  // const handleNewAddressAddition = (supplierData) => {
  //   let payload = {};
  //   let formData = {
  //     locationType: CONSTANTS.BILLING_ADDRESS,
  //     supplierId: (supplierData || {}).supplierId,
  //     pageNumber: pageNumber || 1,
  //   };
  //   payload.formData = formData;
  //   const { pushModalToStack, companyInfo } = props;
  //   formData.relationshipId = companyInfo.relationshipId;
  //   const data = {
  //     title: (
  //       <FormattedMessage id="addItem.text.billingAddress" defaultMessage="" />
  //     ),
  //     formData,
  //     hideFooter: true,
  //     modalData: {
  //       modalType: MODAL_TYPE.BILLING_ADDRESS,
  //       data: payload,
  //     },
  //     handleSubmit: (formData = {}) => {
  //       data.formData.submittedOnce = true;
  //       props.hideModal(data);
  //     },
  //   };
  //   pushModalToStack(data);
  // };

  // const handleNewContactAddition = (supplierData) => {
  //   const { showModal, companyInfo, hideModal } = props;
  //   let payload = {};
  //   let formData = {
  //     supplierId: (supplierData || {}).supplierId,
  //     pageNumber: pageNumber || 1,
  //     relationshipId: companyInfo.relationshipId,
  //   };
  //   payload.formData = formData;
  //   const data = {
  //     title: <FormattedMessage id="addItem.text.contact" defaultMessage="" />,
  //     formData,
  //     hideFooter: true,
  //     modalData: {
  //       modalType: MODAL_TYPE.CONTACT,
  //       data: payload,
  //     },
  //     handleSubmit: (formData = {}) => {
  //       data.formData.submittedOnce = true;
  //       setTimeout(() => {
  //         hideModal(data);
  //         props.fetchSuppliers({ relationshipId: companyInfo.relationshipId });
  //       }, 2000);
  //     },
  //   };
  //   showModal(data);
  // };

  //   const handleNewEmployeeAddition = (suppplierData) => {
  //     let payload = {};
  //     let formData = {
  //         firstName: '',
  //         supplierId: (suppplierData || {}).supplierId
  //     }
  //     payload.formData = formData;
  //     const { showModal, companyInfo } = props;
  //     formData.relationshipId = companyInfo.relationshipId;
  //     const data = {
  //         title: <FormattedMessage id='addItem.text.employee' defaultMessage='' />,
  //         formData,
  //         hideFooter: true,
  //         modalData: {
  //             modalType: MODAL_TYPE.EMPLOYEE,
  //             data: payload,

  //         },
  //         handleSubmit: (formData = {}) => {
  //             data.formData.submittedOnce = true;
  //             showModal(data);
  //         }
  //     };
  //     showModal(data);
  // }

  // const getSupplierDetailsCell = (data) => {
  //   if (data.boLocationList && data.boLocationList.length) {
  //     const address =
  //       find(data.boLocationList, {
  //         isDefault: 1,
  //         locationType: "BillingAddress",
  //       }) || {};

  //     return address.streetAddress1 ? (
  //       <div>
  //         <div className="table-link-text">
  //           <div
  //             onClick={() => {
  //               props.pushModalToStack({
  //                 modalBody: (
  //                   <SupplierDetails
  //                     {...props}
  //                     supplierId={data.supplierId}
  //                     supplierName={data.supplierStoreName}
  //                     supplier={data}
  //                   />
  //                 ),
  //                 width: "100%",
  //                 // title:'Supplier',
  //                 hideTitle: true,
  //                 hideFooter: true,
  //                 wrapClassName: "new-transaction-wrapper",
  //               });
  //             }}
  //             // to={
  //             //     {
  //             //         pathname: 'suppliers/details',
  //             //         state: {
  //             //             supplierId: data.supplierId,
  //             //             supplierName: data.supplierStoreName,
  //             //             supplier: data
  //             //         }
  //             //     }}
  //             className="company-name"
  //           >
  //             {data.supplierStoreName}
  //           </div>
  //         </div>
  //         <div className="light-font">{address.streetAddress1}</div>
  //         <div className="light-font">{address.streetAddress2}</div>
  //         <div className="light-font">
  //           <span>{address.cityName || ""}</span>
  //           <span>, {address.stateName || ""}</span> -
  //           <span> {address.zipCode || ""}</span>
  //         </div>
  //       </div>
  //     ) : (
  //       <div>
  //         <div>
  //           <div
  //             onClick={() => {
  //               props.pushModalToStack({
  //                 modalBody: (
  //                   <SupplierDetails
  //                     {...props}
  //                     supplierId={data.supplierId}
  //                     supplierName={data.supplierStoreName}
  //                     supplier={data}
  //                   />
  //                 ),
  //                 width: "100%",
  //                 // title:'Supplier',
  //                 hideTitle: true,
  //                 hideFooter: true,
  //                 wrapClassName: "new-transaction-wrapper",
  //               });
  //             }}
  //             // to={
  //             //     {
  //             //         pathname: 'suppliers/details',
  //             //         state: {
  //             //             supplierId: data.supplierId,
  //             //             supplierName: data.supplierStoreName,
  //             //             supplier: data
  //             //         }
  //             //     }}
  //             className="company-name"
  //           >
  //             {data.supplierStoreName}
  //           </div>
  //         </div>
  //         <div>
  //           <div
  //             className="table-link-text"
  //             onClick={() => handleNewAddressAddition(data)}
  //           >
  //             Add Address
  //           </div>
  //         </div>
  //       </div>
  //     );
  //   } else {
  //     return (
  //       <div>
  //         <div>
  //           <Link
  //             to={{
  //               pathname: "suppliers/details",
  //               state: {
  //                 supplierId: data.supplierId,
  //                 supplierName: data.supplierStoreName,
  //                 supplier: data,
  //               },
  //             }}
  //             className="company-name"
  //           >
  //             {data.supplierStoreName}
  //           </Link>
  //         </div>
  //         <div>
  //           {" "}
  //           <Link onClick={() => handleNewAddressAddition(data)}>
  //             {" "}
  //             <FormattedMessage id="addAddress.text" defaultMessage="" />{" "}
  //           </Link>{" "}
  //         </div>
  //       </div>
  //     );
  //   }
  // };

  // const getAddressInfoFragment = (obj = {}) => {
  //   return (
  //     <>
  //       {obj.cityName ? (
  //         <div className="billing-address">
  //           {`${obj.cityName || ""}${obj.stateName ? ", " + capitalizeFirstLetter(obj.stateName) : ""
  //             }`}
  //         </div>
  //       ) : (
  //         ""
  //       )}
  //       {obj.countryName ? (
  //         <div
  //           className="billing-address"
  //           style={{ textTransform: "uppercase" }}
  //         >
  //           {`${capitalizeFirstLetter(obj.countryName) || ""}`}
  //         </div>
  //       ) : (
  //         ""
  //       )}
  //     </>
  //   );
  // };
  const getAddressInfoFragment = (obj = {}) => {    
    return (
      <>
         <div className="billing-address first-caps">
            {obj.streetAddress1 || obj.streetAddress2 ? (

                <div>
                    {obj.streetAddress1} {obj.streetAddress2}{", "}
                </div>

            ) : ""}
            {obj.cityName || obj.stateName || obj.zipCode ? (

                <div>
                    {obj.cityName} {obj.stateName}{obj.stateName && obj.zipCode ? ", " : ""}{obj.zipCode}
                </div>

            ) : ""}
             {obj.countryName ? (
     <div>{obj.countryName}</div>
    ):""}
        </div>
      </>
    );
  };
  const getSupplierContactCell = (data) => {
    const contact =
      data.boContactList && find(data.boContactList, { isPrimaryContact: 1 });
    return (
      <>      
        <div style={{ display: "flex" }} className="line-view">
          {contact?.cellPhone ? (
            <>
              <i className={`${ICONS.CALL} mr5`} /> +{contact?.countryCallingCode} {contact?.cellPhone}
            </>
          ) : (
            <div className="empty-data-box mb5"></div>
          )}
        </div>
        <div style={{ display: "flex" }} className="line-view">
          {contact?.emailAddress ? (
            <>
              <i className={`${ICONS.MAIL} mr5`} /> {contact?.emailAddress}
            </>
          ) : (
            <div className="empty-data-box"></div>
          )}
        </div>

      </>

    )

    // contact ? (
    //   <div>
    //     <div>
    //       {(contact.salutationName || "") +
    //         " " +
    //         (contact.firstName || "") +
    //         " " +
    //         (contact.lastName || "")}{" "}
    //     </div>
    //     <div>{(contact.cellPhone || "") && "Ph: " + contact.cellPhone} </div>
    //   </div>
    // ) : (
    //   <div className="table-link-text">
    //     <Link onClick={() => handleNewContactAddition(data)}>
    //       {" "}
    //       <FormattedMessage id="addContact.text" defaultMessage="" />{" "}
    //     </Link>
    //   </div>
    // );
  };
 
  // const tilesData = [{
  //     footer: 'TOTAL ORDERS',
  //     count: supplierOpenOrderCount,
  //     icon: 'fa fa-bullseye',
  //     antIcon: <BookTwoTone />,
  //     color: 'darkGray-tile',
  //     type: SUPPLIER_LISTING_PAGE.TOTAL_ORDERS
  // },
  // {
  //     footer: 'OPEN PURCHASE ORDERS',
  //     count: supplierOpenPOCount,
  //     icon: 'fa fa-file-text-o',
  //     antIcon: <ContainerTwoTone />,
  //     color: 'green-tile',
  //     type: SUPPLIER_LISTING_PAGE.OPEN_PO
  // },
  // {
  //     footer: 'UNPAID BALANCE',
  //     count: supplierUnpaidBalance,
  //     // currencyIcon: companyInfo.currencyIcon,
  //     antIcon: <ExclamationCircleTwoTone />,
  //     color: 'blue-tile',
  //     type: SUPPLIER_LISTING_PAGE.UNPAID_BALANCE
  // },
  // {
  //     footer: 'TOTAL PURCHASE',
  //     count: supplierTotalPurchase,
  //     // currencyIcon: companyInfo.currencyIcon,
  //     antIcon: <CalculatorTwoTone />,
  //     color: 'magenta-tile',
  //     type: SUPPLIER_LISTING_PAGE.TOTAL_PURCHASE
  // }];

  const tilesData = [
    {
      footer: "TOTAL ORDERS",
      count: supplierOpenOrderCount,
      antIcon: <img src={img1} alt="" />,
      color: "tile2",
      type: SUPPLIER_LISTING_PAGE.TOTAL_ORDERS,
      moduleCode:MODULE_CODED_VALUES.SUPPLIERS,
    },
    {
      footer: "OPEN PURCHASE ORDERS",
      count: supplierOpenPOCount,
      antIcon: <img src={img2} alt="" />,
      color: "tile1",
      type: SUPPLIER_LISTING_PAGE.OPEN_PO,
      moduleCode:MODULE_CODED_VALUES.SUPPLIERS,
    },
    {
      footer: "UNPAID BALANCE",
      count: supplierUnpaidBalance,
      // currencyIcon: companyInfo.currencyIcon,
      antIcon: <img src={img3} alt="" />,
      color: "tile4",
      type: SUPPLIER_LISTING_PAGE.UNPAID_BALANCE,
      moduleCode:MODULE_CODED_VALUES.SUPPLIERS,
    },
    {
      footer: "TOTAL PURCHASE",
      count: supplierPaidInvoiceListCount,
      // currencyIcon: companyInfo.currencyIcon,
      antIcon: <img src={img4} alt="" />,
      color: "tile3",
      type: SUPPLIER_LISTING_PAGE.TOTAL_PURCHASE,
      moduleCode:MODULE_CODED_VALUES.SUPPLIERS,
    },
  ];

  const newSupplier = () => {
    props.updateHeaderState({
      supplier: {
        ...props.supplier,
        supplierDrawerVisible: true,
      },
    });
  };
  // const addAddress = () => {
  //   props.updateHeaderState({
  //     address: {
  //       addressDrawer: true,
  //     },
  //   });
  // };

  const editSupplier = (supplierData) => {
    props.updateHeaderState({
      supplier: {
        ...props.supplier,
        supplierDrawerVisible: true,
        isUpdate: true,
        supplierData: supplierData,
      },
    });
  };

  const cloneSupplier = (supplierData) => {
    props.updateHeaderState({
      supplier: {
        ...props.supplier,
        supplierDrawerVisible: true,
        isClone: true,
        supplierData: supplierData,
      },
    });
  };

  // const getActionMenuPermissionData = () => {
  //   const primaryPerm = permissions.primary || [];
  //   const permittedModules = {
  //     firstSection: {
  //       purchaseOrderCreate: checkACLPermission(
  //         primaryPerm,
  //         MODULE_CODED_VALUES.PURCHASE_ORDER,
  //         PERMISSION_VALUES.CREATE
  //       ),
  //       purchaseInvoiceCreate: checkACLPermission(
  //         primaryPerm,
  //         MODULE_CODED_VALUES.PURCHASE_INVOICE,
  //         PERMISSION_VALUES.CREATE
  //       ),
  //       debitMemoCreate: checkACLPermission(
  //         primaryPerm,
  //         MODULE_CODED_VALUES.DEBIT_MEMO,
  //         PERMISSION_VALUES.CREATE
  //       ),
  //       supplierPayment: checkACLPermission(
  //         primaryPerm,
  //         MODULE_CODED_VALUES.PAY_INVOICE,
  //         PERMISSION_VALUES.CREATE
  //       ),
  //     },
  //     secondSection: {
  //       createSupplier: checkACLPermission(
  //         primaryPerm,
  //         MODULE_CODED_VALUES.SUPPLIERS,
  //         PERMISSION_VALUES.CREATE
  //       ),
  //       editSupplier: checkACLPermission(
  //         primaryPerm,
  //         MODULE_CODED_VALUES.SUPPLIERS,
  //         PERMISSION_VALUES.UPDATE
  //       ),
  //       deleteSupplier: checkACLPermission(
  //         primaryPerm,
  //         MODULE_CODED_VALUES.SUPPLIERS,
  //         PERMISSION_VALUES.DELETE
  //       ),
  //     },
  //     thirdSection: {
  //       showAddress: checkACLPermission(
  //         primaryPerm,
  //         MODULE_CODED_VALUES.SUPPLIER_ADDRESS,
  //         PERMISSION_VALUES.READ
  //       ),
  //       showContact: checkACLPermission(
  //         primaryPerm,
  //         MODULE_CODED_VALUES.SUPPLIER_CONTACTS,
  //         PERMISSION_VALUES.READ
  //       ),
  //     },
  //     fourthSection: {
  //       logActivity: checkACLPermission(
  //         primaryPerm,
  //         MODULE_CODED_VALUES.SUPPLIER_ACTIVITY,
  //         PERMISSION_VALUES.CREATE
  //       ),
  //       logCommunication: checkACLPermission(
  //         primaryPerm,
  //         MODULE_CODED_VALUES.SUPPLIER_COMMUNICATION,
  //         PERMISSION_VALUES.CREATE
  //       ),
  //     },
  //   };
  //   Object.keys(permittedModules.firstSection).forEach((moduleName) => {
  //     if (permittedModules.firstSection[moduleName] === false) {
  //       delete permittedModules.firstSection[moduleName];
  //     }
  //   });
  //   Object.keys(permittedModules.secondSection).forEach((moduleName) => {
  //     if (permittedModules.secondSection[moduleName] === false) {
  //       delete permittedModules.secondSection[moduleName];
  //     }
  //   });
  //   Object.keys(permittedModules.thirdSection).forEach((moduleName) => {
  //     if (permittedModules.thirdSection[moduleName] === false) {
  //       delete permittedModules.thirdSection[moduleName];
  //     }
  //   });
  //   Object.keys(permittedModules.fourthSection).forEach((moduleName) => {
  //     if (permittedModules.fourthSection[moduleName] === false) {
  //       delete permittedModules.fourthSection[moduleName];
  //     }
  //   });
  //   return permittedModules;
  // };

  const actionMenu = (param) => (
    <Menu className="row-action-dropdown">
      {/* <Menu.Item key="0">
                <i className="fa fa-file-text-o" />
                <Link to={
                        {
                            pathname: 'pr-create',
                            state: {
                                supplierId: param.data.supplierId,
                                supplierName: param.data.supplierStoreName,
                                pageNumber: props.pageNumber
                            }
                        }}>Create Purchase Request</Link>
            </Menu.Item> */}
      {/* {
                permittedModules.firstSection.purchaseOrderCreate
                    ? (
                        <Menu.Item key="1">
                            <i className="fa fa-file-o" />
                            <Link to={
                                {
                                    pathname: 'po-create',
                                    state: {
                                        supplierId: param.data.supplierId,
                                        supplierName: param.data.supplierStoreName,
                                        pageNumber: props.pageNumber
                                    }
                                }}><FormattedMessage id='supplier.listing.actionMenu.createPurchaseOrder' defaultMessage='' /></Link>
                        </Menu.Item>
                    )
                    : <Fragment />
            }
            {
                permittedModules.firstSection.purchaseInvoiceCreate
                    ? (
                        <Menu.Item key="2">
                            <i className="fa fa-file-o" />
                            <Link to={
                                {
                                    pathname: 'purchase-invoice',
                                    state: {
                                        supplierId: param.data.supplierId,
                                        supplierName: param.data.supplierStoreName,
                                        pageNumber: props.pageNumber
                                    }
                                }}><FormattedMessage id='supplier.listing.actionMenu.bookPurchase' defaultMessage='' /></Link>
                        </Menu.Item>
                    )
                    : <Fragment />
            }
            {
                permittedModules.firstSection.debitMemoCreate
                    ? (
                        <Menu.Item key="3">
                            <i className="fa fa-file-text-o" />
                            <Link
                                to={
                                    {
                                        pathname: 'debit-memo-create',
                                        state: {
                                            supplierId: param.data.supplierId,
                                            supplierName: param.data.supplierStoreName,
                                            pageNumber: props.pageNumber
                                        }
                                    }}
                            >
                                <FormattedMessage id='supplier.listing.actionMenu.createDebitMemo' defaultMessage='' />
                            </Link>
                        </Menu.Item>
                    )
                    : <Fragment />
            }
            {
                permittedModules.firstSection.supplierPayment
                    ? (
                        <Menu.Item key="4" onClick={() => {
                            const modalData = {
                                title: <FormattedMessage id='supplier.listing.actionMenu.makePayment.title' defaultMessage='' />,
                                modalBody: <PayInvoice {...props} invoiceData={{ ...param.data, supplierName: param.data.supplierStoreName }} />,
                                width: 600,
                                hideFooter: true,
                                wrapClassName: 'supplier-payment-modal'
                            };
                            props.showModal(modalData);
                        }}>
                            <i className="fa fa-money" />
                            <Link><FormattedMessage id='supplier.listing.actionMenu.makePayment' defaultMessage='' /></Link>
                        </Menu.Item>
                    )
                    : <Fragment />
            }

            {
                Object.values(permittedModules.firstSection).length
                    ? <Menu.Divider />
                    : <Fragment />
            } */}
      <Menu.Item key="0">

        <div>
          Action
        </div>
      </Menu.Item>

      {supplierPermissionData.update ? (
        <Menu.Item key="5" value="edit" onClick={() => editSupplier(param)}>
          <i className={ICONS.EDIT} />
          <div>
            <FormattedMessage
              id="supplier.listing.actionMenu.editSupplier"
              defaultMessage=""
            />
          </div>
        </Menu.Item>
      ) : (
        <Fragment />
      )}

      {supplierPermissionData.create ? (
        <Menu.Item key="6" value="edit" onClick={() => cloneSupplier(param)}>
          <CopyOutlined />
          <FormattedMessage id="clone.Supplier" defaultMessage="" />
        </Menu.Item>
      ) : (
        <Fragment />
      )}

      {supplierPermissionData.delete ? (
        <Menu.Item key="7">
          <i className={ICONS.DELETE} />
          <Link
            onClick={() => {
              let ids = [];
              if (param.supplierId) {
                ids.push(param.supplierId);
              }
              if (ids.length) {
                const modalData = {
                  modalBody: "Are you sure you want to delete supplier?",
                  handleSubmit: () => {
                    //props.resetPaginatedData();
                    props.deleteSuppliers({
                      relationshipId: props.companyInfo.relationshipId,
                      groupSupplierIds: ids,
                      pageNumber: props.pageNumber,
                      pageSize: props.pageSize,
                    });
                    // props.fetchSuppliers({
                    //     relationshipId: props.companyInfo.relationshipId,
                    //     pageNumber: props.pageNumber,
                    //     pageSize: props.pageSize
                    // })
                  },
                };
                props.pushModalToStack(modalData);
              } else {
                const modalData = {
                  title: (
                    <div className="title">
                      <i className="fa fa-ban mr15" />
                      <FormattedMessage
                        id="supplier.listing.validation.failed.label"
                        defaultMessage=""
                      />
                    </div>
                  ),
                  modalBody: (
                    <Fragment>
                      <div className="warning-message">
                        <FormattedMessage
                          id="supplier.listing.validation.failed.message"
                          defaultMessage=""
                        />
                      </div>
                      <div className="button-action">
                        <Button
                          className="ant-btn-default"
                          onClick={() => props.popModalFromStack()}
                        >
                          <FormattedMessage id="ok" defaultMessage="" />
                        </Button>
                      </div>
                    </Fragment>
                  ),
                  hideFooter: true,
                  wrapClassName: "validation-failed-modal",
                };
                props.pushModalToStack(modalData);
              }
            }}
          >
            <FormattedMessage
              id="supplier.listing.actionMenu.deleteSupplier"
              defaultMessage=""
            />
          </Link>
        </Menu.Item>
      ) : (
        <Fragment />
      )}

      {addressPermissionData.read || contactPermissionData.read ? (
        <Menu.Divider />
      ) : (
        <Fragment />
      )}

      {addressPermissionData.read ? (
        <Menu.Item key="8">
          <HomeOutlined />
          <Link
            onClick={() => {
              const modalData = {
                title: (
                  <div className="dis-heading">
                    <div className="ant-modal-title">
                      <FormattedMessage
                        id="supplier.listing.actionMenu.title.address"
                        defaultMessage=""
                      />
                    </div>
                    <div style={{ "font-size": "12px" }}>
                      {" "}
                      {param.supplierStoreName}
                    </div>
                  </div>
                ),
                modalBody: (
                  <ShowAddress {...props} supplierId={param.supplierId} />
                ),
                width: 1100,
                hideFooter: true,
              };
              props.pushModalToStack(modalData);
            }}
          >
            <FormattedMessage
              id="supplier.listing.actionMenu.address"
              defaultMessage=""
            />
          </Link>
        </Menu.Item>
      ) : (
        <Fragment />
      )}

      {contactPermissionData.read ? (
        <Menu.Item key="9">
          <UserOutlined />
          <Link
            onClick={() => {
              const modalData = {
                title: (
                  <React.Fragment>
                    <div className="ant-modal-title">
                      <FormattedMessage
                        id="supplier.listing.actionMenu.title.contact"
                        defaultMessage=""
                      />
                    </div>
                    <div style={{ "font-size": "12px" }}>
                      {" "}
                      {param.supplierStoreName}
                    </div>
                  </React.Fragment>
                ),
                modalBody: (
                  <ShowContact {...props} supplierId={param.supplierId} />
                ),
                width: 1100,
                hideFooter: true,
              };
              props.pushModalToStack(modalData);
            }}
          >
            <FormattedMessage
              id="supplier.listing.actionMenu.contact"
              defaultMessage=""
            />
          </Link>
        </Menu.Item>
      ) : (
        <Fragment />
      )}

      {/* {Object.values(permittedModules.thirdSection).length ? (
        <Menu.Divider />
      ) : (
        <Fragment />
      )} */}

      {/* {
                permittedModules.fourthSection.logActivity
                    ? (
                        <Menu.Item key="10">
                            <i className="fa fa-pencil-square-o" />
                            <Link onClick={() => {
                                const modalData = {
                                    title: <React.Fragment>
                                        <div className='modal-title'>
                                        <FormattedMessage id='supplier.listing.actionMenu.title.newActivity' defaultMessage='' />
                                        </div>
                                        <div className="modal-sub-title">
                                            {param.data.companyName}
                                        </div>
                                    </React.Fragment>,
                                    modalBody: <LogActivity
                                        supplierId={param.data.supplierId}
                                        supplierName={param.data.supplierStoreName}
                                        pageNumber={props.pageNumber}
                                    />,
                                    wrapClassName: 'log-activity-modal',
                                    width: 500,
                                    hideFooter: true
                                };
                                props.pushModalToStack(modalData);
                            }}
                            ><FormattedMessage id='supplier.listing.actionMenu.newActivity' defaultMessage='' /></Link>
                        </Menu.Item>
                    )
                    : <Fragment />
            }

            {
                permittedModules.fourthSection.logCommunication
                    ? (
                        <Menu.Item key="11">
                            <i className="fa fa-bolt" />
                            <Link onClick={() => {
                                const modalData = {
                                    title: <React.Fragment>
                                        <div className='modal-title'>
                                        <FormattedMessage id='supplier.listing.actionMenu.title.newCommunication' defaultMessage='' />
                                        </div>
                                        <div className="modal-sub-title">
                                            {param.data.companyName}
                                        </div>
                                    </React.Fragment>,
                                    modalBody: <LogCommunication
                                        supplierId={param.data.supplierId}
                                        supplierName={param.data.supplierStoreName}
                                        pageNumber={props.pageNumber}
                                    />,
                                    width: 500,
                                    hideFooter: true,
                                    wrapClassName: 'log-communication-modal',
                                };
                                props.pushModalToStack(modalData);
                            }}
                            ><FormattedMessage id='supplier.listing.actionMenu.newCommunication' defaultMessage='' /></Link>
                        </Menu.Item>
                    )
                    : <Fragment />
            } */}

      {/* <Menu.Item key="12">
                <i className="fa fa-file-pdf-o" />
                <Link onClick={() => {
                    const relationshipAddress = (props.companyInfo.boLocationList || []).find(x => x.isDefault && x.locationType === "RelationshipBillingAddress") || {};
                    props.exportLedgerPdf({ supplierId: param.data.supplierId, supplierObj: param.data, relationshipAddress: relationshipAddress }, props, true)

                }}
                ><FormattedMessage id='supplier.listing.actionMenu.downloadLedgerStatement' defaultMessage='' /></Link>
            </Menu.Item> */}
    </Menu>
  );

  const _isColumnToHide = (columnName) => {
    return props.txColumns.indexOf(columnName) < 0 &&
      props.txMandatoryColumns.indexOf(columnName) < 0
      ? true
      : false;
  };

  // const permittedModules = getActionMenuPermissionData();

  // const columnDefs = [
  //     {
  //         field: 'isChecked',
  //         headerComponentFramework: () => <Fragment />,
  //         cellRendererFramework: (params) => {
  //             return <Checkbox
  //                 onClick={() => {
  //                     params.node.setDataValue('isChecked', !params.data.isChecked)
  //                 }}
  //                 checked={params.data.isChecked} />
  //         },
  //         minWidth: 70,
  //         maxWidth: 70,
  //         suppressMovable: true,
  //         pinned: 'left',
  //         lockPosition: true,
  //         resizable: false
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='supplier.listing.header.supplierDetails' defaultMessage='' />;
  //         },
  //         field: "supplierStoreName",
  //         hide: _isColumnToHide('supplierStoreName'),
  //         colId: "supplierStoreName_1",
  //         resizable: true,
  //         minWidth: 70,
  //         maxWidth: 400,
  //         suppressMovable: true,
  //         cellRendererFramework: function (param) {
  //             return getSupplierDetailsCell(param.data);
  //         },
  //         cellClass: "supplier-details-cell"
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='supplier.listing.header.contact' defaultMessage='' />;
  //         },
  //         field: "contact",
  //         hide: _isColumnToHide('contact'),
  //         colId: "contact_1",
  //         resizable: true,
  //         minWidth: 70,
  //         maxWidth: 400,
  //         suppressMovable: true,
  //         cellRendererFramework: function (param) {
  //             return getSupplierContactCell(param.data);
  //         }
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <div> <FormattedMessage id='supplier.listing.header.dueAmount' defaultMessage='' />  ({props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "})</div>;
  //         },
  //         field: "totalAmountDue",
  //         hide: _isColumnToHide('totalAmountDue'),
  //         colId: "totalAmountDue_1",
  //         resizable: true,
  //         minWidth: 70,
  //         maxWidth: 400,
  //         suppressMovable: true,
  //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
  //         valueFormatter: (data) => {
  //             data.value = data.value ? data.value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '-';
  //             return data.value;
  //         },
  //         cellRendererFramework: function (param) {
  //             return (
  //                 <div>
  //                     <p>{param.data.totalAmountDue ? (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " ") : ''}{(param.data.totalAmountDue || '') && param.data.totalAmountDue.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
  //                 </div>
  //             );
  //         },
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='supplier.listing.header.openOrder' defaultMessage='' />;
  //         },
  //         field: "openedOrdersCount",
  //         hide: _isColumnToHide('openedOrdersCount'),
  //         colId: "openedOrdersCount_1",
  //         minWidth: 70,
  //         maxWidth: 400,
  //         suppressMovable: true,
  //         cellRendererFramework: function (param) {
  //             return (
  //                 <div> <p onClick={() => {
  //                     props.showModal(
  //                         {
  //                             title: <Fragment>
  //                                 <div className='ant-modal-title'><FormattedMessage id='open.Orders' defaultMessage='' /> </div>
  //                                 <div className="ant-modal-subtitle extra-sm">{param.data.companyName}</div>
  //                             </Fragment>,
  //                             modalBody: <OpenOrder {...props} supplierData={param.data} />,
  //                             width: 1000,
  //                             hideFooter: true,
  //                             wrapClassName: 'tiles-modal'
  //                         }
  //                     )
  //                 }} className={param.data.openedOrdersCount ? 'table-link-text' : ''}> {param.data.openedOrdersCount}</p>
  //                     <p>{param.data.openedOrdersTotalAmount ? (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " ") : ''}{(param.data.openedOrdersTotalAmount || '') && param.data.openedOrdersTotalAmount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
  //                 </div>
  //             );
  //         },
  //         resizable: true,
  //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='supplier.listing.header.unpaidInvoices' defaultMessage='' />;
  //         },
  //         field: "unpaidInvoicesCount",
  //         hide: _isColumnToHide('unpaidInvoicesCount'),
  //         colId: "unpaidInvoicesCount_1",
  //         minWidth: 70,
  //         maxWidth: 400,
  //         suppressMovable: true,
  //         cellRendererFramework: function (param) {
  //             return (
  //                 <div> <p onClick={() => {
  //                     props.showModal(
  //                         {
  //                             title: <Fragment>
  //                                 <div className='ant-modal-title'> <FormattedMessage id='ustomer.listing.header.unpaidInvoices' defaultMessage='' /></div>
  //                                 <div className="ant-modal-subtitle extra-sm">{param.data.companyName}</div>
  //                             </Fragment>,
  //                             modalBody: <UnpaidInvoices supplierData={param.data} {...props} />,
  //                             width: 1000,
  //                             hideFooter: true,
  //                             wrapClassName: 'tiles-modal'
  //                         }
  //                     )
  //                 }} className={param.data.unpaidInvoicesCount ? 'table-link-text' : ''} > {param.data.unpaidInvoicesCount}</p>
  //                     <p> {param.data.unpaidInvoicesTotalAmount ? (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " ") : ''}{(param.data.unpaidInvoicesTotalAmount || '') && param.data.unpaidInvoicesTotalAmount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
  //                 </div>
  //             );
  //         },
  //         resizable: true,
  //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='supplier.listing.header.paidInvoices' defaultMessage='' />;
  //         },
  //         field: "paidInvoicesCount",
  //         hide: _isColumnToHide('paidInvoicesCount'),
  //         colId: "paidInvoicesCount_1",
  //         minWidth: 70,
  //         maxWidth: 400,
  //         suppressMovable: true,
  //         cellRendererFramework: function (param) {
  //             return (
  //                 <div> <p onClick={() => {
  //                     props.showModal(
  //                         {
  //                             title: <Fragment>
  //                                 <div className='ant-modal-title'><FormattedMessage id='customer.listing.header.paidInvoices' defaultMessage='' /> </div>
  //                                 <div className="ant-modal-subtitle extra-sm">{param.data.companyName}</div>
  //                             </Fragment>,
  //                             modalBody: <PaidInvoices supplierData={param.data} {...props} />,
  //                             width: 1000,
  //                             hideFooter: true,
  //                             wrapClassName: 'tiles-modal'
  //                         }
  //                     )
  //                 }} className={param.data.paidInvoicesCount ? 'table-link-text' : ''}> {param.data.paidInvoicesCount}</p>
  //                     <p> {param.data.paidInvoicesTotalAmount ? (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " ") : ''}{(param.data.paidInvoicesTotalAmount || '') && param.data.paidInvoicesTotalAmount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
  //                 </div>
  //             );
  //         },
  //         resizable: true,
  //         wrapClassName: 'tiles-modal',
  //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
  //     },
  //     {
  //         headerName: "Payment Term",
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='supplier.listing.header.paymentTerm' defaultMessage='' />;
  //         },
  //         field: "paymentTermName",
  //         minWidth: 70,
  //         maxWidth: 400,
  //         suppressMovable: true,
  //         hide: _isColumnToHide('paymentTermName'),
  //         colId: "paymentTermName_1",
  //         resizable: true
  //     }
  // ];

  // if (Object.keys(permittedModules.firstSection).length || Object.keys(permittedModules.firstSection).length
  //     || Object.keys(permittedModules.firstSection).length || Object.keys(permittedModules.firstSection).length) {
  //     columnDefs.push({
  //         headerComponentFramework: () => {
  //             return <div className="table-link-text">
  //                 <Popover
  //                     content={<AddAndRemoveSupplierListingColumn {...props} />}
  //                     title={<div className="text-center"><FormattedMessage id='common.addRemove' defaultMessage='' /> </div>}
  //                     placement="left"
  //                     trigger="click"
  //                 >
  //                     <i className='fa fa-bars' />
  //                 </Popover>
  //             </div>;
  //         },
  //         field: "action",
  //         colId: "action_1",
  //         pinned: 'right',
  //         minWidth: 52,
  //         maxWidth: 52,
  //         suppressMovable: true,
  //         cellRendererFramework: (param) => {
  //             return <Dropdown overlay={actionMenu(param, permittedModules)} trigger={['click']} overlayClassName="action-listing-dropdown">
  //                 <Button className="action-btn small">
  //                     <i className="fa fa-ellipsis-v" />
  //                 </Button>
  //             </Dropdown>
  //         },
  //         resizable: true,
  //         cellClass: 'lastActionCell'
  //     });
  // }

  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    fetchPaginationDataIfNeeded("fetchSuppliers", "suppliers", props, payload);
  };

  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.resetPaginatedData();
    props.fetchSuppliers(payload);
    props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  };

  const handleMenuClick = (e) => {
    const modalData = {
      title: (
        <div className="ant-modal-title">
          <FormattedMessage
            id="supplier.listing.importSuppliers.modal.title"
            defaultMessage=""
          />
        </div>
      ),
      modalBody: <ImportSupplier {...props}/>,
      hideFooter: true,
      width: "100%",
      wrapClassName: "import-details-modal file-header",
    };
    props.pushModalToStack(modalData);

  };

  // const onGridReady = (params) => {
  //     if (props.isColumnFitTable) {
  //         params.api.sizeColumnsToFit();
  //     }
  //     props.updateState({
  //         gridApi: params.api,
  //         gridColumnApi: params.columnApi,
  //     });
  //     onDataRendered(params);
  // }

  // const saveColumnIndex = (columnState) => {
  //     let data = props.txPreferences || {};
  //     let tablePreference = {};
  //     data.relationshipId = (props.companyInfo || {}).relationshipId;
  //     data.clientUserAccountId = (props.userInfo || {}).userId;
  //     if (data.tablePreference) {
  //         tablePreference = JSON.parse(data.tablePreference);
  //     }
  //     tablePreference.columnSetting = columnState;
  //     data.tablePreference = JSON.stringify(tablePreference);

  //     data.preferenceId = props.preferenceId;
  //     data.gridPreference = props.gridPreference;
  //     props.saveUserPrefrences(data);
  // }

  // const saveColumnWidth = (columnState, width) => {
  //     let data = props.txPreferences || {};
  //     let tablePreference = {};
  //     data.relationshipId = (props.companyInfo || {}).relationshipId;
  //     data.clientUserAccountId = (props.userInfo || {}).userId;
  //     if (data.tablePreference) {
  //         tablePreference = JSON.parse(data.tablePreference);
  //     }
  //     tablePreference.columnSetting = columnState;
  //     tablePreference.columnList = props.txColumns;
  //     data.tablePreference = JSON.stringify(tablePreference);
  //     data.preferenceId = props.preferenceId;
  //     data.gridPreference = props.gridPreference;
  //     props.saveUserPrefrences(data, props);
  // }

  //let moveEvent = {};
  // const onColumnMoved = (event, isDragStopped) => {
  //     if (event.column) {
  //         moveEvent = event;
  //     }
  //     if (isDragStopped && moveEvent.column && moveEvent.column.colDef && moveEvent.source !== AG_GRID_CONSTANTS.EVENT_SOURCE.API) {
  //         saveColumnIndex(moveEvent.columnApi.getColumnState());
  //     }
  // }

  // const onColumnResized = (event, tablegridApi, tableGridColumnApi) => {
  //     const gridColumnApi = tableGridColumnApi || props.gridColumnApi;
  //     event.api.resetRowHeights();
  //     if (event.type === 'columnResized' && event.source !== 'sizeColumnsToFit' && event.source !== 'api' && event.finished === true) {
  //         //event.api.resetRowHeights();
  //         if (gridColumnApi && gridColumnApi.columnController.bodyWidth < gridColumnApi.columnController.scrollWidth) {
  //             props.gridApi.sizeColumnsToFit();
  //             setTimeout(() => {
  //                 const columnArray = event.columnApi.getColumnState();
  //                 saveColumnWidth(columnArray);
  //             }, 1000);
  //         } else {
  //             saveColumnWidth(event.columnApi.getColumnState());
  //         }
  //     }
  // }

  // const onModelUpdated = (params) => {
  //     if ((suppliers[pageNumber] || []).length && !props.isRowHeightSet) {
  //         setTimeout(() => {
  //             params.api.resetRowHeights();
  //         }, 1000)
  //         props.updateState({
  //             isRowHeightSet: true
  //         })
  //     }
  //     if (props.tableDataReRendered) {
  //         //params.api.resetRowHeights();
  //         props.updateState({
  //             tableDataReRendered: false
  //         })
  //         const txColumnSetting = props.txColumnSetting && props.txColumnSetting.length ? props.txColumnSetting : params.columnApi.getColumnState();
  //         txColumnSetting.forEach((e) => {
  //             let columnName = e.colId.split("_")[0];
  //             if (columnName !== 'action') {
  //                 if (columnName === 'isChecked') {
  //                     e.hide = false;
  //                 } else {
  //                     e.hide = _isColumnToHide(columnName);
  //                 }
  //             } if (columnName === 'action') {
  //                 e.hide = false;
  //                 e.pinned = 'right';
  //             }
  //         });
  //         params.columnApi.setColumnState(txColumnSetting);
  //         setTimeout(() => {
  //             if (props.gridColumnApi && props.gridColumnApi.columnController.bodyWidth < props.gridColumnApi.columnController.scrollWidth) {
  //                 params.api.sizeColumnsToFit();
  //             }
  //         }, 500);
  //     }
  // }

  // const onDataRendered = (params) => {
  //     const txColumnSetting = props.txColumnSetting;
  //     if (Object.keys(txColumnSetting).length) {
  //         txColumnSetting.forEach((e) => {
  //             let columnName = e.colId.split("_")[0];
  //             if (columnName !== 'action') {
  //                 if (columnName === 'isChecked') {
  //                     e.hide = false;
  //                 } else {
  //                     e.hide = _isColumnToHide(columnName);
  //                 }
  //             } if (columnName === 'action') {
  //                 e.hide = false;
  //                 e.width = 52;
  //                 e.pinned = 'right';
  //             }
  //         });
  //         params.columnApi.setColumnState(txColumnSetting);
  //         setTimeout(() => {
  //             if (params.columnApi && params.columnApi.columnController.bodyWidth < params.columnApi.columnController.scrollWidth) {
  //                 params.api.sizeColumnsToFit();
  //             }
  //         }, 500);
  //     } else {
  //         params.api.sizeColumnsToFit();
  //     }
  // }

  const openModalOfSelectedTile = (data) => {
    switch (data.type) {
      case SUPPLIER_LISTING_PAGE.TOTAL_ORDERS:
        props.pushModalToStack({
          title: (
            <div className="ant-modal-title">
              <FormattedMessage id="totalOrder.text" defaultMessage="" />{" "}
            </div>
          ),
          modalBody: <TotalOrders {...props} />,
          width: 1000,
          hideFooter: true,
          wrapClassName: "tiles-modal",
        });
        break;
      case SUPPLIER_LISTING_PAGE.OPEN_PO:
        props.pushModalToStack({
          title: (
            <div className="ant-modal-title">
              <FormattedMessage
                id="common.Open.Purchase.Orders"
                defaultMessage=""
              />{" "}
            </div>
          ),
          modalBody: <OpenOrder {...props} />,
          width: 1000,
          hideFooter: true,
          wrapClassName: "tiles-modal",
        });
        break;
      case SUPPLIER_LISTING_PAGE.UNPAID_BALANCE:
        props.pushModalToStack({
          title: (
            <div className="ant-modal-title">
              <FormattedMessage
                id="common.Unpaid.Balance"
                defaultMessage=""
              />{" "}
            </div>
          ),
          modalBody: <UnpaidInvoices {...props} />,
          width: 1000,
          hideFooter: true,
          wrapClassName: "tiles-modal",
        });
        break;
      case SUPPLIER_LISTING_PAGE.TOTAL_PURCHASE:
        props.pushModalToStack({
          title: (
            <div className="ant-modal-title">
              <FormattedMessage
                id="common.Total.Purchases"
                defaultMessage=""
              />{" "}
            </div>
          ),
          modalBody: <PaidInvoices {...props} />,
          width: 1000,
          hideFooter: true,
          wrapClassName: "tiles-modal",
        });
        break;
      default:
        break;
    }
  };

  const itemSelection = (item) => {
    let selectItem = find(selectedItems, {
      supplierId: Number(item.supplierId),
    });
    let updatedItems = [];
    if (selectItem) {
      updatedItems = selectedItems.filter(
        (obj) => obj.supplierId !== item.supplierId
      );
    } else {
      updatedItems = JSON.parse(JSON.stringify(selectedItems));
      updatedItems.push(item);
    }
    props.updateState({ selectedItems: updatedItems });
  };

  const selectAll = (context) => {
    if (!context) {
      selectedItems.splice(0, selectedItems.length);
      props.updateState({ selectedItems: selectedItems });
      return;
    }
    for (let i = 0; i < suppliers[pageNumber].length; i++) {
      let item = suppliers[pageNumber][i];
      let selectItem = find(selectedItems, {
        supplierId: Number(item.supplierId),
      });
      if (!selectItem) {
        selectedItems.push(item);
      }
    }
    props.updateState({ selectedItems: selectedItems });
  };

  const isCheckedAll = () => {
    if (
      !suppliers ||
      !suppliers[pageNumber] ||
      suppliers[pageNumber].length === 0
    ) {
      return false;
    }
    for (let i = 0; i < suppliers[pageNumber].length; i++) {
      let usr = suppliers[pageNumber][i];
      let selectItem = find(selectedItems, {
        supplierId: Number(usr.supplierId),
      });
      if (!selectItem) {
        return false;
      }
    }
    return true;
  };

  const breadCrumbList = [
    {
      name: (
        <FormattedMessage id="sidebar.menuItem.purchase" defaultMessage="" />
      ),
    },
    {
      name: (
        <FormattedMessage id="sidebar.menuItem.suppliers" defaultMessage="" />
      ),
    },
  ];

 

  const exportSupplierData = (supplierList) => {
    if (!supplierList?.length) return;
  
    const formatAddress = (address) => [
      address.streetAddress1,
      address.streetAddress2,
      address.cityName,
      address.stateName,
      address.zipCode,
      address.countryName
    ].filter(Boolean).join(', ');
  
    const formatContact = (contact) => `${contact?.cellPhone || ''}${contact?.cellPhone && contact?.emailAddress ? ' | ' : ''}${contact?.emailAddress || ''}`;
  
    const dataList = supplierList.map((data, i) => {
      const contact = data.boContactList ? find(data.boContactList, { isPrimaryContact: 1 }) : {};
      const address = find(data.boLocationList, { isDefault: 1, locationType: "BillingAddress" }) || {};
  
      let rowData = {
        '#': i + 1,
        'Supplier Name': data.supplierStoreName || '',
        'Contact': formatContact(contact),
        'Address': formatAddress(address),
        'Due Amount': data.totalAmountDue || '',
        'Open Order': data.openedOrdersTotalAmount || '',
        'Unpaid Invoices': data.unpaidInvoicesTotalAmount || ''  
      };
  
      // Conditionally add 'Paid Invoices' if not hidden
      if (!_isColumnToHide("paidInvoicesCount")) {
        rowData.paidInvoicesTotalAmount = data.paidInvoicesTotalAmount || '';
      }
  
      // Conditionally add 'Payment Term' if not hidden
      if (!_isColumnToHide("paymentTermName")) {
        rowData.paymentTermName= data.paymentTermName || '';
      }
  
      return rowData;
    });
  
    exportExcel(dataList, `supplier_list${props.companyInfo?.storeName ? `_${props.companyInfo.storeName}` : ''}`);
  };
  

  const downloadMenu = () => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Download as
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item
          key="1"
          value="excel"
          onClick={()=>{
            if(!selectedItems?.length){
              showToasterMessage({
                messageType: "error",
                description: "Please select some registrations",
              });
            }
            exportSupplierData(selectedItems);
            }}
          // onClick={() => {
          //   const ids = [];
          //   selectedItems.forEach((obj) => {
          //     ids.push(obj.supplierId);
          //   });
          //   if (ids.length) {
          //     props.exportExcel({
          //       relationshipId: props.companyInfo.relationshipId,
          //       supplierIds: ids.toString(),
          //     });
          //   } else {
          //     const modalData = {
          //       title: (
          //         <div className="title">
          //           <i className="fa fa-ban mr15" />
          //           <FormattedMessage
          //             id="product.listing.validation.failed.label"
          //             defaultMessage=""
          //           />
          //         </div>
          //       ),
          //       modalBody: (
          //         <Fragment>
          //           <div className="warning-message">
          //             <FormattedMessage
          //               id="no.supplierselected"
          //               defaultMessage=""
          //             />
          //           </div>
          //           <div className="button-action">
          //             <Button
          //               className="ant-btn-default"
          //               onClick={() => props.popModalFromStack()}
          //             >
          //               <FormattedMessage id="ok" defaultMessage="" />{" "}
          //             </Button>
          //           </div>
          //         </Fragment>
          //       ),
          //       hideFooter: true,
          //       wrapClassName: "validation-failed-modal",
          //     };
          //     props.pushModalToStack(modalData);
          //   }
          // }}
        >
          <FileExcelOutlined />
          Excel File
        </Menu.Item>

        <Menu.Item
          key="2"
          value="pdf"
          onClick={() => {
            const supplierList = [];

            // selectedItems.forEach((data) => {
            //   let address =
            //     find(data.boLocationList, {
            //       isDefault: 1,
            //       locationType: "BillingAddress",
            //     }) || {};
            //   let contact =
            //     data.boContactList &&
            //     find(data.boContactList, { isPrimaryContact: 1 });
            //   supplierList.push({
            //     ...data,
            //     defaultAddress: address,
            //     defaultContact: contact,
            //   });
            // })

            selectedItems.forEach((data) => {
              // Find the default address if it exists, or return an empty object
              let address = find(data.boLocationList, {
                isDefault: 1,
                locationType: "BillingAddress",
              }) || {};
            
              // Find the primary contact if it exists, or return an empty object
              let contact = data.boContactList
                ? find(data.boContactList, { isPrimaryContact: 1 })
                : {};
            
            
              let supplierData = {
                ...data,
                defaultAddress: address,
                defaultContact: contact,
                paidInvoicesTotalAmount:0,
                paymentTermName:null
              };
            
              // Conditionally add values
              if (!_isColumnToHide("paidInvoicesCount")) {
                supplierData.paidInvoicesTotalAmount = data.paidInvoicesTotalAmount || '';
              }
          
              // Conditionally add 'Payment Term' if not hidden
              if (!_isColumnToHide("paymentTermName")) {
                supplierData.paymentTermName = data.paymentTermName || '';
              }
              // Push the resulting object to supplierList
              supplierList.push(supplierData);
            });
            if (supplierList.length) {
              props.generatePDF({
                companyInfo: props.companyInfo,
                fileName: "Suppliers.pdf",
                transactionName: "supplierListing",
                supplierList: supplierList,
              });
            } else {
              const modalData = {
                title: (
                  <div className="title">
                    <i className="fa fa-ban mr15" />
                    <FormattedMessage
                      id="product.listing.validation.failed.label" 
                      defaultMessage=""
                    />
                  </div>
                ),
                modalBody: (
                  <Fragment>
                    <div className="warning-message">
                      <FormattedMessage
                        id="no.supplierselected"
                        defaultMessage=""
                      />
                    </div>
                    <div className="button-action">
                      <Button
                        className="ant-btn-default"
                        onClick={() => props.popModalFromStack()}
                      >
                        {" "}
                        <FormattedMessage id="ok" defaultMessage="" />{" "}
                      </Button>
                    </div>
                  </Fragment>
                ),
                hideFooter: true,
                wrapClassName: "validation-failed-modal",
              };
              props.pushModalToStack(modalData);
            }
          }}
        >
          <FilePdfOutlined />
          PDF File
        </Menu.Item>
      </Menu>
    );
  };

  const sortColumn = (e) => {
    sortTable(
      document.getElementById("supplierList-table"),
      e.target.cellIndex,
      1 - dir
    );
    updateState({ dir: 1 - dir });
  };

  const checkMenu = (e) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item
          key="1"
          onClick={() => {
            selectAll(true);
          }}
        >
          <CheckCircleOutlined />
          Select all
        </Menu.Item>

        <Menu.Item
          key="2"
          onClick={() => {
            selectAll(false);
          }}
        >
          <CloseCircleOutlined />
          Unselect all
        </Menu.Item>

        {supplierPermissionData.delete && (
          <Menu.Item
            key="3"
            onClick={() => {
              const ids = [];
              selectedItems.forEach((obj) => {
                ids.push(obj.supplierId);
              });
              if (ids.length) {
                const modalData = {
                  modalBody: "Are you sure you want to delete supplier?",
                  handleSubmit: () => {
                    props.resetPaginatedData();
                    props.deleteSuppliers({
                      relationshipId: props.companyInfo.relationshipId,
                      groupSupplierIds: ids,
                    });
                    props.fetchSuppliers({
                      relationshipId: props.companyInfo.relationshipId,
                      pageNumber: props.pageNumber || 1,
                      pageSize: props.pageSize || 10,
                    });
                  },
                };
                props.pushModalToStack(modalData);
              } else {
                const modalData = {
                  title: (
                    <div className="title">
                      <i className="fa fa-ban mr15" />
                      <FormattedMessage
                        id="product.listing.validation.failed.label"
                        defaultMessage=""
                      />
                    </div>
                  ),
                  modalBody: (
                    <Fragment>
                      <div className="warning-message">
                        <FormattedMessage
                          id="supplier.listing.validation.failed.message"
                          defaultMessage=""
                        />
                      </div>
                      <div className="button-action">
                        <Button
                          className="ant-btn-default"
                          onClick={() => props.popModalFromStack()}
                        >
                          {" "}
                          <FormattedMessage id="ok" defaultMessage="" />
                        </Button>
                      </div>
                    </Fragment>
                  ),
                  hideFooter: true,
                  wrapClassName: "validation-failed-modal",
                };
                props.pushModalToStack(modalData);
              }
            }}
          >
            <i className={ICONS.DELETE} />
            Delete
          </Menu.Item>
        )}
      </Menu>
    );
  };


  return (
    <>
      <PageHeader {...props}
        pageName='heading.purchase.supplierList'
        breadCrumbList={breadCrumbList}
        canCreate={true}
        onCreate={newSupplier}
        moduleCode = {MODULE_CODED_VALUES.SUPPLIERS}
      
        />
      <Tile
        {...props}
        tilesData={tilesData}
        getSelectedTile={(data) => {
          openModalOfSelectedTile(data);
        }}
        className="p10"
      />
      <div className="view-container" style={{ height: "calc(100vh - 240px)" }}>

        <div className="view-container-actions">
          <Skeleton loading={props.headerLoading} active paragraph={false}>
            <div className="left-actions">
              <div className="table-heading">
                <FormattedMessage
                  id="purchase.supplierList.table.heading"
                  defaultMessage=""
                />
              </div>
              <div className="vertical-sep" />
              {supplierCount}
            </div>
            </Skeleton>
            <div className="right-actions">
              <Search
                placeholder="Enter 3 characters supplier name"
                value={searchedText}
                onChange={(e) => {
                  updateState({ searchedText: e.target.value, pageNumber: 1 });
                  onSearchedInputChange(e.target.value, props);
                }}
                onSearch={(searchedText) => {
                  updateState({ searchedText, pageNumber: 1 });
                  onSearchedInputChange(searchedText, props);
                  handleSearchedText(searchedText, props);
                }}
              />

              <Popover
                content={<AddAndRemoveSupplierListingColumn {...props} />}
                title="Add/Remove"
                placement="left"
                trigger="click"
                arrow="right"
                visible={props.showColPop}
              >
                <button
                  onClick={() => {
                    props.updateState({ showColPop: !props.showColPop });
                  }}
                >
                  <InsertRowRightOutlined className='mr5' />Columns
                </button>
              </Popover>

              <Dropdown overlay={downloadMenu()} trigger={["click"]}>
                <span>
                  <DownloadOutlined className="mr5 " />
                  <FormattedMessage
                    id="common.action.dropdown.downloadAs"
                    defaultMessage=""
                  />
                </span>
              </Dropdown>
              {supplierPermissionData.create && (

                <button onClick={handleMenuClick}>
                  <DownloadOutlined className="mr5" /> Import
                </button>
              )}
            </div>
    
        </div>

        <Skeleton loading={props.listLoading} active paragraph={{ rows: 11 }}>
          <div className="table-container">
            <table id="supplierList-table">
              <thead>
                <tr>
                  <th>
                    <div className="check-cell">
                      <Checkbox
                        onChange={(e) => {
                          selectAll(e.target.checked);
                        }}
                        checked={isCheckedAll()}
                      />
                      <DropdownAction overlay={checkMenu()} trigger={["click"]}>
                        <DownOutlined />
                      </DropdownAction>
                    </div>
                  </th>

                  {!_isColumnToHide("supplierStoreName") ? (
                    <th onClick={sortColumn}>
                      Supplier Name <i className={ICONS.SORT} />
                    </th>
                  ) : (
                    ""
                  )}
                  {!_isColumnToHide("contact") ? (
                    <th onClick={sortColumn}>
                      <FormattedMessage
                        id="supplier.listing.header.contact"
                        defaultMessage=""
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  <th onClick={sortColumn}>
                    Address
                  </th>
                  {!_isColumnToHide("totalAmountDue") ? (
                    <th onClick={sortColumn}>
                      <FormattedMessage
                        id="supplier.listing.header.dueAmount"
                        defaultMessage=""
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {!_isColumnToHide("openedOrdersCount") ? (
                    <th onClick={sortColumn}>
                      <FormattedMessage
                        id="supplier.listing.header.openOrder"
                        defaultMessage=""
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {!_isColumnToHide("unpaidInvoicesCount") ? (
                    <th onClick={sortColumn}>
                      <FormattedMessage
                        id="supplier.listing.header.unpaidInvoices"
                        defaultMessage=""
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {!_isColumnToHide("paidInvoicesCount") ? (
                    <th onClick={sortColumn}>
                      <FormattedMessage
                        id="supplier.listing.header.paidInvoices"
                        defaultMessage=""
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {!_isColumnToHide("paymentTermName") ? (
                    <th onClick={sortColumn}>
                      <FormattedMessage
                        id="supplier.listing.header.paymentTerm"
                        defaultMessage=""
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  <th>
                    Date Created
                  </th>
                  <th onClick={sortColumn}>
                    Status <i className={ICONS.SORT} />
                  </th>
                  {supplierPermissionData.update || supplierPermissionData.create || supplierPermissionData.delete ||addressPermissionData.read || contactPermissionData.read ? <th className="text-center">
                    <FormattedMessage id="actions.text" defaultMessage="" />
                  </th> : "" }
                </tr>
              </thead>
              <tbody>
                {suppliers && (suppliers[pageNumber] || []).length
                  ? suppliers[pageNumber].map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          <Checkbox
                            onClick={() => {
                              itemSelection(item);
                            }}
                            checked={
                              find(selectedItems, {
                                supplierId: Number(item.supplierId),
                              })
                                ? true
                                : false
                            }
                          />
                        </td>
                        {!_isColumnToHide("supplierStoreName") ? (
                          <td>{
                            <>
                              <div
                                className="link line-view"
                                onClick={() => {
                                  props.pushModalToStack({
                                    modalBody: (
                                      <SupplierDetails
                                        {...props}
                                        supplierId={item.supplierId}
                                        supplierName={item.supplierStoreName}
                                        supplier={item}
                                      />
                                    ),
                                    width: "100%",
                                    hideTitle: true,
                                    hideFooter: true,
                                    wrapClassName: "new-transaction-wrapper",
                                  });
                                }}>
                                {capitalizeFirstLetter(item.supplierStoreName) || <div className="empty-data-box"></div>}</div>
                              <div className="light-txt">
                                ID - {item.supplierId ||
                                  <div className="empty-data-box"></div>
                                }
                              </div>
                            </>
                          }</td>
                        ) : (
                          ""
                        )}
                        {!_isColumnToHide("contact") ? (
                          <td>{getSupplierContactCell(item)}</td>
                        ) : (
                          ""
                        )}
                        <td>
                          {(item.boLocationList || []).length ? (
                            <div style={{width:"145px"}}>
                              {getAddressInfoFragment(
                                (item.boLocationList || []).find(
                                  (obj) => obj.isDefault
                                )
                              )}
                            </div>
                          ) : (
                            <div className="empty-data"></div>
                          )}
                        </td>
                        {/* {!_isColumnToHide('totalAmountDue') ? <td> <p>{item.totalAmountDue ? (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " ") : ''}{(item.totalAmountDue || '') && item.totalAmountDue.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p> </td> : ''} */}
                        {!_isColumnToHide("totalAmountDue") ? (
                          <td>
                            <p className="line-view">
                              {item.totalAmountDue ? (
                                props.companyInfo.currencyIcon ? (
                                  <i
                                    className={props.companyInfo.currencyIcon}
                                  />
                                ) : (
                                  props.companyInfo.currencyCode + " "
                                )
                              ) : (
                                "0"
                              )}
                              {(item.totalAmountDue) &&
                                fixedDecimalAndFormateNumber(
                                  item.totalAmountDue
                                )}
                            </p>
                          </td>
                        ) : (
                          ""
                        )}
                        {!_isColumnToHide("openedOrdersCount") ? (
                          <td>
                            {
                              <div>
                                {item.openedOrdersCount &&
                                  item.openedOrdersCount === 1
                                  ? "Order " : item.openedOrdersCount ? "Orders " : ''}
                                <span
                                  onClick={() => {
                                  if (item.openedOrdersCount !== 0) {
                                    props.showModal({
                                      title: (
                                        <Fragment>
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                            }}
                                          >
                                            <div className="ant-modal-title">
                                              <FormattedMessage
                                                id="open.Orders"
                                                defaultMessage=""
                                              />{" "}
                                            </div>
                                            <div className="ant-modal-subtitle extra-sm">
                                              {item.supplierStoreName}
                                            </div>
                                          </div>
                                        </Fragment>
                                      ),
                                      modalBody: (
                                        <OpenOrder
                                          {...props}
                                          supplierData={item}
                                        />
                                      ),
                                      width: 1000,
                                      hideFooter: true,
                                      wrapClassName: "tiles-modal",
                                    });
                                  }
                                  }}
                                  className={
                                    item.openedOrdersCount
                                      ? "table-link-text"
                                      : ""
                                  }
                                >
                                  {" "}
                                  {item.openedOrdersCount}
                                </span>
                                {/* <p>{item.openedOrdersTotalAmount ? (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " ") : ''}{(item.openedOrdersTotalAmount || '') && item.openedOrdersTotalAmount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p> */}
                                <p className="line-view">
                                  {item.openedOrdersTotalAmount ? (
                                    props.companyInfo.currencyIcon ? (
                                      <i
                                        className={
                                          props.companyInfo.currencyIcon
                                        }
                                      ></i>
                                    ) : (
                                      props.companyInfo.currencyCode + " "
                                    )
                                  ) : (
                                    ""
                                  )}
                                  {(item.openedOrdersTotalAmount || "") &&
                                    fixedDecimalAndFormateNumber(
                                      item.openedOrdersTotalAmount
                                    )}
                                </p>
                              </div>
                            }
                          </td>
                        ) : (
                          ""
                        )}
                        {!_isColumnToHide("unpaidInvoicesCount") ? (
                          <td>
                            <div>
                              {" "}
                              {item.unpaidInvoicesCount &&
                                item.unpaidInvoicesCount === 1
                                ? "Invoice "
                                : item.unpaidInvoicesCount === 0
                                  ? ""
                                  : "Invoices "}
                              <span
                                onClick={() => {
                                  if (item.unpaidInvoicesCount !== 0) {

                                  props.showModal({
                                    title: (
                                      <Fragment>
                                        <div className="ant-modal-title">
                                          {" "}
                                          <FormattedMessage
                                            id="customer.listing.header.unpaidInvoices"
                                            defaultMessage=""
                                          />
                                        </div>
                                        <div className="ant-modal-subtitle extra-sm">
                                          {item.companyName}
                                        </div>
                                      </Fragment>
                                    ),
                                    modalBody: (
                                      <UnpaidInvoices
                                        supplierData={item}
                                        {...props}
                                      />
                                    ),
                                    width: 1000,
                                    hideFooter: true,
                                    wrapClassName: "tiles-modal",
                                  });
                                }
                                }}
                                className={
                                  item.unpaidInvoicesCount
                                    ? "table-link-text"
                                    : ""
                                }
                              >
                                {" "}
                                {item.unpaidInvoicesCount}
                              </span>
                              {/* <p> {item.unpaidInvoicesTotalAmount ? (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " ") : ''}{(item.unpaidInvoicesTotalAmount || '') && item.unpaidInvoicesTotalAmount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p> */}
                              <p className="line-view">
                                {item.unpaidInvoicesTotalAmount ? (
                                  props.companyInfo.currencyIcon ? (
                                    <i
                                      className={
                                        props.companyInfo.currencyIcon
                                      }
                                    ></i>
                                  ) : (
                                    props.companyInfo.currencyCode + " "
                                  )
                                ) : (
                                  ""
                                )}
                                {(item.unpaidInvoicesTotalAmount || "") &&
                                  fixedDecimalAndFormateNumber(
                                    item.unpaidInvoicesTotalAmount
                                  )}
                              </p>
                            </div>
                          </td>
                        ) : (
                          ""
                        )}
                        {!_isColumnToHide("paidInvoicesCount") ? (
                          <td>
                            <div>
                              {item.paidInvoicesCount &&
                                item.paidInvoicesCount === 1
                                ? "Invoice "
                                : item.paidInvoicesCount === 0
                                  ? ""
                                  : "Invoices "}
                              <span
                                onClick={() => {
                                  if (item.paidInvoicesCount !== 0) {

                                  props.showModal({
                                    title: (
                                      <Fragment>
                                        <div className="ant-modal-title">
                                          Paid Invoices
                                        </div>
                                        <div className="ant-modal-subtitle extra-sm">
                                          {item.companyName}
                                        </div>
                                      </Fragment>
                                    ),
                                    modalBody: (
                                      <PaidInvoices
                                        supplierData={item}
                                        {...props}
                                      />
                                    ),
                                    width: 900,
                                    hideFooter: true,
                                    wrapClassName: "tiles-modal paid-invoice",
                                  });
                                }
                                }}
                                className={
                                  item.paidInvoicesCount
                                    ? "table-link-text"
                                    : ""
                                }
                              >
                                {" "}
                                {item.paidInvoicesCount}
                              </span>
                              {/* <p> {item.paidInvoicesTotalAmount ? (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " ") : ''}{(item.paidInvoicesTotalAmount || '') && item.paidInvoicesTotalAmount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p> */}
                              <p className="line-view">
                                {" "}
                                {item.paidInvoicesTotalAmount ? (
                                  props.companyInfo.currencyIcon ? (
                                    <i
                                      className={
                                        props.companyInfo.currencyIcon
                                      }
                                    ></i>
                                  ) : (
                                    props.companyInfo.currencyCode + " "
                                  )
                                ) : (
                                  ""
                                )}
                                {(item.paidInvoicesTotalAmount || "") &&
                                  fixedDecimalAndFormateNumber(
                                    item.paidInvoicesTotalAmount
                                  )}
                              </p>
                            </div>
                          </td>
                        ) : (
                          ""
                        )}
                        {!_isColumnToHide("paymentTermName") ? (
                          <td>{item.paymentTermName || <div className="empty-data"></div>
                          }</td>
                        ) : (
                          ""
                        )}
                        <td>
                          {item.dateCreated
                            ? getMomentLoclDateForUIReadOnly({
                              date: item.dateCreated,
                            })
                            : <div className="empty-data"></div>

                          }
                        </td>
                        <td>
                          <div className="status-toggle">
                            <Switch
                              checked={item.isActive === 1 ? true : false}
                              disabled={!supplierPermissionData.update}
                              onClick={(checked, e) => {
                                props.updateStatus({
                                  supplierId: item.supplierId,
                                  relationshipId: item.relationshipId,
                                  active: checked ? 1 : 0
                                }, props)

                              }}
                            />
                          </div>
                        </td>
                      {supplierPermissionData.update || supplierPermissionData.create || supplierPermissionData.delete ||addressPermissionData.read || contactPermissionData.read ?
                        <td style={{ textAlign: "center" }}>
                          <Dropdown
                            overlay={actionMenu(item)}
                            trigger={["click"]}
                            overlayClassName="action-listing-dropdown"
                          >
                            <i className={ICONS.MORE_P} />
                          </Dropdown>
                        </td> : "" }
                      </tr>
                    );
                  })
                  :
                  <tr key="empty-data">
                    <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                      <Empty />
                    </td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </Skeleton>

        <Skeleton loading={props.headerLoading} active paragraph={false}>
          <div className="footer">
            <div className="f-left"></div>
            <div className="f-right">
              <Pagination
                size="small"
                total={supplierCount}
                showTotal={showTotal}
                defaultPageSize={props.pageSize}
                showSizeChanger
                pageSizeOptions={[10, 25, 50, 100, 200]}
                // showQuickJumper
                onChange={(pageNumber, pageSize) => {
                  loadPageData(pageNumber, pageSize);
                  updateState({ pageSize: pageSize, pageNumber: pageNumber });
                }}
                onShowSizeChange={(pageNumber, pageSize) => {
                  handlePageSizeChange(pageNumber || 1, pageSize, props);
                }}
              />
            </div>
          </div>
        </Skeleton>
      </div>
    </>
  );
};

const onSearchedInputChange = debounce((searchedText, props) => {
  handleSearchedText(searchedText, props);
}, 500);

const handleSearchedText = (searchedText, props) => {
  const payload = {
    relationshipId: props.companyInfo.relationshipId,
    pageNumber: 1,
    pageSize: props.pageSize,
    searchedText: searchedText,
  };
  if (searchedText.length) {
    //if (searchedText.length >= 3 && !props.searchedText.toLowerCase().startsWith(searchedText.toLowerCase())) {
    if (searchedText.length >= 3) {
      props.resetPaginatedData();
      props.getSupplierCount(payload);
      props.fetchSuppliers(payload);
    }
  } else {
    props.resetPaginatedData();
    props.getSupplierCount(payload);
    props.fetchSuppliers(payload);
  }
};
export default injectIntl(SuppliersListingComp);
