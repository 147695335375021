import React, { Fragment, Component } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { DropdownRef } from "../../../../general/Dropdown";
import { CustomButton } from "../../../../general/CustomButton";
import { Input, Checkbox, Tooltip } from "antd";
import { MODAL_TYPE } from "../../../../../static/constants";
import { showToasterMessage } from "../../../../../utils";

class TaxIdentificationComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFocus: false,
      taxIdentificationNumberDublicate:false,
      populateOnTransaction:props.data?.formData?.populateOnTransaction ?true:false
    };
  }
  addItemConfirmationModal = (props, text, modalType) => {
    const { pushModalToStack, companyInfo } = props;
    const labelName = (
      <FormattedMessage id="addItem.text.taxIdentification" defaultMessage="" />
    );
    const modalBody = (
      <span>
        <FormattedMessage
          id="addItem.confirmation"
          values={{ value: `${text} to ` }}
          defaultMessage={``}
        />
        {labelName} ?
      </span>
    );

    let addFunc;

    switch (modalType) {
      case MODAL_TYPE.TAX_IDENTIFICATION: {
        addFunc = props.addTaxIdentification;
        break;
      }
      default: {
        addFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        addFunc({ text: text, relationshipId: companyInfo.relationshipId });
        props.popModalFromStack();
      },
    };
    pushModalToStack(modalData);
  };

  getModalTitle = (modalType) => {
    let title;

    switch (modalType) {
      case MODAL_TYPE.TAX_IDENTIFICATION: {
        title = (
          <FormattedMessage
            id="addItem.text.taxIdentification"
            defaultMessage=""
          />
        );
        break;
      }
      default:
        title = (
          <FormattedMessage id="signup.confirmation.title" defaultMessage="" />
        );
    }
    return title;
  };

  handleNewItemAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    const { pushModalToStack } = props;

    if (
      formData.textBoxValue &&
      formData.textBoxValue.length &&
      !payload.existsInData
    ) {
      this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
    } else {
      const data = {
        title: this.getModalTitle(payload, modalType),
        formData,
        modalData: { modalType, data: payload },
        handleSubmit: (formData = {}) => {
          if (formData.textBoxValue && formData.textBoxValue.length) {
            this.addItemConfirmationModal(
              props,
              formData.textBoxValue,
              modalType
            );
          } else {
            data.formData.submittedOnce = true;
            props.popModalFromStack();
            pushModalToStack(data);
          }
        },
      };
      pushModalToStack(data);
    }
  };

  deleteClickHandler = (props, modalType, payload) => {
    const { pushModalToStack, companyInfo } = props;
    const modalBody = (
      <FormattedMessage
        id="deleteItem.confirmation"
        defaultMessage=""
        values={{ value: payload.text }}
      />
    );

    let deleteFunc;

    switch (modalType) {
      case MODAL_TYPE.TAX_IDENTIFICATION: {
        deleteFunc = props.deleteTaxIdentifications;
        break;
      }
      default: {
        deleteFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        deleteFunc({
          id: payload.id,
          relationshipId: companyInfo.relationshipId,
        });
        props.popModalFromStack();
      },
    };
    pushModalToStack(modalData);
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 24,
          offset: 0,
        },
      },
    };
    return (
      <Fragment>

        <Form
          labelAlign="left"
          {...formItemLayout}
          onSubmit={this.handleSubmit}
          hideRequiredMark={true}
        >
          <div className="modal-content employee-modal-control form-item-customize">
            {
              this.props?.data.formData?.isGstin ? "" : ( <Form.Item
                style={{ display: "flex", flexDirection: "column" }}
                label={
                  <span>
                    <FormattedMessage
                      id="modal.common.taxIdentification.taxType"
                      defaultMessage=""
                    />
                  </span>
                }
                colon={false}
              >
                {getFieldDecorator("type", {
                  initialValue: this.props.taxIdentificationType || "",
                  rules: [
                    {
                      required: true,
                      message: "Type is required.",
                    },
                  ],
                })(
                  <DropdownRef
                    items={this.props.allTaxIdentificatinType || []}
                    valueKeyName="type"
                    optionKeyName="taxIdentificationId"
                    placeholder="Select"
                    canAddNew={true}
                    canDelete={true}
                    deleteClickHandler={(payload) => {
                      this.deleteClickHandler(
                        this.props,
                        MODAL_TYPE.TAX_IDENTIFICATION,
                        payload
                      );
                    }}
                    onAddNewClick={(payload) => {
                      payload.formData = {
                        textBoxValue: payload.textEntered,
                        submittedOnce: false,
                        maxLength: 100,
                      };
                      this.handleNewItemAddition(
                        this.props,
                        payload,
                        MODAL_TYPE.TAX_IDENTIFICATION
                      );
                    }}
                    onSelect={(selectedValue, option) => {
                      this.props.form.setFieldsValue({ type: option.name });
                    }}
                  />
                )}
              </Form.Item>)
            }
           

            <Form.Item
              style={{ display: "flex", flexDirection: "column" }}
              label={
                <span>
                  <FormattedMessage
                    id="modal.common.taxIdentification.number"
                    defaultMessage=""
                  />
                </span>
              }
              colon={false}
            >
              {getFieldDecorator("taxIdentificationNumber", {
                initialValue: this.props.taxIdentificationNumber ,
                rules: [
                  {
                    required: true,
                    message: "Number is required.",
                    maxLength: this.props?.data.formData?.isGstin ? 15 : 100,
                  },
                ],
              })(
                <Input
                  placeholder={this.props.intl.formatMessage({
                    id: "modal.common.taxIdentification.number",
                    defaultMessage: "",
                  })}
                  maxLength={100}
                  onChange={(e) => {
                    this.setState({ taxIdentificationNumber: e.target.value });
                  }}
                  onFocus={() => {
                    this.setState({ isFocus: true });
                  }}
                  onBlur={() => {
                   const callback  = (val)=>{

                    this.setState({ taxIdentificationNumberDublicate: val });
                   }
                   const  payload={
                        relationshipId:this.props.companyInfo.relationshipId,
                        taxIdentificationNumber:this.state.taxIdentificationNumber 
                    }
                    if (
                      this.props.data?.formData?.customerId
                    ) {
                      if(this.props.preTypeValue !== payload.taxIdentificationNumber){

                        this.props.checktaxIdentificationNumber(payload,this.props,"customer",callback)
                      }else{
                        this.setState({ taxIdentificationNumberDublicate: false });
                      }
                    } else if (
                      this.props.data?.formData?.supplierId
                    ) {
                     
                      this.props.checktaxIdentificationNumber(payload,this.props,"supplier",callback)
                    }else{
                      this.props.checktaxIdentificationNumber(payload,this.props,"",callback)
                    }
                    this.setState({ isFocus: false });
                  }}
                />
              )}
            </Form.Item>
            {this.state.isFocus ? (
              <div className="custom-count">
                {(this.state.taxIdentificationNumber || "").length
                  ? this.state.taxIdentificationNumber.length +
                    " / " +
                    100 +
                    " characters"
                  : ""}
              </div>
            ) : null}
            <div style={{ marginTop: "15px" }}>
              <Form.Item
                label={
                  <>
                    <span className="">
                      <FormattedMessage
                        id="modal.common.taxIdentification.populateOnTransaction"
                        defaultMessage=""
                      />
                    </span>
                    <Tooltip
                      placement="top"
                      title={
                        <FormattedMessage id="modal.common.taxIdentification.populateOnTransaction.tooltip" />
                      }
                      trigger="click"
                    >
                      <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                    </Tooltip>
                  </>
                }
                colon={false}
              >
                {getFieldDecorator(
                  "populateOnTransaction",
                  {
                    initialValue:this.props.populateOnTransaction ? true :false
                  }
                )(<Checkbox 
                checked={this.state.populateOnTransaction}
                onChange={()=>{
                  this.setState({
                    populateOnTransaction:!this.state.populateOnTransaction
                  })
                }}
                className="login-form-checkbox">
                
                </Checkbox>)}
              </Form.Item>
            </div>
          </div>
          <Form.Item {...tailFormItemLayout} className="modal-footer">
            <div key={0} className="customModal-button-group">
              <CustomButton
                intlId={"modal.contact.button.cancel"}
                defaultMessage={""}
                type={"default"}
                key={1}
                onClick={() => {
                  this.props.hideModal();
                }}
              />
              <CustomButton
                intlId={"button.save.label"}
                defaultMessage={""}
                htmlType="submit"
                key={2}
              />
            </div>
          </Form.Item>
        </Form>
      </Fragment>
    );
  }

  handleSubmit = (e) => {
 
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if(this.state.taxIdentificationNumberDublicate){
        showToasterMessage({messageType: 'error',  description: ' Tax-Identification Number Exists!' })
      }
      if (!err && !this.state.taxIdentificationNumberDublicate) {
        const payload = {
          ...this.props.data,
          taxIdentificationType: values.type || "GSTIN",
          taxIdentificationNumber: values.taxIdentificationNumber,
          //dateCreated: getCurrentDateForBackend(new Date()),
          relationshipId: (this.props.companyInfo || {}).relationshipId,
          populateOnTransaction: values.populateOnTransaction ? 1 : 0,
          version:this.props.version ||0
        };
      
        if (
          this.props.data &&
          this.props.data.formData &&
          this.props.data.formData.customerId
        ) {
          payload.customerId = this.props.data.formData.customerId;
          payload.customerTaxIdentificationId = this.props.customerTaxIdentificationId
        } else if (
          this.props.data &&
          this.props.data.formData &&
          this.props.data.formData.supplierId
        ) {
          payload.supplierId = this.props.data.formData.supplierId;
        }
        else{
          payload.relationshipTaxIdentificationId = this.props.data.formData.relationshipTaxIdentificationId
        }
        const modalData = {       
          modalBody: "Are you sure you want to Submit?",
          handleSubmit: () => {
            if(this.state.taxIdentificationNumberDublicate){
              showToasterMessage({messageType: 'error',  description: ' Tax-Identification Number Exists!' })
            }
            if(!this.state.taxIdentificationNumberDublicate){
              this.props.createTaxIdentification(
                payload,
                (this.props.data || {}).formData,this.props
              );
              this.props.handleSubmit && this.props.handleSubmit();
            }
          },
        };
        this.props.pushModalToStack(modalData);
      }
    });
  };
}

export default Form.create({ name: "Tax_Identification_component" })(
  injectIntl(TaxIdentificationComponent)
);
