import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS, PRODUCT_ACTION_LIST } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const getInventoryItemByIds = (payload,props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.INVENTORY_BASE_URL}${config.API_VERSION}/${config.rootContextInventory}/inventoryItem/getAllMatchingInventoryItems?relationshipId=${
        payload.relationshipId
      }&inventoryItemCol=${[payload.inventoryItemCol]}`)
      .then(res => {
        dispatch({ type: PRODUCT_ACTION_LIST.PRODUCT_INVENTORY_ITEMBYID, data: res.data });
        // dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      
  
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = PRODUCT_ACTION_LIST.PRODUCT_INVENTORY_ITEMBYID;
        }
      })
  }
}