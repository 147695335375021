import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMMON_ACTIONS, STUDENT_REGISTRATION_ACTION_LIST, CONSTANTS } from '../../../../static/constants';
import { showToasterMessage, exportExcel, getMomentDateForUIReadOnly} from '../../../../utils';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchStudentRegistration = (payload, history) => {
 // let FY = getFinancialYear(payload.companyInfo);
  let uri = '';
  if (payload.searchedText && payload.searchedText.length) {
    uri = `/orgRegistration/searchMembers?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 25}&pageNumber=${(payload.pageNumber || 1) - 1}&formId=${payload.formId || 0}&searchString=${payload.searchedText}`;
  } else {
    uri = `/orgRegistration/getRegistrationList?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 25}&pageNumber=${(payload.pageNumber || 1) - 1}&formId=${payload.formId || 0}`
  }
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}${uri}`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: STUDENT_REGISTRATION_ACTION_LIST.STUDENT_REGISTRATION_LIST, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = STUDENT_REGISTRATION_ACTION_LIST.STUDENT_REGISTRATION_LIST;
          err.data = {
            pageNo: payload.pageNumber,
            list: []
          };
        }
      })
  }
}

export const getStudentRegistrationCount = (payload, history) => {
  //let FY = getFinancialYear(payload.companyInfo);
  let uri = '';
 if(payload.searchedText && payload.searchedText.length){
   uri = `/orgRegistration/countMatchingMembers?relationshipId=${payload.relationshipId}&formId=${payload.formId || 0}&searchString=${payload.searchedText}`;
 }else{
  uri = `/student/countStudentByFormId?relationshipId=${payload.relationshipId}&formId=${payload.formId || 0}`
 }
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}${uri}`)
      .then(res => {
        dispatch({ type: STUDENT_REGISTRATION_ACTION_LIST.STUDENT_REGISTRATION_COUNT, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = STUDENT_REGISTRATION_ACTION_LIST.STUDENT_REGISTRATION_COUNT;
        }
      })
  }
}
export const resetPaginatedData = () => {
  return dispatch => {
    dispatch({ type: STUDENT_REGISTRATION_ACTION_LIST.STUDENT_REGISTRATION_LIST_PAGE_RESET });
  }
}

export const deleteRegistration = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios.post(`${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/orgRegistration/delete`, payload)
      .then(response => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({ messageType: 'success', description: (response.data || {}).message || 'Deleted successfully' });
        resetPaginatedData()(dispatch);
        props.getCustomerCount(payload);
        props.fetchCustomers(payload);
        fetchStudentRegistration(payload)(dispatch);
        getStudentRegistrationCount(payload)(dispatch);
        getTotalParentsCount(payload)(dispatch);
      })
      .catch(err => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }
      })
  }
}

export const deleteStudent = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios.post(`${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/student/delete`, payload)
      .then(response => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({ messageType: 'success', description: (response.data || {}).message || 'Deleted successfully' });
        fetchStudentRegistration({ ...payload, formId: props.selectedFormId })(dispatch);
        getTotalStudentsCount({ ...payload, formId: props.selectedFormId })(dispatch);
      })
      .catch(err => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }
      })
  }
}

export const getTotalParentsCount = (payload, history) => {
  //let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/student/countStudentByFormId?relationshipId=${payload.relationshipId}&formId=${payload.formId || 0}`)
      .then(res => {
        dispatch({ type: STUDENT_REGISTRATION_ACTION_LIST.TOTAL_PARENTS_COUNT, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = STUDENT_REGISTRATION_ACTION_LIST.TOTAL_PARENTS_COUNT;
        }
      })
  }
}

export const getTotalStudentsCount = (payload, history) => {
  //let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/student/countStudent?relationshipId=${payload.relationshipId}&formId=${payload.formId || 0}`)
      .then(res => {
        dispatch({ type: STUDENT_REGISTRATION_ACTION_LIST.TOTAL_STUDENT_COUNT, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = STUDENT_REGISTRATION_ACTION_LIST.TOTAL_STUDENT_COUNT;
        }
      })
  }
}

export const getFormNames = (payload, history) => {
  //let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/registrationFormSetting/getFormSummary?relationshipId=${payload.relationshipId}&formId=${payload.formId || 0}&relEmpId=${payload.relationshipEmployeeId}&pageSize=100&pageNumber=1&registrationType=student`)
      .then(res => {
        dispatch({ type: STUDENT_REGISTRATION_ACTION_LIST.All_FORM_NAME, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = STUDENT_REGISTRATION_ACTION_LIST.All_FORM_NAME;
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      })
  }
}

export const getRegistrationDataForExcel = (payload, history) => {
  //let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/orgRegistration/getRegistrationDataForExcel?relationshipId=${payload.relationshipId}&pageSize=0&pageNumber=0&formId=${payload.formId || 0}`)
      .then(res => {
        const studentDetailsList = (res.data || []).map(item => {
          let obj = {
            'Father First Name': item['Father First Name'] || '',
            'Father Last Name': item['Father Last Name'] || '',
            'Father Email': item['Father Email'] || '',
            'Mother First Name': item['Mother First Name'] || '',
            'Mother Last Name': item['Mother Last Name'] || '',
            'Mother Email': item['Mother Email'] || '',
            'Student First Name': item['First Name'] || '',
            'Student Last Name': item['Last Name'] || '',
            'Grade': item['Grade'] || '',
            'Enrollment Date': getMomentDateForUIReadOnly({ date: item['Enrollment Date'], format: CONSTANTS.DISPLAY_DATE_FORMAT }),
            'Allergies': item['Allergies'] || '',
            'Date of Birth': item['Date of Birth'] || '',
            'Emergency Phone': item['Emergency Phone'] || '',
            'Father Mobile': item['Father Mobile'] || '',
            'Mother Mobile': item['Mother Mobile'] || '',
            'Emergency Contact Name': item['Emergency Contact Name'] || '',
          };
          let questionList = JSON.parse(item.questionList || '[]');
          (questionList|| []).map(queObj => {
            obj[queObj.questionText] = queObj.answers;
            return '';
          })
          return obj;
        })

        if (studentDetailsList.length) {
          exportExcel(studentDetailsList, 'RegisteredMembers');
        }
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }
      })
  }
}

export const updateRegistrationUserStatus = (payload, props) => {
  return dispatch => {

    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/orgRegistration/updateUserStatus`, payload)
      .then(res => {
        showToasterMessage({ messageType: 'success', description: 'User status updated' })
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        props.fetchStudentRegistration && props.fetchStudentRegistration(payload);
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }
      })
  }
}

export const updateStudentUserStatus = (payload, props) => {
  return dispatch => {

    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/student/updateUserStatus`, payload)
      .then(res => {
        showToasterMessage({ messageType: 'success', description: 'User status updated' })
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        props.getRegisterParentsData && props.getRegisterParentsData(payload);
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }
      })
  }
}