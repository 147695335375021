import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMMON_ACTIONS, PURCHASE_INVOICE_ACTION_LIST } from '../../../../static/constants';
import { getCurrentDateForBackend, formatDateForBackend, showToasterMessage, getFinancialYear } from '../../../../utils';
import { LEDGER_TYPE, ACCOUNTING_ENTRY_TYPE, PURCHASE_INVOICE_TYPE, TX_TYPE, TX_STATUS } from "../../../../static/constants";
import { resetPaginatedData, getPurchaseInvoicesCount } from '../Listing/action';
import * as filter from 'lodash.filter';
import * as find from 'lodash.find';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const createPurchaseInvoice = (payloadObject) => {
  const props = payloadObject.props;
  const invoiceData = _getInvoiceData(payloadObject);
  let formData = new FormData();
  if (props.fileList && props.fileList.length) {
    props.fileList.forEach((file) => {
      formData.append('files', file, file.name);
    })
  }
  formData.append('invoiceData', JSON.stringify(invoiceData, true));
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/supplierInvoice/createSupplierInvoice`, formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then(res => {
        props.openInvoiceDetails(res.data);
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        resetPaginatedData()(dispatch);
        getPurchaseInvoicesCount({ relationshipId: props.companyInfo.relationshipId })(dispatch);
        showToasterMessage({ messageType: 'success', description: 'Purchase invoice created successfully' })
        // props.history.replace({
        //   pathname: '/admin/purchase-invoice',
        //   state: {}
        // });
        props.poMasterId ? props.history.push('/admin/purchase-order-list') : props.history.push('/admin/purchase-invoice-list');
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' });
      })
  }
}

const _getInvoiceData = (payload) => {
  const props = payload.props;
  const state = payload.state;
  const gridObject = payload.gridObject;
  const isPlaceOfSupplySameAsRelationship = payload.isPlaceOfSupplySameAsRelationship;
  const companyInfo = props.companyInfo || {};
  const userInfo = props.userInfo || {};
  const financialYear = getFinancialYear(companyInfo);
  const oldPayload = props.update ? props.oldPayload || {} : {};
  const paymentTermObj = find((props.paymentTerms || []), { paymentTermId: Number(props.supplierPaymentTerm?.paymentTermId) }) || {};
  let invoiceMaster = {
    ...oldPayload,
    version: props.update ? props.version : 0,
    supplierInvoiceDraftId: props.supplierInvoiceDraftId || 0,
    supplierId: props.supplier && props.supplier.id,
    fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
    fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
    projectName: props.projectName,
    foreignCurrency: props.currencyCode + " ",
    foreignCurrencyIcon: props.currencyIcon,
    isMultiCurrency: 1,
    exchangeRate: props.exchangeRate,
    projectMasterId: props.projectMasterId,
    projectNumber: props.projectNumber,
    isCashInvoice: props.isCashPurchase ? 1 : 0,
    invoiceTotalAmount: state.total,
    invoiceType: PURCHASE_INVOICE_TYPE.STANDARD,
    supplierName: props.supplier && props.supplier.name,
    relationshipName: companyInfo && companyInfo.storeName,
    supplierGstNumber: props.supplier && props.supplier.gstNumber,
    supplierPanNumber: props.supplier && props.supplier.panNumber,
    placeOfSupply: props.placeOfSupply,
    status: TX_STATUS.OPEN,
    invoiceDate: props.invoiceDate && formatDateForBackend(props.invoiceDate),
    invoiceDueDate: props.invoiceDueDate && formatDateForBackend(props.invoiceDueDate),
    relationshipId: companyInfo.relationshipId,
    supplierInvoiceNumber: props.supplierInvoiceNumber,
    poMasterId: props.poMasterId,
    purchaseOrderId: props.purchaseOrderId,
    supplierPoNumber: props.supplierPo,
    referenceNumber: props.supplierRef,
    remarksSupplier: props.supplierRemarks,
    remarksInternal: props.internalRemarks,
    paymentTermId: paymentTermObj.paymentTermId,
    paymentTermName: paymentTermObj.termName,
    paymentTermDays: paymentTermObj.numberOfDays,
    //dateCreated: getCurrentDateForBackend(new Date()),
    createdByUserId: userInfo && userInfo.userId,
    createdByEmpId: userInfo && userInfo.relationshipEmployeeId,
    footer: props.footer,
    totalDiscountGiven: state.totalDiscount,
    txType: TX_TYPE.INVOICE,
    expenseId: props.expenseId,
    expenseName: props.expenseName,
    supplierInvoiceCOATxList: [],
    supplierInvoiceDetailsList: [],
    boLocationPurchaseInvoiceList: [],
    documentName: props.docName,
    rfqMasterId: props.rfqMasterId,
    rfqNumber: props.rfqNumber,
    supplierQuoteNumber: props.supplierQuoteNumber,
    isbankReco: props.isbankReco
  };

  invoiceMaster.supplierInvoiceCOATxList.push({
    relationshipId: companyInfo.relationshipId,
    relationshipName: companyInfo.storeName,
    exchangeRate: props.exchangeRate,
    fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
    fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
    supplierId: props.supplier && props.supplier.id,
    //dateCreated: getCurrentDateForBackend(new Date()),
    createdBy: userInfo.userId,
    amount: state.subtotalWithoutDiscount,
    txDate: formatDateForBackend(new Date()),
    txType: LEDGER_TYPE.TYPE_PURCHASE,
    accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
    ledgerAccountId: props.purchaseCreditorsLedgerId,
    supplierName: props.supplier && props.supplier.name
  });

  invoiceMaster.supplierInvoiceCOATxList.push({
    relationshipId: companyInfo.relationshipId,
    relationshipName: companyInfo.storeName,
    exchangeRate: props.exchangeRate,
    fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
    fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
    supplierId: props.supplier && props.supplier.id,
    //dateCreated: getCurrentDateForBackend(new Date()),
    createdBy: userInfo.userId,
    amount: state.subtotalWithoutDiscount,
    txDate: formatDateForBackend(new Date()),
    txType: LEDGER_TYPE.TYPE_PURCHASE,
    accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
    ledgerAccountId: props.purchaseLedgerId,
    supplierName: props.supplier && props.supplier.name
  });

  if (state.rootDiscountAmount) {
    invoiceMaster.supplierInvoiceCOATxList.push({
      relationshipId: companyInfo.relationshipId,
      relationshipName: companyInfo.storeName,
      exchangeRate: props.exchangeRate,
      fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
      fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
      supplierId: props.supplier && props.supplier.id,
      //dateCreated: getCurrentDateForBackend(new Date()),
      createdBy: userInfo.userId,
      amount: state.rootDiscountAmount,
      amountPercent: state.rootDiscountPercent,
      txDate: formatDateForBackend(new Date()),
      txType: LEDGER_TYPE.TYPE_DISCOUNT,
      accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
      ledgerAccountId: props.purchaseDiscountLedgerId,
      supplierName: props.supplier && props.supplier.name
    });

    invoiceMaster.supplierInvoiceCOATxList.push({
      relationshipId: companyInfo.relationshipId,
      relationshipName: companyInfo.storeName,
      exchangeRate: props.exchangeRate,
      fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
      fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
      supplierId: props.supplier && props.supplier.id,
      //dateCreated: getCurrentDateForBackend(new Date()),
      createdBy: userInfo.userId,
      amount: state.rootDiscountAmount,
      amountPercent: state.rootDiscountPercent,
      txDate: formatDateForBackend(new Date()),
      txType: LEDGER_TYPE.TYPE_DISCOUNT,
      accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
      ledgerAccountId: props.discountCreditorLedgerId,
      supplierName: props.supplier && props.supplier.name
    });
  }

  if (state.expenseAmount) {
    invoiceMaster.supplierInvoiceCOATxList.push({
      relationshipId: companyInfo.relationshipId,
      relationshipName: companyInfo.storeName,
      exchangeRate: props.exchangeRate,
      fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
      fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
      supplierId: props.supplier && props.supplier.id,
      //dateCreated: getCurrentDateForBackend(new Date()),
      createdBy: userInfo.userId,
      amount: state.expenseAmount,
      txDate: formatDateForBackend(new Date()),
      txType: LEDGER_TYPE.TYPE_EXPENSE,
      accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
      ledgerAccountId: props.purchaseExpenseCreditorLedgerId,
      supplierName: props.supplier && props.supplier.name
    });

    invoiceMaster.supplierInvoiceCOATxList.push({
      relationshipId: companyInfo.relationshipId,
      relationshipName: companyInfo.storeName,
      exchangeRate: props.exchangeRate,
      fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
      fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
      supplierId: props.supplier && props.supplier.id,
      //dateCreated: getCurrentDateForBackend(new Date()),
      createdBy: userInfo.userId,
      amount: state.expenseAmount,
      txDate: formatDateForBackend(new Date()),
      txType: LEDGER_TYPE.TYPE_EXPENSE,
      accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
      ledgerAccountId: props.purchaseExpenseLedgerId,
      supplierName: props.supplier && props.supplier.name
    });
  }

  if (props.relationshipBillingAddress && props.relationshipBillingAddress.streetAddress1) {
   // = getCurrentDateForBackend(new Date());
   // props.relationshipBillingAddress.dateUpdated = getCurrentDateForBackend(new Date());
    invoiceMaster.boLocationPurchaseInvoiceList.push(props.relationshipBillingAddress);
  }

  if (props.relationshipShippingAddress && props.relationshipShippingAddress.streetAddress1) {
    //props.relationshipShippingAddress.dateCreated = getCurrentDateForBackend(new Date());
   // props.relationshipShippingAddress.dateUpdated = getCurrentDateForBackend(new Date());
    invoiceMaster.boLocationPurchaseInvoiceList.push(props.relationshipShippingAddress);
  }

  if (props.supplierBillingAddress && props.supplierBillingAddress.streetAddress1) {
   // props.supplierBillingAddress.dateCreated = getCurrentDateForBackend(new Date());
   // props.supplierBillingAddress.dateUpdated = getCurrentDateForBackend(new Date());
    invoiceMaster.boLocationPurchaseInvoiceList.push(props.supplierBillingAddress);
  }

  gridObject.forEachNode((rowNode, index) => {
    const { data } = rowNode;

    if (!data.itemId && !data.description && !data.qty && !data.rate) {
      return
    }
    const itemId = data.selectedProductValue && data.selectedProductValue[0];
    // if(!itemId) return;
    let variantId = data.selectedProductValue && data.selectedProductValue.length === 2 ? data.selectedProductValue[1] : 0;
    if (data.productObj && !data.productObj.hasVariant && !variantId) {
      variantId = data.productObj.inventoryItemVariantList ? data.productObj.inventoryItemVariantList[0]?.variantId : data.inventoryItemVariantId;
    }
    let product = {};
    let itemLevelCoaTxList = [];
    if (itemId) {
      product = data.productObj || {};
    }
    let variant = {};
    if (variantId) {
      variant = data.variant || {};
    }

    let itemLevelAmount = ((data || {}).rate || 0) * ((data || {}).qty || 0);

    if (data.discount) {
      const discountOnItem = itemLevelAmount ? ((data.discount * 0.01) * itemLevelAmount) : 0;
      itemLevelAmount = itemLevelAmount - discountOnItem;
      itemLevelCoaTxList.push({
        relationshipId: companyInfo.relationshipId,
        relationshipName: companyInfo.storeName,
        exchangeRate: props.exchangeRate,
        fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
        fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
        supplierId: props.supplier && props.supplier.id,
        //dateCreated: getCurrentDateForBackend(new Date()),
        createdBy: userInfo.userId,
        amount: discountOnItem,
        amountPercent: data.discount,
        txDate: formatDateForBackend(new Date()),
        txType: LEDGER_TYPE.TYPE_DISCOUNT,
        accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
        ledgerAccountId: props.purchaseDiscountLedgerId,
        supplierName: props.supplier && props.supplier.name
      });

      itemLevelCoaTxList.push({
        relationshipId: companyInfo.relationshipId,
        relationshipName: companyInfo.storeName,
        exchangeRate: props.exchangeRate,
        fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
        fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
        supplierId: props.supplier && props.supplier.id,
        //dateCreated: getCurrentDateForBackend(new Date()),
        createdBy: userInfo.userId,
        amount: discountOnItem,
        amountPercent: data.discount,
        txDate: formatDateForBackend(new Date()),
        txType: LEDGER_TYPE.TYPE_DISCOUNT,
        accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
        ledgerAccountId: props.discountCreditorLedgerId,
        supplierName: props.supplier && props.supplier.name
      })
    }

    if (data.tax) {
      if (companyInfo.countryName !== 'India') {
        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          exchangeRate: props.exchangeRate,
          fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
          customerId: props.customer && props.customer.customerId,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount ? ((data.tax * 0.01) * itemLevelAmount) : 0).toFixed(2),
          amountPercent: Number(data.tax || 0),
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX_VAT,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
          ledgerAccountId: props.salesOutputTaxLedgerId,
          customerName: props.customer && props.customer.companyName
        });

        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          exchangeRate: props.exchangeRate,
          fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
          customerId: props.customer && props.customer.customerId,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount ? ((data.tax * 0.01) * itemLevelAmount) : 0).toFixed(2),
          amountPercent: Number(data.tax || 0),
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX_VAT,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
          ledgerAccountId: props.taxDebtorLedgerId,
          customerName: props.customer && props.customer.companyName
        });
      }
      else if (isPlaceOfSupplySameAsRelationship) {
        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          exchangeRate: props.exchangeRate,
          fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
          supplierId: props.supplier && props.supplier.id,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount ? (((data.tax) * 0.01) * itemLevelAmount)/2 : 0).toFixed(2),
          amountPercent: (data.tax)/2,
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX_CGST,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
          ledgerAccountId: props.taxCreditorsLedgerId,
          supplierName: props.supplier && props.supplier.name
        });

        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          exchangeRate: props.exchangeRate,
          fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
          supplierId: props.supplier && props.supplier.id,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount ? (((data.tax) * 0.01) * itemLevelAmount)/2 : 0).toFixed(2),
          amountPercent: (data.tax)/2,
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX_CGST,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
          ledgerAccountId: props.purchaseTaxLedgerId,
          supplierName: props.supplier && props.supplier.name
        });


        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
          supplierId: props.supplier && props.supplier.id,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          exchangeRate: props.exchangeRate,
          amount: (itemLevelAmount ? (((data.tax) * 0.01) * itemLevelAmount)/2 : 0).toFixed(2),
          amountPercent: (data.tax)/2,
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX_SGST,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
          ledgerAccountId: props.taxCreditorsLedgerId,
          supplierName: props.supplier && props.supplier.name
        });

        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
          supplierId: props.supplier && props.supplier.id,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          exchangeRate: props.exchangeRate,
          amount: (itemLevelAmount ? (((data.tax) * 0.01) * itemLevelAmount)/2 : 0).toFixed(2),
          amountPercent: (data.tax)/2,
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX_SGST,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
          ledgerAccountId: props.purchaseTaxLedgerId,
          supplierName: props.supplier && props.supplier.name
        });

      } else {
        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
          supplierId: props.supplier && props.supplier.id,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          exchangeRate: props.exchangeRate,
          amount: (itemLevelAmount ? ((data.tax * 0.01) * itemLevelAmount) : 0).toFixed(2),
          amountPercent: data.tax,
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX_IGST,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
          ledgerAccountId: props.taxCreditorsLedgerId,
          supplierName: props.supplier && props.supplier.name
        });

        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
          supplierId: props.supplier && props.supplier.id,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          exchangeRate: props.exchangeRate,
          amount: (itemLevelAmount ? ((data.tax * 0.01) * itemLevelAmount) : 0).toFixed(2),
          amountPercent: data.tax,
          taxId: data.taxId,
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX_IGST,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
          ledgerAccountId: props.purchaseTaxLedgerId,
          supplierName: props.supplier && props.supplier.name
        });
      }
    }

    invoiceMaster.supplierInvoiceDetailsList.push({
      version: props.update ? data.version : 0,
      relationshipId: companyInfo && companyInfo.relationshipId,
      supplierId: props.supplier && props.supplier.id,
      quantity: Number(data.qty),
      qtyOrderedFromPO: data.quantityOrdered,
      sNo: index + 1,
      sku: product.sku,
      uomName: data.uom,
      basePrice: Number(data.baseRate),
      specialDiscount: Number(data.specialDiscount),
      txDate: formatDateForBackend(props.invoiceDate),
      invoiceDate: formatDateForBackend(props.invoiceDate),
      discountPercentage: Number(data.discount),
      itemName: product.itemName,
      itemId: product.itemId,
      itemPurchasedPrice: Number(data.rate),
      rowHeight: data.scrollHeight,
      description: data.description,
      hsnCode: data.hsnCode,
      inventoryItemVariantId: variantId,
      supplierInvoiceCOATxList: itemLevelCoaTxList,
      attributeId1: variant.attributeId1,
      attributeId2: variant.attributeId2,
      attributeId3: variant.attributeId3,
      attributeName1: variant.attributeName1,
      attributeName2: variant.attributeName2,
      attributeName3: variant.attributeName3,
      attributeValue1: variant.attributeValue1,
      attributeValue2: variant.attributeValue2,
      attributeValue3: variant.attributeValue3,
      itemVariantName: data.itemVariantName
    });
  });

  let additionalInfo = {};

  if (props.selectedContact && props.selectedContact.firstName) {
    additionalInfo = { ...props.selectedContact }
    // additionalInfo.relationshipVatNumber = companyInfo.vatNumber;
    // additionalInfo.relationshipGstNumber = companyInfo.gstNumber;
    // additionalInfo.relationshipPanNumber = companyInfo.panNumber;
    additionalInfo.relationshipFaxNumber = companyInfo.faxNumber;
    additionalInfo.relationshipPhoneNumber = companyInfo.phoneNumber;
    additionalInfo.relationshipWebsite = companyInfo.website;
  }
  if (props.supplier) {
    additionalInfo.supplierId = props.supplier.id;
    //additionalInfo.gstNumber = props.supplier.gstNumber;
    // additionalInfo.panNumber = props.supplier.panNumber;
  }
  if (Object.keys(additionalInfo).length) {
    additionalInfo.relationshipId = companyInfo && companyInfo.relationshipId;
    additionalInfo.additionalInfoId = props.poMasterId ? 0 : additionalInfo.additionalInfoId;
  } else {
    additionalInfo = null;
  }

  if (props.selectedSupplierTaxIdentifications && props.selectedSupplierTaxIdentifications.length && props.supplier) {
    let selectedSupplierTaxIdentifications = filter((props.supplier.supplierTaxIdentificationsList || []), (taxObj) => {
      return props.selectedSupplierTaxIdentifications.indexOf(taxObj.supplierTaxIdentificationId) > -1;
    });
    additionalInfo.taxIdentifications = JSON.stringify(selectedSupplierTaxIdentifications);
  }
  if (props.selectedRelationshipTaxIdentifications && props.selectedRelationshipTaxIdentifications.length) {
    let selectedRelTaxIdentifications = filter(props.allRelationshipTaxIdentificatins, (taxObj) => {
      return props.selectedRelationshipTaxIdentifications.indexOf(taxObj.relationshipTaxIdentificationId) > -1;
    });
    additionalInfo.relationshipTaxIdentifications = JSON.stringify(selectedRelTaxIdentifications);
  }
  invoiceMaster.additionalInfoList = [additionalInfo];

  return invoiceMaster;
}



export const fetchPurchasedOrderForInvoice = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/purchaseOrder/getPODataForInvoice?poMasterId=${payload.poMasterId}&relationshipId=${payload.relationshipId}&supplierId=${payload.supplierId}`)
      .then(res => {
        const { data } = res;
        dispatch({ type: PURCHASE_INVOICE_ACTION_LIST.PURCHASE_INVOICE_PO_DETAIL, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = PURCHASE_INVOICE_ACTION_LIST.PURCHASE_INVOICE_PO_DETAIL;
        }
      })
  }
}



export const resetPoInvoiceData = () => {
  return dispatch => {
    dispatch({ type: PURCHASE_INVOICE_ACTION_LIST.RESET_PO_INVOICE_DATA });
  }
}

export const getNextTxNumber = (payload) => {
  let date = payload.date ? getCurrentDateForBackend(payload.date) : getCurrentDateForBackend(new Date());
  return dispatch => {
    dispatch({ type: PURCHASE_INVOICE_ACTION_LIST.NEXT_PURCHASE_INVOICE_NUMBER_RESET });
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/supplierInvoice/getNextInvoiceNumber?date=${date}&relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: PURCHASE_INVOICE_ACTION_LIST.NEXT_PURCHASE_INVOICE_NUMBER, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = PURCHASE_INVOICE_ACTION_LIST.NEXT_PURCHASE_INVOICE_NUMBER;
        }
      })
  }
}

export const createPurchaseInvoiceDraft = (payloadObject) => {
  const props = payloadObject.props;
  const invoiceData = _getInvoiceData(payloadObject);
  return dispatch => {
    const data = {
      supplierInvoiceDraftId: props.supplierInvoiceDraftId || 0,
      supplierInvoiceData: JSON.stringify(invoiceData),
      relationshipId: invoiceData.relationshipId,
      supplierId: invoiceData.supplierId
    }

    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/supplierInvoice/saveOrUpdateDraft`, data)
      .then(res => {
        showToasterMessage({ messageType: 'success', description: 'Purchase Invoice saved in draft' })
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        props.history.push({
          pathname: '/admin/purchase-invoice-list',
          state: { activeTab: 'Draft' }
        })
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }
      })
  }
}

export const fetchIDForInvoice = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/inboundDelivery/getInboundDeliveryDataForInvoice?inboundDeliveryMasterId=${payload.inboundDeliveryMasterId}&relationshipId=${payload.relationshipId}&supplierId=${payload.supplierId}`)
      .then(res => {
        const { data } = res;
        dispatch({ type: PURCHASE_INVOICE_ACTION_LIST.ID_INVOICE_DATA, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = PURCHASE_INVOICE_ACTION_LIST.ID_INVOICE_DATA;
        }
      })
  }
}



export const resetIDInvoiceData = () => {
  return dispatch => {
    dispatch({ type: PURCHASE_INVOICE_ACTION_LIST.RESET_ID_INVOICE_DATA });
  }
}