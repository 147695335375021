import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS, SALES_INVOICE_ACTION_LIST } from '../../../../../static/constants';
import { showToasterMessage, getFinancialYear } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchAvailableCreditList = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/creditMemo/getCreditMemosByCustomerId?relationshipId=${payload.relationshipId}&customerId=${payload.customerId}&pageSize=500&pageNumber=0&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
      .then(res => {
        dispatch({ type: SALES_INVOICE_ACTION_LIST.AVAILABLE_CREDIT_LIST, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = SALES_INVOICE_ACTION_LIST.AVAILABLE_CREDIT_LIST;
          err.data = [];
        }
      })
  }
}

  export const resetAvailableCreditList = () => {
    return dispatch => {
      dispatch({ type: SALES_INVOICE_ACTION_LIST.AVAILABLE_CREDIT_LIST_RESET });
    }
  }


export const applyCredits = (payload, props) => {
  return dispatch => {

    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/creditMemo/applyCredits`, payload)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        const payload = {
          companyInfo: props.companyInfo,
          relationshipId: props.companyInfo.relationshipId,
          userId: (props.userInfo || {}).userId,
          pageNumber: 1,
          pageSize: 25
        };
        props.fetchSalesInvoices(payload);
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }
      })
  }
}