import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS } from '../../../static/constants'
import { showToasterMessage } from '../../../utils';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const applyUserPlans = (loginData) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.USERS_BASE_URL}${config.API_VERSION}/${config.rootContextUsers}/userPlan/applyUserPlans?relationshipId=${loginData.relationshipId}&clientUserAccountId=${loginData.clientUserAccountId}&planCode=${loginData.planCode}&addons=${loginData.addons}`)
        .then(res => {
          showToasterMessage({
            description: (res || {}).message || 'Plan Applied successfully.',
            messageType: 'success'
          });
        })
        .catch(err => {
          showToasterMessage({ description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        })
    }
}

export const getAllPricingPlans = (payload) => {
    return dispatch => {
  
      return axios
    //   .get(`${config.USERS_BASE_URL}${config.API_VERSION}/${config.rootContextUsers}/userPlan/getAllPricingPlans`,payload)
        .get("http://localhost:8080/alpide/userPlan/getAllPricingPlans")
        .then(response => {
          
          dispatch({ type: COMMON_ACTIONS.PRICING_PLANS_LIST, data:response.data });
        })
        .catch(err => {

          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          
        })
    }
  }

  export const getUserCountry = () => {
    return dispatch => {
      return axios
        .get('https://ipapi.co/json/')
        .then(response => {
          
          dispatch({ type: COMMON_ACTIONS.USER_LOCATION, data:response.data });
        })
        .catch(err => {
            console.log("error in fetching user location")          
        })
    }
  };