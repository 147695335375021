import React, { Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Drawer, Col, Row, DatePicker } from 'antd';
import { DropdownRef } from '../../general/Dropdown';
import './supplierDrawer.scss';
import { CustomButton } from '../../general/CustomButton';
import { TextBox } from '../../general/TextBox';
import { Dropdown } from '../../general/Dropdown';
import { ErrorMsg } from '../../general/ErrorMessage';
import SupplierTab from './SupplierTab';
import { MODAL_TYPE, CONSTANTS } from '../../../static/constants';
import { getLabelName } from '../../modal/modalBody/customerInfo';
import { validateName,validateEmail, showToasterMessage, getMomentDateForUI, validateWebsite } from '../../../utils';
import { checkCodeRanges } from '../../general/stateCode';


function SupplierDrawer(props) {
  const {
    supplierStoreName, paymentTerms, supplierSources, industries, paymentTermName, supplierSource, industryName,
    supplierType, businessTypes, webSite, intl, updateDrawerState, submittedOnce, invalidCompanyNameClass, supplierPhone ,supplierEmail,contact
  } = props;
  // const newSupplier = 0;

  const format = CONSTANTS.DISPLAY_DATE_FORMAT;

  const closeDrawer = () => {
    props.updateState({
      supplier: {
        supplierDrawerVisible: false
      }
    });
  }

//   const hasDuplicateTaxIdentificationNumber = (supplier) => {
//     if (!supplier || !supplier.taxIdentificationNumber) {
//         return false;
//     }

//     const taxId = supplier.taxIdentificationNumber;
//     let hasDuplicate = false;
//     for (let i = 0; i < props.supplierTaxIdentificationsList.length; i++) {
//         if (props.supplierTaxIdentificationsList[i].taxIdentificationNumber === taxId) {
//             hasDuplicate = true;
//             break;
//         }
//     }
//     return hasDuplicate;
// };
  const handleSubmit = (payload) => {
      if (!payload.submittedOnce) {
        payload.updateDrawerState({ submittedOnce: true });
      }
      
      // if(payload.contact.emailAddress && !validateEmail(payload.contact.emailAddress)){
      //   return showToasterMessage({messageType: 'error', description: "Please enter correct email"});
      // }
      if(payload.supplierEmail){
        if (
        !validateEmail(payload.supplierEmail) 
      ) {
        return showToasterMessage({ messageType: 'error', description: "Please enter correct email" });
      }
      }
      if(payload.webSite){
        if (
        !validateWebsite(payload.webSite) 
      ) {
        return showToasterMessage({ messageType: 'error', description: "Please enter correct website" });
      }
      }
      var billingAddress = payload.billingAddress;
      var shippingAddress = payload.shippingAddress;
      var invalidBillingAddress = false;
      var invalidShippingAddress = false;
    
      if((billingAddress.streetAddress1 || billingAddress.cityName || billingAddress.stateName) && (!billingAddress.streetAddress1 || !billingAddress.cityName || (billingAddress.countryName === 'India' && !billingAddress.stateName))){
        invalidBillingAddress = true;
      }

      if(payload?.billingAddress?.countryName ==="India" && billingAddress?.zipCode && checkCodeRanges(billingAddress?.stateName , billingAddress?.zipCode , true)===false){
       return showToasterMessage({ messageType: 'error', description: "Please enter correct zipcode" });
     }
      // if(!payload.paymentTermId){
      //   return;
      // }
    
      // if(payload.taxIdentificationNumberDublicate || props.duplicateNumber){
      //   return showToasterMessage({ messageType: 'error', description: "Duplicate value" });
      // }
     
    
      if((shippingAddress.streetAddress1 || shippingAddress.cityName || shippingAddress.stateName) && (!shippingAddress.streetAddress1 || !shippingAddress.cityName || (shippingAddress.countryName === 'India' && !shippingAddress.stateName ))){
        invalidShippingAddress = true;
      }
   

  if (props.gridApi) {
    const uniqueValues = [];
    let hasDuplicate = false;
  
    props.gridApi.forEachNode((rowNode, index) => {
      const { data } = rowNode;
     
      if (data && data.taxIdentificationNumber) {
        if (uniqueValues.includes(data.taxIdentificationNumber)) {
          hasDuplicate = true;
          return;
        } else {
          uniqueValues.push(data.taxIdentificationNumber);
        }
      }
    });
  
    if (hasDuplicate) {
      return showToasterMessage({ messageType: 'error', description: "Duplicate value" });
    }
  }
  
      if (payload && payload.supplierStoreName && (validateName(payload.supplierStoreName)) && !invalidBillingAddress && !invalidShippingAddress) {
        const modalData = {
          title:"Confirmation",
          modalBody: payload.isUpdate ? "Are you sure you want to save ?" : <FormattedMessage id='drawer.supplier.confirmation.text' defaultMessage=''/>,
          handleSubmit: () => {
            payload.createOrUpdateSupplier(payload);
            payload.hideModal();
          }
        };
        payload.showModal(modalData);
      } else {
        payload.updateDrawerState({
          // invalidCompanyNameClass: (validateName(payload.supplierStoreName)) ? '' : 'input-text-error',
          invalidBilligCityClass: invalidBillingAddress && !billingAddress.cityName ? 'input-text-error' : '',
          invalidBilligStreet1Class: invalidBillingAddress && !billingAddress.streetAddress1 ? 'input-text-error' : '',
          invalidBilligStateClass: invalidBillingAddress && !billingAddress.stateName ? 'input-text-error' : '',
          invalidShippingCityClass: invalidShippingAddress && !shippingAddress.cityName ? 'input-text-error' :'',
          invalidShippingStreet1Class: invalidShippingAddress && !shippingAddress.streetAddress1 ? 'input-text-error' :'',
          invalidShippingStateClass: invalidShippingAddress && !shippingAddress.stateName ? 'input-text-error' :'',
         });
      }
    }
  return (
    <Fragment>
      <Drawer
        title={
         props.isUpdate ? "Edit Supplier"
          :
          <FormattedMessage id='drawer.addNew.supplier.title' defaultMessage='' />
        }
        width={720}
        zIndex={1}
        maskClosable={false}
        keyboard={false}
        onClose={closeDrawer}
        className="custom-drawer"
        afterVisibleChange={
          (visible) => {
            if (visible) {
              props.initCalls();
            }
          }
        }
        visible={props.supplierDrawerVisible}
        footer={
          <>
          <CustomButton
            intlId='confirmation.cancel'
            defaultMessage=''
            type='default'
            key={0}
            onClick={closeDrawer}
          />
          <CustomButton
            intlId='confirmation.submit'
            loading={props.saveLoading}
            defaultMessage=''
            htmlType='submit'
            onClick={() => handleSubmit(props)}
            key={1}
          />
          </>
        }
      >
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div className="i-label">
                <FormattedMessage id='drawer.supplier.companyName' defaultMessage='' />
                <span className="required">*</span>
              </div>
              <TextBox
                type='text'
                maxLength={45}
                style={{height:"38px"}}
                value={supplierStoreName}
                onChange={(e) => {
                  updateDrawerState({
                    supplierStoreName: e.target.value,
                    isDuplicateStoreName: false,
                    
                  });
                }}
                placeholder={intl.formatMessage(
                  { id: 'drawer.supplier.companyName.placeholder', defaultMessage: '' }
                )}
                className={`${invalidCompanyNameClass} ${submittedOnce && !validateName(supplierStoreName) ? 'input-text-error' : 'border-box'}`}
                onBlur = {(e)=>{
                  // props.isDuplicateSupplier({relationshipId : props.companyInfo.relationshipId, supplierName : props.supplierStoreName}, props)
                }}
              />
              <ErrorMsg
              validator={() => { return !submittedOnce || !!supplierStoreName  }}
              message={intl.formatMessage(
                { id: 'drawer.supplier.validation.supplier.name', defaultMessage: '' }
              )}/>
            </Col>

            <Col span={12}>
              <div className="i-label">
                <FormattedMessage id='drawer.supplier.paymentTerm' defaultMessage='' />
                {/* <span className="required">*</span> */}
              </div>
         
              <Dropdown             
                items={(paymentTerms || []).sort((a, b) => a.termName.localeCompare(b.termName))}
                optionKeyName='paymentTermId'
                valueKeyName='termName'
                value={paymentTerms.length ? (paymentTermName || props.paymentTermId) : undefined}
                placeholder={intl.formatMessage(
                  { id: 'common.select.placeholder', defaultMessage: '' }
                )}
                canDelete={true}
                canAddNew={true}
                deleteClickHandler={(payload) => {
                  deleteClickHandler(props, MODAL_TYPE.PAYMENT_TERM, payload);
                }}
                onAddNewClick={(payload) => {
                  payload.formData = {
                    termName: payload.textEntered,
                    days: null,
                    description: '',
                    isDefaultPayment: false,
                    submittedOnce: false
                  }
                  handleNewPaymentTermAddition(props, payload, MODAL_TYPE.PAYMENT_TERM)
                }}
                onSelect={
                  (paymentTermId, obj) => {
                    updateDrawerState({
                      paymentTermId,
                      paymentTermName: obj.name
                    })
                  }
                }
                allowClear={true}
                onClear={() => {
                  updateDrawerState({ paymentTermId: undefined, paymentTermName: undefined })
                }}
              />
              {/* <ErrorMsg
                validator={() => { return !submittedOnce || !!(paymentTermName || props.paymentTermId)  }}
                message={intl.formatMessage(
                  { id: 'drawer.customer.validation.paymentTerm', defaultMessage: '' }
                )}
              /> */}
            </Col>
            <Col span={12}>
                  <div className="i-label">
                    <FormattedMessage id="contact.email" />
                   
                  </div>
                  <TextBox
                    placeholder={intl.formatMessage({
                      id: "drawer.customer.contact.email.placeholder",
                      defaultMessage: "",
                    })}
                    type="text"
                    maxLength={50}
                    countStyle={{ top: "43px" }}
                    className={props.invalidEmailClass}
                    // validator={(val) => { return validateEmail(val) || !val
                    //   ? ""
                    //   : "input-text-error"; }}
                    // className="textbox-pd"
                    value={supplierEmail}
                    onChange={(e) => {
                      updateDrawerState({
                        supplierEmail: e.target.value ,
                        invalidEmailClass:
                          validateEmail(e.target.value) || !e.target.value
                            ? ""
                            : "input-text-error",
                      });
                    }}
                  />
             
                  


                </Col>

                <Col span={12}>
                  <div className="i-label">
                    Mobile Number
                  </div>
                  <div style={{ display: "flex" }}>

                    {/* <Dropdown

                      style={{ width: "20%" }}
                      items={filteredObjects || []}
                      valueKeyName="countryCallingCode"
                      optionKeyName="countryId"

                      // value={filteredObjects.countryCallingCode || props.companyInfo.countryCallingCode
                      //   ? "+" + props.companyInfo.countryCallingCode
                      //   : "-"}
                      value={props.companyInfo.countryCallingCode
                          ? "+" + props.companyInfo.countryCallingCode
                          : "-"}
                      onSelect={(e) => {
                         updateDrawerState({
                          contact: { ...contact, cellPhone: e.target.value },
                        });
                        props.fetchCountries({
                          countryId: e,
                          relationshipId: (props.companyInfo || {}).relationshipId,
                        });

                      }}

                    /> */}
                    <DropdownRef
                      style={{ width: "20%" }}
                      items={
                        (props.countries || []).filter((el) => {
                          return el.countryCallingCode != null;
                        }) || []
                      }
                      optionKeyName="countryId"
                      valueKeyName="countryCallingCode"
                      defaultValue={props.countryCallingCode}
                      className="currency-tags"
                      onSelect={(selectedValue, option) => {
                        const selectedCountry = props.countries.find((el) => el.countryId === selectedValue);

                        if (selectedCountry) {
                          updateDrawerState({
                            countryCallingCode: selectedCountry.countryCallingCode,
                            contact: { ...contact, countryCallingCode: selectedCountry.countryCallingCode },
                          });
                        }

                      }}
                    />
                    <TextBox
                      className="textbox-pd"
                      maxLength={10}
                      // type="number"
                      countStyle={{ top: "43px" }}
                      value={supplierPhone}
                      placeholder={intl.formatMessage({
                        id: "drawer.customer.contact.mobile.placeholder",
                        defaultMessage: "",
                      })}
                      // onChange={(e) => {
                      //   const re = /^[0-9\b]+$/;
                      //   if (e.target.value === "" || re.test(e.target.value)) {
                      //     updateDrawerState({
                      //       supplierPhone: e.target.value ,
                      //     });
                      //   }
                      // }}
                      onChange={(e) => {
                        let value = (e.target.value).replace(/[^0-9]/g, '');
                        updateDrawerState({
                          supplierPhone: value,
                        });
                      }}
                    />
                  </div>

                  {/* <LabelBox
                  labelValue={
                    props.companyInfo.countryCallingCode
                      ? "+" + props.companyInfo.countryCallingCode
                      : "-"
                  }
                  labelStyle={{ marginRight: "-41px" }}
                  placeholder={intl.formatMessage({
                    id: "drawer.customer.contact.mobile.placeholder",
                    defaultMessage: "",
                  })}
                  type="text"
                  maxLength={10}
                  value={contact.cellPhone}
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      updateDrawerState({
                        contact: { ...contact, cellPhone: e.target.value },
                      });
                    }
                  }}
                /> */}
                  
                </Col>

            <Col span={12}>
              <div className='i-label'>
                <FormattedMessage id='drawer.supplier.supplierType' defaultMessage='' />
              </div>
              <Dropdown
                items={(businessTypes || []).sort((a, b) => a.businessType.localeCompare(b.businessType))}
                valueKeyName='businessType'
                value={props.supplierTypeId}
                optionKeyName='businessTypeId'
                onSelect={
                  (supplierTypeId, obj) => {
                    updateDrawerState({
                      supplierTypeId,
                      supplierType: obj.name
                    });
                  }}
                canAddNew={true}
                canDelete={true}
                deleteClickHandler={(payload) => {
                  deleteClickHandler(props, MODAL_TYPE.SUPPLIER_TYPE, payload);
                }}
                onAddNewClick={(payload) => {
                  payload.formData = {
                    textBoxValue: payload.textEntered,
                    submittedOnce: false
                  }
                  handleNewItemAddition(props, payload, MODAL_TYPE.SUPPLIER_TYPE)
                }}
                placeholder={intl.formatMessage(
                  { id: 'common.select.placeholder', defaultMessage: '' }
                )}
                allowClear={true}
                onClear={() => {
                  updateDrawerState({ supplierTypeId: undefined, supplierType: undefined })
                }}
              />
            </Col>

            <Col span={12}>
              <div className='i-label'>
                <FormattedMessage id='drawer.supplier.websiteUrl' defaultMessage='' />
              </div>
              <TextBox
                maxLength={75}
                type='text'
                value={webSite}
                placeholder={intl.formatMessage(
                  { id: 'drawer.supplier.websiteUrl.placeholder', defaultMessage: '' }
                )}
          
                onChange={(e) => {
                  updateDrawerState({
                    webSite: e.target.value,
                  
                  });
                }}
              />
            </Col>

            <Col span={12}>
              <div className='i-label'>
                <FormattedMessage id='drawer.supplier.supplierSource' defaultMessage='' />
              </div>
              <Dropdown
                items={(supplierSources || []).sort((a, b) => a.supplierSourceName.localeCompare(b.supplierSourceName))}
                valueKeyName='supplierSourceName'
                optionKeyName='supplierSourceId'
                value={supplierSources.length ? (supplierSource || props.supplierSourceId) : undefined}
                canAddNew={true}
                canDelete={true}
                deleteClickHandler={(payload) => {
                  deleteClickHandler(props, MODAL_TYPE.SUPPLIER_SOURCE, payload);
                }}
                onAddNewClick={(payload) => {
                  payload.formData = {
                    textBoxValue: payload.textEntered,
                    submittedOnce: false
                  }
                  handleNewItemAddition(props, payload, MODAL_TYPE.SUPPLIER_SOURCE)
                }}
                placeholder={intl.formatMessage(
                  { id: 'common.select.placeholder', defaultMessage: '' }

                )}
                onSelect={
                  (supplierSourceId) => {
                    updateDrawerState({
                      supplierSourceId
                    });
                  }
                }
                allowClear={true}
                onClear={() => {
                  updateDrawerState({ supplierSourceId: undefined })
                }}
              />
            </Col>

            <Col span={12}>
              <div className='i-label'>
                <FormattedMessage id='drawer.supplier.relationshipSince' defaultMessage='' />
              </div>
              <DatePicker
                getPopupContainer={trigger => trigger.parentNode}
                format={format}
                key={`${props.relationshipSince ? getMomentDateForUI({date: new Date(props.relationshipSince), format}) : null}`}
                defaultValue={props.relationshipSince ? getMomentDateForUI({date: new Date(props.relationshipSince), format}) : null}
                onChange={(selectedDate) =>{
                  updateDrawerState({
                    relationshipSince:selectedDate
                  })
                }}
                placeholder={intl.formatMessage(
                  { id: 'drawer.supplier.relationshipSince.placeholder', defaultMessage: '' }
                )}
              />
            </Col>

            <Col span={12}>
              <div className='i=label'>
                <FormattedMessage id='drawer.supplier.industry' defaultMessage='' />
              </div>
              <Dropdown
                items={(industries || []).sort((a, b) => a.industryName.localeCompare(b.industryName))}
                valueKeyName='industryName'
                value={industries.length ?  (industryName || props.businessIndustryId) : undefined}
                optionKeyName='businessIndustryId'
                canAddNew={true}
                canDelete={true}
                deleteClickHandler={(payload) => {
                  deleteClickHandler(props, MODAL_TYPE.INDUSTRY, payload);
                }}
                onAddNewClick={(payload) => {
                  payload.formData = {
                    textBoxValue: payload.textEntered,
                    submittedOnce: false
                  }
                  handleNewItemAddition(props, payload, MODAL_TYPE.INDUSTRY)
                }}
                onSelect={
                  (businessIndustryId, obj) => {
                    updateDrawerState({
                      businessIndustryId,
                      industryName: obj.name
                    });
                  }
                }
                placeholder={intl.formatMessage(
                  { id: 'common.select.placeholder', defaultMessage: '' }
                )}
                allowClear={true}
                onClear={() => {
                  updateDrawerState({ businessIndustryId: undefined, industryName: undefined  })
                }}
              />
            </Col>
          </Row>

          <SupplierTab {...props} />
      </Drawer>
    </Fragment>
  );
}

const handleNewPaymentTermAddition = (props, payload) => {
  const formData = payload.formData;
  const { showModal, hideModal, companyInfo } = props;
  formData.relationshipId = companyInfo.relationshipId;

  const data = {
    title: <FormattedMessage id='modal.paymentTerm.heading' defaultMessage='' />,
    formData,
    hideFooter: true,
    modalData: { modalType: MODAL_TYPE.PAYMENT_TERM, data: payload },
    handleSubmit: (formData = {}) => {
      if (formData.termName && formData.termName.length
        && formData.days !== 0 && formData.days) {
        formData.relationshipId = (props.companyInfo || {}).relationshipId;
        props.addPaymentTerm(formData);
        hideModal();
      } else {
        data.formData.submittedOnce = true;
        showModal(data);
      }
    }
  };
  showModal(data);
}

const handleNewItemAddition = (props, payload, modalType) => {
  const formData = payload.formData;
  const { showModal,hideModal } = props;

  if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
    addItemConfirmationModal(props, formData.textBoxValue, modalType);
  } else {
    const data = {
      title: getModalTitle(payload, modalType),
      formData,
      modalData: { modalType, data: payload },
      handleSubmit: (formData = {}) => {
        if (formData.textBoxValue && formData.textBoxValue.length) {
          hideModal();
          addItemConfirmationModal(props, formData.textBoxValue, modalType);
        } else {
          data.formData.submittedOnce = true;
          showModal(data);
        }
      }
    };
    showModal(data);
  }
}

const addItemConfirmationModal = (props, text, modalType) => {
  const { showModal, companyInfo } = props;
  const labelName = getLabelName(modalType);
  const modalBody = <span>
    <FormattedMessage
      id='addItem.confirmation'
      values={{ value: `${text} to ` }}
      defaultMessage=''
    />
    {labelName} ?
  </span>;

  let addFunc;

  switch (modalType) {
    case MODAL_TYPE.SUPPLIER_TYPE: {
      addFunc = props.addBusinessType;
      break;
    }
    case MODAL_TYPE.SUPPLIER_SOURCE: {
      addFunc = props.addSupplierSource;
      break;
    }
    case MODAL_TYPE.INDUSTRY: {
      addFunc = props.addIndustry;
      break;
    }
    default: {
      addFunc = () => { }
    }
  }

  const modalData = {
    modalBody,
    handleSubmit: () => {
      addFunc({text:text, relationshipId: companyInfo.relationshipId})
      props.hideModal();
    },
  };
  showModal(modalData);
}

const getModalTitle = (newTextObj, modalType) => {
  let title;

    switch (modalType) {
      case MODAL_TYPE.PAYMENT_TERM: {
        title = <FormattedMessage id='addItem.text.paymentTerm' defaultMessage='' />;
        break;
      }
      case MODAL_TYPE.SUPPLIER_TYPE: {
        title = <FormattedMessage id='addItem.text.supplierType' defaultMessage='' />;
        break;
      }
      case MODAL_TYPE.SUPPLIER_SOURCE: {
        title = <FormattedMessage id='addItem.text.supplierSource' defaultMessage='' />;
        break;
      }
      case MODAL_TYPE.INDUSTRY: {
        title = <FormattedMessage id='addItem.text.industry' defaultMessage='' />;
        break;
      }
      default:
        title = <FormattedMessage id='signup.confirmation.title' defaultMessage='' />;
    }
    
  return title;
}

const deleteClickHandler = (props, modalType, payload) => {
  const { showModal, companyInfo } = props;
  const modalBody =
    <FormattedMessage
      id='deleteItem.confirmation'
      defaultMessage=''
      values={{ value: payload.text }}
    />;

  let deleteFunc;

  switch (modalType) {
    case MODAL_TYPE.PAYMENT_TERM: {
      deleteFunc = props.deletePaymentTerm;
      break;
    }
    case MODAL_TYPE.SUPPLIER_TYPE: {
      deleteFunc = props.deleteBusinessType;
      break;
    }
    case MODAL_TYPE.SUPPLIER_SOURCE: {
      deleteFunc = props.deleteSupplierSource;
      break;
    }
    case MODAL_TYPE.INDUSTRY: {
      deleteFunc = props.deleteIndustry;
      break;
    }
    default: {
      deleteFunc = () => { }
    }
  }

  const modalData = {
    modalBody,
    handleSubmit: () => {
      deleteFunc({id:payload.id, relationshipId: companyInfo.relationshipId});
      props.hideModal();
    },
  };
  showModal(modalData);
}



export default injectIntl(SupplierDrawer);
