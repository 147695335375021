import axios from "axios";
import config from "../../../config/environmentConfig";
import { SMS_ACTION_LIST, COMMON_ACTIONS } from "../../../static/constants";
import { showToasterMessage } from "../../../utils";
const { lang } = require("../../../translations/" +
  (sessionStorage.getItem("language") || "en") +
  ".js");

export const getFinancialReport = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/orgRegistration/getFinancialByFormId?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 200}&pageNumber=${(payload.pageNumber || 1) - 1}&formId=${payload.formId}`
      )
      .then((res) => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({
          type: SMS_ACTION_LIST.FINANCIAL_REPORT_LIST,
          data,
        });
      //  //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const getgetFinancialReportCount = (payload) => {
  return (dispatch) => {
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/orgRegistration/countRegisteredParents?relationshipId=${payload.relationshipId}&createdByEmpId=${payload.relationshipEmployeeId}&formId=${payload.formId}`
      )
      .then((res) => {
        dispatch({
          type: SMS_ACTION_LIST.FINANCIAL_REPORT_COUNT,
          data: res.data,
        });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

// export const saveSchoolCourse = (payload, props) => {
//   return (dispatch) => {
//     return axios
//       .post(
//         `${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/course/saveOrUpdate`,
//         payload
//       )
//       .then((response) => {
//         props.updateHeaderState({
//           school: {
//             addCourseDrawerVisible: false
//           }
//         });
//         props.getSchoolCourse(response.data);
//         ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//       })
//       .catch((err) => {
//         ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (!err.__isRetryRequest) {
//           showToasterMessage({
//             messageType: "error",
//             description:
//               lang[((err.response || {}).data || {}).message] ||
//               "Some error occurred",
//           });
//         }
//       });
//   };
// };

export const resetPaginatedData = () => {
  return (dispatch) => {
    dispatch({ type: SMS_ACTION_LIST.FINANCIAL_REPORT_LIST_PAGE_RESET });
  };
};