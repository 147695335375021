import axios from "axios";
import config from "../config/environmentConfig";
import {
  COMMON_ACTIONS,
  CONSTANTS,
  MODAL_ACTIONS,
  SALES_INVOICE_ACTION_LIST,
  CUSTOMER_ACTION_LIST,
  INVENTORY_ACTION_LIST,
  LOCAL_STORAGE_KEYS,
  SETTING_ACTION_LIST,
  SUPPLIER_ACTION_LIST,
  MODAL_TYPE,
} from "../static/constants";
import {
  showToasterMessage,
  clearLocalStorage,
  fetchFromLocalStorage,
  getFinancialYear,
  getPaths,
} from "./../utils";
const { lang } = require("../translations/" +
  (sessionStorage.getItem("language") || "en") +
  ".js");

export const fetchCountries = () => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(
        `${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/country/getCountries`
      )
      .then((res) => {
          dispatch({ type: COMMON_ACTIONS.COUNTRIES_LIST, data: res.data });
      })
      .catch((err) => {
        showToasterMessage({
          description: lang[(err || {}).message] || "Some Error Occured",
          messageType: "error",
        })
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      })
  };
};

export const uploadImage = (payload) => {
  return (dispatch) => {
    let formData = new FormData();
    formData.append("fileAsByte", payload.file);
    formData.append("objectKey", payload.objectKey);
    formData.append("bucketName", payload.bucketName);
    formData.append("contentType", "multipart/form-data;");
    formData.append("contentLength", "1234");
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.AWS_BASE_URL}${config.API_VERSION}/alpide-aws/boBucketOperations/saveOrUpdateFile`,
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      });
  };
};

export const deleteAttectedFile = (payload, props) => {
  const formData = new FormData();
  formData.append('folderName',payload?.folderName);
  formData.append('bucketName',props?.companyInfo?.bucketName);
  formData.append('objectKey',payload?.awsKeyName);
  formData.append('relationshipId',payload?.relationshipId);
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/api/documents/delete`, formData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        props.fetchTxDetail && props.fetchTxDetail(payload);
        showToasterMessage({
          description: (res || {}).message || "Deleted successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        if (err.__isRetryRequest) {
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const fetchProducts = (payload, history) => {
  return (dispatch) => {
    // dispatch({ type: COMMON_ACTIONS.SHOW_DETAIL_LOADER });

    const url = payload.searchString
      ? `${config.INVENTORY_BASE_URL}${config.API_VERSION}/${
          config.rootContextInventory
        }/inventoryItem/getAllInventoryItems?relationshipId=${
          payload.relationshipId
        }&inventoryType=Product&warehouseMasterId=0&pageSize=${
          payload.pageSize || 25
        }&pageNumber=0&searchString=${payload.searchString}`
      : `${config.INVENTORY_BASE_URL}${config.API_VERSION}/${
          config.rootContextInventory
        }/inventoryItem/getAllInventoryItems?relationshipId=${
          payload.relationshipId
        }&inventoryType=Product&warehouseMasterId=${payload.warehouseMasterId || 0}&pageSize=${
          payload.pageSize || 25
        }&pageNumber=0`;
    return axios
      .get(url)
      .then((res) => {
        dispatch({
          type: SALES_INVOICE_ACTION_LIST.PRODUCT_LIST,
          data: res.data,
        });
        dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (payload.updateAllOptions) {
          payload.updateAllOptions(res.data);
          payload.updateFetching(false);
        }
      })
      .catch((err) => {
        //  //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        } else {
          err.actionToCall = SALES_INVOICE_ACTION_LIST.PRODUCT_LIST;
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      })
  
  };
};

export const fetchStates = (countryId) => {
  if (Number(countryId) === CONSTANTS.INDIA_COUNTRY_ID) {
    countryId = 1;
  }

  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });

    return axios
      .get(
        `${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/states/getStates?countryId=${countryId}`
      )
      .then((res) => {
        dispatch({ type: COMMON_ACTIONS.STATES_LIST, data: res.data });
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.STATES_LIST, data: [] });
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      })
  };
};

export const resetStates = () => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.STATES_LIST, data: [] });
  };
};

export const showModal = (data) => {
  return (dispatch) => {
    dispatch({ type: MODAL_ACTIONS.SHOW_MODAL, data });
  };
};

export const fetchJobTitles = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(
        `${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/jobTitle/getAllJobTitles?relationshipId=${payload.relationshipId}`
      )
      .then((response) => {
        dispatch({ type: COMMON_ACTIONS.JOB_TITLE_LIST, data: response.data });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      });
  };
};

export const deleteJobTitle = (payload) => {
  const requestObj = {
    jobTitleId: payload.id,
    relationshipId: payload.relationshipId,
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/jobTitle/deleteJobTitle`,
        requestObj
      )
      .then((res) => {
        dispatch({
          type: COMMON_ACTIONS.JOB_TITLE_LIST_DELETION,
          data: { id: payload.id },
        });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: lang[(res || {}).message] || "Deleted successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.JOB_TITLE_LIST_DELETION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const addJobTitle = (payload) => {
  const requestObj = {
    relationshipId: payload.relationshipId,
    jobTitle: payload.text,
    //dateCreated: getCurrentDateForBackend(new Date()),
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/jobTitle/createJobTitle`,
        requestObj
      )
      .then((res) => {
        dispatch({
          type: COMMON_ACTIONS.JOB_TITLE_LIST_ADDITION,
          data: res.data,
        });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: lang[(res || {}).message] || "Created successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.JOB_TITLE_LIST_ADDITION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const hideModal = () => {
  return (dispatch) => {
    dispatch({ type: MODAL_ACTIONS.HIDE_MODAL });
  };
};

export const pushModalToStack = (modalData) => {
  return (dispatch) => {
    dispatch({ type: MODAL_ACTIONS.PUSH_STACK_MODAL, data: modalData });
  };
};

export const popModalFromStack = () => {
  return (dispatch) => {
    dispatch({ type: MODAL_ACTIONS.POP_STACK_MODAL });
  };
};

export const clearModalStack = () => {
  return (dispatch) => {
    dispatch({ type: MODAL_ACTIONS.CLEAR_STACK_MODAL });
  };
};

export const setModalFormData = (formData) => {
  return (dispatch) => {
    dispatch({
      type: MODAL_ACTIONS.MODAL_FORM_DATA_UPDATE,
      data: { formData },
    });
  };
};

export const fetchSources = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(
        `${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/customerSource/getAllCustomerSources?relationshipId=${payload.relationshipId}&pageNumber=0&pageSize=50`
      )
      .then((res) => {
        dispatch({ type: COMMON_ACTIONS.SOURCE_LIST, data: res.data });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });

        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.SOURCE_LIST;
        }
      });
  };
};

export const deleteCustomerSource = (payload) => {
  const requestObj = {
    customerSourceId: payload.id,
    relationshipId: payload.relationshipId,
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/customerSource/deleteCustomerSource`,
        requestObj
      )
      .then((res) => {
        dispatch({
          type: COMMON_ACTIONS.SOURCE_LIST_DELETION,
          data: { id: payload.id },
        });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (payload.props) {
          payload.props.fetchSources({
            relationshipId: payload.relationshipId,
          });
        }
        showToasterMessage({
          description: lang[(res || {}).message] || "Deleted successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.SOURCE_LIST_DELETION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const addCustomerSource = (payload) => {
  const requestObj = {
    relationshipId: payload.relationshipId,
    customerSourceName: payload.text,
    //dateCreated: getCurrentDateForBackend(new Date())
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/customerSource/createCustomerSource`,
        requestObj
      )
      .then((res) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (payload.props) {
          payload.props.fetchSources({
            relationshipId: payload.relationshipId,
          });
        }
        showToasterMessage({
          description: lang[(res || {}).message] || "Created successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.SOURCE_LIST_ADDITION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const fetchBusinessTypes = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(
        `${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/businessType/getBusinessType?relationshipId=${payload.relationshipId}&pageSize=50&pageNumber=0`
      )
      .then((res) => {
        if(res.data){

          dispatch({ type: COMMON_ACTIONS.BUSINESS_TYPE_LIST, data: res.data });
        }
        else{
          
          showToasterMessage({
            description: lang[(res || {}).message] || "Some Error Occured",
            messageType: "error",
          })
         }
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });

        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.BUSINESS_TYPE_LIST;
        }
      });
  };
};

export const deleteBusinessType = (payload) => {
  const requestObj = {
    businessTypeId: payload.id,
    relationshipId: payload.relationshipId,
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/businessType/deleteBusinessType`,
        requestObj
      )
      .then((res) => {
        dispatch({
          type: COMMON_ACTIONS.BUSINESS_TYPE_LIST_DELETION,
          data: { id: payload.id },
        });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (payload.props) {
          payload.props.fetchSources({
            relationshipId: payload.relationshipId,
          });
        }
        showToasterMessage({
          description: lang[(res || {}).message] || "Deleted successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.BUSINESS_TYPE_LIST_DELETION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const addBusinessType = (payload) => {
  const requestObj = {
    relationshipId: payload.relationshipId,
    businessType: payload.text,
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/businessType/createBusinessType`,
        requestObj
      )
      .then((res) => {
        if (payload.props) {
          payload.props.fetchSources({
            relationshipId: payload.relationshipId,
          });
        }
        dispatch({
          type: COMMON_ACTIONS.BUSINESS_TYPE_LIST_ADDITION,
          data: res.data,
        });
        showToasterMessage({
          description: lang[(res || {}).message] || "Created successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.BUSINESS_TYPE_LIST_ADDITION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const fetchPaymentTerms = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(
        `${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/paymentTerm/getAllPaymentTerms?relationshipId=${payload.relationshipId}`
      )
      .then((res) => {
        if(res.data)
        dispatch({ type: COMMON_ACTIONS.PAYMENT_LIST, data: res.data });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });

        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.PAYMENT_LIST;
        }
      });
  };
};

export const deletePaymentTerm = (payload) => {
  const requestObj = {
    paymentTermId: payload.id,
    relationshipId: payload.relationshipId,
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/paymentTerm/deletePaymentTerm`,
        requestObj
      )
      .then((res) => {
        if (payload.props) {
          payload.props.fetchSources({
            relationshipId: payload.relationshipId,
          });
        }
        dispatch({
          type: COMMON_ACTIONS.PAYMENT_LIST_DELETION,
          data: { id: payload.id },
        });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: (res || {}).message || "Deleted successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.PAYMENT_LIST_DELETION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const addPaymentTerm = (payload) => {
  // dateCreated: "2020-01-01 12:24:59"
  // relationshipId: 121
  // isDefault: 1
  // termName: "a"
  // description: "qwerty"
  // numberOfDays: 1
  const requestObj = {
    ...payload,
  };
  requestObj.numberOfDays = Number(payload.days);
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/paymentTerm/insertPaymentTerm`,
        requestObj
      )
      .then((res) => {
        dispatch({
          type: COMMON_ACTIONS.PAYMENT_LIST_ADDITION,
          data: res.data,
        });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: (res || {}).message || "Created successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.PAYMENT_LIST_ADDITION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const fetchIndustries = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(
        `${config.USERS_BASE_URL}${config.API_VERSION}/${config.rootContextUsers}/businessIndustry/getBusinessIndustriesByRId?relationshipId=${payload.relationshipId}&pageNumber=0&pageSize=50`
      )
      .then((res) => {
        dispatch({ type: COMMON_ACTIONS.INDUSTRY_LIST, data: res.data });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });

        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.INDUSTRY_LIST;
        }
      });
  };
};

export const deleteIndustry = (payload) => {
  const requestObj = {
    businessIndustryId: payload.id,
    relationshipId: payload.relationshipId,
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.USERS_BASE_URL}${config.API_VERSION}/${config.rootContextUsers}/businessIndustry/deleteIndustry`,
        requestObj
      )
      .then((res) => {
        dispatch({
          type: COMMON_ACTIONS.INDUSTRY_LIST_DELETION,
          data: { id: payload.id },
        });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (payload.props) {
          payload.props.fetchSources({
            relationshipId: payload.relationshipId,
          });
        }
        showToasterMessage({
          description: (res || {}).message || "Deleted successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.INDUSTRY_LIST_DELETION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const addIndustry = (payload) => {
  const requestObj = {
    relationshipId: payload.relationshipId,
    industryName: payload.text,
    //dateCreated: getCurrentDateForBackend(new Date())
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.USERS_BASE_URL}${config.API_VERSION}/${config.rootContextUsers}/businessIndustry/createIndustry`,
        requestObj
      )
      .then((res) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (payload.props) {
          payload.props.fetchSources({
            relationshipId: payload.relationshipId,
          });
        }
        dispatch({
          type: COMMON_ACTIONS.INDUSTRY_LIST_ADDITION,
          data: res.data,
        });
        showToasterMessage({
          description: (res || {}).message || "Created successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.INDUSTRY_LIST_ADDITION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const fetchSalutation = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(
        `${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/salutation/getAllSalutations?relationshipId=${payload.relationshipId}`
      )
      .then((res) => {
        dispatch({ type: COMMON_ACTIONS.SALUTATION_LIST, data: res.data });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });

        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.SALUTATION_LIST;
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      });
  };
};

export const deleteSalutation = (payload) => {
  const requestObj = {
    salutationId: payload.id,
    relationshipId: payload.relationshipId,
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/salutation/deleteSalutation`,
        requestObj
      )
      .then((res) => {
        dispatch({
          type: COMMON_ACTIONS.SALUTATION_LIST_DELETION,
          data: { id: payload.id },
        });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: (res || {}).message || "Deleted successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.SALUTATION_LIST_DELETION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const addSalutation = (payload) => {
  const requestObj = {
    relationshipId: payload.relationshipId,
    titleName: payload.text,
    //dateCreated: getCurrentDateForBackend(new Date()),
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/salutation/saveSalutation`,
        requestObj
      )
      .then((res) => {
        dispatch({
          type: COMMON_ACTIONS.SALUTATION_LIST_ADDITION,
          data: res.data,
        });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: (res || {}).message || "Created successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.SALUTATION_LIST_ADDITION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const fetchPriceList = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/priceList/getPriceList?relationshipId=${payload.relationshipId}&pageSize=100&pageNumber=0`
      )
      .then((res) => {
        dispatch({ type: COMMON_ACTIONS.PRICE_LIST, data: res.data });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });

        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.PRICE_LIST;
        }
      }).finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
      })
  };
};

export const deletePriceList = (payload) => {
  const requestObj = {
    priceListId: payload.id,
    relationshipId: payload.relationshipId,
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/priceList/deletePriceList`,
        requestObj
      )
      .then((res) => {
        dispatch({
          type: COMMON_ACTIONS.PRICE_LIST_DELETION,
          data: { id: payload.id },
        });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: (res || {}).message || "Deleted successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.PRICE_LIST_DELETION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const logout = () => {
  var logoutUrl = `${config.WP_DOMAIN}/logout.php?redirect_to=` + config.UI_BASE_URL;
  document.getElementById('logout-frame').src = logoutUrl;
  setTimeout(function () {
    document.getElementById('logout-frame').src = "";
    clearLocalStorage();
    window.location.reload();
    
  }, 2000);
  //window.location.reload();
};

export const setLocalStorageData = (payload) => {
  return (dispatch) => {
    dispatch({
      type: COMMON_ACTIONS.LOGGEDIN_COMPANY_INFO,
      data: payload.companyInfo,
    });
    dispatch({
      type: COMMON_ACTIONS.LOGGEDIN_USER_INFO,
      data: payload.userInfo,
    });
    dispatch({
      type: COMMON_ACTIONS.LOGGEDIN_USER_BANK_INFO,
      data: payload.bankInfo,
    });
    dispatch({
      type: COMMON_ACTIONS.LOGGEDIN_COMPANY_EMP_LIST,
      data: payload.companyEmpList,
    });
    dispatch({ type: COMMON_ACTIONS.IS_ALIF_APP, data: payload.isAlifApp });
    dispatch({
      type: COMMON_ACTIONS.DEFAULT_SCHOOL_FORM,
      data: payload.formId,
    });
  };
};

export const fetchUOM = (payload) => {
  return (dispatch) => {
    return axios
      .get(
        `${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/lookupUOM/getAllUOM?relationshipId=${payload.relationshipId}`
      )
      .then((res) => {
        dispatch({ type: COMMON_ACTIONS.UOM_LIST, data: res.data });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });

        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.UOM_LIST;
        }
      })
  };
};

export const fetchRecentTransactions = (payload) => {
  const loggedInEntityInfo = fetchFromLocalStorage(
    LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA
  )
    ? JSON.parse(fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA))
    : {};

  let FY = getFinancialYear(loggedInEntityInfo?.defaultCompany || {});
  return (dispatch) => {
    return axios
      .get(
        `${config.SALES_BASE_URL}${config.API_VERSION}/${
          config.rootContextSales
        }/customerTransaction/getRecentTx?relationshipId=${
          (loggedInEntityInfo.defaultCompany || {}).relationshipId
        }&pageSize=10&pageNumber=0&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`
      )
      .then((res) => {
        dispatch({ type: COMMON_ACTIONS.RECENT_TX_LIST, data: res.data });
      })
  };
};

export const deleteUOM = (payload) => {
  const requestObj = {
    uomId: payload.id,
    relationshipId: payload.relationshipId,
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/lookupUOM/deleteUOM`,
        requestObj
      )
      .then((res) => {
        dispatch({
          type: COMMON_ACTIONS.UOM_LIST_DELETION,
          data: { id: payload.id },
        });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: (res || {}).message || "Deleted successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.UOM_LIST_DELETION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const addUOM = (payload, props) => {
  const requestObj = {
    relationshipId: payload.relationshipId,
    uomName: payload.text,
    uomId: payload.id,
    version: payload.version,
    //dateCreated: getCurrentDateForBackend(new Date()),
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/lookupUOM/createUOM`,
        requestObj
      )
      .then((res) => {
        dispatch({ type: COMMON_ACTIONS.UOM_LIST_ADDITION, data: res.data });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        const payload2 = {
          relationshipId: payload.relationshipId,
        };
        props.fetchUOM(payload2);
        showToasterMessage({
          description: (res || {}).message || "Saved successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.UOM_LIST_ADDITION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const fetchExpenseTypes = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(
        `${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/expenseType/getAllExpenseTypes?relationshipId=${payload.relationshipId}`
      )
      .then((res) => {
        dispatch({ type: COMMON_ACTIONS.EXPENSE_LIST, data: res.data });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });

        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.EXPENSE_LIST;
        }
      });
  };
};

export const deleteExpenseTypes = (payload) => {
  const requestObj = {
    lkExpenseTypeId: payload.id,
    relationshipId: payload.relationshipId,
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/expenseType/deleteExpenseType`,
        requestObj
      )
      .then((res) => {
        dispatch({
          type: COMMON_ACTIONS.EXPENSE_LIST_DELETION,
          data: { id: payload.id },
        });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: (res || {}).message || "Deleted successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.EXPENSE_LIST_DELETION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const createEmployee = (payload, props) => {
  return (dispatch) => {
    let formData = new FormData();
    if (props.fileToUpload) {
      formData.append("file", props.fileToUpload);
    }
    formData.append("employee", JSON.stringify(payload, true));
    // let formData = new FormData();
    // // if (payload.fileToUpload) {
    // //   formData.append("files", payload.fileToUpload);
    // // }
    // if (props.fileList && props.fileList.length) {
    //   props.fileList.forEach((file)=>{
    //     formData.append('files', file, file.name);
    //   })
    // }
    // formData.append('employee', JSON.stringify(payload,true));
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.HRMS_BASE_URL}${config.API_VERSION}/${config.rootContextHRMS}/relEmployee/createEmployee`,
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        dispatch({
          type: COMMON_ACTIONS.EMPLOYEES_LIST_ADDITION,
          data: res.data,
        });
        if (props) {
          props.updateState({
            company: {
              employeeDrawerVisible: false,
              isRoleTeacher: false,
            },
          });
          if (props.isRoleTeacher) {
            props.getTeacher({
              relationshipId: payload.relationshipId,
              relationshipEmployeeId: (props.userInfo || {})
                .relationshipEmployeeId,
            });
          } else {
            props.fetchAllEmployees({ relationshipId: payload.relationshipId });
          }
        }
        showToasterMessage({
          messageType: "success",
          description: "Employee created successfully",
        });
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        } else {
          err.actionToCall = COMMON_ACTIONS.EMPLOYEES_LIST_ADDITION;
        }
      });
  };
};

export const addExpenseTypes = (payload) => {
  const requestObj = {
    relationshipId: payload.relationshipId,
    expenseType: payload.text,
    // dateCreated: getCurrentDateForBackend(new Date()),
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/expenseType/createExpenseType`,
        requestObj
      )
      .then((res) => {
        dispatch({
          type: COMMON_ACTIONS.EXPENSE_LIST_ADDITION,
          data: res.data,
        });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: (res || {}).message || "Created successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.EXPENSE_LIST_ADDITION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const fetchTaxes = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(
        `${config.ACCOUNTING_BASE_URL}${config.API_VERSION}/${config.rootContextAccounting}/taxModule/getAllSingleTaxRates?relationshipId=${payload.relationshipId}`
      )
      .then((res) => {
        dispatch({ type: COMMON_ACTIONS.TAX_LIST, data: res.data });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });

        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.TAX_LIST;
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      })
  };
};

export const deleteTax = (payload) => {
  const requestObj = {
    taxSingleRateId: payload.id,
    relationshipId: payload.relationshipId,
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/taxModule/deleteSingleTaxRate`,
        requestObj
      )
      .then((res) => {
        dispatch({
          type: COMMON_ACTIONS.TAX_LIST_DELETION,
          data: { id: payload.id },
        });
        if (payload.taxType === "VAT") {
          dispatch({
            type: INVENTORY_ACTION_LIST.GLOBLE_TAXES_DELETION,
            data: { id: payload.id },
          });
        }
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: (res || {}).message || "Deleted successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.TAX_LIST_DELETION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const fetchProjects = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(
        `${config.PROJECT_BASE_URL}${config.API_VERSION}/${config.rootContextProject}/projectMaster/getAllProjects?relationshipId=${payload.relationshipId}`
      )
      .then((res) => {
        dispatch({ type: COMMON_ACTIONS.PROJECT_LIST, data: res.data });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });

        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.PROJECT_LIST;
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      })
  };
};

export const deleteProject = (payload, props) => {
  const requestObj = {
    projectMasterId: payload.id,
    relationshipId: payload.relationshipId,
    version:payload.version || 0
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.PROJECT_BASE_URL}${config.API_VERSION}/${config.rootContextProject}/projectMaster/deleteProject`,
        requestObj
      )
      .then((res) => {
        dispatch({
          type: COMMON_ACTIONS.PROJECT_LIST_DELETION,
          data: { id: payload.id },
        })(
          //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          props && props.getAllProject
        ) && props.getAllProject(payload);
        showToasterMessage({
          description: (res || {}).message || "Deleted successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.PROJECT_LIST_DELETION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const fetchDocuments = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(
        `${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/documentName/getAllDocumentNames?relationshipId=${payload.relationshipId}`
      )
      .then((res) => {
        dispatch({ type: COMMON_ACTIONS.DOCUMENT_LIST, data: res.data });
      })
      .catch((err) => {
        showToasterMessage({
          description:
            lang[((err.response || {}).data || {}).message] ||
            "Some error occurred",
        });
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
      })
  };
};

export const deleteDocumentName = (payload) => {
  const requestObj = {
    documentNameId: payload.id,
    relationshipId: payload.relationshipId,
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/documentName/deleteDocumentName`,
        requestObj
      )
      .then((res) => {
        //dispatch({ type: COMMON_ACTIONS.DOCUMENT_LIST_DELETION, data: { id: payload.id } })
        fetchDocuments({ relationshipId: payload.relationshipId })(dispatch);
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: (res || {}).message || "Deleted successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.DOCUMENT_LIST_DELETION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const addDocumentName = (payload) => {
  const requestObj = {
    relationshipId: payload.relationshipId,
    docName: payload.text,
    txType: payload.txType,
    // dateCreated: getCurrentDateForBackend(new Date())
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/documentName/createDocumentName`,
        requestObj
      )
      .then((res) => {
        //dispatch({ type: COMMON_ACTIONS.DOCUMENT_LIST_ADDITION, data: res.data });
        fetchDocuments({ relationshipId: payload.relationshipId })(dispatch);
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: (res || {}).message || "Created successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.DOCUMENT_LIST_ADDITION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const fetchSupplierSources = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(
        `${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/supplierSource/getAllSupplierSources?relationshipId=${payload.relationshipId}&pageNumber=0&pageSize=50`
      )
      .then((res) => {
        dispatch({ type: COMMON_ACTIONS.SUPPLIER_SOURCE_LIST, data: res.data });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });

        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.SUPPLIER_SOURCE_LIST;
        }
      });
  };
};

export const addSupplierSource = (payload) => {
  const requestObj = {
    relationshipId: payload.relationshipId,
    supplierSourceName: payload.text,
    //dateCreated: getCurrentDateForBackend(new Date()),
    // userCreated: "Mayank Tyagi",
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/supplierSource/saveOrUpdateSupplier`,
        requestObj
      )
      .then((res) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        dispatch({
          type: COMMON_ACTIONS.SUPPLIER_SOURCE_LIST_ADDITION,
          data: res.data,
        });
        showToasterMessage({
          description: (res || {}).message || "Created successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.SUPPLIER_SOURCE_LIST_ADDITION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data)] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const deleteSupplierSource = (payload) => {
  const requestObj = {
    supplierSourceId: payload.id,
    relationshipId: payload.relationshipId,
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/supplierSource/deleteSupplierSource`,
        requestObj
      )
      .then((res) => {
        dispatch({
          type: COMMON_ACTIONS.SUPPLIER_SOURCE_LIST_DELETION,
          data: { id: payload.id },
        });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: (res || {}).message || "Deleted successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.SUPPLIER_SOURCE_LIST_DELETION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const fetchPaymentModes = (payload, history) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(
        `${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/paymentMode/getAllPaymentModes?relationshipId=${payload.relationshipId}`
      )
      .then((res) => {
        dispatch({ type: COMMON_ACTIONS.PAYMENT_MODE_LIST, data: res.data });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        } else {
          err.actionToCall = COMMON_ACTIONS.PAYMENT_MODE_LIST;
        }
      });
  };
};

export const fetchCashEquivalentLedgers = (payload, history) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(
        `${config.ACCOUNTING_BASE_URL}${config.API_VERSION}/${config.rootContextAccounting}/chartOfAccountDetails/getCashEquivalentLedgers?relationshipId=${payload.relationshipId}`
      )
      .then((res) => {
        dispatch({
          type: COMMON_ACTIONS.CASH_EQUIVALENT_LEDGER,
          data: res.data,
        });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        } else {
          err.actionToCall = COMMON_ACTIONS.CASH_EQUIVALENT_LEDGER;
        }
      });
  };
};

export const resetSupplierDetail = () => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SUPPLIER_DETAIL_RESET });
  };
};

export const fetchSupplierDetailsBySupplierId = (payload, history) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SUPPLIER_DETAIL_RESET });
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(
        `${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/suppliers/getSupplier?relationshipId=${payload.relationshipId}&supplierId=${payload.supplierId}`
      )
      .then((res) => {
        if (payload.callback) {
          payload.callback(res.data);
        }
        dispatch({ type: COMMON_ACTIONS.SUPPLIER_DETAIL, data: res.data });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        } else {
          err.actionToCall = COMMON_ACTIONS.SUPPLIER_DETAIL;
        }
      });
  };
};

export const fetchCustomerDetailsByCustomerId = (payload, history) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_DETAIL_LOADER });
    return axios
      .get(
        `${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customers/getCustomer?relationshipId=${payload.relationshipId}&customerId=${payload.customerId}`
      )
      .then((res) => {
        dispatch({ type: COMMON_ACTIONS.CUSTOMER_DETAIL, data: res.data });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = COMMON_ACTIONS.CUSTOMER_DETAIL;
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_DETAIL_LOADER,
        });
      })
  };
};

export const clearCustomerDetails = (payload, history) => {
  return (dispatch) => {
        dispatch({ type: COMMON_ACTIONS.CUSTOMER_DETAIL, data: {} });  
  };
};

export const fetchCustomerDetailsByCustomerIdForUpdate = (payload, history) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(
        `${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customers/getCustomer?relationshipId=${payload.relationshipId}&customerId=${payload.customerId}`
      )
      .then((res) => {
        dispatch({
          type: COMMON_ACTIONS.CUSTOMER_DETAIL_UPDATE,
          data: res.data,
        });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = COMMON_ACTIONS.CUSTOMER_DETAIL_UPDATE;
        }
      });
  };
};

export const getRelationshipAddress = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(
        `${config.USERS_BASE_URL}${config.API_VERSION}/${config.rootContextUsers}/boLocationRest/getRelationshipAddress?relationshipId=${payload.relationshipId}`
      )
      .then((res) => {
        dispatch({
          type: COMMON_ACTIONS.BO_RELATIONSHIP_ADDRESS,
          data: res.data,
        });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        payload.callback && payload.callback();
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      });
  };
};

export const resetCustomerDetail = () => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.CUSTOMER_DETAIL_RESET });
  };
};

export const resetCustomerDetailUpdate = () => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.CUSTOMER_DETAIL_UPDATE_RESET });
  };
};

export const fetchAllDepartments = (payload, history) => {
  return (dispatch) => {
    return axios
      .get(
        `${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/boDepartment/getAllDepartments?relationshipId=${payload.relationshipId}`
      )
      .then((res) => {
        dispatch({ type: COMMON_ACTIONS.DEPARTMENT_LIST, data: res.data });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          //showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = COMMON_ACTIONS.DEPARTMENT_LIST;
        }
      })
  };
};

export const addDepartment = (payload) => {
  const requestObj = {
    relationshipId: payload.relationshipId,
    departmentName: payload.text,
    // dateCreated: getCurrentDateForBackend(new Date()),
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/boDepartment/saveOrUpdateDepartment`,
        requestObj
      )
      .then((res) => {
        dispatch({
          type: COMMON_ACTIONS.DEPARTMENT_LIST_ADDITION,
          data: res.data,
        });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: (res || {}).message || "Created successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.DEPARTMENT_LIST_ADDITION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const deleteDepartment = (payload) => {
  const requestObj = {
    departmentId: payload.id,
    relationshipId: payload.relationshipId,
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/boDepartment/deleteDepartment`,
        requestObj
      )
      .then((res) => {
        dispatch({
          type: COMMON_ACTIONS.DEPARTMENT_LIST_DELETION,
          data: { id: payload.id },
        });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: (res || {}).message || "Deleted successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.DEPARTMENT_LIST_DELETION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const fetchAllEmployees = (payload, history) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(
        `${config.HRMS_BASE_URL}${config.API_VERSION}/${config.rootContextHRMS}/relEmployee/getAllEmployee?relationshipId=${payload.relationshipId}&searchedText=${payload.searchedText}`
      )
      .then((res) => {
        dispatch({ type: COMMON_ACTIONS.EMPLOYEES_LIST, data: res.data });
      })
      .catch((err) => {
        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.EMPLOYEES_LIST;
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      })
  };
};

export const deleteEmployee = (payload, props) => {
  let requestObj;
  if (payload.isTeacher) {
    requestObj = {
      relationshipEmployeeId: payload.relationshipEmployeeId,
      relationshipId: payload.relationshipId,
      emailAddress: payload.emailAddress,
      firstName: payload.firstName,
      lastName: payload.lastName,
    };
  } else {
    requestObj = {
      relationshipEmployeeId: payload.id,
      relationshipId: payload.relationshipId,
      emailAddress: (payload.employee || {}).emailAddress,
      firstName: (payload.employee || {}).firstName,
      lastName: (payload.employee || {}).lastName,
    };
  }
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.HRMS_BASE_URL}${config.API_VERSION}/${config.rootContextHRMS}/relEmployee/deleteEmployee`,
        requestObj
      )
      .then((res) => {
        if (payload.isTeacher) {
          props.getTeacher({ relationshipId: payload.relationshipId });
        } else {
          dispatch({
            type: COMMON_ACTIONS.EMPLOYEES_LIST_DELETION,
            data: { id: payload.id },
          });
        }
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: (res || {}).message || "Deleted successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.EMPLOYEES_LIST_DELETION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const deleteContact = (payload) => {
  const requestObj = {
    contactId: payload.id,
    relationshipId: payload.relationshipId,
    customerId: payload.customerId,
    supplierId: payload.supplierId,
    isPrimaryContact: payload.isPrimaryContact || 0,
  };

  const path = getPaths(payload);

  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${path.API_BASE_URL}${config.API_VERSION}/${path.rootContext}/boContact/deleteContact`,
        requestObj
      )
      .then((res) => {
        dispatch({
          type: COMMON_ACTIONS.EMPLOYEES_LIST_DELETION,
          data: { id: payload.id },
        });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        payload.callback &&
          payload.callback({ ...payload, contactId: payload.id });
        showToasterMessage({
          description: (res || {}).message || "Deleted successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.EMPLOYEES_LIST_DELETION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data)] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const deleteAddress = (payload) => {
  const path = getPaths(payload);
  const requestObj = {
    locationId: payload.id,
    relationshipId: payload.relationshipId,
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${path.API_BASE_URL}${config.API_VERSION}/${path.rootContext}/boLocationRest/deleteAddress`,
        requestObj
      )
      .then((res) => {
        if (payload.customerId)
          fetchAddresses({
            ...payload,
            locationType: CONSTANTS.BILLING_ADDRESS,
          })(dispatch);
        if (payload.customerId)
          fetchAddresses({
            ...payload,
            locationType: CONSTANTS.SHIPPING_ADDRESS,
          })(dispatch);
        dispatch({
          type: COMMON_ACTIONS.ADDRESS_DELETION,
          data: { id: payload.id },
        });
        //if(payload.customerId) fetchCustomerDetailsByCustomerId({relationshipId: payload.relationshipId, customerId: payload.customerId})(dispatch);
        payload.callback && payload.callback({ ...payload });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: (res || {}).message || "Deleted successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.ADDRESS_DELETION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data)] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const deleteActivityPriorities = (payload) => {
  const requestObj = {
    activityPriorityId: payload.id,
    relationshipId: payload.relationshipId,
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.CRM_BASE_URL}${config.API_VERSION}/${config.rootContextCRM}/crmActivity/deleteActivityPriority`,
        requestObj
      )
      .then((res) => {
        dispatch({
          type: CUSTOMER_ACTION_LIST.DELETE_ACTIVITY_PRIORITIES,
          data: { id: payload.id },
        });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: (res || {}).message || "Deleted successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = CUSTOMER_ACTION_LIST.DELETE_ACTIVITY_PRIORITIES;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const addActivityPriorities = (payload) => {
  const requestObj = {
    relationshipId: payload.relationshipId,
    activityPriorityName: payload.text,
    //dateCreated: getCurrentDateForBackend(new Date()),
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.CRM_BASE_URL}${config.API_VERSION}/${config.rootContextCRM}/crmActivity/saveOrUpdateActivityPriority`,
        requestObj
      )
      .then((res) => {
        dispatch({
          type: CUSTOMER_ACTION_LIST.ADD_ACTIVITY_PRIORITIES,
          data: res.data,
        });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: (res || {}).message || "Created successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = CUSTOMER_ACTION_LIST.ADD_ACTIVITY_PRIORITIES;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const deleteActivityStatus = (payload) => {
  const requestObj = {
    activityStatusId: payload.id,
    relationshipId: payload.relationshipId,
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.CRM_BASE_URL}${config.API_VERSION}/${config.rootContextCRM}/crmActivity/deleteActivityStatus`,
        requestObj
      )
      .then((res) => {
        dispatch({
          type: CUSTOMER_ACTION_LIST.DELETE_ACTIVITY_STATUS,
          data: { id: payload.id },
        });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: (res || {}).message || "Deleted successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = CUSTOMER_ACTION_LIST.DELETE_ACTIVITY_STATUS;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const addActivityStatus = (payload) => {
  const requestObj = {
    relationshipId: payload.relationshipId,
    activityStatusName: payload.text,
    //dateCreated: getCurrentDateForBackend(new Date()),
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.CRM_BASE_URL}${config.API_VERSION}/${config.rootContextCRM}/crmActivity/saveOrUpdateActivityStatus`,
        requestObj
      )
      .then((res) => {
        dispatch({
          type: CUSTOMER_ACTION_LIST.ADD_ACTIVITY_STATUS,
          data: res.data,
        });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: (res || {}).message || "Created successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = CUSTOMER_ACTION_LIST.ADD_ACTIVITY_STATUS;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const deleteActivityTypes = (payload) => {
  const requestObj = {
    activityTypeId: payload.id,
    relationshipId: payload.relationshipId,
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.CRM_BASE_URL}${config.API_VERSION}/${config.rootContextCRM}/crmActivity/deleteActivityType`,
        requestObj
      )
      .then((res) => {
        dispatch({
          type: CUSTOMER_ACTION_LIST.DELETE_ACTIVITY_TYPES,
          data: { id: payload.id },
        });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: (res || {}).message || "Deleted successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = CUSTOMER_ACTION_LIST.DELETE_ACTIVITY_TYPES;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const addActivityTypes = (payload) => {
  const requestObj = {
    relationshipId: payload.relationshipId,
    activityTypeName: payload.text,
    //dateCreated: getCurrentDateForBackend(new Date()),
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.CRM_BASE_URL}${config.API_VERSION}/${config.rootContextCRM}/crmActivity/saveOrUpdateActivityType`,
        requestObj
      )
      .then((res) => {
        dispatch({
          type: CUSTOMER_ACTION_LIST.ADD_ACTIVITY_TYPES,
          data: res.data,
        });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: (res || {}).message || "Created successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = CUSTOMER_ACTION_LIST.ADD_ACTIVITY_TYPES;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const fetchUserPreferences = (payload) => {
  return (dispatch) => {
    return axios
      .get(
        `${config.USERS_BASE_URL}${config.API_VERSION}/${config.rootContextUsers}/userPrefernce/getUserPreferences?relationshipId=${payload.relationshipId}&clientUserAccountId=${payload.userId}`
      )
      .then((res) => {
        dispatch({ type: COMMON_ACTIONS.USER_PREFERENCES, data: res.data });
        // //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.USER_PREFERENCES_FAIL });

        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.USER_PREFERENCES;
        }
      })
  };
};

export const saveUserPrefrences = (payload) => {
  return (dispatch) => {
    // dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.USERS_BASE_URL}${config.API_VERSION}/${config.rootContextUsers}/userPrefernce/createUserPrefernce`,
        payload
      )
      .then((res) => {
        dispatch({
          type: COMMON_ACTIONS.SAVE_OR_UPDATE_PREFERENCE,
          data: res.data,
        });
        // //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        payload.userId = payload.clientUserAccountId;
        fetchUserPreferences(payload)(dispatch);
        // showToasterMessage({
        //   description: (res || {}).message || 'Saved successfully.',
        //   messageType: 'success'
        // })
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.SAVE_OR_UPDATE_PREFERENCE;
        } else {
          // showToasterMessage({
          //   description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
          // })
        }
      });
  };
};

export const fetchShippingInstructions = (payload, history) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(
        `${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/shippingInstruction/getShippingInstructions?relationshipId=${payload.relationshipId}&pageSize=1000&pageNumber=0`
      )
      .then((res) => {
        dispatch({
          type: COMMON_ACTIONS.SHIPPING_INSTRUCTION_LIST,
          data: res.data,
        });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          //showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = COMMON_ACTIONS.SHIPPING_INSTRUCTION_LIST;
        }
      });
  };
};

export const addShippingInstruction = (payload) => {
  const requestObj = {
    relationshipId: payload.relationshipId,
    shippingInstructions: payload.text,
    //dateCreated: getCurrentDateForBackend(new Date()),
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/shippingInstruction/saveShippingInstruction`,
        requestObj
      )
      .then((res) => {
        dispatch({
          type: COMMON_ACTIONS.SHIPPING_INSTRUCTION_ADDITION,
          data: res.data,
        });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: (res || {}).message || "Created successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.SHIPPING_INSTRUCTION_ADDITION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const deleteShippingInstruction = (payload) => {
  const requestObj = {
    shippingInstructionId: payload.id,
    relationshipId: payload.relationshipId,
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/shippingInstruction/deleteShippingInstruction`,
        requestObj
      )
      .then((res) => {
        dispatch({
          type: COMMON_ACTIONS.SHIPPING_INSTRUCTION_DELETION,
          data: { id: payload.id },
        });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: (res || {}).message || "Deleted successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.SHIPPING_INSTRUCTION_DELETION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const fetchTaxIdentifications = (payload, history) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/taxIdentification/getAllTaxIdentifications?relationshipId=${payload.relationshipId}`)
      .then((res) => {
        dispatch({
          type: COMMON_ACTIONS.TAX_IDENTIFICATION_LIST,
          data: res.data,
        });
      })
      .catch((err) => {
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
      })
  };
};

export const addTaxIdentification = (payload, gridApi) => {
  const requestObj = {
    relationshipId: payload.relationshipId,
    type: payload.text,
    //dateCreated: getCurrentDateForBackend(new Date()),
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/taxIdentification/createTaxIdentification`,
        requestObj
      )
      .then((res) => {
        dispatch({
          type: COMMON_ACTIONS.TAX_IDENTIFICATION_ADDITION,
          data: res.data,
        });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (payload.callback) {
          payload.callback(res.data);
        }
        showToasterMessage({
          description: (res || {}).message || "Created successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.TAX_IDENTIFICATION_ADDITION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const deleteTaxIdentifications = (payload) => {
  const requestObj = {
    taxIdentificationId: payload.id,
    relationshipId: payload.relationshipId,
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/taxIdentification/deleteTaxIdentification`,
        requestObj
      )
      .then((res) => {
        dispatch({
          type: COMMON_ACTIONS.TAX_IDENTIFICATION_DELETION,
          data: { id: payload.id },
        });
        if (payload.callback) {
          payload.callback(payload);
        }
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: (res || {}).message || "Deleted successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.TAX_IDENTIFICATION_DELETION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const fetchRelationshipTaxIdentifications = (payload, history) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(
        `${config.USERS_BASE_URL}${config.API_VERSION}/${config.rootContextUsers}/relationshipTaxIdentification/getAllRelationshipTaxIdentifications?relationshipId=${payload.relationshipId}`
      )
      .then((res) => {
        dispatch({
          type: COMMON_ACTIONS.REL_TAX_IDENTIFICATION_LIST,
          data: res.data,
        });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        // if (!err.__isRetryRequest) {
        //   //showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        // } else {
        //   err.actionToCall = COMMON_ACTIONS.REL_TAX_IDENTIFICATION_LIST;
        // }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      })
  };
};

export const addRelationshipTaxIdentification = (payload) => {
  const requestObj = {
    ...payload,
    // dateCreated: getCurrentDateForBackend(new Date()),
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.USERS_BASE_URL}${config.API_VERSION}/${config.rootContextUsers}/relationshipTaxIdentification/createRelationshipTaxIdentification`,
        requestObj
      )
      .then((res) => {
        !payload.relationshipTaxIdentificationId &&
          dispatch({
            type: COMMON_ACTIONS.REL_TAX_IDENTIFICATION_ADDITION,
            data: res.data,
          });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: (res || {}).message || "Created successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.REL_TAX_IDENTIFICATION_ADDITION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const deleteRelationshipTaxIdentifications = (payload) => {
  const requestObj = {
    relationshipTaxIdentificationId: payload.id,
    relationshipId: payload.relationshipId,
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.USERS_BASE_URL}${config.API_VERSION}/${config.rootContextUsers}/relationshipTaxIdentification/deleteRelationshipTaxIdentification`,
        requestObj
      )
      .then((res) => {
        dispatch({
          type: COMMON_ACTIONS.REL_TAX_IDENTIFICATION_DELETION,
          data: { id: payload.id },
        });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: (res || {}).message || "Deleted successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.REL_TAX_IDENTIFICATION_DELETION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const deleteCustomerTaxIdentifications = (payload, cb) => {
  const requestObj = {
    customerTaxIdentificationId: payload.id,
    relationshipId: payload.relationshipId,
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customerTaxIdentification/deleteCustomerTaxIdentification`,
        requestObj
      )
      .then((res) => {
        dispatch({
          type: COMMON_ACTIONS.CUSTOMER_TAX_IDENTIFICATION_DELETION,
          data: { id: payload.id },
        });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        cb && cb(payload);
        showToasterMessage({
          description: (res || {}).message || "Deleted successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall =
            COMMON_ACTIONS.CUSTOMER_TAX_IDENTIFICATION_DELETION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const deleteSupplierTaxIdentifications = (payload, cb) => {
  const requestObj = {
    supplierTaxIdentificationId: payload.id,
    relationshipId: payload.relationshipId,
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/supplierTaxIdentification/deleteSupplierTaxIdentification`,
        requestObj
      )
      .then((res) => {
        dispatch({
          type: COMMON_ACTIONS.SUPPLIER_TAX_IDENTIFICATION_DELETION,
          data: { id: payload.id },
        });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        cb && cb(payload);
        showToasterMessage({
          description: (res || {}).message || "Deleted successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall =
            COMMON_ACTIONS.SUPPLIER_TAX_IDENTIFICATION_DELETION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};
const getTheContextValue = (serviceName) => {
  switch (serviceName) {
    case "purchase":
      return {
        baseUrl: config.PURCHASE_BASE_URL,
        rootContext: config.rootContextPurchase,
      };
    case "sales":
      return {
        baseUrl: config.SALES_BASE_URL,
        rootContext: config.rootContextSales,
      };
    default:
      console.warn(`Unknown serviceName: ${serviceName}, using sales as default.`);
      return {
        baseUrl: config.SALES_BASE_URL,
        rootContext: config.rootContextSales,
      };
  }
};

export const createAuditTrail = (payload, props) => { 

  const contexts = getTheContextValue(props.serviceName);
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    // let url = `${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/auditTrail/${payload.apiUrl}`;
    let url = `${contexts.baseUrl}${config.API_VERSION}/${contexts.rootContext}/auditTrail/${payload.apiUrl}`;
    return axios
      .post(url, payload)
      .then((res) => { 
        if(res.data){
          props.updateState({
            isCommentVisible: false,
            comment: "",
          });
          props.updateState({
            trailList: res.data,
          });
          props.updateTrailListInTransaction(res.data);
          props.hideModal();
        }
         else{
          showToasterMessage({
            description: lang[(res || {}).message] || "Some Error Occured",
            messageType: "error",
          })
         }
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      });
  };
};

export const deleteAuditTrail = (payload, props) => {
  const contexts = getTheContextValue(props.serviceName);
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    let url = `${contexts.baseUrl}${config.API_VERSION}/${contexts.rootContext}/auditTrail/${payload.deletApiUrl}`;
    return axios
      .post(url, payload)
      .then((res) => {
        props.updateState({
          trailList: res.data,
        });
        props.updateTrailListInTransaction(res.data);
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      });
  };
};

export const getQualityChecklistSettings = (payload, history) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_DETAIL_LOADER });
    return axios
      .get(
        `${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/qualityChecklistSettings/getQualityChecklistSettings?relationshipId=${payload.relationshipId}`
      )
      .then((res) => {
        dispatch({
          type: SETTING_ACTION_LIST.QUALITY_CHECK_LIST_SETTING,
          data: res.data,
        });
      })
      .catch((err) => {
        if (err.__isRetryRequest) {
          err.actionToCall = SETTING_ACTION_LIST.QUALITY_CHECK_LIST_SETTING;
        }
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_DETAIL_LOADER });
      })
  };
};

export const fetchIncoterms = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(
        `${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/incoterm/getAllIncoterm?relationshipId=${payload.relationshipId}`
      )
      .then((res) => {
        dispatch({ type: COMMON_ACTIONS.INCOTERM_LIST, data: res.data });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });

        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.INCOTERM_LIST;
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      })
  };
};

export const deleteIncoterm = (payload) => {
  const requestObj = {
    inctermId: payload.id,
    relationshipId: payload.relationshipId,
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/incoterm/deleteIncoterm`,
        requestObj
      )
      .then((res) => {
        dispatch({
          type: COMMON_ACTIONS.INCOTERM_LIST_DELETION,
          data: { id: payload.id },
        });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: (res || {}).message || "Deleted successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.INCOTERM_LIST_DELETION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const addIncoterm = (payload) => {
  const requestObj = {
    relationshipId: payload.relationshipId,
    incotermName: payload.text,
    //dateCreated: getCurrentDateForBackend(new Date()),
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/incoterm/createIncoterm`,
        requestObj
      )
      .then((res) => {
        dispatch({
          type: COMMON_ACTIONS.INCOTERM_LIST_ADDITION,
          data: res.data,
        });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: (res || {}).message || "Created successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.INCOTERM_LIST_ADDITION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const fetchAddresses = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(
        `${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/boLocationRest/getAddressByCustomerIdAndLocationTpe?relationshipId=${payload.relationshipId}&locationType=${payload.locationType}&customerId=${payload.customerId}`
      )
      .then((res) => {
        if (payload.locationType === CONSTANTS.BILLING_ADDRESS) {
          dispatch({
            type: CUSTOMER_ACTION_LIST.FETCH_BILLING_ADDRESSES,
            data: res.data,
          });
        } else {
          dispatch({
            type: CUSTOMER_ACTION_LIST.FETCH_SHIPPING_ADDRESSES,
            data: res.data,
          });
        }
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        } else {
          if (payload.locationType === CONSTANTS.BILLING_ADDRESS) {
            err.actionToCall = CUSTOMER_ACTION_LIST.FETCH_BILLING_ADDRESSES;
          } else {
            err.actionToCall = CUSTOMER_ACTION_LIST.FETCH_SHIPPING_ADDRESSES;
          }
        }
      });
  };
};

export const resetAddresses = () => {
  return (dispatch) => {
    dispatch({ type: CUSTOMER_ACTION_LIST.FETCH_BILLING_ADDRESSES, data: [] });
    dispatch({ type: CUSTOMER_ACTION_LIST.FETCH_SHIPPING_ADDRESSES, data: [] });
  };
};

export const fetchSupplierSummary = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/suppliers/getSupplierSummary?relationshipId=${payload.relationshipId}&pageNumber=${(payload.pageNumber || 1) - 1}&pageSize=${payload.pageSize || 10}`)
      .then((res) => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data,
        };
        dispatch({ type: SUPPLIER_ACTION_LIST.SUPPLIER_SUMMARY, data });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        } else {
          err.actionToCall = SUPPLIER_ACTION_LIST.SUPPLIER_SUMMARY;
        }
      }).finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
      })
  };
};

export const getAllPaymentGatewayConfig = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(
        `${config.PAYMENT_BASE_URL}${config.API_VERSION}/${config.rootContextPayment}/paymentGateway/getAllPaymentGateways?relationshipId=${payload.relationshipId}`
      )
      .then((res) => {
        dispatch({
          type: COMMON_ACTIONS.ALL_PAYMENT_GATEWAY_CONFIG,
          data: res.data,
        });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        // showToasterMessage({ description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'})
      });
  };
};

export const getAllMatchingInventoryItems = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(
        `${config.INVENTORY_BASE_URL}${config.API_VERSION}/${config.rootContextInventory}/inventoryItem/getAllMatchingInventoryItems?relationshipId=${payload.relationshipId}&inventoryItemCol=${payload.itemIds}`
      )
      .then((res) => {
        dispatch({
          type: COMMON_ACTIONS.SELECTED_PRODUCT_LIST,
          data: res.data,
        });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        // showToasterMessage({ description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'})
      });
  };
};
export const clearlMatchingInventoryItems = (payload) => {
  return (dispatch) => {  
        dispatch({
          type: COMMON_ACTIONS.SELECTED_PRODUCT_LIST,
          data: [],
        });

    
  };
};
export const resetMatchingInventoryItems = () => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SELECTED_PRODUCT_LIST, data: [] });
  };
};

export const getItemByUPC = (payload, props) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(
        `${config.INVENTORY_BASE_URL}${config.API_VERSION}/${config.rootContextInventory}/inventoryItem/getItemByUpcCode?relationshipId=${payload.relationshipId}&upcCode=${payload.upc}`
      )
      .then((res) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        // (payload.callback && res.data) && payload.callback(res.data);
        if (payload.callback && res.data && res.data.hasVariant) {
          let load = { formData: { product: res.data } };
          const formData = load.formData;
          const data = {
            title: lang["selectVariant.text"],
            formData,
            hideFooter: true,
            modalData: {
              modalType: MODAL_TYPE.SELECT_VARIANT,
              data: load,
            },
            handleSubmit: (variant) => {
              payload.callback({
                ...res.data,
                inventoryItemVariantList: [variant],
              });
              props.hideModal(data);
            },
          };
          props.showModal(data);
        } else if (payload.callback && res.data) {
          payload.callback(res.data);
        }
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      });
  };
};

export const getItemByPruductId = (payload, props) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(
        `${config.INVENTORY_BASE_URL}${config.API_VERSION}/${config.rootContextInventory}/inventoryItem/getInventoryItemDetails?itemId=${payload.productId}&relationshipId=${payload.relationshipId}`
      )
      .then((res) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        // (payload.callback && res.data) && payload.callback(res.data);
        if (payload.callback && res.data && res.data.hasVariant) {
          const variantId = payload.varienId

          const variantList =  res.data.inventoryItemVariantList

          const variant =  variantList.find((item)=>item.varienId === variantId)
          

              payload.callback({
                ...res.data,
                inventoryItemVariantList: [variant],
              });
          // let load = { formData: { product: res.data } };
          // const formData = load.formData;
          // const data = {
          //   title: lang["selectVariant.text"],
          //   formData,
          //   hideFooter: true,
          //   modalData: {
          //     modalType: MODAL_TYPE.SELECT_VARIANT,
          //     data: load,
          //   },
          //   handleSubmit: (variant) => {
          //     payload.callback({
          //       ...res.data,
          //       inventoryItemVariantList: [variant],
          //     });
          //     props.hideModal(data);
          //   },
          // };
          // props.showModal(data);
        } else if (payload.callback && res.data) {
          payload.callback(res.data);
        }
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      });
  };
};

export const saveClientRelationshipAdditionalInfo = (payload, props, cb) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.USERS_BASE_URL}${config.API_VERSION}/${config.rootContextUsers}/clientRelationship/saveAddiotnalInfo`,
        payload
      )
      .then((res) => {
        const { data } = res;
        //showToasterMessage({ messageType: 'success', description: lang['common.saved.success'] });
        dispatch({ type: COMMON_ACTIONS.CLIENT_REL_ADDITIONAL_INFO, data });
      })
      .catch((err) => {
        // showToasterMessage({ description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      });
  };
};

export const getClientRelationshipAdditionalInfo = (payload, cb) => {
  return (dispatch) => {
    return axios
      .get(
        `${config.USERS_BASE_URL}${config.API_VERSION}/${config.rootContextUsers}/clientRelationship/getClientRelationshipAdditionalInfo?relationshipId=${payload.relationshipId}`
      )
      .then((res) => {
        const { data } = res;
        dispatch({ type: COMMON_ACTIONS.CLIENT_REL_ADDITIONAL_INFO, data });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
        } else {
          err.actionToCall = COMMON_ACTIONS.CLIENT_REL_ADDITIONAL_INFO;
        }
      })
      .finally(() => {
        cb && cb(true);
      });
  };
};

export const fetchJobRoles = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(
        `${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/jobRole/getAllJobRoles?relationshipId=${payload.relationshipId}`
      )
      .then((response) => {
        dispatch({ type: COMMON_ACTIONS.JOB_ROLES_LIST, data: response.data });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      });
  };
};

export const deleteJobRole = (payload) => {
  const requestObj = {
    jobRoleId: payload.id,
    relationshipId: payload.relationshipId,
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/jobRole/delete`,
        requestObj
      )
      .then((res) => {
        dispatch({
          type: COMMON_ACTIONS.JOB_ROLES_LIST_DELETION,
          data: { id: payload.id },
        });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: lang[(res || {}).message] || "Deleted successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.JOB_ROLES_LIST_DELETION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const addJobRole = (payload) => {
  const requestObj = {
    relationshipId: payload.relationshipId,
    jobRoleName: payload.text,
    //dateCreated: getCurrentDateForBackend(new Date()),
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/jobRole/createJobRole`,
        requestObj
      )
      .then((res) => {
        dispatch({
          type: COMMON_ACTIONS.JOB_ROLES_LIST_ADDITION,
          data: res.data,
        });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: lang[(res || {}).message] || "Created successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.JOB_ROLES_LIST_ADDITION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const fetchSchoolSession = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(
        `${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/session/getSchoolSession?relationshipId=${payload.relationshipId}`
      )
      .then((response) => {
        dispatch({
          type: COMMON_ACTIONS.SCHOOL_SESSION_LIST,
          data: response.data,
        });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      });
  };
};

export const deleteSchoolSession = (payload) => {
  const requestObj = {
    schoolSessionId: payload.id,
    relationshipId: payload.relationshipId,
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/session/delete`,
        requestObj
      )
      .then((res) => {
        dispatch({
          type: COMMON_ACTIONS.SCHOOL_SESSION_LIST_DELETION,
          data: { id: payload.id },
        });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: lang[(res || {}).message] || "Deleted successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.SCHOOL_SESSION_LIST_DELETION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const addSchoolSession = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/session/saveOrUpdate`,
        payload
      )
      .then((res) => {
        dispatch({
          type: COMMON_ACTIONS.SCHOOL_SESSION_LIST_ADDITION,
          data: res.data,
        });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: lang[(res || {}).message] || "Created successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.SCHOOL_SESSION_LIST_ADDITION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const setDefaultSchoolForm = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.USERS_BASE_URL}${config.API_VERSION}/${config.rootContextUsers}/user/updateDefaultProgram`,
        payload
      )
      .then((res) => {
        dispatch({
          type: COMMON_ACTIONS.DEFAULT_SCHOOL_FORM,
          data: payload.formId,
        });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        window.location.reload();
        showToasterMessage({
          description: lang[(res || {}).message] || "Saved successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        dispatch({
          type: COMMON_ACTIONS.DEFAULT_SCHOOL_FORM,
          data: payload.formId,
        });
        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.DEFAULT_SCHOOL_FORM;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const fetchEmpTypes = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(
        `${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/empType/getAll?relationshipId=${payload.relationshipId}`
      )
      .then((response) => {
        dispatch({ type: COMMON_ACTIONS.EMP_TYPE_LIST, data: response.data });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      });
  };
};

export const deleteEmpType = (payload) => {
  const requestObj = {
    empTypeId: payload.id,
    relationshipId: payload.relationshipId,
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/empType/delete`,
        requestObj
      )
      .then((res) => {
        dispatch({
          type: COMMON_ACTIONS.EMP_TYPE_LIST_DELETION,
          data: { id: payload.id },
        });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: lang[(res || {}).message] || "Deleted successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.EMP_TYPE_LIST_DELETION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const addEmpType = (payload) => {
  const requestObj = {
    relationshipId: payload.relationshipId,
    empType: payload.text,
    //dateCreated: getCurrentDateForBackend(new Date()),
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/empType/save`,
        requestObj
      )
      .then((res) => {
        dispatch({
          type: COMMON_ACTIONS.EMP_TYPE_LIST_ADDITION,
          data: res.data,
        });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: lang[(res || {}).message] || "Created successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.EMP_TYPE_LIST_ADDITION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const fetchAssignmentGroup = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(
        `${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/assignmentGroup/get?relationshipId=${payload.relationshipId}`
      )
      .then((response) => {
        dispatch({
          type: COMMON_ACTIONS.ASSIGNMENTS_GROUP_LIST,
          data: response.data,
        });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      });
  };
};

export const deleteAssignmentGroup = (payload) => {
  const requestObj = {
    assignmentGroupId: payload.id,
    relationshipId: payload.relationshipId,
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/assignmentGroup/delete`,
        requestObj
      )
      .then((res) => {
        dispatch({
          type: COMMON_ACTIONS.ASSIGNMENTS_GROUP_DELETION,
          data: { id: payload.id },
        });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: lang[(res || {}).message] || "Deleted successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.ASSIGNMENTS_GROUP_DELETION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const addAssignmentGroup = (payload) => {
  const requestObj = {
    relationshipId: payload.relationshipId,
    groupName: payload.text,
    //dateCreated: getCurrentDateForBackend(new Date()),
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/assignmentGroup/save`,
        requestObj
      )
      .then((res) => {
        dispatch({
          type: COMMON_ACTIONS.ASSIGNMENTS_GROUP_ADDITION,
          data: res.data,
        });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: lang[(res || {}).message] || "Created successfully.",
          messageType: "success",
        });
      })
      .catch((err = {}) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.ASSIGNMENTS_GROUP_ADDITION;
        } else {
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const fetchE_StoreData = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(
        `${config.ECOM_BASE_URL}${config.API_VERSION}/${config.rootContextECOM}/ecom_setting/getEcomSetting?rid=${payload.relationshipId}`
      )
      .then((res) => {
        dispatch({ type: COMMON_ACTIONS.STORE_SETTING_VALUES, data: res.data });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });

        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.STORE_SETTING_VALUES;
        }
      });
  };
};


export const createRazorpayPaymentOrder = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_DETAIL_LOADER });
    return axios
      .post(`${config.PAYMENT_BASE_URL}${config.API_VERSION}/${config.rootContextPayment}/razorpayServices/createOrder`, payload)
      .then((res) => {
        dispatch({ type: COMMON_ACTIONS.RAZORPAY_PAYMENT_ORDER, data: res.data });
      })
      .catch(() => {
        showToasterMessage({ description: "Some error occurred" });
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_DETAIL_LOADER });
      })
}};

