import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import InventoryDrawer from '../../../components/drawer/inventory/InventoryDrawer';
import { fetchDataIfNeeded } from '../../../utils';
import { showModal, hideModal, pushModalToStack, popModalFromStack, fetchUOM, addUOM, deleteUOM, deleteTax } from '../../../actions/commonActions';
import {
  fetchCategory, addCategory, deleteCategory,
  fetchBrand, addBrand, deleteBrand,
  fetchManufacturer, addManufacturer, deleteManufacturer,
  fetchWarehouseNames, fetchItemVariantAttributes, addAttribute, deleteAttribute,
  getSkuData, getAllGstRates, getSalesLedgerAccounts, getPurchaseLedgerAccounts, getAllLedgerAccounts,
  getAllTaxByName, createInventoryItem, resetSKUData,isDuplicateProductName, getItemByCategory
} from './action';
import { fetchSuppliers } from '../../supplier/Listing/action';
import { fetchInventoryItemDetails, resetInventoryItemDetails } from '../../product/detail/action';
import { LEDGER_TYPE } from '../../../static/constants';
import LedgerAccountsDrawer from '../finance/ledgers';
class Customer extends Component {
  constructor(props) {
    const creditorAccount = (props.allLedgerAccounts || [])?.find((item) => {
      return (item?.ledgerAccountName === LEDGER_TYPE.TX_TYPE_CREDITORS);
  })
  const debitorAccount = (props.allLedgerAccounts || [])?.find((item) => {
      return (item?.ledgerAccountName === LEDGER_TYPE.TX_TYPE_OPENING_STOCK);
  })
    super(props);
    const { isUpdate, productData = {} } = props;
    const needToInIt = (isUpdate && productData) ? true : false;
    const defaultTaxObj = needToInIt && (productData.inventoryItemDefaultTaxList || [])[0];
    const variantData = (needToInIt && !productData.hasVariant) ? (productData.inventoryItemVariantList || [])[0] : null;
    let warehouse = props.allWarehouses.find(warehouse => (warehouse.warehouseMasterId === productData.warehouseMasterId)) || {};
    this.state = {
      selectedCreditLedger: creditorAccount,
      selectedDebitorLedger: debitorAccount,
      selectedSupplierName:{},
      itemId: needToInIt ? productData.itemId : undefined,
      version: needToInIt ? productData.version : undefined,
      itemName: needToInIt ? productData.itemName : undefined,
      preItemName: needToInIt ? productData.itemName : undefined,
      itemPageDescription:needToInIt ? productData.itemPageDescription : undefined,
      itemPageTitle:needToInIt ? (productData.itemPageTitle||productData.itemName) : undefined,
      productImage: needToInIt ? productData.productImage : undefined,
      description: needToInIt ? productData.description : undefined,
      customerStockNumber: needToInIt ? productData.customerStockNumber : undefined,
      uomName: needToInIt ? productData.uomName : undefined,
      isPopulateTax: needToInIt ? productData.isPopulateTax : 1,
      isPopulateTaxClicked: true,
      useSystemSku: needToInIt ? productData.useSystemSku : 1,
      barCode: needToInIt ? productData.barCode : undefined,
      articleNumber: needToInIt ? productData.articleNumber : undefined,
      brandId: needToInIt ? productData.brandId : undefined,
      manufacturerId: needToInIt ? productData.manufacturerId : undefined,
      categoryId: needToInIt ? productData.categoryId : undefined,
      salesTargetPeriod: needToInIt ? productData.salesTargetPeriod : undefined,
      salesTarget: needToInIt ? productData.salesTarget : undefined,
      coaSalesLedgerAccountId: needToInIt ? productData.coaSalesLedgerAccountId : undefined,
      coaPurchaseLedgerAccountId: needToInIt ? productData.coaPurchaseLedgerAccountId : undefined,
      hsnCodeRate: needToInIt ? (productData.hsnCodeDescription ? productData.hsnCodeRate + " (" + productData.hsnCodeDescription + ")" : productData.hsnCodeRate) : undefined,
      procurementDate: needToInIt ? productData?.procurementDate : undefined,
      hasVariant: needToInIt ? productData.hasVariant : 0,
      updatedByUserId: null,
      reorderQty: needToInIt ? productData.reorderQty : undefined,
      lowStockQty: needToInIt ? productData.lowStockQty : undefined,
      velocityAlert: needToInIt ? productData.velocityAlert : undefined,
      aboveThresholdAlert: needToInIt ? productData.aboveThresholdAlert : undefined,
      outOfStock: needToInIt ? productData.outOfStock : undefined,
      retailPrice: needToInIt ? productData.retailPrice : undefined,
      hsnCodeName: needToInIt ? productData.hsnCodeName : undefined,
      purchaseLedgerAccountName: needToInIt ? productData.purchaseLedgerAccountName : undefined,
      salesLedgerAccountName: needToInIt ? productData.salesLedgerAccountName : undefined,
      categoryName: needToInIt ? productData.categoryName : undefined,
      brandName: needToInIt ? productData.brandName : undefined,
      shortDescription: needToInIt ? productData.shortDescription : undefined,
      manufacturerName: needToInIt ? productData.manufacturerName : undefined,
      partNumber: needToInIt ? productData.partNumber : undefined,
      sku: needToInIt ? productData.sku : undefined,
      upc: needToInIt ? productData.upc : undefined,
      ean: needToInIt ? productData.ean : undefined,
      isbn: needToInIt ? productData.isbn : undefined,
      amazonAsinNumber: needToInIt ? productData.amazonAsinNumber : undefined,
      warehouseName: warehouse.warehouseName || null,
      warehouseMasterId: warehouse.warehouseMasterId || 0,
      inventoryItemVariantList: needToInIt ? productData.inventoryItemVariantList : [],
      inventoryItemDefaultTaxList: needToInIt ? productData.inventoryItemDefaultTaxList : [],
      dateCreatedUnformatted: null,
      dateUpdatedUnformatted: null,

      // isWholesaleProfitMarginInPercentage: needToInIt ? productData.inventoryItemVariantList[0]?.isWholesaleProfitMarginInPercentage : 1,
      // isRetailProfitMarginInPercentage: needToInIt ? productData.isRetailProfitMarginInPercentage : 1,
      variantObj: variantData ? { ...variantData } : {
        attributeId1: "",
        attributeId2: "",
        attributeId3: "",
        attributeName1: undefined,
        attributeName2: undefined,
        attributeName3: undefined,
        attributeValue1: "",
        attributeValue2: "",
        attributeValue3: "",
        purchasedPrice: 0,
        wholesalePrice: 0,
        currentStock: 0,
        uomName: null,
        sku: null,
        barCode: null,
        relationshipId: 0,
        itemNameForReporting: null,
        wholesaleProfitMargin: 0,
        landedCost: 0,
        retailProfitMargin: 0,
        retailPrice: 0,
        maxRetailPrice: 0,
        itemWeight: null,
        itemWeightUnit: "Kg",
        totalCurrentStock: 0,
        isWholesaleProfitMarginInPercentage :0,
        isRetailProfitMarginInPercentage : 0
      },
      defaultTaxObj: defaultTaxObj ? { ...defaultTaxObj } : {
        relationshipId: 0,
        hsnCode: 0,
        cgstRate: 0,
        igstRate: 0,
        sgstRate: 0,
        globleTaxRate: 0,
        taxNameDisplay: undefined,
        cess: 0
      },
      invalidProductNameClass: null,
      invalidAttribute1Class: null,
      invalidAttribute2Class: null,
      invalidAttribute3Class: null,
      submittedOnce: false,
      fileToUpload: null,

      b2bIsBestSellingItem: needToInIt ? productData.b2bIsBestSellingItem : 0,
      b2bIsClearanceItem: needToInIt ? productData.b2bIsClearanceItem : 0,
      b2bIsDealOfTheDayItem: needToInIt ? productData.b2bIsDealOfTheDayItem : 0,
      b2bIsNewArrivalItem: needToInIt ? productData.b2bIsNewArrivalItem : 0,
      b2bIsPublisedItem: needToInIt ? productData.b2bIsPublisedItem : 0,
      activeTabKey: '1',
      qtyCurrentStock: needToInIt ? productData.qtyCurrentStock : undefined,
      totalCost: needToInIt ? productData.totalCost : undefined,

    }
  }

  componentWillReceiveProps(props) {


    if (!this.state.warehouseName && props.allWarehouses && props.allWarehouses.length && !props.warehouseMasterId) {
      this.setState({
        warehouseName: props.allWarehouses[0].warehouseName,
        warehouseMasterId: props.allWarehouses[0].warehouseMasterId
      })
    }

    // if(props.allGstRates && props.allGstRates.length){
    //   let obj = find(allGstRates, {financeGstGoodsRates : optionValue}) || {};
    // }

    if (props.sku && !this.state.isSkuUpdated) {
      const variantObj = this.state.variantObj || {};
      if (variantObj.sku) {
        variantObj.sku = props.sku + variantObj.sku.substring(5, variantObj.sku.length)
        let tempInvItem = (props.inventoryItemVariantList || []).length ? props.inventoryItemVariantList[0] : {};
        let tempInvItemList = props.inventoryItemVariantList || [];
        tempInvItem.sku = variantObj.sku;
        tempInvItemList[0] = tempInvItem;
        this.setState({ sku: props.sku, isSkuUpdated: true, variantObj: { ...variantObj }, inventoryItemVariantList: tempInvItemList });
        // this.setState({ sku: props.sku, isSkuUpdated: true, variantObj: { ...variantObj,sku: props.sku } });
      }
     
      this.setState({ sku: props.sku, isSkuUpdated: true });
      this.props.resetSKUData();
    }
    if (props.isUpdate &&  props.productDetails && props.productDetails.itemId && !props.productDetails.variantId) {
      const defaultTaxObj = (props.productDetails.inventoryItemDefaultTaxList || [])[0];
      // const variantData = !props.productDetails.hasVariant ? (props.productDetails.inventoryItemVariantList || [])[0] : null;
      let variantObj = (props.productDetails.inventoryItemVariantList || [])[0] || {};

      const list = (props.productDetails.inventoryItemVariantList || []);
      let value1 = [];
      let value2 = [];
      let value3 = [];

      list.forEach(item => {
        if (!value1.includes(item.attributeValue1) && item.attributeValue1 !== "") {
          value1.push(item.attributeValue1);
        }
        if (!value2.includes(item.attributeValue2) && item.attributeValue2 !== "") {
          value2.push(item.attributeValue2);
        }
        if (!value3.includes(item.attributeValue3) && item.attributeValue3 !== "") {
          value3.push(item.attributeValue3);
        }
      });

      variantObj = { ...variantObj, attributeValue1: value1.includes(null) ? "" : value1, attributeValue2: value2.includes(null) ? "" : value2, attributeValue3: value3.includes(null) ? "" : value3 };
      // delete variantObj.variantId
      let warehouse = props.allWarehouses.find(warehouse => (warehouse.warehouseMasterId === props.productDetails.warehouseMasterId)) || {};
     
      this.setState({
        itemId: props.productDetails.itemId,
        version: props.productDetails.version || 0,
        itemName: props.productDetails.itemName,
        preItemName: props.productDetails.itemName,
        itemPageDescription: props.productDetails.itemPageDescription,
        itemPageTitle: props.productDetails.itemPageTitle ||props.productDetails.itemName,
        productImage: props.productDetails.productImage,
        description: props.productDetails.description,
        customerStockNumber: props.productDetails.customerStockNumber,
        uomName: props.productDetails.uomName,
        isPopulateTax: props.productDetails.isPopulateTax || 1,
        useSystemSku: props.productDetails.useSystemSku || 0,
        barCode: props.productDetails.barCode,
        articleNumber: props.productDetails.articleNumber,
        brandId: props.productDetails.brandId,
        manufacturerId: props.productDetails.manufacturerId,
        categoryId: props.productDetails.categoryId,
        salesTargetPeriod: props.productDetails.salesTargetPeriod,
        salesTarget: props.productDetails.salesTarget,
        shortDescription:props.productDetails.shortDescription,
        coaSalesLedgerAccountId: props.productDetails.coaSalesLedgerAccountId,
        coaPurchaseLedgerAccountId: props.productDetails.coaPurchaseLedgerAccountId,
        hsnCodeRate: props.productDetails.hsnCodeDescription ? props.productDetails.hsnCodeRate + " (" + props.productDetails.hsnCodeDescription + ")" : props.productDetails.hsnCodeRate,
        procurementDate: props.productDetails?.procurementDate,
        // hasVariant: props.productDetails.hasVariant,
        updatedByUserId: null,
        reorderQty: props.productDetails.reorderQty,
        lowStockQty: props.productDetails.lowStockQty,
        velocityAlert: props.productDetails.velocityAlert,
        aboveThresholdAlert: props.productDetails.aboveThresholdAlert,
        outOfStock: props.productDetails.outOfStock,
        retailPrice: props.productDetails.retailPrice,
        hsnCodeName: props.productDetails.hsnCodeName,
        purchaseLedgerAccountName: props.productDetails.purchaseLedgerAccountName,
        salesLedgerAccountName: props.productDetails.salesLedgerAccountName,
        categoryName: props.productDetails.categoryName,
        brandName: props.productDetails.brandName,
        manufacturerName: props.productDetails.manufacturerName,
        partNumber: props.productDetails.partNumber,
        sku: props.productDetails.sku,
        upc: props.productDetails.upc,
        ean: props.productDetails.ean,
        isbn: props.productDetails.isbn,
        amazonAsinNumber: props.productDetails.amazonAsinNumber,
        warehouseName: warehouse.warehouseName || null,
        warehouseMasterId: warehouse.warehouseMasterId || 0,
        inventoryItemVariantList: props.productDetails.inventoryItemVariantList || [],
        inventoryItemDefaultTaxList: props.productDetails.inventoryItemDefaultTaxList || [],
        dateCreatedUnformatted: null,
        dateUpdatedUnformatted: null,
        qtyCurrentStock: props.productDetails.qtyCurrentStock,
        totalCost: props.productDetails.totalCost,
     
        variantObj: variantObj ? { ...variantObj } : {
          attributeId1: "",
          attributeId2: "",
          attributeId3: "",
          attributeName1: undefined,
          attributeName2: undefined,
          attributeName3: undefined,
          attributeValue1: "",
          attributeValue2: "",
          attributeValue3: "",
          retailPrice: 0,
          purchasedPrice: 0,
          wholesalePrice: 0,
          wholesaleProfitMargin: 0,
          retailProfitMargin: 0,
          maxRetailPrice: 0,
          currentStock: 0,
          uomName: null,
          sku: null,
          barCode: null,
          relationshipId: 0,
          itemNameForReporting: null,
          inventoryItemVariantImageList: [],
          totalCurrentStock: 0,
          isWholesaleProfitMarginInPercentage :0,
          isRetailProfitMarginInPercentage : 0
        
        },
        defaultTaxObj: defaultTaxObj ? { ...defaultTaxObj } : {
          relationshipId: 0,
          hsnCode: 0,
          cgstRate: 0,
          igstRate: 0,
          sgstRate: 0,
          globleTaxRate: 0,
          taxNameDisplay: undefined,
          cess: 0
        },
        invalidProductNameClass: null,
        invalidAttribute1Class: null,
        invalidAttribute2Class: null,
        invalidAttribute3Class: null,
        submittedOnce: false,
        fileToUpload: null,
        b2bIsBestSellingItem: props.productDetails.b2bIsBestSellingItem || 0,
        b2bIsClearanceItem: props.productDetails.b2bIsClearanceItem || 0,
        b2bIsDealOfTheDayItem: props.productDetails.b2bIsDealOfTheDayItem || 0,
        b2bIsNewArrivalItem: props.productDetails.b2bIsNewArrivalItem || 0,
        b2bIsPublisedItem: props.productDetails.b2bIsPublisedItem || 0,
        inventoryItemCategoryRefs: props.productDetails.inventoryItemCategoryRefs,

      });
     
      props.resetInventoryItemDetails();
    }
    if(props.productCategories?.length){
      this.setState({
        productCategories:props.productCategories
      })
    }
  }


  initCalls() {
    const companyInfo = this.props.companyInfo || {};
    const { isUpdate, productData = {} } = this.props;
    const payload = { relationshipId: companyInfo.relationshipId, itemId: productData.itemId,pageNumber: 1, pageSize:100 };
    fetchDataIfNeeded('fetchCategory', 'productCategories', this.props, payload);
    fetchDataIfNeeded('fetchBrand', 'productBrands', this.props, payload);
    fetchDataIfNeeded('fetchManufacturer', 'manufacturers', this.props, payload);
    fetchDataIfNeeded('fetchUOM', 'allUOM', this.props, payload);
    fetchDataIfNeeded('fetchWarehouseNames', 'allWarehouses', this.props, payload);
    fetchDataIfNeeded('fetchItemVariantAttributes', 'variantAttributes', this.props, payload);
    fetchDataIfNeeded('getAllLedgerAccounts', 'allLedgerAccounts', this.props, payload);
    fetchDataIfNeeded('getSalesLedgerAccounts', 'salesLedgerAccounts', this.props, payload);
    fetchDataIfNeeded('getPurchaseLedgerAccounts', 'purchaseLedgerAccounts', this.props, payload);
    this.props.fetchSuppliers(payload);
    if (companyInfo.countryName === "India") {
      fetchDataIfNeeded('getAllGstRates', 'allGstRates', this.props, { ...payload, type: 'GOODS', pageNumber: 0, pageSize: 50 });
      fetchDataIfNeeded('getAllTaxByName', 'cgstTaxes', this.props, { ...payload, type: 'CGST', actionName: 'cgst_taxes' });
      fetchDataIfNeeded('getAllTaxByName', 'sgstTaxes', this.props, { ...payload, type: 'SGST', actionName: 'sgst_taxes' });
      fetchDataIfNeeded('getAllTaxByName', 'igstTaxes', this.props, { ...payload, type: 'IGST', actionName: 'igst_taxes' });
    } else {
      fetchDataIfNeeded('getAllTaxByName', 'VAT', this.props, { ...payload, type: 'VAT', actionName: 'globle_taxes' });
    }
   
    
    if (isUpdate && productData) {
      fetchDataIfNeeded('fetchInventoryItemDetails', 'productDetails', this.props, payload, true);
    }
  }

  render() {
    
    return <div>
       
      <InventoryDrawer initCalls={() => {
        this.initCalls();
      }} {...this.props} {...this.state} updateDrawerState={(data, cb) => { this.setState(data, cb) }} />
    </div>
  }

}

const mapStateToProps = (state) => {
  return {
    productDetails: state.product.productDetails,
    productCategories: state.inventory.productCategories,
    productBrands: state.inventory.productBrands,
    manufacturers: state.inventory.manufacturers,
    allUOM: state.common.uomList,
    allWarehouses: state.inventory.allWarehouses,
    variantAttributes: state.inventory.variantAttributes,
    sku: state.inventory.skuData.sku,
    allGstRates: state.inventory.allGstRates,
    allLedgerAccounts: state.inventory.allLedgerAccounts,
    salesLedgerAccounts: state.inventory.salesLedgerAccounts,
    purchaseLedgerAccounts: state.inventory.purchaseLedgerAccounts,
    cgstTaxes: state.inventory.cgstTaxes,
    sgstTaxes: state.inventory.sgstTaxes,
    igstTaxes: state.inventory.igstTaxes,
    globleTaxes: state.inventory.globleTaxes,
    companyInfo: state.common.companyInfo,
    drawerLoading: state.common.drawerLoading,
    relationshipId: (state.common.companyInfo || {}).relationshipId,
    suppliers: state.supplier.suppliers,


  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchCategory,
  addCategory,
  deleteCategory,
  fetchBrand,
  addBrand,
  deleteBrand,
  fetchManufacturer,
  addManufacturer,
  deleteManufacturer,
  fetchUOM,
  addUOM,
  deleteUOM,
  fetchWarehouseNames,
  fetchItemVariantAttributes,
  addAttribute,
  deleteAttribute,
  getSkuData,
  getAllGstRates,
  getAllLedgerAccounts,
  getSalesLedgerAccounts,
  getPurchaseLedgerAccounts,
  getAllTaxByName,
  createInventoryItem,
  showModal,
  hideModal,
  deleteTax,
  fetchInventoryItemDetails,
  resetInventoryItemDetails,
  pushModalToStack, popModalFromStack,
  resetSKUData,
  isDuplicateProductName,
  getItemByCategory,
  fetchSuppliers
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Customer);