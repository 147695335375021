import axios from "axios";
import config from "../../../config/environmentConfig";
import { SMS_ACTION_LIST } from "../../../static/constants";
import { showToasterMessage } from "../../../utils";
import { COMMON_ACTIONS } from "../../../static/constants";
const { lang } = require("../../../translations/" +
  (sessionStorage.getItem("language") || "en") +
  ".js");

export const getSchoolCourse = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(
        `${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation
        }/course/getSchoolCourse?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 25
        }&pageNumber=${(payload.pageNumber || 1) - 1}&createdByEmpId=${payload.relationshipEmployeeId}`
      )
      .then((res) => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({
          type: SMS_ACTION_LIST.SCHOOL_COURSE_LIST,
          data,
        });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      });
  };
};

export const getSchoolCourseCount = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_HEADER_LOADER });

    return axios
      .get(
        `${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/course/countCourse?relationshipId=${payload.relationshipId}&createdByEmpId=${payload.relationshipEmployeeId}`
      )
      .then((res) => {
        dispatch({
          type: SMS_ACTION_LIST.SCHOOL_COURSE_COUNT,
          data: res.data,
        });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_HEADER_LOADER,
        });
      });
  };
};

export const saveSchoolCourse = (payload, props) => {
  return (dispatch) => {
    return axios
      .post(
        `${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/course/saveOrUpdate`,
        payload
      )
      .then((response) => {
        showToasterMessage({
          description: (response || {}).message || 'Created successfully.',
          messageType: 'success'
        })
        props.updateHeaderState({
          schoolDrawers: {
            ...props.schoolDrawers,
            addCourseDrawerVisible: false
          }
        });
        props.getSchoolCourse(response.data);
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const deleteCourse = (payload, props) => {
  return dispatch => {
    return axios
      .post(`${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/course/delete`, payload)
      .then(res => {
        showToasterMessage({
          description: (res || {}).message || 'Deleted successfully.',
          messageType: 'success'
        })
        // props.getFees && props.getFees(props);
        getSchoolCourse(props)(dispatch);
        //props.getFeeCount && props.getFeeCount(props);
       // showToasterMessage({ messageType: 'success', description: 'Term & Fee deleted successfully' });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          // err.actionToCall = CUSTOMER_ACTION_LIST.DELETE_ACTIVITY;
        }
      })
  }
}
export const resetPaginatedData = () => {
  return (dispatch) => {
    dispatch({ type: SMS_ACTION_LIST.SCHOOL_LIST_PAGE_RESET });
  };
};

export const getsectionByOfferingDepartment = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(
        `${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/student/getStudentByFormId?relationshipId=${payload.relationshipId}&formId=${payload.formId}&pageSize=${payload.pageSize || 200}&pageNumber=${(payload.pageNumber || 1) - 1}&empId=${payload.relationshipEmployeeId}`
      )
      .then((res) => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({
          type: SMS_ACTION_LIST.SCHOOL_COURSE_LIST,
          data,
        });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const getSectionByOfferingDepartmentCount = (payload) => {
  return (dispatch) => {
    return axios
      .get(
        `${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/student/countStudentByFormId?relationshipId=${payload.relationshipId}&formId=${payload.formId}&empId=${payload.relationshipEmployeeId}`
      )
      .then((res) => {
        dispatch({
          type: SMS_ACTION_LIST.SCHOOL_COURSE_COUNT,
          data: res.data,
        });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

