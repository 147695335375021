import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS, SETTING_ACTION_LIST, STUDENT_REGISTRATION_ACTION_LIST } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const saveRegistration = (requestObj, props) => {

  let formData = new FormData();
  formData.append('customer', JSON.stringify(requestObj, true));
  formData.append('bucketName' ,props.companyInfo?.bucketName)

  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customers/saveOrUpdateCustomer`,
        formData,
        { headers: { 'content-type': 'multipart/form-data' } }
      )
      .then(res => {
        showToasterMessage({ messageType: 'success', description: lang['common.saved.success'] });
        props.fetchStudentRegistration && props.fetchStudentRegistration(requestObj);
      })
      .catch(err => {
        // showToasterMessage({ description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}

export const fetchRegistrationFormSetting = (payload) => {
  return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      // dispatch({ type: SETTING_ACTION_LIST.RESET_REGISTRATION_FORM_SETTING });
      return axios
        .get(`${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/registrationFormSetting/getFormSetting?relationshipId=${payload.relationshipId}&registrationFormSettingId=${payload.registrationFormSettingId}`)
        .then(res => {
          const { data } = res
          dispatch({ type: SETTING_ACTION_LIST.REGISTRATION_FORM_SETTING, data });
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
        .catch((err) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if(!err.__isRetryRequest){
            showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          }else{
            err.actionToCall = SETTING_ACTION_LIST.REGISTRATION_FORM_SETTING;
          }
        })
    }
}

export const resetRegistrationFormSetting = () => {
  return dispatch => {
    dispatch({ type: SETTING_ACTION_LIST.RESET_REGISTRATION_FORM_SETTING });
  }
}

export const getTotalStudentcCount = (payload) => {
  return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .get(`${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/student/countStudent?relationshipId=${payload.relationshipId}&formId=${payload.registrationFormSettingId}`)
        .then(res => {
          const { data } = res
          dispatch({ type: STUDENT_REGISTRATION_ACTION_LIST.TOTAL_STUDENT_COUNT, data });
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
        .catch((err) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if(!err.__isRetryRequest){
            showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          }else{
            err.actionToCall = STUDENT_REGISTRATION_ACTION_LIST.TOTAL_STUDENT_COUNT;
          }
        })
    }
}

export const getTotalStudentcCountByGrade = (payload) => {
  return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .get(`${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/student/countStudentByGrade?relationshipId=${payload.relationshipId}&formId=${payload.registrationFormSettingId}`)
        .then(res => {
          const { data } = res
          dispatch({ type: STUDENT_REGISTRATION_ACTION_LIST.TOTAL_STUDENT_COUNT_BY_GRADE, data });
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
        .catch((err) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if(!err.__isRetryRequest){
            showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          }else{
            err.actionToCall = STUDENT_REGISTRATION_ACTION_LIST.TOTAL_STUDENT_COUNT_BY_GRADE;
          }
        })
    }
}