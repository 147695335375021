import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMMON_ACTIONS, CRM_ACTION_LIST } from '../../../../static/constants';
import { showToasterMessage } from '../../../../utils';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const getAllWorkFlow = (payload) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
      return axios 
        .get(`${config.CRM_BASE_URL}${config.API_VERSION}/${config.rootContextCRM}/leadWorkflow/getWorkflows?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize}&pageNumber=${payload.pageNumber}`)
        .then(res => {

            const data = {
                list: res.data,
                pageNo: res.pageNo,
              }
          dispatch({ type: CRM_ACTION_LIST.LEAD_WORKFLOW, data: data });
        })
        .catch((err) => {
          if (!err.__isRetryRequest) {
            showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          } else {
            err.actionToCall = CRM_ACTION_LIST.LEAD_WORKFLOW;
            // err.data = err.data;
          }
        }).finally(() => {
          dispatch({
            type: COMMON_ACTIONS.HIDE_LIST_LOADER,
          });
        })
    }
}