import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMMON_ACTIONS, CUSTOMER_PAYMENT_ACTION_LIST } from '../../../../static/constants';
import { showToasterMessage, getFinancialYear} from '../../../../utils';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchCustomerPayments = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customerPayment/getAllPayments?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 25}&pageNumber=${(payload.pageNumber || 1)-1}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}&filters=${encodeURIComponent(JSON.stringify(payload.filters || {}))}`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: CUSTOMER_PAYMENT_ACTION_LIST.CUSTOMER_PAYMENT_LIST, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = CUSTOMER_PAYMENT_ACTION_LIST.CUSTOMER_PAYMENT_LIST;
          err.data = {
            pageNo: payload.pageNumber,
            list: []
          };
        }
      })  .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
      })
  }
}
export const getCustomerPaymentsCount = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_HEADER_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customerPayment/getTotalPaymentCount?relationshipId=${payload.relationshipId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}&filters=${encodeURIComponent(JSON.stringify(payload.filters || {}))}`)
      .then(res => {
        dispatch({ type: CUSTOMER_PAYMENT_ACTION_LIST.CUSTOMER_PAYMENT_COUNT, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = CUSTOMER_PAYMENT_ACTION_LIST.CUSTOMER_PAYMENT_COUNT;
        }
      }).finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_HEADER_LOADER });
      })
  }
}
export const resetPaginatedData = () => {
  return dispatch => {
    dispatch({ type: CUSTOMER_PAYMENT_ACTION_LIST.CUSTOMER_PAYMENT_LIST_PAGE_RESET });
  }
}


export const postPaymentsToXero = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}alpide-xero/xeroPayment/postPaymentToXero?relationshipId=${payload.relationshipId}`, payload.paymentList)
      .then(res => {
        showToasterMessage({
          messageType: "success",
          description: 'Payment Uploaded to xero successfully.'
        })
        props.hideModal();
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
        })
      })
  }
}



export const deletePayment = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customerPayment/deletePayment`, payload)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        getCustomerPaymentsCount(props)(dispatch);
        props.fetchCustomerPayments && props.fetchCustomerPayments(props);
        showToasterMessage({
          messageType: "success",
          description: lang['common.delete.success']
        })
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
        })
      })
  }
}

export const fetchCustomerSubscriptions = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/subscription/getAllSubscriptions?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 25}&pageNumber=${(payload.pageNumber || 1)-1}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
       
        dispatch({ type: CUSTOMER_PAYMENT_ACTION_LIST.CUSTOMER_SUBSCRIPTION_LIST, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = CUSTOMER_PAYMENT_ACTION_LIST.CUSTOMER_SUBSCRIPTION_LIST;
          err.data = {
            pageNo: payload.pageNumber,
            list: []
          };
        }
      })
  }
}
export const getCustomerSubscriptionsCount = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/subscription/countAllSubscriptions?relationshipId=${payload.relationshipId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
      .then(res => {
        dispatch({ type: CUSTOMER_PAYMENT_ACTION_LIST.CUSTOMER_SUBSCRIPTION_COUNT, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = CUSTOMER_PAYMENT_ACTION_LIST.CUSTOMER_SUBSCRIPTION_COUNT;
        }
      })
  }
}
export const resetSubscriptionsPaginatedData = () => {
  return dispatch => {
    dispatch({ type: CUSTOMER_PAYMENT_ACTION_LIST.CUSTOMER_SUBSCRIPTION_LIST_PAGE_RESET });
  }
}

// export const cancelSubscription = (payload, history) => {
//   let url = `${config.PAYMENT_SERVER_BASE_URL}/alpide_authorise/cancel-subscription?relationshipId=${payload.relationshipId}&refresh_token=${payload.refreshToken}&subscription_id=${payload.subscriptionId}&callback_uri=${window.location.origin}/app/admin/customer-payment-list`
//   window.location = (url);
// }


export const cancelSubscription = (payload, props) => {
  let formData = {
    subscription_id: payload.subscriptionId,
    relationshipId: payload.relationshipId,
    callback_uri: `${config.UI_BASE_URL}admin/customer-payment-list`,
   // refresh_token: payload.refreshToken,
    apiBaseUrl: config.API_BASE_URL + config.rootContext + '/'
  }
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.PAYMENT_SERVER_BASE_URL}/alpide_authorise/cancel-subscription`,
        formData, {
        headers: {
          'content-type': 'application/json'
        },
        cors: true,
        contentType: 'application/json',
        secure: true,
      }
      )
      .then(res => {
        showToasterMessage({ messageType: "success", description: "Upload successfully" });
      })
      .catch(err => {
      })
  }
}