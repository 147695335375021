import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS, INTEGRATION } from '../../../static/constants'
import { showToasterMessage } from '../../../utils';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchLogisticsConfiguration = (payload) =>{
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.LOGISTICS_BASE_URL}${config.API_VERSION}/${config.rootContextLogistics}/integration/getIntegratedLogistics?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({
          type: INTEGRATION.INTEGRATED_LOGISTICS,
          data: res.data
        });
      })
      .catch((err = {}) => {
        showToasterMessage({
          description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
        })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
      })
  }
}


export const disconnectLogisticsConfiguration = (payload) =>{
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
    return axios
      .delete(`${config.LOGISTICS_BASE_URL}${config.API_VERSION}/${config.rootContextLogistics}/integration/delete/${payload.relationshipId}/${payload.logisticsIntegrationId}`)
      .then(res => {
        showToasterMessage({messageType:"success", description: ((res || {}).data || {}).message || 'Disconnected Successfully'});
        fetchLogisticsConfiguration(payload)(dispatch);
      })
      .catch((err = {}) => {
        showToasterMessage({
          description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
        })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
      })
  }
}


export const saveLogisticsConfiguration = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
    return axios
      .post(`${config.LOGISTICS_BASE_URL}${config.API_VERSION}/${config.rootContextLogistics}/integration/saveIntegrationDetails`, payload)
      .then(res => {
        showToasterMessage({
          messageType:"success",
          description: 'Saved successfully'
        })
        fetchLogisticsConfiguration(res.data)(dispatch);
        props && props.popModalFromStack();
      })
      .catch((err = {}) => {
        showToasterMessage({
          description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
        })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
      })
  }
}