import React, { useEffect, useMemo, useState } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import "./inventoryDrawer.scss";
import { Dropdown } from "../../general/Dropdown";
import { ErrorMsg } from "../../general/ErrorMessage";
import { ICONS, MODAL_TYPE, MODULE_CODED_VALUES } from "../../../static/constants";
import { getLabelName } from "../../modal/modalBody/customerInfo";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import {
  Col,
  Row,
  Input,
  Button,
  Select,
  message,
  Upload,
  Tooltip,
  Modal,
} from "antd";
import { fixedDecimalNumber, getPermissionData, showToasterMessage, getCurrencySymbol} from "../../../utils";
import { LoadingOutlined } from "@ant-design/icons";
import VariantModel from "./variantModal";

import * as XLSX from "xlsx";
import "material-ui-rc-color-picker/assets/index.css";
import { GetColorName } from 'hex-color-to-color-name';
import find from "lodash.find";

const { Option } = Select;
function ProductDetailsTab(props) {
  const {
    intl,
    variantAttributes,
    inventoryItemVariantList = [{}],
    updateDrawerState,
    sku,
    useSystemSku,
    variantObj,
    itemName,
    updateState,
    pageNumber,
   
  } = props;

 
  const [loading, setLoading] = useState(false);
  const [dropdownValues, setDropdownValues] = useState([]);
  const [colorPickerVisible, setColorPickerVisible] = useState(false);
  const [selectedColor, setSelectedColor] = useState("");
  const [fileList ,setFileList] = useState([])

  const handleChange = (info) => {
    const { status } = info.file;
    if (status !== "uploading") {
      handleFileInputChange(info?.file?.originFileObj);
    }
    if (status === "done") {
      handleFileInputChange(info?.file?.originFileObj);
    } else if (status === "error") {
      handleFileInputChange(info?.file?.originFileObj);
    }
  };
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {fileList.length > 0 ? (

        <img src={URL.createObjectURL(fileList[0])} className="fav-icon-image" width="100px" alt="Preview" />

      ) : (
        loading ? <LoadingOutlined /> : <i className="fi fi-rr-camera"></i>
      )}


    </button>
  );

  const addItemConfirmationModal = (props, text, modalType) => {
    const { showModal, companyInfo } = props;
    const labelName = getLabelName(modalType);
    const modalBody = (
      <span>
        <FormattedMessage
          id="addItem.confirmation"
          values={{ value: `${text} to ` }}
          defaultMessage=""
        />
        {labelName} ?
      </span>
    );

    let addFunc;

    switch (modalType) {
      case MODAL_TYPE.UOM: {
        addFunc = props.addUOM;
        break;
      }
      case MODAL_TYPE.ATTRIBUTE: {
        addFunc = props.addAttribute;
        break;
      }
      default: {
        addFunc = () => { };
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        addFunc({ text: text, relationshipId: companyInfo.relationshipId });
        props.hideModal();
      },
    };
    showModal(modalData);
  };

  const getModalTitle = (newTextObj, modalType) => {
    let title;

    switch (modalType) {
      case MODAL_TYPE.UOM: {
        title = <FormattedMessage id="addItem.text.uom" defaultMessage="" />;
        break;
      }
      case MODAL_TYPE.ATTRIBUTE: {
        title = (
          <FormattedMessage id="addItem.text.attribute" defaultMessage="" />
        );
        break;
      }
      default:
        title = (
          <FormattedMessage id="signup.confirmation.title" defaultMessage="" />
        );
    }
    return title;
  };

  const handleNewItemAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    const { showModal } = props;

    if (
      formData.textBoxValue &&
      formData.textBoxValue.length &&
      !payload.existsInData
    ) {
      addItemConfirmationModal(props, formData.textBoxValue, modalType);
    } else {
      const data = {
        title: getModalTitle(payload, modalType),
        formData,
        modalData: { modalType, data: payload },
        handleSubmit: (formData = {}) => {
          if (formData.textBoxValue && formData.textBoxValue.length) {
            addItemConfirmationModal(props, formData.textBoxValue, modalType);
          } else {
            data.formData.submittedOnce = true;
            showModal(data);
          }
        },
      };
      showModal(data);
    }
  };

  const deleteClickHandler = (props, modalType, payload) => {
    const { showModal, companyInfo } = props;
    const modalBody = (
      <FormattedMessage
        id="deleteItem.confirmation"
        defaultMessage=""
        values={{ value: payload.text }}
      />
    );

    let deleteFunc;

    switch (modalType) {
      case MODAL_TYPE.UOM: {
        deleteFunc = props.deleteUOM;
        break;
      }
      case MODAL_TYPE.ATTRIBUTE: {
        deleteFunc = props.deleteAttribute;
        break;
      }
      default: {
        deleteFunc = () => { };
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        deleteFunc({
          id: payload.id,
          relationshipId: companyInfo.relationshipId,
        });
      },
    };
    showModal(modalData);
  };

  const tooltipRenderer = (params) => {
    return (
      '<span title="' +
      (params.value || "") +
      '">' +
      (params.value || "") +
      "</span>"
    );
  };

  const getVariantToolTip = (rowData) => {
    let title = "";
    title += rowData.attributeValue1
      ? rowData.attributeName1 + " : " + rowData.attributeValue1
      : "";
    title += rowData.attributeValue2
      ? "\n" + rowData.attributeName2 + " : " + rowData.attributeValue2
      : "";
    title += rowData.attributeValue3
      ? "\n" + rowData.attributeName3 + " : " + rowData.attributeValue3
      : "";
    return title;
  };

  var getUniqueChar = function (field, val) {
    var count = 0;
    for (var i = 0; i < inventoryItemVariantList.length; i++) {
      if (
        inventoryItemVariantList[i][field] &&
        inventoryItemVariantList[i][field].trim()
      ) {
        if (inventoryItemVariantList[i][field].charAt(0) === val) {
          count = count + 1;
        }
      }
    }
    if (count) {
      return val + count;
    } else {
      return val;
    }
  };
  
  function generateAttributeSKU(val, type) {
    var attributeValue1 = variantObj.attributeValue1;
    var attributeValue2 = variantObj.attributeValue2;
    var attributeValue3 = variantObj.attributeValue3;
    var newVariantSKU = sku || "";

    var charToSet = "";
    switch (type) {
      case "attributeValue1": {
        attributeValue1 = val;
        break;
      }
      case "attributeValue2": {
        attributeValue2 = val;
        break;
      }
      case "attributeValue3": {
        attributeValue3 = val;
        break;
      }
      default:
        break;
    }
    if (useSystemSku === 1) {
      if (attributeValue1) {
        charToSet = getUniqueChar("attributeValue1", attributeValue1[0]);
        newVariantSKU += ("-" + charToSet).toUpperCase();
      }
      if (attributeValue2) {
        charToSet = getUniqueChar("attributeValue2", attributeValue2[0]);
        newVariantSKU += ("-" + charToSet).toUpperCase();
      }
      if (attributeValue3) {
        charToSet = getUniqueChar("attributeValue3", attributeValue3[0]);
        newVariantSKU += ("-" + charToSet).toUpperCase();
      }
      updateDrawerState({
        variantObj: {
          ...variantObj,
          sku: newVariantSKU,
          attributeValue1: attributeValue1,
          attributeValue2: attributeValue2,
          attributeValue3: attributeValue3,
        },
      });
    } else {
      updateDrawerState({
        variantObj: {
          ...variantObj,
          attributeValue1: attributeValue1,
          attributeValue2: attributeValue2,
          attributeValue3: attributeValue3,
        },
      });
    }
  }

  function addVariantToProduct() {
    let isInValidVariant = false;

    if (!itemName) {
      return showToasterMessage({
        messageType: "error",
        description: "Please enter product name",
      });
    }

    // Check if at least one attribute value is selected
    if (
      !variantObj.attributeValue1.length &&
      !variantObj.attributeValue2.length &&
      !variantObj.attributeValue3.length
    ) {
      return;
    }

    let combinations = [];

    variantObj.attributeValue1.forEach((value1) => {
      if (!variantObj.attributeValue2.length) {
        combinations.push({
          attributeValue1: value1,
          attributeValue2: null,
          attributeValue3: null,
        });
      } else {
        variantObj.attributeValue2.forEach((value2) => {
          if (!variantObj.attributeValue3.length) {
            combinations.push({
              attributeValue1: value1,
              attributeValue2: value2,
              attributeValue3: null,
            });
          } else {
            variantObj.attributeValue3.forEach((value3) => {
              combinations.push({
                attributeValue1: value1,
                attributeValue2: value2,
                attributeValue3: value3,
              });
            });
          }
        });
      }
    });
    let val = [];
    let charToSet = "";
    !props.isUpdate
      ? (val = combinations.map((item, index) => {
          charToSet = getUniqueChar(
            "attributeValue1",
            item.attributeValue1.charAt(1)
          );
          let data = props.inventoryItemVariantList[index];
          if (
            props.inventoryItemVariantList.length &&
            data?.attributeValue1 === item?.attributeValue1 &&
            data?.attributeValue2 === item?.attributeValue2 &&
            data?.attributeValue3 === item?.attributeValue3
          ) {
            return data;
          } else {
            const val = variantObj;
            delete variantObj.variantId;
            return {
              ...val,
              debtorLedgerName: props.selectedDebitorLedger?.ledgerAccountName,
                debtorLedgerId: props.selectedDebitorLedger?.chartOfAccountDetailsId,
                 creditorLedgerName: props.selectedCreditLedger?.ledgerAccountName,
                 creditorLedgerId: props.selectedCreditLedger?.chartOfAccountDetailsId,
              attributeValue1: item.attributeValue1,
              attributeValue2: item.attributeValue2,
              attributeValue3: item.attributeValue3,
              sku:
                props.sku +
                ("-" + charToSet).toUpperCase() +
                ("-" + `${index}`),
            };
          }
        }))
      : (val = combinations.map((item, index) => {
        charToSet = getUniqueChar(
          "attributeValue1",
          item.attributeValue1.charAt(1)
        );
        let data = props.inventoryItemVariantList[index];
        if (
          data?.attributeValue1 === item?.attributeValue1 &&
          data?.attributeValue2 === item?.attributeValue2 &&
          data?.attributeValue3 === item?.attributeValue3
        ) {
          return data;
        } else {
          const val = variantObj;
          delete variantObj.variantId;
          return {
            ...val,
            attributeValue1: item.attributeValue1,
            attributeValue2: item.attributeValue2,
            attributeValue3: item.attributeValue3,
            sku:
              props.sku +
              ("-" + charToSet).toUpperCase() +
              ("-" + `${index}`),
          };
        }
      }));
    props.updateDrawerState({
      inventoryItemVariantList: val,
      hasVariant: variantObj.attributeValue1.length ? 1 : 0,
    });
  }

  // function addVariantToProduct() {
  //     let isInValidVariant = false;
  //     if (!itemName) {
  //         return showToasterMessage({ messageType: "error", description: "Please enter product name" });
  //     }
  //     if (!variantObj.attributeValue1 && !variantObj.attributeValue2 && !variantObj.attributeValue3) {
  //         return;
  //     }
  //     let combinations = []

  //     if (variantObj.attributeValue1) {
  //         variantObj.attributeValue1.forEach(value1 => {

  //             if (variantObj.attributeValue2 === null) {
  //                 combinations.push({
  //                     attributeValue1: value1,
  //                     attributeValue2: null,
  //                     attributeValue3: null,
  //                 });
  //             } else if (variantObj.attributeValue2) {
  //                 variantObj.attributeValue2.forEach(value2 => {

  //                     if (variantObj.attributeValue3 === null) {
  //                         combinations.push({
  //                             attributeValue1: value1,
  //                             attributeValue2: value2,
  //                             attributeValue3: null,
  //                         });
  //                     } else if (variantObj.attributeValue3) {
  //                         variantObj.attributeValue3.forEach(value3 => {
  //                             combinations.push({
  //                                 attributeValue1: value1,
  //                                 attributeValue2: value2,
  //                                 attributeValue3: value3,
  //                             });
  //                         });
  //                     }
  //                 });
  //             }
  //         });
  //     }

  //     let charToSet = ''
  //     const val = combinations.map((item, index) => {
  //         charToSet = getUniqueChar("attributeValue1", item.attributeValue1.charAt(1));
  //         return {
  //             ...variantObj,
  //             attributeValue1: item.attributeValue1,
  //             attributeValue2: item.attributeValue2,
  //             attributeValue3: item.attributeValue3,
  //             sku: props.sku + ("-" + charToSet).toUpperCase() + ("-" + `${index}`),
  //         }
  //     })
  //     //   props.inventoryItemVariantList.splice(1, 0, ...val);

  //     props.updateDrawerState({
  //         inventoryItemVariantList: val
  //     })
  // }
  // inventoryItemVariantList.forEach((node) => {
  //     if (((node.attributeId1 || 0) === variantObj.attributeId1 && (node.attributeValue1 || "") === variantObj.attributeValue1)
  //         && ((node.attributeId2 || 0) === variantObj.attributeId2 && (node.attributeValue2 || "") === variantObj.attributeValue2)
  //         && ((node.attributeId3 || 0) === variantObj.attributeId3 && (node.attributeValue3 || "") === variantObj.attributeValue3)
  //     ) {
  //         isInValidVariant = true;
  //     }
  // });
  // if (isInValidVariant) {
  //     return showToasterMessage({ messageType: "error", description: "Duplicate Variant" });
  // }
  // let copy = cloneDeep(variantObj);
  // copy.itemNameForReporting = itemName;
  // copy.relationshipId = relationshipId;
  // let joined = inventoryItemVariantList.concat([copy]);
  // updateDrawerState({ inventoryItemVariantList: joined });

  useEffect(() => {
    const calculatedDiscount =
      ((Number(variantObj.maxRetailPrice) - (variantObj.retailPrice || 0)) /
        Number(variantObj.maxRetailPrice)) *
      100;
    props.updateDrawerState({ discount: calculatedDiscount });
  }, [variantObj]);
  function resetVariantObject() {
    updateDrawerState({
      variantObj: {
        attributeId1: "",
        attributeId2: "",
        attributeId3: "",
        attributeName1: null,
        attributeName2: null,
        attributeName3: null,
        attributeValue1: null,
        attributeValue2: null,
        attributeValue3: null,
        retailPrice: 0,
        purchasedPrice: 0,
        wholesalePrice: 0,
        currentStock: 0,
        salesCommitted: 11,
        onOrder: 0,
        uomName: null,
        sku: null,
        procurementDate: null,
        useSystemSku: 0,
        barCode: null,
        warehouseNameDestStockTranfer: null,
        warehouseMasterIdDestStockTranfer: 0,
        chartOfAccountDetailsId: 0,
        expenseForStockTransfer: 0,
        qtyToTransferToWarehouse: 0,
        inventoryWarehouseDetailsId: 0,
        procurementDays: 0,
        itemNameForReporting: null,
        thisCallbackResponse: false,
        wholesaleProfitMargin: 0,
      },
    });
  }
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const addVariants = () => {
    props.pushModalToStack({
      modalBody: <VariantModel {...props} />,
      title: (
        <FormattedMessage
          id="drawer.inventory.addVariant.placeholder"
          defaultMessage=""
        />
      ),
      width: "40%",
      height: "500px",
      hideTitle: false,
      hideFooter: true,
    });
  };
  const handleFileInputChange = (e) => {
    const file = e;
    props.updateDrawerState({
      fileToUpload: file,
    });
    if (file) {
      const reader = new FileReader();
      reader.onloadend = handleFileRead;
      reader.readAsBinaryString(file);
    }
  };
  const handleFileRead = (e) => {
    const content = e.target.result;
    const workbook = XLSX.read(content, { type: "binary" });

    const sheetName = workbook?.SheetNames[0];
    const sheet = workbook.Sheets[sheetName];

    const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
    props.updateDrawerState({
      fileHeader: jsonData[0],
      totaData: jsonData.length,
    });
    const ans = jsonData[0]?.map((item) => {
      if (props.headerValues?.includes(item)) {
        return item;
      }
    });

    const val = ans.map((item, i) => {
      if (item) {
        return false;
      } else {
        return true;
      }
    });
    props.updateDrawerState({
      headerMandatoryValues: val,
    });
    setDropdownValues(ans);
  };
  const onCustomRequest = (file) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        file.onSuccess(null);
      }, 100);
    });
  };
  const uploadImage = {
    name: "file",
    multiple: false,
    customRequest: { onCustomRequest },
    // action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        handleFileInputChange(info?.file?.originFileObj);
      }
      if (status === "done") {
        handleFileInputChange(info?.file?.originFileObj);
      } else if (status === "error") {
        handleFileInputChange(info?.file?.originFileObj);
      }
    },
    onDrop(e) {
      handleFileInputChange(e.dataTransfer.files);
    },
  };
  const handleRemove = file => {
    // Remove the file from the list
    const newFileList = fileList.filter(item => item.uid !== file.uid);
    setFileList(newFileList);
  };
  const getBase641 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const handlePreview = async (file) => {

    if (!file.url && !file.preview) {
      file.preview = await getBase641(file.originFileObj);
    }

    setPreviewImage(file.url || (file.preview));
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };
  const handleCancel = () => setPreviewOpen(false);
  return (
    <div>
      {/* <Row gutter={[16,16]}>

                <Col span={24} className="custom-add">
            <Button  onClic =={() => addVariants()}>Add Variants</Button>
            </Col>
            </Row> */}
      <Row gutter={[16, 16]}>
        <Col span={12}>
          Attribute Options
          <span className="required">*</span>
        </Col>
        <Col span={12}>
          Option Value
          <span className="required">*</span>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={12}>
          <Form.Item>
            <Dropdown
              items={variantAttributes}
              valueKeyName="attributeName"
              optionKeyName="itemVariantAttributeId"
              value={variantObj.attributeName1}
              canAddNew={true}
              canDelete={true}
              moduleName={MODULE_CODED_VALUES.PRODUCTS}
              deleteClickHandler={(payload) => {
                deleteClickHandler(props, MODAL_TYPE.ATTRIBUTE, payload);
              }}
              onAddNewClick={(payload) => {
                payload.formData = {
                  textBoxValue: payload.textEntered,
                  submittedOnce: false,
                };
                handleNewItemAddition(props, payload, MODAL_TYPE.ATTRIBUTE);
              }}
              placeholder={intl.formatMessage({
                id: "drawer.inventory.attribute.placeholder",
                defaultMessage: "",
              })}
              onSelect={(optionValue, elem) => {
                let obj = variantAttributes.find(
                  (variant) => variant.itemVariantAttributeId === optionValue
                );
                updateDrawerState({
                  variantObj: {
                    ...variantObj,
                    attributeId1: obj.itemVariantAttributeId,
                    attributeName1: obj.attributeName,
                  },
                  colorPickerVisible:
                    obj.attributeName === "Colour"
                      ? true
                      : props.colorPickerVisible,
                  currAttribute:
                    obj.attributeName === "Colour" ? 1 : props.currAttribute,
                });
              }}
              allowClear={true}
              onClear={() => {
                updateDrawerState({
                  variantObj: {
                    ...variantObj,
                    attributeId1: undefined,
                    attributeName1: undefined,
                  },
                  currAttribute: null,
                  colorPickerVisible: null,
                });
              }}
            />
          </Form.Item>
      
        </Col>

        <Col span={12}>
          <Form.Item>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {props.colorPickerVisible && props.currAttribute === 1 && (
                <input
                  style={{
                    width: `${props.colorPickerVisible && props.currAttribute === 1
                        ? "10%"
                        : ""
                      }`,
                    height: "38px",
                  }}
                  type="color"
                 
                  onBlur={(color) => {
                    const val = props.variantObj.attributeValue1?.length
                      ? props.variantObj.attributeValue1
                      : [];

                    props.updateDrawerState({
                      variantObj: {
                        ...variantObj,
                        attributeValue1: [...val, (color.target.value)],
                        

                      },
                    });
                  }}
                />
        
              )}

              <div
                style={{
                  width: `${props.colorPickerVisible && props.currAttribute === 1
                      ? "90%"
                      : "100%"
                    }`,
                }}
              >
                <Dropdown
                  mode="tags"
                  placeholder={intl.formatMessage({
                    id: "drawer.inventory.attributeValue.placeholder",
                    defaultMessage: "",
                  })}
                  disabled={!variantObj.attributeName1}
                  value={variantObj.attributeName1 === "Colour" ? (variantObj.attributeValue1 || [])?.map(color => GetColorName(color)) || [] :variantObj.attributeValue1 || []}
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    props.updateDrawerState({
                      attributeId1: e,
                    });
                    generateAttributeSKU(e, "attributeValue1");

                    const hasVariantValue = e.length > 0 ? 1 : 0;
                    updateDrawerState({
                      hasVariant: hasVariantValue,
                    });
                  }}
                />
              </div>
            </div>

    
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={12}>
          <Form.Item>
            <Dropdown
              items={variantAttributes}
              disabled={
                !variantObj.attributeName1 || !variantObj.attributeValue1
              }
              valueKeyName="attributeName"
              optionKeyName="itemVariantAttributeId"
              value={variantObj.attributeName2}
              moduleName={MODULE_CODED_VALUES.PRODUCTS}
              canAddNew={true}
              canDelete={true}
              deleteClickHandler={(payload) => {
                deleteClickHandler(props, MODAL_TYPE.ATTRIBUTE, payload);
              }}
              onAddNewClick={(payload) => {
                payload.formData = {
                  textBoxValue: payload.textEntered,
                  submittedOnce: false,
                };
                handleNewItemAddition(props, payload, MODAL_TYPE.ATTRIBUTE);
              }}
              placeholder={intl.formatMessage({
                id: "drawer.inventory.attribute.placeholder",
                defaultMessage: "",
              })}
              onSelect={(optionValue, elem) => {
                let obj = variantAttributes.find(
                  (variant) => variant.itemVariantAttributeId === optionValue
                );
                updateDrawerState({
                  variantObj: {
                    ...variantObj,
                    attributeId2: obj.itemVariantAttributeId,
                    attributeName2: obj.attributeName,
                  },
                  colorPickerVisible:
                    obj.attributeName === "Colour"
                      ? true
                      : props.colorPickerVisible,
                  currAttribute:
                    obj.attributeName === "Colour" ? 2 : props.currAttribute,
                });
              }}
              allowClear={true}
              onClear={() => {
                updateDrawerState({
                  variantObj: {
                    ...variantObj,
                    attributeId2: undefined,
                    attributeName2: undefined,
                  },
                  currAttribute: null,
                  colorPickerVisible: null,
                });
              }}
            />
          </Form.Item>
          <ErrorMsg
            validator={() => {
              return !(
                variantObj.attributeName2 &&
                (variantObj.attributeName2 === variantObj.attributeName1
                  )
              );
            }}
            message={intl.formatMessage({
              id: "drawer.inventory.validation.duplicateAttribute",
              defaultMessage: "",
            })}
          />
        </Col>
        <Col span={12}>
          <Form.Item>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {props.colorPickerVisible && props.currAttribute === 2 && (
                <input
                  style={{
                    width: `${props.colorPickerVisible && props.currAttribute === 2
                        ? "10%"
                        : ""
                      }`,
                    height: "38px",
                  }}
                  type="color"
                  onBlur={(color) => {
                    const val = props.variantObj.attributeValue2?.length
                      ? props.variantObj.attributeValue2
                      : [];

                    props.updateDrawerState({
                      variantObj: {
                        ...variantObj,
                        attributeValue2: [...val, (color.target.value)],

                      },
                    });
                  }}
                />
         
              )}
            </div>
            <div
              style={{
                width: `${props.colorPickerVisible && props.currAttribute === 2
                    ? "90%"
                    : "100%"
                  }`,
              }}
            >
              <Dropdown
                mode="tags"
                showArrow={false}
                notFoundContent=""
                placeholder={intl.formatMessage({
                  id: "drawer.inventory.attributeValue.placeholder",
                  defaultMessage: "",
                })}
                value={variantObj.attributeName2 === "Colour" ? (variantObj.attributeValue2 || [])?.map(color => GetColorName(color)) || [] :variantObj.attributeValue2 || []}
                disabled={
                  !variantObj.attributeName1 || !variantObj.attributeValue1
                }
                style={{ width: "100%" }}
                onChange={(e) => {
                  props.updateDrawerState({
                    attributeId2: e,
                  });
                  generateAttributeSKU(e, "attributeValue2");
                  const hasVariantValue = e.length > 0 ? 1 : 0;
                  updateDrawerState({
                    hasVariant: hasVariantValue,
                  });
                }}
              />
            </div>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={12}>
          <Form.Item>
            <Dropdown
              items={variantAttributes}
              disabled={
                !variantObj.attributeName2 || !variantObj.attributeValue2
              }
              valueKeyName="attributeName"
              optionKeyName="itemVariantAttributeId"
              value={variantObj.attributeName3}
              moduleName={MODULE_CODED_VALUES.PRODUCTS}
              canAddNew={true}
              canDelete={true}
              deleteClickHandler={(payload) => {
                deleteClickHandler(props, MODAL_TYPE.ATTRIBUTE, payload);
              }}
              onAddNewClick={(payload) => {
                payload.formData = {
                  textBoxValue: payload.textEntered,
                  submittedOnce: false,
                };
                handleNewItemAddition(props, payload, MODAL_TYPE.ATTRIBUTE);
              }}
              placeholder={intl.formatMessage({
                id: "drawer.inventory.attribute.placeholder",
                defaultMessage: "",
              })}
              onSelect={(optionValue, elem) => {
                let obj = variantAttributes.find(
                  (variant) => variant.itemVariantAttributeId === optionValue
                );
                updateDrawerState({
                  variantObj: {
                    ...variantObj,
                    attributeId3: obj.itemVariantAttributeId,
                    attributeName3: obj.attributeName,
                  },
                  colorPickerVisible:
                    obj.attributeName === "Colour"
                      ? true
                      : props.colorPickerVisible,
                  currAttribute:
                    obj.attributeName === "Colour" ? 3 : props.currAttribute,
                });
              }}
              allowClear={true}
              onClear={() => {
                updateDrawerState({
                  variantObj: {
                    ...variantObj,
                    attributeId3: undefined,
                    attributeName3: undefined,
                  },
                  currAttribute: null,
                  colorPickerVisible: null,
                });
              }}
            />
          </Form.Item>
          <ErrorMsg
            validator={() => {
              return !(
                variantObj.attributeName3 &&
                (
                  variantObj.attributeName3 === variantObj.attributeName1 ||
                  variantObj.attributeName3 === variantObj.attributeName2)
              );
            }}
            message={intl.formatMessage({
              id: "drawer.inventory.validation.duplicateAttribute",
              defaultMessage: "",
            })}
          />
        </Col>
        <Col span={12}>
          <Form.Item>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {props.colorPickerVisible && props.currAttribute === 3 && (
                <input
                  style={{
                    width: `${props.colorPickerVisible && props.currAttribute === 3
                        ? "10%"
                        : ""
                      }`,
                    height: "38px",
                  }}
                  type="color"
                  onBlur={(color) => {
                    const val = props.variantObj.attributeValue3?.length
                      ? props.variantObj.attributeValue3
                      : [];

                    props.updateDrawerState({
                      variantObj: {
                        ...variantObj,
                        // attributeValue3: [...val, GetColorName(color.target.value)],
                        attributeValue3: [...val, (color.target.value)],

                      },
                    });
                  }}
                />

                // <ColorPicker

                //     onChange={(e) => {
                //         const val = props.variantObj.attributeValue3?.length ? props.variantObj.attributeValue3 : []

                //         if (!val.includes(e.color)) {
                //             props.updateDrawerState({
                //                 variantObj: { ...variantObj, attributeValue3: [...val, e.color] }
                //             });
                //         } else return
                //     }}

                // />
              )}
              <div
                style={{
                  width: `${props.colorPickerVisible && props.currAttribute === 3
                      ? "90%"
                      : "100%"
                    }`,
                }}
              >
                <Dropdown
                  mode="tags"
                  showArrow={false}
                  disabled={
                    !variantObj.attributeName2 || !variantObj.attributeValue2
                  }
                  notFoundContent=""
                  placeholder={intl.formatMessage({
                    id: "drawer.inventory.attributeValue.placeholder",
                    defaultMessage: "",
                  })}
                  value={variantObj.attributeName3 === "Colour" ? (variantObj.attributeValue3 || [])?.map(color => GetColorName(color)) || [] :variantObj.attributeValue3 || []}
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    props.updateDrawerState({
                      attributeId3: e,
                    });
                    generateAttributeSKU(e, "attributeValue3");
                    const hasVariantValue = e.length > 0 ? 1 : 0;
                    updateDrawerState({
                      hasVariant: hasVariantValue,
                    });
                  }}
                />
              </div>
            </div>
          </Form.Item>
        </Col>
      </Row>

      <div className="pt20 pb10 custom-add">
        <Button onClick={addVariantToProduct}>
          {inventoryItemVariantList.length > 1 ? (
            "Edit Variant"
          ) : (
            <FormattedMessage
              id="drawer.inventory.productDetails.addVariant.button"
              defaultMessage=""
            />
          )}
          {/* <FormattedMessage id='drawer.inventory.productDetails.addVariant.button' defaultMessage='' /> */}
          {/* <i className={ICONS.ADD} style={{ marginLeft: "10px", fontSize: "x-small", color: "#000000" }} /> */}
        </Button>
      </div>

      <div className="inventory-variant-table">
        <div className="table-container">
          <table className="fixed-column-table">
            <thead>
              <tr>
                <th>
                  <FormattedMessage
                    id="drawer.inventory.productDetails.gridHeader.variant"
                    defaultMessage=""
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="drawer.inventory.productDetails.gridHeader.purchasedPrice"
                    defaultMessage=""
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="drawer.inventory.productDetails.gridHeader.msrp"
                    defaultMessage=""
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="drawer.inventory.productDetails.gridHeader.sellingPrice"
                    defaultMessage=""
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="drawer.inventory.productDetails.gridHeader.skuId"
                    defaultMessage=""
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="drawer.inventory.productDetails.gridHeader.qty"
                    defaultMessage=""
                  />
                  <Tooltip placement="top" title={props.intl.formatMessage({ id: 'drawer.product.variantQty.help' })}>
                    <span className="cursor-pointer"><i className={`${ICONS.HELP_P} ml5`} /></span>
                  </Tooltip>
                </th>
                <th>
                  <FormattedMessage
                    id="drawer.inventory.productDetails.gridHeader.weight"
                    defaultMessage=""
                  />
                </th>
                <th></th>
                <th>
                  <FormattedMessage
                    id="SupplierName"
                    defaultMessage=""
                  />

                </th>
                <th>
                  <FormattedMessage
                    id="Credit Ledger"
                    defaultMessage=""
                  />

                </th>
                <th>
                  <FormattedMessage
                    id="Debit Ledger"
                    defaultMessage=""
                  />

                </th>
              </tr>
            </thead>
            <tbody className="fixed-column">
              {inventoryItemVariantList.length
                ? inventoryItemVariantList.map((rowData, i) => {
                    return (
                      <tr key={`iv` + i}>
                        <td
                  
                        >
                   
                          <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                            <img
                              alt="example"
                              style={{
                                width: '100%',
                              }}
                              src={previewImage}
                            />
                          </Modal>
                          {
                            <div
                              title={getVariantToolTip(rowData)}
                              style={{ width: "87px", textWrap: "wrap" }}
                            >
                              {rowData.attributeValue1 && (
                                <>
                                  <div> {rowData.attributeValue1}</div>
                                </>
                              )}
                              {rowData.attributeValue2 && (
                                <>
                                  <div> {rowData.attributeValue2}</div>
                                </>
                              )}
                              {rowData.attributeValue3 && (
                                <>
                                  <div> {rowData.attributeValue3}</div>
                                  <div style={{ color: "#1CB980" }}></div>
                                </>
                              )}
                              {(rowData.attributeValue1 ||
                                rowData.attributeValue2 ||
                                rowData.attributeValue3) && (
                                <div style={{ color: "#1CB980" }}>In Stock</div>
                              )}
                            </div>
                          }
                        </td>
                        <td>
                          <Input
                            className="custom-width"
                            prefix={getCurrencySymbol(props.companyInfo?.currencyCode)}
                            placeholder="Eg. 99"
                            value={rowData.purchasedPrice}
                            onChange={(e) => {
                              let val = props.inventoryItemVariantList[i];
                              val = { ...val, purchasedPrice: e.target.value };
                              let ans = props.inventoryItemVariantList;
                              ans[i] = val;
                              
                              updateDrawerState({
                                inventoryItemVariantList: ans,
                                variantObj:
                                  i == 0
                                    ? {
                                        ...variantObj,
                                        purchasedPrice: e.target.value,
                                      }
                                    : variantObj,
                              });
                            }}
                            // onChange={(e) => {
                            //     updateDrawerState({ variantObj: { ...variantObj, retailPrice: e.target.value } });
                            //   }}
                          />
                        </td>
                        <td>
                          <Input
                            className="custom-width"
                            prefix={getCurrencySymbol(props.companyInfo?.currencyCode)}
                            placeholder="Eg. 99"
                            value={rowData.maxRetailPrice}
                            onChange={(e) => {
                              let val = props.inventoryItemVariantList[i];
                              val = { ...val, maxRetailPrice: e.target.value };
                              let ans = props.inventoryItemVariantList;
                              ans[i] = val;
                              const totalCostValue =
                                Number(variantObj.purchasedPrice) +
                                  Number(variantObj.landedCost) || 0;
                              const updatedIsPercentage =
                                props.isRetailProfitMarginInPercentage ===
                                undefined
                                  ? props.isRetailProfitMarginInPercentage
                                  : props.isRetailProfitMarginInPercentage;
                              const value =
                                updatedIsPercentage === 1
                                  ? totalCostValue - e.target.value
                                  : (e.target.value / totalCostValue) * 100;
                              updateDrawerState({
                                inventoryItemVariantList: ans,
                                variantObj:
                                  i == 0
                                    ? {
                                        ...variantObj,
                                        maxRetailPrice: e.target.value,
                                      }
                                    : variantObj,
                              });
                            }}
                            // onChange={(e) => {
                            //     updateDrawerState({ variantObj: { ...variantObj, retailPrice: e.target.value } });
                            //   }}
                          />
                        </td>
                        <td>
                          <Input
                            className="custom-width"
                            prefix={getCurrencySymbol(props.companyInfo?.currencyCode)}
                            placeholder="Eg. 99"
                            style={{ marginLeft: "12px" }}
                            value={rowData.retailPrice}
                            onChange={(e) => {
                              let val = props.inventoryItemVariantList[i];
                              val = { ...val, retailPrice: e.target.value };
                              let ans = props.inventoryItemVariantList;
                              let totalCost =
                                Number(variantObj.purchasedPrice) +
                                Number(variantObj.landedCost);
                              ans[i] = val;
                              updateDrawerState({
                                inventoryItemVariantList: ans,
                                variantObj:
                                  i == 0
                                    ? {
                                        ...variantObj,
                                        retailPrice: e.target.value,
                                        retailProfitMargin:
                                          variantObj.isRetailProfitMarginInPercentage ===
                                          1
                                            ? fixedDecimalNumber(
                                                ((e.target.value - totalCost) /
                                                  totalCost) *
                                                  100
                                              )
                                            : fixedDecimalNumber(
                                                e.target.value - totalCost
                                              ),
                                        // maxRetailPrice:e.target.value
                                      }
                                    : variantObj,
                              });
                            }}
                          />
                        </td>
                        <td>
                          <Input
                            className="custom-width"
                            placeholder="Eg. 10000001"
                            value={rowData.sku || null}
                            disabled={props.useSystemSku}
                            onChange={(e) => {
                              let val = props.inventoryItemVariantList[i];
                              val = { ...val, sku: e.target.value };
                              let ans = props.inventoryItemVariantList;
                              ans[i] = val;
                              updateDrawerState({
                                inventoryItemVariantList: ans,
                                sku: props.sku,
                              });
                            }}
                          />
                        </td>
                        <td>
                          <Input
                            className="custom-width"
                            placeholder="Eg. 10"
                            value={rowData.currentStock}
                            disabled={props.itemId}
                            onChange={(e) => {
                              let val = props.inventoryItemVariantList[i];
                              val = { ...val, currentStock: e.target.value };
                              let ans = props.inventoryItemVariantList;
                              ans[i] = val;
                              const totalQty = ans.reduce(
                                (sum, variant) =>
                                  sum + parseInt(variant.currentStock || 0),
                                0
                              );

                            updateDrawerState({
                              inventoryItemVariantList: ans,
                              qtyCurrentStock: totalQty,
                              variantObj:
                                i == 0
                                  ? {
                                    ...variantObj,
                                    totalCurrentStock: totalQty,
                                    currentStock: e.target.value,
                                  }
                                  : variantObj,
                            });
                          }}
                        />
                      </td>
                      <td>
                        <Input.Group compact style={{ width: "135px" }}>
                          <Input
                            className="custom-width"
                            style={{ width: "55%", height: "30px" }}
                            placeholder="Eg. 1.2"
                            type="number"
                            maxLength={13}
                            value={rowData.itemWeight}
                            // onChange={(e) => {
                            //     updateDrawerState({
                            //         itemWeight: e.target.value,
                            //     });
                            // }}
                            onChange={(e) => {
                              let val = props.inventoryItemVariantList[i];
                              val = { ...val, itemWeight: e.target.value };
                              let ans = props.inventoryItemVariantList;
                              ans[i] = val;
                              updateDrawerState({
                                inventoryItemVariantList: ans,
                                variantObj:
                                  i == 0
                                    ? {
                                      ...variantObj,
                                      itemWeight: e.target.value,
                                    }
                                    : variantObj,
                              });
                            }}
                          />
                          <Select
                            defaultValue="Kg"
                            style={{ width: "45%" }}
                            value={variantObj.itemWeightUnit}
                            onSelect={(opt) => {
                              let val = props.inventoryItemVariantList[i];
                              val = { ...val, itemWeightUnit: opt };
                              let ans = props.inventoryItemVariantList;
                              ans[i] = val;
                              updateDrawerState({
                                inventoryItemVariantList: ans,
                                variantObj:
                                  i == 0
                                    ? { ...variantObj, itemWeightUnit: opt }
                                    : variantObj,
                              });
                            }}
                          >
                            <Option value="Kg">Kg</Option>
                            <Option value="gm">gm</Option>
                          </Select>
                        </Input.Group>
                      </td>

                      
                        <td>
                        <div >
            <Dropdown
             
              items={props.suppliers[1] || []}
              sortItems={true}
              valueKeyName='supplierStoreName'
              optionKeyName='supplierId'
              value={rowData?.supplierName||""}
              placeholder={intl.formatMessage({
                id: 'supplier.purchaseOrder.form.supplierName.placeholder', defaultMessage: ''
              })}
              moduleName={MODULE_CODED_VALUES.SUPPLIERS}
              canAddNew={true}
              allowClear={true}
              onClear={() => {
                updateState({
                  selectedSupplierName:{},
                })
              }}
              onAddNewClick={(payload) => {
                props.updateHeaderState({
                  supplier: {
                    supplierDrawerVisible: true,
                    supplierName: payload.textEntered,
                  }
                })
              }}
              onSelect={
                (supplierId) => {
                  const supplierVal = find(props.suppliers[1] || [], { supplierId: supplierId }) || {};
                  let val = props.inventoryItemVariantList[i];
                  val = { ...val, supplierId: supplierVal?.supplierId , supplierName: supplierVal?.supplierStoreName };
                  let ans = props.inventoryItemVariantList;
                  ans[i] = val;
                  updateDrawerState({
                    inventoryItemVariantList: ans,
                   
                  });
                 

                              }
                            }
                          />


                        </div>
                      </td>
                      <td>
                        <Dropdown
              
                
                  items={ props.allLedgerAccounts  || []}
                  valueKeyName='ledgerAccountName'
                  value={ rowData?.creditorLedgerName||(props.selectedCreditLedger?.ledgerAccountName)}
                  optionKeyName='chartOfAccountDetailsId'
                  
                  
                  onSelect={
                    (id) => {
                      const ledgerValue = find(props.allLedgerAccounts ,{chartOfAccountDetailsId: id})
                      let val = props.inventoryItemVariantList[i];
                      val = { ...val, creditorLedgerName: ledgerValue?.ledgerAccountName , creditorLedgerId: ledgerValue?.chartOfAccountDetailsId };
                      let ans = props.inventoryItemVariantList;
                      ans[i] = val;
                      updateDrawerState({
                        inventoryItemVariantList: ans,
                       
                      });
                     
                    }
                  }
                />
                        </td>
                        <td>
                        <Dropdown
               
                
                  items={ props.allLedgerAccounts  || []}
                  valueKeyName='ledgerAccountName'
                  value={ rowData?.debtorLedgerName ||  (props.selectedDebitorLedger?.ledgerAccountName) }
                  optionKeyName='chartOfAccountDetailsId'
                  
                  
                  onSelect={
                    (id) => {
                      const ledgerValue = find(props.allLedgerAccounts ,{chartOfAccountDetailsId: id})
                      let val = props.inventoryItemVariantList[i];
                      val = { ...val, debtorLedgerName: ledgerValue?.ledgerAccountName , debtorLedgerId: ledgerValue?.chartOfAccountDetailsId };
                      let ans = props.inventoryItemVariantList;
                      ans[i] = val;
                      updateDrawerState({
                        inventoryItemVariantList: ans,
                       
                      });
                     
                    }
                  }
                />
                        </td>
                        <td>
                          {/* <i className={ICONS.DELETE} onClick={() => {
                                                    const tempArr = inventoryItemVariantList;
                                                    tempArr.splice(i, 1);
                                                    updateDrawerState({ inventoryItemVariantList: tempArr });
                                                }} /> */}

                          <i
                            className={ICONS.DELETE}
                            style={{
                              display: i === 0 ? "none" : "inline-block",
                            }}
                            onClick={() => {
                              if (i !== 0) {
                                const tempArr = [...inventoryItemVariantList];
                                tempArr.splice(i, 1);
                                updateDrawerState({
                                  inventoryItemVariantList: tempArr,
                                });
                              } else {
                              }
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })
                : ""}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(ProductDetailsTab);
