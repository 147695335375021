import download from 'downloadjs';
import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS, SUPPLIER_ACTION_LIST } from '../../../static/constants';
import { showToasterMessage, getFinancialYear, getPaths } from '../../../utils';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchSupplierTransactions = (payload) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/supplierTransaction/getSupplierTransactions?supplierId=${payload.supplierId}&relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1) - 1}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}&filterStartDate=&filterEndDate=`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: SUPPLIER_ACTION_LIST.SUPPLIER_FETCH_TRANSACTIONS, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = SUPPLIER_ACTION_LIST.SUPPLIER_FETCH_TRANSACTIONS;
          err.data = {
            pageNo: payload.pageNumber,
            list: []
          };
        }
      })
  }
}

export const fetchSupplierTransactionCount = (payload) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/supplierTransaction/getSupplierTransactionsCount?supplierId=${payload.supplierId}&relationshipId=${payload.relationshipId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}&filterStartDate=&filterEndDate=`)
      .then(res => {
        dispatch({ type: SUPPLIER_ACTION_LIST.SUPPLIER_FETCH_TRANSACTIONS_COUNT, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = SUPPLIER_ACTION_LIST.SUPPLIER_FETCH_TRANSACTIONS_COUNT;
        }
      })
  }
}

export const fetchSupplierPurchaseOrderTransactions = (payload) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/purchaseOrder/getPurchaseOrdersBySupplierId?supplierId=${payload.supplierId}&relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1) - 1}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}&filterStartDate=&filterEndDate=`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: SUPPLIER_ACTION_LIST.SUPPLIER_FETCH_PURCHASE_ORDER_TRANSACTIONS, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = SUPPLIER_ACTION_LIST.SUPPLIER_FETCH_PURCHASE_ORDER_TRANSACTIONS;
          err.data = {
            pageNo: payload.pageNumber,
            list: []
          };
        }
      })
  }
}

export const fetchSupplierPurchaseOrderTransactionCount = (payload) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/supplierTransaction/getPurchaseTxCount?supplierId=${payload.supplierId}&relationshipId=${payload.relationshipId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}&filterStartDate=&filterEndDate=`)
      .then(res => {
        dispatch({ type: SUPPLIER_ACTION_LIST.SUPPLIER_FETCH_PURCHASE_ORDER_TRANSACTIONS_COUNT, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = SUPPLIER_ACTION_LIST.SUPPLIER_FETCH_PURCHASE_ORDER_TRANSACTIONS_COUNT;
        }
      })
  }
}

export const fetchSupplierInvoiceList = (payload) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      // .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/supplierInvoice/getSupplierInvoicesBySupplierId?supplierId=${payload.supplierId}&relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1) - 1}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}&filters=${encodeURIComponent(JSON.stringify(payload.filters || {}))}`)
      .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/supplierInvoice/getInvoiceSummary?supplierId=${payload.supplierId}&relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1) - 1}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}&filters=${encodeURIComponent(JSON.stringify(payload.filters || {}))}`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: SUPPLIER_ACTION_LIST.SUPPLIER_FETCH_INVOICE_LIST, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = SUPPLIER_ACTION_LIST.SUPPLIER_FETCH_INVOICE_LIST;
          err.data = {
            pageNo: payload.pageNumber,
            list: []
          };
        }
      })
  }
}

export const fetchSupplierInvoiceCount = (payload) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/supplierInvoice/getRevenueDataCountBySupplierId?supplierId=${payload.supplierId}&relationshipId=${payload.relationshipId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}&filters=${encodeURIComponent(JSON.stringify(payload.filters || {}))}`)
      .then(res => {
        dispatch({ type: SUPPLIER_ACTION_LIST.SUPPLIER_FETCH_INVOICE_LIST_COUNT, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = SUPPLIER_ACTION_LIST.SUPPLIER_FETCH_INVOICE_LIST_COUNT;
        }
      })
  }
}

export const fetchSupplierDebitMemoList = (payload) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/debitMemo/getDebitMemosBySupplierId?supplierId=${payload.supplierId}&relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1) - 1}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}&filterStartDate=&filterEndDate=`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: SUPPLIER_ACTION_LIST.SUPPLIER_FETCH_DEBIT_MEMO_LIST, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = SUPPLIER_ACTION_LIST.SUPPLIER_FETCH_DEBIT_MEMO_LIST;
          err.data = {
            pageNo: payload.pageNumber,
            list: []
          };
        }
      })
  }
}

export const fetchSupplierDebitMemoListCount = (payload) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/debitMemo/totalCountBySupplierId?supplierId=${payload.supplierId}&relationshipId=${payload.relationshipId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}&filterStartDate=&filterEndDate=`)
      .then(res => {
        dispatch({ type: SUPPLIER_ACTION_LIST.SUPPLIER_FETCH_DEBIT_MEMO_LIST_COUNT, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = SUPPLIER_ACTION_LIST.SUPPLIER_FETCH_DEBIT_MEMO_LIST_COUNT;
        }
      })
  }
}

export const fetchSupplierPaymentList = (payload) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/supplierPayment/getPaymentsBySupplierId?supplierId=${payload.supplierId}&relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1) - 1}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}&filterStartDate=&filterEndDate=`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: SUPPLIER_ACTION_LIST.SUPPLIER_FETCH_PAYMENT_LIST, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = SUPPLIER_ACTION_LIST.SUPPLIER_FETCH_PAYMENT_LIST;
          err.data = {
            pageNo: payload.pageNumber,
            list: []
          };
        }
      })
  }
}

export const fetchSupplierPaymentListCount = (payload) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/supplierPayment/getTotalPaymentCountBySupplierId?supplierId=${payload.supplierId}&relationshipId=${payload.relationshipId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}&filterStartDate=&filterEndDate=`)
      .then(res => {
        dispatch({ type: SUPPLIER_ACTION_LIST.SUPPLIER_FETCH_PAYMENT_LIST_COUNT, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = SUPPLIER_ACTION_LIST.SUPPLIER_FETCH_PURCHASE_ORDER_TRANSACTIONS_COUNT;
        }
      })
  }
}

export const resetTransactionListData = () => {
  return dispatch => {
    dispatch({ type: SUPPLIER_ACTION_LIST.SUPPLIER_TRANSACTIONS_RESET });
  }
}

export const resetPurchaseOrderTransactionsListData = () => {
  return dispatch => {
    dispatch({ type: SUPPLIER_ACTION_LIST.SUPPLIER_PURCHASE_ORDER_TRANSACTIONS_RESET });
  }
}

export const resetInvoiceTransactionListData = () => {
  return dispatch => {
    dispatch({ type: SUPPLIER_ACTION_LIST.SUPPLIER_INVOICE_TRANSACTIONS_RESET });
  }
}

export const resetPaymentTransactionListData = () => {
  return dispatch => {
    dispatch({ type: SUPPLIER_ACTION_LIST.SUPPLIER_PAYMENT_TRANSACTIONS_RESET });
  }
}

export const resetDebitMemoTransactionListData = () => {
  return dispatch => {
    dispatch({ type: SUPPLIER_ACTION_LIST.SUPPLIER_DEBIT_MEMO_TRANSACTIONS_RESET });
  }
}

export const fetchSupplierCommunications = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/supplierCommunication/getSupplierCommunication?relationshipId=${payload.relationshipId}&supplierId=${payload.supplierId}&pageSize=500&pageNumber=0`)
      .then(res => {
        dispatch({ type: SUPPLIER_ACTION_LIST.SUPPLIER_FETCH_COMMUINACTIONS, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = SUPPLIER_ACTION_LIST.SUPPLIER_FETCH_COMMUINACTIONS;
        }
      })
  }
}

export const fetchSupplierActivities = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/supplierActivity/getAllSupplierActivities?relationshipId=${payload.relationshipId}&supplierId=${payload.supplierId}&pageSize=500&pageNumber=0`)
      .then(res => {
        dispatch({ type: SUPPLIER_ACTION_LIST.SUPPLIER_FETCH_ACTIVITIES, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = SUPPLIER_ACTION_LIST.SUPPLIER_FETCH_ACTIVITIES;
        }
      })
  }
}

export const deleteSupplierActivities = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/supplierActivity/deleteActivity`, payload)
      .then(res => {
        dispatch({ type: SUPPLIER_ACTION_LIST.SUPPLIER_DELETE_ACTIVITIES, data: payload });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({ messageType: 'success', description: 'Task deleted successfully' });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = SUPPLIER_ACTION_LIST.SUPPLIER_DELETE_ACTIVITIES;
        }
      })
  }
}

export const fetchSupplierContacts = (payload) => {

  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/boContact/getAllContacts?relationshipId=${payload.relationshipId}&supplierId=${payload.supplierId}&pageSize=500&pageNumber=0`)
      .then(res => {
        dispatch({ type: SUPPLIER_ACTION_LIST.SUPPLIER_FETCH_CONTACTS, data: res.data });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = SUPPLIER_ACTION_LIST.SUPPLIER_FETCH_CONTACTS;
        }
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
      })
  }
}

export const fetchSupplierAddresses = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/boLocationRest/getAddressBySupplierId?relationshipId=${payload.relationshipId}&supplierId=${payload.supplierId}&pageSize=500&pageNumber=0`)
      .then(res => {
        dispatch({ type: SUPPLIER_ACTION_LIST.SUPPLIER_FETCH_ADDRESSES, data: res.data });
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })

      })
  }
}

export const fetchSupplierToDoTasks = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContext}/toDoTask/getSupplierTasks?relationshipId=${payload.relationshipId}&supplierId=${payload.supplierId}&pageSize=500&pageNumber=0`)
      .then(res => {
        dispatch({ type: SUPPLIER_ACTION_LIST.SUPPLIER_FETCH_TASKS, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = SUPPLIER_ACTION_LIST.FETCH_TASKS;
        }
      })
  }
}


export const createUpdateTask = (payload, toasterMessage = 'Data saved successfully') => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContext}/toDoTask/saveOrUpdate`, payload)
      .then(res => {
        dispatch({ type: SUPPLIER_ACTION_LIST.ADD_UPDATE_TASK, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({ messageType: 'success', description: toasterMessage });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = SUPPLIER_ACTION_LIST.ADD_UPDATE_TASK;
        }
      })
  }
}

export const deleteTask = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContext}/toDoTask/saveOrUpdate`, payload)
      .then(res => {
        dispatch({ type: SUPPLIER_ACTION_LIST.DELETE_TASK, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({ messageType: 'success', description: 'Task deleted successfully' });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = SUPPLIER_ACTION_LIST.DELETE_TASK;
        }
      })
  }
}

export const exportTxPdf = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios.post(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/supplierPdf/getSupplierTxPdf`,
      payload,
      {
        responseType: 'blob',
      }
    )
      .then(response => {
        const content = response.headers['content-type'];
        download(response.data, `${payload.txType.length === 1 ? payload.txType[0] : 'AllTransactions'}.pdf`, content);
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch(err => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
  }
}

export const exportLedgerPdf = (payload, props, isDownload) => {
  let FY = getFinancialYear(props.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios.get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/supplierTransaction/getSupplierLedgerData?relationshipId=${props.companyInfo.relationshipId}&supplierId=${payload.supplierId}&pageSize=50&pageNumber=0&&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}&filterStartDate=${payload.startDate}&filterEndDate=${payload.endDate}`
    )
      .then(response => {

        dispatch({ type: SUPPLIER_ACTION_LIST.SUPPLIER_LEDGER_LIST, data: response.data });
        if (isDownload) {
          var totalDebit = 0;
          var totalCredit = 0;
          response.data && response.data.forEach((row, index) => {
            if (row.txType === "Payment") {
              totalCredit += Number(row.txAmount || 0);
            } else if (row.txType === "Invoice" || row.txType === "Expense" || row.txType === "Debit Memo") {
              totalDebit += Number(row.txAmount);
            }
          });
          var data = {
            fileName: 'Supplier Ledger Report.pdf',
            transactionName: 'supplierLedgerReport',
            supplierObj: payload.supplierObj,
            companyInfo: props.companyInfo,
            relationshipAddress: payload.relationshipAddress,
            ledgerData: response.data,
            fyStartDate: FY.fyStartDate,
            fyEndDate: FY.fyEndDate,
            totalDebit: totalDebit,
            totalCredit: totalCredit
          }
          if (payload.filterStartDate && payload.filterEndDate) {
            data.filterStartDate = payload.filterStartDate;
            data.filterEndDate = payload.filterEndDate;
          }
          props.generatePDF(data);
        }
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch(err => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
  }
}
export const resetSupplierLedgerData = () => {
  return dispatch => {
    dispatch({ type: SUPPLIER_ACTION_LIST.RESET_SUPPLIER_LEDGER_LIST });
  }
}

export const fetchSupplierLedgerTxSummaryList = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/supplierInvoice/getSupplierLedgerSummary?&relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1) - 1}&filters=${encodeURIComponent(JSON.stringify(payload.filters || {}))}`)
      .then(res => {

        dispatch({ type: SUPPLIER_ACTION_LIST.SUPPLIER_INVOICE_LEDGER_SUMMARY_LIST, data: res.data });
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
   
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        
      })
  }
}
export const getTotalOrderCountBySupplierId = (payload, history) => {
  return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });

    return axios
      .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/purchaseOrder/getTotalOrderCountBySupplierId?relationshipId=${payload.relationshipId}&supplierId=${payload.supplierId}`)
      .then(res => {
        dispatch({ type: SUPPLIER_ACTION_LIST.SUPPLIER_TOTAL_ORDER_COUNT_BY_SUPPLIER_ID, data: res.data });

      })
      .catch((err) => {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
       
      })
  }
}

export const fetchSupplierTaxIdentifications = (payload, history) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/supplierTaxIdentification/getTaxIdentificationsBySupplierId?relationshipId=${payload.relationshipId}&supplierId=${payload.supplierId}`)
      .then((res) => {
        dispatch({
          type: SUPPLIER_ACTION_LIST.SUPPLIER_TAX_IDENTIFICATION_LIST,
          data: res.data,
        });
      })
      .catch((err) => {
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
      })
  };
};