import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMMON_ACTIONS } from '../../../../static/constants';
import { showToasterMessage } from '../../../../utils';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const createLeadSource = (payload, props) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.CRM_BASE_URL}${config.API_VERSION}/${config.rootContextCRM}/leadSource/saveOrUpdateSource`, payload)
        .then(res => {
          showToasterMessage({
            description: (res || {}).message || 'Created successfully.',
            messageType: 'success'
          })
          const payload = {
            relationshipId: props.companyInfo.relationshipId,
            pageNumber: 0,
            pageSize: 25,
          }
          props.getAllLeadSources(payload);
        })
        .catch((err = {}) => {
            showToasterMessage({
                description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
            })
        })
    }
  }