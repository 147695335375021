import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS, CUSTOMER_ACTION_LIST, CONSTANTS , SUPPLIER_ACTION_LIST} from '../../../../../static/constants';
import { getPaths, showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const createAddress = (payload, formData) => {
  let actionType = '';

  // Need to update switch for supplier
  switch (payload.locationType) {
    case CONSTANTS.BILLING_ADDRESS:
    case CONSTANTS.SHIPPING_ADDRESS: {
      actionType = payload.customerId ? CUSTOMER_ACTION_LIST.ADD_CUSTOMER_BO_LOCATION : SUPPLIER_ACTION_LIST.ADD_SUPPLIER_BO_LOCATION;
      break;
    }
    case CONSTANTS.RELATIONSHIP_SHIPPING_ADDRESS :
    case CONSTANTS.RELATIONSHIP_BILLING_ADDRESS : {
      actionType = COMMON_ACTIONS.BO_LOCATION_ADDITION;
      break;
    }
    default:
      break;
  }
  
  const paths = getPaths(payload);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${paths.API_BASE_URL}${config.API_VERSION}/${paths.rootContext}/boLocationRest/createAddress`, payload)
      .then(res => {
       
        dispatch({ type:  actionType, data: res.data , formData});
        if(formData.funcToCall){
          formData.funcToCall(formData.funcToCallPayload)
        }
        if(formData.callback){
          formData.callback(res.data)
        }
        showToasterMessage({ messageType: 'success', description: 'Address saved successfully' });
      })
      .catch((err) => {
        
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else {
          err.actionToCall = actionType;
        }
      })
  }
}

