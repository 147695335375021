import React from "react";
import { Switch } from "react-router-dom";
import { injectIntl } from "react-intl";
import StudentInvoice from "../containers/customer/invoice/StudentInvoice";
import SalesInvoice from "../containers/customer/invoice/StandardSalesInvoice";
import PerformaInvoice from "../containers/customer/invoice/StandardPerformaInvoice";
import MultiCurrencySalesInvoice from "../containers/customer/invoice/MultiCurrencySalesInvoice";
import StandardSalesQuote from "../containers/customer/salesQuote/StandardSalesQuote";
import MultiCurrencySalesQuote from "../containers/customer/salesQuote/MultiCurrencySalesQuote";
import Dashboard from "../containers/Dashboard";
import SupplierListing from "../containers/supplier/Listing";
import PageCreateComp from "../containers/eStore/pages/pageCreate";
import CustomersPage from "../containers/customer/Listing";
import EmployeeAttendance from "../containers/company/EmployeeAttendance";
import ProductListing from "../containers/product/Listing";
import PrivateRoute from "./privateRoute";
import PurchaseInvoice from "../containers/supplier/invoice/StandardPurchaseInvoice";
import MultiCurrencyPurchaseInvoice from "../containers/supplier/invoice/MultiCurrencyPurchaseInvoice";
import PurchaseRequestListing from "../containers/supplier/purchaseRequest/Listing";
import RFQListing from "../containers/supplier/rfq/Listing";
import PurchaseOrderListing from "../containers/supplier/purchaseOrder/Listing";
import InboundDeliveryListing from "../containers/supplier/InboundDelivery/Listing";
import StandardPurchaseOrder from "../containers/supplier/purchaseOrder/StandardPurchaseOrder";
import InboundDelivery from "../containers/supplier/InboundDelivery";
import MultiCurrencyPurchaseOrder from "../containers/supplier/purchaseOrder/MultiCurrencyPurchaseOrder";
import StandardDebitMemo from "../containers/supplier/debitMemo/StandardDebitMemo";
import StandardPurchaseRequest from "../containers/supplier/purchaseRequest/StandardPurchaseRequest";
import StandardRFQ from "../containers/supplier/rfq/StandardRFQ";
import StandardSalesEnquiry from "../containers/customer/salesEnquiry/StandardSalesEnquiry";
import SalesEnquiryList from "../containers/customer/salesEnquiry/Listing";
import SplitRFQ from "../containers/supplier/rfq/SplitRFQ";
import PurchaseInvoiceListing from "../containers/supplier/invoice/Listing";
import SupplierPaymentListing from "../containers/supplier/payment/Listing";
import SupplierExpenseListing from "../containers/supplier/expense/Listing";
import DebitMemoListing from "../containers/supplier/debitMemo/Listing";
import SalesQuoteListing from "../containers/customer/salesQuote/Listing";
import SalesOrderListing from "../containers/customer/salesOrder/Listing";
import PackageListing from "../containers/customer/package/Listing";
import ShipmentListing from "../containers/customer/shipment/Listing";
import SalesOrderCreation from "../containers/customer/salesOrder/StandardSalesOrder";
import MultiCurrencySalesOrder from "../containers/customer/salesOrder/MultiCurrencySalesOrder";
import SalesOrderPackage from "../containers/customer/salesOrder/package";
import SalesOrderShipment from "../containers/customer/salesOrder/shipment";
import CreditMemoCreation from "../containers/customer/creditMemo/StandardCreditMemo";
import SalesInvoiceListing from "../containers/customer/invoice/Listing";
import CustomerPaymentListing from "../containers/customer/payment/Listing";
import CreditMemoListing from "../containers/customer/creditMemo/Listing";
import SalesComplaintListing from "../containers/customer/salesComplaint/Listing";
import CustomerExpenseListing from "../containers/customer/expense/Listing";
import BalanceSheet from "../containers/finance/BalanceSheet";
import LedgerAccounts from "../containers/finance/LedgerAccounts";
import CustomerDetails from "../containers/customer/detail";
import SupplierDetails from "../containers/supplier/detail";
import ProductDetails from "../containers/product/detail";
import ProfitAndLoss from "../containers/finance/ProfitAndLoss";
import TrialBalances from "../containers/finance/TrialBalance";
import BankReconciliation from "../components/finance/BankReconciliation";
import OpeningBalance from "../containers/finance/OpeningBalance";
import Journals from "../containers/finance/Journals";
import JournalCreate from "../containers/finance/Journals/JournalCreate";
import Taxes from "../containers/finance/Taxes";
import FinancialYears from "../containers/finance/FinancialYears";
import CostCenters from "../containers/finance/CostCenters";
import Banking from "../containers/finance/Banking";
import EInvoiceGstr1FillingComp  from "../containers/finance/GST"
import BlogList from "../containers/eStore/blogs/blogList"
import BusinesExpenseListing from "../containers/finance/BusinessExpense/Listing";
import BusinesExpenseCreate from "../containers/finance/BusinessExpense/Create";
import ShowSettings from "../containers/settings";
import UserManagement from "../containers/settings/userManagement";
import PriceList from "../containers/inventory/priceList/Listing";
import CategoryListing from "../containers/inventory/extras/category/Listing";
import BrandListing from "../containers/inventory/extras/brands/Listing";
import ManufacturerListing from "../containers/inventory/extras/manufacturer/Listing";
import UOMListing from "../containers/inventory/extras/uom/Listing";
import ShowReports from "../containers/reports";
import AgedStockListing from "../containers/inventory/warehouse/agedStock/Listing";
import MyWarehouseListing from "../containers/inventory/warehouse/myWarehouse/Listing";
import StockTransit from "../containers/inventory/stockInTransit";
import StockAdjustmentrListing from "../containers/inventory/warehouse/stockAdjustment/Listing";
import StockSummaryListing from "../containers/inventory/warehouse/stockSummary/Listing";
import ProductPriceListing from "../containers/inventory/productPrice/Listing";
import Accounting from "../containers/integrations/Accounting";
import CompanyHierarchy from "../containers/company/CompanyHierarchy";
import EmployeeList from "../containers/company/Employee";
import SalesChannels from "../containers/integrations/SalesChannels";
import Payment from "../containers/integrations/Payment";
import Shipping from "../containers/integrations/Shipping";
import Projects from "../containers/company/Project";
import StudentRegistrationListing from "../containers/customer/studentRegistration/Listing";
import { PERMISSION_VALUES, MODULE_CODED_VALUES } from "../static/constants";
import PublishedStock from "../containers/ecommerce/PublishedStock";
import PublishedCategory from "../containers/ecommerce/PublishedCategory";
import ProductOnlineStorePrice from "../containers/ecommerce/productPrice/Listing";
import SalesChannelAmazon from "../containers/salesChannels/amazon";
import SalesChannelEBay from "../containers/salesChannels/ebay";
import SalesChannelWalmart from "../containers/salesChannels/walmart";
import EasyPost from "../containers/shipping/easyPost";
import ShipStation from "../containers/shipping/shipStation";
import "./index.scss";
import TermAndFee from "../containers/membership/termAndFee";
import SchoolContainer from "../containers/schoolManagement/school";
import StudentDashboardContainer from "../containers/schoolManagement/dashboard";
import SubjectContainer from "../containers/schoolManagement/subject";
import SectionContainer from "../containers/schoolManagement/section";
import ClassContainer from "../containers/schoolManagement/class";
import GradeContainer from "../containers/schoolManagement/grade";
import InvoiceContainer from "../containers/schoolManagement/invoice";
import CourseContainer from "../containers/schoolManagement/course";
import AttendanceContainer from "../containers/schoolManagement/attendence";
import DepartmentContainer from "../containers/schoolManagement/department";
import TeacherContainer from "../containers/schoolManagement/teacher";
import StudentContainer from "../containers/schoolManagement/student";
import ParentContainer from "../containers/schoolManagement/parent";
import CommunicationContainer from "../containers/schoolManagement/Communication";
import CourseTermContainer from "../containers/schoolManagement/courseTerm";
import MembershipOption from "../containers/membership/membershipOption";
import NewsLetter from "../containers/NewsLetter";
import TaxesComp from "../containers/settings/taxes";
import MarketplaceStatesTaxComp from "../containers/settings/taxes/marketplaceState";
import Campaign from "../containers/fundraising/campaign";
import CampaignDetail from "../containers/fundraising/campaign/campaignDetaill";
import Pledge from "../containers/fundraising/pledges";
import ShippingComp from "../containers/settings/shipping";
import ShippingRuleComp from "../containers/settings/shipping/shippingRule";
import DistributionGroup from "../containers/communication/distribution-group";
import CommunicationContact from "../containers/communication/contact";
import AccountComp from "../containers/communication/account";
import KioskComp from "../containers/kiosk";
import MembershipFormList from "../containers/membership/membershipFormList";
import NewLetterListing from "../containers/communication/NewLetterListing";
import EventListing from "../containers/communication/EventListing";
import StudentRegFormListing from "../containers/Student/studentFormList";
import FundraisingDonationListing from "../containers/fundraising/Donation";
import SponsorshipListing from "../containers/fundraising/sponsorship";
import CreateSponsorship from "../containers/fundraising/sponsorship/CreateSponsorship";
import OnlineUserContainer from "../containers/schoolManagement/onlineUser";
import RecurringPaymentContainer from "../containers/schoolManagement/recurringPayment";
import BroadcastMessageContainer from "../containers/schoolManagement/BroadcastMessage";
import AssignmentContainer from "../containers/schoolManagement/assignment";
import FinancialReportContainer from "../containers/schoolManagement/FinancialReport";
import FamilyListing from "../containers/schoolManagement/familyListing";
import AssignmentResponse from "../containers/schoolManagement/AssignmentResponse";
import AssignmentResponseReview from "../containers/schoolManagement/AssignmentResponsePreview";
import HsnContainer from "../containers/finance/Hsn";
import orgMembership from "../containers/membership/orgMembership";
import AllInventoryValuation from "../containers/inventory/inventoryValuation/allInvSummary";
import SalesChannelShopify from "../containers/salesChannels/shopify";
import SalesChannelCarrefour from "../containers/salesChannels/carrefour";
import SalesChannelNoon from "../containers/salesChannels/noon";
import MonthlyInventoryValuation from "../containers/inventory/inventoryValuation/monthlyInvSummary";
import DailyInventoryValuation from "../containers/inventory/inventoryValuation/dailyInvSummary";
import LandedCostSetupListing from "../containers/supplier/landedCostSetup";
import LandedCostListing from "../containers/supplier/landedCost";
import NotFound from "../components/Common/NotFound";
// import NotFound404 from "../components/Common/404notFound";
import StoreSettings from "../containers/eStore/storeSettings";
import Menus from "../containers/eStore/menus";
// import LeadForm from "../components/leads/LeadForm";
import LeadFormComp from "../containers/leads/LeadForm";
import AllLeadsListComp from "../containers/leads/AllLeads";
import { FormMataComp } from "../containers/leads/FormMeta";
import { LeadSubSourceListComp } from "../containers/leads/LeadSubSourceList";
import LeadSourceListComp from "../containers/leads/LeadSourceList";
import LeadStageListComp from "../containers/leads/LeadStageList";
import MyLeadListComp from "../containers/leads/MyLeadList";
import TimeTableCont from "../containers/schoolManagement/TimeTable";
import ThemesComp from "../containers/eStore/themes";
import PageComp from "../containers/eStore/pages";
import CustomizeThemesComp from "../containers/eStore/themes/customizeThemes";
import RegistrationsList from "../containers/schoolManagement/formRegistrations";
import DayBookListComp from "../containers/finance/DayBook";
import JournalReportComp from "../containers/finance/JournalReport";
import CustomersList from "../containers/customer/customersList";
import CashBookListComp from "../containers/finance/CashBook";
import BankBookListComp from "../containers/finance/BankBook";
import BillwiseDetailComp from "../containers/finance/BillwiseDetail";
import CloudDrive from "../containers/cloudDrive";
import Integrations from "../containers/integrations"
import EmployeeMetaContainer from "../containers/company/EmployeeMeta";
import AttendanceMetaContainer from "../containers/company/AttendanceMeta";
import PayrollContainer from "../containers/company/PayrollMeta";
import EmployeeContainer from "../containers/company/EmployeeManagement/Employee";
import WarningContainer from "../containers/company/EmployeeManagement/Warning";
import PromotionContainer from "../containers/company/EmployeeManagement/Promotion";
import TerminationContainer from "../containers/company/EmployeeManagement/Termination";
import LeaveManagement from "../containers/company/LeaveManagement";
import MyAttedance from "../containers/company/AttendanceManagement/MyAttendance";
import AttendanceSummary from "../containers/company/AttendanceManagement/AttendanceSummary";
import AttendanceIp from "../containers/company/AttendanceManagement/AttendanceIp";
import Allowance from "../containers/company/PayrollManagement/Allowance";
import Deduction from "../containers/company/PayrollManagement/Deduction";
import MonthlyPayGrade from "../containers/company/PayrollManagement/MonthlyPayGrade";
import HourlyPayGrade from "../containers/company/PayrollManagement/HourlyPayGrade";
import SalarySheet from "../containers/company/PayrollManagement/SalarySheet";
import PaymentHistory from "../containers/company/PayrollManagement/PaymentHistory";
import MyPayroll from "../containers/company/PayrollManagement/MyPayroll";
import Bonus from "../containers/company/PayrollManagement/Bonus";
import LeaveMeta from "../containers/company/LeaveMeta";
import PayGrade from "../containers/company/PayrollManagement/PayGrade";
import LeadCallLogs from "../containers/leads/CallLogs";
import LeadCampaingn from "../containers/leads/LeadCampaigns";
import LeadNotesList from "../containers/leads/LeadNotes";
import LeadTaskList from "../containers/leads/LeadTask";
import LeadTransfer from "../containers/leads/LeadTransfer";
import LeadReminder from "../containers/leads/Reminder";
import EmailCampaign from "../containers/emailMarketing/emailCampaigns";
import EmailTemplate from "../containers/emailMarketing/emailTemplate";
import CouponManagement from "../containers/modal/modalBody/eStore/Coupon";
import CategoryList from "../containers/eStore/blogs/categoryList";
import CommunicationTemplate from "../containers/communication/communicationTemplate";
import { LeadWorkflowComp } from "../containers/leads/LeadWorkFlow";
import LeadOpportunity from "../containers/leads/LeadOpportunity";
import CRMDashboard from "../containers/CRMDashboard";import MyProfile from "../containers/Account/MyProfile";
import ProfileSetting from "../containers/Account/ProfileSetting";
import TeamRoles from "../containers/Account/TeamRoles";
import Subscription from "../containers/Account/Subscription";import QuantityListing from "../containers/inventory/extras/quantity";
import Testimonials from "../containers/eStore/testimonials";
import RatingAndReview from "../containers/product/RatingAndReview";
import Account from "../containers/Account";import DashboardCon from "../containers/Dashboard/dashboard";
import AttendanceReport from "../containers/company/AttendanceManagement/AttendanceReport";


const PartialViewRoute = (props) => {

  return (
    <div
      className={`view-container-wrapper expendedView`}
      onClick={() => {
        // props.updateHeaderState({ activeMasterMenu: false });
        props.updateHeaderState({ activeMenuList: [] });
      }}
    >
      <Switch>
        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/dashboard"
          component={Dashboard}
          moduleCode= {MODULE_CODED_VALUES.ERP_DASHBOARD}
          operation={PERMISSION_VALUES.READ}
        /> */}
        <PrivateRoute
          {...props}
          exact
          path="/admin/suppliers"
          component={SupplierListing}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.SUPPLIERS}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/customers"
          component={CustomersPage}
          // component={CustomersList}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.CUSTOMERS}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/employee-attendance"
          component={EmployeeAttendance}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.HRMS_EMPLOYEE_ATTENDANCE}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/sales-invoice"
          component={SalesInvoice}
          moduleCode={MODULE_CODED_VALUES.SALES_INVOICE}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/student-invoice"
          component={StudentInvoice}
          moduleCode={MODULE_CODED_VALUES.SCHOOL_INVOICE}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/school-management/recurring-payment"
          component={RecurringPaymentContainer}
          moduleCode={MODULE_CODED_VALUES.SCHOOL_RECURRING_PAYMENTS}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/"
          component={SalesInvoice}
          moduleCode={MODULE_CODED_VALUES.SALES_INVOICE}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/performa-invoice"
          component={PerformaInvoice}
          moduleCode={MODULE_CODED_VALUES.SALES_INVOICE}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/sales-invoice-multi-currency"
          component={MultiCurrencySalesInvoice}
          moduleCode={MODULE_CODED_VALUES.SALES_INVOICE_MULTI_CURRENCY}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/sales-invoice-update"
          component={SalesInvoice}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/purchase-invoice"
          component={PurchaseInvoice}
          moduleCode={MODULE_CODED_VALUES.PURCHASE_INVOICE}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/purchase-invoice-update"
          component={PurchaseInvoice}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/purchase-invoice-multi-currency"
          component={MultiCurrencyPurchaseInvoice}
          moduleCode={MODULE_CODED_VALUES.PURCHASE_INVOICE_MULTI_CURRENCY}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/products"
          component={ProductListing}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.PRODUCTS}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/purchase-request-list"
          component={PurchaseRequestListing}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.PURCHASE_REQUEST}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/rfq-list"
          component={RFQListing}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.RFQ}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/purchase-order-list"
          component={PurchaseOrderListing}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.PURCHASE_ORDER}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/inbound-delivery-list"
          component={InboundDeliveryListing}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.PURCHASE_GRN}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/purchase-invoice-list"
          component={PurchaseInvoiceListing}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.PURCHASE_INVOICE}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/supplier-payment-list"
          component={SupplierPaymentListing}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.PURCHASE_PAYMENT}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/debit-memo-list"
          component={DebitMemoListing}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.DEBIT_MEMO}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/supplier-expense-list"
          component={SupplierExpenseListing}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.SUPPLIER_EXPENSE}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/create-credit-memo"
          component={CreditMemoCreation}
          moduleCode={MODULE_CODED_VALUES.CREDIT_MEMO}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/update-credit-memo"
          component={CreditMemoCreation}
          operation={PERMISSION_VALUES.UPDATE}
          moduleCode={MODULE_CODED_VALUES.CREDIT_MEMO}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/update-credit-memo"
          component={CreditMemoCreation}
          operation={PERMISSION_VALUES.READ}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/sales-inquiry-list"
          component={SalesEnquiryList}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.SALES_INQUIRY}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/sq-list"
          component={SalesQuoteListing}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.SALES_QUOTE}
        />

        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/create-sales-quote"
          component={StandardSalesQuote}
          moduleCode={MODULE_CODED_VALUES.SALES_QUOTE}
        /> */}

        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/create-sales-quote-multi-currency"
          component={MultiCurrencySalesQuote}
          moduleCode={MODULE_CODED_VALUES.SALES_QUOTE_MULTI_CURRENCY}
        /> */}

        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/update-sales-quote"
          component={StandardSalesQuote}
          operation={PERMISSION_VALUES.UPDATE}
          moduleCode={MODULE_CODED_VALUES.SALES_QUOTE}
        /> */}

        <PrivateRoute
          {...props}
          exact
          path="/admin/so-list"
          component={SalesOrderListing}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.SALES_ORDER}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/package-list"
          component={PackageListing}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.SALES_ORDER_PACKAGE}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/shipment-list"
          component={ShipmentListing}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.SALES_ORDER_SHIPMENT}
        />
        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/so-create"
          component={SalesOrderCreation}
          moduleCode={MODULE_CODED_VALUES.SALES_ORDER}
        /> */}
        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/so-update"
          component={SalesOrderCreation}
          operation={PERMISSION_VALUES.UPDATE}
          moduleCode={MODULE_CODED_VALUES.SALES_ORDER}
        /> */}

        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/so-multi-currency"
          component={MultiCurrencySalesOrder}
          moduleCode={MODULE_CODED_VALUES.SALES_ORDER_MULTI_CURRENCY}
        /> */}

        <PrivateRoute
          {...props}
          exact
          path="/admin/sales-invoice-list"
          component={SalesInvoiceListing}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.SALES_INVOICE}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/customer-payment-list"
          component={CustomerPaymentListing}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.CUSTOMER_PAYMENT}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/credit-memo-list"
          component={CreditMemoListing}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.CREDIT_MEMO}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/sales-complaint-list"
          component={SalesComplaintListing}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.SALES_SUPPORT}
        />
        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/student-registration-list"
          component={StudentRegistrationListing}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.SALES_ORDER}
        /> */}
        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/customer-expense-list"
          component={CustomerExpenseListing}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.CUSTOMER_EXPENSE}
        /> */}

        <PrivateRoute
          {...props}
          exact
          path="/admin/balance-sheet"
          component={BalanceSheet}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.BALANCE_SHEET}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/ledger-accounts"
          component={LedgerAccounts}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.LEDGER_ACCOUNTS}
        />

        {/* <PrivateRoute
          exact
          path="/details"
          component={CustomerDetails}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.CUSTOMERS}
        /> */}

        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/po-create"
          component={StandardPurchaseOrder}
          moduleCode={MODULE_CODED_VALUES.PURCHASE_ORDER}
        /> */}
{/* 
        <PrivateRoute
          {...props}
          exact
          path="/admin/inbound-delivery-create"
          component={InboundDelivery}
          moduleCode={MODULE_CODED_VALUES.PURCHASE_GRN}
        /> */}

        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/inbound-delivery-update"
          component={InboundDelivery}
          operation={PERMISSION_VALUES.UPDATE}
          moduleCode={MODULE_CODED_VALUES.PURCHASE_GRN}
        /> */}

        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/po-create-multi-currency"
          component={MultiCurrencyPurchaseOrder}
          moduleCode={MODULE_CODED_VALUES.PURCHASE_ORDER_MULTI_CURRENCY}
        /> */}

        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/po-update"
          component={StandardPurchaseOrder}
          moduleCode={MODULE_CODED_VALUES.PURCHASE_ORDER}
        /> */}

        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/debit-memo-create"
          component={StandardDebitMemo}
          moduleCode={MODULE_CODED_VALUES.DEBIT_MEMO}
        /> */}
        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/debit-memo-update"
          component={StandardDebitMemo}
          operation={PERMISSION_VALUES.UPDATE}
          moduleCode={MODULE_CODED_VALUES.DEBIT_MEMO}
        /> */}

        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/pr-create"
          component={StandardPurchaseRequest}
          moduleCode={MODULE_CODED_VALUES.PURCHASE_REQUEST}
        /> */}
{/* 
        <PrivateRoute
          {...props}
          exact
          path="/admin/pr-update"
          component={StandardPurchaseRequest}
          operation={PERMISSION_VALUES.UPDATE}
          moduleCode={MODULE_CODED_VALUES.PURCHASE_REQUEST}
        /> */}

        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/rfq-create"
          component={StandardRFQ}
          moduleCode={MODULE_CODED_VALUES.RFQ}
        /> */}

        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/sales-inquiry-create"
          component={StandardSalesEnquiry}
          moduleCode={MODULE_CODED_VALUES.RFQ}
        /> */}

        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/sales-inquiry-update"
          component={StandardSalesEnquiry}
          moduleCode={MODULE_CODED_VALUES.RFQ}
        /> */}
{/* 
        <PrivateRoute
          {...props}
          exact
          path="/admin/sales-inquiry-list"
          component={SalesEnquiryList}
          moduleCode={MODULE_CODED_VALUES.RFQ}
        /> */}

        <PrivateRoute
          {...props}
          exact
          path="/admin/company-hierarchy-list"
          component={CompanyHierarchy}
          moduleCode={MODULE_CODED_VALUES.COMPANY_HIERARCY}
          operation={PERMISSION_VALUES.READ}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/employee-list"
          component={EmployeeList}
          moduleCode={MODULE_CODED_VALUES.HRMS_EMPLOYEE}
          operation={PERMISSION_VALUES.READ}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/projects"
          component={Projects}
          moduleCode={MODULE_CODED_VALUES.PROJECT}
          operation={PERMISSION_VALUES.READ}
        />

        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/rfq-update"
          component={StandardRFQ}
        /> */}

        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/update-rfq"
          component={StandardRFQ}
          operation={PERMISSION_VALUES.UPDATE}
          moduleCode={MODULE_CODED_VALUES.RFQ}
        /> */}

        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/clone-rfq"
          component={StandardRFQ}
          operation={PERMISSION_VALUES.UPDATE}
          moduleCode={MODULE_CODED_VALUES.RFQ}
        /> */}

        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/rfq-split"
          component={SplitRFQ}
          moduleCode={MODULE_CODED_VALUES.RFQ}
        /> */}

        {/* <PrivateRoute
          {...props}
          isCrmView={true}
          exact
          path="/admin/suppliers/details"
          component={SupplierDetails}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.SUPPLIERS}
        /> */}
        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/products/details"
          component={ProductDetails}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.PRODUCTS}
        /> */}

        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/so/package"
          component={SalesOrderPackage}
          moduleCode={MODULE_CODED_VALUES.SALES_ORDER_PACKAGE}
        /> */}

        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/so/shipment"
          component={SalesOrderShipment}
          moduleCode={MODULE_CODED_VALUES.SALES_ORDER_SHIPMENT}
        /> */}

        <PrivateRoute
          {...props}
          exact
          path="/admin/profit-loss"
          component={ProfitAndLoss}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.PROFIT_LOSS}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/trial-balance"
          component={TrialBalances}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.TRIAL_BALANCE}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/opening-balance"
          component={OpeningBalance}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.OPENING_BALANCE}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/show-journals"
          component={Journals}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.JOURNALS}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/create-journal"
          component={JournalCreate}
          moduleCode={MODULE_CODED_VALUES.JOURNALS}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/show-taxes"
          component={Taxes}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.TAXES}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/price-list"
          component={PriceList}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.PRICE_LIST}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/product-price"
          component={ProductPriceListing}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.PRODUCT_PRICE}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/extras/product-category"
          component={CategoryListing}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.PRODUCT_CATEGORY}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/extras/product-manufacturer"
          component={ManufacturerListing}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.PRODUCT_MANUFACTURER}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/extras/product-brands"
          component={BrandListing}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.PRODUCT_BRAND}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/extras/UOM"
          component={UOMListing}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.UOM}
        />
         <PrivateRoute
          {...props}
          exact
          path="/admin/extras/opening-quantity"
          component={QuantityListing}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.PRODUCT_OPENING_QUANTITY}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/warehouse/my-warehouse"
          component={MyWarehouseListing}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.MY_WAREHOUSE}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/stock-intransit"
          component={StockTransit}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.STOCK_TRANSFER}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/warehouse/stock-summary"
          component={StockSummaryListing}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.STOCK_SUMMARY}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/warehouse/stock-adjustment"
          component={StockAdjustmentrListing}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.STOCK_ADJUSTMENT}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/warehouse/aged-stock"
          component={AgedStockListing}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.AGED_STOCK}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/show-fy-years"
          component={FinancialYears}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.FINANCIAL_YEARS}
        />

        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/show-cost-centers"
          component={CostCenters}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.COST_CENTERS}
        /> */}

        <PrivateRoute
          {...props}
          exact
          path="/admin/dayBook"
          component={DayBookListComp}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.BANKING_BOOKS}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/journal-report"
          component={JournalReportComp}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.REPORTS}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/cashbook"
          component={CashBookListComp}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.BANKING_BOOKS}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/bankbook"
          component={BankBookListComp}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.BANKING_BOOKS}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/bill-wise-detail"
          component={BillwiseDetailComp}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.BANKING}
        />

        <PrivateRoute
          {...props}
          {...props}
          exact
          path="/admin/banking"
          component={Banking}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.BANKING}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/bankReconciliation"
          component={BankReconciliation}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.BANK_RECONCILIATION}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/gst-filling"
          component={EInvoiceGstr1FillingComp}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.GST_FILLING}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/business-expense-listing"
          component={BusinesExpenseListing}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.BUSINESS_EXPENSE}
        />

        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/business-expense-create"
          component={BusinesExpenseCreate}
          moduleCode={MODULE_CODED_VALUES.BUSINESS_EXPENSE}
        /> */}

        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/business-expense-update"
          component={BusinesExpenseCreate}
          moduleCode={MODULE_CODED_VALUES.BUSINESS_EXPENSE}
        /> */}

        <PrivateRoute
          {...props}
          exact
          path="/admin/show-settings"
          component={ShowSettings}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.SETTINGS}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/settings/user-management"
          component={UserManagement}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.SETTINGS}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/settings/taxes"
          component={TaxesComp}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.SETTINGS}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/settings/shipping"
          component={ShippingComp}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.SETTINGS}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/settings/shipping/rule"
          component={ShippingRuleComp}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.SETTINGS}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/settings/taxes/states"
          component={MarketplaceStatesTaxComp}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.SETTINGS}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/show-reports"
          component={ShowReports}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.FINANCIAL_REPORTS}
        />
        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/accounting-integration"
          component={Accounting}
          moduleCode={MODULE_CODED_VALUES.ACCOUNTING}
        /> */}
        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/sales-channels"
          component={SalesChannels}
          moduleCode={MODULE_CODED_VALUES.SALES_CHANNEL}
        /> */}

        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/payment-integration"
          component={Payment}
          moduleCode={MODULE_CODED_VALUES.ACCOUNTING}
        /> */}
        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/shipping"
          component={Shipping}
          moduleCode={MODULE_CODED_VALUES.SHIPPING}
        /> */}
        <PrivateRoute
          {...props}
          exact
          path="/admin/published-stock"
          component={PublishedStock}
          moduleCode={MODULE_CODED_VALUES.E_STORE_PRODUCT_MGMT}
          operation={PERMISSION_VALUES.READ}
        />
        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/published-category"
          component={PublishedCategory}
          moduleCode={MODULE_CODED_VALUES.ONLINE_STORES}
        /> */}
        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/product-online-store-price"
          component={ProductOnlineStorePrice}
          moduleCode={MODULE_CODED_VALUES.ONLINE_STORES}
        /> */}
        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/sales-channels-amazon"
          component={SalesChannelAmazon}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.SALES_CHANNEL}
        /> */}
        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/sales-channels-ebay"
          component={SalesChannelEBay}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.SALES_CHANNEL}
        /> */}
        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/sales-channels-walmart"
          component={SalesChannelWalmart}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.SALES_CHANNEL}
        /> */}
        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/easy-post"
          component={EasyPost}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.SHIPPING}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/ship-station"
          component={ShipStation}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.SHIPPING}
        /> */}
        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/term-and-fee"
          component={TermAndFee}
          moduleCode={MODULE_CODED_VALUES.MEMBERSHIP}
        /> */}
        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/school-management/school"
          component={SchoolContainer}
          moduleCode={MODULE_CODED_VALUES.SCHOOL_DASHBOARD}
        /> */}

        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/school-management/dashboard"
          component={StudentDashboardContainer}
          moduleCode={MODULE_CODED_VALUES.SCHOOL_DASHBOARD}
        /> */}
        <PrivateRoute
          {...props}
          exact
          path="/admin/school-management/department"
          component={DepartmentContainer}
          moduleCode={MODULE_CODED_VALUES.SCHOOL_COURSE_DETAIL}
          operation={PERMISSION_VALUES.READ}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/school-management/course-term"
          component={CourseTermContainer}
          moduleCode={MODULE_CODED_VALUES.SCHOOL_COURSE_DETAIL}
          operation={PERMISSION_VALUES.READ}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/school-management/subject"
          component={SubjectContainer}
          moduleCode={MODULE_CODED_VALUES.SCHOOL_COURSE_DETAIL}
          operation={PERMISSION_VALUES.READ}
        />
        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/school-management/grade"
          component={GradeContainer}
          moduleCode={MODULE_CODED_VALUES.MEMBERSHIP}
        /> */}
        <PrivateRoute
          {...props}
          exact
          path="/admin/school-management/section"
          component={SectionContainer}
          moduleCode={MODULE_CODED_VALUES.SCHOOL_CLASS_DETAIL}
          operation={PERMISSION_VALUES.READ}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/school-management/attendance"
          component={AttendanceContainer}
          moduleCode={MODULE_CODED_VALUES.SCHOOL_ATTENDANCE}
          operation={PERMISSION_VALUES.READ}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/school-management/communication"
          component={CommunicationContainer}
          moduleCode={MODULE_CODED_VALUES.COMMUNICATION_EMAIL_LOGS}
          operation={PERMISSION_VALUES.READ}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/school-management/broadcast-message"
          component={BroadcastMessageContainer}
          moduleCode={MODULE_CODED_VALUES.NEWS_LETTER}
          operation={PERMISSION_VALUES.READ}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/school-management/assignment"
          component={AssignmentContainer}
          moduleCode={MODULE_CODED_VALUES.SCHOOL_ASSIGNMENTS}
          operation={PERMISSION_VALUES.READ}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/school-management/assignment-response"
          component={AssignmentResponse}
          moduleCode={MODULE_CODED_VALUES.SCHOOL_ASSIGNMENTS}
          operation={PERMISSION_VALUES.READ}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/school-management/assignment-response-v"
          component={AssignmentResponseReview}
          moduleCode={MODULE_CODED_VALUES.SCHOOL_ASSIGNMENTS}
          operation={PERMISSION_VALUES.READ}
        />
        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/school-management/financial-report"
          component={FinancialReportContainer}
          moduleCode={MODULE_CODED_VALUES.SCHOOL_ASSIGNMENTS}
        /> */}
        <PrivateRoute
          {...props}
          exact
          path="/admin/school-management/class"
          component={ClassContainer}
          moduleCode={MODULE_CODED_VALUES.SCHOOL_CLASS_DETAIL}
          operation={PERMISSION_VALUES.READ}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/school-management/course"
          component={CourseContainer}
          moduleCode={MODULE_CODED_VALUES.SCHOOL_COURSE_DETAIL}
          operation={PERMISSION_VALUES.READ}
        />
        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/school-management/parent"
          component={ParentContainer}
          moduleCode={MODULE_CODED_VALUES.SCHOOL_PARENTS}
        /> */}
        <PrivateRoute
          {...props}
          exact
          path="/admin/school-management/student"
          component={StudentContainer}
          moduleCode={MODULE_CODED_VALUES.SCHOOL_STUDENT}
          operation={PERMISSION_VALUES.READ}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/school-management/teachers"
          component={TeacherContainer}
          moduleCode={MODULE_CODED_VALUES.SCHOOL_TEACHER}
          operation={PERMISSION_VALUES.READ}
        />
        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/school-management/invoice"
          component={InvoiceContainer}
          moduleCode={MODULE_CODED_VALUES.SCHOOL_INVOICE}
        /> */}
        <PrivateRoute
          {...props}
          exact
          path="/admin/school-management/invoice-list"
          component={SalesInvoiceListing}
          moduleCode={MODULE_CODED_VALUES.SCHOOL_INVOICE} // check module code
          operation={PERMISSION_VALUES.READ}
        />
        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/membership-option"
          component={MembershipOption}
          moduleCode={MODULE_CODED_VALUES.MEMBERSHIP}
        /> */}
        <PrivateRoute
          {...props}
          exact
          path="/admin/communication/dg"
          component={DistributionGroup}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.COMMUNICATION_DG_GROUP}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/communication/contact"
          component={CommunicationContact}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.ALL_CONTACT}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/kiosk"
          component={KioskComp}
          moduleCode={MODULE_CODED_VALUES.KIOSK}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/fundraising-campaign"
          component={Campaign}
          moduleCode={MODULE_CODED_VALUES.FUNDRAISING}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/campaign-detail"
          component={CampaignDetail}
          moduleCode={MODULE_CODED_VALUES.FUNDRAISING}
          operation={PERMISSION_VALUES.READ}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/fundraising-pledges"
          component={Pledge}
          moduleCode={MODULE_CODED_VALUES.SALES_INVOICE}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/fundraising-donation"
          component={FundraisingDonationListing}
          moduleCode={MODULE_CODED_VALUES.FUNDRAISING}
          operation={PERMISSION_VALUES.READ}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/sponsorship"
          component={SponsorshipListing}
          moduleCode={MODULE_CODED_VALUES.FUNDRAISING}
          operation={PERMISSION_VALUES.READ}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/sponsorship-create"
          component={CreateSponsorship}
          moduleCode={MODULE_CODED_VALUES.FUNDRAISING}
          operation={PERMISSION_VALUES.READ}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/news-letter-listing"
          component={NewLetterListing}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.NEWS_LETTER}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/events"
          component={EventListing}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.EVENTS}
        />
        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/newsletter-t"
          component={NewsLetter}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.NEWS_LETTER}
        /> */}

        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/fundraising-t"
          component={NewsLetter}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.NEWS_LETTER}
        /> */}

        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/event-t"
          component={NewsLetter}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.NEWS_LETTER}
        /> */}

        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/account"
          component={AccountComp}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.SALES_INVOICE}
        /> */}

        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/membership-form-list"
          component={MembershipFormList}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.MEMBERSHIP}
        /> */}
        <PrivateRoute
          {...props}
          exact
          path="/admin/school-management/form-list"
          component={StudentRegFormListing}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.SCHOOL_FORM}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/school-management/form-registration-list"
          component={RegistrationsList}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.EDUCATION_FORM_REGISTRATION}
        />
        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/school-management/online-user"
          component={OnlineUserContainer}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.SCHOOL_ONLINE_USER}
        /> */}
        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/school-management/customers"
          component={FamilyListing}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.SCHOOL_CUSTOMERS}
        /> */}
        <PrivateRoute
          {...props}
          exact
          path="/admin/show-HSN"
          component={HsnContainer}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.PRODUCT_HSN}
        />
        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/org-membership"
          component={orgMembership}
        // operation={PERMISSION_VALUES.READ}
        // moduleCode={MODULE_CODED_VALUES.TAXES}
        /> */}
        <PrivateRoute
          {...props}
          exact
          path="/admin/warehouse/all-inventory-valuation"
          component={AllInventoryValuation}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.INVENTORY_VALUATION}
        />
        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/sales-channels-shopify"
          component={SalesChannelShopify}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.SALES_CHANNEL}
        /> */}
        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/sales-channels-carrefour"
          component={SalesChannelCarrefour}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.SALES_CHANNEL}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/sales-channels-noon"
          component={SalesChannelNoon}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.SALES_CHANNEL}
        /> */}
        <PrivateRoute
          {...props}
          exact
          path="/admin/warehouse/monthly-inventory-valuation"
          component={MonthlyInventoryValuation}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.STOCK_ADJUSTMENT}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/warehouse/daily-inventory-valuation"
          component={DailyInventoryValuation}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.STOCK_ADJUSTMENT}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/landed-cost-setup"
          component={LandedCostSetupListing}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.PURCHASE_LANDED_COST}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/landed-cost"
          component={LandedCostListing}
          moduleCode={MODULE_CODED_VALUES.PURCHASE_LANDED_COST}
          operation={PERMISSION_VALUES.READ}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/leads/leads"
          component={AllLeadsListComp}
          moduleCode={MODULE_CODED_VALUES.MY_LEADS}
          operation={PERMISSION_VALUES.READ}
        />

        {/* <PrivateRoute
          {...props}
          isMyLeads={true}
          exact
          path="/admin/leads/my-leads"
          component={AllLeadsListComp}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.MY_LEADS}
        /> */}

        <PrivateRoute
          {...props}
          exact
          path="/admin/leads/lead-form"
          component={LeadFormComp}
          moduleCode={MODULE_CODED_VALUES.LEAD_FROM_SETTING}
          operation={PERMISSION_VALUES.READ}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/leads/lead-meta"
          component={FormMataComp}
          moduleCode={MODULE_CODED_VALUES.LEAD_FROM_SETTING}
          operation={PERMISSION_VALUES.READ}
        />

        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/leads/lead-meta/lead-sub-source-list"
          component={LeadSubSourceListComp}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.CUSTOMERS}
        /> */}

        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/leads/lead-meta/lead-source-list"
          component={LeadSourceListComp}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.LEAD_FROM_SETTING}
        /> */}

        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/leads/lead-meta/lead-stage-list"
          component={LeadStageListComp}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.LEAD_FROM_SETTING}
        /> */}

        {/* <PrivateRoute {...props}
          exact
          path='/admin/leads/lead-meta/lead-stage-list'
          component={LeadStageListComp}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.SCHOOL_DASHBOARD}
        /> */}

        <PrivateRoute
          {...props}
          exact
          path="/admin/school-management/time-table"
          component={TimeTableCont}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.SCHOOL_TIME_TABLE}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/e-store/store-settings"
          component={StoreSettings}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.E_STORE_SETTING}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/integrations"
          component={Integrations}
          // moduleCode={MODULE_CODED_VALUES.ACCOUNTING}
          moduleCode= {[MODULE_CODED_VALUES.INTEGRATIONS_PAYMENT, MODULE_CODED_VALUES.INTEGRATION_ACCOUNTING, MODULE_CODED_VALUES.INTEGRATION_MARKETPLACE, MODULE_CODED_VALUES.INTEGRATION_SHIPPING, MODULE_CODED_VALUES.INTEGRATION_EMAIL]}
          operation={PERMISSION_VALUES.READ}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/e-store/menu"
          component={Menus}
          moduleCode={MODULE_CODED_VALUES.E_STORE_APPEARANCE}
          operation={PERMISSION_VALUES.READ}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/e-store/themes"
          component={ThemesComp}
          moduleCode={MODULE_CODED_VALUES.E_STORE_APPEARANCE}
          operation={PERMISSION_VALUES.READ}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/e-store/pages"
          component={PageComp}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.E_STORE_APPEARANCE}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/e-store/themes/customize"
          component={CustomizeThemesComp}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.E_STORE_APPEARANCE}
        />

        <PrivateRoute
          {...props}
          isCrmView={true}
          exact
          path="/admin/customers-list"
          // component={CustomersList}
          component={CustomersPage}
          moduleCode={MODULE_CODED_VALUES.CUSTOMERS}
          operation={PERMISSION_VALUES.READ}
        />

        <PrivateRoute
          {...props}
          path="/admin/e-store/page-edit"
          component={PageCreateComp}
          moduleCode={MODULE_CODED_VALUES.CUSTOMERS}
          operation={PERMISSION_VALUES.READ}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/cloud-drive"
          component={CloudDrive}
          moduleCode={MODULE_CODED_VALUES.CLOUD_DRIVE}
          operation={PERMISSION_VALUES.READ}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/employee-meta"
          component={EmployeeMetaContainer}
          moduleCode={MODULE_CODED_VALUES.HRMS_EMPLOYEE}
          operation={PERMISSION_VALUES.READ}
        />
        {/* <PrivateRoute
          {...props}
          exact
          path="/admin/payroll-meta"
          component={PayrollContainer}
          moduleCode={MODULE_CODED_VALUES.HRMS_EMPLOYEE}
          operation={PERMISSION_VALUES.READ}
        /> */}
     
         <PrivateRoute
          {...props}
          exact
          path="/admin/employee-management/all-employee"
          component={EmployeeContainer}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.HRMS_EMPLOYEE}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/employee-management/warning"
          component={WarningContainer}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.HRMS_EMPLOYEE}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/employee-management/promotion"
          component={PromotionContainer}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.HRMS_EMPLOYEE}
        />
         <PrivateRoute
          {...props}
          exact
          path="/admin/employee-management/termination"
          component={TerminationContainer}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.HRMS_EMPLOYEE}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/attendance-management/attendance-meta"
          component={AttendanceMetaContainer}
          moduleCode={MODULE_CODED_VALUES.HRMS_EMPLOYEE_ATTENDANCE}
        />
         <PrivateRoute
          {...props}
          exact
          path="/admin/leave-management"
          component={LeaveManagement}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.HRMS_LEAVE_MANAGEMENT}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/leave-management/leave-meta"
          component={LeaveMeta}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.HRMS_LEAVE_MANAGEMENT}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/employee-services/profile"
          component={MyAttedance}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.HRMS_EMPLOYEE_SERVICES}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/employee-services/leave-request"
          component={MyAttedance}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.HRMS_EMPLOYEE_SERVICES}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/employee-services/expense-claims"
          component={MyAttedance}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.HRMS_EMPLOYEE_SERVICES}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/employee-services/performance-reviews"
          component={MyAttedance}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.HRMS_EMPLOYEE_SERVICES}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/employee-services/my-attendance"
          component={MyAttedance}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.HRMS_EMPLOYEE_SERVICES}
        />
         <PrivateRoute
          {...props}
          exact
          path="/admin/attendance-management/attendance-summary"
          component={EmployeeAttendance}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.HRMS_EMPLOYEE_ATTENDANCE}
        />
         <PrivateRoute
          {...props}
          exact
          path="/admin/attendance-management/attendance-ip"
          component={AttendanceIp}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.HRMS_EMPLOYEE_ATTENDANCE}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/attendance-management/attendance-report"
          component={AttendanceReport}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.HRMS_EMPLOYEE_ATTENDANCE}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/payroll-management/allowance"
          component={Allowance}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.HRMS_PAYROLL_MANAGEMENT}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/payroll-management/deduction"
          component={Deduction}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.HRMS_PAYROLL_MANAGEMENT}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/payroll-management/monthlyPayGrade"
          component={MonthlyPayGrade}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.HRMS_PAYROLL_MANAGEMENT}
        />
         <PrivateRoute
          {...props}
          exact
          path="/admin/payroll-management/hourlyPayGrade"
          component={HourlyPayGrade}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.HRMS_PAYROLL_MANAGEMENT}
        />
         <PrivateRoute
          {...props}
          exact
          path="/admin/payroll-management/salarySheet"
          component={SalarySheet}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.HRMS_PAYROLL_MANAGEMENT}
        />
         <PrivateRoute
          {...props}
          exact
          path="/admin/payroll-management/paymentHistory"
          component={PaymentHistory}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.HRMS_PAYROLL_MANAGEMENT}
        />
         <PrivateRoute
          {...props}
          exact
          path="/admin/payroll-management/myPayroll"
          component={MyPayroll}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.HRMS_PAYROLL_MANAGEMENT}
        />
         <PrivateRoute
          {...props}
          exact
          path="/admin/payroll-management/bonus"
          component={Bonus}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.HRMS_PAYROLL_MANAGEMENT}
        />
         <PrivateRoute
          {...props}
          exact
          path="/admin/payroll-management/pay-grade"
          component={PayGrade}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.HRMS_PAYROLL_MANAGEMENT}
        />

         <PrivateRoute
          {...props}
          exact
          path="/admin/leads/call-logs"
          component={LeadCallLogs}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.MY_LEADS}
        />

         <PrivateRoute
          {...props}
          exact
          path="/admin/leads/reminder"
          component={LeadReminder}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.MY_LEADS}
        />


        <PrivateRoute
          {...props}
          exact
          path="/admin/leads/opportunity"
          component={LeadOpportunity}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.CRM_OPPORTUNITY}
        />
         <PrivateRoute
          {...props}
          exact
          path="/admin/leads/Lead-notes"
          component={LeadNotesList}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.MY_LEADS}
        />
         <PrivateRoute
          {...props}
          exact
          path="/admin/leads/lead-campaigns"
          component={LeadCampaingn}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.LEAD_FROM_SETTING}
        />
         <PrivateRoute
          {...props}
          exact
          path="/admin/leads/lead-tasks"
          component={LeadTaskList}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.MY_LEADS}
        />
         <PrivateRoute
          {...props}
          exact
          path="/admin/leads/lead-transfer"
          component={LeadTransfer}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.CRM_LEAD_TRANSFER}
        />
         <PrivateRoute
          {...props}
          exact
          path="/admin/leads/lead-campaigns"
          component={LeadCampaingn}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.LEAD_FROM_SETTING}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/coupon-management"
          component={CouponManagement}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.COUPON_MANAGEMENT}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/email/email-campaigns"
          component={EmailCampaign}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.MARKETING_EMAIL}
        />
         <PrivateRoute
          {...props}
          exact
          path="/admin/email/email-template"
          component={EmailTemplate}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.MARKETING_EMAIL}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/e-store/blogs"
          component={BlogList}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.E_STORE_BLOGS_MGMT}
        />
         <PrivateRoute
          {...props}
          exact
          path="/admin/e-store/category"
          component={CategoryList}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.E_STORE_BLOGS_MGMT}
        />
         <PrivateRoute
          {...props}
          exact
          path="/admin/communication/communication-template"
          component={CommunicationTemplate}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.COMMUNICATION_TEMPLATE_SETTING}
        />

        <PrivateRoute
          {...props}
          exact
          path="/admin/leads/lead-workflows"
          component={LeadWorkflowComp}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.LEAD_FROM_SETTING}
        />      
        <PrivateRoute
          {...props}
          exact
          path="/admin/testimonials"
          component={Testimonials}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.E_STORE_TESTIMONIALS}
        />        
        <PrivateRoute
          {...props}
          exact
          path="/admin/extras/review"
          component={RatingAndReview}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.PRODUCT_RATING_REVIEW}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/account/my-profile"
          component={Account}
          // moduleCode={MODULE_CODED_VALUES.SETTINGS}
          moduleCode= {[MODULE_CODED_VALUES.ACCOUNT_PROFILE, MODULE_CODED_VALUES.ACCOUNT_SETTINGS, MODULE_CODED_VALUES.ACCOUNT_TEAM_ROLE, MODULE_CODED_VALUES.ACCOUNT_SUBSCRIPTION]}
          operation={PERMISSION_VALUES.READ}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/account/account-settings"
          component={ProfileSetting}
          moduleCode={MODULE_CODED_VALUES.SETTINGS}
          operation={PERMISSION_VALUES.READ}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/account/team-role"
          component={TeamRoles}
          moduleCode={MODULE_CODED_VALUES.SETTINGS}
          operation={PERMISSION_VALUES.READ}
        />
        <PrivateRoute
          {...props}
          exact
          path="/admin/account/subscription"
          component={Subscription}
          moduleCode={MODULE_CODED_VALUES.SETTINGS}
          operation={PERMISSION_VALUES.READ}
        />
         {/* <PrivateRoute
          {...props}
          exact
          path="/admin/crm-management/dashboard"
          component={CRMDashboard}
          moduleCode= {MODULE_CODED_VALUES.CRM_CUSTOMER}
          operation={PERMISSION_VALUES.READ}
        />        */}
          <PrivateRoute
          {...props}
          exact
          path="/admin/dashboard"
          component={DashboardCon}
          operation={PERMISSION_VALUES.READ}
          moduleCode= {[MODULE_CODED_VALUES.SCHOOL_DASHBOARD, MODULE_CODED_VALUES.ERP_DASHBOARD,MODULE_CODED_VALUES.CRM_CUSTOMER]}
        />

        <PrivateRoute
          {...props}
          path="/admin/*"
          component={NotFound}
          operation={PERMISSION_VALUES.READ}
          moduleCode={MODULE_CODED_VALUES.GENERIC}
        />
      </Switch>
    </div>
  );
};

export default injectIntl(PartialViewRoute);
