import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as find from "lodash.find";
import * as filter from "lodash.filter";
import {
  fetchDataIfNeeded,
  fetchPaginationDataIfNeeded,
  addDaysToDate,
} from "../../../../utils";
import SalesInvoiceComponent from "../../../../components/customer/invoice/StandardSalesInvoice";
import LedgerDrawer from "../../../../containers/drawer/ledgers/SalesInvoiceLedger";

import {
  fetchProducts,
  fetchPaymentTerms,
  fetchPriceList,
  fetchStates,
  fetchProjects,
  fetchTaxes,
  fetchUOM,
  addUOM,
  deleteUOM,
  fetchExpenseTypes,
  addExpenseTypes,
  deleteExpenseTypes,
  showModal,
  hideModal,
  pushModalToStack,
  popModalFromStack,
  fetchCustomerDetailsByCustomerId,
  addPaymentTerm,
  deletePaymentTerm,
  deleteProject,
  deleteAddress,
  deleteTax,
  deletePriceList,
  fetchDocuments,
  addDocumentName,
  deleteDocumentName,
  deleteContact,
  fetchUserPreferences,
  saveUserPrefrences,
  fetchCountries,
  fetchRelationshipTaxIdentifications,
  deleteCustomerTaxIdentifications,
  deleteRelationshipTaxIdentifications,
  resetCustomerDetail,
  fetchAddresses,
  getRelationshipAddress,
  resetAddresses,
  getAllPaymentGatewayConfig,getItemByUPC,getItemByPruductId,
  fetchPaymentModes
} from "./../../../../actions/commonActions";
import {
  createSalesInvoice,
  resetSalesInvoice,
  fetchSalesInvoiceDetail,
  fetchSoDataForInvoice,
  resetSoInvoiceData,
  getNextTxNumber,
  fetchAllUnfulfilledSalesOrderByCustomerId,
  resetUnfulfilledSalesOrder,
  fetchShipmentForInvoice,
  resetShipmentForInvoice,
  createSalesInvoiceDraft,
  fetchSalesOrderSOId,
  resetSOBySOID,
  fetchShipmentByShipmentId,fetchAmendSoDataForInvoice,
  deleteDocument,
  resetShipmentData,checkCustomerPoNumber,checkManualInvoiceNumber,resetAmendSoDataForInvoice
} from "./action";
import {
  LEDGER_ACTION_LIST,
  CONSTANTS,
  LEDGER_TYPE,
  CONSTANTS_TRANSACTIONS,
  CONSTANTS_TRANSACTIONS_KEY,
} from "../../../../static/constants";
import {
  AG_GRID_CONSTANTS,
  GRID_PREFERENCE_CONSTANTS,
} from "../../../../static/agGridConstants";
import SalesInvoiceDetails from "../../../modal/modalBody/customer/SalesInvoiceDetail";
import { fetchCustomers, deleteCustomers,resetPaginatedData } from "../../Listing/action";
import {
  fetchRemarksTemplate,
  resetRemarksData,
} from "../../../modal/modalBody/settings/RemarksTemplate/action";
import TransportDataDrawer from "../../../../containers/drawer/transportData/index";
import { fetchAllContacts } from "../../Listing/ShowConact/action";
import LedgerAccountsDrawer from "../../../drawer/finance/ledgers";

import { fetchAllLedgerAccounts } from "./../../../finance/LedgerAccounts/action";
import { getAllCoupons,applieCoupon } from '../../../modal/modalBody/eStore/Coupon/action';
import { uploadGSTR1FilingDetails ,deleteValidatedInvoice } from "../../../modal/modalBody/finance/gstrDetails/action";

import SettingSalesDrawer from "../../../drawer/customer/settingSalesDrawer";

class SalesInvoice extends Component {
  constructor(props) {
    super(props);
    
    const roundingOffSettings = JSON.parse(props.companyInfo.roundingOffSettings || "{}");
    const defaultSalesLedger =
      find(props.companyInfo.defaultCoaLedgerAccountsList, {
        txType: LEDGER_ACTION_LIST.SALES,
      }) || {};
    const defaultExpenseLedger =
      find(props.companyInfo.defaultCoaLedgerAccountsList, {
        txType: LEDGER_ACTION_LIST.CUSTOMER_EXPENSE,
      }) || {};
    const defaultSalesOutputLedger =
      find(props.companyInfo.defaultCoaLedgerAccountsList, {
        txType: LEDGER_ACTION_LIST.SALES_OUTPUT_TAX,
      }) || {};
    const defaultSalesDiscountLedger =
      find(props.companyInfo.defaultCoaLedgerAccountsList, {
        txType: LEDGER_ACTION_LIST.SALES_DISCOUNT,
      }) || {};
    const defaultDebtorsLedger =
      find(props.companyInfo.defaultCoaLedgerAccountsList, {
        txType: LEDGER_ACTION_LIST.DEBTORS,
      }) || {};
    const defaultCouponLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_TYPE.TYPE_COUPON }) || {};

      const cashInHandLedger = find((props.companyInfo || {}).defaultCoaLedgerAccountsList, { txType: LEDGER_ACTION_LIST.DEFAULT_LEDGER_TYPE.CASH_IN_HAND }) || {};
    const companyInfo = props.companyInfo;
    const allRelationshipBillingAddress =
      filter(companyInfo.boLocationList, {
        locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
      }) || [];
    const relationshipBillingAddress = find(allRelationshipBillingAddress, {
      isDefault: 1,
    });
    const linkProps = (this.props.location && this.props.location.state) || {};
    this.state = {
      displayName:"drawer.settingDrawer.headingNameInvoice",
      viewName:"Invoice",
      docName:"Sales Invoice",
      tabDocumentOptions: 
      { txType: CONSTANTS_TRANSACTIONS.SALES_INVOICE, label: 'Sales Invoice', numberExample: 'INV-1', numberInfo: 'INV-CustomerNumber-DDMMYY' },
      settingSalesDrawerVisible:false,
      
      valueSet:false,
      preservedSalesOrder: [],
      isbankReco: props.isbankReco || linkProps.isbankReco,
      bankRecoBalance: props.bankRecoBalance || linkProps.bankRecoBalance,
      bankTxData: props.bankTxData || linkProps.bankTxData,
      invoiceDraftId: props.invoiceDraftId || linkProps.invoiceDraftId || 0,
      isClone: props.clone || linkProps.clone,
      invoiceProformaId: props.invoiceProformaId || 0,
      invoiceDraftData: props.invoiceData || {},
      customer: props.customer || linkProps.customer,
      customerName: (props.customer || {}).companyName,
      stateCustomerId: props.customerId || linkProps.customerId,
      pageNumber: props.pageNumber || 1,
      pageSize: 100,
      rootExpenseList:[{}],
      hideAddNew:true,
      cols: 20,
      searchedText: "",
      isSoConversionToInvoice: false,
      ledgerDrawerVisible: false,
      invoiceDate: new Date(),
      salesLedgerId: defaultSalesLedger.ledgerAccountId,
      salesDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
      expenseLedgerId: defaultExpenseLedger.ledgerAccountId,
      expenseDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
      salesOutputTaxLedgerId: defaultSalesOutputLedger.ledgerAccountId,
      taxDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
      salesDiscountLedgerId: defaultSalesDiscountLedger.ledgerAccountId,
      discountDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
      couponSetting: JSON.parse(props.companyInfo?.couponSetting || "{}"),
      igstSalesOutputTaxLedgerId: defaultSalesOutputLedger.ledgerAccountId,
      igstTaxDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
      cgstSalesOutputTaxLedgerId: defaultSalesOutputLedger.ledgerAccountId,
      cgstTaxDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
      sgstSalesOutputTaxLedgerId: defaultSalesOutputLedger.ledgerAccountId,
      sgstTaxDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
      couponLedgerId: defaultCouponLedger.ledgerAccountId, 
      couponDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId, 
      cashInHandLedger, 
      allRelationshipBillingAddress,
      relationshipBillingAddress,
      priceTypeToApply:
        AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PRICE_TYPE_LIST[0],
      customerShippingAddress: undefined,
      customerBillingAddress: undefined,
      customerPaymentTerm: undefined,
      customerRef: undefined,
      customerPo: undefined,
      manualInvoiceNumber:undefined,
      placeOfSupply: undefined,
      customerRemarks: undefined,
      internalRemarks: undefined,
      footer: undefined,
      inheritedTxColumn: [],
      forceHideTxColumn: [],
      txPreferences: { txType: "salesInvoice", templateName: "standard" },
      txColumns: [],
      txColumnSetting: {},
      expenseAmounts: {},
      paymentModeName:undefined,
      paymentModeId:undefined,
      paymentModeDetail:undefined,
      remarks:undefined,
      txMandatoryColumns: [
        GRID_PREFERENCE_CONSTANTS.PRODUCT,
        GRID_PREFERENCE_CONSTANTS.DESCRIPTION,
        GRID_PREFERENCE_CONSTANTS.QUANTITY,
        GRID_PREFERENCE_CONSTANTS.RATE,
        GRID_PREFERENCE_CONSTANTS.AMOUNT,
        GRID_PREFERENCE_CONSTANTS.AMOUNT_WITHOUT_TAX,
        "sno",
      ],
      txAvailableColumn: {
        product: true,
        quantity: true,
        rate: true,
        amount: true,
        description: true,
        uom: true,
        hsn: (props.companyInfo || {}).countryName === "India",
        basePrice: true,
        specialDiscount: true,
        discount: true,
        discountAmount: true,
        tax: true,
        comment: true,
        stockNumber: true,
        partNumber: true,
        origin: true,
        hsCode: true,
        materialNumber: true,
        shipmentNumber: true,
        salesOrderNumber: true,
      },
      selectedSalesOrder: [],
      customerSalesOrderDetailsList: [{}],
      rootExpenseList: [{}],
      validCustomerPoNumber:true,
      validManualInvoiceNumber:true,
    isItemDiscountAplicable:false,
    roundingOffSettings,
    roundingOffLedgerId: roundingOffSettings.roundingOffSalesLedgerAccountId,
    roundingOffDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
    soMasterId: props.soMasterId || linkProps.soMasterId,
    salesOrderMasterId: props.soMasterId || linkProps.soMasterId,
    documentType:[],
    rrItem:props.rrItem ||[],
    docVal:"Regular Invoice",
    };
  }

  componentWillReceiveProps(props) {

    if (props.addresses) {
      if (
        props.addresses.BillingAddress &&
        props.addresses.BillingAddress.length
      ) {
        this.setState({
          allBillingAddress: props.addresses.BillingAddress,
        });
      }
      if (
        props.addresses.ShippingAddress &&
        props.addresses.ShippingAddress.length
      ) {
        this.setState({
          allShippingAddress: props.addresses.ShippingAddress,
        });
      }
    }

    if (props.customerDetail && props.customerDetail.customerId && !this.state.isThroughTx) {
      const customer = props.customerDetail;
      const allShippingAddress =
        (customer &&
          filter(customer.boLocationList, {
            locationType: CONSTANTS.SHIPPING_ADDRESS,
          })) ||
        [];
      const defaultShippingAddress =
        find(allShippingAddress, { isDefault: 1 }) || {};
      const allBillingAddress =
        (customer &&
          filter(customer.boLocationList, {
            locationType: CONSTANTS.BILLING_ADDRESS,
          })) ||
        [];
      const defaultBillingAddress =
        find(allBillingAddress, { isDefault: 1 }) || {};
      // const customerPaymentTerm = props.paymentTerms.length
      //   ? find(props.paymentTerms, {
      //       paymentTermId: (customer.paymentTermId) || 0,
      //     }) || {}
      //   : {};
      const customerPaymentTerm = props.paymentTerms.length
        ? this.state.customerPaymentTerm || find(props.paymentTerms, {
            paymentTermId: ( customer && customer.paymentTermId) || 0,
          }) || {}
        : {};

      let numberOfDays = customerPaymentTerm.numberOfDays;
      const selectedContact =
        (customer.boContactList || []).find((x) => x.isPrimaryContact) || {};
      let selectedCustomerTaxIdentifications = [];
      const selectedList =
        (customer &&
          filter(customer.customerTaxIdentificationsList, {
            populateOnTransaction: 1,
          })) ||
        [];
      selectedCustomerTaxIdentifications = selectedList.map((obj) => {
        return obj.customerTaxIdentificationId;
      });
      this.setState({
        customer,
        customerName: customer.companyName,
        customerPaymentTerm,
        customerShippingAddress: defaultShippingAddress,
        customerBillingAddress: defaultBillingAddress,
        placeOfSupply:props.placeOfSupply?.length ?props.placeOfSupply:defaultBillingAddress.stateName || defaultBillingAddress.stateName,
        invoiceDueDate: numberOfDays
          ? addDaysToDate(props.invoiceDate || new Date(), numberOfDays)
          : new Date(),
        selectedContact,
        paymentTermDays: numberOfDays,
        selectedCustomerTaxIdentifications: selectedCustomerTaxIdentifications,
        selectedSalesOrder: props.preservedSalesOrder || [],
        preservedSalesOrder: [],
        allBillingAddress,
        allShippingAddress,
        selectedSalesOrderIds: [],
      });
      props.resetCustomerDetail();
    }

    if (
      !this.state.selectedRelationshipTaxIdentifications &&
      props.allRelationshipTaxIdentificatins &&
      props.allRelationshipTaxIdentificatins.length
    ) {
      const selectedList =
        (props.allRelationshipTaxIdentificatins &&
          filter(props.allRelationshipTaxIdentificatins, {
            populateOnTransaction: 1,
          })) ||
        [];
      let selectedRelationshipTaxIdentifications = selectedList.map((obj) => {
        return obj.relationshipTaxIdentificationId;
      });
      this.setState({
        selectedRelationshipTaxIdentifications:
          selectedRelationshipTaxIdentifications,
      });
    }

    if (
      props.documentList &&
      props.documentList.length &&
      !this.state.docName
    ) {
      props.documentList.forEach((document) => {
        if (
          document.txType ===
            CONSTANTS_TRANSACTIONS_KEY[CONSTANTS_TRANSACTIONS.SALES_INVOICE] &&
          document.isDefault
        ) {
          this.setState({
            documentNameId: document.documentNameId,
            docName: document.docName,
          });
        }
      });
    }
    if (
      !this.state.customerRemarks &&
      props.remarksData &&
      props.remarksData.isFetched &&
      !this.state.isRemarksSet
    ) {
      this.setState({
        customerRemarks: props.remarksData.content || "",
        isRemarksSet: true,
        footer: props.remarksData.footer || "",
      });
      setTimeout(() => {
        props.resetRemarksData();
      }, 500);
    }
    if (
      // props.taxes &&
      // props.taxes.length &&
      props.isTaxesLoaded &&
      props.soInvoiceData &&
      props.soInvoiceData.salesOrderMasterId && props.allLedgerAccounts?.length
    ) {
      const detailList = [];
      const inheritedTxColumn = [];
      
      const customerPaymentTerm = props.paymentTerms.length
      ? find(props.paymentTerms, {
        paymentTermId: props.soInvoiceData.paymentTermId,
      }) || {}
      : {};
      let numberOfDays = customerPaymentTerm.numberOfDays;
     
      const _emptyColumnCheck = (item, taxPercent) => {
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) <
            0 &&
          item.description
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) < 0 &&
          item.uomName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.UOM);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HSN) < 0 &&
          item.hsnCode
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HSN);
        }
        // if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.BASE_PRICE) < 0 && item.basePrice) {
        //     inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.BASE_PRICE);
        // }
        if (
          inheritedTxColumn.indexOf(
            GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT
          ) < 0 &&
          item.specialDiscount
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DISCOUNT) < 0 &&
          (
            find(item.customerSalesOrderCOATxList, {
              txType: LEDGER_TYPE.TYPE_DISCOUNT,
            }) || {}
          ).amountPercent
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DISCOUNT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.TAX) < 0 &&
          taxPercent
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.TAX);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.COMMENT) < 0 &&
          item.warehouse
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.COMMENT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER) <
            0 &&
          item.stocknumber
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PART_NUMBER) <
            0 &&
          item.partNumber
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PART_NUMBER);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.ORIGIN) < 0 &&
          item.origin
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.ORIGIN);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HS_CODE) < 0 &&
          item.hsCode
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HS_CODE);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HS_CODE) < 0 &&
          item.hsCode
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HS_CODE);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.MATERIAL_NUMBER) <
            0 &&
          item.materialNumber
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.MATERIAL_NUMBER);
        }
      };
      const detailsList = props.soInvoiceData.customerSalesOrderDetailsList.filter(it => !(props.rrItem || []).includes(it.inventoryItemVariantId));
      detailsList.map((ele) => {
        const tax =
          find(ele.customerSalesOrderCOATxList, (ele) => {
            return ele.txType === LEDGER_TYPE.TYPE_TAX;
          }) || {};
        const appliedTax =
          find(props.taxes, { taxSingleRateId: Number(tax.taxId) }) || {};
        _emptyColumnCheck(ele, tax.amountPercent);
        return detailList.push({
          selectedProductValue: [ele.itemId, ele.inventoryItemVariantId,ele.itemVariantStockId],
          version: ele.version,
          inventoryItemVariantId: ele.inventoryItemVariantId,
          product: ele.itemName
            ? ele.itemName +
              ((ele.itemVariantName && " (" + ele.itemVariantName + ")") || "")
            : "",
          productObj: ele.itemName
            ? { itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku}
            : {},
          salesOrderMasterId: ele.salesOrderMasterId,
          salesOrderNumber: props.soInvoiceData.soNumber,
          itemVariantName: ele.itemVariantName,
          variant: {
            attributeId1: ele.attributeId1,
            attributeId2: ele.attributeId2,
            attributeId3: ele.attributeId3,
            attributeName1: ele.attributeName1,
            attributeName2: ele.attributeName2,
            attributeName3: ele.attributeName3,
            attributeValue1: ele.attributeValue1,
            attributeValue2: ele.attributeValue2,
            attributeValue3: ele.attributeValue3,
          },
          description: ele.description,
          scrollHeight: ele.rowHeight,
          qty: ele.quantityToInvoice,
          discount: (
            find(ele.customerSalesOrderCOATxList, {
              txType: LEDGER_TYPE.TYPE_DISCOUNT,
            }) || {}
          ).amountPercent,
          tax: tax.taxName === "CGST" || tax.taxName === "SGST" ?tax.amountPercent*2 || 0:tax.amountPercent,
          taxApplied: appliedTax.taxNameDisplay,
          taxName: appliedTax.taxName,
          taxId: appliedTax.taxSingleRateId,
          quantityOrdered: ele.quantity,
          quantityInvoiced: ele.quantityInvoiced,
          rate: ele.anItemSalePrice,
          baseRate: ele.basePrice,
          uom: ele.uomName,
          hsnCode: ele.hsnCode,
          specialDiscount: ele.basePrice && ele.specialDiscount,
          comment: ele.warehouse,
          stockNumber: ele.stockNumber,
          partNumber: ele.partNumber,
          origin: ele.origin,
          hsCode: ele.hsCode,
          parentDetailsId: ele.salesOrderDetailsId,
          materialNumber: ele.materialNumber,
        });
      });

      const rootDiscount =
        (
          find(props.soInvoiceData.customerSalesOrderCOATxList || [], {
            txType: LEDGER_TYPE.TYPE_DISCOUNT,
          }) || {}
        ).amountPercent || 0;
      const expenseAmount =
        (
          find(props.soInvoiceData.customerSalesOrderCOATxList || [], {
            txType: LEDGER_TYPE.TYPE_EXPENSE,
          }) || {}
        ).amount || 0;
      let selectedContact =
        (props.soInvoiceData.additionalInfoList || [])[0] || {};
      delete selectedContact.additionalInfoId;
      const selectedSalesOrder = [
        { ...props.soInvoiceData, customerSalesOrderDetailsList: detailList },
      ];
      const forceHideTxColumn = [];
      if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0) {
        //forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
      }
      if (
        inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0
      ) {
        //forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
      }
      const linkProps =
        (this.props.location && this.props.location.state) || {};

        let rootExpenseList = [];
        let  rootDiscountList = [];
        for (let ledgerObj of props.soInvoiceData?.customerSalesOrderCOATxList || []) {
          if (
            ledgerObj.txType === LEDGER_TYPE.TYPE_EXPENSE &&
            ledgerObj.accountingEntry === "credit"
          ) {
             let ledger = 
              find(props.allLedgerAccounts || [], {
                chartOfAccountDetailsId: ledgerObj.ledgerAccountId,
              }) || {}
            
            rootExpenseList.push({
              // chartOfAccountDetailsId: ledgerObj.expenseLedgerAccountId,
              chartOfAccountDetailsId: ledgerObj.ledgerAccountId,
              ledgerAccountName: ledger.ledgerAccountName,
            expenseLedgerAccountName:ledger.ledgerAccountName,

              expenseLedgerAccountTaxRate:ledger.taxSingleRatePercent,
              expenseAmount: ledgerObj.amount,
              selectedExpenseLedger: {
                ...ledger
              },
            });
          }
        }
  
        for (let ledgerObj of props.soInvoiceData?.customerSalesOrderCOATxList || []) {
          if (
            ledgerObj.txType === LEDGER_TYPE.TYPE_DISCOUNT &&
            ledgerObj.accountingEntry === "credit"
          ) {
            let ledger = 
            find(props.allLedgerAccounts || [], {
              chartOfAccountDetailsId: ledgerObj.ledgerAccountId,
            }) || {}
            rootDiscountList.push({
              // chartOfAccountDetailsId: ledgerObj.expenseLedgerAccountId,
              chartOfAccountDetailsId: ledgerObj.ledgerAccountId,
              ledgerAccountName: ledger.ledgerAccountName,
            expenseLedgerAccountName:ledger.ledgerAccountName,

              expenseAmount: ledgerObj.amount,
              rootDiscountPercent:ledgerObj.amountPercent,
              rootDiscountAmount:ledgerObj.amount,
              selectedExpenseLedger: {
                ...ledger
              },
            });
          }
        }
      const couponAmount = (find((props.soDetail.customerSalesOrderCOATxList || []), { txType: LEDGER_TYPE.TYPE_COUPON }) || {}).amount || 0;
      const coupon = (find((props.soInvoiceData?.customerSalesOrderCOATxList || []), { txType: LEDGER_TYPE.TYPE_COUPON }) || {});
      this.setState({
        isThroughTx:true,
        valueSet:true,
        isSoConversionToInvoice: true,
        couponAmount,
        coupanValue:coupon.taxName,
        nameOfTransport: props.soInvoiceData.nameOfTransport,
        vehicleNumber: props.soInvoiceData.vehicleNumber,
        roadPermitNumber: props.soInvoiceData.roadPermitNumber,
        freightType: props.soInvoiceData.freightType,
        consignee: props.soInvoiceData.consignee,
        ewayBillNo: props.soInvoiceData.ewayBillNo,
        station: props.soInvoiceData.station,
        gstinNo: props.soInvoiceData.gstinNo,
        // coupondata: couponAmount ?{isvalid:couponAmount?true:false,discountPercentage:coupon.amountPercent,couponType:coupon.amountPercent?"PERCENTAGE":"FLAT"}:{},
        coupondata:couponAmount ?{isvalid:couponAmount?true:false,maxDiscount:coupon.amountPercent?couponAmount:coupon?.amount,discountPercentage:coupon.amountPercent,couponType:coupon.amountPercent?"PERCENTAGE":"FLAT"}:{},
        version: props.soInvoiceData.version,
        salesOrderMasterId: props.soInvoiceData.salesOrderMasterId,
        soNumber: props.soInvoiceData.soNumber,
        customerPreOrderMasterId: props.soInvoiceData.customerPreOrderMasterId,
        relationshipBillingAddress: find(props.soInvoiceData.boLocationSOList, {
          locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
        }),
        customerShippingAddress: find(props.soInvoiceData.boLocationSOList, {
          locationType: CONSTANTS.SHIPPING_ADDRESS,
        }),
        customerBillingAddress: find(props.soInvoiceData.boLocationSOList, {
          locationType: CONSTANTS.BILLING_ADDRESS,
        }),
        customerInvoicePoReplica:props.update? props.soInvoiceData.customerPONumber:null,
        customerinvoiceNoReplica:props.update? props.soInvoiceData.manualInvoiceNumber:null,
        isManualInvoiceNumber:props.soInvoiceData.manualInvoiceNumber?.length ?true:false,
        customerPaymentTerm: customerPaymentTerm,
        paymentTermDays: customerPaymentTerm.numberOfDays,
        selectedContact: selectedContact,
        paymentTermId: customerPaymentTerm.paymentTermId, 
        paymentTermName: customerPaymentTerm.termName,
        invoiceDueDate: new Date(),
        customerRef: props.soInvoiceData.referenceNumber,
        customerPo: props.soInvoiceData.customerPONumber,
        manualInvoiceNumber: props.soInvoiceData.manualInvoiceNumber,
        placeOfSupply: props.soInvoiceData.placeOfSupply,
        customerName: props.soInvoiceData.customerName,
        customer: {
          companyName: props.soInvoiceData.customerName,
          customerId: props.soInvoiceData.customerId,
        },
        customerRemarks: props.soInvoiceData.remarksCustomer,
        internalRemarks: props.soInvoiceData.remarksInternal,
        footer: props.soInvoiceData.footer,
        customerSalesOrderDetailsList: detailList?.sort((a, b) => (a.sNo > b.sNo) ? 1 : -1) || [],
        isRootDiscount: rootDiscount ? true : false,
        rootDiscountPercent: rootDiscount,
        expenseId: props.soInvoiceData.expenseId,
        invoiceDueDate: numberOfDays
          ? addDaysToDate(new Date(), numberOfDays)
          : null,
        invoiceDate: new Date(),
        rootDiscountAmount:
          (
            find(props.soInvoiceData.customerSalesOrderCOATxList || [], {
              txType: LEDGER_TYPE.TYPE_DISCOUNT,
            }) || {}
          ).amount || 0,
        expenseAmount: expenseAmount,
        projectName: props.soInvoiceData.projectName,
        projectNumber: props.soInvoiceData.projectNumber,
        projectMasterId: props.soInvoiceData.projectMasterId,
        isRootExpense: expenseAmount ? true : false,
        inheritedTxColumn: inheritedTxColumn,
        forceHideTxColumn: forceHideTxColumn,
        tableDataReRendered: true,
        salesQuotationMasterId: props.soInvoiceData.salesQuotationMasterId,
        quotationNumber: props.soInvoiceData.quotationNumber,
        rfqNumber: props.soInvoiceData.rfqNumber,
        rfqMasterId: props.soInvoiceData.rfqMasterId,
        selectedSalesOrder,
        selectedSalesOrderIds: props.soMasterIdArr
          ? [props.soMasterIdArr + ""]
          : null,
          rootExpenseList: rootExpenseList
      });
      selectedSalesOrder.forEach((soData) => {
        const rootDiscount =
          (
            find(soData.customerSalesOrderCOATxList || [], {
              txType: LEDGER_TYPE.TYPE_DISCOUNT,
            }) || {}
          ).amountPercent || 0;
        const expenseAmount =
          (
            find(soData.customerSalesOrderCOATxList || [], {
              txType: LEDGER_TYPE.TYPE_EXPENSE,
            }) || {}
          ).amount || 0;

        const payload = {};

        payload["expenseAmount" + soData.salesOrderMasterId] = expenseAmount;
        payload["expenseId" + soData.salesOrderMasterId] = soData.expenseId;
        payload["isRootExpense" + soData.salesOrderMasterId] = expenseAmount
          ? true
          : false;
        payload["rootDiscountPercent" + soData.salesOrderMasterId] =
          rootDiscount;
        payload["isRootDiscount" + soData.salesOrderMasterId] = rootDiscount
          ? true
          : false;
        this.setState(payload);
      });

      props.resetSoInvoiceData();
    }

    if (
      // props.taxes &&
      // props.taxes.length &&
      props.isTaxesLoaded &&
      props.amendSoInvoiceData &&
      props.amendSoInvoiceData.amendSalesOrderMasterId && props.allLedgerAccounts?.length
    ) {
      const detailList = [];
      const inheritedTxColumn = [];
      
      const customerPaymentTerm = props.paymentTerms.length
      ? find(props.paymentTerms, {
        paymentTermId: props.amendSoInvoiceData.paymentTermId,
      }) || {}
      : {};
      let numberOfDays = customerPaymentTerm.numberOfDays;
     
      const _emptyColumnCheck = (item, taxPercent) => {
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) <
            0 &&
          item.description
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) < 0 &&
          item.uomName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.UOM);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HSN) < 0 &&
          item.hsnCode
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HSN);
        }
        // if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.BASE_PRICE) < 0 && item.basePrice) {
        //     inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.BASE_PRICE);
        // }
        if (
          inheritedTxColumn.indexOf(
            GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT
          ) < 0 &&
          item.specialDiscount
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DISCOUNT) < 0 &&
          (
            find(item.customerSalesOrderCOATxList, {
              txType: LEDGER_TYPE.TYPE_DISCOUNT,
            }) || {}
          ).amountPercent
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DISCOUNT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.TAX) < 0 &&
          taxPercent
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.TAX);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.COMMENT) < 0 &&
          item.warehouse
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.COMMENT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER) <
            0 &&
          item.stocknumber
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PART_NUMBER) <
            0 &&
          item.partNumber
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PART_NUMBER);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.ORIGIN) < 0 &&
          item.origin
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.ORIGIN);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HS_CODE) < 0 &&
          item.hsCode
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HS_CODE);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HS_CODE) < 0 &&
          item.hsCode
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HS_CODE);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.MATERIAL_NUMBER) <
            0 &&
          item.materialNumber
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.MATERIAL_NUMBER);
        }
      };
      props.amendSoInvoiceData.customerAmendSalesOrderDetailsList.map((ele) => {
        const tax =
          find(ele.customerAmendSalesOrderCOATxList, (ele) => {
            return ele.txType === LEDGER_TYPE.TYPE_TAX;
          }) || {};
        const appliedTax =
          find(props.taxes, { taxSingleRateId: Number(tax.taxId) }) || {};
        _emptyColumnCheck(ele, tax.amountPercent);
        return detailList.push({
          selectedProductValue: [ele.itemId, ele.inventoryItemVariantId,ele.itemVariantStockId],
          version: ele.version,
          inventoryItemVariantId: ele.inventoryItemVariantId,
          product: ele.itemName
            ? ele.itemName +
              ((ele.itemVariantName && " (" + ele.itemVariantName + ")") || "")
            : "",
          productObj: ele.itemName
            ? { itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku }
            : {},
          // salesOrderMasterId: ele.salesOrderMasterId,
          salesOrderNumber: props.amendSoInvoiceData.soNumber,
          itemVariantName: ele.itemVariantName,
          variant: {
            attributeId1: ele.attributeId1,
            attributeId2: ele.attributeId2,
            attributeId3: ele.attributeId3,
            attributeName1: ele.attributeName1,
            attributeName2: ele.attributeName2,
            attributeName3: ele.attributeName3,
            attributeValue1: ele.attributeValue1,
            attributeValue2: ele.attributeValue2,
            attributeValue3: ele.attributeValue3,
          },
          amendSalesOrderMasterId:ele.amendSalesOrderMasterId,
          description: ele.description,
          scrollHeight: ele.rowHeight,
          qty: ele.quantityToInvoice,
          discount: (
            find(ele.customerAmendSalesOrderCOATxList, {
              txType: LEDGER_TYPE.TYPE_DISCOUNT,
            }) || {}
          ).amountPercent,
          tax: tax.taxName === "CGST" || tax.taxName === "SGST" ?tax.amountPercent*2 || 0:tax.amountPercent,
          taxApplied: appliedTax.taxNameDisplay,
          taxName: appliedTax.taxName,
          taxId: appliedTax.taxSingleRateId,
          quantityOrdered: ele.quantity,
          quantityInvoiced: ele.quantityInvoiced,
          rate: ele.anItemSalePrice,
          baseRate: ele.basePrice,
          uom: ele.uomName,
          hsnCode: ele.hsnCode,
          specialDiscount: ele.basePrice && ele.specialDiscount,
          comment: ele.warehouse,
          stockNumber: ele.stockNumber,
          partNumber: ele.partNumber,
          origin: ele.origin,
          hsCode: ele.hsCode,
          parentDetailsId: ele.salesOrderDetailsId,
          materialNumber: ele.materialNumber,
        });
      });

      const rootDiscount =
        (
          find(props.amendSoInvoiceData.customerAmendSalesOrderCOATxList || [], {
            txType: LEDGER_TYPE.TYPE_DISCOUNT,
          }) || {}
        ).amountPercent || 0;
      const expenseAmount =
        (
          find(props.amendSoInvoiceData.customerAmendSalesOrderCOATxList || [], {
            txType: LEDGER_TYPE.TYPE_EXPENSE,
          }) || {}
        ).amount || 0;
      let selectedContact =
        (props.amendSoInvoiceData.additionalInfoList || [])[0] || {};
      delete selectedContact.additionalInfoId;
      const selectedAmendSalesOrder = [
        { ...props.amendSoInvoiceData, customerAmendSalesOrderDetailsList: detailList },
      ];
      const forceHideTxColumn = [];
      if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0) {
        //forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
      }
      if (
        inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0
      ) {
        //forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
      }
      const linkProps =
        (this.props.location && this.props.location.state) || {};

        let rootExpenseList = [];
        let  rootDiscountList = [];
        for (let ledgerObj of props.amendSoInvoiceData?.customerAmendSalesOrderCOATxList || []) {
          if (
            ledgerObj.txType === LEDGER_TYPE.TYPE_EXPENSE &&
            ledgerObj.accountingEntry === "credit"
          ) {
             let ledger = 
              find(props.allLedgerAccounts || [], {
                chartOfAccountDetailsId: ledgerObj.ledgerAccountId,
              }) || {}
            
            rootExpenseList.push({
              // chartOfAccountDetailsId: ledgerObj.expenseLedgerAccountId,
              chartOfAccountDetailsId: ledgerObj.ledgerAccountId,
              ledgerAccountName: ledger.ledgerAccountName,
            expenseLedgerAccountName:ledger.ledgerAccountName,

              expenseLedgerAccountTaxRate:ledger.taxSingleRatePercent,
              expenseAmount: ledgerObj.amount,
              selectedExpenseLedger: {
                ...ledger
              },
            });
          }
        }
  
        for (let ledgerObj of props.amendSoInvoiceData?.customerAmendSalesOrderCOATxList || []) {
          if (
            ledgerObj.txType === LEDGER_TYPE.TYPE_DISCOUNT &&
            ledgerObj.accountingEntry === "credit"
          ) {
            let ledger = 
            find(props.allLedgerAccounts || [], {
              chartOfAccountDetailsId: ledgerObj.ledgerAccountId,
            }) || {}
            rootDiscountList.push({
              // chartOfAccountDetailsId: ledgerObj.expenseLedgerAccountId,
              chartOfAccountDetailsId: ledgerObj.ledgerAccountId,
              ledgerAccountName: ledger.ledgerAccountName,
            expenseLedgerAccountName:ledger.ledgerAccountName,

              expenseAmount: ledgerObj.amount,
              rootDiscountPercent:ledgerObj.amountPercent,
              rootDiscountAmount:ledgerObj.amount,
              selectedExpenseLedger: {
                ...ledger
              },
            });
          }
        }
      const couponAmount = (find((props.soDetail.customerAmendSalesOrderCOATxList || []), { txType: LEDGER_TYPE.TYPE_COUPON }) || {}).amount || 0;
      const coupon = (find((props.amendSoInvoiceData?.customerAmendSalesOrderCOATxList || []), { txType: LEDGER_TYPE.TYPE_COUPON }) || {});
      this.setState({
        isThroughTx:true,
        valueSet:true,
        isSoConversionToInvoice: true,
        couponAmount,
        coupanValue:coupon.taxName,
        nameOfTransport: props.amendSoInvoiceData.nameOfTransport,
        vehicleNumber: props.amendSoInvoiceData.vehicleNumber,
        roadPermitNumber: props.amendSoInvoiceData.roadPermitNumber,
        freightType: props.amendSoInvoiceData.freightType,
        consignee: props.amendSoInvoiceData.consignee,
        ewayBillNo: props.amendSoInvoiceData.ewayBillNo,
        station: props.amendSoInvoiceData.station,
        gstinNo: props.amendSoInvoiceData.gstinNo,
        // coupondata: couponAmount ?{isvalid:couponAmount?true:false,discountPercentage:coupon.amountPercent,couponType:coupon.amountPercent?"PERCENTAGE":"FLAT"}:{},
        coupondata:couponAmount ?{isvalid:couponAmount?true:false,maxDiscount:coupon.amountPercent?couponAmount:coupon?.amount,discountPercentage:coupon.amountPercent,couponType:coupon.amountPercent?"PERCENTAGE":"FLAT"}:{},
        version: props.amendSoInvoiceData.version,
        // salesOrderMasterId: props.amendSoInvoiceData.salesOrderMasterId,
        soNumber: props.amendSoInvoiceData.soNumber,
        customerPreOrderMasterId: props.amendSoInvoiceData.customerPreOrderMasterId,
        relationshipBillingAddress: find(props.amendSoInvoiceData.boLocationAmendSOList, {
          locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
        }),
        customerShippingAddress: find(props.amendSoInvoiceData.boLocationAmendSOList, {
          locationType: CONSTANTS.SHIPPING_ADDRESS,
        }),
        customerBillingAddress: find(props.amendSoInvoiceData.boLocationAmendSOList, {
          locationType: CONSTANTS.BILLING_ADDRESS,
        }),
        customerInvoicePoReplica:props.update? props.amendSoInvoiceData.customerPONumber:null,
        customerinvoiceNoReplica:props.update? props.amendSoInvoiceData.manualInvoiceNumber:null,
        isManualInvoiceNumber:props.amendSoInvoiceData.manualInvoiceNumber?.length ?true:false,
        customerPaymentTerm: customerPaymentTerm,
        paymentTermDays: customerPaymentTerm.numberOfDays,
        selectedContact: selectedContact,
        paymentTermId: customerPaymentTerm.paymentTermId, 
        paymentTermName: customerPaymentTerm.termName,
        invoiceDueDate: new Date(),
        customerRef: props.amendSoInvoiceData.referenceNumber,
        customerPo: props.amendSoInvoiceData.customerPONumber,
        manualInvoiceNumber: props.amendSoInvoiceData.manualInvoiceNumber,
        placeOfSupply: props.amendSoInvoiceData.placeOfSupply,
        customerName: props.amendSoInvoiceData.customerName,
        customer: {
          companyName: props.amendSoInvoiceData.customerName,
          customerId: props.amendSoInvoiceData.customerId,
        },
        amendSalesOrderMasterId:props.amendSoInvoiceData.amendSalesOrderMasterId,
        customerRemarks: props.amendSoInvoiceData.remarksCustomer,
        internalRemarks: props.amendSoInvoiceData.remarksInternal,
        footer: props.amendSoInvoiceData.footer,
        customerSalesOrderDetailsList: detailList?.sort((a, b) => (a.amendSalesOrderDetailsId > b.amendSalesOrderDetailsId) ? 1 : -1) || [],
        isRootDiscount: rootDiscount ? true : false,
        rootDiscountPercent: rootDiscount,
        expenseId: props.amendSoInvoiceData.expenseId,
        invoiceDueDate: numberOfDays
          ? addDaysToDate(new Date(), numberOfDays)
          : null,
        invoiceDate: new Date(),
        rootDiscountAmount:
          (
            find(props.amendSoInvoiceData.customerAmendSalesOrderCOATxList || [], {
              txType: LEDGER_TYPE.TYPE_DISCOUNT,
            }) || {}
          ).amount || 0,
        expenseAmount: expenseAmount,
        projectName: props.amendSoInvoiceData.projectName,
        projectNumber: props.amendSoInvoiceData.projectNumber,
        projectMasterId: props.amendSoInvoiceData.projectMasterId,
        isRootExpense: expenseAmount ? true : false,
        inheritedTxColumn: inheritedTxColumn,
        forceHideTxColumn: forceHideTxColumn,
        tableDataReRendered: true,
        salesQuotationMasterId: props.amendSoInvoiceData.salesQuotationMasterId,
        quotationNumber: props.amendSoInvoiceData.quotationNumber,
        rfqNumber: props.amendSoInvoiceData.rfqNumber,
        rfqMasterId: props.amendSoInvoiceData.rfqMasterId,
        selectedAmendSalesOrder,
        selectedAmendSalesOrderIds: props.amendSoMasterIdArr
          ? [props.amendSoMasterIdArr + ""]
          : null,
          rootExpenseList: rootExpenseList
      });
      selectedAmendSalesOrder.forEach((soData) => {
        const rootDiscount =
          (
            find(soData.customerAmendSalesOrderCOATxList || [], {
              txType: LEDGER_TYPE.TYPE_DISCOUNT,
            }) || {}
          ).amountPercent || 0;
        const expenseAmount =
          (
            find(soData.customerAmendSalesOrderCOATxList || [], {
              txType: LEDGER_TYPE.TYPE_EXPENSE,
            }) || {}
          ).amount || 0;

        const payload = {};

        payload["expenseAmount" + soData.amendSalesOrderMasterId] = expenseAmount;
        payload["expenseId" + soData.amendSalesOrderMasterId] = soData.expenseId;
        payload["isRootExpense" + soData.amendSalesOrderMasterId] = expenseAmount
          ? true
          : false;
        payload["rootDiscountPercent" + soData.amendSalesOrderMasterId] =
          rootDiscount;
        payload["isRootDiscount" + soData.amendSalesOrderMasterId] = rootDiscount
          ? true
          : false;
        this.setState(payload);
      });

      props.resetAmendSoDataForInvoice();
    }

    if (
      // props.taxes &&
      // props.taxes.length &&
      props.isTaxesLoaded &&
      (this.state.invoiceDraftId || this.state.invoiceProformaId) &&
      this.state.invoiceDraftData
    ) {
      const relationshipBillingAddress= find(this.state.invoiceDraftData.boLocationSalesInvoiceList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || {}
      const taxForLedger = this.state.invoiceDraftData.customerInvoiceDetailsList?this.state.invoiceDraftData.customerInvoiceDetailsList[0]?.customerInvoiceCOATxList?.filter(item=>item.txType ===LEDGER_TYPE.TYPE_TAX):[]
      const isPlaceOfSupplySameAsRelationship = relationshipBillingAddress.stateName === this.state.invoiceDraftData?.placeOfSupply
      
         taxForLedger.map((item)=>{
           if(props.companyInfo.countryName === "India" && !isPlaceOfSupplySameAsRelationship){
            if(item.accountingEntry === "debit"){
              this.setState({
                igstTaxDebtorLedgerId:item.ledgerAccountId 
              })

            }else{
              this.setState({
                igstSalesOutputTaxLedgerId:item.ledgerAccountId
              })
            }

           }else if(props.companyInfo.countryName === "India" && isPlaceOfSupplySameAsRelationship){
            if(item.accountingEntry === "debit"){
              if(item.taxName ==="CGST"){
                this.setState({
                  cgstTaxDebtorLedgerId:item.ledgerAccountId,
                  })  
              }else{
                this.setState({
                sgstTaxDebtorLedgerId:item.ledgerAccountId
                })

              }

            }else{
              if(item.taxName ==="CGST"){
                this.setState({
                  cgstSalesOutputTaxLedgerId:item.ledgerAccountId,
                  })  
              }else{
                this.setState({
                  sgstSalesOutputTaxLedgerId:item.ledgerAccountId
                })

              }
              
            }
            
           } 
      })
      const detailList = [];
      const inheritedTxColumn = [];
      const _emptyColumnCheck = (item, taxPercent) => {
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) <
            0 &&
          item.description
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) < 0 &&
          item.uomName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.UOM);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HSN) < 0 &&
          item.hsnCode
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HSN);
        }
        // if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.BASE_PRICE) < 0 && item.basePrice) {
        //     inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.BASE_PRICE);
        // }
        if (
          inheritedTxColumn.indexOf(
            GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT
          ) < 0 &&
          item.specialDiscount
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DISCOUNT) < 0 &&
          (
            find(item.customerInvoiceCOATxList, {
              txType: LEDGER_TYPE.TYPE_DISCOUNT,
            }) || {}
          ).amountPercent
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DISCOUNT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.TAX) < 0 &&
          taxPercent
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.TAX);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.COMMENT) < 0 &&
          item.warehouse
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.COMMENT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER) <
            0 &&
          item.stocknumber
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PART_NUMBER) <
            0 &&
          item.partNumber
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PART_NUMBER);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.ORIGIN) < 0 &&
          item.origin
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.ORIGIN);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HS_CODE) < 0 &&
          item.hsCode
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HS_CODE);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.MATERIAL_NUMBER) <
            0 &&
          item.materialNumber
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.MATERIAL_NUMBER);
        }
      };
      this.state.invoiceDraftData.customerInvoiceDetailsList.map((ele) => {
        const tax =
        find(ele.customerInvoiceCOATxList, (ele) => {
          return ele.txType === LEDGER_TYPE.TYPE_TAX;
        }) || {};
        const appliedTax =
        find(props.taxes, { taxSingleRateId: Number(tax.taxId) }) || {};
        _emptyColumnCheck(ele, tax.amountPercent);
        return detailList.push({
          selectedProductValue: [ele.itemId, ele.inventoryItemVariantId,ele.itemVariantStockId],
          inventoryItemVariantId: ele.inventoryItemVariantId,
          product: ele.itemName
            ? ele.itemName +
              ((ele.itemVariantName && " (" + ele.itemVariantName + ") ") || "")
            : "",
          productObj: ele.itemName
            ? { itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku }
            : {},
          salesOrderMasterId: ele.salesOrderMasterId,
          shipmentMasterId: ele.shipmentMasterId,
          parentDetailsId: ele.parentDetailsId,
          soNumber: ele.soNumber,
          itemVariantName: ele.itemVariantName,
          variant: {
            attributeId1: ele.attributeId1,
            attributeId2: ele.attributeId2,
            attributeId3: ele.attributeId3,
            attributeName1: ele.attributeName1,
            attributeName2: ele.attributeName2,
            attributeName3: ele.attributeName3,
            attributeValue1: ele.attributeValue1,
            attributeValue2: ele.attributeValue2,
            attributeValue3: ele.attributeValue3,
          },
          description: ele.description,
          scrollHeight: ele.rowHeight,
          qty: ele.quantityToInvoice,
          discount:ele.discountPercentage,
          discount: (
            find(ele.customerInvoiceCOATxList, {
              txType: LEDGER_TYPE.TYPE_DISCOUNT,
            }) || {}
          ).amountPercent,
          tax:tax.taxName === "CGST" || tax.taxName === "SGST" ?tax.amountPercent*2 || 0:tax.amountPercent,
          taxApplied: appliedTax.taxNameDisplay,
          taxName: appliedTax.taxName,
          taxId: appliedTax.taxSingleRateId,
          quantityOrdered: ele.quantity,
          quantityInvoiced: ele.quantityInvoiced,
          rate: ele.anItemSalePrice,
          baseRate: ele.basePrice,
          uom: ele.uomName,
          hsnCode: ele.hsnCode,
          specialDiscount: ele.basePrice && ele.specialDiscount,
          comment: ele.warehouse,
          stockNumber: ele.stockNumber,
          partNumber: ele.partNumber,
          origin: ele.origin,
          hsCode: ele.hsCode,
          materialNumber: ele.materialNumber,
        });
      });

      const rootDiscount =
        (
          find(this.state.invoiceDraftData.customerInvoiceCOATxList || [], {
            txType: LEDGER_TYPE.TYPE_DISCOUNT,
          }) || {}
        ).amountPercent || 0;
      const expenseAmount =
        (
          find(this.state.invoiceDraftData.customerInvoiceCOATxList || [], {
            txType: LEDGER_TYPE.TYPE_EXPENSE,
          }) || {}
        ).amount || 0;
      let selectedContact =
        (this.state.invoiceDraftData.additionalInfoList || [])[0] || {};
      delete selectedContact.additionalInfoId;
      const selectedSalesOrder = [
        {
          ...this.state.invoiceDraftData,
          customerSalesOrderDetailsList: detailList?.sort((a, b) => (a.sNo > b.sNo) ? 1 : -1) || [],
        },
      ];
      const forceHideTxColumn = [];
      if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0) {
        //forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
      }
      if (
        inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0
      ) {
        //forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
      }
      setTimeout(() => {
        if (this.state.invoiceDraftData) {
          this.setState({
        isItemDiscountAplicable:props.invoiceDraftData?.isItemDiscountAplicable,
            isProformaConversion: this.state.invoiceProformaId ? 1 : 0,
            isSoConversionToInvoice: this.state.invoiceDraftData.isSoConversion,
            isShipmentConversion:
              this.state.invoiceDraftData.isShipmentConversion,
            //salesOrderMasterId: this.state.invoiceDraftData.salesOrderMasterId,
            soNumber: this.state.invoiceDraftData.soNumber,
            relationshipBillingAddress: find(
              this.state.invoiceDraftData.boLocationSalesInvoiceList,
              { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }
            ),
            customerShippingAddress: find(
              this.state.invoiceDraftData.boLocationSalesInvoiceList,
              { locationType: CONSTANTS.SHIPPING_ADDRESS }
            ),
            customerBillingAddress: find(
              this.state.invoiceDraftData.boLocationSalesInvoiceList,
              { locationType: CONSTANTS.BILLING_ADDRESS }
            ),
            customerPaymentTerm: props.paymentTerms.length
              ? find(props.paymentTerms, {
                  paymentTermId: this.state.invoiceDraftData.paymentTermId,
                }) || {}
              : {},
            selectedContact: selectedContact,
        amendSalesOrderMasterID:props.invoiceDraftData.amendSalesOrderMasterID,
            customerRef: this.state.invoiceDraftData.referenceNumber,
            customerPo: this.state.invoiceDraftData.customerPONumber,
            manualInvoiceNumber: this.state.invoiceDraftData.manualInvoiceNumber,
            isManualInvoiceNumber: this.state.invoiceDraftData.manualInvoiceNumber?.length ? true :false,
            placeOfSupply: this.state.invoiceDraftData.placeOfSupply,
            customerName: this.state.invoiceDraftData.customerName,
            customer: {
              companyName: this.state.invoiceDraftData.customerName,
              customerId: this.state.invoiceDraftData.customerId,
            },
            customerRemarks: this.state.invoiceDraftData.remarksCustomer,
            internalRemarks: this.state.invoiceDraftData.remarksInternal,
            footer: this.state.invoiceDraftData.footer,
            customerSalesOrderDetailsList: detailList,
            isRootDiscount: rootDiscount ? true : false,
            rootDiscountPercent: rootDiscount,
            expenseId: this.state.invoiceDraftData.expenseId,
            rootDiscountAmount:
              (
                find(
                  this.state.invoiceDraftData.customerInvoiceCOATxList || [],
                  { txType: LEDGER_TYPE.TYPE_DISCOUNT }
                ) || {}
              ).amount || 0,
            expenseAmount: expenseAmount,
            projectName: this.state.invoiceDraftData.projectName,
            projectNumber: this.state.invoiceDraftData.projectNumber,
            projectMasterId: this.state.invoiceDraftData.projectMasterId,
            isRootExpense: expenseAmount ? true : false,
            inheritedTxColumn: inheritedTxColumn,
            forceHideTxColumn: forceHideTxColumn,
            tableDataReRendered: true,
            salesQuotationMasterId:
              this.state.invoiceDraftData.salesQuotationMasterId,
            quotationNumber: this.state.invoiceDraftData.quotationNumber,
            rfqNumber: this.state.invoiceDraftData.rfqNumber,
            rfqMasterId: this.state.invoiceDraftData.rfqMasterId,
            selectedSalesOrder,
            salesOrderMasterId: this.state.invoiceDraftData.salesOrderMasterId,
            selectedSalesOrderIds: this.state.invoiceDraftData
              .salesOrderMasterId
              ? [this.state.invoiceDraftData.salesOrderMasterId]
              : null,
            isCashSales: this.state.invoiceDraftData.isCashInvoice,
            invoiceDraftData: null,
            isAllowPayOnline: selectedContact.paymentGatewayName ? true : false,
            paymentGatewayId: selectedContact.paymentGatewayId,
            paymentGatewayName: selectedContact.paymentGatewayName,
          });
        }
      }, 1000);
      selectedSalesOrder.forEach((soData) => {
        const rootDiscount =
          (
            find(soData.customerInvoiceCOATxList || [], {
              txType: LEDGER_TYPE.TYPE_DISCOUNT,
            }) || {}
          ).amountPercent || 0;
        const expenseAmount =
          (
            find(soData.customerInvoiceCOATxList || [], {
              txType: LEDGER_TYPE.TYPE_EXPENSE,
            }) || {}
          ).amount || 0;

        const payload = {};

        payload["expenseAmount" + soData.salesOrderMasterId] = expenseAmount;
        payload["expenseId" + soData.salesOrderMasterId] = soData.expenseId;
        payload["isRootExpense" + soData.salesOrderMasterId] = expenseAmount
          ? true
          : false;
        payload["rootDiscountPercent" + soData.salesOrderMasterId] =
          rootDiscount;
        payload["isRootDiscount" + soData.salesOrderMasterId] = rootDiscount
          ? true
          : false;
        this.setState(payload);
      });

      //  props.resetSoInvoiceData();
    }

    if (
    //  props.taxes &&
     // props.taxes.length &&
      props.isTaxesLoaded &&
      props.salesInvoiceDetail &&
      props.salesInvoiceDetail.invoiceMasterId
    ) {

    //  let stateProps = (this.props.location && this.props.location.state) || {}; 
    let stateProps = {...props};
      const detailList = [];
      const inheritedTxColumn = [];
      let selectedLedgers = {};
      const _emptyColumnCheck = (item, taxPercent) => {
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) <
            0 &&
          item.description
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) < 0 &&
          item.uomName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.UOM);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HSN) < 0 &&
          item.hsnCode
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HSN);
        }
        // if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.BASE_PRICE) < 0 && item.basePrice) {
        //     inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.BASE_PRICE);
        // }
        if (
          inheritedTxColumn.indexOf(
            GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT
          ) < 0 &&
          item.specialDiscount
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DISCOUNT) < 0 &&
          (
            find(item.customerInvoiceCOATxList, {
              txType: LEDGER_TYPE.TYPE_DISCOUNT,
            }) || {}
          ).amountPercent
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DISCOUNT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.TAX) < 0 &&
          taxPercent
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.TAX);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.COMMENT) < 0 &&
          item.warehouse
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.COMMENT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER) <
            0 &&
          item.stocknumber
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PART_NUMBER) <
            0 &&
          item.partNumber
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PART_NUMBER);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.ORIGIN) < 0 &&
          item.origin
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.ORIGIN);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HS_CODE) < 0 &&
          item.hsCode
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HS_CODE);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.MATERIAL_NUMBER) <
            0 &&
          item.materialNumber
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.MATERIAL_NUMBER);
        }
      };
      const relationshipBillingAddress= find(props.salesInvoiceDetail.boLocationSalesInvoiceList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || {}
      const taxForLedger = props.salesInvoiceDetail.customerInvoiceDetailsList?props.salesInvoiceDetail.customerInvoiceDetailsList[0]?.customerInvoiceCOATxList?.filter(item=>item.txType ===LEDGER_TYPE.TYPE_TAX):[]
      const isPlaceOfSupplySameAsRelationship = relationshipBillingAddress.stateName === props.salesInvoiceDetail?.placeOfSupply
      
         taxForLedger.map((item)=>{
           if(props.companyInfo.countryName === "India" && !isPlaceOfSupplySameAsRelationship){
            if(item.accountingEntry === "debit"){
              this.setState({
                igstTaxDebtorLedgerId:item.ledgerAccountId 
              })

            }else{
              this.setState({
                igstSalesOutputTaxLedgerId:item.ledgerAccountId
              })
            }

           }else if(props.companyInfo.countryName === "India" && isPlaceOfSupplySameAsRelationship){
            if(item.accountingEntry === "debit"){
              if(item.taxName ==="CGST"){
                this.setState({
                  cgstTaxDebtorLedgerId:item.ledgerAccountId,
                  })  
              }else{
                this.setState({
                sgstTaxDebtorLedgerId:item.ledgerAccountId
                })

              }

            }else{
              if(item.taxName ==="CGST"){
                this.setState({
                  cgstSalesOutputTaxLedgerId:item.ledgerAccountId,
                  })  
              }else{
                this.setState({
                  sgstSalesOutputTaxLedgerId:item.ledgerAccountId
                })

              }
              
            }
            
           } 
      })
      props.salesInvoiceDetail.customerInvoiceDetailsList.map((ele) => {
        const tax =
          find(ele.customerInvoiceCOATxList, (ele) => {
            return ele.txType === LEDGER_TYPE.TYPE_TAX;
          }) || {};

        ele.customerInvoiceCOATxList.map((ledgerObj) => {
          if (
            ledgerObj.txType === LEDGER_TYPE.TYPE_DISCOUNT &&
            ledgerObj.accountingEntry === "credit"
          ) {
            selectedLedgers.discountDebtorLedgerId = ledgerObj.ledgerAccountId;
          } else if (
            ledgerObj.txType === LEDGER_TYPE.TYPE_DISCOUNT &&
            ledgerObj.accountingEntry === "debit"
          ) {
            selectedLedgers.salesDiscountLedgerId = ledgerObj.ledgerAccountId;
          } else if (
            ledgerObj.txType === LEDGER_TYPE.TYPE_TAX &&
            ledgerObj.accountingEntry === "credit"
          ) {
            selectedLedgers.salesOutputTaxLedgerId = ledgerObj.ledgerAccountId;
          } else if (
            ledgerObj.txType === LEDGER_TYPE.TYPE_TAX &&
            ledgerObj.accountingEntry === "debit"
          ) {
            selectedLedgers.taxDebtorLedgerId = ledgerObj.ledgerAccountId;
          }
          return "";
        });
        const appliedTax =
          find(props.taxes, { taxSingleRateId: Number(tax.taxId) }) || {};
        _emptyColumnCheck(ele, tax.amountPercent);
        return detailList.push({
          version: ele.version,
          invoiceMasterId: stateProps.update ? ele.invoiceMasterId : 0,
          selectedProductValue: [ele.itemId, ele.inventoryItemVariantId,ele.itemVariantStockId],
          inventoryItemVariantId: ele.inventoryItemVariantId,
          product: ele.itemName
            ? ele.itemName +
              ((ele.itemVariantName && " (" + ele.itemVariantName + ") ") || "")
            : "",
          productObj: ele.itemName
            ? { itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku }
            : {},
          salesOrderMasterId: ele.salesOrderMasterId,
          shipmentMasterId: ele.shipmentMasterId,
          soNumber: ele.soNumber,
          itemVariantName: ele.itemVariantName,
          variant: {
            attributeId1: ele.attributeId1,
            attributeId2: ele.attributeId2,
            attributeId3: ele.attributeId3,
            attributeName1: ele.attributeName1,
            attributeName2: ele.attributeName2,
            attributeName3: ele.attributeName3,
            attributeValue1: ele.attributeValue1,
            attributeValue2: ele.attributeValue2,
            attributeValue3: ele.attributeValue3,
          },
          description: ele.description,
          scrollHeight: ele.rowHeight,
          qty: ele.quantityToInvoice,
          discount: ele.discountPercentage,
          tax: tax.taxName === "CGST" || tax.taxName === "SGST" ?tax.amountPercent*2 || 0:tax.amountPercent,
          taxApplied: appliedTax.taxNameDisplay,
          taxName: appliedTax.taxName,
          taxId: appliedTax.taxSingleRateId,
          quantityOrdered: ele.quantity,
          quantityInvoiced: ele.quantityInvoiced,
          rate: ele.anItemSalePrice,
          baseRate: ele.basePrice,
          uom: ele.uomName,
          hsnCode: ele.hsnCode,
          specialDiscount: ele.basePrice && ele.specialDiscount,
          comment: ele.warehouse,
          stockNumber: ele.stockNumber,
          partNumber: ele.partNumber,
          origin: ele.origin,
          hsCode: ele.hsCode,
          parentDetailsId: ele.parentDetailsId,
          materialNumber: ele.materialNumber,
          invoiceDetailsId: stateProps.update ? ele.invoiceDetailsId : 0,
          customerInvoiceDetailsTaxList: stateProps.update
            ? ele.customerInvoiceDetailsTaxList
            : [],
          documentDetails: stateProps.update ? ele.documentDetails : [],
        });
      });

      props.salesInvoiceDetail.customerInvoiceCOATxList.map((ledgerObj) => {
        if (
          ledgerObj.txType === LEDGER_TYPE.TYPE_SALES &&
          ledgerObj.accountingEntry === "credit"
        ) {
          selectedLedgers.salesLedgerId = ledgerObj.ledgerAccountId;
        } else if (
          ledgerObj.txType === LEDGER_TYPE.TYPE_SALES &&
          ledgerObj.accountingEntry === "debit"
        ) {
          selectedLedgers.salesDebtorLedgerId = ledgerObj.ledgerAccountId;
        } else if (
          ledgerObj.txType === LEDGER_TYPE.TYPE_EXPENSE &&
          ledgerObj.accountingEntry === "credit"
        ) {
          selectedLedgers.expenseLedgerId = ledgerObj.ledgerAccountId;
        } else if (
          ledgerObj.txType === LEDGER_TYPE.TYPE_EXPENSE &&
          ledgerObj.accountingEntry === "debit"
        ) {
          selectedLedgers.expenseDebtorLedgerId = ledgerObj.ledgerAccountId;
        } else if (
          ledgerObj.txType === LEDGER_TYPE.TYPE_DISCOUNT &&
          ledgerObj.accountingEntry === "credit"
        ) {
          selectedLedgers.discountDebtorLedgerId = ledgerObj.ledgerAccountId;
        } else if (
          ledgerObj.txType === LEDGER_TYPE.TYPE_DISCOUNT &&
          ledgerObj.accountingEntry === "debit"
        ) {
          selectedLedgers.salesDiscountLedgerId = ledgerObj.ledgerAccountId;
        }
        return "";
      });

      const linkProps =
        (this.props.location && this.props.location.state) || {};
      const rootDiscount =
        (
          find(props.salesInvoiceDetail.customerInvoiceCOATxList || [], {
            txType: LEDGER_TYPE.TYPE_DISCOUNT,
          }) || {}
        ).amountPercent || 0;
      const expenseAmount =
        (
          find(props.salesInvoiceDetail.customerInvoiceCOATxList || [], {
            txType: LEDGER_TYPE.TYPE_EXPENSE,
          }) || {}
        ).amount || 0;
      let selectedContact =
        (props.salesInvoiceDetail.additionalInfoList || [])[0] || {};
      delete selectedContact.additionalInfoId;
      const selectedSalesOrder = [];
      const forceHideTxColumn = [];
      const additionalInfoList =
        props.salesInvoiceDetail.additionalInfoList[0] || {};

      if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0) {
        //forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
      }
      if (
        inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0
      ) {
        //forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
      }
      let rootExpenseList = [];
      // for (let ledgerObj of props.salesInvoiceDetail
      //   ?.customerInvoiceCOATxList || []) {
      //   if (
      //     ledgerObj.txType === LEDGER_TYPE.TYPE_EXPENSE &&
      //     ledgerObj.accountingEntry === "credit"
      //   ) {
      //     rootExpenseList.push({
      //       chartOfAccountDetailsId: ledgerObj.ledgerAccountId,
      //       ledgerAccountName: ledgerObj.ledgerAccountName,
      //       expenseAmount: ledgerObj.amount,
      //       selectedExpenseLedger: {
      //         taxSingleRatePercent: ledgerObj.taxId,
      //         taxSingleRateId: ledgerObj.taxRate,
      //       },
      //     });
      //   }
      // }

      let  rootDiscountList = [];
      for (let ledgerObj of props.salesInvoiceDetail?.customerInvoiceCOATxList || []) {
        if (
          ledgerObj.txType === LEDGER_TYPE.TYPE_EXPENSE &&
          ledgerObj.accountingEntry === "credit"
        ) {
           let ledger = 
            find(props.allLedgerAccounts || [], {
              chartOfAccountDetailsId: ledgerObj.ledgerAccountId,
            }) || {}
          
          rootExpenseList.push({
            // chartOfAccountDetailsId: ledgerObj.expenseLedgerAccountId,
            chartOfAccountDetailsId: ledgerObj.ledgerAccountId,
            ledgerAccountName: ledger.ledgerAccountName,
            expenseLedgerAccountName:ledger.ledgerAccountName,
            expenseLedgerAccountTaxRate:ledger.taxSingleRatePercent,
            expenseAmount: ledgerObj.amount,
            selectedExpenseLedger: {
              ...ledger
            },
          });
        }
      }

      for (let ledgerObj of props.salesInvoiceDetail?.customerInvoiceCOATxList || []) {
        if (
          ledgerObj.txType === LEDGER_TYPE.TYPE_DISCOUNT &&
          ledgerObj.accountingEntry === "credit"
        ) {
          let ledger = 
          find(props.allLedgerAccounts || [], {
            chartOfAccountDetailsId: ledgerObj.ledgerAccountId,
          }) || {}
          rootDiscountList.push({
            // chartOfAccountDetailsId: ledgerObj.expenseLedgerAccountId,
            chartOfAccountDetailsId: ledgerObj.ledgerAccountId,
            ledgerAccountName: ledger.ledgerAccountName,
            expenseLedgerAccountName:ledger.ledgerAccountName,
            expenseAmount: ledgerObj.amount,
            isTaxable:ledgerObj.isTaxable,
            rootDiscountPercent:ledgerObj.amountPercent,
            rootDiscountAmount:ledgerObj.amount,
            selectedExpenseLedger: {
              ...ledger
            },
          });
        }
      }
      const couponAmount = (find((props.salesInvoiceDetail?.customerInvoiceCOATxList || []), { txType: LEDGER_TYPE.TYPE_COUPON }) || {}).amount || 0;

      const coupon = (find((props.salesInvoiceDetail?.customerInvoiceCOATxList || []), { txType: LEDGER_TYPE.TYPE_COUPON }) || {});

      this.setState({
        ...selectedLedgers,
        valueSet:true,
        coupanValue:coupon.taxName,
        couponAmount,
        nameOfTransport: props.salesInvoiceDetail.nameOfTransport,
        vehicleNumber: props.salesInvoiceDetail.vehicleNumber,
        roadPermitNumber: props.salesInvoiceDetail.roadPermitNumber,
        freightType: props.salesInvoiceDetail.freightType,
        consignee: props.salesInvoiceDetail.consignee,
        ewayBillNo: props.salesInvoiceDetail.ewayBillNo,
        station: props.salesInvoiceDetail.station,
        gstinNo: props.salesInvoiceDetail.gstinNo,
        // coupondata:couponAmount ?{isvalid:couponAmount?true:false,discountPercentage:coupon.amountPercent,couponType:coupon.amountPercent?"PERCENTAGE":"FLAT"}:"",
        coupondata:couponAmount ?{isvalid:couponAmount?true:false,maxDiscount:coupon.amountPercent?couponAmount:coupon?.amount,discountPercentage:coupon.amountPercent,couponType:coupon.amountPercent?"PERCENTAGE":"FLAT"}:{},
        rootExpenseList:rootExpenseList,
        viewCoupon:couponAmount?false:true,
        rootDiscountList:rootDiscountList,
        salesOrderMasterId: props.salesInvoiceDetail.salesOrderMasterId,
        version: props.salesInvoiceDetail.version,
        customerPreOrderMasterId: props.salesInvoiceDetail.salesOrderMasterId,
        amendSalesOrderMasterID:props.salesInvoiceDetail.amendSalesOrderMasterID,
        isUpdate: true,
        invoiceMasterId: stateProps.update
          ? props.salesInvoiceDetail.invoiceMasterId
          : 0,
        soNumber: props.salesInvoiceDetail.soNumber,
        relationshipBillingAddress: find(
          props.salesInvoiceDetail.boLocationSalesInvoiceList,
          { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }
        ),
        customerShippingAddress: find(
          props.salesInvoiceDetail.boLocationSalesInvoiceList,
          { locationType: CONSTANTS.SHIPPING_ADDRESS }
        ),
        customerBillingAddress: find(
          props.salesInvoiceDetail.boLocationSalesInvoiceList,
          { locationType: CONSTANTS.BILLING_ADDRESS }
        ),
        customerPaymentTerm: props.paymentTerms.length
          ? find(props.paymentTerms, {
              paymentTermId: props.salesInvoiceDetail.paymentTermId,
            }) || {}
          : {},
        selectedContact: selectedContact,
        isItemDiscountAplicable:props.salesInvoiceDetail?.isItemDiscountAplicable,
        customerRef: props.salesInvoiceDetail.referenceNumber,
        invoiceDueDate: props.salesInvoiceDetail.invoiceDueDate
          ? new Date(props.salesInvoiceDetail.invoiceDueDate)
          : null,
        invoiceDate: props.salesInvoiceDetail.invoiceDate
          ? new Date(props.salesInvoiceDetail.invoiceDate)
          : null,
        customerPo: props.salesInvoiceDetail.customerPONumber,
        customerInvoicePoReplica:props.update? props.salesInvoiceDetail.customerPONumber:null,
        customerinvoiceNoReplica:props.update? props.salesInvoiceDetail.manualInvoiceNumber:null,
        manualInvoiceNumber: props.salesInvoiceDetail.manualInvoiceNumber,
        isManualInvoiceNumber:props.salesInvoiceDetail.manualInvoiceNumber?.length ?true:false,
        placeOfSupply: props.salesInvoiceDetail.placeOfSupply,
        customerName: props.salesInvoiceDetail.customerName,
        // customer: {
        //     companyName: props.salesInvoiceDetail.customerName, customerId: props.salesInvoiceDetail.customerId
        // },
        customerRemarks: props.salesInvoiceDetail.remarksCustomer,
        internalRemarks: props.salesInvoiceDetail.remarksInternal,
        footer: props.salesInvoiceDetail.footer,
        customerSalesOrderDetailsList: detailList?.sort((a, b) => (a.sNo > b.sNo) ? 1 : -1) || [],
        isRootDiscount: rootDiscount ? true : false,
        rootDiscountPercent: rootDiscount,
        expenseId: props.salesInvoiceDetail.expenseId,
        rootDiscountAmount:
          (
            find(props.salesInvoiceDetail.customerInvoiceCOATxList || [], {
              txType: LEDGER_TYPE.TYPE_DISCOUNT,
            }) || {}
          ).amount || 0,
        expenseAmount: expenseAmount,
        projectName: props.salesInvoiceDetail.projectName,
        projectNumber: props.salesInvoiceDetail.projectNumber,
        projectMasterId: props.salesInvoiceDetail.projectMasterId,
        isRootExpense: expenseAmount ? true : false,
        inheritedTxColumn: inheritedTxColumn,
        forceHideTxColumn: forceHideTxColumn,
        tableDataReRendered: true,
        salesQuotationMasterId: props.salesInvoiceDetail.salesQuotationMasterId,
        quotationNumber: props.salesInvoiceDetail.quotationNumber,
        rfqNumber: props.salesInvoiceDetail.rfqNumber,
        rfqMasterId: props.salesInvoiceDetail.rfqMasterId,
        selectedSalesOrder,
        selectedSalesOrderIds: props.soMasterIdArr
          ? [props.soMasterIdArr]
          : null,

        isSORadio: props.soMasterIdArr ? true : false,
        isSoConversionToInvoice: selectedSalesOrder.length ? true : false,
        isCashSales: props.salesInvoiceDetail.isCashInvoice,
        docDetailList: props.salesInvoiceDetail.docDetailList,

        oldPayload: stateProps.update ? props.salesInvoiceDetail : {},
        isSoConversion: stateProps.update
          ? props.salesInvoiceDetail.isSOConversion
          : 0,
        isProformaConversion: stateProps.update
          ? props.salesInvoiceDetail.isProformaConversion
          : 0,
        isShipmentConversion: stateProps.update
          ? props.salesInvoiceDetail.isShipmentConversion
          : 0,
        docName: props.salesInvoiceDetail.documentName,
        documentNameId: (
          find(props.documentList || [], {
            docName: props.salesInvoiceDetail.documentName,
          }) || {}
        ).documentNameId,
        isAllowPayOnline: selectedContact.paymentGatewayName ? true : false,
        paymentGatewayId: selectedContact.paymentGatewayId,
        paymentGatewayName: selectedContact.paymentGatewayName,
        selectedRelationshipTaxIdentifications:
          additionalInfoList.relationshipTaxIdentifications
            ? JSON.parse(additionalInfoList.relationshipTaxIdentifications).map(
                (obj) => {
                  return obj.relationshipTaxIdentificationId;
                }
              )
            : this.state.selectedRelationshipTaxIdentifications,
      });

      if (!this.state.isClone) {
        this.setState({
          nextTxNumber: props.salesInvoiceDetail.invoiceNumber,
        });
      }

      selectedSalesOrder.forEach((soData) => {
        const rootDiscount =
          (
            find(soData.customerInvoiceCOATxList || [], {
              txType: LEDGER_TYPE.TYPE_DISCOUNT,
            }) || {}
          ).amountPercent || 0;
        const expenseAmount =
          (
            find(soData.customerInvoiceCOATxList || [], {
              txType: LEDGER_TYPE.TYPE_EXPENSE,
            }) || {}
          ).amount || 0;

        const payload = {};

        payload["expenseAmount" + soData.salesOrderMasterId] = expenseAmount;
        payload["expenseId" + soData.salesOrderMasterId] = soData.expenseId;
        payload["isRootExpense" + soData.salesOrderMasterId] = expenseAmount
          ? true
          : false;
        payload["rootDiscountPercent" + soData.salesOrderMasterId] =
          rootDiscount;
        payload["isRootDiscount" + soData.salesOrderMasterId] = rootDiscount
          ? true
          : false;
        this.setState(payload);
      });
         if(!(this.props.validatedGstr1)){
this.props.resetSalesInvoice();
         }
      
    }

    if (
      this.state.stateCustomerId &&
      props.customers[this.state.pageNumber] &&
      props.customers[this.state.pageNumber].length &&
      props.paymentTerms &&
      props.paymentTerms.length
    ) {
      const customer =
      find(props.customers[this.state.pageNumber], {customerId: Number(this.state.stateCustomerId || 0),}) || {};
      const allShippingAddress =(customer &&filter(customer.boLocationList, {locationType: CONSTANTS.SHIPPING_ADDRESS,})) ||[];
      const defaultShippingAddress =find(allShippingAddress, { isDefault: 1 }) || {};const allBillingAddress =(customer &&filter(customer.boLocationList, {locationType: CONSTANTS.BILLING_ADDRESS,})) ||[];
      const defaultBillingAddress =
      find(allBillingAddress, { isDefault: 1 }) || {};
      const customerPaymentTerm = this.state.customerPaymentTerm ||(props.paymentTerms.length? find(props.paymentTerms, {paymentTermId: (props.soInvoiceData.paymentTermId ||customer && customer.paymentTermId) || 0,}) || {}: {});
      let numberOfDays = customerPaymentTerm.numberOfDays;

      this.setState({
        stateCustomerId: null,
        customer,
        customerName: customer.companyName,
        customerPaymentTerm,
        paymentTermId: customerPaymentTerm.paymentTermId,
        paymentTermName: customerPaymentTerm.termName,
        customerShippingAddress:
          this.state.customerShippingAddress || defaultShippingAddress,
        customerBillingAddress:
          this.state.customerBillingAddress || defaultBillingAddress,
        placeOfSupply:props.placeOfSupply?.length ?props.placeOfSupply:
          this.state.customerBillingAddress &&
          this.state.customerBillingAddress.stateName,
        invoiceDueDate: numberOfDays
          ? addDaysToDate(
              this.state.invoiceDate || props.invoiceDate || new Date(),
              numberOfDays
            )
          : null,
        boContactList: customer.boContactList || [],
      });
    }

    if (props.allPreferences && props.allPreferences.length) {
      let txPreferences =
        find(props.allPreferences, {
          txType: "salesInvoice",
          templateName: "standard",
        }) || {};
      if (txPreferences) {
        let txColumns = txPreferences.gridPreference
          ? (JSON.parse(txPreferences.gridPreference) || {}).columnList || []
          : [];
        txColumns = txColumns.length
          ? txColumns
          : (this.state.txMandatoryColumns || []).concat(
              "product",
              "description"
            );
        let tablePreference = txPreferences.tablePreference;
        const txColumnSetting = txPreferences.gridPreference
          ? (JSON.parse(txPreferences.gridPreference) || {}).columnSetting || {}
          : {};
        const isColumnFitTable =
          Object.keys(txColumnSetting).length ||
          this.state.isColumnFitTable === false
            ? false
            : true;

        this.setState({
          isColumnFitTable,
          tablePreference,
          txColumns,
          txColumnSetting,
          txPreferences,
        });
      } else {
        this.setState({
          txColumns: (this.state.txMandatoryColumns || []).concat(
            "product",
            "description"
          ),
        });
      }
    }

    if (this.props.shipmentData && this.props.shipmentData.shipmentMasterId) {
      if (
        this.state.selectedSalesOrder &&
        this.state.selectedSalesOrder.length
      ) {
        const soData = find(this.state.selectedSalesOrder, {
          shipmentMasterId: Number(this.props.shipmentData.shipmentMasterId),
        });
        if (soData) return;
      }
      const shipmentData = this.props.shipmentData || {};
      const detailList = [];
      const inheritedTxColumn = [];
      const _emptyColumnCheck = (item, taxPercent) => {
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) <
            0 &&
          item.description
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) < 0 &&
          item.uomName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.UOM);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HSN) < 0 &&
          item.hsnCode
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HSN);
        }
        // if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.BASE_PRICE) < 0 && item.basePrice) {
        //     inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.BASE_PRICE);
        // }
        if (
          inheritedTxColumn.indexOf(
            GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT
          ) < 0 &&
          item.specialDiscount
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DISCOUNT) < 0 &&
          (
            find(item.customerSalesOrderCOATxList, {
              txType: LEDGER_TYPE.TYPE_DISCOUNT,
            }) || {}
          ).amountPercent
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DISCOUNT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.TAX) < 0 &&
          taxPercent
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.TAX);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.COMMENT) < 0 &&
          item.comment
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.COMMENT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER) <
            0 &&
          item.stocknumber
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PART_NUMBER) <
            0 &&
          item.partNumber
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PART_NUMBER);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.ORIGIN) < 0 &&
          item.origin
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.ORIGIN);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HS_CODE) < 0 &&
          item.hsCode
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HS_CODE);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.MATERIAL_NUMBER) <
            0 &&
          item.materialNumber
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.MATERIAL_NUMBER);
        }
      };
      shipmentData.customerSOShipmentDetailsList.map((ele) => {
        const tax =
          find(ele.customerShipmentCOATxList, (ele) => {
            return ele.txType === LEDGER_TYPE.TYPE_TAX;
          }) || {};

        const appliedTax =
          find(props.taxes, { taxSingleRateId: Number(tax.taxId) }) || {};
        const item = { itemId: ele.itemId, itemName: ele.itemName };
        _emptyColumnCheck(ele, tax.amountPercent);
        item.itemId = ele.itemId;
        item.selectedProductValue = [ele.itemId, ele.inventoryItemVariantId,ele.itemVariantStockId];
        item.product = ele.itemName
          ? ele.itemName +
            ((ele.itemVariantName && " (" + ele.itemVariantName + ") ") || "")
          : "";
        item.productObj = ele.itemName
          ? { itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku }
          : {};
        item.itemVariantName = ele.itemVariantName;
        item.variant = {
          attributeId1: ele.attributeId1,
          attributeId2: ele.attributeId2,
          attributeId3: ele.attributeId3,
          attributeName1: ele.attributeName1,
          attributeName2: ele.attributeName2,
          attributeName3: ele.attributeName3,
          attributeValue1: ele.attributeValue1,
          attributeValue2: ele.attributeValue2,
          attributeValue3: ele.attributeValue3,
        };
        item.description = ele.description;
        item.scrollHeight = ele.rowHeight;
        item.qty = Number(ele.quantity || 0);
        item.discount = (
          find(ele.customerShipmentCOATxList, {
            txType: LEDGER_TYPE.TYPE_DISCOUNT,
          }) || {}
        ).amountPercent;
        item.tax = tax.amountPercent || 0;
        item.taxApplied = appliedTax.taxNameDisplay;
        item.taxId = appliedTax.taxSingleRateId;
        item.taxName = appliedTax.taxName;
        item.quantityOrdered = ele.quantity;
        item.shipmentNumber = shipmentData.shipmentNumber;
        item.quantityInvoiced = ele.quantityInvoiced;
        item.rate = ele.rate;
        item.baseRate = ele.basePrice;
        item.uom = ele.uomName;
        item.hsnCode = ele.hsnCode;
        item.specialDiscount = ele.basePrice && ele.specialDiscount;
        item.comment = ele.comment;
        item.stockNumber = ele.stockNumber;
        item.partNumber = ele.partNumber;
        item.origin = ele.origin;
        item.hsCode = ele.hsCode;
        item.materialNumber = ele.materialNumber;
        item.salesOrderMasterId = ele.salesOrderMasterId;
        item.salesOrderNumber = ele.salesOrderNumber;
        item.soNumber = shipmentData.soNumber;
        item.customerId = shipmentData.customerId;
        item.salesQuotationMasterId = shipmentData.salesQuotationMasterId;
        item.quotationNumber = shipmentData.quotationNumber;
        item.rfqNumber = shipmentData.rfqNumber;
        item.rfqMasterId = shipmentData.rfqMasterId;
        item.shipmentMasterId = shipmentData.shipmentMasterId;
        item.parentDetailsId = ele.salesOrderDetailsId;
        return detailList.push(item);
      });
      const { selectedSalesOrder } = this.state;
      let newDetailList = [];
      selectedSalesOrder.push({
        ...shipmentData,
        customerSalesOrderDetailsList: detailList,
      });
      selectedSalesOrder.forEach((e) => {
        newDetailList = newDetailList.concat(e.customerSalesOrderDetailsList);
      });
      this.setState({
        shipmentNumber: shipmentData.shipmentNumber,
        isSoConversion: false,
        selectedSalesOrder: selectedSalesOrder,
        customerSalesOrderDetailsList: newDetailList,
        customerRef: shipmentData.referenceNumber,
        salesOrderMasterId: shipmentData.soMasterId,
        isShipmentConversion: true,
      });

      this.props.resetShipmentData();
    }

    if (this.props.soDataBySOId && this.props.soDataBySOId.salesOrderMasterId) {
      if (
        this.state.selectedSalesOrder &&
        this.state.selectedSalesOrder.length
      ) {
        const soData = find(this.state.selectedSalesOrder, {
          salesOrderMasterId: Number(
            this.props.soDataBySOId.salesOrderMasterId
          ),
        });
        if (soData) return;
      }
      const detailList = [];
      const inheritedTxColumn = [];
      const _emptyColumnCheck = (item, taxPercent) => {
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) <
            0 &&
          item.description
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) < 0 &&
          item.uomName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.UOM);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HSN) < 0 &&
          item.hsnCode
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HSN);
        }
        // if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.BASE_PRICE) < 0 && item.basePrice) {
        //     inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.BASE_PRICE);
        // }
        if (
          inheritedTxColumn.indexOf(
            GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT
          ) < 0 &&
          item.specialDiscount
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DISCOUNT) < 0 &&
          (
            find(item.customerSalesOrderCOATxList, {
              txType: LEDGER_TYPE.TYPE_DISCOUNT,
            }) || {}
          ).amountPercent
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DISCOUNT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.TAX) < 0 &&
          taxPercent
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.TAX);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.COMMENT) < 0 &&
          item.comment
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.COMMENT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER) <
            0 &&
          item.stocknumber
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PART_NUMBER) <
            0 &&
          item.partNumber
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PART_NUMBER);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.ORIGIN) < 0 &&
          item.origin
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.ORIGIN);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HS_CODE) < 0 &&
          item.hsCode
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HS_CODE);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.MATERIAL_NUMBER) <
            0 &&
          item.materialNumber
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.MATERIAL_NUMBER);
        }
      };
      this.props.soDataBySOId.customerSalesOrderDetailsList.map((ele) => {
        const tax =
          find(ele.customerSalesOrderCOATxList, (ele) => {
            return ele.txType === LEDGER_TYPE.TYPE_TAX;
          }) || {};
        const appliedTax =
          find(props.taxes, { taxSingleRateId: Number(tax.taxId) }) || {};
        _emptyColumnCheck(ele, tax.amountPercent);
        return detailList.push({
          selectedProductValue: [ele.itemId, ele.inventoryItemVariantId,ele.itemVariantStockId],
          inventoryItemVariantId: ele.inventoryItemVariantId,
          product: ele.itemName
            ? ele.itemName +
              ((ele.itemVariantName && " (" + ele.itemVariantName + ") ") || "")
            : "",
          productObj: ele.itemName
            ? { itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku }
            : {},
          itemVariantName: ele.itemVariantName,
          variant: {
            attributeId1: ele.attributeId1,
            attributeId2: ele.attributeId2,
            attributeId3: ele.attributeId3,
            attributeName1: ele.attributeName1,
            attributeName2: ele.attributeName2,
            attributeName3: ele.attributeName3,
            attributeValue1: ele.attributeValue1,
            attributeValue2: ele.attributeValue2,
            attributeValue3: ele.attributeValue3,
          },
          description: ele.description,
          scrollHeight: ele.rowHeight,
          qty: ele.quantityToInvoice,
          discount: (
            find(ele.customerSalesOrderCOATxList, {
              txType: LEDGER_TYPE.TYPE_DISCOUNT,
            }) || {}
          ).amountPercent,
          tax:tax.amountPercent,
          taxApplied: appliedTax.taxNameDisplay,
          taxId: appliedTax.taxSingleRateId,
          salesOrderNumber: this.props.soDataBySOId.soNumber,
          taxName: appliedTax.taxName,
          quantityOrdered: ele.quantity,
          quantityInvoiced: ele.quantityInvoiced,
          rate: ele.anItemSalePrice,
          baseRate: ele.basePrice,
          uom: ele.uomName,
          hsnCode: ele.hsnCode,
          specialDiscount: ele.basePrice && ele.specialDiscount,
          comment: ele.comment,
          stockNumber: ele.stockNumber,
          partNumber: ele.partNumber,
          origin: ele.origin,
          hsCode: ele.hsCode,
          materialNumber: ele.materialNumber,
          salesOrderMasterId: this.props.soDataBySOId.salesOrderMasterId,
          soNumber: this.props.soDataBySOId.soNumber,
          customerId: this.props.soDataBySOId.customerId,
          salesQuotationMasterId:
            this.props.soDataBySOId.salesQuotationMasterId,
          quotationNumber: this.props.soDataBySOId.quotationNumber,
          rfqNumber: this.props.soDataBySOId.rfqNumber,
          rfqMasterId: this.props.soDataBySOId.rfqMasterId,
          parentDetailsId: ele.salesOrderDetailsId,
        });
      });

      //  const rootDiscount = (find((props.soInvoiceData.customerSalesOrderCOATxList || []), { txType: LEDGER_TYPE.TYPE_DISCOUNT }) || {}).amountPercent || 0;
      //  const expenseAmount = (find((props.soInvoiceData.customerSalesOrderCOATxList || []), { txType: LEDGER_TYPE.TYPE_EXPENSE }) || {}).amount || 0;
      //   let selectedContact = (props.soInvoiceData.additionalInfoList || [])[0] || {};
      //  delete selectedContact.additionalInfoId;
      const { selectedSalesOrder } = this.state;
      const additionalInfoList = props.soDataBySOId.additionalInfoList[0] || {};

      let newDetailList = [];
      selectedSalesOrder.push({
        ...this.props.soDataBySOId,
        customerSalesOrderDetailsList: detailList,
      });
      selectedSalesOrder.forEach((e) => {
        newDetailList = newDetailList.concat(e.customerSalesOrderDetailsList);
      });

      this.setState({
        isSoConversion: true,
        customerSalesOrderDetailsList: newDetailList,
        selectedSalesOrder,
        selectedRelationshipTaxIdentifications:
          additionalInfoList.relationshipTaxIdentifications
            ? JSON.parse(additionalInfoList.relationshipTaxIdentifications).map(
                (obj) => {
                  return obj.relationshipTaxIdentificationId;
                }
              )
            : this.state.selectedRelationshipTaxIdentifications,
      });
      this.props.resetSOBySOID();
    }

    if (props.boRelationshipAddress && props.boRelationshipAddress.length) {
      const allRelationshipBillingAddress = [];
      props.boRelationshipAddress.forEach((e) => {
        if (e.locationType === "RelationshipBillingAddress") {
          allRelationshipBillingAddress.push(e);
        }
      });
      this.setState({
        allRelationshipBillingAddress,
      });
    } else {
      const allRelationshipBillingAddress =
        filter(props.companyInfo.boLocationList, {
          locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
        }) || [];
      this.setState({
        allRelationshipBillingAddress,
      });
    }
    if (props.customerContactList && props.customerContactList.length) {
      this.setState({
        boContactList: props.customerContactList,
      });
    }

  }

  componentDidMount() {
    // this.props.updateHeaderState({
    //     collapsed: true
    // })
    const companyInfo = this.props.companyInfo || {};
    const linkProps = (this.props.location && this.props.location.state) || {};
    
    const payload = {
      customerId: this.props.customerId,
      soMasterId: this.props.soMasterId,
      shipmentMasterId:
        this.props.shipmentMasterId || linkProps.shipmentMasterId,
      soMasterIdArr: this.props.soMasterIdArr || linkProps.soMasterIdArr,
      amendSoMasterId : this.props.amendSoMasterId,
      invoiceMasterId: this.props.invoiceMasterId || linkProps.invoiceMasterId,
      relationshipId: companyInfo.relationshipId,
      userId: (this.props.userInfo || {}).userId,
      isClone: this.props.clone || linkProps.clone,
      pageNumber: 1,
      pageSize: 100,
      isCompact:true
    };
    this.props.resetSoInvoiceData();
    this.props.resetShipmentForInvoice();
    if(!(this.props.validatedGstr1)){
    this.props.resetSalesInvoice();

    }
    this.props.resetAddresses();
    this.props.resetCustomerDetail();        
     !payload.amendSoMasterId && payload.soMasterId &&
      fetchDataIfNeeded(
        "fetchSoDataForInvoice",
        "soInvoiceData",
        this.props,
        payload,
        true
      );
      payload.invoiceMasterId &&  this.props.fetchSalesInvoiceDetail(payload)
    payload.invoiceMasterId &&
      fetchDataIfNeeded(
        "fetchSalesInvoiceDetail",
        "salesInvoiceDetail",
        this.props,
        payload,
        true
      );
    payload.customerId &&
      this.props.fetchAddresses({
        ...payload,
        locationType: CONSTANTS.SHIPPING_ADDRESS,
      });
    payload.customerId &&
      this.props.fetchAddresses({
        ...payload,
        locationType: CONSTANTS.BILLING_ADDRESS,
      });
    payload.customerId && this.props.fetchAllContacts(payload);
    payload.customerId &&
      fetchDataIfNeeded(
        "fetchCustomerDetailsByCustomerId",
        "customerDetail",
        this.props,
        payload,
        true
      );
    fetchDataIfNeeded("fetchPaymentTerms", "paymentTerms", this.props, payload);
   payload.shipmentMasterId && fetchDataIfNeeded(
      "fetchShipmentByShipmentId",
      "shipmentData",
      this.props,
      payload
    );
    // fetchPaginationDataIfNeeded(
    //   "fetchCustomers",
    //   "customers",
    //   this.props,
    //   { ...payload, isCompact: true },
    //   true
    // );
    this.props.resetPaginatedData();
    fetchDataIfNeeded("fetchPriceList", "priceList", this.props, payload);
    fetchDataIfNeeded("fetchStates", "states", this.props, 1);
    fetchDataIfNeeded("fetchProjects", "projectList", this.props, payload);
    fetchDataIfNeeded("fetchProducts", "products", this.props, payload);
    fetchDataIfNeeded("fetchTaxes", "taxes", this.props, payload);
    fetchDataIfNeeded("fetchUOM", "uomList", this.props, payload);
    fetchDataIfNeeded("fetchExpenseTypes", "expenseTypes", this.props, payload);
    fetchDataIfNeeded("fetchDocuments", "documentList", this.props, payload);
    if (!this.props.update) {
      fetchDataIfNeeded("getNextTxNumber", "nextTxNumber", this.props, payload);
    }
    this.props.getAllCoupons(payload);
    fetchDataIfNeeded("fetchCountries", "countries", this.props, payload);
    fetchDataIfNeeded('fetchPaymentModes', 'paymentModeList', this.props, payload);
    fetchDataIfNeeded(
      "fetchUserPreferences",
      "allPreferences",
      this.props,
      payload
    );
    if (payload.customerId) {
      this.props.fetchAllUnfulfilledSalesOrderByCustomerId(payload);
    }
    this.props.fetchRemarksTemplate({
      relationshipId: companyInfo.relationshipId,
      docName: "Sales Invoice",
    });
    fetchDataIfNeeded(
      "fetchRelationshipTaxIdentifications",
      "allRelationshipTaxIdentificatins",
      this.props,
      payload
    );
    this.props.getAllPaymentGatewayConfig(payload);
    fetchDataIfNeeded(
      "fetchAllLedgerAccounts",
      "allLedgerAccounts",
      this.props,
      payload
    );

    payload.amendSoMasterId &&
    fetchDataIfNeeded(
      "fetchAmendSoDataForInvoice",
      "amendSoInvoiceData",
      this.props,
      payload,
      true
    );
  }

  openSalesInvoiceDetails = (data) => {
    const invoicePayload = {
      customerId: data.customerId,
      invoiceMasterId: data.invoiceMasterId,
    };
    this.props.pushModalToStack({
      modalBody: (
        <SalesInvoiceDetails {...this.props} invoicePayload={invoicePayload} />
      ),
      width: "100%",
      hideFooter: true,
      wrapClassName: "modal-custom-detail",
    });
  };

  onSearch(text, updateAllOptions, updateFetching) {
    const payload = {
      relationshipId: (this.props || {}).companyInfo.relationshipId,
      pageNumber: 1,
      searchString: text,
      pageSize: 100,
      updateAllOptions,
      updateFetching,
    };
    this.props.fetchProducts(payload);
  }

  render() {
    return (
      <div className="new-invoice-modal">
        {this.state.settingSalesDrawerVisible && 
          <SettingSalesDrawer
            {...this.state}
            {...this.props}
            updateState={(data) => {
              this.setState(data);
            }}
            updateDrawerState={(data) => { this.setState(data) }}
            />
        }
        <LedgerDrawer
          {...this.state}
          updateState={(data) => {
            this.setState(data);
          }}
          updateDrawerState={(data) => { this.setState(data) }}
          />
        {this.state.transportDataDrawerVisible && (
          <TransportDataDrawer
            {...this.state}
            {...this.props}
            updateState={(data) => {
              this.setState(data);
            }}
            updateDrawerState={(data) => { this.setState(data) }}
          />
        )}
        {this.props.ledgerAccountsDrawerVisible || this.state.ledgerAccountsDrawerVisible && (
          <LedgerAccountsDrawer
            {...this.state}
            {...this.props}
            updateState={(data) => {
              this.setState(data);
            }}
            updateDrawerState={(data) => { this.setState(data) }}
          />
        )}
        <SalesInvoiceComponent
          onSearch={(text, updateAllOptions, updateFetching) => {
            this.onSearch(text, updateAllOptions, updateFetching);
          }}
          {...this.props}
          {...this.state}
          openSalesInvoiceDetails={this.openSalesInvoiceDetails}
          updateState={(data) => {
            this.setState(data);
          }}
          updateDrawerState={(data) => { this.setState(data) }}
          />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    paymentTerms: state.common.paymentTerms,
    customers: state.customer.customers,
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    priceList: state.common.priceList,
    states: state.common.states,
    products: state.salesInvoice.products,
    projectList: state.common.projectList,
    uomList: state.common.uomList,
    soInvoiceData: state.so.soInvoiceData,
    taxes: state.common.taxes,
    soDataBySOId: state.so.soDataBySOId,
    shipmentData: state.shipment.shipmentData,
    expenseTypes: state.common.expenseTypes,
    customerDetail: state.common.customerDetail,
    salesInvoiceDetail: state.salesInvoice.salesInvoiceDetail,
    documentList: state.common.documentList,
    nextTxNumber: state.salesInvoice.nextTxNumber,
    allPreferences: state.common.allPreferences,
    remarksData: state.settings.remarksData,
    permissions: state.auth.permissions,
    countries: state.common.countries,
    soUnfulfilledList: state.so.soUnfulfilledList,
    soShipmentList: state.so.shipmentListForInvoice,
    addresses: state.customer.addresses,
    boRelationshipAddress: state.common.boRelationshipAddress,
    allRelationshipTaxIdentificatins:
    state.common.allRelationshipTaxIdentificatins,
    allPaymentGatewayConfig: state.common.allPaymentGatewayConfig,
    customerContactList: state.customer.contacts,
    allLedgerAccounts: state.ledger.allLedgerAccounts,
    saveLoading: state.common.saveLoading,
    detailLoading: state.common.detailLoading,
    isTaxesLoaded: state.common.isTaxesLoaded,
    paymentModeList: state.common.paymentModeList,
    couponList: state.eStore.couponList,
    amendSoInvoiceData: state.so.amendSoInvoiceData,


  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchCustomerDetailsByCustomerId,
      createSalesInvoice,
      resetSoInvoiceData,
      fetchSoDataForInvoice,
      fetchPaymentTerms,
      fetchCustomers,
      fetchPriceList,
      fetchStates,
      fetchProjects,
      fetchProducts,
      fetchTaxes,
      fetchUOM,
      addUOM,
      deleteUOM,
      fetchExpenseTypes,
      addExpenseTypes,
      deleteExpenseTypes,
      showModal,
      hideModal,
      pushModalToStack,
      popModalFromStack,
      deleteCustomers,
      addPaymentTerm,
      deletePaymentTerm,
      deleteProject,
      deleteAddress,
      deleteTax,
      deletePriceList,
      fetchDocuments,
      addDocumentName,
      deleteDocumentName,
      getNextTxNumber,
      deleteContact,
      fetchUserPreferences,
      saveUserPrefrences,
      fetchRemarksTemplate,
      resetRemarksData,
      resetSOBySOID,
      fetchSalesOrderSOId,
      resetSalesInvoice,
      fetchCountries,
      fetchAllUnfulfilledSalesOrderByCustomerId,
      resetUnfulfilledSalesOrder,
      fetchShipmentForInvoice,
      fetchSalesInvoiceDetail,
      resetShipmentForInvoice,
      fetchRelationshipTaxIdentifications,
      deleteCustomerTaxIdentifications,
      deleteRelationshipTaxIdentifications,
      createSalesInvoiceDraft,
      resetShipmentData,
      fetchShipmentByShipmentId,
      fetchAddresses,
      getRelationshipAddress,
      resetCustomerDetail,
      resetAddresses,
      getAllPaymentGatewayConfig,
      fetchAllContacts,
      fetchAllLedgerAccounts,
      checkCustomerPoNumber,
      getItemByUPC,
      getItemByPruductId,
      resetPaginatedData,
      uploadGSTR1FilingDetails,
      deleteValidatedInvoice,
      deleteDocument,
      fetchPaymentModes,
      getAllCoupons,
      applieCoupon,checkManualInvoiceNumber,
      fetchAmendSoDataForInvoice,
      resetAmendSoDataForInvoice
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SalesInvoice);
