import axios from "axios";
import config from "../../../../config/environmentConfig";
import { showToasterMessage } from "../../../../utils";
import { COMMON_ACTIONS, MEMBERSHIP_ACTION_LIST ,COMMUNICATION_ACTION_LIST} from "../../../../static/constants";
const { lang } = require("../../../../translations/" +
  (sessionStorage.getItem("language") || "en") +
  ".js");


export const createCampaign = (payload, props) => {
  return (dispatch) => {
  
    return axios
      .post(
        `${config.COMMUNICATION_BASE_URL}${config.API_VERSION}/${config.rootContextCommunication}/email/send`,payload)
      .then((res) => {
        showToasterMessage({
            description: (res || {}).message || 'Email sent successfully',
            messageType: 'success'
          })
        
        const payload2 = {
          relationshipId: payload.relationshipId, 
          pageSize: payload.pageSize || 25, 
          pageNumber: payload.pageNumber || 1,
        }
        props.fetchCampaign(payload2);
        props.getCampaignCount(payload2);
        props.fetchgAggregatedReportByRid(payload2);
        props.popModalFromStack();
      })
      .catch((err) => {
        payload.updateState({  isReplicate: true, marketingCampaignId : {} });
        showToasterMessage({
          description:
            lang[((err.response || {}).data || {}).message] ||
            "Some error occurred",
        });
      });
  };
};

export const getDGDetails = (payload) => {
  return (dispatch) => {
      return axios
          .get(
              `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextTwilio
              }/dg/getDGById?relationshipId=${payload.relationshipId}&distributionGroupId=${payload.distributionGroupId}`
          )
          .then((res) => {
              dispatch({
                  type: MEMBERSHIP_ACTION_LIST.DG_DETAILS,
                  data: res.data,
              });
          })
          .catch((err) => {
              if (!err.__isRetryRequest) {
                  showToasterMessage({
                      messageType: "error",
                      description:
                          lang[((err.response || {}).data || {}).message] ||
                          "Some error occurred",
                  });
              }
          });
  };
};