import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS, CUSTOMER_PAYMENT_ACTION_LIST, SMS_ACTION_LIST } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');



export const getSchoolAttendanceByStudentId = (payload) => {
    return (dispatch) => {
      return axios
        .get(
          `${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/attendance/getSchoolAttendanceByStudentId?relationshipId=${payload.relationshipId}&studentId=${payload.studentId}&startDate=${payload.startDate}&endDate=${payload.endDate}`
        )
        .then((res) => {
          dispatch({
            type: SMS_ACTION_LIST.SCHOOL_ATTENDENCE_BY_STUDENT_ID,
            data: res.data,
          });
        })
        .catch((err) => {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        });
    };
  };

  export const getSchoolCourseById = (payload) => {
    return (dispatch) => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .get(`${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/course/getSchoolCourseById?relationshipId=${payload.relationshipId}&courseId=${payload.courseId}`)
        .then((res) => {
          const data = res.data
          dispatch({
            type: SMS_ACTION_LIST.SCHOOL_COURSE,
            data,
          });
        })
        .catch((err) => {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        });
    };
  };

  export const getContactEducation = (payload) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/education/getByContactId?relationshipId=${payload.relationshipId}&customerId=${payload.customerId}&contactId=${payload.contactId}`)
        .then(res => {
          const { data } = res
          dispatch({ type: COMMON_ACTIONS.CONTACT_EDUCATION_LIST, data });
        })
        .catch((err) => {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        })
    }
  }

  export const saveContactEducation = (payload, props) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/education/saveEducation`, payload)
        .then(res => {
          showToasterMessage({ messageType: 'success', description: 'Saved successfully' });
          props.updateModalState({
            educationDrawerVisible: false
          })
          const payload = {
            relationshipId: props.companyInfo.relationshipId,
            contactId: Number(res.data?.contactId),
            customerId: res.data?.customerId
          }
          props.getContactEducation(payload);
        })
        .catch((err) => {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        })
    }
  }

  export const deleteContactEducation = (payload, props) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/education/delete`, payload)
        .then(res => {
          showToasterMessage({ messageType: 'success', description: 'Deleted successfully' });
          const payload2 = {
            relationshipId: props.companyInfo.relationshipId,
            contactId: Number(payload?.contactId),
            customerId: payload?.customerId
          }
          props.getContactEducation(payload2);
        })
        .catch((err) => {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        })
    }
  }
  

  export const fetchPaymentsByCustomer = (payload) => {
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios
          .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customerPayment/getPaymentsByCustomerId?relationshipId=${payload.relationshipId}&customerId=${payload.customerId}`)
          .then(res => {
            const { data } = res
            dispatch({ type: CUSTOMER_PAYMENT_ACTION_LIST.ALL_CUSTOMER_PAYMENTS, data });
          })
          .catch((err) => {
            showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          })
      }
  }

  export const updateStudentProfilePic = (payload) => {
    return dispatch => {
      let formData = new FormData();
      formData.append("file", payload.file);;
      formData.append("payload", JSON.stringify(payload.studentData, true));
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/student/updateProfilePhoto?relationshipId=${payload.relationshipId}`, formData, 
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          },
        )
        .then(res => {
          if(!res?.data){
            throw new Error();
          }
          showToasterMessage({ description: (res || {}).message || 'Profile picture updates successfully.', messageType: 'success' });
        })
        .catch(err => {
          showToasterMessage({ description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        })
    }
  }

