import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS, INTEGRATION } from '../../../static/constants'
import { showToasterMessage } from '../../../utils';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');
export const fetchShipStationConfiguration = (payload) =>{
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/carrier/getAllCarriers?relationshipId=${payload.relationshipId}`)
        .then(res => {
          dispatch({
            type: INTEGRATION.LINKED_SHIP_STATIONS,
            data: res.data
          });
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
        .catch((err = {}) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          showToasterMessage({
            description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
          })
        })
    }
  }
  
  
  export const disconnectConfiguration = (payload, props) =>{
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/carrier/delete`, payload)
        .then(res => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          showToasterMessage({messageType:"success", description: ((res || {}).data || {}).message || 'Disconnected Successfully'});
          fetchShipStationConfiguration(payload)(dispatch);
        })
        .catch((err = {}) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          showToasterMessage({
            description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
          })
        })
    }
  }
  
  
  export const saveShipStationConfiguration = (payload, props) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
      return axios
        .post(`${config.LOGISTICS_BASE_URL}${config.API_VERSION}/${config.rootContextLogistics}/carrier/save`, payload)
        .then(res => {
          showToasterMessage({
            messageType:"success",
            description: 'Now you can use this for shipping'
          })
          fetchShipStationConfiguration(res.data)(dispatch);
        })
        .catch((err = {}) => {
          showToasterMessage({
            description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
          })
        })
        .finally(()=>{
          dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
        })
    }
  }