import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS, CUSTOMER_ACTION_LIST, SUPPLIER_ACTION_LIST } from '../../../../../static/constants';
import { getPaths, showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const createContact = (payload, formData) => {
  let actionType = payload.customerId ? CUSTOMER_ACTION_LIST.ADD_CUSTOMER_BO_CONTACT : SUPPLIER_ACTION_LIST.ADD_SUPPLIER_BO_CONTACT;
  const paths = getPaths(payload);

  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${paths.API_BASE_URL}${config.API_VERSION}/${paths.rootContext}/boContact/createContact`, payload)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!formData.isFundraiser) {
          dispatch({ type: actionType, data: res.data, formData });
        }

        if (formData.funcToCall) {
          formData.funcToCall(formData.funcToCallPayload)
        }
        if (formData.callback) {
          formData.callback(res.data)
        }
        showToasterMessage({ messageType: 'success', description: 'Contact saved successfully' });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          //  showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = actionType;
        }
      })
  }
}


export const saveOrUpdateCustomer = (requestObj, props, amount) => {

  let formData = new FormData();
  formData.append('customer', JSON.stringify(requestObj, true));
  formData.append('bucketName' ,props.companyInfo?.bucketName)

  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customers/saveOrUpdateCustomer`,
        formData,
        { headers: { 'content-type': 'multipart/form-data' } }
      )
      .then(res => {
        if (requestObj.orgRegistrationList[0] && requestObj.orgRegistrationList[0].amount) {
          
          //window.location = (res.data || {}).redirectUrl+`&callback_uri=${window.location.origin}/app/register&formId=${(props.formSetting) || {}.registrationFormSettingId}`;
        }

        // if(props && props.updateState){
        //   props.updateState({
        //     isFormSaved: true
        //   })
        // }
        //showToasterMessage({ messageType: 'success', description: lang[res.data.message || 'common.saved.success'] })
      })
      .catch(err => {
        showToasterMessage({ description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}