import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS, SUPPLIER_ACTION_LIST } from '../../../../../static/constants';
import { showToasterMessage, getFinancialYear, formatDateForBackend } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchOpenOrders = (payload, props) => {
  let FY = getFinancialYear(props?.companyInfo);
  return dispatch => {
    let url = ""
    if (payload.isForSupplier) {
      url = `purchaseOrder/getOpenOrdersDataBySupplierId?supplierId=${payload.supplierId}&relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1)-1}&startDate=${FY.fyStartDate}&endDate=${formatDateForBackend(new Date()) || FY.fyEndDate}`;
    } else {
      url = `purchaseOrder/getOpenOrdersDataByRid?&relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1)-1}&startDate=${FY.fyStartDate}&endDate=${formatDateForBackend(new Date()) ||FY.fyEndDate}`;
    }
    dispatch({ type: COMMON_ACTIONS.SHOW_MODAL_LOADER });
    return axios
      .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/${url}`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: SUPPLIER_ACTION_LIST.SUPPLIER_OPEN_ORDER, data });
        dispatch({ type: COMMON_ACTIONS.HIDE_MODAL_LOADER });
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_MODAL_LOADER });
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })

      })
  }
}



export const fetchOpenOrdersCount = (payload, props) => {
  let FY = getFinancialYear(props?.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_HEADER_LOADER });
    let url = ""
    if (payload.isForSupplier) {
      url = `purchaseOrder/countOpenOrdersBySupplierId?supplierId=${payload.supplierId}&relationshipId=${payload.relationshipId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`;
    } else {
      url = `purchaseOrder/getOpenOrdersCountByRid?&relationshipId=${payload.relationshipId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`;
    }
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/${url}`)
      .then(res => {
        dispatch({ type: SUPPLIER_ACTION_LIST.SUPPLIER_OPEN_ORDER_COUNT_BY_SUPPLIER_ID, data: res.data });
        dispatch({ type: COMMON_ACTIONS.HIDE_HEADER_LOADER });
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_HEADER_LOADER });
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}

export const resetOpenOrderPaginatedData = () => {
  return dispatch => {
    dispatch({ type: SUPPLIER_ACTION_LIST.SUPPLIER_OPEN_ORDER_PAGE_RESET });
  }
}