import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS, CRM_ACTION_LIST } from '../../../static/constants';
import { showToasterMessage } from '../../../utils';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const deleteCrmLeadStage = (payload, props) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.CRM_BASE_URL}${config.API_VERSION}/${config.rootContextCRM}/leadStatus/deleteLeadStatus`, payload)
        .then(res => {
          showToasterMessage({
            description: (res || {}).message || 'Deleted successfully.',
            messageType: 'success'
          })
          const payload = {
            relationshipId: props.companyInfo.relationshipId,
            pageNumber: 0,
            pageSize: 25,
          }
          props.getAllLeadStage(payload);
        })
        .catch((err = {}) => {
            showToasterMessage({
                description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
            })
        })
    }
  }

export const getAllLeadStage = (payload) => {
  return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
      return axios 
      .get(`${config.CRM_BASE_URL}${config.API_VERSION}/${config.rootContextCRM}/leadStatus/getAllLeadStatus?relationshipId=${payload.relationshipId}`)
      .then(res => {
          const data = res.data
          dispatch({ type: CRM_ACTION_LIST.CRM_LEAD_STATUS_LIST, data });
      })
      .catch((err) => {
          if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          } else {
          err.actionToCall = CRM_ACTION_LIST.CRM_LEAD_STATUS_LIST;
          // err.data = err.data;
          }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      })
  }
}