import axios from 'axios';
import config from '../../../../../../config/environmentConfig';
import { COMMON_ACTIONS, SMS_ACTION_LIST } from '../../../../../../static/constants';
import { showToasterMessage } from '../../../../../../utils';
const { lang } = require('../../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const getSubcourse = (payload) => {
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios.get(`${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/subcourse/getSubcourse?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1) - 1}`
        )
            .then(response => {
                dispatch({ type: SMS_ACTION_LIST.SUBCOURSE_LIST, data: response.data });
                //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            })
            .catch(err => {
                //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            })
    }
}

export const deleteSubcourse = (payload) => {
    const requestObj = {
        subcourseId: payload.id,
        relationshipId: payload.relationshipId,
    }
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios
            .post(`${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/subcourse/delete`, requestObj)
            .then(res => {
                dispatch({ type: SMS_ACTION_LIST.SUBCOURSE_LIST_DELETION, data: { id: payload.id } })
                //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
                showToasterMessage({
                    description: lang[(res || {}).message] || 'Deleted successfully.',
                    messageType: 'success'
                })
            })
            .catch((err = {}) => {
                //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
                if (err.__isRetryRequest) {
                    err.actionToCall = SMS_ACTION_LIST.SUBCOURSE_LIST_DELETION;
                } else {
                    showToasterMessage({
                        description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
                    })
                }
            })
    }
}

export const addSubcourse = (payload) => {
    const requestObj = {
        relationshipId: payload.relationshipId,
        subcourseName: payload.text,
        //dateCreated: getCurrentDateForBackend(new Date()),
    }
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios
            .post(`${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/subcourse/saveOrUpdate`, requestObj)
            .then(res => {
                dispatch({ type: SMS_ACTION_LIST.SUBCOURSE_LIST_ADDITION, data: res.data });
                //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
                showToasterMessage({
                    description: lang[(res || {}).message] || 'Created successfully.',
                    messageType: 'success'
                })
            })
            .catch((err = {}) => {
                //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
                if (err.__isRetryRequest) {
                    err.actionToCall = SMS_ACTION_LIST.SUBCOURSE_LIST_ADDITION;
                } else {
                    showToasterMessage({
                        description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
                    })
                }
            })
    }
}