import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS, HIERARCHY_ACTION_LIST } from '../../../static/constants';
import { showToasterMessage } from '../../../utils';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchCompanyHierarchy = (payload) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
      return axios.get(`${config.HRMS_BASE_URL}${config.API_VERSION}/${config.rootContextHRMS}/companyHierarchy/getAllCompanyHierarchies?relationshipId=${payload.relationshipId}`)
        .then(response => {
            dispatch({ type: HIERARCHY_ACTION_LIST.HIERARCHY_LIST, data: response.data });
        })
        .catch(err => {
        })
        .finally(()=>{
          dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
        })
    }
}

export const deleteHierarchy = (payload) => {
    const requestObj = {
        hierarchyId: payload.id,
        relationshipId: payload.relationshipId,
    }
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
      return axios
        .post(`${config.HRMS_BASE_URL}${config.API_VERSION}/${config.rootContextHRMS}/companyHierarchy/deleteCompanyHierarchy`, requestObj)
        .then(res => {
          dispatch({ type: HIERARCHY_ACTION_LIST.HIERARCHY_LIST_DELETION, data: { id: payload.id } })
          showToasterMessage({
            description: (res || {}).message || 'Deleted successfully.',
            messageType: 'success'
          })
        })
        .catch((err = {}) => {
          showToasterMessage({
            description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
          })
        })
        .finally(()=>{
          dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
        })
    }
  }