import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMMON_ACTIONS, SQ_ACTION_LIST, PURCHASE_ORDER_ACTION_LIST, INQUIRY_ACTION_LIST } from '../../../../static/constants';
import { getCurrentDateForBackend, formatDateForBackend, showToasterMessage, getFinancialYear } from '../../../../utils'
import { LEDGER_TYPE, ACCOUNTING_ENTRY_TYPE, TX_STATUS } from "../../../../static/constants";
import { resetPaginatedData, getSalesQuoteCount, resetDraftPaginatedData, getSalesQuoteDraftCount } from '../Listing/action';
import * as filter from 'lodash.filter';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const createSalesQuote = (payloadObject) => {
  const props = payloadObject.props;
  const salesQuotation = _getQuoteData(payloadObject);
  let formData = new FormData();
  if (props.fileList && props.fileList.length) {
    props.fileList.forEach((file)=>{
      formData.append('files', file, file.name);
    })
  }
  formData.append('salesQuotation', JSON.stringify(salesQuotation, true));
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
    return axios
      .post(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesQuotation/saveOrUpdateSalesQuotation`, formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then(res => {
        
        resetPaginatedData()(dispatch);
        getSalesQuoteCount({ relationshipId: props.companyInfo.relationshipId })(dispatch);
        resetDraftPaginatedData()(dispatch);
        getSalesQuoteDraftCount({ relationshipId: props.companyInfo.relationshipId })(dispatch);
        showToasterMessage({ messageType: 'success', description: 'Sales quote created successfully' });
        props.popModalFromStack();
        props.fetchSalesQuotes(props)
        props.openSQDetails(res.data);
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        props.fetchAllRFQ({
          relationshipId: (props.companyInfo || {}).relationshipId
        })
        props.history.push('/admin/sq-list');
        if (props.isInquiryConversion) {
          dispatch({ type: INQUIRY_ACTION_LIST.ENQUIRY_LIST_PAGE_RESET });
          props.history.push('/admin/sales-inquiry-list')
        }
      })
      .catch((err) => {
        
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
      })
  }
}

const _getQuoteData = (payload) => {
  const props = payload.props;
  const state = payload.state;
  const gridObject = payload.gridObject;
  const isPlaceOfSupplySameAsRelationship = payload.isPlaceOfSupplySameAsRelationship;
  const companyInfo = props.companyInfo || {};
  const userInfo = props.userInfo || {};
  const financialYear = getFinancialYear(companyInfo);
  const oldPayload = props.update ? props.oldPayload || {} : {};
  let quoteMaster = {
    ...oldPayload,
    version: props.update ? props.version : 0,
    quoatationDraftId: props.quoatationDraftId || 0,
    placeOfSupply: props.placeOfSupply,
    projectName: props.projectName,
    projectNumber: props.projectNumber,
    projectMasterId: props.projectMasterId,
    customerInquiryNumber: props.customerInquiryNumber || '',
    salesQuotationMasterId: props.isUpdate ? props.salesQuotationMasterId : 0,
    isInquiryConversion: (props.isInquiryConversion && !props.isClone) ? 1: 0,
    inquiryMasterId: props.inquiryMasterId,
    customerId: props.customer && props.customer.customerId,
    fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
    fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
    salesQuoteTotalAmount: Number(state.total),
    rfqChildMasterId: props.rfqChildMasterId,
    subTotal: Number(state.subtotalWithoutDiscount),
    customerName: props.customer && props.customer.companyName,
    docDetailList: props.sqEditDetail?.docDetailList,
    relationshipName: companyInfo && companyInfo.storeName,
    customerGstNumber: props.customer && props.customer.gstNumber,
    customerPanNumber: props.customer && props.customer.panNumber,
    status: TX_STATUS.OPEN,
    orderPriority: props.priority,
    salesQuoteDate: props.quoteDate && formatDateForBackend(props.quoteDate),
    salesQuoteExpiryDate: props.quoteExpiryDate && formatDateForBackend(props.quoteExpiryDate),
    relationshipId: companyInfo.relationshipId,
    rfqNumber: props.rfqNumber,
    customerRFQNumber: props.customerRfq || '',
    rfqMasterId: props.rfqMasterId,
    isQuoteConverted: (props.isRFQConversion && !props.isClone) ? 1 : 0,
    isRFQCconversion: (props.isRFQConversion && !props.isClone) ? 1 : 0,
    rfqDate: props.rfqDate && formatDateForBackend(props.rfqDate),
    rfqExpirationDate: props.rfqExpirationDate && formatDateForBackend(props.rfqExpirationDate),
    customerRfq: props.customerRfq,
    referenceNumber: props.customerRef,
    remarksCustomer: props.customerRemarks,
    remarksInternal: props.internalRemarks,
    //dateCreated: getCurrentDateForBackend(new Date()),
    createdByUserId: userInfo && userInfo.userId,
    createdByEmpId: userInfo && userInfo.relationshipEmployeeId, 
    footer: props.footer,
    totalDiscountGiven: Number(state.totalDiscount || 0),
    customerSalesQuotationCOATxList: [],
    customerSalesQuotationDetailsList: [],
    boLocationSQList: [],
    documentName: props.docName,
    salesPersonId: props.salesPersonId,
    salesPersonFullName: props.salesPersonName,
    currencyCode: companyInfo.currencyCode,
    inctermId: props.inctermId, 
    incotermName: props.incotermName,
    deliveryMethod: props.deliveryMethod 
  };


  if (props.relationshipBillingAddress && (props.relationshipBillingAddress.locationSQId || props.relationshipBillingAddress.locationId)) {
    let relationshipBillingAddress = props.relationshipBillingAddress;
    relationshipBillingAddress.salesQuotationMasterId = props.isUpdate ? props.salesQuotationMasterId :  0;
    relationshipBillingAddress.locationSQId = props.update ? relationshipBillingAddress.locationSQId : 0;
    if (props.update) {
      //relationshipBillingAddress.dateUpdated = getCurrentDateForBackend(new Date());
    } else {
     // relationshipBillingAddress.dateCreated = getCurrentDateForBackend(new Date());
     // relationshipBillingAddress.dateUpdated = getCurrentDateForBackend(new Date());
    } 
    quoteMaster.boLocationSQList.push(relationshipBillingAddress);
  }

  if (props.customerBillingAddress && (props.customerBillingAddress.locationSQId || props.customerBillingAddress.locationId)) {
    let customerBillingAddress = props.customerBillingAddress;
    customerBillingAddress.salesQuotationMasterId = props.isUpdate ? props.salesQuotationMasterId :  0;
    customerBillingAddress.locationSQId = props.update ? customerBillingAddress.locationSQId : 0;
    if (props.update) {
      //customerBillingAddress.dateUpdated = getCurrentDateForBackend(new Date());
    } else {
     // customerBillingAddress.dateCreated = getCurrentDateForBackend(new Date());
     // customerBillingAddress.dateUpdated = getCurrentDateForBackend(new Date());
    } 
    quoteMaster.boLocationSQList.push(customerBillingAddress);
  }

  if (props.customerShippingAddress && (props.customerShippingAddress.locationSQId || props.customerShippingAddress.locationId)) {
    let customerShippingAddress = props.customerShippingAddress;
    customerShippingAddress.salesQuotationMasterId = props.isUpdate ? props.salesQuotationMasterId :  0;
    customerShippingAddress.locationSQId = props.update ? customerShippingAddress.locationSQId : 0;
    if (props.update) {
     // customerShippingAddress.dateUpdated = getCurrentDateForBackend(new Date());
    } else {
     // customerShippingAddress.dateCreated = getCurrentDateForBackend(new Date());
     // customerShippingAddress.dateUpdated = getCurrentDateForBackend(new Date());
    } 
    quoteMaster.boLocationSQList.push(customerShippingAddress);
  }
  gridObject.forEachNode((rowNode, index) => {
    const { data } = rowNode;
  //props.salesQuoteDetails.forEach((data, index) => {
    if(!data.itemId && !data.description && !data.qty && !data.rate){
      return
    }
    const itemId = data.selectedProductValue && data.selectedProductValue[0];
    //if (!itemId) return;
    let variantId = data.selectedProductValue && data.selectedProductValue.length === 2 ? data.selectedProductValue[1] : 0;
    if(data.productObj && !data.productObj.hasVariant && !variantId){
      variantId = data.productObj.inventoryItemVariantList ? data.productObj.inventoryItemVariantList[0].variantId: data.inventoryItemVariantId;
    }
    
    let product = {};
    let variant = {};
    let itemLevelCoaTxList = [];
    if (itemId) {
      product = data.productObj || {};
    }
    if (variantId) {
      variant = data.variant || {};
    }

    let itemLevelAmount = ((data || {}).rate || 0) * ((data || {}).qty || 0);

    if (data.discount) {
      const discountOnItem = itemLevelAmount ? ((data.discount * 0.01) * itemLevelAmount) : 0;
      itemLevelAmount = itemLevelAmount - discountOnItem;
      itemLevelCoaTxList.push({
        relationshipId: companyInfo.relationshipId,
        relationshipName: companyInfo.storeName,
        fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
        fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
        customerId: props.customer && props.customer.customerId,
        //dateCreated: getCurrentDateForBackend(new Date()),
        createdBy: userInfo.userId,
        amount: discountOnItem,
        amountPercent: data.discount,
        txDate: formatDateForBackend(new Date()),
        txType: LEDGER_TYPE.TYPE_DISCOUNT,
        accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
        customerName: props.customer && props.customer.companyName
      });

      itemLevelCoaTxList.push({
        relationshipId: companyInfo.relationshipId,
        relationshipName: companyInfo.storeName,
        fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
        fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
        customerId: props.customer && props.customer.customerId,
        //dateCreated: getCurrentDateForBackend(new Date()),
        createdBy: userInfo.userId,
        amount: discountOnItem,
        amountPercent: data.discount,
        txDate: formatDateForBackend(new Date()),
        txType: LEDGER_TYPE.TYPE_DISCOUNT,
        accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
        customerName: props.customer && props.customer.companyName
      })
    }

    if (data.tax) {
      if (companyInfo.countryName !== 'India') {
        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
          customerId: props.customer && props.customer.customerId,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount ? ((data.tax * 0.01) * itemLevelAmount) : 0).toFixed(2),
          amountPercent: Number(data.tax || 0),
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX,
          taxName: data.taxName,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
          ledgerAccountId: props.salesOutputTaxLedgerId,
          customerName: props.customer && props.customer.companyName
        });

        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
          customerId: props.customer && props.customer.customerId,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount ? ((data.tax * 0.01) * itemLevelAmount) : 0).toFixed(2),
          amountPercent: Number(data.tax || 0),
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX,
          taxName: data.taxName,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
          ledgerAccountId: props.taxDebtorLedgerId,
          customerName: props.customer && props.customer.companyName
        });
      }
      else if (isPlaceOfSupplySameAsRelationship) {
        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
          customerId: props.customer && props.customer.customerId,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount ? (((data.tax) * 0.01) * itemLevelAmount)/2 : 0).toFixed(2),
          amountPercent: (data.tax)/2,
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX,
          taxName: LEDGER_TYPE.TYPE_TAX_CGST,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
          customerName: props.customer && props.customer.companyName
        });

        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
          customerId: props.customer && props.customer.customerId,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount ? (((data.tax) * 0.01) * itemLevelAmount)/2 : 0).toFixed(2),
          amountPercent: (data.tax)/2,
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX,
          taxName: LEDGER_TYPE.TYPE_TAX_CGST,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
          customerName: props.customer && props.customer.companyName
        });


        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
          customerId: props.customer && props.customer.customerId,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount ? (((data.tax) * 0.01) * itemLevelAmount)/2 : 0).toFixed(2),
          amountPercent: (data.tax)/2,
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX,
          taxName: LEDGER_TYPE.TYPE_TAX_SGST,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
          customerName: props.customer && props.customer.companyName
        });

        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
          customerId: props.customer && props.customer.customerId,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount ? (((data.tax) * 0.01) * itemLevelAmount)/2 : 0).toFixed(2),
          amountPercent: (data.tax)/2,
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX,
          taxName: LEDGER_TYPE.TYPE_TAX_SGST,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
          customerName: props.customer && props.customer.companyName
        });

      } else {
        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
          customerId: props.customer && props.customer.customerId,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount ? ((data.tax * 0.01) * itemLevelAmount) : 0).toFixed(2),
          amountPercent: data.tax,
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX,
          taxName: LEDGER_TYPE.TYPE_TAX_IGST,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
          customerName: props.customer && props.customer.companyName
        });

        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
          customerId: props.customer && props.customer.customerId,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount ? ((data.tax * 0.01) * itemLevelAmount) : 0).toFixed(2),
          amountPercent: data.tax,
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX,
          taxName: LEDGER_TYPE.TYPE_TAX_IGST,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
          customerName: props.customer && props.customer.companyName
        });
      }
    }

    quoteMaster.customerSalesQuotationDetailsList.push({
      parentDetailsId: data.parentDetailsId,
      version: props.update ? data.version : 0,
      salesQuotationDetailsId: data.salesQuotationDetailsId || 0,
      salesQuotationMasterId: props.isUpdate ? data.salesQuotationMasterId  : 0,
      relationshipId: companyInfo && companyInfo.relationshipId,
      customerId: props.customer && props.customer.customerId,
      quantity: Number(data.qty),
      qtyBeforeUpdate: Number(data.qtyBeforeUpdate || 0),
      sNo: index + 1,
      sku: product.sku,
      uomName: data.uom,
      basePrice: Number(data.baseRate || 0),
      specialDiscount: Number(data.specialDiscount || 0),
      txDate: formatDateForBackend(props.quoteDate),
      discountPercentage: Number(data.discount || 0),
      itemName: product.itemName,
      itemId: product.itemId,
      anItemSalePrice: Number(data.rate || 0),
      description: data.description,
      rowHeight: data.scrollHeight,
      hsnCode: data.hsnCode,
      inventoryItemVariantId: variantId,
      customerSalesQuotationCOATxList: itemLevelCoaTxList,
      warehouse: data.comment,
      stockNumber: data.stockNumber,
      partNumber: data.partNumber,
      origin: data.origin,
      hsCode: data.hsCode,
      materialNumber: data.materialNumber,
      attributeId1: variant.attributeId1,
      attributeId2: variant.attributeId2,
      attributeId3: variant.attributeId3,
      attributeName1: variant.attributeName1,
      attributeName2: variant.attributeName2,
      attributeName3: variant.attributeName3,
      attributeValue1: variant.attributeValue1,
      attributeValue2: variant.attributeValue2,
      attributeValue3: variant.attributeValue3,
      inquiryMasterId: props.inquiryMasterId || data.inquiryMasterId,
    });
  });

  let additionalInfo = {};

  if (props.selectedContact && props.selectedContact.firstName) {
    additionalInfo = { ...props.selectedContact }
   // additionalInfo.relationshipVatNumber = companyInfo.vatNumber;
   // additionalInfo.relationshipGstNumber = companyInfo.gstNumber;
    //additionalInfo.relationshipPanNumber = companyInfo.panNumber;
    additionalInfo.relationshipFaxNumber = companyInfo.faxNumber;
    additionalInfo.relationshipPhoneNumber = companyInfo.phoneNumber;
    additionalInfo.relationshipWebsite = companyInfo.website;
  }
  if (props.customer) {
    additionalInfo.customerId = props.customer && props.customer.customerId;
    additionalInfo.customerPhone = props.customer && props.customer.customerPhone;
    additionalInfo.customerEmail = props.customer && props.customer.customerEmail;
   // additionalInfo.gstNumber = props.customer.gstNumber;
   // additionalInfo.panNumber = props.customer.panNumber;
  }
  if (Object.keys(additionalInfo).length) {
    additionalInfo.relationshipId = companyInfo && companyInfo.relationshipId;
    additionalInfo.additionalInfoId = (props.isRFQConversion || props.isInquiryConversion) ? 0 : additionalInfo.additionalInfoId;
  }

  if (props.selectedCustomerTaxIdentifications && props.selectedCustomerTaxIdentifications.length) {
    let selectedCustomerTaxIdentifications = filter(props.customer.customerTaxIdentificationsList, (taxObj) => {
      return props.selectedCustomerTaxIdentifications.indexOf(taxObj.customerTaxIdentificationId) > -1;
    });
    additionalInfo.taxIdentifications = JSON.stringify(selectedCustomerTaxIdentifications);
  }

  if (props.selectedRelationshipTaxIdentifications && props.selectedRelationshipTaxIdentifications.length) {
    let selectedRelTaxIdentifications = filter(props.allRelationshipTaxIdentificatins, (taxObj) => {
      return props.selectedRelationshipTaxIdentifications.indexOf(taxObj.relationshipTaxIdentificationId) > -1;
    });
    additionalInfo.relationshipTaxIdentifications = JSON.stringify(selectedRelTaxIdentifications);
  }

  if (!props.update) delete additionalInfo.additionalInfoId;
  quoteMaster.additionalInfoList = [additionalInfo];
  quoteMaster.customerSalesOrderMasterList = props.isUpdate ?  quoteMaster.customerSalesOrderMasterList : [];
  return quoteMaster;
}


export const fetchRfqData = (payload) => {
  return dispatch => {
    let url = `${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/rfqRest/getRFQsByRFQId?rfqMasterId=${payload.rfqMasterId}&relationshipId=${payload.relationshipId}`;
    if (payload.rfqChildMasterId) {
      url = `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/rfqChildRest/getChildRFQData?rfqMasterId=${payload.rfqMasterId}&relationshipId=${payload.relationshipId}&rfqChildMasterId=${payload.rfqChildMasterId}`
    }
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(url)
      .then(res => {
        dispatch({ type: PURCHASE_ORDER_ACTION_LIST.RFQ_PO_DATA, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = PURCHASE_ORDER_ACTION_LIST.RFQ_PO_DATA;
        }
      })
  }
}



export const fetchSQDetail = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_DETAIL_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesQuotation/getSalesQuotationDataBySalesQuotationMasterId?salesQuotationMasterId=${payload.salesQuotationMasterId}&relationshipId=${payload.relationshipId}&customerId=${payload.customerId}`)
      .then(res => {
        const { data } = res
        dispatch({ type: SQ_ACTION_LIST.SQ_DETAIL, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          //showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = SQ_ACTION_LIST.SQ_DETAIL;
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_DETAIL_LOADER,
        });
      })
  }
}

export const resetSQDetails = () => {
  return dispatch => {
    dispatch({ type: SQ_ACTION_LIST.RESET_SQ_DATA });
  }
}


export const resetRfqPoData = () => {
  return dispatch => {
    dispatch({ type: PURCHASE_ORDER_ACTION_LIST.RESET_RFQ_PO_DATA });
  }
}

export const getNextTxNumber = (payload) => {
  let date = payload.date ? getCurrentDateForBackend(payload.date) : getCurrentDateForBackend(new Date());
  return dispatch => {
    dispatch({ type: SQ_ACTION_LIST.NEXT_SQ_NUMBER_RESET });
    dispatch({ type: COMMON_ACTIONS.SHOW_DETAIL_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesQuotation/getNextQuotationNumber?date=${date}&relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: SQ_ACTION_LIST.NEXT_SQ_NUMBER, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = SQ_ACTION_LIST.NEXT_SQ_NUMBER;
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_DETAIL_LOADER,
        });
      })
  }
}


export const fetchInquiryDetail = (payload) => {
  return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      let url = `${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/inquiry/getInquiryDataForQuote?inquiryMasterId=${payload.inquiryMasterId}&relationshipId=${payload.relationshipId}&customerId=${payload.customerId}`;
      return axios
        .get(url)
        .then(res => {
          const { data } = res
          dispatch({ type: INQUIRY_ACTION_LIST.ENQUIRY_DETAIL, data });
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
        .catch((err) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if(!err.__isRetryRequest){
            showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          }else{
            err.actionToCall = INQUIRY_ACTION_LIST.ENQUIRY_DETAIL;
          }
        })
    }
}

export const resetEnquiryDetail = () => {
  return dispatch => {
    dispatch({ type: INQUIRY_ACTION_LIST.RESET_ENQUIRY_DETAIL });
  }
}


export const createSalesQuoteDraft = (payloadObject) => {
  const props = payloadObject.props;
  return dispatch => {
    const salesQuotationeData = _getQuoteData(payloadObject);
    const data = {
      quoatationDraftId: props.quoatationDraftId || 0,
      salesQuotationeData: JSON.stringify(salesQuotationeData),
      relationshipId: salesQuotationeData.relationshipId,
      customerId: salesQuotationeData.customerId
    }

    dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
    return axios
      .post(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesQuotation/saveOrUpdateDraft`, data)
      .then(res => {
        showToasterMessage({ messageType: 'success', description: 'Sales Quote saved in draft' })
       
        props.history.push({
          pathname: '/admin/sq-list',
          state: { activeTab: 'Draft' }
        })
        props.popModalFromStack()
        props.fetchSalesQuoteDrafts(props)

      })
      .catch((err) => {
    
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
      })
  }
}




export const deleteDocument = (payload,props) => {
  return dispatch => {
    let formData = new FormData();
    formData.append("relationshipId", payload.relationshipId);
    formData.append('bucketName', props.companyInfo.bucketName);
    formData.append('objectKey', payload.awsKeyName);
    formData.append('folderName', payload.folderName);
    formData.append('salesQuotationMasterId', payload.sqMasterId);
   
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesQuotation/deleteSalesQuotationDocument`,
        formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        props.fetchSQDetail(props);
        showToasterMessage({
          description: (res || {}).message || 'Deleted successfully.',
          messageType: 'success'
        })
      })
      .catch(err => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
  }
}
