import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS, SETTING_ACTION_LIST, DONATION_ACTION_LIST } from '../../../static/constants'
import { showToasterMessage } from '../../../utils';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchRelationshipDataByRID = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.USERS_BASE_URL}${config.API_VERSION}/${config.rootContextUsers}/clientRelationship/getRelationshipByRID?relationshipId=${payload.relationshipId}`)
      .then(res => {
        const { data } = res
        dispatch({ type: SETTING_ACTION_LIST.RELATIONSHIP_DATA, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = SETTING_ACTION_LIST.RELATIONSHIP_DATA;
        }
      })
  }
}


export const fetchRelationshipByDomainName = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.USERS_BASE_URL}${config.API_VERSION}/${config.rootContextUsers}/alpideClientURL/getRelationshipId?name=${payload.domainName}`)
      .then(res => {
        const { data } = res
        const load = { relationshipId: data.relationshipId }
        props.fetchDonationSetting(load);
        props.fetchCountries(load);
        props.fetchRelationshipDataByRID(load);
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = SETTING_ACTION_LIST.RELATIONSHIP_DATA;
        }
      })
  }
}


export const fetchDonationSetting = (payload) => {
  return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      dispatch({ type: DONATION_ACTION_LIST.RESET_DONATION_SETTING });
      return axios
        .get(`${config.USERS_BASE_URL}${config.API_VERSION}/${config.rootContextUsers}/donationSettings/getDonationSettings?relationshipId=${payload.relationshipId}`)
        .then(res => {
          const { data } = res
          dispatch({ type: DONATION_ACTION_LIST.DONATION_SETTING, data });
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
        .catch((err) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if(!err.__isRetryRequest){
            showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          }else{
            err.actionToCall = DONATION_ACTION_LIST.DONATION_SETTING;
          }
        })
    }
}


export const getAuthNetPaymentUrl = (props, payload, recData) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/payment/getAuthNetPaymentUrl?relationshipId=${props.relationshipId}&amount=${props.amount}&projectMasterId=${payload.projectMasterId}`)
      .then(res => {
        let paymentUrl = res.data+`&callback_uri=${window.location.origin}/app/donation?projectMasterId=${payload.projectMasterId}&projectMasterId=${payload.projectMasterId}&project_name=${payload.projectName}`
        if(recData){
          paymentUrl = paymentUrl+`&subscription_name=${recData.subscriptionName}&intervalLength=${recData.intervalLength}&unit=${recData.unit}&start_date=${recData.startDate}&totalOccurrences=${recData.totalOccurrences}`
        }
        window.location = (paymentUrl);
        //window.open(res.data);
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
        } else {
          showToasterMessage({
            description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
          })
        }
      })
  }
}


export const getFormToken = (requestObj, props, cb) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}/alpide-an/payment/getHostedForm`,
        requestObj,
      )
      .then(res => {
        (props && props.updateState) && props.updateState({
          isTokenReceived: true,
          hostedPaymentPageToken: res.data,
        });
        cb && cb({
          isTokenReceived: true,
          hostedPaymentPageToken: res.data,
        });
      })
      .catch(err => {
        showToasterMessage({ description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}


export const paymentSuccess = (requestObj, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/payment/hostedFormResponse`,
        requestObj,
      )
      .then(res => {
      })
      .catch(err => {
        showToasterMessage({ description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}