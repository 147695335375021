import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS, HIERARCHY_ACTION_LIST } from '../../../static/constants';
//import {  formatDateForBackend } from '../../../utils';
import { showToasterMessage } from '../../../utils';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchCompanyHierarchy = (payload) => {
    return dispatch => {
      // dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios.get(`${config.USERS_BASE_URL}${config.API_VERSION}/${config.rootContextUsers}/companyHierarchy/getAllCompanyHierarchies?relationshipId=${payload.relationshipId}`)
        .then(response => {
            dispatch({ type: HIERARCHY_ACTION_LIST.HIERARCHY_LIST, data: response.data });
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
        .catch(err => {
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
    }
}

export const deleteHierarchy = (payload) => {
    const requestObj = {
        hierarchyId: payload.id,
        relationshipId: payload.relationshipId,
    }
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.USERS_BASE_URL}${config.API_VERSION}/${config.rootContextUsers}/companyHierarchy/deleteCompanyHierarchy`, requestObj)
        .then(res => {
          dispatch({ type: HIERARCHY_ACTION_LIST.HIERARCHY_LIST_DELETION, data: { id: payload.id } })
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          showToasterMessage({
            description: (res || {}).message || 'Deleted successfully.',
            messageType: 'success'
          })
        })
        .catch((err = {}) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (err.__isRetryRequest) {
            err.actionToCall = HIERARCHY_ACTION_LIST.HIERARCHY_LIST_DELETION;
          } else {
            showToasterMessage({
              description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
            })
          }
        })
    }
  }