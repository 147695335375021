import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMMON_ACTIONS, SO_ACTION_LIST } from '../../../../static/constants';
import { showToasterMessage, getFinancialYear } from '../../../../utils';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchSalesOrders = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesOrder/getAllSalesOrders?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1)-1}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: SO_ACTION_LIST.SO_LIST, data });
      })
      .catch((err) => {
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
     
      }).finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
      })
  }
}

export const fetchAmendSalesOrders = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/amendSalesOrder/getAllAmendSalesOrder?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1)-1}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: SO_ACTION_LIST.AMEND_SO_LIST, data });
      })
      .catch((err) => {
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
     
      }).finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
      })
  }
}

export const fetchSalesOrdersOnlineOrders = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/ecom_order/getAllPreOrders?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1)-1}`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: SO_ACTION_LIST.SO_ONLINE_LIST, data });
      })
      .catch((err) => {
        showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
      .finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      })
  }
}

export const getSalesOrderOnlineCount = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_HEADER_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/ecom_order/getAllPreOrdersCount?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: SO_ACTION_LIST.SALES_ORDER_ONLINE_COUNT, data: res.data });
        dispatch({ type: COMMON_ACTIONS.HIDE_HEADER_LOADER });
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_HEADER_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = SO_ACTION_LIST.SALES_ORDER_ONLINE_COUNT;
        }
      })
  }
}

export const getSalesOrderCount = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_HEADER_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesOrder/getTotalOrdersCountByRid?relationshipId=${payload.relationshipId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
      .then(res => {
        dispatch({ type: SO_ACTION_LIST.SALES_ORDER_COUNT, data: res.data });
        dispatch({ type: COMMON_ACTIONS.HIDE_HEADER_LOADER });
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_HEADER_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = SO_ACTION_LIST.SALES_ORDER_COUNT;
        }
      })
  }
}

export const resetPaginatedData = () => {
  return dispatch => {
    dispatch({ type: SO_ACTION_LIST.SO_LIST_PAGE_RESET });
  }
}

export const fetchSalesOrderDrafts = (payload, history) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesOrder/getAllSalesOrderDraftData?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1)-1}`)
      .then(res => {
        let list = res.data.map(obj => {
          let rObj = {...obj};
          rObj.salesOrderData = JSON.parse(obj.salesOrderData);
          return rObj;
       })
        const data = {
          pageNo: payload.pageNumber,
          list: list
        }
        dispatch({ type: SO_ACTION_LIST.SO_DRAFT_LIST, data });
      })
      .catch((err) => {
        showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
      })
  }
}

export const getSalesOrderDraftCount = (payload, history) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_HEADER_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesOrder/getSalesOrderDraftCount?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: SO_ACTION_LIST.SO_DRAFT_COUNT, data: res.data });
        dispatch({ type: COMMON_ACTIONS.HIDE_HEADER_LOADER });
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_HEADER_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = SO_ACTION_LIST.SO_DRAFT_COUNT;
        }
      })
  }
}

export const resetDraftPaginatedData = () => {
  return dispatch => {
    dispatch({ type: SO_ACTION_LIST.SO_DRAFT_LIST_PAGE_RESET });
  }
}

export const updateQualityCheckList = (qualityCheckList, props) => {
  
  let formData = new FormData();
  if(qualityCheckList && qualityCheckList.length){
    qualityCheckList.forEach((quality, qualityIndex) => {
      const documentsToUpdate = [];
      if(quality.documentDetails && quality.documentDetails.length){
        quality.documentDetails.forEach((document) => {
          if(document.docDetailsId){
            documentsToUpdate.push(document);
          }else{
            formData.append('documents', document, qualityIndex +","+document.name);
          }
        });
      }
      quality.sno = qualityIndex;
      quality.documentDetails = documentsToUpdate;
    });
    formData.append('qualityCheckList', JSON.stringify(qualityCheckList, true));
  }
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/qualityChecklistTransaction/saveOrUpdateSOQC`, formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then(res => {
        showToasterMessage({ messageType: 'success', description: 'Update successfully' })
        props.fetchSalesOrders(props);
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }
      })
  }
}


export const updateUserStatus = (payload, props) => {
  return dispatch => {

    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesOrder/updateUserStatus`, payload)
      .then(res => {
        showToasterMessage({ messageType: 'success', description: 'User status updated' })
        props.fetchSalesOrders(props);
        props.fetchSODetail && props.fetchSODetail(payload);
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }
      })
  }
}


export const deleteSalesOrder = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesOrder/deleteSalesOrder`, payload)
      .then(res => {
        showToasterMessage({ messageType: 'success', description: lang[(res.data || {}).message  || 'Sales Order has been deleted successfully' ] }) ;
        props.fetchSalesOrders(props);
        props.getSalesOrderCount(props);
      })
      .catch((err) => {
        showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}


export const fetchSalesOrdersByFilter = (payload, history) => {

  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesOrder/getSalesOrderSummary?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1)-1}&filters=${encodeURIComponent(JSON.stringify(payload.filters || {}))}`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: SO_ACTION_LIST.SO_LIST, data });
      })
      .catch((err) => {
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
  
        }
      })
  }
}

export const getSalesOrdersCountByFilter = (payload, history) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesOrder/countSOSummary?relationshipId=${payload.relationshipId}&filters=${encodeURIComponent(JSON.stringify(payload.filters || {}))}`)
      .then(res => {
        dispatch({ type: SO_ACTION_LIST.SALES_ORDER_COUNT, data: res.data });
      })
      .catch((err) => {
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = SO_ACTION_LIST.SALES_ORDER_COUNT;
        }
      })
  }
}

export const fetchCancelledSalesOrder = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesOrder/getCancelledSo?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1)-1}`)
      .then(res => {
        const data = {
          pageNumber: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: SO_ACTION_LIST.CANCELLED_SO_LIST, data });
      })
      .catch((err) => {
        showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
      .finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      })
  }
}

export const getCancelledSalesOrderCount = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_HEADER_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesOrder/getCancelledSoCount?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: SO_ACTION_LIST.CANCELLED_SO_COUNT, data: res.data });
      })
      .catch((err) => {
        showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_HEADER_LOADER });
      })
  }
}

export const cancelSalesOrder = (payload, props) => {
  return dispatch => {

    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesOrder/cancelSalesOrder`, payload)
      .then(res => {
        showToasterMessage({ messageType: 'success', description: 'Sales Order Cancelled' });
        // refresh list and details
        props.fetchSalesOrders(props);
        props.fetchSODetail && props.fetchSODetail(payload);
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}


export const requestCancelSalesOrder = (payload, props) => {
  return dispatch => {

    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesOrder/rejectCancellationRequestSalesOrder`, payload)
      .then(res => {
        showToasterMessage({ messageType: 'success', description: 'Request  Cancelled' });
        // refresh list and details
        props.fetchSalesOrders(props);
        props.fetchSODetail && props.fetchSODetail(payload);
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}

export const cancelSalesOrderPartially = (payload, props) => {
  return dispatch => {

    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesOrder/cancelSalesOrderPartially`, payload)
      .then(res => {
        showToasterMessage({ messageType: 'success', description: 'Sales Order Cancelled' });
        // refresh list and details
        props.fetchSalesOrders(props);
        props.fetchSODetail && props.fetchSODetail(payload);
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}