import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMPANY_ACTION_LIST, COMMON_ACTIONS } from '../../../../static/constants';
import { showToasterMessage } from '../../../../utils';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const createDesignation = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/hrmsDesignation/saveOrUpdate`, payload)
      .then(res => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });

        showToasterMessage({
          description: (res || {}).message || 'Created successfully.',
          messageType: 'success'
        })

        const payload = {
          relationshipId: props.companyInfo.relationshipId,
          pageNumber: 1,
          pageSize: 25,
        }
        props.getDesignation(payload);
        getTotalDesignationCount(payload);
      })
      .catch((err = {}) => {
        showToasterMessage({
          description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
        })
      })
  }
}


export const getDesignation = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios.get(`${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/hrmsDesignation/getDesignationByPage?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${payload.pageNumber - 1}`)
      .then(response => {
        const data = {
          pageNo: payload.pageNumber,
          list: response.data
        }
        if(response.data.status === 200)
        dispatch({ type: COMPANY_ACTION_LIST.DESIGNATION_LIST_PAGE, data });
      })
      .catch(err => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
      })
  }
}

export const getTotalDesignationCount = (payload, history) => {
  return dispatch => {
    return axios
      .get(`${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/hrmsDesignation/getDesignationCount?relationshipId=${payload.relationshipId}`)

      .then(res => {
        if(res.data.status === 200)
        dispatch({ type: COMPANY_ACTION_LIST.DESIGNATION_LIST_PAGE_COUNT, data: res.data });
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}

export const deleteDesignation = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/hrmsDesignation/delete`, payload)
      .then(res => {
        showToasterMessage({
          description: (res || {}).message || 'Deleted successfully.',
          messageType: 'success'
        })
        const payload = {
          relationshipId: props.companyInfo.relationshipId,
          pageNumber: 0,
          pageSize: 25,
        }
        props.getDesignation(payload);
        props.getTotalDesignationCount(payload);

      })
      .catch((err = {}) => {
        showToasterMessage({
          description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
        })
      })
  }
}

export const setStatus = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios.patch(`${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/hrmsDesignation/setStatus?relationshipId=${payload.relationshipId}&designationId=${payload.designationId}&status=${(payload.status)}`,)
      .then(response => {
        showToasterMessage({ messageType: 'success', description: `Status updated` });
        const payload = {
          relationshipId: props.companyInfo.relationshipId,
          pageNumber: 1,
          pageSize: 25,
        }
        props.getDesignation(payload);
      })

      .catch(err => {
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }
      })
  }
}