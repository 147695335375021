import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS, CUSTOMER_ACTION_LIST } from '../../../../../static/constants';
import { showToasterMessage ,getFinancialYear} from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const fetchTotalOrders = (payload, props) => {
  let FY = getFinancialYear(props?.companyInfo);

  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_MODAL_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesOrder/getTotalOrderOrSalesRevenueDataByRId?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1)-1}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: CUSTOMER_ACTION_LIST.CUSTOMER_TOTAL_ORDER, data });
        dispatch({ type: COMMON_ACTIONS.HIDE_MODAL_LOADER });
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_MODAL_LOADER });
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}



export const fetchTotalOrdersCount = (payload, props) => {
  let FY = getFinancialYear(props?.companyInfo);

    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });

      return axios
        .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesOrder/getTotalOrdersCountByRid?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1)-1}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
        .then(res => {
          dispatch({ type: CUSTOMER_ACTION_LIST.CUSTOMER_TOTAL_ORDER_COUNT_BY_R_ID, data: res.data });
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
        .catch((err) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if(!err.__isRetryRequest){
            showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          }else{
            err.actionToCall = CUSTOMER_ACTION_LIST.CUSTOMER_TOTAL_ORDER_COUNT_BY_R_ID;
          }
        })
    }
  }

  export const resetPaginatedData = () => {
    return dispatch => {
      dispatch({ type: CUSTOMER_ACTION_LIST.TOTAL_ORDER_LIST_PAGE_RESET });
    }
  }