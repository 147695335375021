import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS, CUSTOMER_ACTION_LIST } from '../../../static/constants'
import { showToasterMessage, getFinancialYear } from '../../../utils';
import download from 'downloadjs'
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchCustomers = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  const searchedTextQuery = payload.searchedText && payload.searchedText.length ? `&searchString=${payload.searchedText}` : '';
  let url = `/customers/getCustomers?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1) - 1}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}${searchedTextQuery}`
  if (payload.isCompact) {
    url = `/customers/getCustomerSummary?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1) - 1}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}${searchedTextQuery}&module=${payload.module}`
  }
  return dispatch => {
    // !payload.hideSpinner && dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    payload.hideLoad && dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });

    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}${url}`)
      .then(res => {
        if (!res?.data) {
          throw new Error("Invalid Data");
        }

        const data = {
          pageNo: payload.pageNumber,
          list: payload.filterData ? (res.data?.filter((item)=>item.isActive == 1) ):res.data
        }
        dispatch({ type: CUSTOMER_ACTION_LIST.CUSTOMER_LIST, data });

        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = CUSTOMER_ACTION_LIST.CUSTOMER_LIST;
          err.data = {
            pageNo: payload.pageNumber,
            list: []
          };
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      })
  }
}

export const getCustomerCount = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  const searchedTextQuery = payload.searchedText && payload.searchedText.length ? `&searchString=${payload.searchedText}` : '';
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_HEADER_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customers/getCustomerCount?relationshipId=${payload.relationshipId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}${searchedTextQuery}`)
      .then(res => {
        dispatch({ type: CUSTOMER_ACTION_LIST.CUSTOMER_COUNT, data: res.data });
        dispatch({ type: COMMON_ACTIONS.HIDE_HEADER_LOADER });
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_HEADER_LOADER });
        if (!err.__isRetryRequest) {
          // showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = CUSTOMER_ACTION_LIST.CUSTOMER_COUNT;
        }
      })
  }
}
export const resetPaginatedData = () => {
  return dispatch => {
    dispatch({ type: CUSTOMER_ACTION_LIST.CUSTOMER_LIST_PAGE_RESET });
  }
}

export const getTotalOrdersCount = (payload, history) => {  
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesOrder/getTotalOrdersCountByRid?relationshipId=${payload.relationshipId}&customerId=${payload.customerId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
      .then(res => {
        dispatch({ type: CUSTOMER_ACTION_LIST.CUSTOMER_OPEN_ORDER_COUNT, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = CUSTOMER_ACTION_LIST.CUSTOMER_OPEN_ORDER_COUNT;
        }
      })
  }
}

export const getTotalSalesOrdersCount = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesOrder/getOpenOrdersCountByRid?relationshipId=${payload.relationshipId}&customerId=${payload.customerId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
      .then(res => {
        dispatch({ type: CUSTOMER_ACTION_LIST.CUSTOMER_OPEN_SO_COUNT, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = CUSTOMER_ACTION_LIST.CUSTOMER_OPEN_SO_COUNT;
        }
      })
  }
}

export const getTotalUnpaidBalance = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customerInvoice/countUnpaidInvoicesByRId?relationshipId=${payload.relationshipId}&customerId=${payload.customerId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
      .then(res => {
        dispatch({ type: CUSTOMER_ACTION_LIST.CUSTOMER_UNPAID_BALANCE, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = CUSTOMER_ACTION_LIST.CUSTOMER_UNPAID_BALANCE;
        }
      })
  }
}
export const getTotalPaidBalance = (payload, history) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customerInvoice/countPaidInvoicesByRid?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: CUSTOMER_ACTION_LIST.CUSTOMER_PAID_BALANCE, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = CUSTOMER_ACTION_LIST.CUSTOMER_PAID_BALANCE;
        }
      })
  }
}

export const getTotalSalesRevenue = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customerInvoice/getTotalInvoiceCount?relationshipId=${payload.relationshipId}&customerId=${payload.customerId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}&filters=${encodeURIComponent(JSON.stringify(payload.filters || {}))}`)
      .then(res => {
        dispatch({ type: CUSTOMER_ACTION_LIST.CUSTOMER_SALES_REVENUE, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = CUSTOMER_ACTION_LIST.CUSTOMER_SALES_REVENUE;
        }
      })
  }
}

export const exportPdf = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios.get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customerPdf/getSelectedCustomerPdf?relationshipId=${payload.relationshipId}&customerIds=${payload.customerIds}`,
      {
        responseType: 'blob',
      }
    )
      .then(response => {
        const content = response.headers['content-type'];
        download(response.data, 'Customer.pdf', content);
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch(err => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
  }
}

export const exportExcel = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios.get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customerExcel/getSelectedCustomerExcel?relationshipId=${payload.relationshipId}&customerIds=${payload.customerIds}`,
      {
        responseType: 'blob',
      }
    )
      .then(response => {
        const content = response.headers['content-type'];
        download(response.data, 'Customer.xlsx', content);
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch(err => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
  }
}

export const deleteCustomers = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios.post(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customers/deleteMultipleCustomers`, payload)
      .then(response => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({ messageType: 'success', description: (response.data || {}).message || 'Customer(s) deleted successfully' });
        resetPaginatedData()(dispatch);
        getCustomerCount(payload)(dispatch);
        fetchCustomers(payload)(dispatch);
      })
      .catch(err => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }
      })
  }
}
export const updateStatus = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios.patch(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/customers/activate?relationshipId=${payload.relationshipId}&customerId=${payload.customerId}&active=${(payload.active)}`,)
      .then(response => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({ messageType: 'success', description: `Status change` });
        const payload = {
          relationshipId: props.companyInfo.relationshipId,
          pageNumber: 1,
          pageSize: 25,
        }
        props.fetchCustomers(payload);
        // fetchCustomers(payload)(dispatch);
      })
      .catch(err => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }
      })
  }
}