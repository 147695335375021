import axios from "axios";
import config from "../../../../config/environmentConfig";
import { COMMON_ACTIONS } from "../../../../static/constants";
import { showToasterMessage } from "../../../../utils";
const { lang } = require("../../../../translations/" +
  (sessionStorage.getItem("language") || "en") +
  ".js");

export const saveDepartment = (payload, props) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });

    return axios
      .post(`${config.EDUCATION_BASE_URL}${config.API_VERSION}/${config.rootContextEducation}/dept/saveOrUpdate`, payload)
      .then((response) => {
        if (props) {
          props.updateHeaderState({
            schoolDrawers: {
              ...props.schoolDrawers,
              addDepartmentDrawerVisible: false,
              deptData: {}
            }
          });
          props.getDepartmentList && props.getDepartmentList && props.getDepartmentList(props);
          props.getDepartmentCount && props.getDepartmentCount && props.getDepartmentCount(props);
          showToasterMessage({
            messageType: "success",
            description: "Created Successfully!",
        });

        }
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
      })
      
  };
};


