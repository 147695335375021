import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS, SO_ACTION_LIST } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const getSOPackageList = (payload, history) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesOrderPackage/getSOPackageBySOMasterId?relationshipId=${payload.relationshipId}&salesOrderMasterId=${payload.salesOrderMasterId}&customerId=${payload.customerId}`)
      .then(res => {
        dispatch({ type: SO_ACTION_LIST.SO_PACKAGE_LIST, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = SO_ACTION_LIST.SO_PACKAGE_LIST;
        }
      })
  }
}

export const updatePackageQualityCheck = (payload, props, callback) => {
  
  let formData = new FormData();
  if(payload.qualityChecklistPackageList && payload.qualityChecklistPackageList.length){
    payload.qualityChecklistPackageList.forEach((quality, qualityIndex) => {
      const documentsToUpdate = [];
      if(quality.documentDetails && quality.documentDetails.length){
        quality.documentDetails.forEach((document) => {
          if(document.docDetailsId){
            documentsToUpdate.push(document);
          }else{
            formData.append('documents', document, qualityIndex +","+document.name);
          }
        });
      }
      quality.sno = qualityIndex;
      quality.packageMasterId = payload.soPackageMasterId;
      quality.relationshipId= payload.relationshipId;
      quality.customerId= payload.customerId;
      quality.documentDetails = documentsToUpdate;
    });
    formData.append('packageMaster', JSON.stringify(payload, true));
  }
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/qualityChecklistTransaction/saveOrUpdatePackageQC`, formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then(res => {
        props.getSOPackageList({
          customerId: payload.customerId,
          salesOrderMasterId: payload.salesOrderMasterId,
          relationshipId: payload.relationshipId
      });
        showToasterMessage({ messageType: 'success', description: 'Update successfully' })
       // dispatch({ type: SO_ACTION_LIST.SO_LIST_PAGE_RESET });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(callback){ callback();}
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }
      })
  }
}