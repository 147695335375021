import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMPANY_ACTION_LIST, COMMON_ACTIONS } from '../../../../static/constants';
import { showToasterMessage } from '../../../../utils';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const createDepartment = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_DRAWER_LOADER });
    return axios
      .post(`${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/boDepartment/saveOrUpdateDepartment`, payload)
      .then(res => {

        dispatch({ type: COMMON_ACTIONS.HIDE_DRAWER_LOADER });

        showToasterMessage({
          description: (res || {}).message || 'Created successfully.',
          messageType: 'success'
        })

        const payload = {
          relationshipId: props.companyInfo.relationshipId,
          pageNumber: 1,
          pageSize: 25,
        }
        props.getDepartment(payload);
        getTotalDepartmentCount(payload);
      })
      .catch((err = {}) => {
        showToasterMessage({
          description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
        })
        dispatch({ type: COMMON_ACTIONS.HIDE_DRAWER_LOADER });

      })
  }
}


export const getDepartment = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios.get(`${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/boDepartment/getDepartmentsByPage?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${payload.pageNumber - 1}`)

      .then(response => {
        const data = {
          pageNo: payload.pageNumber,
          list: response.data
        }
        if(response.data.status === 200)
        dispatch({ type: COMPANY_ACTION_LIST.DEPARTMENT_LIST, data });

      })
      .catch(err => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      })
  }
}

export const getTotalDepartmentCount = (payload, history) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_HEADER_LOADER });
    return axios
      .get(`${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/boDepartment/getDepartmentsCount?relationshipId=${payload.relationshipId}`)
      .then(res => {
        if(res.data.status === 200)
        dispatch({ type: COMPANY_ACTION_LIST.DEPARTMENT_COUNT, data: res.data });

      })
      .catch((err) => {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })

      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_HEADER_LOADER,
        });
      })
  }
}
export const deletedepartment = (payload, props) => {
  return dispatch => {
    // dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/boDepartment/deleteDepartment`, payload)
      .then(res => {
        showToasterMessage({
          description: (res || {}).message || 'Deleted successfully.',
          messageType: 'success'
        })
        const payload = {
          relationshipId: props.companyInfo.relationshipId,
          pageNumber: 0,
          pageSize: 25,
        }
        props.getDepartment(payload);
        props.getTotalDepartmentCount(payload);

      })
      .catch((err = {}) => {
        showToasterMessage({
          description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
        })
      })
  }
}

// export const setDepartmentStatus = (payload,props) => {
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios.patch(`${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/boDepartment/setStatus?relationshipId=${payload.relationshipId}&departmentId=${payload.departmentId}&status=${(payload.status)}`,)
//       .then(response => {
//         showToasterMessage({ messageType: 'success', description: `Status updated` });
//         const payload = {
//           relationshipId: props.companyInfo.relationshipId,
//           pageNumber: 1,
//           pageSize: 25,
//         }
//         props.getDepartment(payload);
//       })
//       .catch(err => {
//         if (!err.__isRetryRequest) {
//           showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
//         }
//       })
//   }
// }


export const setDepartmentStatus = (payload, props) => {
  return dispatch => {
    return axios
    .patch(`${config.LOOKUP_BASE_URL}${config.API_VERSION}/${config.rootContextLookup}/boDepartment/setStatus?relationshipId=${payload.relationshipId}&departmentId=${payload.departmentId}&status=${(payload.status)}`)
      .then(res => {
        showToasterMessage({
          description: (res || {}).message || 'Created successfully.',
          messageType: 'success'
        })

        const payload = {
          relationshipId: props.companyInfo.relationshipId,
          pageNumber: 1,
          pageSize: 25,
        }
        props.getDepartment(payload);
      })
      .catch((err = {}) => {
        showToasterMessage({
          description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
        })
      })
  }
}
