import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS, PRODUCT_ACTION_LIST } from '../../../../../static/constants'
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchOutOfStockItems = (payload, history) => {

  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.INVENTORY_BASE_URL}${config.API_VERSION}/${config.rootContextInventory}/inventoryItem/getOutOfStockItems?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${payload.pageNumber-1}`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: PRODUCT_ACTION_LIST.PRODUCT_OUT_OF_STOCK_LIST, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = PRODUCT_ACTION_LIST.PRODUCT_OUT_OF_STOCK_LIST;
          err.data = {
            pageNo: payload.pageNumber,
            list: []
          };
        }
      })
  }
}

  export const resetPaginatedData = () => {
    return dispatch => {
      dispatch({ type: PRODUCT_ACTION_LIST.PRODUCT_OUT_OF_STOCK_LIST_PAGE_RESET });
    }
  }