import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS, SUPPLIER_ACTION_LIST } from '../../../../../static/constants';
import { showToasterMessage, getFinancialYear } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchUnpaidInvoices = (payload, props) => {
  let FY = getFinancialYear(props?.companyInfo);
  let url = '';
  if (payload.supplierId) {
    url = `supplierInvoice/getAllUnpaidInvoicesBySupplierId?supplierId=${payload.supplierId}&relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1)-1}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`;
  } else {
    url = `supplierInvoice/getAllUnpaidInvoices?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1)-1}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`;
  }
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_MODAL_LOADER });
    return axios
      .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/${url}`)
      .then(res => {
        if(!res.data){
          throw new Error();
        }
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: SUPPLIER_ACTION_LIST.SUPPLIER_UNPAID_INVOICES_BY_SUPPLIER, data });
        dispatch({ type: COMMON_ACTIONS.HIDE_MODAL_LOADER });
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_MODAL_LOADER });
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}



export const fetchUnpaidInvoicesCount = (payload, props) => {
  let FY = getFinancialYear(props?.companyInfo);
  
  let url = '';
  if (payload.supplierId) {
    url = `supplierInvoice/getUnpaidInvoicesCount?relationshipId=${payload.relationshipId}&supplierId=${payload.supplierId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`;
  } else {
    url = `supplierInvoice/getUnpaidInvoiceCount?relationshipId=${payload.relationshipId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`;
  }
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/${url}`)
      .then(res => {
        dispatch({ type: SUPPLIER_ACTION_LIST.SUPPLIER_UNPAID_INVOICES_COUNT_BY_SUPPLIER, data: res.data });
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}

export const resetPaginatedData = () => {
  return dispatch => {
    dispatch({ type: SUPPLIER_ACTION_LIST.SUPPLIER_UNPAID_INVOICES_BY_SUPPLIER_RESET });
  }
}