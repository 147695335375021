import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as filter from "lodash.filter";
import * as find from "lodash.find";
import { fetchAllLedgerAccounts } from "../../../finance/LedgerAccounts/action";
import StandardPurchaseOrder from "../../../../components/supplier/purchaseOrder/StandardPurchaseOrder";
import { fetchAllRFQ } from "../../../../containers/supplier/rfq/Listing/action";
import { fetchSuppliers ,resetPaginatedData} from "../../Listing/action";
import {
  fetchPriceList,
  fetchPaymentTerms,
  addPaymentTerm,
  deletePaymentTerm,
  fetchProjects,
  fetchStates,
  fetchTaxes,
  fetchUOM,
  addUOM,
  deleteUOM,
  fetchExpenseTypes,
  addExpenseTypes,
  deleteExpenseTypes,
  fetchProducts,
  showModal,
  hideModal,
  pushModalToStack,
  popModalFromStack,
  deleteProject,
  deleteAddress,
  deleteTax,
  deletePriceList,
  fetchDocuments,
  addDocumentName,
  deleteDocumentName,
  deleteContact,
  fetchUserPreferences,
  saveUserPrefrences,
  fetchShippingInstructions,
  addShippingInstruction,
  deleteShippingInstruction,
  fetchRelationshipTaxIdentifications,
  deleteRelationshipTaxIdentifications,
  deleteSupplierTaxIdentifications,
  fetchSupplierSummary,
  fetchSupplierDetailsBySupplierId,
  resetSupplierDetail,
  getRelationshipAddress,
  getItemByUPC,
} from "../../../../actions/commonActions";
import LedgerDrawer from "../../../../containers/drawer/ledgers/PurchaseOrderLedger";
import {
  createPO,
  fetchRfqDataForPo,
  resetRfqPoData,
  getNextTxNumber,
  fetchPRDataForPO,
  resetPrDataForPO,
  createPODraft,
  deleteDocument,
} from "./action";
import {
  fetchDataIfNeeded,
  fetchPaginationDataIfNeeded,
} from "../../../../utils";
import PurchaseOrderDetail from "../../../modal/modalBody/supplier/PurchaseOrderDetail";
import {
  CONSTANTS,
  LEDGER_ACTION_LIST,
  LEDGER_TYPE,
  CONSTANTS_TRANSACTIONS,
  CONSTANTS_TRANSACTIONS_KEY,
} from "../../../../static/constants";
import { GRID_PREFERENCE_CONSTANTS } from "../../../../static/agGridConstants";
import { deleteSuppliers } from "../../Listing/action";
import { fetchPurchaseOrderDrafts, fetchPurchaseOrders,getPurchaseOrderDraftCount } from "../Listing/action";
import { fetchAddresses } from "../../Listing/ShowAddress/action";
import { fetchAllContacts } from "../../Listing/ShowConact/action";
import {
  fetchRemarksTemplate,
  resetRemarksData,
} from "../../../modal/modalBody/settings/RemarksTemplate/action";
import {
  fetchPurchasedOrderDetail,
  resetPODetail,
} from "../../../modal/modalBody/supplier/PurchaseOrderDetail/action";
import LedgerAccountsDrawer from "../../../drawer/finance/ledgers"
import SettingPurchaseDrawer from "../../../drawer/supplier/settingPurchaseDrawer";

class PurchaseInvoice extends Component {
  constructor(props) {
    super(props);
    const defaultPurchaseLedger =
      find(props.companyInfo.defaultCoaLedgerAccountsList, {
        txType: LEDGER_ACTION_LIST.PURCHASE,
      }) || {};
    const defaultTaxLedger =
      find(props.companyInfo.defaultCoaLedgerAccountsList, {
        txType: LEDGER_ACTION_LIST.DEFAULT_LEDGER_TYPE.PURCHASE_TAX,
      }) || {};
    const defaultDiscountLedger =
      find(props.companyInfo.defaultCoaLedgerAccountsList, {
        txType: LEDGER_ACTION_LIST.DISCOUNT_RECIEVED,
      }) || {};
    const defaultExpenseLedger =
      find(props.companyInfo.defaultCoaLedgerAccountsList, {
        txType: LEDGER_ACTION_LIST.DEFAULT_LEDGER_TYPE.SUPPLIER_EXPENSE,
      }) || {};
    const defaultCreditorLedger =
      find(props.companyInfo.defaultCoaLedgerAccountsList, {
        txType: LEDGER_ACTION_LIST.DEFAULT_LEDGER_TYPE.CREDITORS,
      }) || {};
    const allRelationshipBillingAddress =
      filter(props.companyInfo.boLocationList, {
        locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
      }) || [];
    const allRelationshipShippingAddress =
      filter(props.companyInfo.boLocationList, {
        locationType: CONSTANTS.RELATIONSHIP_SHIPPING_ADDRESS,
      }) || [];
    const relationshipBillingAddress =
      allRelationshipBillingAddress.find((x) => x.isDefault) || {};
    const relationshipShippingAddress =
      allRelationshipShippingAddress.find((x) => x.isDefault) || {};
    const placeOfSupply = (relationshipBillingAddress || {}).stateName || "";
    // (
    //   allRelationshipShippingAddress.find((x) => x.isDefault) || {}
    // ).stateName;
    // const linkProps = (this.props.location && this.props.location.state) || {};
    this.state = {
      displayName:"drawer.settingDrawer.headingNamePurchaseOrder",
      viewName: "PuchaseOrder",
      docName:'',
      tabDocumentOptions: 
      { txType: CONSTANTS_TRANSACTIONS.PURCHASE_ORDER, label: 'Purchase Order', numberExample: 'PO-1', numberInfo: 'PO-SupplierNumber-DDMMYY' },
      settingPurchaseDrawerVisible:false,

      hideAddNew:true,
      valueSet:false,
      expectedDeliveryDate: new Date(),
      isClone: props.clone,
      poDraftData: props.purchaseOrderData,
      poDraftId: props.poDraftId,
      stateSupplierId: props.supplierId,
      pageNumber: props.pageNumber || 1,
      supplier: undefined,
      allRelationshipBillingAddress,
      allRelationshipShippingAddress,
      relationshipBillingAddress,
      relationshipShippingAddress,
      placeOfSupply,
      rootExpenseList:[{}],
      priceListName: undefined,
      poDate: new Date(),
      pageSize: 2000,
      purchaseLedgerId: defaultPurchaseLedger.ledgerAccountId,
      purchaseCreditorsLedgerId: defaultCreditorLedger.ledgerAccountId,
      purchaseDiscountLedgerId: defaultDiscountLedger.ledgerAccountId,
      discountCreditorLedgerId: defaultCreditorLedger.ledgerAccountId,
      purchaseTaxLedgerId: defaultTaxLedger.ledgerAccountId,
      taxCreditorsLedgerId: defaultCreditorLedger.ledgerAccountId,
      purchaseExpenseLedgerId: defaultExpenseLedger.ledgerAccountId,
      purchaseExpenseCreditorLedgerId: defaultCreditorLedger.ledgerAccountId,

      igstPurchaseTaxLedgerId: defaultTaxLedger.ledgerAccountId,
      igstTaxCreditorsLedgerId: defaultCreditorLedger.ledgerAccountId,
      
      cgstPurchaseTaxLedgerId: defaultTaxLedger.ledgerAccountId,
      cgstTaxCreditorsLedgerId: defaultCreditorLedger.ledgerAccountId,
  
      
    
      sgstPurchaseTaxLedgerId: defaultTaxLedger.ledgerAccountId,
      sgstTaxCreditorsLedgerId: defaultCreditorLedger.ledgerAccountId,
     
      companyInfo: props.companyInfo,
      inheritedTxColumn: [],
      forceHideTxColumn: [],
      txPreferences: { txType: "purchaseOrder", templateName: "standard" },
      txColumns: [],
      txColumnSetting: {},
      txMandatoryColumns: [
        GRID_PREFERENCE_CONSTANTS.PRODUCT,
        GRID_PREFERENCE_CONSTANTS.DESCRIPTION,
        GRID_PREFERENCE_CONSTANTS.QUANTITY,
        GRID_PREFERENCE_CONSTANTS.RATE,
        GRID_PREFERENCE_CONSTANTS.AMOUNT,
        GRID_PREFERENCE_CONSTANTS.AMOUNT_WITHOUT_TAX,
        "sno",
        "upc",
      ],
      txAvailableColumn: {
        product: true,
        quantity: true,
        rate: true,
        amount: true,
        description: true,
        uom: true,
        hsn: (props.companyInfo || {}).countryName === "India",
        basePrice: true,
        specialDiscount: true,
        discount: true,
        tax: true,
        taxableAmount:true
        // upc: true
      },
    };
  }

  componentDidMount() {
    // this.props.updateHeaderState({
    //   collapsed: true
    // });
    const companyInfo = this.props.companyInfo || {};
    const linkProps = (this.props.location && this.props.location.state) || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      rfqMasterId: this.props.rfqMasterId || linkProps.rfqMasterId,
      userId: (this.props.userInfo || {}).userId,
      rfqChildMasterId:
        this.props.rfqChildMasterId || linkProps.rfqChildMasterId,
      purchaseRequestMasterId:
        this.props.purchaseRequestMasterId || linkProps.purchaseRequestMasterId,
      poMasterId: this.props.poMasterId || linkProps.poMasterId,
      supplierId: this.props.supplierId || linkProps.supplierId,
      pageNumber: 1,
      pageSize: 2000,
    };
    this.props.fetchAllLedgerAccounts(payload);
    this.props.resetSupplierDetail();
    // fetchPaginationDataIfNeeded('fetchSuppliers', 'suppliers', this.props, payload, true);
    this.props.resetPaginatedData();
    payload.supplierId && this.props.fetchSupplierDetailsBySupplierId(payload);
    this.props.resetRfqPoData();
    payload.rfqMasterId &&
      fetchDataIfNeeded(
        "fetchRfqDataForPo",
        "rfqPoData",
        this.props,
        payload,
        true
      );
    this.props.resetPODetail();
    payload.poMasterId &&
      fetchDataIfNeeded(
        "fetchPurchasedOrderDetail",
        "poData",
        this.props,
        payload,
        true
      );
    this.props.resetPrDataForPO();
    payload.purchaseRequestMasterId &&
      fetchDataIfNeeded(
        "fetchPRDataForPO",
        "poPrData",
        this.props,
        payload,
        true
      );
    fetchPaginationDataIfNeeded(
      "fetchSupplierSummary",
      "supplierSummary",
      this.props,
      payload,
      true
    );
    fetchDataIfNeeded("fetchPriceList", "priceList", this.props, payload);
    fetchDataIfNeeded("fetchProjects", "projectList", this.props, payload);
    fetchDataIfNeeded("fetchPaymentTerms", "paymentTerms", this.props, payload);
    fetchDataIfNeeded("fetchStates", "states", this.props, 1);
    fetchDataIfNeeded("fetchProducts", "products", this.props, payload);
    fetchDataIfNeeded("fetchTaxes", "taxes", this.props, payload);
    fetchDataIfNeeded("fetchUOM", "uomList", this.props, payload);
    fetchDataIfNeeded("fetchExpenseTypes", "expenseTypes", this.props, payload);
    fetchDataIfNeeded("fetchDocuments", "documentList", this.props, payload);
    if (!this.props.update) {
      fetchDataIfNeeded("getNextTxNumber", "nextTxNumber", this.props, payload);
    }
    fetchDataIfNeeded(
      "fetchUserPreferences",
      "allPreferences",
      this.props,
      payload
    );
    fetchDataIfNeeded(
      "fetchShippingInstructions",
      "allShippingInstruction",
      this.props,
      payload
    );
    this.props.fetchRemarksTemplate({
      relationshipId: companyInfo.relationshipId,
      docName: "Purchase Order",
    });
    fetchDataIfNeeded(
      "fetchRelationshipTaxIdentifications",
      "allRelationshipTaxIdentificatins",
      this.props,
      payload
    );
  }

  openPODetails = (data) => {
    const poPayload = {
      supplierId: data.supplierId,
      poMasterId: data.poMasterId,
    };
    this.props.pushModalToStack({
      modalBody: <PurchaseOrderDetail {...this.props} poPayload={poPayload} />,
      width: "100%",
      hideFooter: true,
      wrapClassName: "modal-custom-detail",
    });
  };

  componentWillReceiveProps(props) {
    // const linkProps = (this.props.location && this.props.location.state) || {};
    if (
      !this.state.selectedRelationshipTaxIdentifications &&
      props.allRelationshipTaxIdentificatins &&
      props.allRelationshipTaxIdentificatins.length
    ) {
      const selectedList =
        (props.allRelationshipTaxIdentificatins &&
          filter(props.allRelationshipTaxIdentificatins, {
            populateOnTransaction: 1,
          })) ||
        [];
      let selectedRelationshipTaxIdentifications = selectedList.map((obj) => {
        return obj.relationshipTaxIdentificationId;
      });
      this.setState({
        selectedRelationshipTaxIdentifications:
          selectedRelationshipTaxIdentifications,
      });
    }

    if (
      props.documentList &&
      props.documentList.length &&
      !this.state.docName
    ) {
      props.documentList.forEach((document) => {
        if (
          document.txType ===
            CONSTANTS_TRANSACTIONS_KEY[CONSTANTS_TRANSACTIONS.PURCHASE_ORDER] &&
          document.isDefault
        ) {
          this.setState({
            documentNameId: document.documentNameId,
            docName: document.docName,
          });
        }
      });
    }
    if (
      !this.state.supplierRemarks &&
      props.remarksData &&
      props.remarksData.isFetched &&
      !this.state.isRemarksSet
    ) {
      this.setState({
        supplierRemarks: props.remarksData.content || "",
        isRemarksSet: true,
        footer: props.remarksData.footer || "",
      });
      setTimeout(() => {
        props.resetRemarksData();
      }, 500);
    }
    if (props.boRelationshipAddress && props.boRelationshipAddress.length) {
      const allRelationshipBillingAddress = [];
      const allRelationshipShippingAddress = [];
      props.boRelationshipAddress.forEach((e) => {
        if (e.locationType === "RelationshipBillingAddress") {
          allRelationshipBillingAddress.push(e);
        } else {
          allRelationshipShippingAddress.push(e);
        }
      });
      this.setState({
        allRelationshipBillingAddress,
        allRelationshipShippingAddress,
      });
    } else {
      const allRelationshipBillingAddress =
        filter(props.companyInfo.boLocationList, {
          locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
        }) || [];
      const allRelationshipShippingAddress =
        filter(props.companyInfo.boLocationList, {
          locationType: CONSTANTS.RELATIONSHIP_SHIPPING_ADDRESS,
        }) || [];
      this.setState({
        allRelationshipBillingAddress,
        allRelationshipShippingAddress,
      });
    }
    if (
      // props.taxes &&
      // props.taxes.length &&
      props.isTaxesLoaded && 
      props.poData &&
      props.poData.poMasterId && props.allLedgerAccounts?.length
    ) {
      const detailList = [];

      const relationshipBillingAddress= find(props.poData.boLocationPOList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || {}
      const taxForLedger = props.poData.supplierPurchaseOrderDetailList?props.poData.supplierPurchaseOrderDetailList[0]?.supplierPOCOATxList?.filter(item=>item.txType ===LEDGER_TYPE.TYPE_TAX):[]
      const isPlaceOfSupplySameAsRelationship = relationshipBillingAddress.stateName === props.poData?.placeOfSupply
      taxForLedger.map((item)=>{
           if(props.companyInfo.countryName === "India" && !isPlaceOfSupplySameAsRelationship){
            if(item.accountingEntry === "debit"){
              this.setState({
                igstTaxCreditorsLedgerId:item.ledgerAccountId 
              })

            }else{
              this.setState({
                igstPurchaseTaxLedgerId:item.ledgerAccountId
              })
            }

           }else if(props.companyInfo.countryName === "India" && isPlaceOfSupplySameAsRelationship){
            if(item.accountingEntry === "debit"){
              if(item.taxName ==="CGST"){
                this.setState({
                  cgstTaxCreditorsLedgerId:item.ledgerAccountId,
                  })  
              }else{
                this.setState({
                  sgstTaxCreditorsLedgerId:item.ledgerAccountId
                })

              }

            }else{
              if(item.taxName ==="CGST"){
                this.setState({
                  cgstPurchaseTaxLedgerId:item.ledgerAccountId,
                  })  
              }else{
                this.setState({
                  sgstPurchaseTaxLedgerId:item.ledgerAccountId
                })

              }
              
            }
            
           } 
      })
      props.poData.supplierPurchaseOrderDetailList &&
        props.poData.supplierPurchaseOrderDetailList.map((ele) => {
          const tax =
            find(ele.supplierPOCOATxList, (ele) => {
              return ele.txType === LEDGER_TYPE.TYPE_TAX;
            }) || {};

          const appliedTax =
            find(props.taxes, { taxSingleRateId: Number(tax.taxId) }) || {};
          return detailList.push({
            version: ele.version,
            selectedProductValue: [ele.itemId, ele.inventoryItemVariantId],
            product: ele.itemName
              ? ele.itemName +
                ((ele.itemVariantName && " (" + ele.itemVariantName + ") ") ||
                  "")
              : "",
            productObj: ele.itemName
              ? { itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku }
              : {},
            itemVariantName: ele.itemVariantName,
            variant: {
              attributeId1: ele.attributeId1,
              attributeId2: ele.attributeId2,
              attributeId3: ele.attributeId3,
              attributeName1: ele.attributeName1,
              attributeName2: ele.attributeName2,
              attributeName3: ele.attributeName3,
              attributeValue1: ele.attributeValue1,
              attributeValue2: ele.attributeValue2,
              attributeValue3: ele.attributeValue3,
            },
            description: ele.description,
            scrollHeight: ele.rowHeight,
            discount: (
              find(ele.supplierPOCOATxList, {
                txType: LEDGER_TYPE.TYPE_DISCOUNT,
              }) || {}
            ).amountPercent,
            tax: tax.taxName === "CGST" || tax.taxName === "SGST" ?tax.amountPercent*2 || 0:tax.amountPercent,
            taxApplied: appliedTax.taxNameDisplay,
            taxName: appliedTax.taxName || 0,
            taxId: appliedTax.taxSingleRateId,
            qty: ele.quantity,
            qtyBeforeUpdate: ele.quantity,
            rate: ele.anItemPurchasePrice,
            baseRate: ele.basePrice,
            uom: ele.uomName,
            hsnCode: ele.hsnCode,
            specialDiscount: ele.specialDiscount,
            purchaseRequestMasterId: ele.purchaseRequestMasterId,
            parentDetailsId: props.update ? ele.parentDetailsId : 0,
            poDetailsId: props.update ? ele.poDetailsId : 0,
          });
        });

      const rootDiscount =
        find(props.poData.supplierPOCOATxList || [], {
          txType: LEDGER_TYPE.TYPE_DISCOUNT,
        }) || {};
      const expenseAmount =
        (
          find(props.poData.supplierPOCOATxList || [], {
            txType: LEDGER_TYPE.TYPE_EXPENSE,
          }) || {}
        ).amount || 0;
      const selectedContact = (props.poData.additionalInfoList || [])[0] || {};
      const supplier = this.state.supplier || {};
      let selectedSupplierTaxIdentifications = [];
      const additionalInfoList = props.poData.additionalInfoList[0] || {};

      if (selectedContact.taxIdentifications) {
        let taxIdentificationsList = JSON.parse(
          selectedContact.taxIdentifications
        );
        selectedSupplierTaxIdentifications = taxIdentificationsList.map(
          (obj, i) => {
            return obj.supplierTaxIdentificationId;
          }
        );
      }
      let rootExpenseList = [];
      let   rootDiscountList = [];

      // props.invoiceData.supplierPOCOATxList.map((ledgerObj) => {
      //   if (ledgerObj.txType === LEDGER_TYPE.TYPE_EXPENSE && ledgerObj.accountingEntry === "credit") {
      //       rootExpenseList.push({
      //           // chartOfAccountDetailsId: ledgerObj.expenseLedgerAccountId,
      //           chartOfAccountDetailsId: ledgerObj.ledgerAccountId,
      //           expenseLedgerAccountName: ledgerObj.ledgerAccountName,
      //           expenseAmount: ledgerObj.amount,  
      //           selectedExpenseLedger: {
      //               taxSingleRatePercent: ledgerObj.taxId,
      //               taxSingleRateId: ledgerObj.taxRate
      //           }
      //       });
      //   }
      // });
      // above code changed to below to remove warnigns
      for (let ledgerObj of props.poData?.supplierPOCOATxList || []) {
        if (
          ledgerObj.txType === LEDGER_TYPE.TYPE_EXPENSE &&
          ledgerObj.accountingEntry === "credit"
        ) {
           let ledger = 
            find(props.allLedgerAccounts || [], {
              chartOfAccountDetailsId: ledgerObj.ledgerAccountId,
            }) || {}
          
          rootExpenseList.push({
            // chartOfAccountDetailsId: ledgerObj.expenseLedgerAccountId,
            chartOfAccountDetailsId: ledgerObj.ledgerAccountId,
            ledgerAccountName: ledger.ledgerAccountName,
            expenseLedgerAccountTaxRate:ledger.taxSingleRatePercent,
            expenseAmount: ledgerObj.amount,
            selectedExpenseLedger: {
              ...ledger
            },
          });
        }
      }

      for (let ledgerObj of props.poData?.supplierPOCOATxList || []) {
        if (
          ledgerObj.txType === LEDGER_TYPE.TYPE_DISCOUNT &&
          ledgerObj.accountingEntry === "credit"
        ) {
          let ledger = 
          find(props.allLedgerAccounts || [], {
            chartOfAccountDetailsId: ledgerObj.ledgerAccountId,
          }) || {}
          rootDiscountList.push({
            // chartOfAccountDetailsId: ledgerObj.expenseLedgerAccountId,
            chartOfAccountDetailsId: ledgerObj.ledgerAccountId,
            ledgerAccountName: ledger.ledgerAccountName,
            expenseAmount: ledgerObj.amount,
            isTaxable:ledgerObj.isTaxable,
            // isTaxable:ledgerObj.isTaxable,
            rootDiscountPercent:ledgerObj.amountPercent,
            rootDiscountAmount:ledgerObj.amount,
            selectedExpenseLedger: {
              ...ledger
            },
          });
        }
      }
      this.setState({
        selectedSupplierTaxIdentifications,
        version: props.poData.version,
        rootExpenseList:rootExpenseList,
        rootDiscountList:rootDiscountList,
        valueSet:true,
        supplier: {
          ...supplier,
          id: props.poData.supplierId,
          supplierId: props.poData.supplierId,
          name: props.poData.supplierName,
          gstNumber: props.poData.gstNumber,
          panNumber: props.poData.panNumber,
        },
        relationshipBillingAddress: find(props.poData.boLocationPOList, {
          locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
        }),
        supplierBillingAddress: find(props.poData.boLocationPOList, {
          locationType: CONSTANTS.BILLING_ADDRESS,
        }),
        selectedContact: (props.poData.additionalInfoList || [])[0] || {},
        supplierPaymentTerm: props.paymentTerms.length
          ? find(props.paymentTerms, {
              paymentTermId: props.poData.paymentTermId,
            }) || {}
          : {},
        update: props.update,
        poMasterId: props.update ? props.poData.poMasterId : 0,
        purchaseOrderId: props.poData.poId,
        rfqChildMasterId: props.poData.rfqChildMasterId,
        rfqMasterId: props.poData.rfqMasterId,
        rfqNumber: props.poData.rfqNumber,
        purchaseRequestNumber: props.poData.purchaseRequestNumber,
        reference: props.poData.reference,
        projectMasterId: props.poData.projectMasterId,
        projectName: props.poData.projectName,
        projectNumber: props.poData.projectNumber,
        internalRemarks: props.poData.remarksInternal,
        supplierRemarks: props.poData.remarksSupplier,
        supplierPONumber: props.poData.supplierPONumber,
        shippingInstructions: props.poData.shippingInstructions,
        footer: props.poData.footer,
        placeOfSupply: props.poData.placeOfSupply,
        isRootDiscount: rootDiscount ? true : false,
        rootDiscountPercent: rootDiscount.amountPercent || 0,
        expenseId: props.poData.expenseId,
        rootDiscountAmount: Number(rootDiscount.amount || 0),
        expenseAmount: Number(expenseAmount),
        isRootExpense: expenseAmount ? true : false,
        poDetailsList: detailList?.sort((a, b) => (a.sNo > b.sNo) ? 1 : -1) || [],
        poDate:
          props.update && props.poData.poDate
            ? new Date(props.poData.poDate)
            : new Date(),
        poDueDate:
          props.update && props.poData.poDueDate
            ? new Date(props.poData.poDueDate)
            : new Date(),
        poNumber: props.update ? props.poData.poNumber : 0,
        conversionPRToPORef: props.poData.conversionPRToPORef || [],
        conversionRFQToPORef: props.poData.conversionRFQToPORef || [],
        oldPayload: props.update ? props.poData : {},
        isPRConversion: props.update ? props.poData.isPRConversion : 0,
        isRFQConversion: props.update ? props.poData.isRFQConversion : 0,
        docName: props.poData.documentName,
        supplierQuoteNumber: props.poData.supplierQuoteNumber,
        docDetailList: props.poData.docDetailList,
        selectedRelationshipTaxIdentifications:
          additionalInfoList.relationshipTaxIdentifications
            ? JSON.parse(additionalInfoList.relationshipTaxIdentifications).map(
                (obj) => {
                  return obj.relationshipTaxIdentificationId;
                }
              )
            : this.state.selectedRelationshipTaxIdentifications,
      });
      if (props.update) {
        this.setState({ nextTxNumber: props.poData.poNumber });
      }
      props.resetPODetail();
    }

    if (
      // props.taxes &&
      // props.taxes.length &&
      props.isTaxesLoaded &&
      this.state.poDraftId &&
      this.state.poDraftData &&
      this.props.supplierDetail.supplierId
    ) {
      const detailList = [];
      const relationshipBillingAddress= find(this.state.poDraftData.boLocationPOList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || {}
      const taxForLedger = this.state.poDraftData.supplierPurchaseOrderDetailList?this.state.poDraftData.supplierPurchaseOrderDetailList[0]?.supplierPOCOATxList?.filter(item=>item.txType ===LEDGER_TYPE.TYPE_TAX):[]
      const isPlaceOfSupplySameAsRelationship = relationshipBillingAddress.stateName === this.state.poDraftData?.placeOfSupply
      taxForLedger.map((item)=>{
           if(props.companyInfo.countryName === "India" && !isPlaceOfSupplySameAsRelationship){
            if(item.accountingEntry === "debit"){
              this.setState({
                igstTaxCreditorsLedgerId:item.ledgerAccountId 
              })

            }else{
              this.setState({
                igstPurchaseTaxLedgerId:item.ledgerAccountId
              })
            }

           }else if(props.companyInfo.countryName === "India" && isPlaceOfSupplySameAsRelationship){
            if(item.accountingEntry === "debit"){
              if(item.taxName ==="CGST"){
                this.setState({
                  cgstTaxCreditorsLedgerId:item.ledgerAccountId,
                  })  
              }else{
                this.setState({
                  sgstTaxCreditorsLedgerId:item.ledgerAccountId
                })

              }

            }else{
              if(item.taxName ==="CGST"){
                this.setState({
                  cgstPurchaseTaxLedgerId:item.ledgerAccountId,
                  })  
              }else{
                this.setState({
                  sgstPurchaseTaxLedgerId:item.ledgerAccountId
                })

              }
              
            }
            
           } 
      })
   let test  = taxForLedger.map((item)=>{
        return {
          ...item,
          amountPercent: item.amountPercent + item.amountPercent
        }
      })
      this.state.poDraftData.supplierPurchaseOrderDetailList &&
        this.state.poDraftData.supplierPurchaseOrderDetailList.map((ele) => {
          const tax =
            find(ele.supplierPOCOATxList, (ele) => {
              return ele.txType === LEDGER_TYPE.TYPE_TAX;
            }) || {};

          const appliedTax =
            find(props.taxes, { taxSingleRateId: Number(tax.taxId) }) || {};
          return detailList.push({
            version: ele.version,
            selectedProductValue: [ele.itemId, ele.inventoryItemVariantId],
            product: ele.itemName
              ? ele.itemName +
                ((ele.itemVariantName && " (" + ele.itemVariantName + ") ") ||
                  "")
              : "",
            productObj: ele.itemName
              ? { itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku }
              : {},
            itemVariantName: ele.itemVariantName,
            variant: {
              attributeId1: ele.attributeId1,
              attributeId2: ele.attributeId2,
              attributeId3: ele.attributeId3,
              attributeName1: ele.attributeName1,
              attributeName2: ele.attributeName2,
              attributeName3: ele.attributeName3,
              attributeValue1: ele.attributeValue1,
              attributeValue2: ele.attributeValue2,
              attributeValue3: ele.attributeValue3,
            },
            description: ele.description,
            scrollHeight: ele.rowHeight,
            discount: (
              find(ele.supplierPOCOATxList, {
                txType: LEDGER_TYPE.TYPE_DISCOUNT,
              }) || {}
            ).amountPercent,
            tax: tax.taxName === "CGST" || tax.taxName === "SGST" ?tax.amountPercent*2 || 0:tax.amountPercent,
            taxApplied: appliedTax.taxNameDisplay,
            taxName: appliedTax.taxName,
            taxId: appliedTax.taxSingleRateId,
            qty: ele.quantity,
            rate: ele.anItemPurchasePrice,
            baseRate: ele.basePrice,
            uom: ele.uomName,
            hsnCode: ele.hsnCode,
            specialDiscount: ele.specialDiscount,
            parentDetailsId: props.update ? ele.parentDetailsId : 0,
          });
        });

      const rootDiscount =
        find(this.state.poDraftData.supplierPOCOATxList || [], {
          txType: LEDGER_TYPE.TYPE_DISCOUNT,
        }) || {};
      const expenseAmount =
        (
          find(this.state.poDraftData.supplierPOCOATxList || [], {
            txType: LEDGER_TYPE.TYPE_EXPENSE,
          }) || {}
        ).amount || 0;
      const selectedContact =
        (this.state.poDraftData.additionalInfoList || [])[0] || {};
      const supplier = this.state.supplier || {};
      let selectedSupplierTaxIdentifications = [];
      if (selectedContact.taxIdentifications) {
        let taxIdentificationsList = JSON.parse(
          selectedContact.taxIdentifications
        );
        selectedSupplierTaxIdentifications = taxIdentificationsList.map(
          (obj, i) => {
            return obj.supplierTaxIdentificationId;
          }
        );
      }

      setTimeout(() => {
        if (this.state.poDraftData) {
          this.setState({
            selectedSupplierTaxIdentifications,
            version: this.props.draftVersion,
            supplier: {
              ...supplier,
              id: this.state.poDraftData.supplierId,
              supplierId: this.state.poDraftData.supplierId,
              name: this.state.poDraftData.supplierName,
              gstNumber: this.state.poDraftData.gstNumber,
              panNumber: this.state.poDraftData.panNumber,
            },
            relationshipBillingAddress: find(
              this.state.poDraftData.boLocationPOList,
              { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }
            ),
            supplierBillingAddress: find(
              this.state.poDraftData.boLocationPOList,
              { locationType: CONSTANTS.BILLING_ADDRESS }
            ),
            selectedContact:
              (this.state.poDraftData.additionalInfoList || [])[0] || {},
            supplierPaymentTerm: props.paymentTerms.length
              ? find(props.paymentTerms, {
                  paymentTermId: this.state.poDraftData.paymentTermId,
                }) || {}
              : {},
            update: props.update,
            poMasterId: props.update ? this.state.poDraftData.poMasterId : 0,
            purchaseOrderId: this.state.poDraftData.poId,
            rfqChildMasterId: this.state.poDraftData.rfqChildMasterId,
            rfqMasterId: this.state.poDraftData.rfqMasterId,
            rfqNumber: this.state.poDraftData.rfqNumber,
            purchaseRequestNumber: this.state.poDraftData.purchaseRequestNumber,
            reference: this.state.poDraftData.reference,
            projectMasterId: this.state.poDraftData.projectMasterId,
            projectName: this.state.poDraftData.projectName,
            projectNumber: this.state.poDraftData.projectNumber,
            internalRemarks: this.state.poDraftData.remarksInternal,
            supplierRemarks: this.state.poDraftData.remarksSupplier,
            supplierPONumber: this.state.poDraftData.supplierPONumber,
            footer: this.state.poDraftData.footer,
            placeOfSupply: this.state.poDraftData.placeOfSupply,
            isRootDiscount: rootDiscount ? true : false,
            rootDiscountPercent: rootDiscount.amountPercent || 0,
            expenseId: this.state.poDraftData.expenseId,
            rootDiscountAmount: Number(rootDiscount.amount || 0),
            expenseAmount: expenseAmount,
            isRootExpense: expenseAmount ? true : false,
            poDetailsList: detailList,
            poDate: this.state.poDraftData.poDate
              ? new Date(this.state.poDraftData.poDate)
              : new Date(),
            poDueDate: this.state.poDraftData.poDueDate
              ? new Date(this.state.poDraftData.poDueDate)
              : new Date(),
            poNumber: props.update ? this.state.poDraftData.poNumber : 0,
            conversionPRToPORef:
              this.state.poDraftData.conversionPRToPORef || [],
            conversionRFQToPORef:
              this.state.poDraftData.conversionRFQToPORef || [],
            shippingInstructions: this.state.poDraftData.shippingInstructions,
            poDraftData: null,
            isRemarksSet: true,
            docName: this.state.poDraftData.documentName,
            supplierQuoteNumber: this.state.poDraftData.supplierQuoteNumber,
            isPRConversion: this.state.poDraftData.isPRConversion
              ? true
              : false,
            isRFQConversion: this.state.poDraftData.isRFQConversion
              ? true
              : false,
          });
        }
      });
    }

    if (
      // props.taxes &&
      // props.taxes.length &&
      props.isTaxesLoaded &&
      props.rfqPoData &&
      props.rfqPoData.rfqMasterId 
    ) {
      const detailList = [];
      const inheritedTxColumn = [];
      const _emptyColumnCheck = (item) => {
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0 &&
          item.itemName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
        }
        if (   
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) <
            0 &&
          item.description
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) < 0 &&
          item.uomName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.UOM);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HSN) < 0 &&
          item.hsnCode
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HSN);
        }
        // if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.BASE_PRICE) < 0 && item.basePrice) {
        //   inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.BASE_PRICE);
        // }
        if (
          inheritedTxColumn.indexOf(
            GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT
          ) < 0 &&
          item.specialDiscount
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT);
        }
      };
   const biddingDetailList = Object.keys(props.biddingSupplierData||{})?.length ? JSON.parse(props.biddingSupplierData?.supplierResponse||"{}")?.rfqDetailsList :[];
   
      (props.rfqPoData.rfqDetailsList || biddingDetailList?.length) &&
        (biddingDetailList?.length?biddingDetailList:props.rfqPoData.rfqDetailsList).forEach((ele) => {
          const appliedTax =
            find(props.taxes, { taxSingleRateId: Number(ele.productTaxId) }) ||
            {};
          _emptyColumnCheck(ele);

          return detailList.push({
            version: ele.version,
            selectedProductValue: [ele.itemId, ele.itemVariantId],
            product: ele.itemName
              ? ele.itemName +
                ((ele.itemVariantName && " (" + ele.itemVariantName + ") ") ||
                  "")
              : "",
            productObj: ele.itemName
              ? { itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku }
              : {},
            itemVariantName: ele.itemVariantName,
            description: ele.description,
            qty: ele.quantity,
            tax: appliedTax.taxPercent || 0,
            taxApplied: appliedTax.taxNameDisplay,
            taxName: appliedTax.taxName,
            taxId: appliedTax.taxSingleRateId,
            rate: ele.anItemPurchasePrice,
            baseRate: ele.anItemPurchasePrice,
            uom: ele.uomName,
            hsnCode: ele.hsnCode,
            specialDiscount: ele.specialDiscount,
            parentDetailsId: ele.rfqDetailsId,
          });
        });

      props.rfqPoData.rfqChildDetailList &&
        props.rfqPoData.rfqChildDetailList.map((ele) => {
          _emptyColumnCheck(ele);
          const appliedTax =
            find(props.taxes, { taxSingleRateId: Number(ele.productTaxId) }) ||
            {};
          return detailList.push({
            version: ele.version,
            selectedProductValue: [ele.itemId, ele.itemVariantId],
            product: ele.itemName
              ? ele.itemName +
                ((ele.itemVariantName && " (" + ele.itemVariantName + ") ") ||
                  "")
              : "",
            productObj: ele.itemName
              ? { itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku }
              : {},
            itemVariantName: ele.itemVariantName,
            variant: {
              attributeId1: ele.attributeId1,
              attributeId2: ele.attributeId2,
              attributeId3: ele.attributeId3,
              attributeName1: ele.attributeName1,
              attributeName2: ele.attributeName2,
              attributeName3: ele.attributeName3,
              attributeValue1: ele.attributeValue1,
              attributeValue2: ele.attributeValue2,
              attributeValue3: ele.attributeValue3,
            },
            description: ele.description,
            qty: ele.quantity,
            tax: appliedTax.amountPercent || 0,
            taxApplied: appliedTax.taxNameDisplay,
            taxName: appliedTax.taxName,
            taxId: appliedTax.taxSingleRateId,
            rate: ele.anItemSalePrice,
            baseRate: ele.basePrice,
            uom: ele.uomName,
            hsnCode: ele.hsnCode,
            specialDiscount: ele.specialDiscount,
            parentDetailsId: ele.rfqDetailsId,
          });
        });

      const forceHideTxColumn = [];

      if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0) {
        forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
      }
      if (
        inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0
      ) {
        forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
      }
      const customerName = props.rfqPoData.customerName;

      this.setState({
        version: props.rfqPoData.version,
        isRFQConversion: true,
        rfqChildMasterId: props.rfqPoData.rfqChildMasterId,
        rfqMasterId: props.rfqPoData.rfqMasterId,
        rfqNumber: props.rfqPoData.rfqNumber,
        customerInquiryNumber: props.rfqPoData.customerInquiryNumber,
        customerPONumber: props.rfqPoData.customerPONumber,
        customerRFQNumber: props.rfqPoData.customerRFQNumber,
        customerId: props.rfqPoData.customerId,
        customerName: props.rfqPoData.customerName,
        customer: { customerId: props.rfqPoData.customerId, customerName },
        purchaseRequestNumber: props.rfqPoData.purchaseRequestNumber,
        reference: props.rfqPoData.refNumber,
        projectMasterId: props.rfqPoData.projectMasterId,
        projectName: props.rfqPoData.projectName,
        projectNumber: props.rfqPoData.projectNumber,
        internalRemarks: props.rfqPoData.remarksInternal,
        supplierRemarks: props.rfqPoData.remarksSupplier,
        footer: props.rfqPoData.footer,
        poDetailsList: detailList?.sort((a, b) => (a.sNo > b.sNo) ? 1 : -1) || [],
        inheritedTxColumn: inheritedTxColumn,
        forceHideTxColumn: forceHideTxColumn,
        tableDataReRendered: true,
      });
      props.resetRfqPoData();
    }

    if (
      // props.taxes &&
      // props.taxes.length &&
      props.isTaxesLoaded &&
      props.poPrData &&
      props.poPrData.purchaseRequestMasterId
    ) {
      const detailList = [];
      const inheritedTxColumn = [];
      const _emptyColumnCheck = (item) => {
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0 &&
          item.itemName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) <
            0 &&
          item.description
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) < 0 &&
          item.uomName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.UOM);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HSN) < 0 &&
          item.hsnCode
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HSN);
        }
        // if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.BASE_PRICE) < 0 && item.basePrice) {
        //   inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.BASE_PRICE);
        // }
        if (
          inheritedTxColumn.indexOf(
            GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT
          ) < 0 &&
          item.specialDiscount
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT);
        }
      };

      props.poPrData.purchaseRequestDetailsList &&
        props.poPrData.purchaseRequestDetailsList.map((ele) => {
          _emptyColumnCheck(ele);
          return detailList.push({
            version: ele.version,
            selectedProductValue: [ele.itemId, ele.inventoryItemVariantId],
            product: ele.itemName
              ? ele.itemName +
                ((ele.itemVariantName && " (" + ele.itemVariantName + ") ") ||
                  "")
              : "",
            variant: {
              attributeId1: ele.attributeId1,
              attributeId2: ele.attributeId2,
              attributeId3: ele.attributeId3,
              attributeName1: ele.attributeName1,
              attributeName2: ele.attributeName2,
              attributeName3: ele.attributeName3,
              attributeValue1: ele.attributeValue1,
              attributeValue2: ele.attributeValue2,
              attributeValue3: ele.attributeValue3,
            },
            productObj: ele.itemName
              ? { itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku }
              : {},
            description: ele.description,
            qty: ele.quantity,
            rate: ele.anItemSalePrice,
            baseRate: ele.basePrice,
            uom: ele.uomName,
            hsnCode: ele.hsnCode,
            specialDiscount: ele.specialDiscount,
            parentDetailsId: ele.purchaseRequestDetailsId,
          });
        });
      const forceHideTxColumn = [];
      if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0) {
        forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
      }
      if (
        inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0
      ) {
        forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
      }
      const customerName = props.poPrData.customerName;

      this.setState({
        version: props.poPrData.version,
        isPRConversion: true,
        purchaseRequestMasterId: props.poPrData.purchaseRequestMasterId,
        purchaseRequestNumber: props.poPrData.purchaseRequestNumber,
        reference: props.poPrData.reference,
        customerInquiryNumber: props.poPrData.customerInquiryNumber,
        customerPONumber: props.poPrData.customerPONumber,
        customerRFQNumber: props.poPrData.customerRFQNumber,
        customerId: props.poPrData.customerId,
        customerName: props.poPrData.customerName,
        customer: { customerId: props.poPrData.customerId, customerName },
        supplierPONumber: props.poPrData.supplierPONumber,
        projectMasterId: props.poPrData.projectMasterId,
        projectName: props.poPrData.projectName,
        projectNumber: props.poPrData.projectNumber,
        internalRemarks: props.poPrData.remarksInternal,
        supplierRemarks: props.poPrData.remarksSupplier,
        footer: props.poPrData.footer,
        poDetailsList: detailList?.sort((a, b) => (a.sNo > b.sNo) ? 1 : -1) || [],
        inheritedTxColumn: inheritedTxColumn,
        forceHideTxColumn: forceHideTxColumn,
        tableDataReRendered: true,
      });
      props.resetPrDataForPO();
    }

    if (
      this.state.stateSupplierId &&
      props.supplierDetail &&
      props.supplierDetail.supplierId 
      // &&
      // props.paymentTerms &&
      // props.paymentTerms.length
    ) {
      const supplierObj = props.supplierDetail;
      const supplierPaymentTerm = find(props.paymentTerms, {
        paymentTermId: supplierObj.paymentTermId,
      });
      const allSupplierBillingAddress = filter(supplierObj.boLocationList, {
        locationType: CONSTANTS.BILLING_ADDRESS,
      });
      const defaultBillingAddress = find(allSupplierBillingAddress, {
        isDefault: 1,
      });
      const selectedContact =
        (supplierObj.boContactList || []).find((x) => x.isPrimaryContact) || {};
      const selectedList =
        (supplierObj &&
          filter(supplierObj.supplierTaxIdentificationsList, {
            populateOnTransaction: 1,
          })) ||
        [];
      let selectedSupplierTaxIdentifications = selectedList.map((obj) => {
        return obj.supplierTaxIdentificationId;
      });
      const isPlaceOfSupplySameAsRelationship =
        (defaultBillingAddress || {}).stateName === this.state.placeOfSupply;
      this.setState({
        stateSupplierId: null,
        supplierAddresses:
          (supplierObj.boLocationList || []).filter(
            (x) => x.locationType === CONSTANTS.TYPE_SHIPPING_ADDRESS
          ) || [],
        supplier: {
          ...supplierObj,
          id: supplierObj.supplierId,
          supplierId: supplierObj.supplierId,
          name: supplierObj.supplierStoreName,
        },
        supplierPaymentTerm:
          this.state.supplierPaymentTerm || supplierPaymentTerm,
        supplierBillingAddress: defaultBillingAddress,
        selectedContact,
        boContactList: supplierObj.boContactList || [],
        selectedSupplierTaxIdentifications,
        allBillingAddress: allSupplierBillingAddress,
        isPlaceOfSupplySameAsRelationship,
      });
    }
    // if (this.props.supplierAddresses) {
    // }
    if (props.addresses) {
      if (
        props.addresses.BillingAddress &&
        props.addresses.BillingAddress.length
      ) {
        this.setState({
          allBillingAddress: props.addresses.BillingAddress,
        });
      }
    }
    if (props.supplierContactList && props.supplierContactList.length) {
      this.setState({
        boContactList: props.supplierContactList,
      });
    }

    if (props.allPreferences && props.allPreferences.length) {
      let txPreferences = find(props.allPreferences, {
        txType: "purchaseOrder",
        templateName: "standard",
      });
      if (txPreferences) {
        let txColumns = txPreferences.gridPreference
          ? (JSON.parse(txPreferences.gridPreference) || {}).columnList || []
          : [];
        txColumns = txColumns.length
          ? txColumns
          : (this.state.txMandatoryColumns || []).concat(
              "product",
              "description"
            );
        const txColumnSetting = txPreferences.gridPreference
          ? (JSON.parse(txPreferences.gridPreference) || {}).columnSetting || {}
          : [];
        const isColumnFitTable =
          Object.keys(txColumnSetting).length ||
          this.state.isColumnFitTable === false
            ? false
            : true;
        let tablePreference = txPreferences.tablePreference;
        this.setState({
          tablePreference,
          isColumnFitTable,
          preferenceId: txPreferences.preferenceId,
          txColumns,
          txColumnSetting,
          txPreferences,
        });
      } else {
        this.setState({
          txColumns: (this.state.txMandatoryColumns || []).concat(
            "product",
            "description"
          ),
        });
      }
    }
  }

  onSearch(text, updateAllOptions, updateFetching) {
    const payload = {
      relationshipId: (this.props || {}).companyInfo.relationshipId,
      pageNumber: 1,
      searchString: text,
      pageSize: 2000,
      updateAllOptions,
      updateFetching,
    };
    this.props.fetchProducts(payload);
  }

  render() {
    return (
      <Fragment>
        {this.state.settingPurchaseDrawerVisible && 
          <SettingPurchaseDrawer
            {...this.state}
            {...this.props}
            updateState={(data) => {
              this.setState(data);
            }}
            updateDrawerState={(data) => { this.setState(data) }}
            />
        }

        <LedgerDrawer
          {...this.state}
          updateState={(data) => {
            this.setState(data);
          }}
          updateDrawerState={(data) => { this.setState(data) }}
        />
        {this.props.ledgerAccountsDrawerVisible || this.state.ledgerAccountsDrawerVisible && (
          <LedgerAccountsDrawer
            {...this.state}
            {...this.props}
            updateState={(data) => {
              this.setState(data);
            }}
            updateDrawerState={(data) => { this.setState(data) }}
          />
        )}
        <StandardPurchaseOrder
          onSearch={(text, updateAllOptions, updateFetching) => {
            this.onSearch(text, updateAllOptions, updateFetching);
          }}
          {...this.props}
          {...this.state}
          openPODetails={this.openPODetails}
          updateState={(data) => {
            this.setState(data);
          }} 
          updateDrawerState={(data) => { this.setState(data) }}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    priceList: state.common.priceList,
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    supplierAddresses: state.supplier.supplierAddresses,
    paymentTerms: state.common.paymentTerms,
    states: state.common.states,
    projectList: state.common.projectList,
    uomList: state.common.uomList,
    rfqPoData: state.purchaseOrder.rfqPoData,
    taxes: state.common.taxes,
    expenseTypes: state.common.expenseTypes,
    products: state.salesInvoice.products,
    documentList: state.common.documentList,
    nextTxNumber: state.purchaseOrder.nextTxNumber,
    allPreferences: state.common.allPreferences,
    allShippingInstruction: state.common.allShippingInstruction,
    remarksData: state.settings.remarksData,
    permissions: state.auth.permissions,
    poData: state.purchaseOrder.purchaseOrderDetail,
    poPrData: state.purchaseRequest.poPrData,
    allRelationshipTaxIdentificatins:
    state.common.allRelationshipTaxIdentificatins,
    supplierSummary: state.supplier.supplierSummary,
    supplierDetail: state.common.supplierDetail,
    addresses: state.supplier.addresses,
    supplierContactList: state.supplier.contacts,
    boRelationshipAddress: state.common.boRelationshipAddress,
    allLedgerAccounts: state.ledger.allLedgerAccounts,
    saveLoading: state.common.saveLoading,
    suppliers: state.supplier.suppliers,
    detailLoading: state.common.detailLoading,
    isTaxesLoaded: state.common.isTaxesLoaded

  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetRfqPoData,
      fetchRfqDataForPo,
      fetchPriceList,
      fetchProjects,
      fetchPaymentTerms,
      addPaymentTerm,
      deletePaymentTerm,
      fetchStates,
      fetchProducts,
      fetchTaxes,
      fetchUOM,
      addUOM,
      deleteUOM,
      fetchExpenseTypes,
      addExpenseTypes,
      deleteExpenseTypes,
      showModal,
      hideModal,
      createPO,
      pushModalToStack,
      popModalFromStack,
      deleteSuppliers,
      deleteProject,
      deleteAddress,
      deleteTax,
      deletePriceList,
      fetchDocuments,
      addDocumentName,
      deleteDocumentName,
      getNextTxNumber,
      deleteContact,
      fetchUserPreferences,
      saveUserPrefrences,
      fetchShippingInstructions,
      addShippingInstruction,
      deleteShippingInstruction,
      fetchRemarksTemplate,
      fetchPurchasedOrderDetail,
      resetRemarksData,
      resetPODetail,
      fetchAllRFQ,
      fetchPRDataForPO,
      resetPrDataForPO,
      fetchRelationshipTaxIdentifications,
      deleteRelationshipTaxIdentifications,
      deleteSupplierTaxIdentifications,
      createPODraft,
      fetchSupplierSummary,
      fetchSupplierDetailsBySupplierId,
      resetSupplierDetail,
      fetchAddresses,
      fetchAllContacts,
      getRelationshipAddress,
      getItemByUPC,
      fetchPurchaseOrders,
      resetPaginatedData,
      fetchAllLedgerAccounts,fetchSuppliers,deleteDocument,
      fetchPurchaseOrderDrafts,getPurchaseOrderDraftCount,fetchPurchaseOrderDrafts
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseInvoice);
