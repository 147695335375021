import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS, CRM_ACTION_LIST } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const fetchLeadActivityTrack = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios 
      .get(`${config.CRM_BASE_URL}${config.API_VERSION}/${config.rootContextCRM}/leadActivity/getAllLeadsActivity?relationshipId=${payload.relationshipId}&crmLeadId=${payload.crmLeadId}`)
      .then(res => {
        dispatch({ type: CRM_ACTION_LIST.LEAD_ACTIVITY_TRACK_LIST, data:res.data});
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}

export const fetchLeadTasks = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.CRM_BASE_URL}${config.API_VERSION}/${config.rootContextCRM}/lead/getLeadTasksById?relationshipId=${payload.relationshipId}&leadId=${payload.crmLeadId}`)
      .then(res => {
        dispatch({ type: CRM_ACTION_LIST.LEAD_TASK_LIST, data: res.data });
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}

export const fetchLeadTasksByUserId = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.CRM_BASE_URL}${config.API_VERSION}/${config.rootContextCRM}/lead/getLeadTasksByAssignedUserId?relationshipId=${payload.relationshipId}&assignedUserId=${payload.assignedUserId}`)
      .then(res => {
        dispatch({ type: CRM_ACTION_LIST.LEAD_TASK_LIST_PAGE, data: res.data });
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      })
  }
}

export const fetchLeadTasksByRid = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.CRM_BASE_URL}${config.API_VERSION}/${config.rootContextCRM}/lead/getLeadTasks?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: CRM_ACTION_LIST.LEAD_TASK_LIST_PAGE, data: res.data });
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      })
  }
}

export const saveCallLogs = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.CRM_BASE_URL}${config.API_VERSION}/${config.rootContextCRM}/leadCallLogs/saveOrUpdateCallLogs`,payload)
      .then(res => {
        dispatch({ type: CRM_ACTION_LIST.LEAD_CALL_LOG, data: res.data });
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}

export const resetCrmLogsdata = () => {
  return dispatch => {
    dispatch({ type: CRM_ACTION_LIST.RESET_LEAD_CALL_LOG });
  }
}