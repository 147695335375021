import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS } from '../../../static/constants';
import { showToasterMessage, getPaths } from '../../../utils';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const createTaxIdentification = (payload, formData, props) => {
  let url = '/relationshipTaxIdentification/createRelationshipTaxIdentification';
  if (payload.customerId) {
    url = '/customerTaxIdentification/createCustomerTaxIdentification';
  } else if (payload.supplierId) {
    url = '/supplierTaxIdentification/createSupplierTaxIdentification';
  }
 const servicePath = getPaths(payload);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
    return axios
      .post(`${servicePath.API_BASE_URL}/${servicePath.rootContext}${url}`, payload)
      .then(res => {
        if (!payload.customerId && !payload.supplierId) {
          dispatch({ type: COMMON_ACTIONS.REL_TAX_IDENTIFICATION_ADDITION, data: res.data })
        }else if (formData.callback) {
          formData.callback(res.data)
        }
        const payload2 = {
          relationshipId: props.companyInfo.relationshipId
        }
        props.fetchSupplierTaxIdentifications(payload2);
        showToasterMessage({
          description: (res || {}).message || 'Created successfully.',
          messageType: 'success'
        })
      })
      .catch((err = {}) => {
        showToasterMessage({
          description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
        })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
      })
  }
}

// export const checktaxIdentificationNumber  = (payload,props) =>{
//   return dispatch => {
//     return axios
//       .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customerTaxIdentification/checktaxIdentificationNumber?relationshipId=${payload.relationshipId}&taxIdentificationNumber=${payload.taxIdentificationNumber}`)
//       .then(res => {
//         const { data } = res
//         if(data){
//           showToasterMessage({messageType: 'error',  description: ' Tax-Identification Number Exists!' })
//         }
//       })
//       .catch((err) => {
//         if(!err.__isRetryRequest){
//           showToasterMessage({messageType: 'error',  description: 'Some error occurred' })
//         }
//       })
//   }
// }

export const checktaxIdentificationNumber  = (payload,props,type,callback) =>{

  let url = '/relationshipTaxIdentification/checktaxIdentificationNumber';
  let rootUrl = config.USERS_BASE_URL + '/' + config.rootContextUsers

  if (type === "customer") {
    rootUrl = config.SALES_BASE_URL + '/' + config.rootContextSales;
    url = '/customerTaxIdentification/checktaxIdentificationNumber';
  } else if (type === "supplier") {
    rootUrl = config.PURCHASE_BASE_URL + '/' + config.rootContextPurchase

    url = '/supplierTaxIdentification/checktaxIdentificationNumber';
  }
  const val =  [payload.taxIdentificationNumber];
  return dispatch => {
    return axios
      .get(`${rootUrl}${url}?relationshipId=${payload.relationshipId}&taxIdentificationNumber=${val}`)
      .then(res => {
        const { data } = res
        if(data){
          callback(data)
          showToasterMessage({messageType: 'error',  description: ' Tax-Identification Number Exists!' })
        }else{
          callback(data)
        }
      })
      .catch((err) => {

        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: 'Some error occurred' })
        }
      })
  }
}