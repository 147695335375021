import axios from 'axios';
import config from '../../config/environmentConfig';
import { COMMON_ACTIONS, SIGN_UP_CONSTANTS } from '../../static/constants';
import { showToasterMessage, } from '../../utils';
const { lang } = require('../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const signIn = (payload) => {
  const requestObj = getSignInRequestObj(payload);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.USERS_BASE_URL}${config.API_VERSION}/${config.rootContextUsers}/clientRelationship/createRelationship`,
        requestObj
      )
      .then(res => {
        showToasterMessage({messageType: "success", description: (res.data || {}).message || "Your account has been created successfully."})
        payload.updateState({isSignupCompleted : true, mailSentTo: maskEmail(requestObj.userList[0].email)})
      })
      .catch(err => {
        showToasterMessage({ description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}

function maskEmail(email) {
  const atIndex = email.indexOf('@');
  if (atIndex <= 1) {
    return email;
  }

  const localPart = email.substring(0, atIndex);
  const maskedLocalPart = localPart.charAt(0) + '*'.repeat(localPart.length - 3) + localPart.charAt(localPart.length - 2) + localPart.charAt(localPart.length - 1);
  return maskedLocalPart + email.substring(atIndex);
}

const getSignInRequestObj = (payload) => {
  const { email, password, contact, country, company,  phoneNumber, timezone } = payload;
  return {
    storeName: company,
    timezone,
    isCompanySetupRequired: SIGN_UP_CONSTANTS.IS_COMPANY_SETUP_REQUIRED,
    hasAccountActivated: SIGN_UP_CONSTANTS.HAS_ACCOUNT_ACTIVATED,
    boLocationList: [
      // {
      //   locationName: SIGN_UP_CONSTANTS.LOCATION_NAME,
      //   // cityName: address.city,
      //   // streetAddress1: address.street,
      //   // streetAddress2: address.streetCont,
      //   // stateName: address.state,
      //   // stateId: address.stateId,
      //   // countryId: country.countryId,
      //   // countryCallingCode: country.countryCallingCode,
      //   // zipCode: address.zip,
      //   // countryName: country.countryName,
      //   locationType: SIGN_UP_CONSTANTS.LOCATION_TYPE,
      //   isDefault: SIGN_UP_CONSTANTS.IS_DEFAULT
      // }
    ],
    userList: [
      {
        email,
        password,
        subscriptionId: SIGN_UP_CONSTANTS.SUBSCRIPTION_ID,
        firstName: (contact.firstName).split(" ")[0],
        lastName:  (contact.firstName).split(" ")[1],
        workPhone: contact.workPhone,
        cellPhone: null,
        contactType: SIGN_UP_CONSTANTS.CONTACT_TYPE,
        relationshipId: SIGN_UP_CONSTANTS.RELATIONSHIP_ID,
        status: SIGN_UP_CONSTANTS.STATUS,
        statusColor: SIGN_UP_CONSTANTS.STATUS_COLOR,
        isTrial: SIGN_UP_CONSTANTS.IS_TRIAL,
      }],
    currencyCode: country.currencyCode + " ",
    currencyIcon: country.currencyIcon,
    countryName: country.countryName,
    countryId: country.countryId,
    countryCallingCode: country.countryCallingCode,
    isDesktop: SIGN_UP_CONSTANTS.IS_DESKTOP,
    // gstNumber: gstNumber, 
    // vatNumber: vatNumber,
    phoneNumber: phoneNumber,
    // faxNumber: faxNumber,
    // website: website,
    // fyStartDate: getCurrentDateForBackend(payload.fyStartDate),
    // fyEndDate: getCurrentDateForBackend(payload.fyEndDate),
    planId: payload.planId,
    planModule: payload.permList,
    // fyStartDay: getPartialDateForBackend(payload.fyStartDate, CONSTANTS.FILTER_DATE_TYPE.START, CONSTANTS.DATE_PART_TYPE.DAY),
    // fyEndDay: getPartialDateForBackend(payload.fyEndDate, CONSTANTS.FILTER_DATE_TYPE.END, CONSTANTS.DATE_PART_TYPE.DAY),
    // fyStartMonth: getPartialDateForBackend(payload.fyStartDate, CONSTANTS.FILTER_DATE_TYPE.START, CONSTANTS.DATE_PART_TYPE.MONTH),
    // fyEndMonth: getPartialDateForBackend(payload.fyEndDate, CONSTANTS.FILTER_DATE_TYPE.END, CONSTANTS.DATE_PART_TYPE.MONTH)
    bankDetails: [],
    costCenterList: [],
    defaultCoaLedgerAccountsList: []
  }
}