import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMMON_ACTIONS, SO_ACTION_LIST, PACKAGE_ACTION_LIST, TX_STATUS } from '../../../../static/constants'
import { showToasterMessage, formatDateForBackend, getCurrentDateForBackend } from '../../../../utils';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchSoDataForPackage = (payload, history) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesOrder/getSODataForPackage?customerId=${payload.customerId}&relationshipId=${payload.relationshipId}&soMasterId=${payload.soMasterId}`)
      .then(res => {
        dispatch({ type: SO_ACTION_LIST.SO_PACKAGE_DATA, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        // if (err.__isRetryRequest) {
        //   err.actionToCall = SO_ACTION_LIST.SO_EMPLOYEES;
        // }
      })
  }
}



export const createPackage = (payload, history) => {
  return dispatch => {
    const props = payload.props;
    const saleOrderPackageData = _getPackageData(payload);
    let formData = new FormData();
    if(props.qualityCheckList && props.qualityCheckList.length){
      props.qualityCheckList.forEach((quality, qualityIndex) => {
        const documentsToUpdate = [];
        if(quality.documentDetails && quality.documentDetails.length){
          quality.documentDetails.forEach((document) => {
            if(document.docDetailsId){
              document.docDetailsId = document.qualityChecklistSoId ?  0: document.docDetailsId;
              documentsToUpdate.push(document);
            }else{
              formData.append('documents', document, qualityIndex +","+document.name);
            }
          });
        }
        quality.documentDetails = documentsToUpdate;
      });
    }
    formData.append('packageData', JSON.stringify(saleOrderPackageData, true));
    let url = saleOrderPackageData?.amendSalesOrderMasterId ? `${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesOrderPackage/createAmendSalesOrderPackage`:`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesOrderPackage/createSalesOrderPackage`
    dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
    return axios
      .post(url, formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then(res => {
        showToasterMessage({ messageType: 'success', description: 'Sales order Package created successfully' });
        dispatch({ type: SO_ACTION_LIST.SO_LIST_PAGE_RESET });
        dispatch({ type: PACKAGE_ACTION_LIST.PACKAGE_LIST_PAGE_RESET });
        if(props.connected){
          props.history.push('/admin/so-list');

        }
        props.fetchSalesOrders(props);
        props.popModalFromStack();
        props.fetchAllPackage(props)
        props.openPackageDetails(res.data);
        
      })
      .catch((err) => {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })       
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
      })
  }
}


const _getPackageData = (payloadObj) => {
  const props = payloadObj.props;
  const agGridObj = payloadObj.agGridObj;
  const companyInfo = props.companyInfo || {};
  const userInfo = props.userInfo || {};
  const rootCOA = [];
  ((props.soPackageData || {}).customerSalesOrderCOATxList || []).forEach((e)=>{
    e.salesOrderMasterId = (props.soPackageData || {}).salesOrderMasterId;
    rootCOA.push(e);
  });

  ((props.amendSoInvoiceData || {}).customerAmendSalesOrderCOATxList || []).forEach((e)=>{
    e.amendSalesOrderMasterId = (props.amendSoInvoiceData || {}).amendSalesOrderMasterId;
    rootCOA.push(e);
  });
  
  const payload = {
    version: props.update ? props.version : 0,
    soPackageMasterId: props.update ? props.soPackageMasterId : 0,
    packageNumber: props.update ? props.packageNumber : undefined,
    relationshipId: companyInfo.relationshipId,
    customerId: (props.soPackageData|| {}).customerId  ||(props.amendSoInvoiceData|| {}).customerId,
    salesOrderMasterId: (props.soPackageData || {}).salesOrderMasterId,
    salesOrderNumber: props.update?(props.soPackageData || {}).salesOrderNumber: (props.soPackageData || {}).soNumber,
    customerPONumber: (props.soPackageData || {}).customerPONumber,
    referenceNumber: (props.soPackageData || {}).referenceNumber,
    inctermId: (props.soPackageData || {}).inctermId || (props.amendSoInvoiceData || {}).inctermId,
    incotermName: (props.soPackageData || {}).incotermName,
    packageDate: formatDateForBackend(props.packageDate),
    deliveryDate: props.deliveryDate && formatDateForBackend(props.deliveryDate),
    salesOrderDate: (props.soPackageData || {}).salesOrderDate || (props.amendSoInvoiceData || {}).salesOrderDate,
    companyName: (props.soPackageData || {}).customerName ||(props.amendSoInvoiceData || {}).customerName,
    packageBy: props.packageBy,
    customerSOPackageDetailsList: [],
    amendSalesOrderMasterId:props.amendSoInvoiceData?.amendSalesOrderMasterId,
    asoNumber:props.amendSoInvoiceData?.asoNumber,
    boLocationPackageList: [],
    remarksInternal: props.remarksInternal,
    remarksCustomer: props.remarksCustomer,
    length: props.length,
    width: props.width,
    height: props.height,
    dimensionUnit: props.dimensionUnit,
    weight: props.weight,
    weightUnit: props.weightUnit,
    measurementUnit: props.measurementUnit,
    customerPackageCOATxList : rootCOA,
    qualityChecklistStatus: props.qualityChecklistStatus || 'not.started',
    createdByUserId: userInfo && userInfo.userId,
    createdByEmpId: userInfo && userInfo.relationshipEmployeeId,
    customerRFQNumber: (props.soPackageData || {}).customerRFQNumber || '',
    customerInquiryNumber: (props.soPackageData || {}).customerInquiryNumber || '',
    projectMasterId:(props.soPackageData || {}).projectMasterId ||(props.amendSoInvoiceData|| {}).projectMasterId
  };

  if (props.billingAddress && props.billingAddress.streetAddress1) {
    // props.billingAddress.dateCreated = getCurrentDateForBackend(new Date());
    if (!props.update) {
      delete props.billingAddress.version;
    }
    payload.boLocationPackageList.push(props.billingAddress);
  }
  if (props.shippingAddress && props.shippingAddress.streetAddress1) {
    if (!props.update) {
      delete props.shippingAddress.version;
    }
   // props.shippingAddress.dateCreated = getCurrentDateForBackend(new Date());
    payload.boLocationPackageList.push(props.shippingAddress);
  }
  if (props.relationshipBillingAddress && props.relationshipBillingAddress.streetAddress1) {
    if (!props.update) {
      delete props.relationshipBillingAddress.version;
    }
  //  props.relationshipBillingAddress.dateCreated = getCurrentDateForBackend(new Date());
    payload.boLocationPackageList.push(props.relationshipBillingAddress);
  }

  let additionalInfo = {};

  if (props.selectedContact && props.selectedContact.firstName) {
    additionalInfo = { ...props.selectedContact }
  }
  if (props.customer) {
    additionalInfo.customerId = props.customer && props.customer.customerId;
    additionalInfo.gstNumber = props.customer.gstNumber;
    additionalInfo.panNumber = props.customer.panNumber;
    additionalInfo.customerPhone = props.customer && props.customer.customerPhone;
    additionalInfo.customerEmail = props.customer && props.customer.customerEmail;
  }
  if (Object.keys(additionalInfo).length) {
    additionalInfo.relationshipId = companyInfo && companyInfo.relationshipId;
  }
  payload.additionalInfoList = [additionalInfo];

  agGridObj.forEachNode((rowNode, index) => {
    const { data } = rowNode;

    if(!data.itemId && !data.description && !data.qtyToPack && !data.anItemSalePrice){
      return
    }

    const itemCOA = [];
    (data.customerSalesOrderCOATxList || []).forEach((e)=>{
      e.salesOrderMasterId = (props.soPackageData || {}).salesOrderMasterId;
      itemCOA.push(e);
    });

    (data.customerAmendSalesOrderCOATxList || []).forEach((e)=>{
      e.amendSalesOrderMasterId = (props.amendSoInvoiceData || {}).amendSalesOrderMasterId;
      itemCOA.push(e);
    });

    payload.customerSOPackageDetailsList.push({
      version: props.update ? data.version : 0,
      itemName: data.itemName,
      description: data.description,
      quantity: Number(data.qtyToPack),
      quantityOrdered: data.quantity,
      qtyBeforeUpdate: Number(data.qtyBeforeUpdate || 0),
      itemId: data.itemId,
      itemVariantName: data.itemVariantName,
      relationshipId: companyInfo.relationshipId,
      itemVariantId: data.inventoryItemVariantId,
      uomName: data.uomName,
      rate: data.anItemSalePrice,
      sku: data.sku,
      warehouse: data.warehouse,
      materialNumber: data.materialNumber,
      stockNumber: data.stockNumber,
      partNumber: data.partNumber,
      origin: data.origin,
      hsCode: data.hsCode,
      attributeId1: data.attributeId1,
      attributeId2: data.attributeId2,
      attributeId3: data.attributeId3,
      attributeName1: data.attributeName1,
      attributeName2: data.attributeName2,
      attributeName3: data.attributeName3,
      attributeValue1: data.attributeValue1,
      attributeValue2: data.attributeValue2,
      attributeValue3: data.attributeValue3,
      salesOrderMasterId: (props.soPackageData || {}).salesOrderMasterId,
      salesOrderDetailsId: data.salesOrderDetailsId,
      amendSalesOrderMasterId:props.amendSoInvoiceData?.amendSalesOrderMasterId,
      amendSalesOrderDetailsId:data.amendSalesOrderDetailsId,
      customerPackageCOATxList : itemCOA,
      customerId: (props.soPackageData || props.amendSoInvoiceData|| {}).customerId,
      itemVariantStockId:data.itemVariantStockId || (props.soPackageData || {}).itemVariantStockId

    });
  });

  if (props.qualityCheckList && props.qualityCheckList.length) {
    let checkList = [];
    props.qualityCheckList.forEach((obj) => {
      obj.customerId = (props.soPackageData || {}).customerId;
      obj.relationshipId = companyInfo && companyInfo.relationshipId;
      obj.salesOrderMasterId = (props.soPackageData || {}).salesOrderMasterId
      checkList.push(obj);
    });
    payload.qualityChecklistPackageList = checkList;
  }else{
    payload.qualityChecklistStatus = TX_STATUS.NOT_REQUIRED;
    payload.qualityChecklistPackageList = [];
  }

  return payload;
}

export const fetchUnpackedSalesOrders = (payload, history) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesOrder/getUnpackedSalesOrders?relationshipId=${payload.relationshipId}&customerId=${payload.customerId || ''}`)
      .then(res => {
        dispatch({ type: SO_ACTION_LIST.SO_UNPACKED_LIST, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        // if (err.__isRetryRequest) {
        //   err.actionToCall = SO_ACTION_LIST.SO_EMPLOYEES;
        // }
      })
  }
}

export const resetSOUnpackedList = () => {
  return dispatch => {
    dispatch({ type: SO_ACTION_LIST.SO_UNPACKED_LIST_RESET });
  }
}

export const getNextTxNumber = (payload) => {
  let date = payload.date ? getCurrentDateForBackend(payload.date) : getCurrentDateForBackend(new Date());
  return dispatch => {
    dispatch({ type: PACKAGE_ACTION_LIST.NEXT_PACKAGE_NUMBER_RESET });
    dispatch({ type: COMMON_ACTIONS.SHOW_DETAIL_LOADER });
    return axios
      .get(`${config.SALES_BASE_URL}${config.API_VERSION}/${config.rootContextSales}/salesOrderPackage/getNextPackageNumber?date=${date}&relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: PACKAGE_ACTION_LIST.NEXT_PACKAGE_NUMBER, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = PACKAGE_ACTION_LIST.NEXT_PACKAGE_NUMBER;
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_DETAIL_LOADER,
        });
      })
  }
}