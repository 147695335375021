import React, { Component, Fragment } from "react";
import { FormattedMessage } from "react-intl";
//import moment from 'moment-timezone';
//import * as find from 'lodash.find';
// import { CustomAgGrid } from '../../../general/CustomAgGrid';
import { ICONS, MODAL_TYPE,MODULE_CODED_VALUES } from "../../../../static/constants";
// import checkIcon from "../../../../assets/images/check.png";
// import crossIcon from "../../../../assets/images/cross.png";
import { Menu, Dropdown, Empty, Skeleton } from "antd";
import { MoreOutlined, DeleteOutlined } from "@ant-design/icons";
import { getPermissionData } from "../../../../utils";

class TaxIdentification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
    };
  }
  state = {
    buttonDropDown: false,
  };

  toggleDropdown = () => {
    const { buttonDropDown } = this.state;
    this.setState({
      buttonDropDown: !buttonDropDown,
    });
  };

  render() {
    const { tax,permissions, supplierTaxIdentifications=[] } = this.props;
    const supplierObj = this.props.supplier || {};
    const primaryPerm = (permissions && permissions.primary) || [];

    const permissionData = getPermissionData(primaryPerm, MODULE_CODED_VALUES.SUPPLIER_COMMUNICATION )
    // const agGridStyle = {
    //   height: '100%',
    //   width: '100%'
    // };

    // const onFirstDataRendered = (params) => {
    //   params.api.sizeColumnsToFit();
    // }
    // const getRowHeight = (params) => {
    //   return 36;
    // }

    const self = this;
    const callback = function (payload) {
      self.props.fetchSupplierTaxIdentifications(payload);
      return;
    };
    const deleteTaxIdentifications = (payload) => {
      const modalBody = <FormattedMessage id="common.delete.confirmation" />;
      const modalData = {
        modalBody,
        handleSubmit: () => {
          this.props.deleteSupplierTaxIdentifications(
            {
              id: payload.supplierTaxIdentificationId,
              relationshipId: payload.relationshipId,
              supplierId: payload.supplierId,
            },
            callback
          );
          this.props.hideModal();
        },
      };
      this.props.showModal(modalData);
    };

    // const columnDefs = [
    //   {
    //     headerName: "#",
    //     resizable: true,
    //     width: 40,
    //     cellRendererFramework: function (link) {
    //       return <div>
    //         {link.rowIndex + 1}
    //       </div>
    //     }
    //   },

    //   {
    //     headerComponentFramework: () => {
    //       return <FormattedMessage id='modal.common.taxIdentification.taxType' defaultMessage='' />;
    //     },
    //     field: "taxIdentificationType",
    //     resizable: true,
    //     width: 130,
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return <FormattedMessage id='modal.common.taxIdentification.number' defaultMessage='' />;
    //     },
    //     field: "taxIdentificationNumber",
    //     resizable: true,
    //     width: 300,
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return <FormattedMessage id='modal.common.taxIdentification.populateOnTransaction' defaultMessage='' />;
    //     },
    //     field: "populateOnTransaction",
    //     resizable: true,
    //     width: 110,
    //     cellRendererFramework: (params) => {
    //       return params.node.data.populateOnTransaction ? <img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Invoice" /> : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Invoice" />;
    //   },
    //   },
    //   {
    //     field: 'action',
    //     lockPosition: true,
    //     pinned: 'right',
    //     width: 50,
    //     cellRendererFramework: (params) => <div>
    //       <span className='cursor-pointer' onClick={() => deleteTaxIdentifications(params.data)}>
    //         <i className="fa fa-trash" />
    //       </span>
    //     </div>
    //   }
    // ]

    // const columnDefs = [
    //   {
    //     title: "#",
    //     dataIndex: "rowIndex",
    //     key: "rowIndex",
    //     render:(text, record, index)=> <div>
    //         {index + 1}
    //       </div>,
    //     width: 40,
    //   },
    //   {
    //     title: <FormattedMessage id='modal.common.taxIdentification.taxType' defaultMessage='' />,
    //     dataIndex: "taxIdentificationType",
    //     key: "taxIdentificationType",
    //     width: 130,
    //   },
    //   {
    //     title: <FormattedMessage id='modal.common.taxIdentification.number' defaultMessage='' />,
    //     dataIndex: "taxIdentificationNumber",
    //     key: "taxIdentificationNumber",
    //     width: 300,
    //   },
    //   {
    //     title: <FormattedMessage id='modal.common.taxIdentification.populateOnTransaction' defaultMessage='' />,
    //     dataIndex: "populateOnTransaction",
    //     key: "populateOnTransaction",
    //     width: 110,
    //     render: (populateOnTransaction) => {
    //       return populateOnTransaction ? <img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Invoice" /> : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Invoice" />;
    //     },
    //   },
    //   {
    //     title: <FormattedMessage id='customer.listing.header.action' defaultMessage='' />,
    //     dataIndex: 'action',
    //     key: 'action',
    //     width: 70,
    //     render: (text, record, index) => <div>
    //       <span className='cursor-pointer' onClick={() => deleteTaxIdentifications(record)}>
    //         <i className="fa fa-trash" />
    //       </span>
    //     </div>
    //   }
    // ]
    // const { buttonDropDown } = this.state;
    // const { toggleDropdown } = this;

    // const onGridReady = (params) => {
    //   this.gridApi = params.api;
    // }

    const handleNewTaxAddition = (tax) => {
      this.props.updateHeaderState({
        tax: {
          taxDrawer: true,
          formData: {
            isUpdate:tax? true:false,
            ...tax,
            supplierId: this.props.supplierId,
          },
        },
      });
    };

    const actionMenu = (e) => {
      return (
        <Menu className="row-action-dropdown">
          <Menu.Item key="0" value="title">
            Actions
          </Menu.Item>

          <Menu.Divider />
          <Menu.Item
            key="1"
            onClick={() => {
              handleNewTaxAddition(e);
            }}
          >
            <i className={ICONS.EDIT} ></i>
            Edit
          </Menu.Item>
          <Menu.Item
            key="2"
            onClick={() => {
              deleteTaxIdentifications(e);
            }}
          >
            <i className={ICONS.DELETE} />
            Delete
          </Menu.Item>
        </Menu>
      );
    };
    return (
      <>
        {/* <div className="view-top-bar">
          <div className="top-view-left">
            <div className="page-heading">Tax Identification</div>
          </div>
          <div className="top-view-right">
            <button
              className="create-action-btn"
              onClick={() => {
                let self = this;
                const formData = {
                  supplierId: Number(this.props.supplierId),
                };
                const { showModal, companyInfo } = this.props;
                formData.relationshipId = companyInfo.relationshipId;
                formData.callback = function (taxIdentification) {
                  self.props.fetchSupplierDetailsBySupplierId({
                    supplierId: Number(self.props.supplierId),
                    relationshipId: companyInfo.relationshipId,
                  });
                  return;
                };
                const data = {
                  title: (
                    <FormattedMessage id="addItem.text.taxIdentification" />
                  ),
                  formData,
                  hideFooter: true,
                  modalData: {
                    modalType: MODAL_TYPE.NEW_TAX_IDENTIFICATION,
                    data: { formData },
                  },
                  handleSubmit: (formData = {}) => {
                    data.formData.submittedOnce = true;
                    this.props.hideModal(data);
                  },
                };
                showModal(data);
              }}
            >
              <i className={ICONS.ADD} />{" "}
              <FormattedMessage id="button.create.label" defaultMessage="" />
              <FormattedMessage id="new" />{" "}
              <FormattedMessage id="addItem.text.taxIdentification" />
            </button>
          </div>
        </div> */}

        {/* <div className='agGridWrapper'>
            <div className="ag-theme-balham" style={agGridStyle} >
              <CustomAgGrid
                columnDefs={columnDefs}
                rowData={supplierObj.supplierTaxIdentificationsList || []}
                rowDragManaged={true}
                domLayout={"autoHeight"}
                animateRows={true}
                onGridSizeChanged={onFirstDataRendered}
                getRowHeight={getRowHeight}
                editType={'fullRow'}
                onGridReady={onGridReady}
              >
              </CustomAgGrid>
            </div>
          </div> */}
        <div className="view-container">
          <div className="view-container-actions">
            <div className="left-actions">
              <div className="table-heading">Tax Identification </div>
              {supplierTaxIdentifications ? (
                <>
                  <div className="vertical-sep" />
                  <div>{supplierTaxIdentifications.length}</div>
                </>
              ) : (
                ""
              )}
            </div>
            <div className="right-actions">
              <button
                className="create-action-btn"
                style={{
                  backgroundColor: "#1cb961",
                  color: "#ffffff",
                  fontSize: "14px",
                }}
                onClick={() => {
                  handleNewTaxAddition();
                }}
              >
                <i className={`${ICONS.ADD} mr5`} />
                <FormattedMessage id="button.create.label" defaultMessage="" />
              </button>
            </div>
          </div>

          <Skeleton loading={this.props.listLoading} active paragraph={{ rows: 6 }}>
            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    <th style={{ width: "10%" }}>S.No</th>
                    <th>
                      <FormattedMessage
                        id="modal.common.taxIdentification.taxType"
                        defaultMessage=""
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="modal.common.taxIdentification.number"
                        defaultMessage=""
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="modal.common.taxIdentification.populateOnTransaction"
                        defaultMessage=""
                      />
                    </th>
                    <th style={{textAlign:'center'}}>
                      <FormattedMessage
                        id="customer.listing.header.action"
                        defaultMessage=""
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {(supplierTaxIdentifications || []).length > 0
                    ? supplierTaxIdentifications.sort((a, b) => (a.supplierTaxIdentificationId < b.supplierTaxIdentificationId ? 1 : -1)).map((rowData, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{rowData.taxIdentificationType}</td>
                          <td>{rowData.taxIdentificationNumber}</td>
                          <td>
                            {rowData.populateOnTransaction ? "Yes" : "No"}
                          </td>
                          <td style={{textAlign:'center'}}>
                            <div className="action-icon">
                              <Dropdown
                                overlay={actionMenu(rowData)}
                                trigger={["click"]}
                              >
                                <i className={ICONS.MORE_P} />
                              </Dropdown>
                            </div>
                          </td>
                        </tr>
                      ))
                    :  
                    <tr key="empty-data">
                      <td colSpan={"100%"} style={{borderBottom:"none"}}>
                        <Empty />
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          </Skeleton>
        </div>
      </>
    );
  }
}

export default TaxIdentification;
