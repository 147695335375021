import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { GST_MECHANISM_OPTIONS, ICONS, MODAL_TYPE } from '../../../static/constants';
//import { UploadOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Col, Row, Tabs, Checkbox, Button } from 'antd';
import './customerDrawer.scss';
import { TextBox } from '../../general/TextBox';
import { LabelBox } from '../../general/LabelBox';
import { Dropdown, DropdownRef } from '../../general/Dropdown';
import { ErrorMsg } from '../../general/ErrorMessage';
// import { CustomAgGrid } from '../../general/CustomAgGrid';
import { showToasterMessage, validateEmail } from '../../../utils';
import { getLabelName } from '../../modal/modalBody/customerInfo';
import { checkCodeRanges } from '../../general/stateCode';

const { TabPane } = Tabs;


function CustomerTabs(props) {
    let {
        countries,
        updateDrawerState,
        fetchStates,
        billingAddress,
        shippingAddressSameAsBilling,
        shippingAddress,
        contact,
        allTaxIdentificatinType,
        gstMechanism,
        // gstNumber,
        // panNumber,
        salutations,
        locatedInSez,
        states,
        intl,
        //  props2,
        submittedOnce,
        invalidBilligStreet1Class,
        invalidBilligCityClass,
        invalidBilligStateClass,
        invalidShippingStreet1Class,
        invalidShippingCityClass,
        invalidShippingStateClass,
        isUpdate,
        customerTaxIdentificationsList = [{}],
        gstDetails,
    } = props;
    const addItemConfirmationModal = (props, text, modalType) => {
        const { showModal, companyInfo } = props;
        //const labelName = <FormattedMessage id='addItem.text.taxIdentification' defaultMessage='' />;
        const labelName = getLabelName(modalType);
        const modalBody = <span>
            <FormattedMessage
                id='addItem.confirmation'
                values={{ value: `${text} to ` }}
                defaultMessage=''
            />
            {labelName} ?
        </span>;

        let addFunc;

        switch (modalType) {
            case MODAL_TYPE.TAX_IDENTIFICATION: {
                addFunc = props.addTaxIdentification;
                break;
            }
            case MODAL_TYPE.SALUTATION: {
                addFunc = props.addSalutation;
                break;
            }
            default: {
                addFunc = () => { }
            }
        }

        const modalData = {
            modalBody,
            handleSubmit: () => {
                addFunc({
                    text: text, relationshipId: companyInfo.relationshipId, callback: (data) => {
                        allTaxIdentificatinType.unshift(data);
                    }
                }, props)
                props.hideModal();
            },
        };
        showModal(modalData);
    }

    const getModalTitle = (newTextObj, modalType) => {
        let title;

        switch (modalType) {
            case MODAL_TYPE.TAX_IDENTIFICATION: {
                title = <FormattedMessage id='addItem.text.taxIdentification' defaultMessage='' />;
                break;
            }
            case MODAL_TYPE.SALUTATION: {
                title = <FormattedMessage id='addItem.text.salutation' defaultMessage='' />;
                break;
            }
            default:
                title = <FormattedMessage id='signup.confirmation.title' defaultMessage='' />;
        }
        return title;
    }

    const handleNewItemAddition = (props, payload, modalType) => {
        const formData = payload.formData;
        const { showModal } = props;

        if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
            addItemConfirmationModal(props, formData.textBoxValue, modalType);
        } else {
            const data = {
                title: getModalTitle(payload, modalType),
                formData,
                modalData: { modalType, data: payload },
                handleSubmit: (formData = {}) => {
                    if (formData.textBoxValue && formData.textBoxValue.length) {
                        addItemConfirmationModal(props, formData.textBoxValue, modalType);
                    } else {
                        data.formData.submittedOnce = true;
                        showModal(data);
                    }
                }
            };
            showModal(data);
        }
    }

    const deleteClickHandler = (props, modalType, payload) => {
        const { showModal, companyInfo } = props;
        const modalBody =
            <FormattedMessage
                id='deleteItem.confirmation'
                defaultMessage=''
                values={{ value: payload.text }}
            />;

        let deleteFunc;

        switch (modalType) {
            case MODAL_TYPE.TAX_IDENTIFICATION: {
                deleteFunc = props.deleteTaxIdentifications;
                break;
            }
            case MODAL_TYPE.SALUTATION: {
                deleteFunc = props.deleteSalutation;
                break;
            }
            default: {
                deleteFunc = () => { }
            }
        }

        const modalData = {
            modalBody,
            handleSubmit: () => {
                deleteFunc({
                    id: payload.id, relationshipId: companyInfo.relationshipId, callback: (data) => {
                        let allTaxes = props.allTaxIdentificatinType;
                        allTaxes = allTaxes.filter((e) => {
                            return e.taxIdentificationId !== data.id;
                        });

                        allTaxIdentificatinType = allTaxes;
                    }
                })
                props.hideModal();
            },
        };
        showModal(modalData);
    }

    // const onGridReady = (params) => {
    //     updateDrawerState({ gridApi: params.api });
    //     params.api.sizeColumnsToFit();
    // }
    // const columnDefs = [
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='drawer.customer.taxInfo.taxType' defaultMessage='' />;
    //         },
    //         resizable: true,
    //         field: 'taxIdentificationType',
    //         editable: true,
    //         sortable: true,
    //         cellEditor: 'customDropDownEditor',
    //         cellEditorParams: (obj) => {
    //             return {
    //                 lastColumnCell: false,
    //                 items: allTaxIdentificatinType,
    //                 optionKeyName: 'taxIdentificationId',
    //                 valueKeyName: 'type',
    //                 canAddNew: true,
    //                 canDelete: true,
    //                 defaultValue: obj.node.data.taxIdentificationType,
    //                 deleteClickHandler: (payload) => {
    //                     deleteClickHandler(props, MODAL_TYPE.TAX_IDENTIFICATION, payload);
    //                 },
    //                 onAddNewClick: (payload) => {
    //                     payload.formData = {
    //                         textBoxValue: payload.textEntered,
    //                         submittedOnce: false
    //                     }
    //                     handleNewItemAddition(props, payload, MODAL_TYPE.TAX_IDENTIFICATION)
    //                 },
    //                 onSelect: (selectedValue, option) => {
    //                     obj.node.data.taxIdentificationType = selectedValue;
    //                 },
    //             }
    //         },
    //         cellClass: 'variant-column',
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='drawer.customer.taxInfo.taxNumber' defaultMessage='' />;
    //         },
    //         field: 'taxIdentificationNumber',
    //         editable: true,
    //         sortable: true,
    //         cellEditor: 'customTextEditor',
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div>
    //                 <FormattedMessage id='drawer.customer.taxInfo.populateOnTransaction' defaultMessage='' />
    //                 <Tooltip placement="top" title={intl.formatMessage(
    //                     {
    //                         id: 'tooltip.taxDisplayOnPdf',
    //                         defaultMessage: ''
    //                     }
    //                 )} trigger="click">
    //                     <i className="fa fa-question-circle-o pl5 cursor-pointer" />
    //                 </Tooltip>
    //             </div>
    //         },
    //         field: "populateOnTransaction",
    //         resizable: true,
    //         editable: true,
    //         cellRendererFramework: function (link) {
    //             return <div>
    //                 {link.value ? "Yes" : "No"}
    //             </div>
    //         },
    //         cellEditor: 'customCheckboxEditor'
    //     },
    //     {
    //         pinned: 'right',
    //         cellRenderer: 'customActionEditor',
    //         suppressNavigable: true,
    //         cellRendererParams: (params) => {
    //             return {
    //                 onClick: () => {
    //                     params.api.updateRowData({ remove: [params.api.getDisplayedRowAtIndex(params.node.rowIndex).data] });
    //                     let rowData = [];
    //                     params.api.forEachNode(node => rowData.push(node.data));
    //                     updateDrawerState({ customerTaxIdentificationsList: rowData });
    //                 },
    //             }
    //         },
    //         width: 70
    //     }]

    return (
        <Tabs type="line">
            {!isUpdate &&
                <TabPane tab={intl.formatMessage({ id: 'signup.tab.address', defaultMessage: '' })} key="1">
                    <Row gutter={[16, 24]}>
                        <Col span={12}>
                            <strong>
                                <FormattedMessage id='drawer.customer.billingAddress' defaultMessage='' />
                            </strong>
                        </Col>

                        <Col span={12}>
                            <Checkbox
                                checked={shippingAddressSameAsBilling}
                                onClick={(e) => {
                                    updateDrawerState({
                                        shippingAddressSameAsBilling: e.target.checked ? 1 : 0
                                    });
                                    if (e.target.checked) {
                                        updateDrawerState({
                                            shippingAddress: { ...billingAddress }
                                        });
                                    } else {
                                        updateDrawerState({
                                            shippingAddress: {
                                                streetAddress1: undefined,
                                                streetAddress2: undefined,
                                                cityName: undefined,
                                                stateName: undefined,
                                                zipCode: undefined,
                                                locationName: undefined,
                                                countryName: undefined
                                            }
                                        });
                                    }

                                }}
                            >
                                <FormattedMessage id='drawer.customer.shippingAndBillingAddressSame' defaultMessage='' />
                            </Checkbox>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Row gutter={[8, 8]}>

                                <Col span={12}>
                                    <TextBox
                                        placeholder={intl.formatMessage(
                                            { id: 'drawer.customer.address.streetName.placeholder', defaultMessage: '' }
                                        )}
                                        value={billingAddress.streetAddress1}
                                        type='text'
                                        maxLength={45}
                                        countStyle={{ display: "none" }}
                                        onChange={(e) => {
                                            updateDrawerState({
                                                billingAddress: { ...billingAddress, streetAddress1: e.target.value },
                                                shippingAddress: shippingAddressSameAsBilling ? { ...billingAddress, streetAddress1: e.target.value } : { ...shippingAddress }
                                            });
                                        }}
                                    />
                                    <ErrorMsg
                                        validator={() => { return !(submittedOnce && invalidBilligStreet1Class) || !!billingAddress.streetAddress1 }}
                                        message={intl.formatMessage(
                                            { id: 'drawer.customer.validation.billingStreet1Name', defaultMessage: '' }
                                        )}
                                    />
                                </Col>

                                <Col span={12}>
                                    <TextBox
                                        placeholder={intl.formatMessage(
                                            { id: 'drawer.customer.address.streetContd.placeholder', defaultMessage: '' }
                                        )}
                                        maxLength={45}
                                        countStyle={{ display: "none" }}
                                        value={billingAddress.streetAddress2}
                                        type='text'
                                        onChange={(e) => {
                                            updateDrawerState({
                                                billingAddress: { ...billingAddress, streetAddress2: e.target.value },
                                                shippingAddress: shippingAddressSameAsBilling ? { ...billingAddress, streetAddress2: e.target.value } : { ...shippingAddress }

                                            });
                                        }}
                                    />
                                </Col>

                                <Col span={12}>
                                    <TextBox
                                        placeholder={intl.formatMessage(
                                            { id: 'drawer.customer.address.city.placeholder', defaultMessage: '' }
                                        )}
                                        type='text'
                                        maxLength={25}
                                        countStyle={{ display: "none" }}
                                        value={billingAddress.cityName}
                                        onChange={(e) => {
                                            updateDrawerState({
                                                billingAddress: { ...billingAddress, cityName: e.target.value },
                                                shippingAddress: shippingAddressSameAsBilling ? { ...billingAddress, cityName: e.target.value } : { ...shippingAddress }

                                            });
                                        }}
                                    />
                                    <ErrorMsg
                                        validator={() => { return !(submittedOnce && invalidBilligCityClass) || !!billingAddress.cityName }}
                                        message={intl.formatMessage(
                                            { id: 'drawer.customer.validation.billingCityName', defaultMessage: '' }
                                        )}
                                    />
                                </Col>

                                <Col span={12}>
                                    <Dropdown
                                        items={countries}
                                        valueKeyName='countryName'
                                        optionKeyName='countryId'
                                        value={billingAddress.countryName}
                                        placeholder={intl.formatMessage({
                                            id: 'drawer.customer.address.country.placeholder', defaultMessage: ''
                                        })}
                                        onSelect={(optionValue, elem) => {
                                            updateDrawerState({
                                                billingAddress: {
                                                    ...billingAddress,
                                                    stateName: null,
                                                    countryName: (countries.find(x => x.countryId === +elem.key) || {}).countryName
                                                },
                                                shippingAddress: shippingAddressSameAsBilling ? {
                                                    ...billingAddress,
                                                    countryName: (countries.find(x => x.countryId === +elem.key) || {}).countryName,
                                                    stateName: null
                                                } : {
                                                    ...shippingAddress
                                                }
                                            });
                                            fetchStates(elem.key);
                                        }}
                                    />
                                </Col>

                                <Col span={12}>
                                    <TextBox
                                        placeholder={intl.formatMessage(
                                            { id: 'drawer.customer.address.state.placeholder', defaultMessage: '' }
                                        )}
                                        type='text'
                                        maxLength={45}
                                        countStyle={{display:"none"}}
                                        className={((billingAddress.countryName === 'India' || billingAddress.countryName === 'United States' || billingAddress.countryName === 'United States (USA)') ? ' hide' : '')}
                                        value={billingAddress.stateName}
                                        onChange={(e) => {
                                            updateDrawerState({
                                                billingAddress:
                                                    { ...billingAddress, stateName: e.target.value },
                                                shippingAddress: shippingAddressSameAsBilling ? {
                                                    ...billingAddress, stateName: e.target.value
                                                } : {
                                                    ...shippingAddress
                                                }
                                            })
                                        }}
                                    />                                 

                                    <Dropdown
                                        style={{ width: "100%" }}
                                        items={states}
                                        valueKeyName='stateName'
                                        optionKeyName='stateId'
                                        value={billingAddress.stateName}
                                        className={invalidBilligStateClass + ' ' + ((billingAddress.countryName !== 'India' && billingAddress.countryName !== 'United States' && billingAddress.countryName !== 'United States (USA)') ? ' hide' : '')}
                                        placeholder={intl.formatMessage({
                                            id: 'drawer.customer.address.state.placeholder', defaultMessage: ''
                                        })}
                                        onSelect={(optionValue, elem) => {
                                            updateDrawerState({
                                                billingAddress:
                                                    { ...billingAddress, stateName: elem.name },
                                                shippingAddress: shippingAddressSameAsBilling ? {
                                                    ...billingAddress, stateName: elem.name
                                                } : {
                                                    ...shippingAddress
                                                }
                                            });
                                        }}
                                    />
                                    <ErrorMsg
                                        validator={() => { return !(submittedOnce && invalidBilligStateClass && billingAddress.countryName === 'India') || !!billingAddress.stateName }}
                                        message={intl.formatMessage(
                                            { id: 'drawer.customer.validation.billingStateName', defaultMessage: '' }
                                        )}
                                    />
                                </Col>


                                <Col span={12}>
                                    <Form.Item>
                                        <TextBox
                                            placeholder={intl.formatMessage(
                                                { id: 'drawer.customer.address.zipCode.placeholder', defaultMessage: '' }
                                            )}
                                            maxLength={25}
                                            countStyle={{ display: "none" }}
                                            type='text'
                                            value={billingAddress.zipCode}
                                            onChange={(e) => {
                                                updateDrawerState({
                                                    billingAddress: { ...billingAddress, zipCode: e.target.value },
                                                    shippingAddress: shippingAddressSameAsBilling ? { ...billingAddress, zipCode: e.target.value } : { ...shippingAddress }

                                                });
                                            }}
                                        />
                                    </Form.Item>
                                    {/* <ErrorMsg
                                        validator={() => { return !(submittedOnce && !(( billingAddress?.countryName === "India" && (!!billingAddress?.stateName) &&checkCodeRanges(billingAddress?.stateName , billingAddress?.zipCode , true))) ) }}
                                        message={"Invalid Zipcode"}
                                    /> */}
                                </Col>

                            </Row>
                        </Col>

                        <Col span={12}>
                            <Row gutter={[8, 8]}>

                                <Col span={12}>
                                    <TextBox
                                        placeholder={intl.formatMessage(
                                            { id: 'drawer.customer.address.streetName.placeholder', defaultMessage: '' }
                                        )}
                                        maxLength={45}
                                        countStyle={{ display: "none" }}

                                        value={shippingAddress.streetAddress1}
                                        disabled={shippingAddressSameAsBilling ? true : false}
                                        type='text'
                                        onChange={(e) => {
                                            updateDrawerState({
                                                shippingAddress: { ...shippingAddress, streetAddress1: e.target.value }

                                            });
                                        }}
                                    />
                                    <ErrorMsg
                                        validator={() => { return !(submittedOnce && invalidShippingStreet1Class) || !!shippingAddress.streetAddress1 || shippingAddressSameAsBilling }}
                                        message={intl.formatMessage(
                                            { id: 'drawer.customer.validation.shippingStreet1Name', defaultMessage: '' }
                                        )}
                                    />
                                </Col>

                                <Col span={12}>
                                    <TextBox style={{ height: '38px' }}
                                        className='text-input-box'
                                        placeholder={intl.formatMessage(
                                            { id: 'drawer.customer.address.streetContd.placeholder', defaultMessage: '' }
                                        )}
                                        maxLength={45}
                                        countStyle={{ display: "none" }}

                                        value={shippingAddress.streetAddress2}
                                        disabled={shippingAddressSameAsBilling ? true : false}
                                        type='text'
                                        onChange={(e) => {
                                            updateDrawerState({
                                                shippingAddress: { ...shippingAddress, streetAddress2: e.target.value }

                                            });
                                        }}
                                    />
                                </Col>

                                <Col span={12}>
                                    <TextBox
                                        placeholder={intl.formatMessage(
                                            { id: 'drawer.customer.address.city.placeholder', defaultMessage: '' }
                                        )}
                                        type='text'
                                        maxLength={25}
                                        countStyle={{ display: "none" }}
                                        value={shippingAddress.cityName}
                                        disabled={shippingAddressSameAsBilling ? true : false}
                                        onChange={(e) => {
                                            updateDrawerState({
                                                shippingAddress: { ...shippingAddress, cityName: e.target.value }

                                            });
                                        }}
                                    />
                                    <ErrorMsg
                                        validator={() => { return !(submittedOnce && invalidShippingCityClass) || !!shippingAddress.cityName || shippingAddressSameAsBilling }}
                                        message={intl.formatMessage(
                                            { id: 'drawer.customer.validation.shippingCityName', defaultMessage: '' }
                                        )}
                                    />
                                </Col>

                                <Col span={12}>
                                    <Dropdown
                                        items={countries}
                                        valueKeyName='countryName'
                                        value={shippingAddress.countryName}
                                        disabled={shippingAddressSameAsBilling ? true : false}
                                        optionKeyName='countryId'
                                        placeholder={intl.formatMessage({
                                            id: 'drawer.customer.address.country.placeholder', defaultMessage: ''
                                        })}
                                        onSelect={(optionValue, elem) => {
                                            updateDrawerState({
                                                shippingAddress: {
                                                    ...shippingAddress,
                                                    stateName: null,
                                                    countryName: (countries.find(x => x.countryId === +elem.key) || {}).countryName
                                                }
                                            });
                                            fetchStates(elem.key);
                                        }}
                                    />
                                </Col>

                                <Col span={12}>
                                    <TextBox
                                        placeholder={intl.formatMessage(
                                            { id: 'drawer.customer.address.state.placeholder', defaultMessage: '' }
                                        )}
                                        type='text'
                                        maxLength={45}
                                        countStyle={{ display: "none" }}
                                        value={shippingAddress.stateName}
                                        disabled={shippingAddressSameAsBilling ? true : false}
                                        className={((shippingAddress.countryName === 'India' || shippingAddress.countryName === 'United States' || shippingAddress.countryName === 'United States (USA)') ? ' hide' : '')}
                                        onChange={(e) => {
                                            updateDrawerState({ shippingAddress: { ...shippingAddress, stateName: e.target.value } })
                                        }}
                                    />
                                    <Dropdown
                                        style={{ width: "100%" }}
                                        items={states}
                                        valueKeyName='stateName'
                                        optionKeyName='stateId'
                                        value={shippingAddress.stateName}
                                        disabled={shippingAddressSameAsBilling ? true : false}
                                        className={invalidShippingStateClass + ' ' + ((shippingAddress.countryName !== 'India' && shippingAddress.countryName !== 'United States' && shippingAddress.countryName !== 'United States (USA)') ? ' hide' : '')}
                                        placeholder={intl.formatMessage({
                                            id: 'drawer.customer.address.state.placeholder', defaultMessage: ''
                                        })}
                                        onSelect={(optionValue, elem) => {
                                            updateDrawerState({ shippingAddress: { ...shippingAddress, stateName: elem.name } });
                                        }}
                                    />
                                    <ErrorMsg
                                        validator={() => { return !(submittedOnce && invalidShippingStateClass && shippingAddress.countryName === 'India') || !!shippingAddress.stateName || shippingAddressSameAsBilling }}
                                        message={intl.formatMessage(
                                            { id: 'drawer.customer.validation.shippingStateName', defaultMessage: '' }
                                        )}
                                    />
                                </Col>

                                <Col span={12}>
                                    <TextBox
                                        placeholder={intl.formatMessage(
                                            { id: 'drawer.customer.address.zipCode.placeholder', defaultMessage: '' }
                                        )}
                                        type='text'
                                        maxLength={25}
                                        countStyle={{ display: "none" }}
                                        value={shippingAddress.zipCode}
                                        disabled={shippingAddressSameAsBilling ? true : false}
                                        onChange={(e) => {
                                            updateDrawerState({
                                                shippingAddress: { ...shippingAddress, zipCode: e.target.value }
                                            });
                                        }}
                                    />
                                </Col>

                            </Row>
                        </Col>
                    </Row>

                    <div className="billing-address-information">
                        <strong><FormattedMessage id='drawer.customer.address.note.text' defaultMessage='' />:</strong>
                        <ul>
                            <li><FormattedMessage id='drawer.customer.address.note.message1' defaultMessage='' /></li>
                            {/* <li><FormattedMessage id='drawer.customer.address.note.message2' defaultMessage='' /></li> */}
                        </ul>
                    </div>
                </TabPane>
            }

            {!isUpdate && !(props.customerType === 'individual') ?
                <TabPane tab="Contact" key="2">
                    <Row gutter={[16, 16]}>
                        {/* <Col span={12}>
                            <div className='i-label'>
                                Salutation
                            </div>
                            <Dropdown
                                items={salutations}
                                valueKeyName='titleName'
                                value={contact.salutationName}
                                canAddNew={true}
                                canDelete={true}
                                optionKeyName='salutationId'
                                deleteClickHandler={(payload) => {
                                    deleteClickHandler(props, MODAL_TYPE.SALUTATION, payload);
                                }}
                                onAddNewClick={(payload) => {
                                    payload.formData = {
                                        textBoxValue: payload.textEntered,
                                        submittedOnce: false
                                    }
                                    handleNewItemAddition(props, payload, MODAL_TYPE.SALUTATION)
                                }}
                                onSelect={(salutationName, option) => {
                                    updateDrawerState({ contact: { ...contact, salutationName: option.name } })
                                }}
                                placeholder={intl.formatMessage(
                                    { id: 'drawer.customer.contact.title.placeholder', defaultMessage: '' }
                                )}
                                allowClear={true}
                                onClear={() => {
                                    updateDrawerState({ contact: { ...contact, salutationName: undefined } })
                                }}
                            />
                        </Col> */}

                        <Col span={12}>
                            <div className='i-label'>
                                First Name
                            </div>
                            <Row>
                                <Col span={8}>
                                    <Dropdown
                                        items={salutations}
                                        valueKeyName='titleName'
                                        value={contact.salutationName}
                                        canAddNew={true}
                                        canDelete={true}
                                        optionKeyName='salutationId'
                                        deleteClickHandler={(payload) => {
                                            deleteClickHandler(props, MODAL_TYPE.SALUTATION, payload);
                                        }}
                                        onAddNewClick={(payload) => {
                                            payload.formData = {
                                                textBoxValue: payload.textEntered,
                                                submittedOnce: false
                                            }
                                            handleNewItemAddition(props, payload, MODAL_TYPE.SALUTATION)
                                        }}
                                        onSelect={(salutationName, option) => {
                                            updateDrawerState({ contact: { ...contact, salutationName: option.name } })
                                        }}
                                        placeholder={intl.formatMessage(
                                            { id: 'drawer.customer.contact.title.placeholder', defaultMessage: '' }
                                        )}
                                        allowClear={true}
                                        onClear={() => {
                                            updateDrawerState({ contact: { ...contact, salutationName: undefined } })
                                        }}
                                    />
                                </Col>

                                <Col span={16}>
                                    <TextBox
                                        placeholder={intl.formatMessage(
                                            { id: 'drawer.customer.contact.firstName.placeholder', defaultMessage: '' }
                                        )}
                                        type='text'
                                        // maxLength={25} 
                                        value={contact.firstName}
                                        onChange={(e) => {
                                            updateDrawerState({
                                                contact: { ...contact, firstName: e.target.value }
                                            });
                                            if (props.customerType === 'individual') {
                                                updateDrawerState({ companyName: (e.target.value || '') + ' ' + (contact.lastName || '') });
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>

                        </Col>

                        {/* <Col span={6}>
                            <Form.Item>
                                <TextBox
                                    placeholder={intl.formatMessage(
                                        { id: 'common.middleName', defaultMessage: '' }
                                    )}
                                    maxLength={25} 
                                    type='text'
                                    value={contact.middleName}
                                    onChange={(e) => {
                                        updateDrawerState({
                                            contact: { ...contact, middleName: e.target.value }
                                        });
                                       
                                    }}
                                />
                            </Form.Item>
                        </Col> */}

                        <Col span={12}>
                            <div className='i-label'>
                                Last Name
                            </div>
                            <TextBox
                                placeholder={intl.formatMessage(
                                    { id: 'drawer.customer.contact.lastName.placeholder', defaultMessage: '' }
                                )}
                                type='text'
                                // maxLength={25} 
                                value={contact.lastName}
                                onChange={(e) => {
                                    updateDrawerState({
                                        contact: { ...contact, lastName: e.target.value }
                                    });
                                    if (props.customerType === 'individual') {
                                        updateDrawerState({ companyName: (contact.firstName || '') + ' ' + (e.target.value || '') });
                                    }
                                }}
                            />
                        </Col>

                        {/* <Col span={6}>
                            <Form.Item >
                                <TextBox
                                    placeholder={intl.formatMessage(
                                        { id: 'common.callName', defaultMessage: '' }
                                    )}
                                    type='text'
                                    value={contact.preferCallName}
                                    onChange={(e) => {
                                        updateDrawerState({
                                            contact: { ...contact, preferCallName: e.target.value }

                                        });
                                    }}
                                />
                            </Form.Item>
                        </Col> */}

                        <Col span={12}>
                            <div className='i-label'>
                                Work Phone
                            </div>
                            <div style={{ display: "flex" }}>

                                <DropdownRef
                                    style={{ width: "20%" }}
                                    items={
                                        (props.countries || []).filter((el) => {
                                            return el.countryCallingCode != null;
                                        }) || []
                                    }
                                    optionKeyName="countryId"
                                    valueKeyName="countryCallingCode"
                                    defaultValue={props.countryCallingCode}
                                    className="currency-tags"
                                    onSelect={(selectedValue, option) => {
                                        const selectedCountry = props.countries.find((el) => el.countryId === selectedValue);

                                        if (selectedCountry) {
                                            updateDrawerState({
                                                countryCallingCode: selectedCountry.countryCallingCode,
                                                contact: { ...contact, countryCallingCode: selectedCountry.countryCallingCode },
                                            });
                                        }

                                    }}
                                />
                                <TextBox
                                    // labelValue={props.companyInfo.countryCallingCode ? '+' + props.companyInfo.countryCallingCode : "-"}
                                    // labelStyle={{ marginRight: '-41px' }}
                                    className="textbox-pd"
                                    maxLength={10}
                                    countStyle={{ top: "40px" }}
                                    placeholder={intl.formatMessage(
                                        { id: 'common.phone', defaultMessage: '' }
                                    )}
                                    type='text'
                                    value={contact.workPhone}
                                    onChange={(e) => {
                                        const re = /^[0-9\b]+$/;
                                        if (e.target.value === '' || re.test(e.target.value)) {
                                            updateDrawerState({
                                                contact: { ...contact, workPhone: e.target.value }

                                            });
                                        }
                                    }}
                                />
                            </div>
                        </Col>

                        <Col span={12}>
                            <div className='i-label'>
                                Mobile Number
                            </div>
                            <div style={{ display: "flex" }}>

                                <DropdownRef
                                    style={{ width: "20%" }}
                                    items={
                                        (props.countries || []).filter((el) => {
                                            return el.countryCallingCode != null;
                                        }) || []
                                    }
                                    optionKeyName="countryId"
                                    valueKeyName="countryCallingCode"
                                    defaultValue={props.countryCallingCode}
                                    className="currency-tags"
                                    onSelect={(selectedValue, option) => {
                                        const selectedCountry = props.countries.find((el) => el.countryId === selectedValue);

                                        if (selectedCountry) {
                                            updateDrawerState({
                                                countryCallingCode: selectedCountry.countryCallingCode,
                                                contact: { ...contact, countryCallingCode: selectedCountry.countryCallingCode },
                                            });
                                        }

                                    }}
                                />
                                <TextBox
                                    className="textbox-pd"
                                    maxLength={10}
                                    countStyle={{ top: "40px" }}
                                    placeholder={intl.formatMessage(
                                        { id: 'drawer.customer.contact.mobile.placeholder', defaultMessage: '' }
                                    )}
                                    type='text'
                                    value={contact.cellPhone}
                                    onChange={(e) => {
                                        const re = /^[0-9\b]+$/;
                                        if (e.target.value === '' || re.test(e.target.value)) {
                                            updateDrawerState({
                                                contact: { ...contact, cellPhone: e.target.value }

                                            });
                                        }
                                    }}
                                />
                            </div>
                        </Col>

                        <Col span={12}>
                            <div className='i-label'>
                                Email
                            </div>
                            <TextBox
                                placeholder={intl.formatMessage(
                                    { id: 'drawer.customer.contact.email.placeholder', defaultMessage: '' }
                                )}
                                type='text'
                                // maxLength={254} 
                                className={props.invalidEmailClass}
                                // validator={(val) => {
                                //     return validateEmail(val) || !val
                                //         ? ""
                                //         : "input-text-error";
                                // }}
                                value={contact.emailAddress}
                                onChange={(e) => {
                                    updateDrawerState({
                                        contact: { ...contact, emailAddress: e.target.value },
                                    });
                                }}
                            />
                            {/* <ErrorMsg
                                validator={() => { return !props.submittedOnce && !validateEmail(props.contact.emailAddress) }}
                                message={intl.formatMessage(
                                    { id: 'login.email.invalidEmail', defaultMessage: '' }
                                )}
                            /> */}
                            <ErrorMsg
                                validator={() => {
                                    return !submittedOnce || !!contact.emailAddress;
                                }}
                                message={intl.formatMessage({
                                    id: "email.requiredField.missing.msg",
                                    defaultMessage: "",
                                })}
                            />
                        </Col>

                        <Col span={12}>
                            <div className='i-label'>
                                Fax Number
                            </div>
                            <TextBox
                                placeholder={intl.formatMessage(
                                    { id: 'drawer.customer.contact.faxNumber.placeholder', defaultMessage: '' }
                                )}
                                type='text'
                                // maxLength={15} 
                                value={contact.faxNumber}
                                onChange={(e) => {
                                    updateDrawerState({
                                        contact: { ...contact, faxNumber: e.target.value }
                                    });
                                }}
                            />
                        </Col>

                    </Row>


                    <div className="billing-address-information">
                        <strong><FormattedMessage id='drawer.customer.address.note.text' defaultMessage='' /></strong>
                        <ul>
                            <li><FormattedMessage id='drawer.customer.contact.note.message1' defaultMessage='' /></li>
                            {/* <li><FormattedMessage id='drawer.customer.address.note.message2' defaultMessage='' /> </li> */}
                        </ul>
                    </div>
                </TabPane>
                : ''
            }

            <TabPane tab={intl.formatMessage({ id: 'addItem.text.taxIdentification', defaultMessage: '' })} key="3">
                {(props.companyInfo || {}).countryName === "India" ?
                    <Row gutter={[16, 16]}>

                        <Col span={12}>
                            <div className='i-label'>
                                <FormattedMessage id='drawer.customer.taxInfo.gstMechanism' defaultMessage='' />
                            </div>

                            <Dropdown
                                items={GST_MECHANISM_OPTIONS}
                                value={gstMechanism}
                                onSelect={(gstMechanism) => {
                                    updateDrawerState({
                                        gstMechanism
                                    });
                                }}
                                placeholder={intl.formatMessage(
                                    { id: 'drawer.customer.taxInfo.gstMechanism.placeholder', defaultMessage: '' }
                                )}
                                allowClear={true}
                                onClear={() => {
                                    updateDrawerState({ gstMechanism: undefined })
                                }}
                            />
                        </Col>

                        {/* <Row gutter={16}>
                   <Col span={8}>
                   <label className="pull-right">
                   <FormattedMessage id='drawer.customer.taxInfo.gstin' defaultMessage='' />
                   </label>
                   </Col>
                       <Col span={10}>
                           <Form.Item >
                           <TextBox
                               placeholder={intl.formatMessage(
                               { id: 'drawer.customer.taxInfo.gstin.placeholder', defaultMessage: '' }
                               )}
                               type='text'
                               value={gstNumber}
                               onChange={(e) => {
                                   updateDrawerState({
                                       gstNumber: e.target.value

                                   });
                               }}
                           />
                           </Form.Item>
                       </Col>
                   </Row>

                   <Row gutter={16}>
                   <Col span={8}>
                   <label className="pull-right">
                   <FormattedMessage id='drawer.customer.taxInfo.pan' defaultMessage='' />
                   </label>
                   </Col>
                       <Col span={10}>
                           <Form.Item >
                           <TextBox
                               placeholder={intl.formatMessage(
                               { id: 'drawer.customer.taxInfo.pan.placeholder', defaultMessage: '' }
                               )}
                               type='text'
                               value={panNumber}
                               onChange={(e) => {
                                   updateDrawerState({
                                       panNumber: e.target.value

                                   });
                               }}
                           />
                           </Form.Item>
                       </Col>
                   </Row> */}

                        <Col span={12}>
                            <div className='i-label'>
                                <FormattedMessage id='drawer.customer.taxInfo.sez' defaultMessage='' />
                            </div>
                            <Checkbox
                                className='login-form-checkbox'
                                checked={locatedInSez}
                                onClick={(e) => {
                                    updateDrawerState({
                                        locatedInSez: e.target.checked
                                    });
                                }}
                            />
                        </Col>
                    </Row>
                    :
                    ""
                }

                {/* <div>
                    <CustomAgGrid
                        columnDefs={columnDefs}
                        onGridReady={onGridReady}
                        rowData={customerTaxIdentificationsList}
                        singleClickEdit={true}
                        disableFocusOnClickOutside={true}
                        editType={'fullRow'}
                        gridStyle={{
                            width: '100%',
                            height: '100%',
                            marginBottom: '10px'
                        }} />
                    <Button className="grey-button" onClick={
                        () => {
                            let newRowIndex = -1;
                            gridApi.forEachNode((rowNode, index) => {
                                newRowIndex = index;
                            });
                            gridApi.stopEditing();
                            gridApi.updateRowData({ add: [{}] });
                            gridApi.startEditingCell({
                                rowIndex: newRowIndex + 1,
                                colKey: "type"
                            });
                            gridApi.refreshCells({ force: true });
                        }
                    }><FormattedMessage id='customer.salesOrder.form.button.addLine' defaultMessage='' /></Button>
                </div> */}

                <div className='table-container row-adder' style={{ height: 'unset' }}>
                    <table>
                        <thead>
                            <tr>
                                <th width="6%">S.No.</th>
                                <th width="30%">Tax Type</th>
                                <th width="30%">Number</th>
                                <th width="28%">Populate on Transaction</th>
                                <th width="6%">
                                    Action {(customerTaxIdentificationsList || []).length === 0 ? <i className={ICONS.ADD} onClick={() => {
                                        updateDrawerState({ customerTaxIdentificationsList: [...(customerTaxIdentificationsList || []), {}] })
                                    }} /> : ''}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (customerTaxIdentificationsList || []).length ? customerTaxIdentificationsList.map((rowData, i) => {
                                    return <tr key={'tax' + i}>
                                        <td>{i + 1}</td>
                                        <td>


                                            <Dropdown
                                                items={(allTaxIdentificatinType || []).sort((a, b) => (a.type||"").localeCompare(b.type||""))}
                                                optionKeyName='taxIdentificationId'
                                                valueKeyName='type'
                                                placeholder='Select'
                                                canAddNew={true}
                                                canDelete={true}

                                                value={rowData.taxIdentificationType}
                                                onChange={(val, options) => {
                                                    let tempList = customerTaxIdentificationsList;
                                                    let tempObj = tempList[i];
                                                    tempObj.taxIdentificationType = options?.name;
                                                    
                                                    tempList[i] = tempObj;
                                                    props.updateDrawerState({
                                                        customerTaxIdentificationsList: tempList
                                                    })
                                                }}
                                                deleteClickHandler={(payload) => {
                                                    deleteClickHandler(props, MODAL_TYPE.TAX_IDENTIFICATION, payload);
                                                }}
                                                onAddNewClick={(payload) => {
                                                    payload.formData = {
                                                        textBoxValue: payload.textEntered,
                                                        submittedOnce: false
                                                    };
                                                    handleNewItemAddition(
                                                        props,
                                                        payload,
                                                        MODAL_TYPE.TAX_IDENTIFICATION
                                                    );
                                                }}
                                            />

                                        </td>
                                        <td>
                                            <TextBox
                                                value={rowData.taxIdentificationNumber}
                                                placeholder={'Enter'}
                                                maxLength={25}
                                                countStyle={{ display: "none" }}
                                                onBlur = {()=>{
                                                    const callback  = (val)=>{
                                                        props.updateDrawerState({ taxIdentificationNumberDublicate: val });
                                                       }
                                                    let tempList = customerTaxIdentificationsList;
                                                    let tempObj = tempList[i];
                                                   const  payload={
                                                        relationshipId:props.companyInfo.relationshipId,
                                                        taxIdentificationNumber:tempObj.taxIdentificationNumber
                                                    }
                                                    props.checktaxIdentificationNumber(payload,props,"customer",callback)
                                                }}
                                           
                                            onChange={(e) => {
                                                let tempList = customerTaxIdentificationsList;
                                                let tempObj = tempList[i] ;                                              
                                                tempObj.taxIdentificationNumber = e.target.value;
                                                tempList[i] = tempObj;
                                              
                                                const value = tempList.filter((item, index) => index !== i);
                                              
                                                if (value.find(item => item.taxIdentificationNumber === e.target.value)) {
                                                  showToasterMessage({
                                                    messageType: "error",
                                                    description: "Duplicate value",
                                                  });
                                                  props.updateDrawerState({
                                                  
                                                    duplicateNumber: true
                                                  });
                                               
                                                }
                                                else{
                                                    props.updateDrawerState({
                                                        duplicateNumber: false
                                                      });
                                                }
                                              
                                                props.updateDrawerState({
                                                  customerTaxIdentificationsList: tempList,
                                                  
                                                });
                                              }}
                                              />
                                              
                                        </td>
                                        <td>
                                            <Checkbox
                                                className='ml15'
                                                checked={rowData.populateOnTransaction == 1}
                                                onChange={(event) => {
                                                    let tempList = customerTaxIdentificationsList;
                                                    let tempObj = tempList[i];
                                                    tempObj.populateOnTransaction = event.target.checked ? 1:0;
                                                    tempList[i] = tempObj;
                                                    props.updateDrawerState({
                                                        customerTaxIdentificationsList: tempList
                                                    })
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <i className={`${ICONS.DELETE} mr10`} onClick={() => {
                                                let tempList = customerTaxIdentificationsList;
                                                tempList.splice(i, 1)                                               
                                                updateDrawerState({ customerTaxIdentificationsList: tempList})
                                            }} />
                                            {customerTaxIdentificationsList.length === i + 1 ?
                                                <i className={ICONS.ADD} onClick={() => {
                                                    updateDrawerState({ customerTaxIdentificationsList: [...(customerTaxIdentificationsList || []), {}] })
                                                }} />
                                                :
                                                ''
                                            }
                                        </td>
                                    </tr>
                                })
                                    :
                                    ''
                            }
                        </tbody>
                    </table>
                </div>

            </TabPane>



            {
                props?.companyInfo?.countryName === "India" ? <TabPane tab='GSTIN Details' key="4">
                    <Row gutter={[16,16]}>
                        <Col span={16}>
                        <div className="i-label">
                <FormattedMessage
                  id="Enter GSTIN Number"
                  defaultMessage=""
                />
              </div>
              <TextBox
                                        placeholder={intl.formatMessage(
                                            { id: 'drawer.customer.gstDetails.gstin', defaultMessage: '' }
                                        )}
                                     
                                        value={(props?.gstinNumber||"").toUpperCase()}
                                        type='text'
                                        maxLength={15}
                                        countStyle={{ top:"40px" }}
                                        onChange={(e) => {

                                            updateDrawerState({
                                                gstinNumber: e.target.value
                                               });
                                        }}
                                    />
                        </Col>

                        <Col span={4}>
                        <Button type='primary' className='mt25' onClick={()=>{
                            props.fetchAllGSTINDetails({gstin: props?.gstinNumber , relationshipId: props.companyInfo?.relationshipId},(data)=>{
                                const gstinDetails = data?.data;
         const val = {
        gstinNumber: gstinDetails?.gstin || undefined,
        gstin: gstinDetails?.gstin || undefined,
        businessName: gstinDetails?.name || undefined,
        state: gstinDetails?.state || undefined,
        registrationDate: gstinDetails?.registrationDate || undefined,
        constitute: gstinDetails?.constitution || undefined,
        taxPayerType: gstinDetails?.type || undefined,
        // natureOfBussiness: gstinDetails?.nature[0] || undefined,
        gstinStatus: gstinDetails?.status || undefined,
        stateJurisdication: gstinDetails?.state_cd || undefined,
        cancellationDate: gstinDetails?.cancellationDate || undefined,
        lastUpdateDate: gstinDetails?.lastUpdateDate || undefined,
        stateCode: gstinDetails?.state_cd || undefined,
        centerName: gstinDetails?.center || undefined,
        centerCode: gstinDetails?.center_cd || undefined,
        tradeName: gstinDetails?.tradeName || undefined,
        businessFields: gstinDetails?.gstin || undefined,
        businessAddress: gstinDetails?.gstin || undefined,
        buildingName: gstinDetails?.pradr?.bnm || undefined,
        streetName: gstinDetails?.pradr?.st || undefined,
        location: gstinDetails?.pradr?.loc || undefined,
        doorNumber: gstinDetails?.pradr?.bno || undefined,
        stateName: gstinDetails?.pradr?.stcd|| undefined,
        floorNo: gstinDetails?.pradr?.flno|| undefined,
        latitude: gstinDetails?.pradr?.lt|| undefined,
        longitude: gstinDetails?.pradr?.lg || undefined,
        pincode: gstinDetails?.pradr?.pncd|| undefined,
        natureofAdditionalBusiness: gstinDetails?.ntr|| undefined,
        principalPlaceOfBusiness: gstinDetails?.gstin|| undefined,
        principalPlaceOfAddress: gstinDetails?.gstin|| undefined,
      };
                                props.updateDrawerState({
                                    gstDetails: val,
                                })
                            })
                        }}>

                            Fetch Details
                        </Button>
                        </Col>
                    </Row>
                    
                            <Row gutter={[8, 8]}>

                                <Col span={12}>
                                <div className="i-label">
                <FormattedMessage
                  id="drawer.customer.gstDetails.gstin"
                  defaultMessage=""
                />
              </div>
                                    <TextBox
                                        placeholder={intl.formatMessage(
                                            { id: 'drawer.customer.gstDetails.gstin', defaultMessage: '' }
                                        )}
                                        value={gstDetails?.gstin}
                                        type='text'
                                        disabled={true}
                                        maxLength={15}
                                        countStyle={{ top:"15px" }}
                                        onChange={(e) => {
                                            updateDrawerState({
                                                gstin: e.target.value
                                               });
                                        }}
                                    />
                                   
                                </Col>

                                <Col span={12}>
                                <div className="i-label">
                <FormattedMessage
                  id="drawer.customer.gstDetails.businessName"
                  defaultMessage=""
                />
              </div>
                                    <TextBox
                                        placeholder={intl.formatMessage(
                                            { id: 'drawer.customer.gstDetails.businessName', defaultMessage: '' }
                                        )}
                                        value={gstDetails?.businessName}
                                        type='text'
                                        maxLength={200}
                                        disabled={true}
                                        countStyle={{ top:"15px" }}
                                        onChange={(e) => {
                                            updateDrawerState({
                                                businessName: e.target.value
                                               });
                                        }}
                                    />
                                   
                                </Col>

                                <Col span={12}>
                                <div className="i-label">
                <FormattedMessage
                  id="drawer.customer.address.state.placeholder"
                  defaultMessage=""
                />
              </div>
                                    <TextBox
                                        placeholder={intl.formatMessage(
                                            { id: 'drawer.customer.address.state.placeholder', defaultMessage: '' }
                                        )}
                                        value={gstDetails?.state}
                                        type='text'
                                        maxLength={200}
                                        disabled={true}
                                        countStyle={{ top:"15px" }}
                                        onChange={(e) => {
                                            updateDrawerState({
                                                state: e.target.value
                                               });
                                        }}
                                    />
                                   
                                </Col>

                                <Col span={12}>
                                <div className="i-label">
                <FormattedMessage
                  id="drawer.customer.gstDetails.registrationDate"
                  defaultMessage=""
                />
              </div>
                                    <TextBox
                                        placeholder={intl.formatMessage(
                                            { id: 'drawer.customer.gstDetails.registrationDate', defaultMessage: '' }
                                        )}
                                        value={gstDetails?.registrationDate}
                                        type='text'
                                        maxLength={10}
                                        disabled={true}
                                        countStyle={{ top:"15px" }}
                                        onChange={(e) => {
                                            updateDrawerState({
                                                registrationDate: e.target.value
                                               });
                                        }}
                                    />
                                   
                                </Col>
                                <Col span={12}>
                                <div className="i-label">
                <FormattedMessage
                  id="drawer.customer.gstDetails.constitute"
                  defaultMessage=""
                />
              </div>
                                    <TextBox
                                        placeholder={intl.formatMessage(
                                            { id: 'drawer.customer.gstDetails.constitute', defaultMessage: '' }
                                        )}
                                        value={gstDetails?.constitute}
                                        type='text'
                                        disabled={true}
                                       
                                        // onChange={(e) => {
                                        //     updateDrawerState({
                                        //         constitute: e.target.value
                                        //        });
                                        // }}
                                    />
                                   
                                </Col>

                                <Col span={12}>
                                <div className="i-label">
                <FormattedMessage
                  id="drawer.customer.gstDetails.taxPayerName"
                  defaultMessage=""
                />
              </div>
                                    <TextBox
                                        placeholder={intl.formatMessage(
                                            { id: 'drawer.customer.gstDetails.taxPayerName', defaultMessage: '' }
                                        )}
                                        value={gstDetails?.taxPayerType}
                                        type='text'
                                        disabled={true}
                                        // onChange={(e) => {
                                        //     updateDrawerState({
                                        //         taxPayerType: e.target.value
                                        //        });
                                        // }}
                                    />
                                   
                                </Col>

                                <Col span={12}>
                                <div className="i-label">
                <FormattedMessage
                  id="drawer.customer.gstDetails.NatureofBusiness"
                  defaultMessage=""
                />
              </div>
                                    <TextBox
                                        placeholder={intl.formatMessage(
                                            { id: 'drawer.customer.gstDetails.NatureofBusiness', defaultMessage: '' }
                                        )}
                                        value={gstDetails?.natureOfBussiness}
                                        type='text'
                                        disabled={true}
                                        // onChange={(e) => {
                                        //     updateDrawerState({
                                        //         natureOfBussiness: e.target.value
                                        //        });
                                        // }}
                                    />
                                   
                                </Col>
                                <Col span={12}>
                                <div className="i-label">
                <FormattedMessage
                  id="drawer.customer.gstDetails.gstinStatus"
                  defaultMessage=""
                />
              </div>
                                    <TextBox
                                        placeholder={intl.formatMessage(
                                            { id: 'drawer.customer.gstDetails.gstinStatus', defaultMessage: '' }
                                        )}
                                        value={gstDetails?.gstinStatus}
                                        type='text'
                                        disabled={true}
                                        // onChange={(e) => {
                                        //     updateDrawerState({
                                        //         gstinStatus: e.target.value
                                        //        });
                                        // }}
                                    />
                                   
                                </Col>
                                <Col span={12}>
                                <div className="i-label">
                <FormattedMessage
                  id="drawer.customer.gstDetails.stateJurisdication"
                  defaultMessage=""
                />
              </div>
                                    <TextBox
                                        placeholder={intl.formatMessage(
                                            { id: 'drawer.customer.gstDetails.stateJurisdication', defaultMessage: '' }
                                        )}
                                        disabled={true}
                                        value={gstDetails?.stateJurisdication}
                                        type='text'
                                       
                                        // onChange={(e) => {
                                        //     updateDrawerState({
                                        //         stateJurisdication: e.target.value
                                        //        });
                                        // }}
                                    />
                                   
                                </Col>
                                <Col span={12}>
                                <div className="i-label">
                <FormattedMessage
                  id="drawer.customer.gstDetails.CancellationDate"
                  defaultMessage=""
                />
              </div>
                                    <TextBox
                                        placeholder={intl.formatMessage(
                                            { id: 'drawer.customer.gstDetails.CancellationDate', defaultMessage: '' }
                                        )}
                                        value={gstDetails?.cancellationDate}
                                        type='text'
                                        disabled={true}
                                        // onChange={(e) => {
                                        //     updateDrawerState({
                                        //         cancellationDate: e.target.value
                                        //        });
                                        // }}
                                    />
                                   
                                </Col>

                                <Col span={12}>
                                <div className="i-label">
                <FormattedMessage
                  id="drawer.customer.gstDetails.lastUpdateDate"
                  defaultMessage=""
                />
              </div>
                                    <TextBox
                                        placeholder={intl.formatMessage(
                                            { id: 'drawer.customer.gstDetails.lastUpdateDate', defaultMessage: '' }
                                        )}
                                        value={gstDetails?.lastUpdateDate}
                                        type='text'
                                        disabled={true}
                                        // onChange={(e) => {
                                        //     updateDrawerState({
                                        //         lastUpdateDate: e.target.value
                                        //        });
                                        // }}
                                    />
                                   
                                </Col>


                                <Col span={12}>
                                <div className="i-label">
                <FormattedMessage
                  id="drawer.customer.gstDetails.centerJurisdication"
                  defaultMessage=""
                />
              </div>
                                    <TextBox
                                        placeholder={intl.formatMessage(
                                            { id: 'drawer.customer.gstDetails.centerJurisdication', defaultMessage: '' }
                                        )}
                                        value={gstDetails?.centerName}
                                        type='text'
                                        disabled={true}
                                        // onChange={(e) => {
                                        //     updateDrawerState({
                                        //         centerName: e.target.value
                                        //        });
                                        // }}
                                    />
                                   
                                </Col>

                                <Col span={12}>
                                <div className="i-label">
                <FormattedMessage
                  id="Building Name"
                  defaultMessage=""
                />
              </div>
                                    <TextBox
                                        placeholder={intl.formatMessage(
                                            { id: 'Building Name', defaultMessage: '' }
                                        )}
                                        value={gstDetails?.buildingName}
                                        type='text'
                                        disabled={true}
                                        // onChange={(e) => {
                                        //     updateDrawerState({
                                        //         centerName: e.target.value
                                        //        });
                                        // }}
                                    />
                                   
                                </Col>

                                <Col span={12}>
                                <div className="i-label">
                <FormattedMessage
                  id="Street Name"
                  defaultMessage=""
                />
              </div>
                                    <TextBox
                                        placeholder={intl.formatMessage(
                                            { id: 'Street Name', defaultMessage: '' }
                                        )}
                                        value={gstDetails?.streetName}
                                        type='text'
                                        disabled={true}
                                        // onChange={(e) => {
                                        //     updateDrawerState({
                                        //         centerName: e.target.value
                                        //        });
                                        // }}
                                    />
                                   
                                </Col>
                                <Col span={12}>
                                <div className="i-label">
                <FormattedMessage
                  id="Location Name"
                  defaultMessage=""
                />
              </div>
                                    <TextBox
                                        placeholder={intl.formatMessage(
                                            { id: 'Location Name', defaultMessage: '' }
                                        )}
                                        value={gstDetails?.location}
                                        type='text'
                                        disabled={true}
                                        // onChange={(e) => {
                                        //     updateDrawerState({
                                        //         centerName: e.target.value
                                        //        });
                                        // }}
                                    />
                                   
                                </Col>
                                <Col span={12}>
                                <div className="i-label">
                <FormattedMessage
                  id="Door Number"
                  defaultMessage=""
                />
              </div>
                                    <TextBox
                                        placeholder={intl.formatMessage(
                                            { id: 'Door Number', defaultMessage: '' }
                                        )}
                                        value={gstDetails?.doorNumber}
                                        type='text'
                                        disabled={true}
                                        // onChange={(e) => {
                                        //     updateDrawerState({
                                        //         centerName: e.target.value
                                        //        });
                                        // }}
                                    />
                                   
                                </Col>
                                <Col span={12}>
                                <div className="i-label">
                <FormattedMessage
                  id="State Name"
                  defaultMessage=""
                />
              </div>
                                    <TextBox
                                        placeholder={intl.formatMessage(
                                            { id: 'State Name', defaultMessage: '' }
                                        )}
                                        value={gstDetails?.stateName}
                                        type='text'
                                        disabled={true}
                                        // onChange={(e) => {
                                        //     updateDrawerState({
                                        //         centerName: e.target.value
                                        //        });
                                        // }}
                                    />
                                   
                                </Col>
                                <Col span={12}>
                                <div className="i-label">
                <FormattedMessage
                  id="Floor Number"
                  defaultMessage=""
                />
              </div>
                                    <TextBox
                                        placeholder={intl.formatMessage(
                                            { id: 'Floor Number', defaultMessage: '' }
                                        )}
                                        value={gstDetails?.floorNo}
                                        type='text'
                                        disabled={true}
                                        // onChange={(e) => {
                                        //     updateDrawerState({
                                        //         centerName: e.target.value
                                        //        });
                                        // }}
                                    />
                                   
                                </Col>
                                <Col span={12}>
                                <div className="i-label">
                <FormattedMessage
                  id="Latitude"
                  defaultMessage=""
                />
              </div>
                                    <TextBox
                                        placeholder={intl.formatMessage(
                                            { id: 'Latitude', defaultMessage: '' }
                                        )}
                                        value={gstDetails?.latitude}
                                        type='text'
                                        disabled={true}
                                        // onChange={(e) => {
                                        //     updateDrawerState({
                                        //         centerName: e.target.value
                                        //        });
                                        // }}
                                    />
                                   
                                </Col>
                                <Col span={12}>
                                <div className="i-label">
                <FormattedMessage
                  id="Longitude"
                  defaultMessage=""
                />
              </div>
                                    <TextBox
                                        placeholder={intl.formatMessage(
                                            { id: 'Longitude', defaultMessage: '' }
                                        )}
                                        value={gstDetails?.longitude}
                                        type='text'
                                        disabled={true}
                                        // onChange={(e) => {
                                        //     updateDrawerState({
                                        //         centerName: e.target.value
                                        //        });
                                        // }}
                                    />
                                   
                                </Col>

                                <Col span={12}>
                                <div className="i-label">
                <FormattedMessage
                  id="Pincode"
                  defaultMessage=""
                />
              </div>
                                    <TextBox
                                        placeholder={intl.formatMessage(
                                            { id: 'Pincode', defaultMessage: '' }
                                        )}
                                        value={gstDetails?.pincode}
                                        type='text'
                                        disabled={true}
                                        // onChange={(e) => {
                                        //     updateDrawerState({
                                        //         centerName: e.target.value
                                        //        });
                                        // }}
                                    />
                                   
                                </Col>

                    </Row>
                  
                </TabPane>:''
            }
            {/* <TabPane tab="Logo" key="4">
                <Row gutter={16}>
                    <Col span={8}>
                        <label className="pull-right pt5">
                            <FormattedMessage id='drawer.customer.taxInfo.companyLogo' defaultMessage='' />
                        </label>
                    </Col>
                    <Col span={10}>
                        <Form.Item >
                            <Upload {...props2} onChange={(obj) => {
                                updateDrawerState({
                                    fileToUpload: obj.file.originFileObj
                                });
                            }}>
                                <Button>
                                    <UploadOutlined />
                                    <FormattedMessage id='drawer.customer.taxInfo.companyLogo.upload' defaultMessage='' />
                                </Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                </Row>

            </TabPane> */}
        </Tabs>
    );
}

export default injectIntl(CustomerTabs);
