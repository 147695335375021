import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMMON_ACTIONS, PURCHASE_ORDER_TYPE, PURCHASE_ORDER_ACTION_LIST } from '../../../../static/constants'
import { getCurrentDateForBackend, formatDateForBackend, showToasterMessage, getFinancialYear } from '../../../../utils';
import { LEDGER_TYPE, ACCOUNTING_ENTRY_TYPE, TX_STATUS } from "../../../../static/constants";
import { resetPaginatedData, getPurchaseOrdersCount } from '../Listing/action';
import * as filter from 'lodash.filter';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const createPO = (payloadObject) => {
  const props = payloadObject.props;
  const poData = _getPOData(payloadObject);
  let formData = new FormData();
  if (props.fileList && props.fileList.length) {
    props.fileList.forEach((file) => {
      formData.append('files', file, file.name);
    })
  }
  formData.append('poData', JSON.stringify(poData, true));
  formData.append('bucketName' ,props.companyInfo?.bucketName)
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/purchaseOrder/createPurchaseOrder`, formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then(res => {
        props.openPODetails(res.data);
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        resetPaginatedData()(dispatch);
        showToasterMessage({ messageType: 'success', description: 'Purchase order created successfully' })
        getPurchaseOrdersCount({ relationshipId: props.companyInfo.relationshipId })(dispatch);
        props.history.push('/admin/purchase-order-list');
      })
      .catch((err) => {
        showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
  }
}

const _getPOData = (payload) => {
  const props = payload.props;
  const state = payload.state;
  const gridObject = payload.gridObject;
  const isPlaceOfSupplySameAsRelationship = payload.isPlaceOfSupplySameAsRelationship;
  const companyInfo = props.companyInfo || {};
  const userInfo = props.userInfo || {};
  const financialYear = getFinancialYear(companyInfo);
  let poMaster = {
    poDraftId: props.poDraftId || 0,
    version: props.version,
    supplierId: props.supplier && props.supplier.id,
    fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
    fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
    projectName: props.projectName,
    foreignCurrency: props.currencyCode + " ",
    foreignCurrencyIcon: props.currencyIcon,
    isMultiCurrency: 1,
    exchangeRate: props.exchangeRate,
    projectMasterId: props.projectMasterId,
    projectNumber: props.projectNumber,
    totalPOAmount: state.total,
    txType: PURCHASE_ORDER_TYPE.STANDARD,
    supplierName: props.supplier && props.supplier.name,
    relationshipName: companyInfo && companyInfo.storeName,
    supplierGstNumber: props.supplier && props.supplier.gstNumber,
    supplierPanNumber: props.supplier && props.supplier.panNumber,
    placeOfSupply: props.placeOfSupply,
    status: TX_STATUS.OPEN,
    poDate: props.poDate && formatDateForBackend(props.poDate),
    poDueDate: props.poDueDate && formatDateForBackend(props.poDueDate),
    relationshipId: companyInfo.relationshipId,
    supplierPoNumber: props.supplierPo,
    reference: props.reference,
    remarksSupplier: props.supplierRemarks,
    remarksInternal: props.internalRemarks,
    paymentTermId: props.supplierPaymentTerm && props.supplierPaymentTerm.paymentTermId,
    paymentTermName: props.supplierPaymentTerm && props.supplierPaymentTerm.termName,
    paymentTermDays: props.paymentTermDays,
    //dateCreated: getCurrentDateForBackend(new Date()),
    createdByUserId: userInfo && userInfo.userId,
    createdByEmpId: userInfo && userInfo.relationshipEmployeeId,
    footer: props.footer,
    totalDiscountGiven: state.totalDiscount,
    expenseId: props.expenseId,
    expenseName: props.expenseName,
    supplierPOCOATxList: [],
    supplierPurchaseOrderDetailList: [],
    boLocationPOList: [],
    documentName: props.docName,
    isRFQConversion: props.isRFQConversion ? 1 : 0,
    rfqNumber: props.rfqNumber,
    rfqMasterId: props.rfqMasterId,
    purchaseRequestNumber: props.purchaseRequestNumber,
    expectedDeliveryDate: props.expectedDeliveryDate && formatDateForBackend(props.expectedDeliveryDate),
    shippingInstructions: props.shippingInstructions,
    supplierQuoteNumber: props.supplierQuoteNumber
  };

  poMaster.supplierPOCOATxList.push({
    relationshipId: companyInfo.relationshipId,
    relationshipName: companyInfo.storeName,
    exchangeRate: props.exchangeRate,
    fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
    fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
    supplierId: props.supplier && props.supplier.id,
    //dateCreated: getCurrentDateForBackend(new Date()),
    createdBy: userInfo.userId,
    amount: state.subtotalWithoutDiscount,
    txDate: formatDateForBackend(new Date()),
    txType: LEDGER_TYPE.TYPE_PURCHASE,
    accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
    ledgerAccountId: props.purchaseCreditorsLedgerId,
    supplierName: props.supplier && props.supplier.name
  });

  poMaster.supplierPOCOATxList.push({
    relationshipId: companyInfo.relationshipId,
    relationshipName: companyInfo.storeName,
    exchangeRate: props.exchangeRate,
    fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
    fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
    supplierId: props.supplier && props.supplier.id,
    //dateCreated: getCurrentDateForBackend(new Date()),
    createdBy: userInfo.userId,
    amount: state.subtotalWithoutDiscount,
    txDate: formatDateForBackend(new Date()),
    txType: LEDGER_TYPE.TYPE_PURCHASE,
    accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
    ledgerAccountId: props.purchaseLedgerId,
    supplierName: props.supplier && props.supplier.name
  });

  if (state.rootDiscountAmount) {
    poMaster.supplierPOCOATxList.push({
      relationshipId: companyInfo.relationshipId,
      relationshipName: companyInfo.storeName,
      exchangeRate: props.exchangeRate,
      fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
      fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
      supplierId: props.supplier && props.supplier.id,
      //dateCreated: getCurrentDateForBackend(new Date()),
      createdBy: userInfo.userId,
      amount: state.rootDiscountAmount,
      amountPercent: state.rootDiscountPercent,
      txDate: formatDateForBackend(new Date()),
      txType: LEDGER_TYPE.TYPE_DISCOUNT,
      accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
      ledgerAccountId: props.purchaseDiscountLedgerId,
      supplierName: props.supplier && props.supplier.name
    });

    poMaster.supplierPOCOATxList.push({
      relationshipId: companyInfo.relationshipId,
      relationshipName: companyInfo.storeName,
      exchangeRate: props.exchangeRate,
      fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
      fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
      supplierId: props.supplier && props.supplier.id,
      //dateCreated: getCurrentDateForBackend(new Date()),
      createdBy: userInfo.userId,
      amount: state.rootDiscountAmount,
      amountPercent: state.rootDiscountPercent,
      txDate: formatDateForBackend(new Date()),
      txType: LEDGER_TYPE.TYPE_DISCOUNT,
      accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
      ledgerAccountId: props.discountCreditorLedgerId,
      supplierName: props.supplier && props.supplier.name
    });
  }

  if (state.expenseAmount) {
    poMaster.supplierPOCOATxList.push({
      relationshipId: companyInfo.relationshipId,
      relationshipName: companyInfo.storeName,
      exchangeRate: props.exchangeRate,
      fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
      fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
      supplierId: props.supplier && props.supplier.id,
      //dateCreated: getCurrentDateForBackend(new Date()),
      createdBy: userInfo.userId,
      amount: state.expenseAmount,
      txDate: formatDateForBackend(new Date()),
      txType: LEDGER_TYPE.TYPE_EXPENSE,
      accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
      ledgerAccountId: props.purchaseExpenseLedgerId,
      supplierName: props.supplier && props.supplier.name
    });

    poMaster.supplierPOCOATxList.push({
      relationshipId: companyInfo.relationshipId,
      relationshipName: companyInfo.storeName,
      exchangeRate: props.exchangeRate,
      fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
      fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
      supplierId: props.supplier && props.supplier.id,
      //dateCreated: getCurrentDateForBackend(new Date()),
      createdBy: userInfo.userId,
      amount: state.expenseAmount,
      txDate: formatDateForBackend(new Date()),
      txType: LEDGER_TYPE.TYPE_EXPENSE,
      accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
      ledgerAccountId: props.purchaseExpenseCreditorLedgerId,
      supplierName: props.supplier && props.supplier.name
    });
  }

  if (props.relationshipBillingAddress && props.relationshipBillingAddress.streetAddress1) {
   // props.relationshipBillingAddress.dateCreated = getCurrentDateForBackend(new Date());
   // props.relationshipBillingAddress.dateUpdated = getCurrentDateForBackend(new Date());
    poMaster.boLocationPOList.push(props.relationshipBillingAddress);
  }

  if (props.relationshipShippingAddress && props.relationshipShippingAddress.streetAddress1) {
   // props.relationshipShippingAddress.dateCreated = getCurrentDateForBackend(new Date());
   // props.relationshipShippingAddress.dateUpdated = getCurrentDateForBackend(new Date());
    poMaster.boLocationPOList.push(props.relationshipShippingAddress);
  }

  if (props.supplierBillingAddress && props.supplierBillingAddress.streetAddress1) {
   // props.supplierBillingAddress.dateCreated = getCurrentDateForBackend(new Date());
   // props.supplierBillingAddress.dateUpdated = getCurrentDateForBackend(new Date());
    poMaster.boLocationPOList.push(props.supplierBillingAddress);
  }

  gridObject.forEachNode((rowNode, index) => {
    const { data } = rowNode;
    if(!data.itemId && !data.description && !data.qty && !data.rate){
      return
    }
    const itemId = data.selectedProductValue && data.selectedProductValue[0];
    //if(!itemId) return;
    let variantId = data.selectedProductValue && data.selectedProductValue.length === 2 ? data.selectedProductValue[1] : 0;
    if(data.productObj && !data.productObj.hasVariant && !variantId){
      variantId = data.productObj.inventoryItemVariantList ? data.productObj.inventoryItemVariantList[0]?.variantId: data.inventoryItemVariantId;
    }
    let product = {};
    let itemLevelCoaTxList = [];
    if (itemId) {
      product = data.productObj || {};
    }
    let variant = {};
    if (variantId) {
      variant = data.variant || {};
    }
    let itemLevelAmount = ((data || {}).rate || 0) * ((data || {}).qty || 0);

    if (data.discount) {
      const discountOnItem = itemLevelAmount ? ((data.discount * 0.01) * itemLevelAmount) : 0;
      itemLevelAmount = itemLevelAmount - discountOnItem;
      itemLevelCoaTxList.push({
        relationshipId: companyInfo.relationshipId,
        relationshipName: companyInfo.storeName,
        exchangeRate: props.exchangeRate,
        fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
        fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
        supplierId: props.supplier && props.supplier.id,
       // dateCreated: getCurrentDateForBackend(new Date()),
        createdBy: userInfo.userId,
        amount: discountOnItem,
        amountPercent: data.discount,
        txDate: formatDateForBackend(new Date()),
        txType: LEDGER_TYPE.TYPE_DISCOUNT,
        accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
        ledgerAccountId: props.purchaseDiscountLedgerId,
        supplierName: props.supplier && props.supplier.name
      });

      itemLevelCoaTxList.push({
        relationshipId: companyInfo.relationshipId,
        relationshipName: companyInfo.storeName,
        exchangeRate: props.exchangeRate,
        fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
        fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
        supplierId: props.supplier && props.supplier.id,
        //dateCreated: getCurrentDateForBackend(new Date()),
        createdBy: userInfo.userId,
        amount: discountOnItem,
        amountPercent: data.discount,
        txDate: formatDateForBackend(new Date()),
        txType: LEDGER_TYPE.TYPE_DISCOUNT,
        accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
        ledgerAccountId: props.discountCreditorLedgerId,
        supplierName: props.supplier && props.supplier.name
      })
    }

    if (data.tax) {
      if (companyInfo.countryName !== 'India') {
        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          exchangeRate: props.exchangeRate,
          fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
          customerId: props.customer && props.customer.customerId,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount ? ((data.tax * 0.01) * itemLevelAmount) : 0).toFixed(2),
          amountPercent: Number(data.tax || 0),
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX_VAT,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
          ledgerAccountId: props.salesOutputTaxLedgerId,
          customerName: props.customer && props.customer.companyName
        });

        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          exchangeRate: props.exchangeRate,
          fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
          customerId: props.customer && props.customer.customerId,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount ? ((data.tax * 0.01) * itemLevelAmount) : 0).toFixed(2),
          amountPercent: Number(data.tax || 0),
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX_VAT,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
          ledgerAccountId: props.taxDebtorLedgerId,
          customerName: props.customer && props.customer.companyName
        });
      }
      else if (isPlaceOfSupplySameAsRelationship) {
        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          exchangeRate: props.exchangeRate,
          fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
          supplierId: props.supplier && props.supplier.id,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount ? (((data.tax) * 0.01) * itemLevelAmount)/2 : 0).toFixed(2),
          amountPercent: (data.tax)/2,
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX_CGST,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
          ledgerAccountId: props.cgstTaxCreditorsLedgerId,
          supplierName: props.supplier && props.supplier.name
        });

        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          exchangeRate: props.exchangeRate,
          fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
          supplierId: props.supplier && props.supplier.id,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount ? (((data.tax) * 0.01) * itemLevelAmount)/2 : 0).toFixed(2),
          amountPercent: (data.tax)/2,
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX_CGST,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
          ledgerAccountId: props.cgstPurchaseTaxLedgerId,
          supplierName: props.supplier && props.supplier.name
        });


        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          exchangeRate: props.exchangeRate,
          fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
          supplierId: props.supplier && props.supplier.id,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount ? (((data.tax) * 0.01) * itemLevelAmount)/2 : 0).toFixed(2),
          amountPercent: (data.tax)/2,
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX_SGST,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
          ledgerAccountId: props.sgstTaxCreditorsLedgerId,
          supplierName: props.supplier && props.supplier.name
        });

        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          exchangeRate: props.exchangeRate,
          fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
          supplierId: props.supplier && props.supplier.id,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount ? (((data.tax) * 0.01) * itemLevelAmount)/2 : 0).toFixed(2),
          amountPercent: (data.tax)/2,
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX_SGST,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
          ledgerAccountId: props.sgstPurchaseTaxLedgerId,
          supplierName: props.supplier && props.supplier.name
        });

      } else {
        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          exchangeRate: props.exchangeRate,
          fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
          supplierId: props.supplier && props.supplier.id,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount ? ((data.tax * 0.01) * itemLevelAmount) : 0).toFixed(2),
          amountPercent: data.tax,
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX_IGST,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
          ledgerAccountId: props.igstTaxCreditorsLedgerId,
          supplierName: props.supplier && props.supplier.name
        });

        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          exchangeRate: props.exchangeRate,
          fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
          supplierId: props.supplier && props.supplier.id,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount ? ((data.tax * 0.01) * itemLevelAmount) : 0).toFixed(2),
          amountPercent: data.tax,
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX_IGST,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
          ledgerAccountId: props.igstPurchaseTaxLedgerId,
          supplierName: props.supplier && props.supplier.name
        });
      }
    }

    poMaster.supplierPurchaseOrderDetailList.push({
      relationshipId: companyInfo && companyInfo.relationshipId,
      version: data.version,
      supplierId: props.supplier && props.supplier.id,
      quantityToInvoice: Number(data.qty),
      quantity: Number(data.qty),
      sNo: index + 1,
      sku: product.sku,
      uomName: data.uom,
      basePrice: Number(data.baseRate),
      specialDiscount: Number(data.specialDiscount),
      txDate: formatDateForBackend(props.invoiceDate),
      invoiceDate: formatDateForBackend(props.invoiceDate),
      discountPercentage: Number(data.discount),
      itemName: product.itemName,
      itemId: product.itemId,
      anItemPurchasePrice: Number(data.rate),
      rowHeight: data.scrollHeight,
      description: data.description,
      hsnCode: data.hsnCode,
      inventoryItemVariantId: variantId,
      supplierPOCOATxList: itemLevelCoaTxList,
      attributeId1: variant.attributeId1,
      attributeId2: variant.attributeId2,
      attributeId3: variant.attributeId3,
      attributeName1: variant.attributeName1,
      attributeName2: variant.attributeName2,
      attributeName3: variant.attributeName3,
      attributeValue1: variant.attributeValue1,
      attributeValue2: variant.attributeValue2,
      attributeValue3: variant.attributeValue3,
      rfqMasterId: props.rfqMasterId,
      rfqNumber: props.rfqNumber,
    });
  });

  let additionalInfo = {};

  if (props.selectedContact && props.selectedContact.firstName) {
    additionalInfo = { ...props.selectedContact }
    // additionalInfo.relationshipVatNumber = companyInfo.vatNumber;
    // additionalInfo.relationshipGstNumber = companyInfo.gstNumber;
    //  additionalInfo.relationshipPanNumber = companyInfo.panNumber;
    additionalInfo.relationshipFaxNumber = companyInfo.faxNumber;
    additionalInfo.relationshipPhoneNumber = companyInfo.phoneNumber;
    additionalInfo.relationshipWebsite = companyInfo.website;
  }
  if (props.supplier) {
    additionalInfo.supplierId = props.supplier.id;
    //  additionalInfo.gstNumber = props.supplier.gstNumber;
    //  additionalInfo.panNumber = props.supplier.panNumber;
  }
  if (Object.keys(additionalInfo).length) {
    additionalInfo.relationshipId = companyInfo && companyInfo.relationshipId;
  }

  if (props.selectedSupplierTaxIdentifications && props.selectedSupplierTaxIdentifications.length) {
    let selectedSupplierTaxIdentifications = filter(props.supplier.supplierTaxIdentificationsList, (taxObj) => {
      return props.selectedSupplierTaxIdentifications.indexOf(taxObj.supplierTaxIdentificationId) > -1;
    });
    additionalInfo.taxIdentifications = JSON.stringify(selectedSupplierTaxIdentifications);
  }
  if (props.selectedRelationshipTaxIdentifications && props.selectedRelationshipTaxIdentifications.length) {
    let selectedRelTaxIdentifications = filter(props.allRelationshipTaxIdentificatins, (taxObj) => {
      return props.selectedRelationshipTaxIdentifications.indexOf(taxObj.relationshipTaxIdentificationId) > -1;
    });
    additionalInfo.relationshipTaxIdentifications = JSON.stringify(selectedRelTaxIdentifications);
  }
  poMaster.additionalInfoList = [additionalInfo];

  return poMaster;
}


export const fetchRfqDataForPo = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/rfqRest/getRFQsByRFQId?rfqMasterId=${payload.rfqMasterId}&relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: PURCHASE_ORDER_ACTION_LIST.RFQ_PO_DATA, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = PURCHASE_ORDER_ACTION_LIST.RFQ_PO_DATA;
        }
      })
  }
}

export const resetRfqPoData = () => {
  return dispatch => {
    dispatch({ type: PURCHASE_ORDER_ACTION_LIST.RESET_RFQ_PO_DATA });
  }
}

export const getNextTxNumber = (payload) => {
  let date = payload.date ? getCurrentDateForBackend(payload.date) : getCurrentDateForBackend(new Date());
  return dispatch => {
    dispatch({ type: PURCHASE_ORDER_ACTION_LIST.NEXT_PO_NUMBER_RESET });
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/purchaseOrder/getNextPONumber?date=${date}&relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: PURCHASE_ORDER_ACTION_LIST.NEXT_PO_NUMBER, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = PURCHASE_ORDER_ACTION_LIST.NEXT_PO_NUMBER;
        }
      })
  }
}
export const createPODraft = (payloadObject) => {
  const props = payloadObject.props;
  const poData = _getPOData(payloadObject);
  return dispatch => {
    const data = {
      poDraftId: props.poDraftId || 0,
      purchaseOrderData: JSON.stringify(poData),
      relationshipId: poData.relationshipId,
      supplierId: poData.supplierId
    }

    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/purchaseOrder/saveOrUpdateDraft`, data)
      .then(res => {
        showToasterMessage({ messageType: 'success', description: 'Purchase Order saved in draft' })
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        props.history.push({
          pathname: '/admin/purchase-order-list',
          state: { activeTab: 'Draft' }
        })
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }
      })
  }
}