import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS, ECOMMERCE_ACTION_LIST } from '../../../static/constants';
import { showToasterMessage,getFinancialYear} from '../../../utils';
import download from 'downloadjs'
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchPublishedProducts = (payload, history) => {

  const searchedTextQuery = payload.searchedText && payload.searchedText.length ? `&searchString=${payload.searchedText}` : '';
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    let url = '/inventoryItem/getPublisedItem' ;
    return axios
      .get(`${config.INVENTORY_BASE_URL}${config.API_VERSION}/${config.rootContextInventory}${url}?relationshipId=${payload.relationshipId}&warehouseMasterId=0&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber||1) - 1}&warehouseMasterId=${payload.warehouseMasterId ||0}&countryName=${payload.companyInfo.countryName ||0}${searchedTextQuery}`
      )
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: ECOMMERCE_ACTION_LIST.PUBLISHED_PRODUCT_LIST, data });
      })
      .catch((err) => {
        if(!err.__isRetryRequest){
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = ECOMMERCE_ACTION_LIST.PUBLISHED_PRODUCT_LIST;
          err.data = {
            pageNo: payload.pageNumber,
            list: []
          };
        }
      })
  }
}

export const getPublishedProductsCount = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  const searchedTextQuery = payload.searchedText && payload.searchedText.length ? `&searchString=${payload.searchedText}` : '';
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.INVENTORY_BASE_URL}${config.API_VERSION}/${config.rootContextInventory}/inventoryItem/countPublisedItem?relationshipId=${payload.relationshipId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}&warehouseMasterId=${payload.warehouseMasterId ||0}${searchedTextQuery}`)
      .then(res => {
        dispatch({ type: ECOMMERCE_ACTION_LIST.PUBLISHED_PRODUCT_COUNT, data: res.data });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = ECOMMERCE_ACTION_LIST.PUBLISHED_PRODUCT_COUNT;
        }
      })
  }
}

export const resetPublishedProductsPage = () => {
  return dispatch => {
    dispatch({ type: ECOMMERCE_ACTION_LIST.PUBLISHED_PRODUCT_LIST_PAGE_RESET });
  }
}

export const fetchUnpublishedProducts = (payload, history) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    let url =  '/inventoryItem/getUnPublishedItem' 
    return axios
      .get(`${config.INVENTORY_BASE_URL}${config.API_VERSION}/${config.rootContextInventory}${url}?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${payload.pageNumber - 1}`
      )
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: ECOMMERCE_ACTION_LIST.UNPUBLISHED_PRODUCT_LIST, data });
      })
      .catch((err) => {
        if(!err.__isRetryRequest){
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = ECOMMERCE_ACTION_LIST.UNPUBLISHED_PRODUCT_LIST;
          err.data = {
            pageNo: payload.pageNumber,
            list: []
          };
        }
      })
  }
}

export const getUnpublishedProductsCount = (payload, history) => {
  const searchedTextQuery = payload.searchedText && payload.searchedText.length ? `&searchString=${payload.searchedText}` : '';
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.INVENTORY_BASE_URL}${config.API_VERSION}/${config.rootContextInventory}/inventoryItem/countUnPublishedItem?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: ECOMMERCE_ACTION_LIST.UNPUBLISHED_PRODUCT_COUNT, data: res.data });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = ECOMMERCE_ACTION_LIST.UNPUBLISHED_PRODUCT_COUNT;
        }
      })
  }
}

export const resetUnpublishedProductsPage = () => {
  return dispatch => {
    dispatch({ type: ECOMMERCE_ACTION_LIST.UNPUBLISHED_PRODUCT_LIST_PAGE_RESET });
  }
}

export const fetchClearanceProducts = (payload, history) => {
  const searchedTextQuery = payload.searchedText && payload.searchedText.length ? `&searchString=${payload.searchedText}` : '';
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    let url =  '/inventoryItem/getClearanceItem' ;
    return axios
      .get(`${config.INVENTORY_BASE_URL}${config.API_VERSION}/${config.rootContextInventory}${url}?relationshipId=${payload.relationshipId}&warehouseMasterId=0&pageSize=${payload.pageSize || 10}&pageNumber=${payload.pageNumber - 1}&warehouseMasterId=${payload.warehouseMasterId ||0}${searchedTextQuery}`
      )
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: ECOMMERCE_ACTION_LIST.CLEARANCE_PRODUCT_LIST, data });
      })
      .catch((err) => {
        if(!err.__isRetryRequest){
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = ECOMMERCE_ACTION_LIST.CLEARANCE_PRODUCT_LIST;
          err.data = {
            pageNo: payload.pageNumber,
            list: []
          };
        }
      })
  }
}

export const getClearanceProductsCount = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  const searchedTextQuery = payload.searchedText && payload.searchedText.length ? `&searchString=${payload.searchedText}` : '';
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.INVENTORY_BASE_URL}${config.API_VERSION}/${config.rootContextInventory}/inventoryItem/countClearanceItem?relationshipId=${payload.relationshipId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}&warehouseMasterId=${payload.warehouseMasterId ||0}${searchedTextQuery}`)
      .then(res => {
        dispatch({ type: ECOMMERCE_ACTION_LIST.CLEARANCE_PRODUCT_COUNT, data: res.data });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = ECOMMERCE_ACTION_LIST.CLEARANCE_PRODUCT_COUNT;
        }
      })
  }
}

export const resetClearanceProductsPage = () => {
  return dispatch => {
    dispatch({ type: ECOMMERCE_ACTION_LIST.CLEARANCE_PRODUCT_LIST_PAGE_RESET });
  }
}

export const fetchDealOfTheDayProducts = (payload, history) => {
  const searchedTextQuery = payload.searchedText && payload.searchedText.length ? `&searchString=${payload.searchedText}` : '';
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    let url = '/inventoryItem/getDealOfTheDayItem';
    return axios
      .get(`${config.INVENTORY_BASE_URL}${config.API_VERSION}/${config.rootContextInventory}${url}?relationshipId=${payload.relationshipId}&warehouseMasterId=0&pageSize=${payload.pageSize || 10}&pageNumber=${payload.pageNumber - 1}&warehouseMasterId=${payload.warehouseMasterId ||0}${searchedTextQuery}`
      )
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: ECOMMERCE_ACTION_LIST.DEAL_OF_THE_DAY_PRODUCT_LIST, data });
      })
      .catch((err) => {
        if(!err.__isRetryRequest){
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = ECOMMERCE_ACTION_LIST.DEAL_OF_THE_DAY_PRODUCT_LIST;
          err.data = {
            pageNo: payload.pageNumber,
            list: []
          };
        }
      })
  }
}

export const getDealOfTheDayProductsCount = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  const searchedTextQuery = payload.searchedText && payload.searchedText.length ? `&searchString=${payload.searchedText}` : '';
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.INVENTORY_BASE_URL}${config.API_VERSION}/${config.rootContextInventory}/inventoryItem/countDealOfTheDayItem?relationshipId=${payload.relationshipId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}&warehouseMasterId=${payload.warehouseMasterId ||0}${searchedTextQuery}`)
      .then(res => {
        dispatch({ type: ECOMMERCE_ACTION_LIST.DEAL_OF_THE_DAY_PRODUCT_COUNT, data: res.data });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = ECOMMERCE_ACTION_LIST.DEAL_OF_THE_DAY_PRODUCT_COUNT;
        }
      })
  }
}



export const unPublishedProductsCount = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  const searchedTextQuery = payload.searchedText && payload.searchedText.length ? `&searchString=${payload.searchedText}` : '';
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.INVENTORY_BASE_URL}${config.API_VERSION}/${config.rootContextInventory}/inventoryItem/countDealOfTheDayItem?relationshipId=${payload.relationshipId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}&warehouseMasterId=${payload.warehouseMasterId ||0}${searchedTextQuery}`)
      .then(res => {
        dispatch({ type: ECOMMERCE_ACTION_LIST.DEAL_OF_THE_DAY_PRODUCT_COUNT, data: res.data });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = ECOMMERCE_ACTION_LIST.DEAL_OF_THE_DAY_PRODUCT_COUNT;
        }
      })
  }
}

export const resetDealOfTheDayProductsPage = () => {
  return dispatch => {
    dispatch({ type: ECOMMERCE_ACTION_LIST.DEAL_OF_THE_DAY_PRODUCT_LIST_PAGE_RESET });
  }
}

export const fetchNewArrivalProducts = (payload, history) => {
  
  const searchedTextQuery = payload.searchedText && payload.searchedText.length ? `&searchString=${payload.searchedText}` : '';
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
   
    return axios
      .get(`${config.INVENTORY_BASE_URL}${config.API_VERSION}/${config.rootContextInventory}/inventoryItem/getNewArrivalItem?relationshipId=${payload.relationshipId}&warehouseMasterId=0&pageSize=${payload.pageSize || 10}&pageNumber=${payload.pageNumber - 1}&warehouseMasterId=${payload.warehouseMasterId ||0}${searchedTextQuery}`
      )
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
     
        dispatch({ type: ECOMMERCE_ACTION_LIST.NEW_ARRIVAL_PRODUCT_LIST, data });
      })
      .catch((err) => {
        if(!err.__isRetryRequest){
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = ECOMMERCE_ACTION_LIST.NEW_ARRIVAL_PRODUCT_LIST;
          err.data = {
            pageNo: payload.pageNumber,
            list: []
          };
        }
      })
  }
}

export const getNewArrivalProductsCount = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  const searchedTextQuery = payload.searchedText && payload.searchedText.length ? `&searchString=${payload.searchedText}` : '';
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.INVENTORY_BASE_URL}${config.API_VERSION}/${config.rootContextInventory}/inventoryItem/countNewArrivalItem?relationshipId=${payload.relationshipId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}&warehouseMasterId=${payload.warehouseMasterId ||0}${searchedTextQuery}`)
      .then(res => {
        dispatch({ type: ECOMMERCE_ACTION_LIST.NEW_ARRIVAL_PRODUCT_COUNT, data: res.data });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = ECOMMERCE_ACTION_LIST.NEW_ARRIVAL_PRODUCT_COUNT;
        }
      })
  }
}

export const resetNewArrivalProductsPage = () => {
  return dispatch => {
    dispatch({ type: ECOMMERCE_ACTION_LIST.NEW_ARRIVAL_PRODUCT_LIST_PAGE_RESET });
  }
}


export const fetchBestSellingProducts = (payload, history) => {
  const searchedTextQuery = payload.searchedText && payload.searchedText.length ? `&searchString=${payload.searchedText}` : '';
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    let url =  '/inventoryItem/getBestSellingItem';
    return axios
      .get(`${config.INVENTORY_BASE_URL}${config.API_VERSION}/${config.rootContextInventory}${url}?relationshipId=${payload.relationshipId}&warehouseMasterId=0&pageSize=${payload.pageSize || 10}&pageNumber=${payload.pageNumber - 1}&warehouseMasterId=${payload.warehouseMasterId ||0}${searchedTextQuery}`
      )
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: ECOMMERCE_ACTION_LIST.BEST_SELLING_PRODUCT_LIST, data });
      })
      .catch((err) => {
        if(!err.__isRetryRequest){
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = ECOMMERCE_ACTION_LIST.BEST_SELLING_PRODUCT_LIST;
          err.data = {
            pageNo: payload.pageNumber,
            list: []
          };
        }
      })
  }
}
export const fetchOnSaleProducts = (payload, history) => {

  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    
    return axios
      .get(`${config.INVENTORY_BASE_URL}${config.API_VERSION}/${config.rootContextInventory}/inventoryItem/getOnSaleItem?relationshipId=${payload.relationshipId}&warehouseMasterId=0&pageSize=${payload.pageSize || 10}&pageNumber=${payload.pageNumber - 1}&warehouseMasterId=${payload.warehouseMasterId ||0}`
      )
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: ECOMMERCE_ACTION_LIST.ON_SALE_PRODUCT_LIST, data });
      })
      .catch((err) => {
        if(!err.__isRetryRequest){
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = ECOMMERCE_ACTION_LIST.ON_SALE_PRODUCT_LIST;
          err.data = {
            pageNo: payload.pageNumber,
            list: []
          };
        }
      })
  }
}
export const fetchCollectionProducts = (payload, history) => {

  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    
    return axios
      .get(`${config.INVENTORY_BASE_URL}${config.API_VERSION}/${config.rootContextInventory}/inventoryItem/getCollectionItem?relationshipId=${payload.relationshipId}&warehouseMasterId=0&pageSize=${payload.pageSize || 10}&pageNumber=${payload.pageNumber - 1}&warehouseMasterId=${payload.warehouseMasterId ||0}`
      )
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: ECOMMERCE_ACTION_LIST.COLLECTION_PRODUCT_LIST, data });
      })
      .catch((err) => {
        if(!err.__isRetryRequest){
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = ECOMMERCE_ACTION_LIST.COLLECTION_PRODUCT_LIST;
          err.data = {
            pageNo: payload.pageNumber,
            list: []
          };
        }
      })
  }
}
export const getBestSellingProductsCount = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  const searchedTextQuery = payload.searchedText && payload.searchedText.length ? `&searchString=${payload.searchedText}` : '';
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.INVENTORY_BASE_URL}${config.API_VERSION}/${config.rootContextInventory}/inventoryItem/countBestSellingItem?relationshipId=${payload.relationshipId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}&warehouseMasterId=${payload.warehouseMasterId ||0}${searchedTextQuery}`)
      .then(res => {
        dispatch({ type: ECOMMERCE_ACTION_LIST.BEST_SELLING_PRODUCT_COUNT, data: res.data });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = ECOMMERCE_ACTION_LIST.BEST_SELLING_PRODUCT_COUNT;
        }
      })
  }
}
export const getOnSaleProductsCount = (payload, history) => {

  const searchedTextQuery = payload.searchedText && payload.searchedText.length ? `&searchString=${payload.searchedText}` : '';
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.INVENTORY_BASE_URL}${config.API_VERSION}/${config.rootContextInventory}/inventoryItem/countOnSaleItem?relationshipId=${payload.relationshipId}&startDate=${payload.startDate||'2019-04-01%2000:00:00.0'}&endDate=${payload.endDate||'2020-03-31%2023:59:59.0'}&warehouseMasterId=${payload.warehouseMasterId ||0}${searchedTextQuery}`)
      .then(res => {
        dispatch({ type: ECOMMERCE_ACTION_LIST.ON_SALE_PRODUCT_COUNT, data: res.data });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          // showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = ECOMMERCE_ACTION_LIST.ON_SALE_PRODUCT_COUNT;
        }
      })
  }
}
export const getCollectionProductsCount = (payload, history) => {

  const searchedTextQuery = payload.searchedText && payload.searchedText.length ? `&searchString=${payload.searchedText}` : '';
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.INVENTORY_BASE_URL}${config.API_VERSION}/${config.rootContextInventory}/inventoryItem/countCollectionItem?relationshipId=${payload.relationshipId}&startDate=${payload.startDate||'2019-04-01%2000:00:00.0'}&endDate=${payload.endDate||'2020-03-31%2023:59:59.0'}&warehouseMasterId=${payload.warehouseMasterId ||0}${searchedTextQuery}`)
      .then(res => {
        dispatch({ type: ECOMMERCE_ACTION_LIST.COLLECTION_PRODUCT_COUNT, data: res.data });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          // showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = ECOMMERCE_ACTION_LIST.COLLECTION_PRODUCT_COUNT;
        }
      })
  }
}
export const resetBestSellingProductsPage = () => {
  return dispatch => {
    dispatch({ type: ECOMMERCE_ACTION_LIST.BEST_SELLING_PRODUCT_LIST_PAGE_RESET });
  }
}
export const resetOnSaleProductsPage = () => {
  return dispatch => {
    dispatch({ type: ECOMMERCE_ACTION_LIST.ON_SALE_PRODUCT_LIST_PAGE_RESET });
  }
}
export const resetCollectionProductsPage = () => {
  return dispatch => {
    dispatch({ type: ECOMMERCE_ACTION_LIST.COLLECTION_PRODUCT_LIST_PAGE_RESET });
  }
}
export const exportExcel = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios.get(`${config.INVENTORY_BASE_URL}${config.API_VERSION}/${config.rootContextInventory}/inventoryExcel/getSelectedInventoryExcel?relationshipId=${payload.relationshipId}&itemIds=${payload.itemIds}`,
      {
        responseType: 'blob',
      }
    )
      .then(response => {
        const content = response.headers['content-type'];
        download(response.data, 'Product.xlsx', content);
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch(err => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
  }
}

export const updateBestSellingItem = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios.post(`${config.INVENTORY_BASE_URL}${config.API_VERSION}/${config.rootContextInventory}/eCommerce/updateBestSellingItem`, payload)
        .then(res => {
          showToasterMessage({
            description: (res || {}).message || 'Save successfully.',
            messageType: 'success'
          });
          props.fetchProducts && props.fetchProducts(props);
          props.fetchBestSellingProducts && props.fetchBestSellingProducts(props)
        })
        .catch((err = {}) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (err.__isRetryRequest) {
          } else {
            showToasterMessage({
              description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
            })
          }
        })
    }
}
export const updateOnSaleItem = (payload, props) => {
  
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios.post(`${config.INVENTORY_BASE_URL}${config.API_VERSION}/${config.rootContextInventory}/eCommerce/updateOnSaleItem`, payload)
        .then(res => {
          showToasterMessage({
            description: (res || {}).message || 'Save successfully.',
            messageType: 'success'
          });
          const val = {  relationshipId: payload.relationshipId, pageNumber: payload.pageNumber, pageSize: payload.pageSize };
          props.fetchProducts && props.fetchProducts(props);
          props.fetchOnSaleProducts && props.fetchOnSaleProducts(val);
        })
        .catch((err = {}) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (err.__isRetryRequest) {
          } else {
            showToasterMessage({
              description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
            })
          }
        })
    }
}
export const updatePublisedItem = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios.post(`${config.INVENTORY_BASE_URL}${config.API_VERSION}/${config.rootContextInventory}/eCommerce/updatePublisedItem`, payload)
        .then(res => {
          showToasterMessage({
            description: (res || {}).message || 'Save successfully.',
            messageType: 'success'
          });
          props.fetchProducts && props.fetchProducts(props);
          props.fetchPublishedProducts && props.fetchPublishedProducts(props)
        })
        .catch((err = {}) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (err.__isRetryRequest) {
          } else {
            showToasterMessage({
              description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
            })
          }
        })
    }
}

export const updateClearanceItem = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios.post(`${config.INVENTORY_BASE_URL}${config.API_VERSION}/${config.rootContextInventory}/eCommerce/updateClearanceItem`, payload)
        .then(res => {
          showToasterMessage({
            description: (res || {}).message || 'Save successfully.',
            messageType: 'success'
          });
          props.fetchProducts && props.fetchProducts(props);
          props.fetchClearanceProducts && props.fetchClearanceProducts(props);
        })
        .catch((err = {}) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (err.__isRetryRequest) {
          } else {
            showToasterMessage({
              description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
            })
          }
        })
    }
}


export const updateDealOfTheDayItem = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios.post(`${config.INVENTORY_BASE_URL}${config.API_VERSION}/${config.rootContextInventory}/eCommerce/updateDealOfTheDayItem`, payload)
        .then(res => {
          showToasterMessage({
            description: (res || {}).message || 'Save successfully.',
            messageType: 'success'
          });
          props.fetchProducts && props.fetchProducts(props);
          props.fetchDealOfTheDayProducts && props.fetchDealOfTheDayProducts(props);
        })
        .catch((err = {}) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (err.__isRetryRequest) {
          } else {
            showToasterMessage({
              description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
            })
          }
        })
    }
}


export const updateCollectiveItems = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios.post(`${config.INVENTORY_BASE_URL}${config.API_VERSION}/${config.rootContextInventory}/eCommerce/updateCollectiveItems`, payload)
        .then(res => {
          showToasterMessage({
            description: (res || {}).message || 'Save successfully.',
            messageType: 'success'
          });
          props.fetchProducts && props.fetchProducts(props);
          props.fetchCollectionProducts && props.fetchCollectionProducts(props);
        })
        .catch((err = {}) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (err.__isRetryRequest) {
          } else {
            showToasterMessage({
              description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
            })
          }
        })
    }
}


export const updateNewArrivalItem = (payload, props) => {

 
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios.post(`${config.INVENTORY_BASE_URL}${config.API_VERSION}/${config.rootContextInventory}/eCommerce/updateNewArrivalItem`, payload)
        .then(res => {
          props.fetchProducts && props.fetchProducts(props);
          props.fetchNewArrivalProducts &&  props.fetchNewArrivalProducts(props);
          showToasterMessage({
            description: (res || {}).message || 'Save successfully.',
            messageType: 'success'
          });
         
        })
        .catch((err = {}) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (err.__isRetryRequest) {
          } else {
            showToasterMessage({
              description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
            })
          }
        })
    }
}