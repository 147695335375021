import axios from 'axios';
import React from 'react';
import config from '../../../../config/environmentConfig';
import { COMMON_ACTIONS, SUPPLIER_PAYMENT_ACTION_LIST, CONSTANTS } from '../../../../static/constants'
import { showToasterMessage, getCurrentDateForBackend, formatDateForBackend, getFinancialYear } from '../../../../utils';
import { fetchPurchaseInvoices, resetPaginatedData} from '../Listing/action'
import {fetchSupplierPayments } from '../../payment/Listing/action';
import SupplierDetails from "../../../supplier/detail";

const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const createSupplierPayment = (payloadObject) => {
  const history = payloadObject.history;
  const payload = _getPaymentData(payloadObject);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/supplierPayment/createSupplierPayment`, payload)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        payloadObject.hideModal();
        if(history.location?.pathname  === "/admin/suppliers"){
           payloadObject.popModalFromStack()
           payloadObject.pushModalToStack({
            modalBody: (
              <SupplierDetails
                {...payloadObject}
                supplierId={payloadObject.supplierId}
                supplierName={payloadObject.supplierStoreName}
                supplier={payloadObject}
              />
            ),
            width: "100%",
            // title:'Supplier',
            hideTitle: true,
            hideFooter: true,
            wrapClassName: "new-transaction-wrapper",
          });
        }
        let data = {
          relationshipId: payloadObject.companyInfo.relationshipId || 0,
          pageSize: 100,
          pageNumber: 1,
        }
        fetchSupplierPayments(data)(dispatch); 
        if(!(history.location?.pathname  === "/admin/suppliers")){

          history.push('/admin/supplier-payment-list');
        }
        showToasterMessage({ messageType: 'success', description: 'Payment saved successfully' });
        resetPaginatedData()(dispatch);
        fetchPurchaseInvoices(data)(dispatch);
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        payloadObject.hideModal();
        payloadObject.hideModal();
        let data = {
          relationshipId: payloadObject.companyInfo.relationshipId || 0,
          pageSize: 100,
          pageNumber: 1,
        }
        showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        resetPaginatedData()(dispatch);
        fetchPurchaseInvoices(data)(dispatch);
      })
  }
}

const _getPaymentData = (formData) => {
  let payload = {};
  let supplierName = formData.supplierName;
  let createdByUserId = formData.userInfo.userId || 0;
  let relationshipId = formData.companyInfo.relationshipId || 0;
  let finYear = getFinancialYear(formData.companyInfo);

  payload.paymentDate = formatDateForBackend(formData.paymentDate);
  payload.reference = formData.reference;
  payload.createdByEmpId = formData.userInfo && formData.userInfo.relationshipEmployeeId; 
  let txList = {
    fyStartDate: finYear && finYear.fyStartDate && formatDateForBackend(finYear.fyStartDate),
    fyEndDate: finYear && finYear.fyEndDate && formatDateForBackend(finYear.fyEndDate),
    relationshipId: relationshipId,
    //dateCreated: getCurrentDateForBackend(new Date()),
    createdByUserId: createdByUserId,
    txType: 'Payment',
    instrumentNumber: formData.paymentModeDetail,
    instrumentDate: formatDateForBackend(formData.paymentDate),
    txDate: formatDateForBackend(formData.paymentDate),
    accountingEntry: 'CREDIT',
    paymentModeId: formData.paymentModeId,
    invoiceMasterId: formData.invoiceMasterId,
    remarks: formData.remarks,
    availableCredit: formData.availableCredit,
    amount: Number(formData.amount).toFixed(2),
    paymentModeDetail: formData.paymentModeDetail,
    supplierId: formData.supplierId,
    supplierName: supplierName,
    bankDate: formatDateForBackend(new Date() ),
    paymentMode: formData.paymentModeName,
    currencyCode: formData.companyInfo.currencyCode,
    ledgerAccountId: formData.chartOfAccountDetailsId,
    customerId: formData.customerId,
    customerName: formData.customerName,
  };

  var txList1 = {
    relationshipId: relationshipId,
    //dateCreated: getCurrentDateForBackend(new Date()),
    createdByUserId: createdByUserId,
    fyStartDate: finYear && finYear.fyStartDate && formatDateForBackend(finYear.fyStartDate),
    fyEndDate: finYear && finYear.fyEndDate && formatDateForBackend(finYear.fyEndDate),
    txType: 'Payment',
    instrumentNumber: formData.paymentModeDetail,
    instrumentDate: formatDateForBackend(formData.paymentDate),
    txDate: formatDateForBackend(formData.paymentDate),
    accountingEntry: 'DEBIT',
    paymentModeId: formData.paymentModeId,
    remarks: formData.remarks,
    invoiceMasterId: formData.invoiceMasterId,
    availableCredit: formData.availableCredit,
    amount: Number(formData.amount).toFixed(2),
    paymentModeDetail: formData.paymentModeDetail,
    supplierName: supplierName,
    paymentMode: formData.paymentModeName,
    supplierId: formData.supplierId,
    bankDate: formatDateForBackend(new Date()),
    ledgerAccountId: (formData.creditorsLedger || {}).ledgerAccountId
  };

  var customerTxPayment = {
    relationshipId: relationshipId,
    txAmount: Number(formData.amount).toFixed(2),
    paymentModeDetail: formData.paymentModeDetail,
    fyStartDate: finYear && finYear.fyStartDate && formatDateForBackend(finYear.fyStartDate),
    fyEndDate: finYear && finYear.fyEndDate && formatDateForBackend(finYear.fyEndDate),
    status: 'Processed',
    statusColor: ' success',
    txDate: formatDateForBackend(formData.paymentDate),
    txType: 'Payment',
    invoiceNumber: formData.invoiceData.invoiceNumber,
    txNumber: formData.invoiceData.invoiceNumber,
    invoiceMasterId: formData.invoiceMasterId,
    isMultiCurrency: formData.invoiceData.isMultiCurrency,
    currencyCode: formData.invoiceData.isMultiCurrency ? formData.invoiceData.foreignCurrency : formData.companyInfo.currencyCode,
    exchangeRate: formData.exchangeRate,
    remarks: formData.remarks,
    invoiceDueDate: formData.invoiceData.invoiceDueDate,
    paymentModeId: formData.paymentModeId,
    //dateCreated: getCurrentDateForBackend(new Date()),
    createdByUserId: createdByUserId,
    supplierId: formData.supplierId,
    poNumber: formData.invoiceData.supplierPoNumber,
    poMasterId: formData.invoiceData.poMasterId,
  }
  payload.supplierId = formData.supplierId;
  payload.fyStartDate = finYear && finYear.fyStartDate && formatDateForBackend(finYear.fyStartDate);
  payload.fyEndDate = finYear && finYear.fyEndDate && formatDateForBackend(finYear.fyEndDate);
  payload.remarks = formData.remarks;
  payload.poNumber = formData.invoiceData.supplierPoNumber;
  payload.poMasterId = formData.invoiceData.poMasterId;
  //txList.ledgerAccountId = $scope.debtorsLedgerAccount && $scope.debtorsLedgerAccount.chartOfAccountDetailsId;
  //txList1.ledgerAccountId = formData.bankledgerId || ($scope.cashInHandLedgerAccount && $scope.cashInHandLedgerAccount.chartOfAccountDetailsId);

  payload.coaTxPaymentList = [txList, txList1];
  payload.txDate = formatDateForBackend(formData.paymentDate);
  payload.customerTxPaymentList = [customerTxPayment];

  payload.invoiceAmount = formData.invoiceData.invoiceTotalAmount;
  payload.relationshipId = relationshipId;
  payload.chartOfAccountDetailsId = formData.chartOfAccountDetailsId;
  payload.currentBalanceDue = formData.dueAmount - (formData.amount || 0);
  payload.txType = 'Payment';
  payload.paymentModeId = formData.paymentModeId;
  payload.paymentModeName = formData.paymentModeName;
  payload.remarks = formData.remarks;
  payload.invoiceMasterId = formData.invoiceMasterId;
  payload.status = "Processed";
  payload.statusColor = " success";
  payload.invoiceNumber = formData.invoiceData.invoiceNumber;
  payload.txNumber = formData.invoiceData.invoiceNumber;
  payload.paymentAmount = formData.amount;
  payload.paymentModeDetail = formData.paymentModeDetail;
  payload.isMultiCurrency = formData.invoiceData.isMultiCurrency;
  payload.currencyCode = formData.invoiceData.isMultiCurrency ? formData.invoiceData.foreignCurrency : formData.companyInfo.currencyCode;
  payload.exchangeRate = formData.exchangeRate;
  payload.templateCode = formData.invoiceData.templateCode;
  payload.relationshipName = formData.companyInfo.storeName;
  payload.projectNumber = formData.invoiceData.projectNumber;
  payload.projectName = formData.invoiceData.projectName;
  payload.projectMasterId = formData.invoiceData.projectMasterId;
  payload.supplierName = supplierName;
  payload.rfqNumber = formData.invoiceData.rfqNumber;
  payload.rfqMasterId = formData.invoiceData.rfqMasterId;
  payload.paymentSource = CONSTANTS.PAYMENT_SOURCE.MANUAL;
  payload.customerPONumber = (formData.invoiceData || {}).customerPONumber || '';
  payload.customerRFQNumber = (formData.invoiceData || {}).customerRFQNumber || '';
  payload.customerInquiryNumber = (formData.invoiceData || {}).customerInquiryNumber || '';
  
  return payload;
}

export const getNextTxNumber = (payload) => {
  let date = payload.date ? getCurrentDateForBackend(payload.date) : getCurrentDateForBackend(new Date());
  return dispatch => {
    dispatch({ type: SUPPLIER_PAYMENT_ACTION_LIST.NEXT_SUPPLIER_PAYMENT_NUMBER_RESET});
    dispatch({ type: COMMON_ACTIONS.SHOW_DETAIL_LOADER });
    return axios
      .get(`${config.PURCHASE_BASE_URL}${config.API_VERSION}/${config.rootContextPurchase}/supplierPayment/getNextPaymentNumber?date=${date}&relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: SUPPLIER_PAYMENT_ACTION_LIST.NEXT_SUPPLIER_PAYMENT_NUMBER, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = SUPPLIER_PAYMENT_ACTION_LIST.NEXT_SUPPLIER_PAYMENT_NUMBER;
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_DETAIL_LOADER,
        });
      })
  }
}