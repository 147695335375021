import axios from 'axios';
import config from '../../../../config/environmentConfig';
import {  COMMON_ACTIONS } from '../../../../static/constants'
import { showToasterMessage, getCurrentDateForBackend } from '../../../../utils';
import { fetchAllJournals } from '../action';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const createJournal = (payloadObject) => {
    const payload = _getJournalData(payloadObject);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.ACCOUNTING_BASE_URL}${config.API_VERSION}/${config.rootContextAccounting}/journalEntryMaster/createJournal`, payload)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({ messageType: 'success', description: ((res || {}).data || {}).message || 'Journal Created Successfully' });
        fetchAllJournals(payload)(dispatch);
        payloadObject.props.history.push("/admin/show-journals");
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}

const _getJournalData = (payload) =>{
    const props = payload.props;
    const state = payload.state;
    const gridObject = payload.gridObject;
    const companyInfo = props.companyInfo || {};
    const userInfo = props.userInfo || {};
    const journalMaster = {
        version: props.version,
        relationshipId: companyInfo.relationshipId,
        narration: props.narration,
        journalDate: getCurrentDateForBackend(props.journalDate),
        totalAmount: state.totalDebit,
        //dateCreated: getCurrentDateForBackend(new Date()),
        createdByUserId: userInfo && userInfo.userId,
        journalEntryDetailsList:[],
        journalCOATxList: [],
        costCenters: []
    };

    gridObject.forEachNode((rowNode, index) => {
        const { data } = rowNode;

        if(!data.debit &&  !data.credit) return;
        const detail = {
            ledgerAccountIdDebit: Number(data.chartOfAccountDetailsId),
            debitAmount: Number(data.debit),
            creditAmount: Number(data.credit),
            accountingEntry: Number(data.credit) ? "credit": "debit",
            relationshipId: companyInfo.relationshipId,
            description: data.description,
            ledgerAccountId: Number(data.chartOfAccountDetailsId),
            ledgerAccountName: data.ledgerAccountName
        }

        const coaTx = {
            relationshipId: companyInfo.relationshipId,
            amount:  Number(data.debit ) ||  Number(data.credit),
            txDate: getCurrentDateForBackend(props.journalDate),
            ledgerAccountId: Number(data.chartOfAccountDetailsId),
            accountingEntry: Number(data.credit) ? "credit": "debit",
            costCenterId: 0,
            txType: "Journal",
            //dateCreated: getCurrentDateForBackend(new Date()),
            createdByUserId: userInfo.userId,
            ledgerAccountName: data.ledgerAccountName
        }

        journalMaster.journalCOATxList.push(coaTx);
        journalMaster.journalEntryDetailsList.push(detail);
    })

    return journalMaster;
}