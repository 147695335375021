import { notification } from 'antd';
import * as get from 'lodash.get';
import moment from 'moment-timezone';
import { CONSTANTS, PERMISSION_VALUES } from '../static/constants';
import { PLACEHOLDER_CONSTANTS } from '../static/placeholderConstants';
import exportFromJSON from 'export-from-json';
import config from '../config/environmentConfig';

export const showToasterMessage = (payload = {}) => {
  if(!payload.messageType){
    payload.messageType = 'error';
  }
  // notification[payload.messageType || 'error'](
  //   {
  //     message: payload.message || (payload.messageType === "error" ? 'Error' : (payload.messageType === "success" ? 'Success': 'Warning')),
  //     description: payload.description || 'Error',
  //     ...payload,
  //     duration: 6,
     
  //   }
  // )

  notification[payload.messageType || 'error'](
    {
      message:  (payload.messageType === "error" ? 'Error' : (payload.messageType === "success" ? 'Success': 'Warning'))+ " ! " +(payload.description || 'Error'),
      
    
      duration: 6,
      placement: 'bottom',
      className: payload.messageType +" "+ 'custom-notification'
    }
  )
}

export const validateEmail = (val) => {
  return !!(val && val.toLowerCase().match(CONSTANTS.EMAIL_REGEX_EXP))
}


export const validateName = (name) => {
  return (CONSTANTS.NAME_REGEX_EXP).test(name) && name?.length > 1;
}

export const validateWebsite = (val) => {
  return !!(val.match(CONSTANTS.WEBSITE_URL_REGEX))
  
  // return !!(val && val.toLowerCase().match(CONSTANTS.WEBSITE_URL_REGEX))
}

export const getMomentDateForUI = (data={}) =>{
  return moment((data.date || new Date()), (data.format || CONSTANTS.DISPLAY_DATE_FORMAT));
}

export const getMomentDateForUIReadOnly = (data) =>{
  return moment(new Date(data.date || new Date())).format(data.format || CONSTANTS.DISPLAY_DATE_FORMAT);
}

export const getMomentLoclDateForUIReadOnly = (data) =>{
  var toDt = data.date ? moment.utc(new Date(data.date), "YYYY-MM-DD HH:mm:ss").toDate(): moment(new Date()).format(data.format || CONSTANTS.DISPLAY_DATE_FORMAT);
  return moment(toDt).format(data.format || CONSTANTS.DISPLAY_DATE_FORMAT);
}

export const getLocalDateFromUtcStr = (data) =>{
  var toDt = data.date ? moment.utc(data.date, "YYYY-MM-DD HH:mm:ss").local().format(data.format || CONSTANTS.DISPLAY_DATE_FORMAT) : '';
  return toDt;
}

export const addToLocalStorage = (keyName, value) => {
  sessionStorage.setItem([keyName], value);
}

export const addToLocalMemory = (keyName, value) => {
  localStorage.setItem([keyName], value);
}

export const fetchFromLocalStorage = (keyName) => {
  return sessionStorage.getItem([keyName]);
}

export const fetchFromLocalMemory = (keyName) => {
  return localStorage.getItem([keyName]);
}

export const deleteFromLocalStorage = (keyName) => {
  sessionStorage.removeItem([keyName]);
}

export const deleteFromLocalMemory = (keyName) => {
  localStorage.removeItem([keyName]);
}

export const clearLocalStorage = () => {
  sessionStorage.removeItem("expires_in");
  sessionStorage.removeItem("refresh_token");
  sessionStorage.removeItem("logged_in_entity");
  sessionStorage.removeItem("permission_data");
  sessionStorage.removeItem("access_token");
}

export const getCompanyInfo = () => {
  const loggedInEntityData = sessionStorage.getItem("logged_in_entity") ? JSON.parse(sessionStorage.getItem("logged_in_entity")) : {};
  return loggedInEntityData.defaultCompany;

}

export const fetchDataIfNeeded = (methodName, propName, props, payload, isForceUpdate) => {
  props[methodName](payload, props.history);
  // isForceUpdate = true;
  // let isDataAvailable = false;
  // if (props[propName] instanceof Number) { isDataAvailable = props[propName] > 0 ? false : true };
  // if (props[propName] instanceof Object) { isDataAvailable = Object.keys(props[propName]).length > 0 ? false : true };
  // if (props[propName] instanceof Array) { isDataAvailable = props[propName].length > 0 ? false : true };
  // if (isForceUpdate || !props[propName] || isDataAvailable) {
  //   props[methodName](payload, props.history);
  // }
}

export const fetchPaginationDataIfNeeded = (methodName, propName, props, payload, isForceUpdate) => {
  props[methodName](payload, props.history);
  // isForceUpdate = true;
  // if (isForceUpdate || !props[propName] || !props[propName][payload.pageNumber || 0] || !props[propName][payload.pageNumber || 0].length) {
  //   props[methodName](payload, props.history);
  // }
}

// export const fetchPaginationDataIfNeeded = (methodName, propName, props, payload, isForceUpdate) => {
//   let totalRecords = 0;
//   if (payload && payload.pageSize) {
//     totalRecords = (payload.pageSize * (payload.pageNumber || 0) - 1) + payload.pageSize;
//   }
//   if (isForceUpdate || !props[propName] || !props[propName].length || props[propName].length < totalRecords) {
//     props[methodName](payload, props.history);
//   }
// }

export const removeDuplicateObject = (arr, uniqueKey) => {
  let set = new Set();
  let unionArray = arr.filter(item => {
    if (!set.has(item[uniqueKey])) {
      set.add(item[uniqueKey]);
      return true;
    }
    return false;
  }, set);
  return unionArray;
}

export const base64Toblob = (dataURI) => {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  let byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to an ArrayBuffer
  let ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  let ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  let blob = new Blob([ab], { type: mimeString });
  return blob;
}

export const addDaysToDate = (date, days) => {
  date = new Date(date);
  date.setDate(date.getDate() + (days || 0));
  return date;
}

export const getDaysBetweenDates = (startDate, endDate) => {
  let date1 = new Date(startDate);
  let date2 = new Date(endDate);
  let difference = date2.getTime() - date1.getTime();
  let totalDays = Math.ceil(difference / (1000 * 3600 * 24));
  return totalDays;
}

export const getMonthsBetweenDates = (startDate, endDate) => {
  let date1 = new Date(startDate);
  let date2 = new Date(endDate);
  return (date2.getMonth() - date1.getMonth() + (12 * (date2.getFullYear() - date1.getFullYear())) ) || 1;
}
export const getTimeBetweenDates = (startDate, endDate) => {
  
  if(startDate && endDate){
    const start = new Date(startDate);
    const end = new Date(endDate);

    const differenceInMilliseconds = Math.abs(end - start);
    const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);

    const hours = Math.floor(differenceInSeconds / 3600);
    const minutes = Math.floor((differenceInSeconds % 3600) / 60);
    const seconds = differenceInSeconds % 60;

    const formattedDifference = `${hours} h ${minutes} m${minutes !== 1 ? 's' : ''} ${seconds} s${seconds !== 1 ? 's' : ''}`;
return formattedDifference;

  }else return null
}
export const getAgeFromDOB = (dob) => {
  let birthDate = new Date(dob);
  let today = new Date();
  let month = 0;
  var year = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    year--;
    month = 12 + m;
  } else {
    month = m;
  }
  let age = '';
  if (year) {
    age = `${year} ${year > 1 ? 'yrs' : 'yr'}`
  }
  if (month) {
    age += ` ${month} ${month > 1 ? 'months' : 'month'}`
  }
  return age;
} 

export const getCurrentDateForBackend = (dt) => {

  // let today = dt ? new Date(dt) : new Date();
  // let dd = today.getDate();
  // let mm = today.getMonth() + 1; //January is 0!
  // let yyyy = today.getFullYear();
  // let hr = today.getHours();
  // let min = today.getMinutes();
  // let second = today.getSeconds();

  // if (dd < 10) {
  //   dd = '0' + dd
  // }

  // if (mm < 10) {
  //   mm = '0' + mm
  // }

  // let date = yyyy + '-' + mm + '-' + dd + ' ' + hr + ':' + min + ':' + second;

  // let ue = new Date(date);
  // let fullYear = ue.getFullYear();
  // let month = ("0" + (ue.getMonth() + 1)).slice(-2);
  // date = ("0" + ue.getDate()).slice(-2);
  // let seconds = ("0" + ue.getSeconds()).slice(-2);
  // let minutes = ("0" + ue.getMinutes()).slice(-2);
  // let hours = ("0" + ue.getHours()).slice(-2);
  // let fullDate = fullYear + '-' + month + '-' + date + ' ' + hours + ':' + minutes + ':' + seconds;
  // let fullDate = moment.utc(new Date(dt)).format();
  let fullDate = moment.utc(new Date(dt)).format("YYYY-MM-DD HH:mm:ss");
  fullDate = fullDate.replace("T", " ")
  fullDate = fullDate.replace("Z", " ")
  return fullDate;
};

export const formatDateForBackend = (date) => {

  // let ue = new Date(date);
  // let fullYear = ue.getFullYear();
  // let month = ("0" + (ue.getMonth() + 1)).slice(-2);
  // let day = ("0" + ue.getDate()).slice(-2);
  // let seconds = ("0" + ue.getSeconds()).slice(-2);
  // let minutes = ("0" + ue.getMinutes()).slice(-2);
  // let hours = ("0" + ue.getHours()).slice(-2);
  // //2016-06-18 13:5:59
  // let fullDate = fullYear + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
  // fullDate = moment.utc(fullDate).format()
  // let fullDate = moment.utc(new Date(date)).format();
  let fullDate = moment.utc(new Date(date)).format("YYYY-MM-DD HH:mm:ss");

  fullDate = fullDate.replace("T", " ")
  fullDate = fullDate.replace("Z", " ")
  return fullDate;
};

export const formatDateForBackendPlain = (date) => {

  // let ue = new Date(date);
  // let fullYear = ue.getFullYear();
  // let month = ("0" + (ue.getMonth() + 1)).slice(-2);
  // let day = ("0" + ue.getDate()).slice(-2);
  // let seconds = ("0" + ue.getSeconds()).slice(-2);
  // let minutes = ("0" + ue.getMinutes()).slice(-2);
  // let hours = ("0" + ue.getHours()).slice(-2);
  // //2016-06-18 13:5:59
  // let fullDate = fullYear + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
  // fullDate = moment.utc(fullDate).format()
  // let fullDate = moment.utc(new Date(date)).format();
  let fullDate = moment(new Date(date)).format("YYYY-MM-DD HH:mm:ss");

  fullDate = fullDate.replace("T", " ")
  fullDate = fullDate.replace("Z", " ")
  return fullDate;
};

export const formatDateFilterForBackend = (date, dateType) => {
  let tempDate = new Date(date);
  if (dateType === CONSTANTS.FILTER_DATE_TYPE.END) {
    tempDate.setHours(23, 59, 59, 999);
  } else {
    tempDate.setHours(0, 0, 0, 0)
  }
  let fullDate = moment.utc(tempDate).format("YYYY-MM-DD HH:mm:ss");
  fullDate = fullDate.replace("T", " ")
  fullDate = fullDate.replace("Z", " ")
  return fullDate;
};


export const formatDateForBackendFY = (date) => {

  let ue = new Date(date);
  let fullYear = ue.getFullYear();
  let month = ("0" + (ue.getMonth() + 1)).slice(-2);
  let day = ("0" + ue.getDate()).slice(-2);
  let seconds = ("0" + ue.getSeconds()).slice(-2);
  let minutes = ("0" + ue.getMinutes()).slice(-2);
  let hours = ("0" + ue.getHours()).slice(-2);
  //2016-06-18 13:5:59
  let fullDate = fullYear + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
  //fullDate = moment.utc(fullDate).format()
  return fullDate;
};

export const getPartialDateForBackend = (date, dateType, returnPart) => {
  let tempDate = new Date(date);
  if (dateType === CONSTANTS.FILTER_DATE_TYPE.END) {
    tempDate.setHours(23, 59, 59, 999);
  } 
  if(dateType === CONSTANTS.FILTER_DATE_TYPE.START) {
    tempDate.setHours(0, 0, 0, 0)
  }
  let dateObj = moment.utc(tempDate);
  
  switch(returnPart) {
    case CONSTANTS.DATE_PART_TYPE.DAY : {
      return dateObj.format("DD")
     // break;
    }
    case CONSTANTS.DATE_PART_TYPE.MONTH : {
      return dateObj.format("MM")
    }
    case CONSTANTS.DATE_PART_TYPE.YEAR : {
      return dateObj.format("YYYY")
    }
    default: {
      let fullDate = dateObj.format("YYYY-MM-DD HH:mm:ss");
      fullDate = fullDate.replace("T", " ")
      fullDate = fullDate.replace("Z", " ")
      return fullDate;
    }
  }

};

export const getFinancialYear = (companyInfo = {}) => {
  let FY = {};
  if(companyInfo && companyInfo.fyStartDate){
    let fyStartDate = new Date(companyInfo.fyStartDate);
    let fyEndDate = new Date(companyInfo.fyEndDate);
    fyStartDate.setHours(0, 0, 0, 0)
      fyEndDate.setHours(23, 59, 59, 999);
    let fullStartDate = moment.utc(fyStartDate).format("YYYY-MM-DD HH:mm:ss");
    fullStartDate = fullStartDate.replace("T", " ")
    fullStartDate = fullStartDate.replace("Z", " ")

    let fullEndDate = moment.utc(fyEndDate).format("YYYY-MM-DD HH:mm:ss");
    fullEndDate = fullEndDate.replace("T", " ")
    fullEndDate = fullEndDate.replace("Z", " ")

    FY.fyStartDate = fullStartDate;
    FY.fyEndDate = fullEndDate;
  }
  
  return FY;
};

export const bytesToSize = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const downloadDataFromAWS = (bucketName, key, fileName) => {
  let xhr = new XMLHttpRequest();
  xhr.open("GET", "https://s3.amazonaws.com/" + bucketName + "/" + key, true);
  xhr.responseType = "blob";
  xhr.onload = function () {
    let urlCreator = window.URL || window.webkitURL;
    let imageUrl = urlCreator.createObjectURL(this.response);
    let tag = document.createElement('a');
    tag.href = imageUrl;
    tag.download = fileName;
    document.body.appendChild(tag);
    tag.click();
    document.body.removeChild(tag);
  }
  xhr.send();
}

export const getAWSFileURL = (awsKey, bucketName, folderName) => {
 
  if (!awsKey) return null;
 
  if (bucketName && folderName){
    return 'https://s3.amazonaws.com/' + bucketName + '/'+ folderName + '/' + awsKey + '?' + Date.now();
  }
  else if (bucketName) {
    return 'https://s3.amazonaws.com/' + bucketName + '/' + awsKey + '?' + Date.now();
  } 
  else {
    return 'https://s3.amazonaws.com/bo-relationship\\' + awsKey + '?' + Date.now();
  }
}

export const checkSecondaryPermission = (secondary = {}, keyPath) => {
  let hasPermission = true;
  if (Object.keys(secondary).length) {
    hasPermission = get(secondary, keyPath);
    if (hasPermission === undefined) {
      hasPermission = true;
    }
  }
  return hasPermission;
}

export const primaryPermissionConversion = (permissions) => {
  const result = {};
  if (Array.isArray(permissions)) {
    permissions.forEach(perm => {
      const permSplitted = perm.split('-');
      if (permSplitted && permSplitted.length === 2 && !isNaN(permSplitted[1])) {
        result[permSplitted[0]] = +permSplitted[1];
      }
    })
  }
  return Object.keys(result).length > 0 ? result : permissions;
}

export const checkACLPermission = (primary, moduleCode, operation) => {
  let hasPermission = false;
  // for single module code
  if(typeof moduleCode === 'object' && operation && primary && Object.keys(primary).length){
    for(let i=0; i<moduleCode?.length; i++){
      if ((primary[moduleCode[i]] & +operation) === 0) {
        hasPermission = false;
      } else {
        hasPermission = true;
        break;
      }
    }
  }else{
    if (moduleCode && operation && primary && Object.keys(primary).length && primary[moduleCode]) {
      if ((primary[moduleCode] & +operation) === 0) {
        hasPermission = false;
      } else {
        hasPermission = true;
      }
    }
  }
  return hasPermission;
}

export const getPermissionData =(primaryPerm , moduleCode)=>{
  const permissionObj = {
    read: false,
    create: false,
    update: false,
    delete: false
  }
  
  if(moduleCode && primaryPerm && Object.keys(primaryPerm).length && primaryPerm[moduleCode]){
   
    permissionObj["read"] =   (primaryPerm[moduleCode] & +(PERMISSION_VALUES.READ)) !== 0 ;
    permissionObj["create"] = (primaryPerm[moduleCode] & +(PERMISSION_VALUES.CREATE)) !== 0;
    permissionObj["update"] = (primaryPerm[moduleCode] & +(PERMISSION_VALUES.UPDATE)) !== 0;
    permissionObj["delete"] = (primaryPerm[moduleCode] & +(PERMISSION_VALUES.DELETE)) !== 0

  }
  
  return permissionObj;
}
export const camelize = (str) => {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
    return index === 0 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/\s+/g, '');
}


export const capitalizeFirstLetter = (string) => {
  if((string || '').length){
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return "";
}

export const fixedDecimalNumber = (number) => {
  const defaultCompany = getCompanyInfo() || {};
  return (Number(number || 0)).toFixed(defaultCompany.decimalDigitsLengthPrice || CONSTANTS.DEFAULT_DECIMAL_NUMBER_LENGTH);
}

export const formatedNumber = (number) => {
  return (number).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const fixedDecimalAndFormateNumber = (number) => {
  const defaultCompany = getCompanyInfo() || {};
  return (Number(number || 0)).toFixed(defaultCompany.decimalDigitsLengthPrice || CONSTANTS.DEFAULT_DECIMAL_NUMBER_LENGTH).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const isValidDateRange = (startDate, endDate) => {
  let date1 = new Date(startDate);
  let date2 = new Date(endDate);
  let isValid = date1.getTime() < date2.getTime();
  return isValid;
}

export const exportExcel = (data, fileName) => {
  exportFromJSON({ data: data, fileName: fileName, exportType: exportFromJSON.types.xls });
  return "";
}

export const exportCSV = (data, fileName) => {
  exportFromJSON({ data: data, fileName: fileName, exportType: exportFromJSON.types.csv });
  return "";
} 

// Number.prototype.fixedDecimalNumber = function () {
//   const defaultCompany = getCompanyInfo() || {};
//   return this.valueOf().toFixed(defaultCompany.decimalDigitsLenth || CONSTANTS.DEFAULT_DECIMAL_NUMBER_LENGTH);
// };

// Number.prototype.fixedDecimalNumber = function () {
//   const defaultCompany = getCompanyInfo() || {};
//   return this.valueOf().toFixed(defaultCompany.decimalDigitsLenth || CONSTANTS.DEFAULT_DECIMAL_NUMBER_LENGTH).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
// };

export const replacePlaceHolders = (str, data, props) => {
  let txData = {};
  let companyInfo = data.companyInfo || {};
  let additionalInfo = {}
  let previewUrl = '';
  // if (data.transactionName === 'salesInvoice') {
  //   txData = data.salesInvoiceDetail;
  //   additionalInfo = (data.salesInvoiceDetail.additionalInfoList || [])[0] || {};
  //   previewUrl = config.UI_BASE_URL + 'invoice?webInvoiceId=' + txData.webInvoiceId;
  // }
  switch (data.transactionName) {
    case "salesOrder": {
      txData = data.soDetail;
      additionalInfo = (data.soDetail.additionalInfoList || [])[0] || {};
      break;
    }
    case 'salesInvoice':
    case 'proformaInvoice': {
      txData = data.salesInvoiceDetail;
      additionalInfo = (data.salesInvoiceDetail.additionalInfoList || [])[0] || {};
      previewUrl = config.UI_BASE_URL + 'invoice?webInvoiceId=' + txData.webInvoiceId;
      break;
    }
    case 'technicalOffer':
    case 'salesQuote': {
      txData = data.sqDetail;
      additionalInfo = (data.sqDetail.additionalInfoList || [])[0] || {};
      break;
    }
    case 'creditMemo': {
      txData = data.creditMemoDetail;
      additionalInfo = (data.creditMemoDetail.additionalInfoList || [])[0] || {};
      break;
    }
    case "purchaseOrder": {
      txData = data.poData;
      additionalInfo = (data.poData.additionalInfoList || [])[0] || {};
      break;
    }
    case 'purchaseInvoice': {
      txData = data.invoiceData;
      additionalInfo = (data.invoiceData.additionalInfoList || [])[0] || {};
      break;
    }
    case 'purchaseRequest': {
      txData = data.prData;
      additionalInfo = (data.prData.additionalInfoList || [])[0] || {};
      break;
    }
    case 'RFQ':
    case 'RFQChild': {
      txData = data.rfqData;
      additionalInfo = (data.rfqData.additionalInfoList || [])[0] || {};
      break;
    }
    case 'debitMemo': {
      txData = data.debitMemoData;
      additionalInfo = (data.debitMemoData.additionalInfoList || [])[0] || {};
      break;
    }
    case 'customerPayment': {
      txData = data.paymentDetail;
      additionalInfo = (data.paymentDetail.additionalInfoList || [])[0] || {};
      break;
    }
    case 'supplierPayment': {
      txData = data.paymentDetail;
      additionalInfo = (data.paymentDetail.additionalInfoList || [])[0] || {};
      break;
    }
    // case 'shippingInvoice': {

    //   break;
    // }
    // case 'deliveryNotes': {

    //   break;
    // }
    // case 'soPackage': {

    //   break;
    // }
    case 'inquiry': {
      txData = data.inquiryData;
      additionalInfo = (data.inquiryData.additionalInfoList || [])[0] || {};
      break;
    }
    case 'inboundDelivery': {
      txData = data.inboundDeliveryData;
      additionalInfo = (data.inboundDeliveryData.deliveryAdditionalInfoList || [])[0] || {};
      break;
    }
    default: {
      txData = {};
      additionalInfo = {};
      break;
    }
  }


  str = str.replaceAll(PLACEHOLDER_CONSTANTS.BALANCE, txData.invoiceTotalAmount);
  str = str.replaceAll(PLACEHOLDER_CONSTANTS.DUE_DATE, getMomentDateForUI(txData.invoiceDueDate || {}));
  str = str.replaceAll(PLACEHOLDER_CONSTANTS.INVOICE_DATE, getMomentDateForUI(txData.invoiceDate || {}));
  str = str.replaceAll(PLACEHOLDER_CONSTANTS.INVOICE_NUMBER, txData.invoiceNumber);
  str = str.replaceAll(PLACEHOLDER_CONSTANTS.INVOICE_URL, previewUrl || '');
  str = str.replaceAll(PLACEHOLDER_CONSTANTS.PROJECT_NAME, txData.projectName || '');
  //str = str.replaceAll(PLACEHOLDER_CONSTANTS.PAYMENT_LINK, txData.invoiceTotalAmount);

  str = str.replaceAll(PLACEHOLDER_CONSTANTS.QUOTE_BALANCE, txData.salesQuoteTotalAmount);
  str = str.replaceAll(PLACEHOLDER_CONSTANTS.BID_CLOSING_DATE, getMomentDateForUI(txData.salesQuoteExpiryDate || {}));
  str = str.replaceAll(PLACEHOLDER_CONSTANTS.QUOTE_DATE, getMomentDateForUI(txData.salesQuoteDate || {}));
  str = str.replaceAll(PLACEHOLDER_CONSTANTS.QUOTE_NUMBER, txData.quotationNumber);
  str = str.replaceAll(PLACEHOLDER_CONSTANTS.PROJECT_NAME, txData.projectName || '');

  str = str.replaceAll(PLACEHOLDER_CONSTANTS.SO_BALANCE, txData.salesOrderTotalAmount);
  str = str.replaceAll(PLACEHOLDER_CONSTANTS.SO_DELIVERY_DATE, getMomentDateForUI(txData.deliveryDate || {}));
  str = str.replaceAll(PLACEHOLDER_CONSTANTS.SO_DATE, getMomentDateForUI(txData.salesOrderDate || {}));
  str = str.replaceAll(PLACEHOLDER_CONSTANTS.SO_NUMBER, txData.soNumber);
  str = str.replaceAll(PLACEHOLDER_CONSTANTS.PROJECT_NAME, txData.projectName || '');

  str = str.replaceAll(PLACEHOLDER_CONSTANTS.SALES_PAYMENT_BALANCE, txData.paymentAmount);
  str = str.replaceAll(PLACEHOLDER_CONSTANTS.SALES_PAYMENT_DATE, getMomentDateForUI(txData.paymentDate || {}));
  str = str.replaceAll(PLACEHOLDER_CONSTANTS.SALES_PAYMENT_NUMBER, txData.paymentNumber || {});
  str = str.replaceAll(PLACEHOLDER_CONSTANTS.PROJECT_NAME, txData.projectName || '');

  str = str.replaceAll(PLACEHOLDER_CONSTANTS.CM_BALANCE, txData.creditMemoTotalAmount);
  str = str.replaceAll(PLACEHOLDER_CONSTANTS.CM_EXPIRY_DATE, getMomentDateForUI(txData.creditMemoExpDate || {}));
  str = str.replaceAll(PLACEHOLDER_CONSTANTS.CM_DATE, getMomentDateForUI(txData.creditMemoDate || {}));
  str = str.replaceAll(PLACEHOLDER_CONSTANTS.CM_NUMBER, txData.creditMemoNumber);
  str = str.replaceAll(PLACEHOLDER_CONSTANTS.PROJECT_NAME, txData.projectName || '');

  str = str.replaceAll(PLACEHOLDER_CONSTANTS.INQUIRY_DATE, getMomentDateForUI(txData.inquiryDate || {}));
  str = str.replaceAll(PLACEHOLDER_CONSTANTS.INQUIRY_EXPIRY_DATE, getMomentDateForUI(txData.inquiryExpirationDate || {}));
  str = str.replaceAll(PLACEHOLDER_CONSTANTS.INQUIRY_NUMBER, txData.inquiryNumber);
  str = str.replaceAll(PLACEHOLDER_CONSTANTS.PROJECT_NAME, txData.projectName || '');
  
  str = str.replaceAll(PLACEHOLDER_CONSTANTS.PI_BALANCE, getMomentDateForUI(txData.invoiceTotalAmount || {}));
  str = str.replaceAll(PLACEHOLDER_CONSTANTS.PROJECT_NAME, txData.projectName || '');
    
  str = str.replaceAll(PLACEHOLDER_CONSTANTS.PO_BALANCE, txData.totalPOAmount);
  str = str.replaceAll(PLACEHOLDER_CONSTANTS.PO_DUE_DATE, getMomentDateForUI(txData.poDueDate || {}));
  str = str.replaceAll(PLACEHOLDER_CONSTANTS.PO_DATE, getMomentDateForUI(txData.poDate || {}));
  str = str.replaceAll(PLACEHOLDER_CONSTANTS.PO_NUMBER, txData.poNumber);
  str = str.replaceAll(PLACEHOLDER_CONSTANTS.PROJECT_NAME, txData.projectName || '');

  str = str.replaceAll(PLACEHOLDER_CONSTANTS.PURCHASE_PAYMENT_BALANCE, txData.paymentAmount);
  str = str.replaceAll(PLACEHOLDER_CONSTANTS.PURCHASE_PAYMENT_DATE, getMomentDateForUI(txData.paymentDate || {}));
  str = str.replaceAll(PLACEHOLDER_CONSTANTS.PURCHASE_PAYMENT_NUMBER, txData.paymentNumber);
  str = str.replaceAll(PLACEHOLDER_CONSTANTS.PROJECT_NAME, txData.projectName || '');

  str = str.replaceAll(PLACEHOLDER_CONSTANTS.DM_BALANCE, txData.debitMemoTotalAmount);
  str = str.replaceAll(PLACEHOLDER_CONSTANTS.DM_EXPIRY_DATE, getMomentDateForUI(txData.debitMemoExpDate || {}));
  str = str.replaceAll(PLACEHOLDER_CONSTANTS.DM_DATE, getMomentDateForUI(txData.debitMemoDate || {}));
  str = str.replaceAll(PLACEHOLDER_CONSTANTS.DM_NUMBER, txData.debitMemoNumber);
  str = str.replaceAll(PLACEHOLDER_CONSTANTS.PROJECT_NAME, txData.projectName || '');

  str = str.replaceAll(PLACEHOLDER_CONSTANTS.RFQ_EXPIRY_DATE, getMomentDateForUI(txData.rfqExpirationDate || {}));
  str = str.replaceAll(PLACEHOLDER_CONSTANTS.RFQ_DATE, getMomentDateForUI(txData.rfqDate || {}));
  str = str.replaceAll(PLACEHOLDER_CONSTANTS.RFQ_NUMBER, txData.rfqNumber);
  str = str.replaceAll(PLACEHOLDER_CONSTANTS.PROJECT_NAME, txData.projectName || '');

  str = str.replaceAll(PLACEHOLDER_CONSTANTS.PR_DATE, getMomentDateForUI(txData.purchaseRequestDate || {}));
  str = str.replaceAll(PLACEHOLDER_CONSTANTS.PR_EXPIRY_DATE, getMomentDateForUI(txData.prExpirationDate || {}));
  str = str.replaceAll(PLACEHOLDER_CONSTANTS.PR_NUMBER, txData.purchaseRequestNumber);
  str = str.replaceAll(PLACEHOLDER_CONSTANTS.PROJECT_NAME, txData.projectName || '');

  str = str.replaceAll(PLACEHOLDER_CONSTANTS.CUSTOMER_NAME, txData.customerName);
  str = str.replaceAll(PLACEHOLDER_CONSTANTS.SALUTATION, additionalInfo.salutationName || '');
  str = str.replaceAll(PLACEHOLDER_CONSTANTS.FIRST_NAME, additionalInfo.firstName || '');
  str = str.replaceAll(PLACEHOLDER_CONSTANTS.LAST_NAME, additionalInfo.lastName || '');
  str = str.replaceAll(PLACEHOLDER_CONSTANTS.EMAIL, txData.emailAddress);

  str = str.replaceAll(PLACEHOLDER_CONSTANTS.STORE_NAME, txData.relationshipName);
  str = str.replaceAll(PLACEHOLDER_CONSTANTS.USER, (companyInfo.relationshipEmployees || {}).fullName || '');
  str = str.replaceAll(PLACEHOLDER_CONSTANTS.ORG_EMAIL, (companyInfo.relationshipEmployees || {}).emailAddress || '');
  str = str.replaceAll(PLACEHOLDER_CONSTANTS.ORG_PHONE, companyInfo.phoneNumber);
  str = str.replaceAll(PLACEHOLDER_CONSTANTS.WEBSITE, companyInfo.website);
  return str;
} 


export const sortByKey = (array, key) => {
  return array.sort(function (a, b) {
    var x = a[key];
    var y = b[key];

    if (typeof x == "string") {
      x = ("" + x).toLowerCase();
    }
    if (typeof y == "string") {
      y = ("" + y).toLowerCase();
    }

    return ((x < y) ? -1 : ((x > y) ? 1 : 0));
  });
}

export const getFirstAndLastDateOfMonth = (dateKey, format) => {
  var date = new Date(dateKey);
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  
  return {firstDay, lastDay};
}

export const getInitialsFromString = function(strData){
  const nameArr = (strData||"").split(' ');
  let initials;
  if(nameArr.length >= 2 && nameArr[1]){
    initials = `${nameArr[0].slice(0, 1)}${nameArr[1].slice(0, 1)}`.toUpperCase();
  }
  else{
    initials= `${nameArr[0].slice(0, 2)}`.toUpperCase();
  }
  return initials;
}

// export const sortTable = function (table, col, reverse) {
//   var tb = table.tBodies[0], // use `<tbody>` to ignore `<thead>` and `<tfoot>` rows
//       tr = Array.prototype.slice.call(tb.rows, 0), // put rows into array
//       i;
//   reverse = -((+reverse) || -1);
//   tr = tr.sort(function (a, b) { // sort rows
//       return reverse // `-1 *` if want opposite order
//           * (a.cells[col]?.textContent.trim() // using `.textContent.trim()` for test
//               .localeCompare(b.cells[col].textContent.trim())
//              );
//   });
//   for(i = 0; i < tr.length; ++i) tb.appendChild(tr[i]); // append each row in order
// }


export const sortTable = (table, col, reverse) => {
  const tbody = table.tBodies[0];
  const rows = Array.from(tbody.rows);
  const direction = reverse ? -1 : 1;

  rows.sort((a, b) => {
    const aText = a.cells[col]?.textContent.trim();
    const bText = b.cells[col]?.textContent.trim();
    const aNum = parseFloat(aText);
    const bNum = parseFloat(bText);

    const isNumeric = !isNaN(aNum) && !isNaN(bNum);

    return direction * (isNumeric ? aNum - bNum : aText.localeCompare(bText, undefined, { numeric: true }));
  });

  rows.forEach(row => tbody.appendChild(row));
};



export const getGreetingFromTime = (date)=>{
  let curHr = date.getHours()
  
  if (curHr < 12) {
    return "Good Morning," 
  } else if (curHr < 18) {
    return "Good Afternoon,"
  } else {
    return "Good Evening,"
  }
  
  }


  export const getUserDefinedName = function(name, props){
    const labels = props?.labelConfig?.educationLabel || {};
    let text = name;
    if(text.includes('.')){
      text = props.intl.formatMessage({id: text})
    }

    if(Object.keys(labels).length){
      text = labels.program ? text.replaceAll("Program", labels.program) : text;
      text = labels.program ? text.replaceAll("Department", labels.program) : text;
      text = labels.term ? text.replaceAll("Term", labels.term) : text;
      text = labels.subject ? text.replaceAll("Subject", labels.subject) : text;
      text = labels.course ? text.replaceAll("Course", labels.course) : text;
      text = labels.class ? text.replaceAll("Class", labels.class) : text;
      text = labels.section ? text.replaceAll("Section", labels.section) : text;
      text = labels.program ? text.replaceAll("program", labels.program) : text;
      text = labels.program ? text.replaceAll("department", labels.program) : text;
      text = labels.term ? text.replaceAll("term", labels.term) : text;
      text = labels.subject ? text.replaceAll("subject", labels.subject) : text;
      text = labels.course ? text.replaceAll("course", labels.course) : text;
      text = labels.class ? text.replaceAll("class", labels.class) : text;
      text = labels.section ? text.replaceAll("section", labels.section) : text;
    }
    return text;
  }

  export const exportToSheet = (data, fileName, exportType, fieldHeader) => {
    exportFromJSON({ data: data, fileName: fileName, exportType: (exportFromJSON.types[exportType] || exportFromJSON.types.xls), fields: fieldHeader});
    return "";
  } 

  export const camelToFlat=(camel)=>{
    const camelCase =camel.replace(/([a-z])([A-Z])/g, '$1 $2')
    return capitalizeFirstLetter(camelCase)
  }

  export const getCurrencySymbol= (code)=>{
    const currencySymbols = {
      "ALL": "Lek",
      "AFN": "؋",
      "ARS": "$",
      "AWG": "ƒ",
      "AUD": "$",
      "AZN": "₼",
      "BSD": "$",
      "BBD": "$",
      "BYR": "p.",
      "BZD": "BZ$",
      "BMD": "$",
      "BOB": "$b",
      "BAM": "KM",
      "BWP": "P",
      "BGN": "лв",
      "BRL": "R$",
      "BND": "$",
      "KHR": "៛",
      "CAD": "$",
      "KYD": "$",
      "CLP": "$",
      "CNY": "¥",
      "COP": "$",
      "CRC": "₡",
      "HRK": "kn",
      "CUP": "₱",
      "CZK": "Kč",
      "DKK": "kr",
      "DOP": "RD$",
      "EGP": "£",
      "SVC": "$",
      "EEK": "kr",
      "EUR": "€",
      "FKP": "£",
      "FJD": "$",
      "GEL": "₾",
      "GHC": "¢",
      "GIP": "£",
      "GTQ": "Q",
      "GGP": "£",
      "GYD": "$",
      "HNL": "L",
      "HKD": "$",
      "HUF": "Ft",
      "ISK": "kr",
      "INR": "₹",
      "IDR": "Rp",
      "IRR": "﷼",
      "IMP": "£",
      "ILS": "₪",
      "JMD": "J$",
      "JPY": "¥",
      "JEP": "£",
      "KZT": "лв",
      "KPW": "₩",
      "KRW": "₩",
      "KGS": "лв",
      "LAK": "₭",
      "LVL": "Ls",
      "LBP": "£",
      "LRD": "$",
      "LTL": "Lt",
      "MKD": "ден",
      "MYR": "RM",
      "MUR": "₨",
      "MXN": "$",
      "MNT": "₮",
      "MZN": "MT",
      "NAD": "$",
      "NPR": "₨",
      "ANG": "ƒ",
      "NZD": "$",
      "NIO": "C$",
      "NGN": "₦",
      "NOK": "kr",
      "OMR": "﷼",
      "PKR": "₨",
      "PAB": "B/.",
      "PYG": "Gs",
      "PEN": "S/.",
      "PHP": "₱",
      "PLN": "zł",
      "QAR": "﷼",
      "RON": "lei",
      "RUB": "₽",
      "SHP": "£",
      "SAR": "﷼",
      "RSD": "Дин.",
      "SCR": "₨",
      "SGD": "$",
      "SBD": "$",
      "SOS": "S",
      "ZAR": "R",
      "LKR": "₨",
      "SEK": "kr",
      "CHF": "CHF",
      "SRD": "$",
      "SYP": "£",
      "TWD": "NT$",
      "THB": "฿",
      "TTD": "TT$",
      "TRL": "₺",
      "TVD": "$",
      "UAH": "₴",
      "GBP": "£",
      "USD": "$",
      "UYU": "$U",
      "UZS": "лв",
      "VEF": "Bs",
      "VND": "₫",
      "YER": "﷼",
      "ZWD": "Z$"
    };

    const icon = currencySymbols[code] || code;
    
    return icon
  }

  export const getCountryCode=(countryName)=>{
   
      const countryCodes = {
        'USA': 'US',
        'United States': 'US',
        'United States (USA)': 'US',
        'United State (USA)': 'US',
        'Canada': 'CA',
        'India': 'IN',
        'india': 'IN',
        // Add more country names and codes as needed
      };
      const countryCode = countryCodes[countryName] || countryName;
      return countryCode 
}

  export const getTimeFromDate = (dateStr, format) => {
    const utcDate = moment.utc(dateStr);
    const localDate = utcDate.local();
    const time = localDate.format(format || 'HH:mm');
  
    return time;
  };

  export const getMomentMonthCountBetweenDates = (startDateUTC, endDateUTC) => {
    const startMoment = moment.utc(startDateUTC, 'YYYY-MM-DDTHH:mm:ss');
    const endMoment = moment.utc(endDateUTC, 'YYYY-MM-DDTHH:mm:ss');
    return Math.ceil(endMoment.diff(startMoment, 'months', true));
  }

  export const getPreviousYearDate = (date) => {
    date = new Date(date);
    date.setFullYear(date.getFullYear() - 1);
    return date;
  }

 function numberToWords(number, decimalUnit) {
    const ones = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
    const teens = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
    const tens = ['', 'Ten', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
    const scales = ['', 'Thousand', 'Million', 'Billion', 'Trillion'];
    const indiaScales = ['', 'Thousand', 'Lakh', 'Crore', 'Arab', 'Kharab'];
    const selectedScales = decimalUnit === 'Paise' ? indiaScales : scales;

    if (number === 0) return 'Zero';

    let result = '';
    let scaleIndex = 0;

    while (number > 0) {
        const chunk = number % 1000;
        if (chunk !== 0) {
            const chunkWords = chunkToWords(chunk);
            result = chunkWords + " " + selectedScales[scaleIndex] + (result ? ' ' + result : '');
        }
        number = Math.floor(number / 1000);
        scaleIndex++;
    }

    return result.trim();

    function chunkToWords(chunk) {
        let result = '';
        const hundreds = Math.floor(chunk / 100);
        const tensAndOnes = chunk % 100;

        if (hundreds !== 0) {
            result += ones[hundreds] + ' Hundred' + (tensAndOnes ? ' ' : '');
        }

        if (tensAndOnes !== 0) {
            if (tensAndOnes < 10) {
                result += ones[tensAndOnes];
            } else if (tensAndOnes < 20) {
                result += teens[tensAndOnes - 10];
            } else {
                const tensDigit = Math.floor(tensAndOnes / 10);
                const onesDigit = tensAndOnes % 10;
                result += tens[tensDigit] + (onesDigit ? ' ' + ones[onesDigit] : '');
            }
        }

        return result;
    }
}

export function numberToWordsWithDecimals(number, decimalUnit) {
    const [integerPart, decimalPart] = number.toString().split('.');
    const integerWords = numberToWords(parseInt(integerPart), decimalUnit);
    let decimalWords = '';
  
    if (decimalPart) {
        const allZeros = decimalPart.split('').every(digit => digit === '0');
        if (!allZeros) {
            decimalWords = 'And ' + decimalToWords(decimalPart);
        }
    }

    return integerWords + (decimalWords ? ' ' + decimalWords + ` ${decimalUnit ? decimalUnit : 'Paise'}` : '') + " Only";
}

function decimalToWords(decimal) {
    const digits = decimal.split('').map(Number);
    const ones = ['Zero', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
    return digits.map(digit => ones[digit]).join(' ');
}

export function ConvertWeightToOunces(value, type){
  if(!value || !type){
    return value;
  }
  let weightVal = value;
  switch(type){
    case 'ounces': weightVal = weightVal*1;
      break;
    case 'pounds': weightVal = weightVal * 16;
      break;
    case 'kilograms': weightVal = weightVal * 35.274;
      break;
    case 'grams': weightVal = weightVal * 0.035274;
      break;
    default: weightVal = weightVal*1;
  }
  return weightVal;
}

export function ConvertDimensionsToInches(value, type){
  if(!value || !type){
    return value;
  }
  let dimVal = value;
  switch(type){
    case 'inche': dimVal = dimVal * 1;
      break;
    case 'centimeter': dimVal = dimVal * 0.393701;
      break;
    default: dimVal = dimVal*1;
  }
  return dimVal;
}

export function getEmailStatisticsCounts(emailStats={}) {
  const { requests=0, delivered=0, hardBounces=0, softBounces=0, invalid=0, blocked=0 } = emailStats || {};

  const failed = hardBounces + softBounces + blocked + invalid;
  return {
    sent: requests,
    delivered: delivered,
    failed: failed
  };
}

export const camelCaseToTitleCase = (str) => {
  let spaced = str.replace(/([A-Z])/g, ' $1').trim();
  let titleCased = spaced.replace(/\b\w/g, char => char.toUpperCase());
  return titleCased;
}

export const getPaths = (payload) => {
  const serviceName = getServiceName(payload);
  
  const services = {
    purchase: {
      API_BASE_URL: config.PURCHASE_BASE_URL,
      rootContext: config.rootContextPurchase,
    },
    sales: {
      API_BASE_URL: config.SALES_BASE_URL,
      rootContext: config.rootContextSales,
    },
    crm: {
      API_BASE_URL: config.CRM_BASE_URL,
      rootContext: config.rootContextCRM,
    },
    inventory: {
      API_BASE_URL: config.INVENTORY_BASE_URL,
      rootContext: config.rootContextInventory,
    },
    user: {
      API_BASE_URL: config.USERS_BASE_URL,
      rootContext: config.rootContextUsers,
    },
  };

  if (!services[serviceName]) {
    console.warn(`Unknown serviceName: ${serviceName}, using 'user' as default.`);
  }

  return services[serviceName] || services.user;
};

const getServiceName = (payload) => {
  if (payload.supplierId) return "purchase";
  if (payload.customerId) return "sales";
  if (payload.leadId) return "crm";
  if (payload.inventoryId) return "inventory";
  return "user";
};

